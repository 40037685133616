import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import {
    getStatusReportingList
} from "../../../Services/Store/Common/statusReporting/statusReporting.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { statusReportingList } from "../../../Services/Store/Common/statusReporting/statusReporting.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
const Contact = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [userData, setUserData] = useState([]);
    const [userId, setUserId] = useState("");
    const [errorResponce, setErrorResponce] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [addModel, setAddModel] = useState(false);
    const [perPage, setPerPage] = useState(25);
    const [modalData, setModalData] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [userRecordsData, setUserRecordsData] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ratesListResult = useSelector(statusReportingList);
    // const mainClientresponse = useSelector(mainClient);
    const [searchType, setSearchType] = useState("");

    useEffect(() => {
        const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

        if (!loginCheck || errorResponce === 4) {
            navigate("/");
        }

    }, []);

    useEffect(() => {
        if (ratesListResult.result !== undefined) {
            const result = ratesListResult.result;
            if (result.length > 0) {
                setUserData(ratesListResult.result);
                setUserRecordsData(ratesListResult.records);
                setErrorResponce(ratesListResult.error_code);
            } else {
                setUserData("");
                setUserRecordsData("");
            }
        }
    }, [ratesListResult]);



    // useEffect(() => {
    //   const getData = setTimeout(() => {
    //     dispatch(getTruckerReportingList({ searchType: searchType, search: searchKeyword, offset, start: formik.values.start_date ,end: formik.values.end_date  }));      
    //   }, 500);
    //   return () => clearTimeout(getData);
    // }, [searchKeyword]);


    /** For pagination code start here **/
    var offset = currentPage * perPage;
    const handlePageClick = (selectedPage) => {
        dispatch(
            getStatusReportingList({ searchType: searchType, search: searchKeyword, offset: selectedPage, status: formik.values.status })
        );
    };
    /** For pagination code end here **/

    const handleChange = (event) => {
        if (searchType) {
            setSearchKeyword(event.target.value.trim());
        }
    };



    const callPageRecords = (userData) => {
        console.log(userData)
        if (userData.length) {

            let release_status = 0;
            let total_balance_main = 0;
            let total_balance_main_cad = 0;
            let text = userData.map((data, index) => {



                total_balance_main = data.total_recievable_usd - data.total_usd;
                total_balance_main_cad = data.total_recievable_cad - data.total_cad;

                if (formik.values.status == 1 || formik.values.status == 3 || formik.values.status == 6 || formik.values.status == 8) {

                    // if (total_balance_main <= 0) {
                       

                    // }

                    return (<tr key={index}>

                        <td>{data.job_number}</td>
                        <td>{data.job_date != "0000-00-00" && data.job_date !=null ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.shipper}</td>
                        <td>{data.customer_name}</td>

                        <td>{data.booking_number}</td>
                        <td>{data.bl_number}</td>
                        <td>{data.carrier_name}</td>
                        <td>{data.destination}</td>
                        <td>{data.container_number}</td>
                        <td>{data.released_received_id != 0 ? "Yes" : "No"}</td>
                        <td>{data.release_date != "0000-00-00" && data.release_date !=null ? moment(data.release_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.eta != "0000-00-00"  && data.eta !=null  ? moment(data.eta).format("YYYY-MM-DD") : ""}</td>
                        <td>
                            {data.ssl_amount} {data.ssl_paid_date == '0000-00-00' ? "Not Paid" : "Paid"}
                            <br />
                            {data.ssl1_amount != 0.00 ? data.ssl_amount1 : 0} {data.ssl1_amount != 0.00 && data.ssl_date_new == '0000-00-00' ? "Not Paid" : "Paid"}
                        </td>
                        <td>
                            {data.total_recievable_usd === null ? 0 : data.total_recievable_usd} USD
                            <br />
                            {data.total_recievable_cad === null ? 0 : data.total_recievable_cad} CAD
                        </td>

                        <td>
                            {data.total_usd} USD
                            <br />
                            {data.total_cad} CAD
                        </td>

                        <td>
                            {data.total_balance_main} USD  {data.manifest_paidby != "" ? "TP Charges " + data.manifest_amount : ""}
                            <br />
                            {data.total_balance_main_cad} CAD   {data.manifest_paidby != "" ? "TP Charges " + data.manifest_cad : ""}
                        </td>
                        <td>
                            {data.job_status_name}
                        </td>
                        <td>
                            {data.job_completed === null ? "No" : "Yes"}
                        </td>

                    </tr>)

                } else {

                    // if (total_balance_main > 0) {
                      


                    // }

                    return (<tr key={index}>

                        <td>{data.job_number}</td>
                        <td>{data.job_date != "0000-00-00" && data.job_date !=null ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.shipper}</td>
                        <td>{data.customer_name}</td>

                        <td>{data.booking_number}</td>
                        <td>{data.bl_number}</td>
                        <td>{data.shipping_line_id}</td>
                        <td>{data.destination}</td>
                        <td>{data.container_number}</td>
                        <td>{data.released_received_id != 0 ? "Yes" : "No"}</td>
                        <td>{data.release_date != "0000-00-00" && data.release_date !=null ? moment(data.release_date).format("YYYY-MM-DD") : "" }</td>
                        <td>{data.eta != "0000-00-00" ? moment(data.eta).format("YYYY-MM-DD") : ""}</td>
                        <td>
                            {data.ssl_amount} {data.ssl_paid_date == '0000-00-00' ? "Not Paid" : "Paid"}
                            <br />
                            {data.ssl1_amount != 0.00 ? data.ssl_amount1 : 0} {data.ssl1_amount != 0.00 && data.ssl_date_new == '0000-00-00' ? "Not Paid" : "Paid"}
                        </td>
                        <td>
                            {data.total_recievable_usd} USD
                            <br />
                            {data.total_recievable_cad} CAD
                        </td>

                        <td>
                            {data.total_usd} USD
                            <br />
                            {data.total_cad} CAD
                        </td>

                        <td>
                            {data.total_balance_main} USD  {data.manifest_paidby != "" ? "TP Charges " + data.manifest_amount : ""}
                            <br />
                            {data.total_balance_main_cad} CAD   {data.manifest_paidby != "" ? "TP Charges " + data.manifest_cad : ""}
                        </td>
                        <td>
                            {data.job_status_name}
                        </td>
                        <td>
                            {data.job_completed}
                        </td>

                    </tr>)

                }







            })


            return text


        } else {
            return (
                <tr className="text-center">
                    <td colSpan={19}>No Record Found</td>
                </tr>
            );
        }
    };



    const deleteUser = async (userId2) => {
        //dispatch(deleteRatesById(userId2));
        setShowModel(false);
        setDeleteModel(false);

    };





    const handleSearhChange = (event) => {
        setSearchType(event.target.value);
    };

    const validateContactFrom = Yup.object().shape({
        status: Yup.number().required('Field is required')
    });
    let initialValues = {
        status: ''
    }
  
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {

            dispatch(getStatusReportingList({ searchType: searchType, search: searchKeyword, offset, status: values.status }));

        },
    });

    return (
        <>
            <AdminLayout pageHeading="Jobs Status Report" pageIcon="fa fa-bar-chart">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <h3>Search Jobs With Status</h3>
                        </div>
                    </div>
                    <div className="container-fluid demo">
                        <div
                            className="panel-group dashboard-table-format"
                            id="accordion"
                            role="tablist"
                            aria-multiselectable="true"
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="headingOne"
                                >
                                    <div className="panel-body">
                                        <div className="card  mb-4">
                                            <div className="card-body">

                                                <div
                                                    id="dataTable_wrapper"
                                                    className="dataTables_wrapper dt-bootstrap4"
                                                >

                                                    <div className="deposited p-0">
                                                        <form onSubmit={formik.handleSubmit}>
                                                            <div className="row align-items-center" >
                                                                <div className="col-md-4 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label>Job Status</label>

                                                                        <select className="form-control" name="status"
                                                                            {...formik.getFieldProps('status')}
                                                                        >
                                                                            <option selected="" disabled="">--Select Status--</option>
                                                                            <option value="1">JOB CLOSURE REQUIRED</option>
                                                                            <option value="2">PAYMENT NOT RECEIVED</option>
                                                                            <option value="3">RELEASE REQUIRED</option>
                                                                            <option value="4">PAID, NOT RECEIVED</option>
                                                                            <option value="5">NOT RECEIVED NOT PAID </option>
                                                                            <option value="6">RECEIVED, NOT PAID</option>
                                                                            <option value="7">NEW FILE</option>
                                                                            <option value="8">RECEIVED, NOT RELEASED</option>
                                                                        </select>
                                                                        {formik.touched.status && formik.errors.status && (
                                                                            <div className="errorMsg text-danger">{formik.errors.status}</div>
                                                                        )}
                                                                    </div>
                                                                </div>



                                                                <div className="col-md-3 col-sm-12">
                                                                    <input type="submit" name="search" value="Go" className="btn btn-primary mr-2" style={{ marginTop: "15px" }} />
                                                                    <button className="action-tab btn btn-primary"  style={{ marginTop: "15px", display: "none" }}>PDF Download</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-bordered" id="pdf">
                                                        <thead>
                                                            <tr className="bg-primary text-white">
                                                                <th>Job No.</th>
                                                                <th>Job Date</th>
                                                                <th>Shipper</th>
                                                                <th>Customer Name</th>
                                                                <th>Booking No.</th>
                                                                <th>Bl No.</th>
                                                                <th>Carrier</th>
                                                                <th>Desti.</th>
                                                                <th>Container No</th>
                                                                <th>Release Rcvd.</th>
                                                                <th>Release date.</th>
                                                                <th>ETA</th>
                                                                <th>Steamship Inv.</th>
                                                                <th>Total Receivable</th>
                                                                <th>Total received</th>
                                                                <th>Balance</th>
                                                                <th>Job Status</th>
                                                                <th>Completed</th>
                                                            </tr>


                                                        </thead>
                                                        <tbody>
                                                            {ratesListResult.result !==
                                                                undefined &&
                                                                ratesListResult.result !== "" ? (
                                                                callPageRecords(
                                                                    ratesListResult.result
                                                                )
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={19}>No Record Found</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>

                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-5">
                                                        {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                                       */}
                                                    </div>
                                                    <div className="col-sm-12 col-md-7">
                                                        <div
                                                            className="dataTables_paginate paging_simple_numbers"
                                                            id="dataTable_paginate"
                                                        >
                                                            {userData != undefined &&
                                                                userData.length > 0 &&
                                                                userRecordsData !== undefined ? (
                                                                <PaginationBlock
                                                                    perPage={perPage}
                                                                    userRecordsData={userRecordsData}
                                                                    callHandlePageClick={(data) =>
                                                                        handlePageClick(data)
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </AdminLayout>
        </>
    );
};
export default Contact;
