import { USER_ACTION_TYPE } from "../../../Constant/user.constants";

const INITIAL_STATE = {
  showCustomerVolumeReportingList: [],
  blocking:false,
};
export const customerVolumeReportingListReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPE.SET_CUSTOMERV_VOLUME_REPORTING_LIST_PENDING:
      return {
        blocking: true,
        showCustomerVolumeReportingList: [],
      };

    case USER_ACTION_TYPE.SET_CUSTOMERV_VOLUME_REPORTING_LIST_SUCESS:
      return {
        blocking: false,
        showCustomerVolumeReportingList: payload,
      };

    case USER_ACTION_TYPE.SET_CUSTOMERV_VOLUME_REPORTING_LIST_ERROR:
      return {
        blocking: true,
        showCustomerVolumeReportingList: [],
      };

    case USER_ACTION_TYPE.RESET_CUSTOMERV_VOLUME_REPORTING_LIST:
      return {
        blocking: true,
        showCustomerVolumeReportingList: [],
      };

    default:
      return state;
  }
};
