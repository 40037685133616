import React from 'react';
import { Modal } from "react-bootstrap";

export function ModalBox(props) {
  return (
    <>
        <Modal {...props} backdrop="static">
          <Modal.Header closeButton>
            { props?.title !== '' ? <Modal.Title className='w-100 text-left'>{ props.title }</Modal.Title> : '' }
          </Modal.Header>
          { props.children }
        </Modal>
    </>
  );
};