import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import { TrackingForm } from "./TrackingForm";
import { VesseloperationForm } from "./VesseloperationForm"
import { JobCommentHisory } from "./JobCommentHisory";
import {
  deleteJobsById,
  getjobsList,
  addJobsDetails,
  editJobsDetails,
  addDocumentationDetails,
  editDocumentationDetails,
  addAccountDetails,
  editAccountDetails,
  addExcelDetails,
  addExtraCharges
} from "../../../Services/Store/Common/jobs/jobs.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { JobForm } from "./JobForm";
import { ViewForm } from "./ViewForm";
import { ExcelForm } from "./ExcelForm";
import { DocumentationForm } from "./DocumentationForm";
import { AccountsForm } from "./AccountsForm";
import { EditAccountsForm } from "./EditAccountsForm";
import { ExtraCharges } from "./ExtraCharges";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { jobsList } from "../../../Services/Store/Common/jobs/jobs.selector";
import moment from "moment";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import { addNotificationDetails } from "../../../Services/Store/Common/notification/notification.action";

const Jobs = () => {
  const id = useParams()?.id;
  const currentUser = useSelector((state) => state.userClient);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [shipperConsignee, setShipperConsignee] = useState([]);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [addExtraModel, setAddExtraModel] = useState(false);
  const [addExcelModel, setAddExcelModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [addDocumentationModel, setAddDocumentationModel] = useState(false);
  const [editDocumentationModel, setEditDocumentationModel] = useState(false);
  const [addAccountModel, setAddAccountModel] = useState(false);
  const [editAccountModel, setEditAccountModel] = useState(false);
  const [viewModel, setViewModel] = useState(false);
  const [perPage, setperPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState({});
  const jobsListResult = useSelector(jobsList);
  const [searchType, setSearchType] = useState(null);
  const [documentationData, setDocumentationData] = useState('');
  const [documentationDataById, setDocumentationDataById] = useState({});
  const [accountsData, setAccountsData] = useState('');
  const [accountsDataById, setAccountsDataById] = useState({});
  const [jobComments, setJobComments] = useState([]);
  const [jobCommentsHistory, setJobCommentsHistory] = useState([]);
  const [jobCommentsHistoryModel, setJobCommentsHistoryModel] = useState(false);
  const [lastInsertedId, setLastInsertedId] = useState(0);
  const largeNubmer = 1000;
  const [jobVin, setJobVin] = useState([]);
  const [mscToken, setMscToken] = useState("");
  const [addTrackingModel, setAddTrackingModel] = useState(false);
  const [addVesselOperationModel, setAddVesselOperationModel] = useState(false);
  const [searchIds, setSearchIds] = useState(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (id) {
      if (id === 'search') {
        let search = localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : '';
        if (search?.resource === 'jobs' && search.ids) {
          setSearchIds(search.ids);
        }
      } else {
        setSearchType('id');
        setSearchKeyword(id);
      }
    } else {
      localStorage.removeItem('search');
      setSearchIds('');
    }
  }, [id]);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));
    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }
  }, [errorResponce]);

  useEffect(() => {
    if (jobsListResult.result !== undefined) {
      const result = jobsListResult.result;
      if (result.length > 0) {
        setUserRecordsData(jobsListResult.records);
        setErrorResponce(jobsListResult.error_code);
      } else {
        setUserRecordsData(0);
      }
    }


  }, [jobsListResult]);

  async function fetchMyShipperConsignee(customerID) {
    const response = await api.get("api/v1/tslFetchMyShipperConsignee", {
      params: { id: customerID },
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setShipperConsignee(response.data.data);
    } else {
      setShipperConsignee("");
    }
  }

  useEffect(() => {
    async function fetchMyAPI() {
      const response = await api.get("api/v1/tsljobData", {
        params: "",
        headers: requestTokenHeader()
      });
      if (response?.data?.errorCode === 0) {
        setJobsData(response.data.data);
      } else {
        setJobsData("");
      }
    }

    fetchMyAPI();
  }, []);

  useEffect(() => {
    async function fetchMyAPIDocumentation() {
      const response = await api.get("api/v1/tsldocumentationData", {
        params: "",
        headers: requestTokenHeader()
      });
      if (response?.data?.errorCode === 0) {
        setDocumentationData(response.data.data);
      } else {
        setDocumentationData("");
      }
    }

    fetchMyAPIDocumentation();
  }, []);

  async function fetchDocumentationData(data) {
    setDocumentationDataById({});
    const response = await api.get("api/v1/tsldocumentationExist", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setDocumentationDataById(response.data.data);
      setEditDocumentationModel(true);
      setAddDocumentationModel(false);
    } else {
      setDocumentationDataById({});
      setEditDocumentationModel(false);
      setAddDocumentationModel(true);
    }
  }

  async function fetchVin(data) {
    setJobComments([]);
    const response = await api.get("api/v1/tsljobVin", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setJobVin(response.data.data);
    } else {
      setJobVin([]);
    }
  }

  /* Accounts realted code */
  useEffect(() => {
    async function fetchMyAPIAccounts() {
      const response = await api.get("api/v1/tslaccountsData", {
        params: "",
        headers: requestTokenHeader()
      });
      if (response?.data?.errorCode === 0) {
        setAccountsData(response.data.data);
      } else {
        setAccountsData("");
      }
    }

    fetchMyAPIAccounts();
  }, []);

  async function fetchAccountsData(data) {
    setAccountsDataById({});
    const response = await api.get("api/v1/tslaccountsExist", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0 && response.data.data[0].length) {
      setAccountsDataById(response.data.data);
      setEditAccountModel(true);
      setAddAccountModel(false);
    } else {
      setAccountsDataById({});
      setEditAccountModel(false);
      setAddAccountModel(true);
    }
  }

  async function fetchAccountsDataForExtra(data) {
    setAccountsDataById({});
    const response = await api.get("api/v1/tslaccountsExist", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0 && response.data.data[0].length) {
      setAccountsDataById(response.data.data);
    } else {
      setAccountsDataById({});
    }
  }

  async function fetchJobComments(data) {
    setJobComments([]);
    const response = await api.get("api/v1/tsljobComments", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setJobComments(response.data.data);

    } else {
      setJobComments([]);

    }
  }

  async function fetchLastInsertedId() {
    setLastInsertedId(0);
    const response = await api.get("api/v1/tslFetchLastInsertedId", {
      params: '',
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setLastInsertedId(response.data.data?.[0].lastId);

    } else {
      setLastInsertedId([]);

    }
  }
  /* End Accounts related code */

  useEffect(() => {
    if(searchKeyword !== null && searchType !== null)
      dispatch(getjobsList({ searchIds, searchType: searchType, search: searchKeyword, limit: perPage }));
  }, [searchKeyword, searchType]);

  useEffect(() => {
    if(searchIds !== null) {
      setSearchKeyword(null);
      setSearchType(null);
      dispatch(getjobsList({ searchIds, limit: perPage }));
    }
  }, [searchIds]);

  const handlePageClick = (selectedPage) => {
    setOffset(selectedPage);
    dispatch(getjobsList({ searchIds, searchType: searchType, search: searchKeyword, offset: selectedPage, limit: perPage }));
  };

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (userDetails) => {
    fetchJobComments({ id: userDetails.id });
    setModalData(userDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleViewClick = (jobsData) => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    setModalData(jobsData);
    if (userId) {
      setUserId(userId);
      setShowModel(true);
      setViewModel(true);
    }
  };

  const _handleDocumentationClick = (jobsData) => {
    fetchMyShipperConsignee(jobsData.customer_id);
    fetchDocumentationData({ id: jobsData.id });
    fetchJobComments({ id: jobsData.id })
    fetchVin({ id: jobsData.id })
    setModalData(jobsData);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
      setShowModel(true);
      setAddDocumentationModel(true);
    }
  };

  const _handleAccountClick = (jobsData) => {
    let rate_date = moment(jobsData.created_at).format("YYYY-MM-DD");
    fetchAccountsData({ id: jobsData.id, rates_date: rate_date });
    fetchJobComments({ id: jobsData.id })

    setModalData(jobsData);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
      setShowModel(true);
      setAddAccountModel(true);
    }
  };

  const _handleDeleteClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleAddClick = () => {
    fetchLastInsertedId();
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
      setShowModel(true);
      setAddModel(true);
    }
  };

  const _handleAddExtraClick = (jobsData) => {
    let rate_date = moment(jobsData.created_at).format("YYYY-MM-DD");
    fetchAccountsDataForExtra({ id: jobsData.id, rates_date: rate_date });

    setShowModel(true);
    setAddExtraModel(true);
  };

  const _handleAddExcelClick = () => {
    setAddExcelModel(true);
    setShowModel(true);
  };

  async function fetchDocumentationData2(data) {
    setDocumentationDataById({});
    const response = await api.get("api/v1/tsldocumentationExist", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setDocumentationDataById(response.data.data);
    } else {
      setDocumentationDataById({});
    }
  }

  async function fetchMSCToken(data) {
    setDocumentationDataById({});
    const response = await api.get("api/v1/tslGetMSCToken", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setMscToken(response.data.data);
    } else {
      setMscToken("");
    }
  }

  const _handleTrackingClick = (jobsData) => {
    fetchMSCToken({ id: jobsData.shipping_line_id });
    fetchDocumentationData2({ id: jobsData.id });
    setShowModel(true);
    setAddTrackingModel(true);
    setModalData(jobsData);
  };

  const _handleVesseloperationClick = (jobsData) => {
    fetchMSCToken({ id: jobsData.shipping_line_id });
    setModalData(jobsData);
    setShowModel(true);
    setAddVesselOperationModel(true);

  };

  async function fetchDocumentationData3(data) {
    setDocumentationDataById({});
    setJobCommentsHistory([]);
    const response = await api.get("api/v1/tslGetJobCommentByJobId", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {

      setJobCommentsHistory(response.data.data);
    } else {
      setJobCommentsHistory([]);
    }
  }

  const _handleJobHistoryClick = (jobsData) => {
    fetchDocumentationData3({ id: jobsData.id });
    setShowModel(true);
    setJobCommentsHistoryModel(true);
    setModalData(jobsData);
  };

  const docPermissionToCustomer = async (job) => {
    const response = await api.post("api/v1/tslDocPermissionCustomer", { job_id: job.id }, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage('Permission Granted');
      dispatch(getjobsList({ searchIds, searchType: searchType, search: searchKeyword, offset: offset, limit: perPage }));
      dispatch(addNotificationDetails({
        resource: 'customer/dashboard',
        resource_id: job.id,
        content: 'Upload Documents Permission Granted for Job #' + job.job_number,
        customer_id: job.customer_id
      }));
    } else {
      displayErrorMessage('Error, Please try again');
    }
  }

  const callPageRecords = (userData) => {
    if (userData) {
      return userData.map((data, index) => (
        <tr key={data.id} className={
          (currentUser.user.role === 'booking' && (!data.container_number || data.container_number == ""))
          || (currentUser.user.role === 'documentation' && data.customer_doc_permission === '1' && data.customer_file_count > 0 && data.documentation_done !== 'Yes')
             ? "table-warning" : ""
          }>
          <td>{data.job_number}</td>
          <td> {data.job_date && data.job_date !== '0000-00-00' ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
          <td> {data.job_for}</td>
          <td> {data.booking_number == "undefined" ? "" : data.booking_number}</td>
          <td> {data.shipping_line_id ? data.carriers_name : ""}</td>
          <td> {data.container_number == "undefined" ? "" : data.container_number}</td>
          <td> {data.bl_number ? data.bl_number : ""}</td>
          {
            ["documentation"].includes(currentUser.user.role) &&
            <td> {data.doc_cutoff_date && data.doc_cutoff_date !== '0000-00-00' ? moment(data.doc_cutoff_date).format("YYYY-MM-DD") : ""}</td>

          }

          <td> {data.customer_id ? data.firstname + (data.lastname ? ' ' + data.lastname : '') : ""}</td>
          <td>{data.sales_firstname} {data.sales_lastname}</td>
          {
            ["accounts"].includes(currentUser.user.role) &&
            <td>{data.ssl_amount ? data.ssl_amount : 0} {(data?.ssl_paid_date &&  data?.ssl_paid_date !="0000-00-00" &&  data?.ssl_paid_date !=null )  ? "Paid" : "Not paid"}
            </td>
          }

          <td> {data.export_document_coordinator_id ? data.edc_name : ""}</td>
          <td> {data.job_status_id ? data.job_status_name : ""}</td>
          <td style={{ display: "none" }}> {data.job_completed ? data.job_completed : "NO"}</td>
          {
            currentUser.user.role === 'documentation' ?
              <td>
                {
                  data.customer_doc_permission === '0' ?
                    <button
                      type="button"
                      className="btn btn-primary btn-xsm"
                      onClick={() => docPermissionToCustomer(data)}
                    >
                      Allow
                    </button> : <b className="text-success">Granted</b>
                }
              </td> : ''
          }
          <td align="center">
            {/* <button type="button" className="dropdown-toggle" role="button" id={"btn-" + index} data-bs-toggle="dropdown" aria-expanded="false">Open Buttons</button> */}
            {/* <div className="dropdown-menu dropdown-menu-right animated--grow-in" aria-labelledby={"btn-" + index}> */}
            {["admin", "booking", "manager", "accounts"].includes(currentUser.user.role) && data.job_completed !== 'Yes' ?
              <button
                type="button"
                className="bd-none btn btn-warning btn-xsm"
                onClick={() => _handleEditClick(data)}
                data-toggle="tooltip" data-placement="top" title="Edit"
              >
                <i className="fa fa-fw fa-edit"></i>
              </button> : ""}
            {["admin", "documentation", "manager", "accounts"].includes(currentUser.user.role) && data.job_completed !== 'Yes'
              && (data.container_number && data.container_number != "undefined" && data.container_number != "") ?
              <button
                type="button"
                className="bd-none btn btn-info btn-xsm"
                onClick={() => _handleDocumentationClick(data)}
                data-toggle="tooltip" data-placement="top" title="Documentation"
              >
                <i className="fa fa-fw fa-file"></i>
              </button> : ""}
            {["admin", "accounts", "rates", "manager", "documentation"].includes(currentUser.user.role) && data.job_completed !== 'Yes' && data.documentation_done === 'Yes' ?
              <button
                type="button"
                className="bd-none btn btn-primary btn-xsm"
                onClick={() => _handleAccountClick(data)}
                data-toggle="tooltip" data-placement="top" title="Accounts"
              >
                <i className="fa fa-fw fa-user"></i>
              </button> : ""}
            <button
              type="button"
              className="bd-none btn btn-secondary btn-xsm"
              onClick={() => _handleViewClick(data)}
              data-toggle="tooltip" data-placement="top" title="View"
            >
              <i className="fa fa-fw fa-eye"></i>
            </button>
            {["admin", 'booking', 'documentation', "manager"].includes(currentUser.user.role) && data.job_completed !== 'Yes' ?
              <button
                type="button"
                className="bd-none btn btn-danger btn-xsm"
                onClick={() => _handleDeleteClick(data.id)}
                data-toggle="tooltip" data-placement="top" title="Delete"
              >
                <i className="fa fa-fw fa-trash"></i>
              </button> : ""}
            {data.job_completed !== 'Yes' && data.job_accounts_id != null && data.documentation_done === 'Yes' ?
              <button
                type="button"
                className="bd-none btn btn-success btn-xsm"
                onClick={() => _handleAddExtraClick(data)}
                data-toggle="tooltip" data-placement="top" title="Add Extra Charges"
              >
                <i className="fa fa-dollar"></i>
              </button> : ""}
            {data.shipping_line_id == 1 || data.shipping_line_id == 6 || data.shipping_line_id == 5 ?
              <button
                type="button"
                className="bd-none btn btn-dark btn-xsm"
                onClick={() => _handleTrackingClick(data)}
                data-toggle="tooltip" data-placement="top" title="Tracking"
              >
                <i className="fa fa-map-marker "></i>
              </button>
              : ""}

            {data.shipping_line_id == 5 || data.shipping_line_id == 6 ?
              <button
                type="button"
                className="bd-none btn btn-dark btn-xsm"
                onClick={() => _handleVesseloperationClick(data)}
                data-toggle="tooltip" data-placement="top" title="Vessel Tracking"
              >
                <i className="fa fa-ship"></i>
              </button>
              : ""}

            <button
              type="button"
              className="bd-none btn btn-light btn-xsm"
              onClick={() => _handleJobHistoryClick(data)}
              data-toggle="tooltip" data-placement="top" title="Job Comment History"
            >
              <i className="fa fa-history"></i>
            </button>
            {/* </div> */}
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={13}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteUser = async (userId2) => {
    dispatch(deleteJobsById(userId2));
    setShowModel(false);
    setDeleteModel(false);
    if (userId) {
      setUserId(userId);
    }
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submiExcelDetails = async (params) => {
    dispatch(addExcelDetails(params, searchIds));
    setShowModel(false);
    setAddExcelModel(false);
  };

  const submiExtraCharges = async (params) => {
    dispatch(addExtraCharges(params, searchIds));
    setShowModel(false);
    setAddExtraModel(false);
  };

  const submitUserDetails = async (params) => {
    dispatch(addJobsDetails(params, searchIds));
    setShowModel(false);
    setAddModel(false);
  };

  const submitDocumentationDetails = async (params) => {
    dispatch(addDocumentationDetails(params, modalData, currentUser.user, searchIds));
    if (params.getAll('pdfs') && params.getAll('pdfs').length)
      displaySuccessMessage('File processing may take some time');
    setShowModel(false);
    setAddDocumentationModel(false);
  };

  const submitEditDocumentationDetails = async (params) => {
    dispatch(editDocumentationDetails(params, modalData, currentUser.user, searchIds));
    if (params.getAll('pdfs') && params.getAll('pdfs').length)
      displaySuccessMessage('File processing may take some time');
    setShowModel(false);
    setEditDocumentationModel(false);
  };

  const submitEditUserDetails = async (params) => {
    dispatch(editJobsDetails(params, modalData, currentUser.user.role, searchIds));
    setShowModel(false);
    setEditModel(false);
  };

  const submitAccountDetails = async (params) => {
    dispatch(addAccountDetails(params, searchIds));
    setShowModel(false);
    setAddAccountModel(false);
  };

  const submitEditAccountDetails = async (params) => {
    dispatch(editAccountDetails(params, searchIds));
    setShowModel(false);
    setEditAccountModel(false);
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  const handlePerPage = (event) => {
    setperPage(event.target.value);
    dispatch(getjobsList({ searchIds, searchType: searchType, search: searchKeyword, limit: event.target.value }));
  };

  return (
    <>
      <AdminLayout pageHeading="Jobs" pageIcon="fa fa-tasks">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Job List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                  {["admin", "booking", "manager"].includes(currentUser.user.role) ?
                    <button
                      type="button"
                      className="mb-2 mb-md-0 btn btn-primary me-1"
                      onClick={() => _handleAddClick()}
                    >
                      Add <i className="fa fa-plus fa-sm"></i>
                    </button> : ""
                  }
                  {["admin", "accounts"].includes(currentUser.user.role) ?

                    <button
                      type="button"
                      className="mb-2 mb-md-0 btn btn-primary"
                      onClick={() => _handleAddExcelClick()}
                    >
                      Excel <i className="fa fa-upload"></i>
                    </button>
                    : ""
                  }

                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="fillter-wrap d-flex justify-content-end">
                    <select name="per_page" className="form-control me-1" value={perPage} onChange={(e) => handlePerPage(e)}>
                      <option value='25'>25 Per Page</option>
                      <option value='50'>50 Per Page</option>
                      <option value='100'>100 Per Page</option>
                      <option value='150'>150 Per Page</option>
                    </select>
                    <select name="selectType" className="form-control me-1" value={searchType ?? ''} onChange={(event) => handleSearhChange(event)}>
                      <option value="">Select Type</option>
                      <option value="job_number">Job No.</option>
                      <option value="job_date">Job Date</option>
                      <option value="job_for">Job For</option>
                      <option value="booking_number">Booking No.</option>
                      <option value="shipping_line_id">Carrier</option>
                      <option value="container_number">Container No</option>
                      <option value="bl_number">BL Number</option>
                      <option value="doc_cutoff_date">Doc. Cut off date</option>
                      <option value="customer_name">Customer Name</option>
                      <option value="job_status_id">Job Status</option>
                      <option value="export_document_coordinator_name">Export Coordinator</option>
                    </select>
                    <input type="search" className="form-control" placeholder="Search" value={searchKeyword ?? ''} onChange={handleChange} aria-controls="dataTable" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Job No.</th>
                          <th>Job Date</th>
                          <th>Job For	</th>
                          <th>Booking No.</th>
                          <th>Carrier	</th>
                          <th>Container No</th>
                          <th>BL No</th>
                          {
                            ["documentation"].includes(currentUser.user.role) &&
                            <th>Doc. Cut off date</th>
                          }
                          <th>Customer Name</th>
                          <th>Sales Person</th>
                          {
                            ["accounts"].includes(currentUser.user.role) &&
                            <th>SSL Invoice (USD)</th>
                          }

                          <th>Export Coordinator</th>
                          <th>Job Status</th>
                          <th style={{ display: "none" }}>Completed</th>
                          {
                            currentUser.user.role === 'documentation' ?
                              <th>Doc Permission to Customer</th> : ''
                          }
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobsListResult.result !==
                          undefined &&
                          jobsListResult.result !== "" ? (
                          callPageRecords(
                            jobsListResult.result
                          )
                        ) : (
                          <tr>
                            <td colSpan={13}>No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {
                    <PaginationBlock
                      perPage={perPage}
                      userRecordsData={userRecordsData}
                      callHandlePageClick={(data) =>
                        handlePageClick(data)
                      }
                    />}
                </div>
              </div>

            </div>
          </div>
          <ModalBox
            title={jobCommentsHistoryModel ? "Job Comments History" : (deleteModel ? 'Delete Job'
              : (addExcelModel ? 'Upload Excel'
                : (addTrackingModel ? 'Job Tracking'
                  : (addVesselOperationModel ? 'Vessel Tracking'
                    : (addDocumentationModel || editDocumentationModel ? 'Documentation Form'
                      : (addAccountModel || editAccountModel ? 'Account Form' : (viewModel ? 'Job View'
                        : (addExtraModel ? 'Extra Charges Form' : 'Job Form'))))))))}
            show={showModel}
            size={deleteModel ? 'md' : (addExcelModel ? 'lg' : 'xl')}
            onHide={() => {
              setShowModel(false);
              setAddModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddAccountModel(false);
              setEditAccountModel(false);
              setAddDocumentationModel(false);
              setEditDocumentationModel(false);
              setAddExcelModel(false);
              setAddTrackingModel(false);
              setJobCommentsHistoryModel(false);
              setViewModel(false);
              setAddExtraModel(false);
              setAddVesselOperationModel(false);
            }}
          >
            {editModel === true ? (
              <JobForm
                editUserDetails={(params) => submitEditUserDetails(params)}
                userDetails={modalData}
                jobsData={jobsData}
                jobComments={jobComments}

              />
            ) : addModel === true ? (
              <JobForm
                userId={userId}
                addUserDetails={(params) => submitUserDetails(params)}
                jobsData={jobsData}
                jobNumber={lastInsertedId + largeNubmer + 1}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={userId}
                deleteUserId={(userId) => deleteUser(userId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) :
              addDocumentationModel === true ? (
                <DocumentationForm
                  key="1"
                  addUserDetails={(params) => submitDocumentationDetails(params)}
                  jobsDetails={modalData}
                  documentationData={documentationData}
                  jobComments={jobComments}
                  jobsData={jobsData}
                  shipperConsignee={shipperConsignee}

                />
              ) :
                editDocumentationModel === true ? (
                  <DocumentationForm
                    key="2"
                    userId={userId}
                    jobsDetails={modalData}
                    jobsData={jobsData}
                    documentationData={documentationData}
                    documentationDataById={documentationDataById}
                    jobComments={jobComments}
                    jobVin={jobVin}
                    shipperConsignee={shipperConsignee}
                    editUserDetails={(params) => submitEditDocumentationDetails(params)}
                  />
                ) :
                  addAccountModel === true ? (
                    <AccountsForm
                      addUserDetails={(params) => submitAccountDetails(params)}
                      documentationData={documentationData}
                      jobsDetails={modalData}
                      jobsData={jobsData}
                      accountsData={accountsData}
                      jobComments={jobComments}
                      role={currentUser.user.role}
                    />
                  ) :
                    editAccountModel === true ? (
                      <EditAccountsForm
                        userId={userId}
                        jobsDetails={modalData}
                        jobsData={jobsData}
                        accountsData={accountsData}
                        accountsDataById={accountsDataById}
                        jobComments={jobComments}
                        role={currentUser.user.role}
                        editUserDetails={(params) => submitEditAccountDetails(params)}
                      />
                    ) :

                      viewModel === true ? (
                        <ViewForm
                          userId={userId}
                          jobsDetails={modalData}
                          jobsData={jobsData}
                          accountsData={accountsData}
                          accountsDataById={accountsDataById}
                          jobComments={jobComments}
                        />
                      ) :

                        addExtraModel === true ? (

                          <ExtraCharges
                            accountsData={accountsData}
                            accountsDataById={accountsDataById}
                            addUserDetails={(params) => submiExtraCharges(params)}

                          />

                        ) :

                          addExcelModel === true ? (
                            <ExcelForm
                              addUserDetails={(params) => submiExcelDetails(params)}
                            />
                          ) :

                            addTrackingModel === true ?
                              (
                                <TrackingForm
                                  userId={userId}
                                  jobsDetails={modalData}
                                  jobsData={jobsData}
                                  documentationDataById={documentationDataById}
                                  mscToken={mscToken}
                                />
                              )
                              :
                              addVesselOperationModel === true ?
                                (
                                  <VesseloperationForm
                                    userId={userId}
                                    jobsDetails={modalData}
                                    mscToken={mscToken}
                                  />
                                )
                                :
                                jobCommentsHistoryModel === true ?
                                  (
                                    <JobCommentHisory
                                      userId={userId}
                                      jobCommentsHistory={jobCommentsHistory}
                                    />
                                  )
                                  :

                                  (
                                    ""
                                  )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default Jobs;
