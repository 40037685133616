import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ModalBox } from "../../../../Components/Ui/ModalBox";

import {
  getcontactList,
  deleteUserById,
  addUserDetails,
  editUserDetails,
  changeUserPassword,
} from "../../../../Services/Store/Common/contact/contact.action";
import PaginationBlock from "../../../../Components/Ui/PaginationBlock";
import { ContactForm } from "./ContactForm";
import { DeleteForm } from "../../../../Components/Common/DeleteForm";
import AdminLayout from "../../../../Layout";
import { contactList } from "../../../../Services/Store/Common/contact/contact.selector";
import moment from "moment";
const Contact = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userRecordsData, setUserRecordsData] = useState(0);
  const [getClientList, setGetClientList] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contactListResult = useSelector(contactList);
  // const mainClientresponse = useSelector(mainClient);
  const [searchType, setSearchType] = useState("username");

  const location = useLocation();
  const customerId = location.state.customerId;

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("customerToken"));
    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('customerToken');
      navigate("/");
    }
  }, [errorResponce]);

  useEffect(() => {
    if (contactListResult.result !== undefined) {
      const result = contactListResult.result;
      if (result.length > 0) {
        setUserData(contactListResult.result);
        setUserRecordsData(contactListResult.records);
        setErrorResponce(contactListResult.error_code);
      } else {
        setUserData("");
        setUserRecordsData("");
      }
    }
  }, [contactListResult]);



  useEffect(() => {
    const getData = setTimeout(() => {
      const userId = JSON.parse(localStorage.getItem("userId"));
      if (userId) {
        dispatch(getcontactList({ userId, customerId, searchType: searchType, search: searchKeyword, offset }));
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [searchKeyword]);


  /** For pagination code start here **/
  var offset = currentPage * perPage;
  const handlePageClick = (selectedPage) => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      dispatch(
        getcontactList({ userId, customerId, searchType: searchType, search: searchKeyword, offset: selectedPage })
      );
    }
  };
  /** For pagination code end here **/

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (userDetails) => {
    setModalData(userDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleChangePasswordClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setChangePasswordModel(true);

  }

  const _handleAddClick = () => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
      setShowModel(true);
      setAddModel(true);
    }
  };

  const callPageRecords = (userData) => {
    if (userData) {
      return userData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.contact_person}</td>
          <td>{data.contact_type}</td>
          <td> {data.mobile}</td>
          <td> {data.telephone}</td>
          <td> {data.email}</td>
          <td> {data.fax}</td>
          <td> {moment(data.created_at).format("YYYY-MM-DD")}</td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit contact"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Delete contact"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>

          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={8}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteUser = async (userId2) => {
    dispatch(deleteUserById(userId2));
    setShowModel(false);
    setDeleteModel(false);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
    }
  };

  const changePasswordUser = async (params) => {
    dispatch(changeUserPassword(params));
    setShowModel(false);
    setChangePasswordModel(false);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
    }
  };


  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };



  const submitUserDetails = async (params) => {
    dispatch(addUserDetails(params));
    setShowModel(false);
    setAddModel(false);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
    }
  };



  const submitEditUserDetails = async (params) => {
    dispatch(editUserDetails(params));
    setShowModel(false);
    setAddModel(false);
  };



  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };
  return (
    <>
      <AdminLayout pageHeading="Contacts" pageIcon="fa fa-address-book">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Contact List</h1>
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="table-responsive">
                <div className="row mb-2">
                  <div className="col-sm-12 col-md-6">
                    <button
                      type="button"
                      className="mb-2 mb-md-0 btn btn-primary"
                      onClick={() => _handleAddClick(customerId)}
                    >
                      Add&nbsp;
                      <i className="fa fa-plus fa-sm"></i>
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="fillter-wrap d-flex justify-content-end">
                      <select name="selectType" id="" className="form-control me-1" onChange={(event) => handleSearhChange(event)}>
                        <option value="">Select Type</option>
                        <option value="contact_person">Contact Person</option>
                        <option value="mobile">Mobile</option>
                        <option value="telephone">Telephone</option>
                        <option value="email">Email</option>
                        <option value="fax">Fax</option>
                      </select>
                      <input type="search" className="form-control" placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Contact Person</th>
                          <th>Role</th>
                          <th>Mobile</th>
                          <th>Telephone</th>
                          <th>Email</th>
                          <th>Fax</th>
                          <th>Created At</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactListResult.result !==
                          undefined &&
                          contactListResult.result !== "" ? (
                          callPageRecords(
                            contactListResult.result
                          )
                        ) : (
                          <tr>
                            <td colSpan={8}>No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    {
                      <PaginationBlock
                        perPage={perPage}
                        userRecordsData={userRecordsData}
                        callHandlePageClick={(data) =>
                          handlePageClick(data)
                        }
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalBox
            show={showModel}
            title={deleteModel ? 'Delete Contact' : 'Contact Form'}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setChangePasswordModel(false);
              setDeleteModel(false);
              setAddModel(false);
            }}
          >
            {editModel === true ? (
              <ContactForm
                editUserDetails={(params) => submitEditUserDetails(params)}
                userDetails={modalData}
                customerId={customerId}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={userId}
                deleteUserId={(userId) => deleteUser(userId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : addModel === true ? (
              <ContactForm
                userId={userId}
                customerId={customerId}
                addUserDetails={(params) => submitUserDetails(params)}
              />
            ) : (
              ""
            )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default Contact;
