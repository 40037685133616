import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
export function ImportDocumentCoordinatorForm(props) {

    const [fields, setFields] = useState(props.userDetails ? props.userDetails : {});
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState(false);
    const [applyCheck] = useState(false);

    const validateContactFrom = Yup.object().shape({
        name: Yup.string().required('Name is required').max(150),
        email: Yup.string().email().required('Email is required'),
        mobile_number: Yup.string().required('Mobile number is required').matches(/^[0-9]{10}$/ , 'Phone number must have 10 digits.'),
    });
    let initialValues = {};
    if (fields) {
        initialValues = {
            name: fields.name,
            email: fields.email,
            mobile_number: fields.mobile_number,
        }

    } else {
        initialValues = {
            name: '',
            email: '',
            mobile_number: '',
        }
    }


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            if (fields?.id) {
                const postData = {
                    name: values.name,  
                    email: values.email,  
                    mobile_number: values.mobile_number,                    
                    id: fields.id,
                }
                props.editUserDetails(postData);

            } else {

                const params = {
                    name: values.name,
                    email: values.email,  
                    mobile_number: values.mobile_number,  
                }
                props.addUserDetails(params)
            }


        },
    });



    return (
        <>
            <div className="modal-body invitation-wrap">
                
                    <div className="login-wrap-new">
                        
                            <div className="form-content">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">

                                        <div className="col-md-12 col-xs-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Name"
                                                id="name"
                                                name="name"
                                                {...formik.getFieldProps('name')}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <div className="errorMsg text-danger">{formik.errors.name}</div>
                                            )}

                                        </div>

                                        <div className="col-md-6 col-xs-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email"
                                                id="email"
                                                name="email"
                                                {...formik.getFieldProps('email')}
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <div className="errorMsg text-danger">{formik.errors.email}</div>
                                            )}

                                        </div>

                                        <div className="col-md-6 col-xs-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Mobile Number"
                                                id="mobile_number"
                                                name="mobile_number"
                                                {...formik.getFieldProps('mobile_number')}
                                            />
                                            {formik.touched.mobile_number && formik.errors.mobile_number && (
                                                <div className="errorMsg text-danger">{formik.errors.mobile_number}</div>
                                            )}

                                        </div>
                                        
                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        { fields.id ? 'Save' : 'Save' }
                                    </button>
                                </form>
                            </div>
                        
                    </div>
               
            </div>
        </>
    );
}