import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
  getPrintBalanceSheetList
} from "../../../Services/Store/Common/printBalanceSheetReporting/printBalanceSheetReporting.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { printBalanceSheetReportingList } from "../../../Services/Store/Common/printBalanceSheetReporting/printBalanceSheetReporting.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
const Contact = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
   const ratesListResult = useSelector(printBalanceSheetReportingList);
  // const mainClientresponse = useSelector(mainClient);
  const [searchType, setSearchType] = useState("");

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }   
    
  }, []);

  useEffect(() => {
    if (ratesListResult.result !== undefined) {
      const result = ratesListResult.result;
      if (result.length > 0) {
        setUserData(ratesListResult.result);
        setUserRecordsData(ratesListResult.records);
        setErrorResponce(ratesListResult.error_code);
      } else {
        setUserData("");
        setUserRecordsData("");
      }
    }
  }, [ratesListResult]);



  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     dispatch(getShippinglineReportingList({ searchType: searchType, search: searchKeyword, offset, start: formik.values.start_date ,end: formik.values.end_date  }));      
  //   }, 500);
  //   return () => clearTimeout(getData);
  // }, [searchKeyword]);


  /** For pagination code start here **/
  var offset = currentPage * perPage;
  const handlePageClick = (selectedPage) => {
    dispatch(
      getPrintBalanceSheetList({ searchType: searchType, search: searchKeyword, offset: selectedPage, start: formik.values.start_date ,end: formik.values.end_date  })
    );
  };
  /** For pagination code end here **/

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  


  const deleteUser = async (userId2) => {
    //dispatch(deleteRatesById(userId2));
    setShowModel(false);
    setDeleteModel(false);
  
  };





  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  const validateContactFrom = Yup.object().shape({
    end_date: Yup.string().required('Field is required'),
    // start_date: Yup.string().required('Field is required'),
});
let initialValues = {
  end_date: '',
  start_date:""
}


const formik = useFormik({
  initialValues: initialValues,
  validationSchema: validateContactFrom,
  onSubmit: (values) => {   

  dispatch(getPrintBalanceSheetList({searchType: searchType, search: searchKeyword, offset, start: values.start_date ,end: values.end_date }));

  },
});

const callPageRecords = (userData) => {
  console.log(userData)
  if (userData) {
   

    return (

      <>
       <tr>
    <th style={{border: "#bdbdbd thin solid", backgroundColor:"#2093a8", color:"#fff"}}>Description</th>
    <th style={{backgroundColor:"#2093a8", border: "#bdbdbd thin solid" ,color:"#fff"}}>Ammount</th>
  </tr>
  <tr>
    <th> Ammount:</th>
    <td>
      <b  style={{color:"#000"}}>{userData[1][0]['total_recievable_usd']} USD,  {userData[1][0]['total_recievable_cad']} CAD</b>
    </td>
  </tr>
  <tr>
    <th> Payable Ammount USD:</th>
    <td>Shipping line: ${userData[1][0]['total_payable_usd'] - userData[1][0]['total_trucker_usd'] } + Trucker: $ {userData[1][0]['total_trucker_usd'] === null ? 0 :  userData[1][0]['total_trucker_usd']} = <b style={{color:"#FF0000"}} >$ {userData[1][0]['total_payable_usd'] === null ? 0 : userData[1][0]['total_payable_usd']} </b>
    </td>
  </tr>
  <tr>
    <th> Payable Ammount CAD:</th>
    <td>Shipping line:  ${userData[1][0]['total_payable_cad'] - userData[1][0]['total_trucker_cad'] }+ Trucker: $ {userData[1][0]['total_trucker_cad'] === null ? 0 :  userData[1][0]['total_trucker_cad']} = <b style={{color:"#FF0000"}} > ${userData[1][0]['total_payable_cad'] === null ? 0 : userData[1][0]['total_payable_cad']} </b>
    </td>
  </tr>
  <tr>
    <th> Ammount Recieved:</th>
    <td>${userData[2][0]['total_usd_rec'] === null ? 0 : userData[2][0]['total_usd_rec']  } USD, ${userData[2][0]['total_cad_rec'] === null ? 0 : userData[2][0]['total_usd_rec']} CAD </td>
  </tr>
  <tr>
    <th> Ammount Paid USD: </th>
    <td>Shipping line: $ {userData[0][0]['total_payable_usd'] - userData[0][0]['total_trucker_usd'] } + Trucker: $ {userData[0][0]['total_trucker_usd']=== null ? 0: userData[0][0]['total_trucker_usd'] } = <b style={{color:"#FF0000"}} >$ {userData[0][0]['total_payable_usd'] === null ? 0 : userData[0][0]['total_payable_usd']} </b>
    </td>
  </tr>
  <tr>
    <th> Ammount Paid CAD: </th>
    <td>Shipping line:  {userData[0][0]['total_payable_cad'] - userData[0][0]['total_trucker_cad'] }+ Trucker: {userData[0][0]['total_trucker_cad']  === null ?0 : userData[0][0]['total_trucker_cad']} = <b style={{color:"#FF0000"}} > ${userData[0][0]['total_payable_cad'] === null ? 0 :userData[0][0]['total_payable_cad']} </b>
    </td>
  </tr>
  <tr>
    <th>Total Pending Receiving Ammount USD:</th>
    <td>$ {userData[1][0]['total_recievable_usd']-userData[0][0]['total_recievable_usd']}</td>
  </tr>
  <tr>
    <th>Total Pending Receiving Ammount CAD:</th>
    <td>$ {userData[1][0]['total_recievable_usd']-userData[0][0]['total_recievable_usd']}</td>
  </tr>
  <tr>
    <th>Total Pending Paying Ammount USD:</th>
    <td>$ {parseFloat(userData[1][0]['total_payable_usd']-userData[0][0]['total_payable_usd'] ).toFixed(2)}</td>
  </tr>
  <tr>
    <th>Total Pending Paying Ammount CAD:</th>
    <td>$ {parseFloat(userData[1][0]['total_payable_cad']-userData[0][0]['total_payable_cad']).toFixed(2)}</td>
  </tr>
  <tr>
    <th>Total Net Gain Ammount USD:</th>
    <td>
      <b style={{color:"#006600"}}>$ {userData[1][0]['total_gain_usd'] === null ? 0 : userData[1][0]['total_gain_usd']}</b>
    </td>
  </tr>
  <tr>
    <th>Total Net Gain Ammount CAD:</th>
    <td>
      <b style={{color:"#006600"}} >$ {userData[1][0]['total_gain_cad'] === null ? 0 : userData[1][0]['total_gain_cad']}</b>
    </td>
  </tr>
  </>
    );


  } else {
    return (
      <tr className="text-center">
        <td colSpan={8}>No Record Found</td>
      </tr>
    );
  }
};



  return (
    <>
      <AdminLayout pageHeading="Print Balance Sheet">
        <div className="container-fluid">
    
          <div className="container-fluid demo">
            <div
              className="panel-group dashboard-table-format"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="panel panel-default">
                <div
                  className="panel-heading"
                  role="tab"
                  id="headingOne"
                >
                  <div className="panel-body">
                    <div className="card  mb-4">
                      <div className="card-body">
                        <div className="table-responsive">
                          <div
                            id="dataTable_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                       
                       <div className="deposited p-0">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row align-items-center" > 
                              <div className="col-sm-6 col-md-4">
                              <div className="form-group">
                                  <label>Start Date</label>
                                  <input className="form-control"  
                                  type="date" 
                                  name="start_date" 
                                   {...formik.getFieldProps('start_date')}
                                  />
                                  {formik.touched.start_date && formik.errors.start_date && (
                                                <div className="errorMsg text-danger">{formik.errors.start_date}</div>
                                            )}
                                </div>  
                                </div>                         
                                <div className="col-sm-6 col-md-4">
                                <div className="form-group">
                                  <label>End Date</label>
                                  <input className="form-control"  
                                  type="date" 
                                  name="end_date" 
                                   {...formik.getFieldProps('end_date')}
                                  />
                                  {formik.touched.end_date && formik.errors.end_date && (
                                    <div className="errorMsg text-danger">{formik.errors.end_date}</div>
                                  )}
                                </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                  <input type="submit" className="btn btn-primary mr-2" name="search" value="Go" style={{ marginTop: "12px" }} />
                                  <button className="action-tab btn btn-primary ml-1"  style={{ marginTop: "12px", display:"none" }}>PDF Download</button>
                                </div>
                              </div>
                              </form>

                               
                            </div>
                          </div>
                       
                        </div>
                        <div className="row">
  <div className="col-md-12 panel-warning">
    
    <div className="content-box-large box-with-header">
      <div className="content-box-large">
       
       
        <div className="panel-body">
          <table style={{width:"100%", cellpadding:"0", cellspacing:"0" , border:"0"}}>
            <tbody>
              <tr>
                <th style={{textAlign:"center"}} colSpan="2">
                  <h1>Golden Freight Forwarding and Marketing Inc</h1>
                </th>
              </tr>
              <tr>
                <th style={{textAlign:"center"}} colSpan="2">
                  <h4>
                    <span style={{fontSize:"22px"}}>Balance Sheet for dates:</span> {formik.values.start_date == "" ? "Start Date" : formik.values.start_date} to {formik.values.end_date == "" ? "End Date" : formik.values.end_date}
                  </h4>
                </th>
              </tr>
            </tbody>
          </table>
          <br/>
         
          <table className="my-table" cellpadding="20" cellspacing="20" border="0" style={{width:"90%"}} align="center">
            <tbody>
            {ratesListResult.result !==
                                undefined &&
                                ratesListResult.result !== "" ? (
                                callPageRecords(
                                  ratesListResult.result
                                )
                              ) : (
                                <tr>
                                  <td colSpan={8}>No Record Found</td>
                                </tr>
                              )}
            </tbody>
          </table>
        </div>
      </div>
      <br/>
      <br/>
    </div>
  </div>
</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
     
     
        </div>

      </AdminLayout>
    </>
  );
};
export default Contact;
