import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import {
  getCustomerReportingList
} from "../../../Services/Store/Common/customerReporting/customerReporting.action";

import { customerReportingList } from "../../../Services/Store/Common/customerReporting/customerReporting.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import Loader from "../../../../src/Components/Ui/loader";
import * as XLSX from "xlsx";
import { requestTokenHeader, displayErrorMessage, } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";

const Contact = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [display, setDisplay] = useState(false);
  // const mainClientresponse = useSelector(mainClient);
  const [searchType, setSearchType] = useState("");
  
  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }
  }, []);

  const callPageRecords = (userData) => {
    //console.log(userData)
    if (userData?.[0]) {
      let text = userData?.[0].map((data, index) => (
        <tr key={index}>
          <td>{data.name}</td>
          <td>{parseFloat(data.thiry_balance).toFixed(2)}</td>
          <td>{parseFloat(data.sixty_balance).toFixed(2)}</td>
          <td>{parseFloat(data.ninety_balance).toFixed(2)}</td>
          <td>{parseFloat(data.ninetyplus_balance).toFixed(2)}</td>
          <td className="font-weight-bold">{parseFloat(data.total).toFixed(2)}</td>
        </tr>
      ));

      let text2 = userData?.[1].map((data, index) => (
        <tr key={data.id}>
          <td className="font-weight-bold">Total</td>
          <td className="font-weight-bold">{parseFloat(data.thiry_balance_total).toFixed(2)}</td>
          <td className="font-weight-bold">{parseFloat(data.sixty_balance_total).toFixed(2)}</td>
          <td className="font-weight-bold">{parseFloat(data.ninety_balance_total).toFixed(2)}</td>
          <td className="font-weight-bold">{parseFloat(data.ninetyplus_balance_total).toFixed(2)}</td>
          <td className="font-weight-bold">{parseFloat(data.total_balance_all).toFixed(2)}</td>
        </tr>
      ));
      return text.concat(text2);
    } else {
      return (
        <tr className="text-center">
          <td colSpan={8}>No Record Found</td>
        </tr>
      );
    }
  };

  const validateContactFrom = Yup.object().shape({
    end_date: Yup.string().required('Field is required'),
    // start_date: Yup.string().required('Field is required'),
  });
  let initialValues = {
    end_date: '',
    start_date: "2019-01-01"
  }
  
  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateContactFrom,
    onSubmit: async(values) => {
      setDisplay(true);
      const response = await api.post("api/v1/tslCustomerReportingList", { start: formik.values.start_date, end: formik.values.end_date }, { headers: requestTokenHeader() });
      setDisplay(false);
      if (response.data.errorCode === 0) {
        setUserRecordsData(response.data.data);
      }
    },
  });

  const handleDownload = (type) => {
    fetchMyAPI2(type);
  };
  
  async function fetchMyAPI2(type) {

    if (formik.values.start_date == "" || formik.values.end_date == "") {
      displayErrorMessage("containerReportingError"); return;

    }
    //console.log( { start: formik.values.start_date, end: formik.values.end_date, offset: 0, limit: 9999999999999 }); return; 

    setDisplay(true);

    const response = await api.post("api/v1/tslCustomerReportingList", 
    { start: formik.values.start_date, end: formik.values.end_date, offset: 0, limit: 9999999999999 },
      { headers: requestTokenHeader() }
    );

    if (response.data.errorCode === 0) {

      if(type == "excel"){
        const rows = (response.data.data[0]).map((data) => ({
          name:data.name,
          thiry_balance: parseFloat(data.thiry_balance).toFixed(2),
          sixty_balance: parseFloat(data.sixty_balance).toFixed(2),
          ninety_balance: parseFloat(data.ninety_balance).toFixed(2),
          ninetyplus_balance: parseFloat(data.ninetyplus_balance).toFixed(2),
          total: parseFloat(data.total).toFixed(2)
        }));
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(rows);  
        XLSX.utils.book_append_sheet(workbook, worksheet, "CustomerReporting");
        XLSX.utils.sheet_add_aoa(worksheet, [
          ["Customer Name", "Aged 1-30", "Aged 30-60", "Aged 60-90", "Aged > 91","Total"],
        ]);  
        XLSX.writeFile(workbook, "customer_reporting.xlsx", { compression: true });
      }

      if(type == "pdf"){
        const doc = new jsPDF();
        const tableColumn = ["Customer Name", "Aged 1-30", "Aged 30-60", "Aged 60-90", "Aged > 91","Total"];
        const tableRows = [];
  
        (response.data.data[0]).forEach(data => {
          const ticketData = [
            data.name,
            parseFloat(data.thiry_balance).toFixed(2),
            parseFloat(data.sixty_balance).toFixed(2),
            parseFloat(data.ninety_balance).toFixed(2),
            parseFloat(data.ninetyplus_balance).toFixed(2),
            parseFloat(data.total).toFixed(2)
          ];
          tableRows.push(ticketData);
        });
  
        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        doc.save(`customer_reporting.pdf`);

      }
      setDisplay(false);

    } else {
      setDisplay(false);
    }
  }

  return (
    <>
      <AdminLayout pageHeading="Customer Reporting" pageIcon="fa fa-bar-chart">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Customer Reporting</h1>
          <div className="card shadow mb-4">
            <div className="card-body">
              <Loader display={display}/>
              <div className="deposited">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row align-items-center" >

                    <div className=" col-md-8 col-sm-12">
                      <div className="form-group">
                        < label>End Date</label>
                        <input className="form-control"
                          type="date"
                          name="end_date"
                          {...formik.getFieldProps('end_date')}
                        />
                        {formik.touched.end_date && formik.errors.end_date && (
                          <div className="errorMsg text-danger">{formik.errors.end_date}</div>
                        )}
                      </div>
                    </div>
                    <div className=" col-md-4 col-sm-12">
                      <input type="submit" className="btn btn-primary" name="search" value="Go" style={{ marginTop: "15px" }} />
                      <button className="action-tab btn btn-primary" onClick={item => handleDownload("excel")} style={{marginLeft: "10px", marginTop: "15px" }} data-toggle="tooltip" data-placement="top" title="Export as Excel file"><i class="fa fa-file-excel-o"></i></button>
                      <button className="action-tab btn btn-primary" onClick={item => handleDownload("pdf")} style={{marginLeft: "10px", marginTop: "15px" }} data-toggle="tooltip" data-placement="top" title="Export as PDF file"><i class="fa fa-file-pdf-o"></i></button>
                                 
                    </div>
                  </div>
                </form>
              </div>

              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="bg-primary text-white">

                      <th>Customer Name</th>
                      <th>Aged 1-30</th>
                      <th>Aged 30-60</th>
                      <th>Aged 60-90</th>
                      <th>Aged {">"} 91</th>
                      <th className="font-weight-bold">Total</th>

                    </tr>
                  </thead>
                  <tbody>
                    {callPageRecords(userRecordsData)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>


        </div>
      </AdminLayout>
    </>
  );
};
export default Contact;
