import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";

export const deleteCarrierById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteCarrier", { id },
      { headers: requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      const showCarrierList = {};
      const resultOutput = getState()
        .carrierList.showCarrierList.result.map((data) => data)
        .filter((data) => data.id !== id);
      showCarrierList.result = resultOutput;
      showCarrierList.records = getState().carrierList.showCarrierList.records - 1;
      showCarrierList.errorCode = 0;
      displaySuccessMessage("carrierDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_CARRIER_LIST_SUCESS,
        payload: showCarrierList,
      });
    } else {
      displayErrorMessage("carrierDeleteError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_CARRIER_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_CARRIER_LIST_ERROR });
  }
};

export const getcarrierList = (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslCarriersList", {
      params: postData,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_CARRIER_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_CARRIER_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    return dispatch({ type: USER_ACTION_TYPE.SET_CARRIER_LIST_ERROR });
  }
};

export const addCarrierDetails = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddCarrier", postData,
      { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      const resultOutput = getState().carrierList.showCarrierList.result;
      resultOutput.push(response.data.result);
      const showCarrierList = {};
      showCarrierList.result = resultOutput;
      showCarrierList.records = getState().carrierList.showCarrierList.records + 1;
      showCarrierList.errorCode = 0;
      displaySuccessMessage("carrierAdded");
      return dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: showCarrierList,
      });
    } else {
      displayErrorMessage("carrierAddedError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_USER_LIST_ERROR });
  }
};

export const editCarrierDetails = (postData, searchIds) => {
  return async (dispatch) => {
    try {
      const response = await api.post("api/v1/tslEditCarrier", postData,
        {
          headers: requestTokenHeader()
        });
      if (response.data.errorCode === 0) {
        displaySuccessMessage("carrierUpdated");
        dispatch(getcarrierList({ searchIds }));
      } else {
        const responce = {
          result: "",
          records: 0,
          error_code: response.data.errorCode,
        };
        displayErrorMessage("carrierUpdatedError");

        dispatch({
          type: USER_ACTION_TYPE.SET_CARRIER_LIST_SUCESS,
          payload: responce,
        });
      }
    } catch (err) {
      displayErrorMessage(err.name);
      dispatch({ type: USER_ACTION_TYPE.SET_CARRIER_LIST_ERROR });
    }
  }
};


