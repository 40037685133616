import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Services/Helpers/helper";

export function ExtraCharges(props) {
    const [fields, setFields] = useState('');
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState(false);
    const [applyCheck] = useState(false);

    const [ecUsd, setEcUsd] = useState(0);
    const [ecCad, setEcCad] = useState(0);
    const [quoteExtra, setQuoteExtra] = useState({});
    const [addExtraClicked, setAddExtraClicked] = useState(true);
    const [extraData, setExtraData] = useState([]);
    const regAmount = /^\d{0,4}(\.\d{0,2})?$/;
    const [conversionUsd, setConversionUsd] = useState(props?.accountsData?.[0] ? props?.accountsData?.[0]?.usd_to_cad_rate  : "");
    const [conversionCad, setConversionCad] = useState(props?.accountsData?.[0] ? props?.accountsData?.[0]?.cad_to_usd_rate : "");

    function conversion(value, currency) {
        let newValue = "";
        if (value != "" && value > 0 && conversionUsd != "" && conversionCad != "") {

            if (currency == "USD") {
                newValue = (value * conversionUsd).toFixed(2);
            } else {
                newValue = (value * conversionCad).toFixed(2);
            }
        } else {
            value = "";
            newValue = "";

        }

        return [value, newValue];

    }
    const handelExtraClick = () => {
        setAddExtraClicked(true);
    }

    const handelCancelExtra = () => {
        setAddExtraClicked(false);
    }

    const validateQuoteExtra = (quoteExtra, applyCheck = false) => {
        let errors = {};
        let formIsValid = true;

        if (!quoteExtra["service_name"] || quoteExtra["service_name"].trim() === "") {
            formIsValid = false;
            errors["service_name"] = "*Field is required";
        }
        if (!quoteExtra["cad_amount"] || quoteExtra["cad_amount"].trim() === "") {
            formIsValid = false;
            errors["cad_amount"] = "*Field is required";
        }

        if (!quoteExtra["usd_amount"] || quoteExtra["usd_amount"].trim() === "") {
            formIsValid = false;
            errors["usd_amount"] = "*Field is required";
        }

        if (!regAmount.test(quoteExtra["cad_amount"])) {
            formIsValid = false;
            errors["cad_amount"] = "*Please enter correct value";

        }

        if (!regAmount.test(quoteExtra["usd_amount"])) {
            formIsValid = false;
            errors["usd_amount"] = "*Please enter correct value";

        }


        return {
            errors: errors,
            formIsValid: formIsValid,
        };
    };

    const _validateQuoteExtraForm = () => {
        let formquoteExtra = quoteExtra;
        let response = validateQuoteExtra(formquoteExtra, applyCheck);
        setErrors(response.errors);
        return response.formIsValid;
    };
    const handleChangeQuoteExtra = (event) => {
        let data = quoteExtra;
        data[event.target.name] = event.target.value;
        setQuoteExtra({ ...data });
    };
    const updateQuoteExtraDetails = (event) => {
        let currencytype = "USD";
        if (_validateQuoteExtraForm()) {
            if (quoteExtra && (currencytype === "USD" || currencytype === "CAD")) {
                quoteExtra.job_number = props?.accountsDataById?.[0][0]?.job_number;
                quoteExtra.conversion_cad = conversionCad;
                quoteExtra.conversion_usd = conversionUsd;
                setExtraData(quoteArray => [...quoteArray, quoteExtra]);
                setAddExtraClicked(false);
                setQuoteExtra({});
            } else {
                displayErrorMessage('currencyNotFound');
            }
        }
    }



    const _handleQuoteExtraDeleteClick = (data) => {
        setExtraData(
            quoteArray => quoteArray.filter(function (item) {
                return item.service_name !== data.service_name
            })

        );
    }
    const validateAddAccounts = (fields, applyCheck = false) => {
        let errors = {};
        let formIsValid = true;

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;



        return {
            errors: errors,
            formIsValid: formIsValid,
        };
    };
    const [formFields, setFormFields] = useState({
        cq_amount: 0, cq_cad: 0, esm_amount: 0, esm_cad: 0, chc_amount: 0, chc_cad: 0, ctc_amount: 0, ctc_cad: 0, manifest_amount: 0, manifest_cad: 0,
        ec_usd: 0, ec_cad: 0, ssl_amount: 0, ssl_cad: 0, ssl1_amount: 0, ssl_with_others: 0,
        ssl_with_others_cad: 0, ssl1_cad: 0, trucker_amount: 0, trucker_cad: 0,
        trucker_paid_cad: 0, trucker_paid_amount: 0, third_party_usd: 0, third_party_cad: 0,
        extra_payable_usd: 0, extra_payable_cad: 0,
        total_payable_usd: 0, total_payable_cad: 0, total_recievable_usd: 0, total_recievable_cad: 0,
        gain_usd: 0, gain_cad: 0


    });
    const _validateForm = () => {
        let formFields123 = formFields;
        let response = validateAddAccounts(formFields123, applyCheck);
        setErrors(response.errors);
        return response.formIsValid;
    };
    const updateUserDetails = async (event) => {
        event.preventDefault();
        if (_validateForm()) {
          

                if (props.accountsData == "") {
                    displayErrorMessage('ratesNotFound');
                    return;
                }
                

                const params = {    
                    quoteExtra:  JSON.stringify(extraData[0]) 
               
                }
              
                props.addUserDetails(params)

            
        }
    }

    return (
        <>
            <div className="modal-body">
                <div className="login-wrap-new">
                    <form onSubmit={(event) => updateUserDetails(event)}>
                        <div className="form-content">                                    
                            <div className="row">
                            { (Object.keys(props.accountsDataById).length === 0) && 
                                 
                                    <div className="col-12 ">
                                        <div className="alert alert-danger mb-1 p-2">
                                            <strong>Account information missing!</strong>
                                        </div>
                                    </div>
                            }

                            {props.accountsData == "" && 
                                <div className="col-12 ">

                                    <div className="alert alert-danger p-2">
                                        <strong>Rates not found!</strong> Please add rates for today.
                                    </div>
                                </div>}

                                {addExtraClicked && (<div className=''>
                                    <div className="row" style={{ mar: "10px" }}>

                                        <div className=" col-sm-4">
                                            <label>Service Name</label>

                                            <input
                                                className="form-control service_name clear-fields"
                                                type="text"
                                                name="service_name"
                                                value={quoteExtra.service_name}
                                                onChange={(event) => handleChangeQuoteExtra(event)}
                                            />
                                            {errors.service_name ? (
                                                <div className="errorMsg text-danger">
                                                    {errors.service_name}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className="col-sm-4" >
                                            <label>Service Amount</label>
                                            <div className='d-flex '>
                                            <div className="left-inner-addon mr-2">
                                                <i className="">c$</i>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="CAD Amount"
                                                    name="cad_amount"
                                                    id="cad_amount"
                                                    value={quoteExtra.cad_amount}
                                                    onChange={(event) => {
                                                        const response = conversion(event.target.value, "CAD");
                                                        let data = quoteExtra;
                                                        data["cad_amount"] = response[0];
                                                        data["usd_amount"] = response[1];
                                                        setQuoteExtra({ ...data });

                                                    }}

                                                />


                                            </div>

                                            <div className="left-inner-addon">
                                                <i className="">$</i>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="USD Amount"
                                                    name="usd_amount"
                                                    id="usd_amount"
                                                    value={quoteExtra?.usd_amount}
                                                    onChange={(event) => {
                                                        const response = conversion(event.target.value, "USD");
                                                        let data = quoteExtra;
                                                        data["usd_amount"] = response[0];
                                                        data["cad_amount"] = response[1];
                                                        setQuoteExtra({ ...data });

                                                    }}

                                                />

                                            </div>
                                            </div>
                                        </div>

                                        <div className=" col-sm-4">
                                            <label>Extra Charges Remarks</label><br />
                                            <textarea
                                                className="form-control extra_charges_remarks clear-fields"
                                                type="textarea"
                                                id="extra_charges_remarks"
                                                name="extra_charges_remarks"
                                                rows={1}
                                                value={quoteExtra.extra_charges_remarks}
                                                onChange={(event) => handleChangeQuoteExtra(event)}
                                            />
                                            {errors.extra_charges_remarks ? (
                                                <div className="errorMsg text-danger">
                                                    {errors.extra_charges_remarks}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    
                                    <div className="row" style={{ marginTop: "20px" }}>

                                        <div className="col-sm-4">
                                            <input type='button' name="submit" className="btn btn-primary extra-submit text-white" value="Add charges" onClick={(e) => updateQuoteExtraDetails(e)} />
                                        </div>


                                    </div>

                                </div>)}

                                {(extraData && extraData.length > 0) && (<table className="table table-striped payable-charges-table" style={{ marginTop: "20px" }}>
                                    <thead>
                                        <tr>

                                            <th>Sr No.</th>
                                            <th>Conversion CAD</th>
                                            <th>Conversion USD</th>
                                            <th>Name</th>
                                            <th>CAD Amount </th>
                                            <th>USD Amount</th>
                                            <th>Extra Charges Remarks</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {extraData.map((item, i) => {

                                            return [
                                                <tr key={i} id={`id-${item?.id ? item.id : i}`}>
                                                    <td>{i + 1}</td>
                                                    <td>{conversionCad}</td>
                                                    <td>{conversionUsd}</td>
                                                    <td>{item.service_name}</td>
                                                    <td>{parseFloat(item.cad_amount).toFixed(2)}</td>
                                                    <td>{parseFloat(item.usd_amount).toFixed(2)}</td>
                                                    <td>{item.extra_charges_remarks}</td>
                                                    <td>
                                                        <button
                                                            style={{ margin: "0px", padding: "2px" }}
                                                            type="button"
                                                            className="bd-none"
                                                            onClick={() => {
                                                                _handleQuoteExtraDeleteClick(item);

                                                            }}
                                                            data-toggle="tooltip" data-placement="top" title="Delete"
                                                        >
                                                            <i className="fa fa-fw fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ]

                                        })}



                                    </tbody>
                                </table>)}

                            
                            <div className=" col-sm-4 mt-3">
                                <input type="submit" name="submit" className="btn btn-primary" value="Add Extra Charges" />
                            </div>
                            </div>
                        </div>
                    </form>                        
                </div>
            </div>
        </>
    );
}