import React, { useState, useEffect } from 'react';
import { requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import CONSTANT from '../../../Services/Constant/user.constants';
import { CustomerBookings } from "../CustomerHistory/CustomerBookings";
import { Tooltip } from 'react-tooltip';

export function QuoteView(props) {
    const [customer, setCustomer] = useState({});
    const [jobsData, setJobsData] = useState({});

    useEffect(() => {
        async function fetchMyAPI() {
            const response = await api.get("api/v1/tsljobData", {
                params: "",
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setJobsData(response.data.data);
            } else {
                setJobsData("");
            }
        }
        fetchMyAPI();
    }, []);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/getCustomerById", {
                params: { id: props.quoteData.customer_id },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setCustomer(response?.data?.data?.[0]);
            }
        }
        fetchMyAPI2();
    }, []);

    return (
        <>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                        <fieldset>
                            <legend>Customer</legend>
                            <div className="row">
                                <div className="col-md-4">
                                    <p><strong>First Name:</strong> <code>{customer.firstname}</code></p>
                                </div>
                                <div className="col-md-4">
                                    <p><strong>Last Name:</strong> <code>{customer.lastname}</code></p>
                                </div>
                                <div className="col-md-4">
                                    <p><strong>Email:</strong> <code>{customer.email}</code></p>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Quote</legend>
                            <div className="row">
                                <div className="col-md-4">
                                    <p><strong>Quote Number:</strong> <code>{props.quoteData.quote_number}</code></p>
                                </div>
                                <div className="col-md-4">
                                    <p><strong>Sales Person:</strong> <code>{props.quoteData.salesperson}</code></p>
                                </div>    
                                
                                <div className="col-md-4">
                                    <p><strong>Potential Cargo Weight:</strong> <code>{props.quoteData.cargo_weight}</code></p>
                                </div>
                                <div className="col-md-4">
                                    <p><strong>Weight Unit:</strong> <code>{props.quoteData.unit}</code></p>
                                </div>
                                <div className="col-md-4">
                                    <p><strong>Origin:</strong> <code>{props.quoteData.origin}</code></p>
                                </div>
                                <div className="col-md-4">
                                    <p><strong>Destination:</strong> <code>{props.quoteData.destination}</code></p>
                                </div>
                                <div className="col-md-4">
                                    <p><strong>Commodity:</strong> <code>{props.quoteData.item_type}</code></p>
                                </div>
                                <div className="col-md-4">
                                    <p><strong>Dangerous:</strong> <code>{props.quoteData.dangerous}</code></p>
                                </div>
                                <div className="col-md-4">
                                    <p><strong>Priority:</strong> <code>{CONSTANT['priority'][props.quoteData.priority]}</code></p>
                                </div>
                                <div className="col-md-4">
                                    <p><strong>Cost:</strong> <code>
                                        ${props.quoteData.bl_charges + props.quoteData.freight + props.quoteData.telux}
                                        <a className="btn btn-warning btn-xsm ms-1" data-tooltip-id={"rate-tooltip-" + props.quoteData.id} data-tooltip-html={
                                            "<span>Bl Charges: $" + props.quoteData.bl_charges + "</span><br/>"
                                            + "<span>Freight: $" + props.quoteData.freight + "</span><br/>"
                                            + "<span>Telux: $" + props.quoteData.telux + "</span>"
                                        }><i className="fa fa-info"></i></a>
                                        <Tooltip id={"rate-tooltip-" + props.quoteData.id} />
                                    </code></p>
                                </div>

                                <div className="col-md-4">
                                    <p><strong>Shipping Line:</strong> <code>{props.quoteData.shipping_line}</code></p>
                                </div>

                                <div className="col-md-4">
                                    <p><strong>Equipment Types:</strong> {
                                        JSON.parse(props.quoteData.equipment_types)?.length
                                        && JSON.parse(props.quoteData.equipment_types).map((val, index) => {
                                            return <span className='badge badge-info me-1' key={index}>{val}</span>
                                        })
                                    }</p>
                                </div>

                                <div className='col-md-4'>
                                    <p><strong>Comments:</strong> <code>{props.quoteData.comments}</code></p>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>Past Bookings</legend>
                            {
                                jobsData.length && customer?.id
                                    ? <CustomerBookings
                                        showCustomer='no'
                                        customer={customer}
                                        jobsData={jobsData}
                                    /> : ''
                            }
                        </fieldset>
                    </div>
                </div>
            </div>
        </>
    );
}