import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "../Layout";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { changeUserPassword } from "../Services/Store/Common/account/account.action";

const Profile = (props) => {
  const currentUser = useSelector((state) => state.userClient);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();

  const validateContactFrom = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  let initialValues = {
    email: email,
    password: '',
    passwordConfirmation: ''
  };

  useEffect(() => {
    setEmail(currentUser.user.email);
  }, [currentUser])


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateContactFrom,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      dispatch(changeUserPassword({ "userId": currentUser.user.id, "password": values.password }));
      resetForm({ 'password': '', 'passwordConfirmation': '' });
    },
  });

  return (
    <>
      <AdminLayout pageHeading="Profile" pageIcon="fa fa-user">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Update Profile</h1>
          <div className="card shadow mb-4">
            <div className="card-body invitation-wrap">
              <div className='row'>
                <div className='col-md-6 offset-md-3'>
                  <div className="form-content mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12 col-xs-12">
                          <input
                            type="text"
                            className="form-control mb-3"
                            disabled
                            {...formik.getFieldProps('email')}
                          />
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <input
                            type="password"
                            className="form-control mb-3"
                            placeholder='password'
                            {...formik.getFieldProps('password')}
                          />
                          {formik.touched.password && formik.errors.password && (
                            <div className="errorMsg text-danger">{formik.errors.password}</div>
                          )}
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <input
                            type="password"
                            className="form-control mb-3"
                            placeholder='Confirm Password'
                            {...formik.getFieldProps('passwordConfirmation')}
                          />
                          {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                            <div className="errorMsg text-danger">{formik.errors.passwordConfirmation}</div>
                          )}
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary">Save</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
}
export default Profile; 