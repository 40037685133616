import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import moment from "moment";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
const libraries = ['places'];

export function BookingForm(props) {
    const inputRefOrigin = useRef();
    const inputRefDestination = useRef();
    const inputRefTruckingLocation = useRef();
    const fields = props.bookingDetails ? props.bookingDetails : '';
    const optionsCarrier = [];
    const optionsJobStatus = [];
    props?.bookingData?.[0].map((data, index) => (
        optionsCarrier.push({ value: data.id, label: data.name })
    ));

    props?.bookingData?.[6].map((data, index) => (
        optionsJobStatus.push({ value: data.id, label: data.name })
    ));
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDm9SqM0fp4sg7KSLBachj-WS7zpQ80Wtk',
        libraries
    });
    const handlePlaceChangedTruckingLocation = () => {
        const [place] = inputRefTruckingLocation.current.getPlaces();
        if (place) {
            formik.setFieldValue("trucking_location", place.formatted_address);
        }
    }
    const handlePlaceChangedOrigin = () => {
        const [place] = inputRefOrigin.current.getPlaces();
        if (place) {
            formik.setFieldValue("origin", place.formatted_address);
        }
    }

    const handlePlaceChangedDestination = () => {
        const [place] = inputRefDestination.current.getPlaces();
        if (place) {
            formik.setFieldValue("destination", place.formatted_address);
          

        }
    }
    const validateContactFrom = Yup.object().shape({
        booking_number: Yup.string().required('Booking Number is required'),
        shipping_line_id: Yup.number().required('Carrier Name is required'),
        service_id: Yup.number().nullable(true),
        seal_number: Yup.string().nullable(true),
        // sale_person_id: Yup.number().nullable(true),
        container_number: Yup.string().nullable(true),
        origin: Yup.string().required('Origin Name is required'),
        destination: Yup.string().required('Destination Name is required'),
        truker_id: Yup.number().nullable(true),
        doc_cutoff_date: Yup.string().nullable(true),
        vgm_cutoff_date: Yup.string().nullable(true),
        cargo_curtoff_date: Yup.date().required('Cargo Cutoff date is required'),
        booking_rollover_date: Yup.string().nullable(true),
        export_document_coordinator_id: Yup.number().nullable(true),
        import_document_coordinator_id: Yup.number().nullable(true),
        job_status_id: Yup.number().nullable(true),
        trucking_location: Yup.string().nullable(true),
        comments: Yup.string().nullable(true),
        need_trucking: Yup.string().nullable(true),
        vessal_name: Yup.string().required("Vessal Name is required"),
        loading_date: Yup.string().nullable(true),
        loading_port: Yup.number().required('Origin port is required'),
        destination_port: Yup.number().required('Destination port is required'),
    });

    let initialValues = {
        booking_number: fields.booking_number ?? '',
        shipping_line_id: fields.shipping_line_id ?? '',
        service_id: fields.service_id ?? '',
        seal_number: fields.seal_number ?? '',
        // sale_person_id: fields.sale_person_id ?? '',
        container_number: fields.container_number ?? '',
        origin: fields.origin ?? '',
        destination: fields.destination ?? '',
        loading_port: fields.loading_port ?? '',
        destination_port: fields.destination_port ?? '',
        truker_id: fields.truker_id ?? '',
        doc_cutoff_date: fields.doc_cutoff_date ? moment(fields.doc_cutoff_date).format('yyyy-MM-DD') : '',
        vgm_cutoff_date: fields.vgm_cutoff_date ? moment(fields.vgm_cutoff_date).format('yyyy-MM-DD') : '',
        cargo_curtoff_date: fields.cargo_curtoff_date ? moment(fields.cargo_curtoff_date).format('yyyy-MM-DD') : '',
        booking_rollover_date: fields.booking_rollover_date ? moment(fields.booking_rollover_date).format('yyyy-MM-DD') : '',
        export_document_coordinator_id: fields.export_document_coordinator_id ?? '',
        import_document_coordinator_id: fields.import_document_coordinator_id ?? '',
        job_status_id: fields.job_status_id ?? '',
        trucking_location: fields.trucking_location ?? '',
        comments: fields.comments ?? '',
        need_trucking: fields.need_trucking ?? '',
        vessal_name: fields.vessal_name ?? '',
        loading_date: fields.loading_date ? moment(fields.loading_date).format('yyyy-MM-DD') : null,
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            const postData = {
                booking_number: values.booking_number,
                shipping_line_id: values.shipping_line_id,
                service_id: values.service_id,
                seal_number: values.seal_number,
                // sale_person_id: values.sale_person_id,
                container_number: values.container_number,
                origin: values.origin,
                destination: values.destination,
                loading_port: values.loading_port,
                destination_port: values.destination_port,
                truker_id: values.truker_id,
                doc_cutoff_date: values.doc_cutoff_date ? moment(values.doc_cutoff_date).format("YYYY-MM-DD") : null,
                vgm_cutoff_date: values.vgm_cutoff_date ? moment(values.vgm_cutoff_date).format("YYYY-MM-DD") : null,
                cargo_curtoff_date: values.cargo_curtoff_date ? moment(values.cargo_curtoff_date).format("YYYY-MM-DD") : null,
                booking_rollover_date: values.booking_rollover_date ? moment(values.booking_rollover_date).format("YYYY-MM-DD") : null,
                export_document_coordinator_id: values.export_document_coordinator_id,
                import_document_coordinator_id: values.import_document_coordinator_id,
                job_status_id: values.job_status_id,
                trucking_location: values.trucking_location,
                comments: values.comments,
                loading_date: values.loading_date ? moment(values.loading_date).format("YYYY-MM-DD") : null,
                need_trucking: values.need_trucking,
                vessal_name: values.vessal_name,
            }
            if (fields?.id) {
                props.editBookingDetails(postData, fields.id);
            } else {
                props.addBookingDetails(postData);
            }
        },
    });
    const optionsOrigin = [];
    const optionsDestination = [];
    props?.bookingData?.[8].map((data, index) => (
        optionsOrigin.push({ value: data.id, label: data.name + ', ' + data.country })
    ));

    props?.bookingData?.[9].map((data, index) => (
        optionsDestination.push({ value: data.id, label: data.name + ', ' + data.country })
    ));

    const handleOriginSelect = e => {
        formik.setFieldValue("loading_port", e.value);
    };

    const handleDestinationSelect = e => {
        formik.setFieldValue("destination_port", e.value);
    };
    return (
        <>
            <div className="modal-body">
                <form onSubmit={formik.handleSubmit} className='invitation-wrap form-content'>
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <label>Booking Number*</label>
                            <input
                                className="form-control"
                                type="text"
                                name="booking_number"
                                id="booking_number"
                                {...formik.getFieldProps('booking_number')}
                            />
                            {formik.touched.booking_number && formik.errors.booking_number && (
                                <div className="errorMsg text-danger">{formik.errors.booking_number}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Carrier</label>
                            <Select
                                name="shipping_line_id"
                                options={optionsCarrier}
                                value={optionsCarrier.find(function (option) {
                                    return option.value === formik.values.shipping_line_id;
                                })}
                                onChange={(selectedOption) =>
                                    formik.setFieldValue(
                                        'shipping_line_id',
                                        selectedOption.value,
                                    )
                                }
                                label="Single select"
                            />
                            {formik.touched.shipping_line_id && formik.errors.shipping_line_id && (
                                <div className="errorMsg text-danger">{formik.errors.shipping_line_id}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Service</label>
                            <select
                                id="service_id"
                                name="service_id"
                                className="form-control"
                                {...formik.getFieldProps('service_id')}
                            >
                                <option>--Select Service--</option>
                                {props.bookingData ?
                                    props.bookingData[1].map((data, index) => (
                                        <option value={data.id} key={index.toString()}>{data.name}</option>
                                    )) : ''}
                            </select>
                            {formik.touched.service_id && formik.errors.service_id && (
                                <div className="errorMsg text-danger">{formik.errors.service_id}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Seal No.</label>
                            <input
                                className="form-control"
                                type="text"
                                name="seal_number"
                                id="seal_number"
                                {...formik.getFieldProps('seal_number')}
                            />
                            {formik.touched.seal_number && formik.errors.seal_number && (
                                <div className="errorMsg text-danger">{formik.errors.seal_number}</div>
                            )}
                        </div>
                        {/* <div className="col-md-3 col-6">
                            <label>Sale Person</label>
                            <select
                                id="sale_person_id"
                                name="sale_person_id"
                                className="form-control"
                                {...formik.getFieldProps('sale_person_id')}
                            >
                                <option>--Select Sale Person--</option>
                                {props.bookingData ?
                                    props.bookingData[7].map((data, index) => (
                                        data.role === 'sales' && <option value={data.id} key={index.toString()}>{data.firstname + (data.lastname ? ' ' + data.lastname : '')}</option>
                                    )) : ''}
                            </select>
                            {formik.touched.sale_person_id && formik.errors.sale_person_id && (
                                <div className="errorMsg text-danger">{formik.errors.sale_person_id}</div>
                            )}
                        </div> */}
                        <div className="col-md-3 col-6">
                            <label>Container No.</label>
                            <input
                                className="form-control"
                                type="text"
                                name="container_number"
                                id="container_number"
                                {...formik.getFieldProps('container_number')}
                            />
                            {formik.touched.container_number && formik.errors.container_number && (
                                <div className="errorMsg text-danger">{formik.errors.container_number}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Origin</label>
                            {isLoaded
                                && <StandaloneSearchBox
                                    onLoad={ref => inputRefOrigin.current = ref}
                                    onPlacesChanged={handlePlaceChangedOrigin}
                                >
                                    <input className="form-control"
                                        name="origin"
                                        id="origin"
                                        type='text'
                                        placeholder='Origin'
                                        {...formik.getFieldProps('origin')}
                                    />
                                </StandaloneSearchBox>}
                           
                            {formik.touched.origin && formik.errors.origin && (
                                <div className="errorMsg text-danger">{formik.errors.origin}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Loading Port</label>
                            <Select
                                name="loading_port"
                                id="loading_port"
                                options={optionsOrigin}
                                onChange={handleOriginSelect}
                                value={optionsOrigin.filter(function (option) {
                                    return option.value == formik.values.loading_port;
                                })}
                                label="Single select"
                            />
                            {formik.touched.loading_port && formik.errors.loading_port && (
                                <div className="errorMsg text-danger">{formik.errors.loading_port}</div>
                            )}
                        </div>

                        <div className="col-md-3 col-6">
                            <label>Destination</label>
                            {isLoaded
                                && <StandaloneSearchBox
                                    onLoad={ref => inputRefDestination.current = ref}
                                    onPlacesChanged={handlePlaceChangedDestination}
                                >
                                    <input className="form-control"
                                        name="destination"
                                        id="destination"
                                        placeholder='Destination'
                                        type='text'
                                        {...formik.getFieldProps('destination')}
                                    />

                                </StandaloneSearchBox>}
                            {formik.touched.destination && formik.errors.destination && (
                                <div className="errorMsg text-danger">{formik.errors.destination}</div>
                            )}
                        </div>

                        <div className="col-md-3 col-6">
                            <label>Destination Port</label>
                            <Select
                                name="destination_port"
                                id="destination_port"
                                options={optionsDestination}
                                onChange={handleDestinationSelect}
                                value={optionsDestination.filter(function (option) {
                                    return option.value == formik.values.destination_port;
                                })}
                                label="Single select"
                            />
                            {formik.touched.destination_port && formik.errors.destination_port && (
                                <div className="errorMsg text-danger">{formik.errors.destination_port}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Trucker Name</label>
                            <select className="form-control"
                                name="truker_id"
                                {...formik.getFieldProps('truker_id')}
                            >
                                <option>--Select Trucker--</option>
                                {props.bookingData ?
                                    props.bookingData[2].map((data, index) => (
                                        <option value={data.id} key={index.toString()}>{data.name}</option>
                                    )) : ''}
                            </select>
                            {formik.touched.truker_id && formik.errors.truker_id && (
                                <div className="errorMsg text-danger">{formik.errors.truker_id}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Doc Cutoff Date</label>
                            <input className="form-control"
                                type="date"
                                name="doc_cutoff_date"
                                id="doc_cutoff_date"
                                {...formik.getFieldProps('doc_cutoff_date')}
                            />
                            {formik.touched.doc_cutoff_date && formik.errors.doc_cutoff_date && (
                                <div className="errorMsg text-danger">{formik.errors.doc_cutoff_date}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>VGM Cutoff Date</label>
                            <input className="form-control"
                                type="date"
                                name="vgm_cutoff_date"
                                id="vgm_cutoff_date"
                                {...formik.getFieldProps('vgm_cutoff_date')}
                            />
                            {formik.touched.vgm_cutoff_date && formik.errors.vgm_cutoff_date && (
                                <div className="errorMsg text-danger">{formik.errors.vgm_cutoff_date}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Cargo Cutoff Date</label>
                            <input className="form-control"
                                type="date"
                                name="cargo_curtoff_date"
                                id="cargo_curtoff_date"
                                {...formik.getFieldProps('cargo_curtoff_date')}
                            />
                            {formik.touched.cargo_curtoff_date && formik.errors.cargo_curtoff_date && (
                                <div className="errorMsg text-danger">{formik.errors.cargo_curtoff_date}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label> Booking Rollover Date</label>
                            <input className="form-control"
                                type="date"
                                name="booking_rollover_date"
                                id="booking_rollover_date"
                                {...formik.getFieldProps('booking_rollover_date')}
                            />
                            {formik.touched.booking_rollover_date && formik.errors.booking_rollover_date && (
                                <div className="errorMsg text-danger">{formik.errors.booking_rollover_date}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Export Document Coordinator</label>
                            <select className="form-control"
                                name="export_document_coordinator_id"
                                id="export_document_coordinator_id"
                                {...formik.getFieldProps('export_document_coordinator_id')}
                            >
                                <option>--Export Document Coordinator--</option>
                                {props.bookingData ?
                                    props.bookingData[4].map((data, index) => (
                                  <option value={data.id} key={index.toString()}>{data.name}</option>
                                    )) : ''}
                            </select>
                            {formik.touched.export_document_coordinator_id && formik.errors.export_document_coordinator_id && (
                                <div className="errorMsg text-danger">{formik.errors.export_document_coordinator_id}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Import Document Coordinator</label>
                            <select
                                className="form-control"
                                name="import_document_coordinator_id"
                                id="import_document_coordinator_id"
                                {...formik.getFieldProps('import_document_coordinator_id')}

                            >
                                <option value="">--Import Document Coordinator--</option>
                                {props.bookingData ?
                                    props.bookingData[5].map((data, index) => (
                                      <option value={data.id} key={index.toString()}>{data.name}</option>
                                    )) : ''}
                            </select>
                            {formik.touched.import_document_coordinator_id && formik.errors.import_document_coordinator_id && (
                                <div className="errorMsg text-danger">{formik.errors.import_document_coordinator_id}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Job Status</label>
                            <Select
                                name="job_status_id"
                                options={optionsJobStatus}
                                value={optionsJobStatus.find(function (option) {
                                    return option.value === initialValues.job_status_id;
                                })}
                                onChange={(selectedOption) =>
                                    formik.setFieldValue(
                                        'job_status_id',
                                        selectedOption.value,
                                    )
                                }
                                label="Single select"
                            />
                            {formik.touched.job_status_id && formik.errors.job_status_id && (
                                <div className="errorMsg text-danger">{formik.errors.job_status_id}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Loading Date</label>
                            <input className="form-control"
                                type="date"
                                name="loading_date"
                                id="loading_date"
                                {...formik.getFieldProps('loading_date')}
                            />
                            {formik.touched.loading_date && formik.errors.loading_date && (
                                <div className="errorMsg text-danger">{formik.errors.loading_date}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Vessal Name</label>
                            <input className="form-control"
                                name="vessal_name"
                                id="vessal_name"
                                {...formik.getFieldProps('vessal_name')}
                            />
                            {formik.touched.vessal_name && formik.errors.vessal_name && (
                                <div className="errorMsg text-danger">{formik.errors.vessal_name}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Need trucking</label>
                            <select className="form-control"
                                name="need_trucking"
                                id="need_trucking"
                                {...formik.getFieldProps('need_trucking')}
                            >
                                <option>--Select--</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            {formik.touched.need_trucking && formik.errors.need_trucking && (
                                <div className="errorMsg text-danger">{formik.errors.need_trucking}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Trucking Location</label>
                            {isLoaded
                                &&
                                <StandaloneSearchBox
                                    onLoad={ref => inputRefTruckingLocation.current = ref}
                                    onPlacesChanged={handlePlaceChangedTruckingLocation}
                                >
                                    <input
                                        name="trucking_location"
                                        type="text"
                                        className="form-control"
                                        id="trucking_location"
                                        placeholder='Trucking Location'
                                        {...formik.getFieldProps('trucking_location')}
                                    />
                                </StandaloneSearchBox>
                            }
                            {formik.touched.trucking_location && formik.errors.trucking_location && (
                                <div className="errorMsg text-danger">{formik.errors.trucking_location}</div>
                            )}
                        </div>
                        <div className="col-12">
                            <label>Comments</label>
                            <textarea
                                className="form-control"
                                name="comments"
                                id="comments"
                                {...formik.getFieldProps('comments')}
                            ></textarea>
                            {formik.touched.comments && formik.errors.comments && (
                                <div className="errorMsg text-danger">{formik.errors.comments}</div>
                            )}
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-12">
                            <input type="submit" name="submit" className="btn btn-primary" value={fields?.id ? "Save Booking" : "Save Booking"} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}