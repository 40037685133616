import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

const CustomerPermissions = ({ allowedRoles }) => {

    const currentUser = useSelector((state) => state.user);
    const location = useLocation();

    if(!currentUser.user || !sessionStorage.getItem("customerToken")) {
        sessionStorage.removeItem('customerToken');
        return <Navigate to="/customer/login" />
    }

    return allowedRoles.includes(currentUser.user.role) ? (
        <Outlet />
    ) : currentUser.user?.firstName ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
        <Navigate to="/customer/login" state={{ from: location }} replace />
    );
};

export default CustomerPermissions;