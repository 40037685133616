import React, { useState } from 'react';
export function ChangePasswordForm(props) {

  const [fields, setFields] = useState(props.userDetails ? props.userDetails : {});
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    let data = fields;
    data[event.target.name] = event.target.value;
    setFields({ ...data });
  };

  const validateRegistration = (fields) => {

    let errors = {};
    let formIsValid = true;

    if ((!fields["password"] || fields["password"].trim() === "")) {
      formIsValid = false;
      errors["password"] = "*Please enter your Password.";
    } else if (fields["password"].length < 4) {
      formIsValid = false;
      errors["password"] = "*Please enter minimum 5 characters";
    }
    if ((!fields["confirm_password"] || fields["confirm_password"].trim() === "")) {
      formIsValid = false;
      errors["confirm_password"] = "*Please enter your Password again.";
    } else if (fields["confirm_password"].length < 4) {
      formIsValid = false;
      errors["confirm_password"] = "*Please enter minimum 5 characters";
    }

    if ((fields["password"].trim() !== fields["confirm_password"].trim())) {
      formIsValid = false;
      errors["password"] = "*Please New password and Confirm password does not matched.";
    }

    return {
      errors: errors,
      formIsValid: formIsValid,
    };
  };

  const _validateForm = () => {
    let formFields = fields;
    let response = validateRegistration(formFields);
    setErrors(response.errors);
    return response.formIsValid;
  };

  const updateUserDetails = async (event) => {
    event.preventDefault();
    if (_validateForm()) {
      if (fields) {
        if (props.userId) {
          const params = {
            password: fields.password,
            userId: props.userId
          }
          props.changeUserPassword(params)
        }
      }
    }
  }

  return (
    <>
      <div className="modal-body">
        <div className="login-wrap-new">
          <div className="form-content">
            <form onSubmit={(event) => updateUserDetails(event)}>
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    name="password"
                    value={fields?.password}
                    onChange={(event) => handleChange(event)}
                  />
                  {errors.password ? (
                    <div className="errorMsg text-danger">
                      {errors.password}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-12 col-xs-12">
                  <input
                    type="password"
                    className="form-control"
                    id="confirm_password"
                    placeholder="Comfirm Password"
                    name="confirm_password"
                    value={fields?.confirm_password}
                    onChange={(event) => handleChange(event)}
                  />
                  {errors.confirm_password ? (
                    <div className="errorMsg text-danger">
                      {errors.confirm_password}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <button type="submit" className="btn btn-primary">Save Password</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}