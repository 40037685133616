import axios from 'axios';
import { USER_ACTION_TYPE } from '../../Constant/user.constants';
import { API_URL } from '../../../Config/config';
import { getNotificationList } from "../Common/notification/notification.action";
import { displayErrorMessage } from '../../Helpers/helper';

export const checkLoginUser = (postData) => async dispatch => {

    try {
        const headers = {
            'Access-Control-Allow-Origin': '*',
        }
        const response = await axios.post(API_URL + 'api/v1/tslClientLogin', postData, { headers });

        if (response.data.errorCode === 0) {
            sessionStorage.setItem("clientToken", JSON.stringify(response.data.token));
            sessionStorage.removeItem("customerToken");
            localStorage.setItem("user", JSON.stringify(response.data.user));
            localStorage.setItem("userId", response.data.user.id);
            dispatch(getNotificationList({'user_id': response.data.user.id}));
            return dispatch({
                type: USER_ACTION_TYPE.SET_CURRENT_CLIENT_SUCESS,
                payload: { user: response.data.user },
            });
        } else if (response.data.errorCode === 1 || response.data.errorCode === 2) {
            displayErrorMessage(response.data.errorMessage);
            return dispatch({
                type: USER_ACTION_TYPE.SET_CURRENT_CLIENT_ERROR,
                payload: { error: response.data.errorMessage },
            });
        }
    } catch (err) {
        return dispatch({
            type: USER_ACTION_TYPE.SET_CURRENT_CLIENT_ERROR,
            payload: { error: "Undefined Error, Please try again." },
        });
    }
};

export const logoutUser = () => async dispatch => {
    return dispatch({
        type: USER_ACTION_TYPE.SET_CURRENT_CLIENT_SUCESS,
        payload: { user: null },
    });
};