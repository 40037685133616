import axios from 'axios';
import { API_URL } from '../../Config/config';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const api =  axios.create({
    baseURL: API_URL,
    responseType: "json"
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log(error.response);
        if(error.response.status === 400 || error.response.status === 401) {
            if(error?.response?.data?.errorCode === 4) {
                sessionStorage.removeItem('clientToken');
                sessionStorage.removeItem('customerToken');
                localStorage.removeItem('user');
                window.location.href = '/login';
            } else if(error?.response?.data?.errorCode === 6) {
                window.location.href = '/unauthorized';
            }

        }
    }
)

export default api;