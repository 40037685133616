import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { App as SendbirdApp } from "sendbird-uikit";
import AdminLayout from "../../../Layout";
import { contactList } from "../../../Services/Store/Common/contact/contact.selector";
import moment from "moment";
const Contact = () => {
  const currentUser = useSelector((state) => state.userClient);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userRecordsData, setUserRecordsData] = useState(0);
  const [getClientList, setGetClientList] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contactListResult = useSelector(contactList);
  // const mainClientresponse = useSelector(mainClient);
  const [searchType, setSearchType] = useState("username");

  const location = useLocation();
  const APP_ID = "DCF2D7A4-6FAE-44B0-BC0D-DA8C60CD8009";
  const USER_ID =`${currentUser.user.id}`; 

  //const USER_ID = "42"; 
  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }
  
    
  }, []);

  


  

  


  return (
    <>
      <AdminLayout pageHeading="Chat" pageIcon="fa fa-address-book">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Chat List</h1>
          <div className="mb-4">
            <div className="table-responsive">
              <div className="row">
                <div className="col-12">
                  <SendbirdApp appId={APP_ID} userId={USER_ID} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout >
    </>
  );
};
export default Contact;
