import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
export function TaskForm(props) {

    const [fields, setFields] = useState(props.userDetails ? props.userDetails : {});
    const [users, setUsers] = useState([]);
    const validateTaskFrom = Yup.object().shape({
        name: Yup.string().required('Name is required').max(255),
        due_date: Yup.string().required('Due date is required'),
        assigned_to: props?.currentUser?.user?.role === "admin" ? Yup.number().required('Assign to is required') : Yup.number().nullable(true),
        status: Yup.string().required('Status is required'),
    });

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/tslUserListById", {
                params: "",
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setUsers(response?.data?.data);
            } else {
                setUsers("");
            }
        }
        fetchMyAPI2();
    }, []);

    let initialValues = {};
    if (fields) {
        initialValues = {
            name: fields.name,
            description: fields.description,
            due_date: fields.due_date,
            assigned_to: fields.assigned_to,
            status: fields.status,
            user_comment: fields.user_comment,
        }
    } else {
        initialValues = {
            name: '',
            description: "",
            due_date: "",
            assigned_to: "",
            status: "",
            user_comment: ""
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateTaskFrom,
        onSubmit: (values) => {
            if (fields?.id) {
                const postData = {
                    name: values.name,
                    description: values.description,
                    due_date: values.due_date,
                    assigned_to: props?.currentUser ? props.currentUser.user.id : values.assigned_to,
                    status: values.status,
                    id: fields.id,
                    user_comment: fields.user_comment,
                }
                props.editUserDetails(postData);
            } else {
                const params = {
                    name: values.name,
                    description: values.description,
                    due_date: values.due_date,
                    assigned_to: props?.currentUser ? props.currentUser.user.id : values.assigned_to,
                    status: values.status,
                    user_comment: null
                }
                props.addUserDetails(params)
            }
        },
    });

    return (
        <>
            <div className="modal-body invitation-wrap">
                <div className="login-wrap-new">
                    <div className="form-content">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-md-4 col-6">
                                    <label>Subject</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        {...formik.getFieldProps('name')}
                                    />

                                    {formik.touched.name && formik.errors.name && (
                                        <div className="errorMsg text-danger">{formik.errors.name}</div>
                                    )}

                                </div>
                                <div className="col-md-4 col-6">
                                    <label>Due Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="due_date"
                                        name="due_date"
                                        {...formik.getFieldProps('due_date')}
                                    />
                                    {formik.touched.due_date && formik.errors.due_date && (
                                        <div className="errorMsg text-danger">{formik.errors.due_date}</div>
                                    )}

                                </div>
                                {["admin"].includes(props?.currentUser?.user?.role) ?
                                    <div className="col-md-4 col-6">
                                        <label>Assigned To</label>
                                        <select
                                            id="assigned_to"
                                            name="assigned_to"
                                            className="form-control"
                                            {...formik.getFieldProps('assigned_to')}
                                        >

                                            <option>--Select--</option>

                                            {users ?
                                                users.map((data, index) => (
                                                    <option value={data.id} key={index.toString()}>{data.firstname + (data.lastname ? ' ' + data.lastname : '')}</option>

                                                )) : ''}
                                        </select>


                                        {formik.touched.assigned_to && formik.errors.assigned_to && (
                                            <div className="errorMsg text-danger">{formik.errors.assigned_to}</div>
                                        )}

                                    </div> : ""}
                                <div className="col-md-4 col-6">
                                    <label>Status</label>
                                    <select className="form-control"
                                        name="status"
                                        id="status"
                                        {...formik.getFieldProps('status')}
                                    >
                                        <option>--Select--</option>
                                        <option value="inprogress">Inprogress</option>
                                        <option value="complete">Complete</option>
                                    </select>

                                    {formik.touched.status && formik.errors.status && (
                                        <div className="errorMsg text-danger">{formik.errors.status}</div>
                                    )}

                                </div>
                                <div className="col-md-4 col-6">
                                    <label>Description</label>
                                    <textarea
                                        className="form-control"
                                        name="description"
                                        id="description"
                                        {...formik.getFieldProps('description')}
                                    ></textarea>
                                    {formik.touched.description && formik.errors.description && (
                                        <div className="errorMsg text-danger">{formik.errors.description}</div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">
                                        {fields.id ? 'Save Task' : 'Save Task'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}