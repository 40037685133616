import axios from "axios";
import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import {  displayErrorMessage, displaySuccessMessage,requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";


export const deleteTruckerName = (id) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteTruckerName",{id},
                                   { headers:requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      const truckernameList = {};
      const resultOutput = getState()
        .truckernameList.truckernameList.result.map((data) => data)
        .filter((data) => data.id !== id);
      truckernameList.result = resultOutput;
      truckernameList.records = getState().truckernameList.truckernameList.records - 1;
      truckernameList.errorCode = 0;
      displaySuccessMessage("truckerNameDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_SUCESS,
        payload: truckernameList,
      });
    } else {
      displayErrorMessage("truckerNameDeleteError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_ERROR });
  }
};

export const getTruckerNameListList= (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslTruckerNameList", {
                                    params: postData,
                                    headers:requestTokenHeader()
                                  });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    return dispatch({ type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_ERROR });
  }
};



export const addTruckerName = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddTruckerName",postData,
      { headers:requestTokenHeader() });
    if (response.data.errorCode === 0) {
      const resultOutput = getState().truckernameList.truckernameList.result;
      resultOutput.push(response.data.result);
      const truckernameList = {};
      truckernameList.result = resultOutput;
      truckernameList.records = getState().truckernameList.truckernameList.records + 1;
      truckernameList.errorCode = 0;
      displaySuccessMessage("truckerNameAdded");
      return dispatch({
        type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_SUCESS,
        payload: truckernameList,
      });
    } else {
      displayErrorMessage("truckerNameAddedError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_ERROR });
  }
};


export const editTruckerName = (postData, searchIds) => 
 {
 return async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslEditTruckerName",postData,
    {
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("truckerNameUpdated");
      dispatch(getTruckerNameListList({ searchIds }));

    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      displayErrorMessage("truckerNameUpdatedError");
      
       dispatch({
        type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
     displayErrorMessage(err.name);
     dispatch({ type: USER_ACTION_TYPE.SET_TRUCKERNAME_LIST_ERROR });
  }
 }
};