import React from 'react';
import "./invoice_pdf.css";
export default function InvoicePdfCad(props) {
    let total_paid = 0;
    let total_amount = 0;

    if (parseFloat(props.fields?.[3]?.[0]?.cq_cad) > 0) {
        total_amount += parseFloat(props.fields?.[3]?.[0]?.cq_cad);
    }

    if (parseFloat(props.fields?.[3]?.[0]?.ctc_cad) > 0) {
        total_amount += parseFloat(props.fields?.[3]?.[0]?.ctc_cad);
    }

    if ((props.fields?.[3]?.[0]?.chc_cad) > 0) {
        total_amount += parseFloat(props.fields?.[3]?.[0]?.chc_cad);
    }

    if (parseFloat(props.fields?.[3]?.[0]?.esm_cad) > 0) {
        total_amount += parseFloat(props.fields?.[3]?.[0]?.esm_cad);
    }
    {
        props.receivableData && props.receivableData.length > 0 && props.receivableData.map((item, i) => {


            total_paid += item.cad_amount;

        })
    }


    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "-";

    const current_date = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;


    const getRandomId = (min = 0, max = 500000) => {
        min = Math.ceil(100000);
        max = Math.floor(500000);
        const num = Math.floor(Math.random() * (max - min + 1)) + min;
        return num.toString().padStart(6, "0")
    };

    return (
        <div id="container" style={{ display: props.displayItem == false ? "none" : "block" }}>
            <div id="wrapper1">
                <h2 style={{ margin: "0px", padding: "0px", display: "none", textAlign: "center", marginBottom: "10px", fontWeight: "bold", fontSize: "18px" }}>Invoice</h2>
                <div id="page_top_bar_1">

                    <div style={{ width: "50%", float: "left" }} className='mobFullw'>
                        <img src="http://golden-test.com/assets/images/logo.png" style={{ marginLeft: "10px", marginTop: "0px" }} />
                    </div>
                    <div id='address-container' style={{ width: "50%" }} className='mobFullw'>
                        <p id="text-p">5945 AIRPORT ROAD, SUITE 185 MISSISSAUGA ON L4V1R9</p>
                        <p id="text-p">Tel: 905 672 9077, 647 818 9791 Fax: 905 672 9071</p>
                    </div>
                </div>
                <br />

                <div id="page_top_bar_2" style={{ backgroundColor: "#fff" }}>

                    <div style={{ width: "50%", float: "left" }} className='mobFullw'>
                        <p id="heading-p">Invoice To:</p>
                        <p id="text-p">{props.customerData?.firstname + (props.customerData?.lastname ? ' ' + props.customerData?.lastname : '')}</p>
                        <p id="text-p">{props.customerData?.email}</p>
                        <p id="text-p">{props.customerData?.company_address === "undefined" ? "" : props.customerData?.company_address}</p>
                    </div>
                    <div style={{ width: "50%", float: "left", fontSize: "10px", textAlign: "right" }} className='mobFullw'>
                        <table id='table_top' cellPadding='0' cellSpacing='0'>
                            <tr>
                                <th className="brd-btm">Invoice Number:</th>
                                <td className="brd-lft brd-btm">{getRandomId()}</td>
                            </tr>
                            <tr>
                                <th className="brd-btm">Date:</th>
                                <td className="brd-lft brd-btm">{current_date}</td>
                            </tr>
                            <tr>
                                <th>GST Registration</th>
                                <td className="brd-lft "># 754342293RM0001</td>
                            </tr>
                        </table>
                    </div>
                </div>


                <div id="page_top_bar_2" style={{ backgroundColor: "#fff" }}>

                    <div style={{ width: "50%", float: "left" }} className='mobFullw'>
                        <p id="heading-p">Shipper:</p>
                        <p id="text-p">{props.shipper}</p>
                        <p id="heading-p">Consignee:</p>
                        <p id="text-p">{props.consignee}</p>
                    </div>
                    <div style={{ width: "50%", float: "left", fontSize: "10px", textAlign: "right" }} className='mobFullw'>
                        <table id='table_top' cellPadding='0' cellSpacing='0'>
                            <tr>
                                <th className="brd-btm">BKG/BL #:</th>
                                <th className="brd-lft brd-btm">Booking No:</th>
                                <th className="brd-lft brd-btm brd-rit">Origin:</th>
                                <th className="brd-btm">Destination:</th>

                            </tr>
                            <tr>

                                <td className="brd-btm">{props.jobsDetails.bl_number}</td>
                                <td className="brd-lft brd-btm">{props.jobsDetails.job_number}</td>
                                <td className="brd-lft brd-btm brd-rit">   {(props.jobsDetails.origin).length < 35
                                    ? `${props.jobsDetails.origin}`
                                    : `${(props.jobsDetails.origin).substring(0, 15)}...`}
                                </td>
                                <td className="brd-btm">  {(props.jobsDetails.destination).length < 35
                                    ? `${props.jobsDetails.destination}`
                                    : `${(props.jobsDetails.destination).substring(0, 15)}...`}
                                </td>

                            </tr>
                            <tr>
                                <th className="">CNTR #</th>
                                <td colSpan='3' className="brd-lft" style={{ textAlign: 'left' }}>{props.jobsDetails.container_number}</td>
                            </tr>



                        </table>
                    </div>
                </div>


                <div id="page_top_bar_2" style={{ backgroundColor: "#fff" }}>
                    <h5 id='item-top-heading'>FULL DESCRIPTION OF GOODS</h5>
                    <div style={{ float: "left", width: "50%", fontSize: "12px" }}>
                        <p id="heading-p">  No of Pcs: </p>
                        <p style={{ fontWeight: "bold" }} id="text-p">{props.service}</p>
                    </div>
                    <div style={{ float: "left", width: "50%", textAlign: "right", fontSize: "12px" }}>
                        <p id="heading-p"> STD</p>
                        <p style={{ fontWeight: "bold" }} id="text-p">{props.jobsDetails.commodity}</p>
                    </div>




                    <div style={{ width: "100%", float: "left", fontsize: "10px" }}>
                        <table id='total_amt' cellPadding='0' cellSpacing='0' style={{ width: "100%" }}>

                            <tr>
                                <th style={{ backgroundColor: "#f7f7f7", fontWeight: "bold" }} className='brd-btm'>Description</th>
                                <th style={{ backgroundColor: "#f7f7f7", fontWeight: "bold", textAlign: "right", paddingRight: "20px" }} className=' brd-lft brd-btm'>Total</th>
                            </tr>

                            <tr>
                                <th className="brd-btm">{props.fields?.[3]?.[0]?.invoice_documentation}</th>
                                <td className=" brd-lft brd-btm">{props.fields?.[3]?.[0]?.cq_cad} CAD</td>
                            </tr>


                            {props.fields?.[3]?.[0]?.ctc_amount > 0 && <tr>
                                <th className="brd-btm">Trucking Charges</th>
                                <td className="brd-lft brd-btm">{props.fields?.[3]?.[0]?.ctc_cad}</td>
                            </tr>}


                            {props.fields?.[3]?.[0]?.chc_amount > 0 && <tr>
                                <th className="brd-btm">Custom Hold Charges</th>
                                <td className=" brd-lft brd-btm">{props.fields?.[3]?.[0]?.chc_cad}</td>
                            </tr>}


                            {props.fields?.[3]?.[0]?.esm_amount > 0 && <tr>
                                <th className="brd-btm">Scale/Extra Move</th>
                                <td className=" brd-lft brd-btm">{props.fields?.[3]?.[0]?.esm_cad}</td>
                            </tr>}


                            {props.fields?.[3]?.[0]?.manifest_amount > 0 && <tr>
                                <th className="brd-btm">Manifest Correction</th>
                                <td className=" brd-lft brd-btm">{props.fields?.[3]?.[0]?.manifest_cad}</td>
                            </tr>}



                            {props.extraCharges != "" &&
                                <tr>
                                    <th className="brd-btm">Extra Charges <br />
                                        <table width='300' id='total_amt' style={{ borderBottom: "none" }} cellPadding='0' cellSpacing='0'>
                                            {props.extraCharges != "" && props.extraCharges.map((item, index, arr) => {
                                                total_amount += item.usd_amount;
                                                return (<tr>
                                                    <th style={{ backgroundColor: "uppercase" }} className="brd-btm">{item.service_name}</th>
                                                    <td className="brd-btm brd-lft">{item.cad_amount}</td>
                                                </tr>)

                                            })}



                                        </table>
                                    </th>
                                    <td className=" brd-lft brd-btm"></td>
                                </tr>}



                            <tr>
                                <th className="brd-btm"><b>Total Amount</b></th>
                                <td className=" brd-lft brd-btm"><b>{total_amount} CAD</b></td>
                            </tr>

                            <tr>
                                <th className="brd-btm"><b>Amount Paid</b></th>



                                <td className=" brd-lft brd-btm"><b>{total_paid} CAD</b></td>
                            </tr>

                            <tr>
                                <th className="brd-btm"><b>Balance</b></th>
                                <td className=" brd-lft brd-btm"><b>{(total_amount - total_paid) > 0 ? (total_amount - total_paid).toFixed(2) : (total_amount - total_paid)} CAD</b></td>
                            </tr>
                        </table>
                    </div>
                </div>


                <div id="page_top_bar_2" style={{ backgroundColor: "#fff" }}>

                    <div style={{ width: "100%", marginLeft: "10px", float: "left", fontzize: "10px" }}>
                        <div className='bankaddressbox'>
                            <h3>USD BANK DETAILS</h3>
                            <p>
                                Bank Name: HSBC Bank Canada<br />
                                Branch Address: 4550 Hurontario Street
                                Mississauga, Ontario, L5R 4E4

                            </p>
                            <p>
                                Account Name: Golden Freight Forwarding and Marketing Inc.<br />
                                185-5945 Airport rd.
                                Mississauga, Ontario, L4V IR9
                                <br />Tel: (905) 672-0977 ; Fax : 905 672 9071<br />
                            </p>
                            <p style={{ marginBottom: "0px", marginTop: "5px" }}><span><b>Transit No: 10052</b></span></p>
                            <p style={{ marginBottom: "0px" }}><span><b>Institution No: 016</b></span></p>
                            <p style={{ marginBottom: "0px" }}><span><b>Account No: 588831070</b></span></p>
                            <p style={{ marginBottom: "0px" }}><span>SWIFT CODE: HKBCCATT</span></p>
                        </div>

                        <div className='bankaddressbox'>
                            <h3>CAD BANK DETAILS</h3>
                            <p>
                                Bank Name: HSBC Bank Canada<br />
                                4550 Hurontario Street
                                Mississauga, Ontario, L5R 4E4
                            </p>
                            <p>
                                Account Name: Golden Freight Forwarding and Marketing Inc.<br />
                                185-5945 Airport rd.
                                Mississauga, Ontario, L4V IR9
                                <br />Tel: (905) 672-0977 ; Fax : 905 672 9071<br />
                            </p>
                            <p style={{ marginBottom: "0px", marginTop: "5px" }}><span><b>Transit No: 10052</b></span></p>
                            <p style={{ marginBottom: "0px" }}><span><b>Institution No: 016</b></span></p>
                            <p style={{ marginBottom: "0px" }}><span><b>Account No: 588831001</b></span></p>
                        </div>

                    </div>

                </div>


                <div id="page_top_bar_2" style={{ backgroundColor: "transparent" }}>

                    <p style={{ fontSize: "10px", textAlign: "center" }}>THANK YOU FOR SHIPPING THROUGH  GOLDEN FREIGHT FORWARDING.  WE APPRECIATE YOUR BUSINESS.</p>
                </div>

            </div>
        </div>
    );
}


