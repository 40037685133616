import React, { useState, useEffect } from 'react';
import { requestTokenHeader } from "../../../../Services/Helpers/helper";
import api from "../../../../Services/Axios/index";
import moment from "moment";
import InvoicePdfUsd from "../../Jobs/InvoicePdfUsd";
import InvoicePdfCad from "../../Jobs/InvoicePdfCad";
import InvoicePdfMix from "../../Jobs/InvoicePdfMix";

import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
export function TrackingForm(props) {
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState(false);
    useEffect(() => {
        async function fetchMyAPIMSC() {
           if (props.jobsDetails?.booking_number !== undefined && props.jobsDetails?.booking_number != "" && props.jobsDetails?.carriers_name == "MSC LINE" && props.jobsDetails?.container_number == "") {
                
                const response = await api.get("api/v1/tslMSCApiData", {
                    params: {
                        token: props?.mscToken?.[0]?.token,
                        url: 'https://uat.api.tech.msc.com/msc/trackandtrace/v2.2/events',
                        endUrl: `carrierBookingReference=${props.jobsDetails?.booking_number}`
                    },
                    headers: requestTokenHeader()
                });
                if (response.data.errorCode === 0) {
                    setFields(response.data.data);
                } else {
                    setFields("");
                }
            }
            else if (props.jobsDetails?.container_number !== undefined && props.jobsDetails?.booking_number != "" && props.jobsDetails?.container_number != "" && props.jobsDetails?.carriers_name == "MSC LINE" && (props?.documentationDataById?.[0]?.[0]?.bl_number === null || props?.documentationDataById?.[0]?.[0]?.bl_number === undefined)) {

                const response = await api.get("api/v1/tslMSCApiData", {

                    params: {
                        token: props?.mscToken?.[0]?.token,
                        url: 'https://uat.api.tech.msc.com/msc/trackandtrace/v2.2/events',
                        endUrl: `equipmentReference=${props.jobsDetails?.container_number}`
                    },
                    headers: requestTokenHeader()
                });
                if (response.data.errorCode === 0) {
                    setFields(response.data.data);

                } else {
                    setFields("");
                }
            }
            else if (props.jobsDetails?.booking_number != "" && props.jobsDetails?.container_number != "" && props.jobsDetails?.carriers_name == "MSC LINE" && props?.documentationDataById?.[0]?.[0]?.bl_number !== null && props?.documentationDataById?.[0]?.[0]?.bl_number !== "undefined") {
          
                const response = await api.get("api/v1/tslMSCApiData", {

                    params: {
                        token: props?.mscToken?.[0]?.token,
                        url: 'https://uat.api.tech.msc.com/msc/trackandtrace/v2.2/events',
                        endUrl: `transportDocumentReference=${props?.documentationDataById?.[0]?.[0]?.bl_number}`
                    },
                    headers: requestTokenHeader()
                });
                if (response.data.errorCode === 0) {
                    setFields(response.data.data);
                } else {
                    setFields("");
                }
            }
            else {
                console.log("ssfsdsfsdf");
                setFields("");
            }

        }


        async function fetchMyAPIZIM() { 
            if (props.jobsDetails?.booking_number !== undefined && props.jobsDetails?.booking_number != "" && props.jobsDetails?.carriers_name == "ZIM LINE" && props.jobsDetails?.container_number == "") {
              
                const response = await api.get("api/v1/tslZIMApiData", {
                    params: {
                        token: props?.mscToken?.[0]?.token,
                        url: 'https://apigw.zim.com/tracing-sandbox/v1',
                        refNumber: props.jobsDetails?.booking_number
                    },
                    headers: requestTokenHeader()
                });
                if (response.data.errorCode === 0) {
                    setFields(response.data.data);
                } else {
                    setFields("");
                }
            }
            else if (props.jobsDetails?.container_number !== undefined && props.jobsDetails?.booking_number != "" && props.jobsDetails?.container_number != "" && props.jobsDetails?.carriers_name == "ZIM LINE" && (props?.documentationDataById?.[0]?.[0]?.bl_number === null || props?.documentationDataById?.[0]?.[0]?.bl_number === undefined ) ) {
             
                const response = await api.get("api/v1/tslZIMApiData", {
                    params: {
                        token: props?.mscToken?.[0]?.token,
                        url: 'https://apigw.zim.com/tracing-sandbox/v1',
                        refNumber: props.jobsDetails?.container_number
                    },
                    headers: requestTokenHeader()
                });
                if (response.data.errorCode === 0) {
                    setFields(response.data.data);

                } else {
                    setFields("");
                }
            }
            else if (props.jobsDetails?.booking_number != "" && props.jobsDetails?.container_number != "" && props.jobsDetails?.carriers_name == "ZIM LINE" && (props?.documentationDataById?.[0]?.[0]?.bl_number !== null && props?.documentationDataById?.[0]?.[0]?.bl_number !== undefined)) {
               
                const response = await api.get("api/v1/tslZIMApiData", {

                    params: {
                        token: props?.mscToken?.[0]?.token,
                        url: 'https://apigw.zim.com/tracing-sandbox/v1',
                        refNumber: props?.documentationDataById?.[0]?.[0]?.bl_number
                    },
                    headers: requestTokenHeader()
                });
                if (response.data.errorCode === 0) {
                    setFields(response.data.data);
                } else {
                    setFields("");
                }
            }
            else {
                setFields("");
            }

        }

        async function fetchMyAPICMACGM() { 
            if (props.jobsDetails?.booking_number !== undefined && props.jobsDetails?.booking_number != "" && props.jobsDetails?.carriers_name == "CMA CGM" && props.jobsDetails?.container_number == "") {
              
                const response = await api.get("api/v1/tslCMACGMApiData", {
                    params: {
                        token: props?.mscToken?.[0]?.token,
                        url: 'https://apis.cma-cgm.net/operation/trackandtrace/v1/events',
                        endURL: `carrierBookingReference=`+ props.jobsDetails?.booking_number
                    },
                    headers: requestTokenHeader()
                });
                if (response.data.errorCode === 0) {
                    setFields(response.data.data);
                } else {
                    setFields("");
                }
            }
            else if (props.jobsDetails?.container_number !== undefined && props.jobsDetails?.booking_number != "" && props.jobsDetails?.container_number != "" && props.jobsDetails?.carriers_name == "CMA CGM" && (props?.documentationDataById?.[0]?.[0]?.bl_number === null || props?.documentationDataById?.[0]?.[0]?.bl_number === undefined ) ) {
             
                const response = await api.get("api/v1/tslCMACGMApiData", {
                    params: {
                        token: props?.mscToken?.[0]?.token,
                        url: 'https://apis.cma-cgm.net/operation/trackandtrace/v1/events',
                        endURL: `equipmentReference=`+ props.jobsDetails?.container_number
                    },
                    headers: requestTokenHeader()
                });
                if (response.data.errorCode === 0) {
                    setFields(response.data.data);

                } else {
                    setFields("");
                }
            }
            else if (props.jobsDetails?.booking_number != "" && props.jobsDetails?.container_number != "" && props.jobsDetails?.carriers_name == "CMA CGM" && (props?.documentationDataById?.[0]?.[0]?.bl_number !== null && props?.documentationDataById?.[0]?.[0]?.bl_number !== undefined)) {
               
                const response = await api.get("api/v1/tslCMACGMApiData", {

                    params: {
                        token: props?.mscToken?.[0]?.token,
                        url: 'https://apis.cma-cgm.net/operation/trackandtrace/v1/events',
                        endURL: `transportDocumentReference=`+ props?.documentationDataById?.[0]?.[0]?.bl_number
                    },
                    headers: requestTokenHeader()
                });
                if (response.data.errorCode === 0) {
                    setFields(response.data.data);
                } else {
                    setFields("");
                }
            }
            else {
                setFields("");
            }

        }


        if(props.jobsDetails?.shipping_line_id === 1){ //MSC
            fetchMyAPIMSC();
        }
        if(props.jobsDetails?.shipping_line_id === 6){ //zim line
            fetchMyAPIZIM();
        }

        if(props.jobsDetails?.shipping_line_id === 5){ //CMA cgm line
            fetchMyAPICMACGM();
        }

        
    }, [props?.jobsDetails?.shipping_line_id, props?.documentationDataById?.[0]?.[0]?.bl_number, props.jobsDetails?.booking_number,props.jobsDetails?.container_number]);



    const callPageRecords = (userData) => {
        if(props.jobsDetails?.shipping_line_id === 1){

        if (userData.length > 0 ) {
            return userData?.map((data, index) =>
            (

                <div className="order-track-step">
                    <div className="order-track-status">
                        <span className="order-track-status-dot"></span>
                        <span className="order-track-status-line"></span>
                    </div>
                    <div className="order-track-text">
                        <p className="order-track-text-stat">Location: { data?.eventType ==  "TRANSPORT" ? "TRANSPORT" : data?.eventLocation?.locationName}</p>

                        <span className="order-track-text-sub">Description: {data.description}</span>
                        <span className="order-track-text-sub">Empty/Laden/Vessel/Voyage: {data?.emptyIndicatorCode} {data?.transportCall.modeOfTransport}</span>
                        <span className="order-track-text-sub">Equipment handling facility name: {data?.transportCall?.transportCallID}</span>
                    </div>
                    <div className="order-track-time">
                        <span className="order-track-text-sub"><i className="fa fa-calendar"></i> {moment(data?.eventDateTime).format("YYYY-MM-DD")}</span>
                    </div>
                </div>
            ));
        } else {
            return (
                <tr className="text-center">
                    <td colSpan={13}>No Record Found</td>
                </tr>
            );
        }
    }

    if(props.jobsDetails?.shipping_line_id === 6){

        if (userData) {

            
            return userData?.bkBlDetails?.consContainerList?.[0]?.containerEventsList?.map((data, index) =>
            (

                <div className="order-track-step">
                    <div className="order-track-status">
                        <span className="order-track-status-dot"></span>
                        <span className="order-track-status-line"></span>
                    </div>
                    <div className="order-track-text">
                        <p className="order-track-text-stat">Location: { data?.countryPort}</p>

                        <span className="order-track-text-sub">Description: {data.eventName}</span>
                        <span className="order-track-text-sub">Empty/Laden/Vessel/Voyage: {data?.vesselName}</span>
                        <span className="order-track-text-sub">Equipment handling facility name: {data?.portCode}</span>
                    </div>
                    <div className="order-track-time">
                        <span className="order-track-text-sub"><i className="fa fa-calendar"></i> {moment(data?.activityDateTz).format("YYYY-MM-DD")}</span>
                    </div>
                </div>
            ));
        } else {
            return (
                <tr className="text-center">
                    <td colSpan={13}>No Record Found</td>
                </tr>
            );
        }
    }

    if(props.jobsDetails?.shipping_line_id === 5){ //CMA

        if (userData) {

            
            return userData?.bkBlDetails?.consContainerList?.[0]?.containerEventsList?.map((data, index) =>
            (

                <div className="order-track-step">
                    <div className="order-track-status">
                        <span className="order-track-status-dot"></span>
                        <span className="order-track-status-line"></span>
                    </div>
                    <div className="order-track-text">
                        <p className="order-track-text-stat">Location: { data?.countryPort}</p>

                        <span className="order-track-text-sub">Description: {data.eventName}</span>
                        <span className="order-track-text-sub">Empty/Laden/Vessel/Voyage: {data?.vesselName}</span>
                        <span className="order-track-text-sub">Equipment handling facility name: {data?.portCode}</span>
                    </div>
                    <div className="order-track-time">
                        <span className="order-track-text-sub"><i className="fa fa-calendar"></i> {moment(data?.activityDateTz).format("YYYY-MM-DD")}</span>
                    </div>
                </div>
            ));
        } else {
            return (
                <tr className="text-center">
                    <td colSpan={13}>No Record Found</td>
                </tr>
            );
        }
    }

    };

    return (
        <>
            <div className="invitation-wrap">
                <div className="container">
                    <div className="row login-wrap-new">
                        <div className="col-md-12 col-xs-12">
                            <div className="form-content">

                                <div className="order-track">
                                    {fields !==
                                        undefined &&
                                        fields !== "" ? (
                                        callPageRecords(
                                            fields
                                        )
                                    ) : (
                                        <div className='text-center mb-4'>No Record Found</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}