import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { requestTokenHeader } from "../Services/Helpers/helper";
import api from "../Services/Axios/index";
import NotificationSound from "../../src/notification-sound.mp3";
import { ModalBox } from "../../src/Components/Ui/ModalBox";
const AdminLayout = (props) => {
  const currentUser = useSelector((state) => state.userClient);
  const [toggleClass, setToggleClass] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [notificationUnreadCount, setNotificationUnreadCount] = useState(0);
  const audioPlayer = useRef(null);
  const [audioError, setAudioError] = useState("");
  const [displayPopUp, setDisplayPopUp] = useState(false);

  const sidebarClick = () => {
    setToggleClass(toggleClass === "" ? "toggled" : "");
  }

  const getData = async () => {
    try {
      const response = await api.get("api/v1/tslChecktoken", {
        params: { id: currentUser.user.id },
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        console.log("success");
      } else {
        console.log("error");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMyNotifications = async () => {
    
    const params = { limit: 5 };
    if (currentUser.user.role === 'customer') {
      params['customer_id'] = currentUser.user.id;
    } else {
      params['user_id'] = currentUser.user.id;
    }
    const response = await api.get("api/v1/tslNotifications", { params: params, headers: requestTokenHeader() });
    if (response && response.data.errorCode === 0) {

      setNotifications(response.data.data);
      setNotificationUnreadCount(count => {
        if (count > 0 && count < response.data.unread_count) {
          audioPlayer.current.play().catch((e) => {
            setAudioError(e);
            let returningUser = localStorage.getItem("seenPopUp");
          setDisplayPopUp(!returningUser);
          });
        }
        return response.data.unread_count;
      });
    } else {
      setNotifications([]);
      setNotificationUnreadCount(0);
    }
  }

  useEffect(() => {
    const tokenCall = setInterval(() => {
      getData();
    }, 300000);
    const notificationCall = setInterval(() => {
      fetchMyNotifications();
    }, 5000);
    fetchMyNotifications();
    return () => {
      clearInterval(tokenCall);
      clearInterval(notificationCall);
    };
  }, []);

  const closePopUp = () => {
    localStorage.setItem("seenPopUp", true);
    setDisplayPopUp(false);
  };

  return (
    <React.Fragment>
      <div id="wrapper">
        <Sidebar toggleClass={toggleClass}  sidebarClick={sidebarClick}/>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header pageHeading={props.pageHeading} 
            pageIcon={props.pageIcon} 
            notifications={notifications} 
            unreadCount={notificationUnreadCount} 
            sidebarClick={sidebarClick} 
            />
            {props.children}
            <audio id="audio" ref={audioPlayer} src={NotificationSound} />
            <ModalBox
            show={ displayPopUp}
            title={displayPopUp ? "Enable Sound Permission ":""}
            size={(displayPopUp === true )? 'md' : ""}
            onHide={() => {
              closePopUp();
            }}
          >
            { displayPopUp === true ? (

              <div className="modal-body">
                <div className="container login-wrap-new">
                  <div className="row">
                    <div className="col-md-12">
                      <p>Please enable play-sound permission in your browser.</p>
                    </div>
                  </div>
                </div>
              </div>
            ) :


              (
                ""
              )}
          </ModalBox>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AdminLayout;
