import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import {
  getcustomerList,
  deleteUserById,
  addCustomerDetails,
  editCustomerDetails,
  changeUserPassword,
} from "../../../Services/Store/Common/customer/customer.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { CustomerForm } from "./CustomerForm";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { customerList } from "../../../Services/Store/Common/customer/customer.selector";
import api from "../../../Services/Axios/index";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Services/Helpers/helper";

const Account = () => {
  const id = useParams()?.id;
  const currentUser = useSelector((state) => state.userClient);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const perPage = 25;
  const [modalData, setModalData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerListResult = useSelector(customerList);
  const [searchType, setSearchType] = useState(null);
  const [customersList, setCustomersList] = useState([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [searchIds, setSearchIds] = useState(null);

  useEffect(() => {
    if (id) {
      if (id === 'search') {
        let search = localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : '';
        if (search?.resource === 'customer' && search.ids) {
          setSearchIds(search.ids);
        }
      } else {
        setSearchType('id');
        setSearchKeyword(id);
      }
    } else {
      localStorage.removeItem('search');
      setSearchIds('');
    }
  }, [id]);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));
    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }
  }, [errorResponce]);

  useEffect(() => {
    setCustomersList(customerListResult.result);
    setCustomersCount(customerListResult.records);
    setErrorResponce(customerListResult.error_code);
  }, [customerListResult]);

  useEffect(() => {
    if(searchKeyword !== null && searchType !== null)
      dispatch(getcustomerList({ searchIds, searchType: searchType, search: searchKeyword }));
  }, [searchKeyword, searchType]);

  useEffect(() => {
    if(searchIds !== null) {
      setSearchKeyword(null);
      setSearchType(null);
      dispatch(getcustomerList({ searchIds }));
    }
  }, [searchIds]);

  const handlePageClick = (selectedPage) => {
    dispatch(getcustomerList({ searchIds, searchType: searchType, search: searchKeyword, offset: selectedPage }));
  };

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (userDetails) => {
    setModalData(userDetails);
    setShowModel(true);
    setEditModel(true);
    if(currentUser.user.role === 'documentation' ){
      updateReadStatus(userDetails.id)
    }
  };

  const _handleDeleteClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleChangePasswordClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setChangePasswordModel(true);
  }

  const _handleAddClick = () => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
      setShowModel(true);
      setAddModel(true);
    }
  };

  async function fetchMyAPIQuote(id) {
    const response = await api.get("api/v1/downloadCustomerExcel",
      { headers: requestTokenHeader() }
    );
    if (response.data.errorCode === 0 && response.data.data != "") {
      displaySuccessMessage("fileDownloadedSuccess");
      window.open("http://golden-test.com:3001/uploads/coustomers.xlsx", '_blank', 'noopener,noreferrer');
    } else {
      displayErrorMessage("fileDownloadedError");
    }
  }

  const _handleDownloadClick = (id) => {
    fetchMyAPIQuote(id);
  };

  async function updateReadStatus(customerId) {
    const response = await api.post("api/v1/tslUpdateIsRead", { is_read: '1', id: customerId }, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      let newQuotes = await customersList.map(quote => {
        if (quote.id === customerId) {
          quote.is_read = '1';
          return quote;
        } else {
          return quote;
        }
      });
      setCustomersList(newQuotes);
    }
  }

  const callPageRecords = (userData) => {
    if (userData?.length) {
      return userData.map((data, index) => (
        <tr key={data.id} className={currentUser.user.role === 'documentation' && data.is_read === '0' ? 'table-info' : ''}>
          <td>{data.firstname}</td>
          <td> {data.lastname}</td>
          <td> {data.username}</td>
          <td> {data.mobile_number}</td>
          <td> {data.status}</td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit User"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>

            {["admin"].includes(currentUser.user.role) ?
              <button
                type="button"
                className="bd-none btn btn-danger btn-xsm"
                onClick={() => _handleDeleteClick(data.id)}
                data-toggle="tooltip" data-placement="top" title="Delete User"
              >
                <i className="fa fa-fw fa-trash"></i>
              </button> : ""}
              {["admin", "manager", "sales"].includes(currentUser.user.role) ?
            <button
              type="button"
              className="bd-none btn btn-success btn-xsm"
              onClick={() => _handleChangePasswordClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Change Password"
            >
              <i className="fa fa-fw fa-key"></i>
            </button> : ""}
            <Link state={{ customerId: data.id }} className="bd-none btn btn-info btn-xsm" to="/contact" data-toggle="tooltip" data-placement="top" title="Contacts Listing">
              <i className="fa fa-fw fa-address-book"></i>
            </Link>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={8}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteUser = async (userId2) => {
    dispatch(deleteUserById(userId2));
    setShowModel(false);
    setDeleteModel(false);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
    }
  };

  const changePasswordUser = async (params) => {
    dispatch(changeUserPassword(params));
    setShowModel(false);
    setChangePasswordModel(false);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
    }
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submitUserDetails = async (params) => {
    dispatch(addCustomerDetails(params, searchIds));
    setShowModel(false);
    setAddModel(false);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
    }
  };

  const submitEditUserDetails = async (params) => {
    dispatch(editCustomerDetails(params, searchIds));
    setShowModel(false);
    setAddModel(false);
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <>
      <AdminLayout pageHeading="Customers" pageIcon="fa fa-user-circle">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Customer List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                  <button
                    type="button"
                    className="mb-2 mb-md-0 btn btn-primary"
                    onClick={() => _handleAddClick()}
                  >
                    Add Customer&nbsp;
                    <i className="fa fa-plus fa-sm"></i>
                  </button>
                  {["admin"].includes(currentUser.user.role) ?
                    <button
                      style={{ marginLeft: "10px" }}
                      type="button"
                      className="mb-2 mb-md-0 btn btn-primary"
                      onClick={() => _handleDownloadClick()}
                    >
                      Download&nbsp;
                      <i className="fa fa-download fa-sm"></i>
                    </button> : ""}
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="fillter-wrap d-flex justify-content-end">
                    <select name="selectType" id="" className="form-control me-1" value={searchType ?? ''} onChange={(event) => handleSearhChange(event)}>
                      <option value="">Select Type </option>
                      <option value="firstname">First Name </option>
                      <option value="lastname">Last Name</option>
                      <option value="username">Username</option>
                      <option value="mobile_number">Mobile Number</option>
                      <option value="status">Status</option>
                    </select>
                    <input type="search" className="form-control" value={searchKeyword ?? ''} placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-bordered" >
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Username</th>
                          <th>Mobile Number</th>
                          <th>Status</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        { callPageRecords(
                            customersList
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {
                    <PaginationBlock
                      perPage={perPage}
                      userRecordsData={customersCount}
                      callHandlePageClick={(data) =>
                        handlePageClick(data)
                      }
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <ModalBox
            title={deleteModel ? 'Delete Customer' : (changePasswordModel ? 'Change Password Form' : 'Customer Form')}
            size={deleteModel || changePasswordModel ? 'md' : 'xl'}
            show={showModel}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setChangePasswordModel(false);
              setDeleteModel(false);
              setAddModel(false);
            }}
          >
            {editModel === true ? (
              <CustomerForm
                editUserDetails={(params) => submitEditUserDetails(params)}
                userDetails={modalData}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={userId}
                deleteUserId={(userId) => deleteUser(userId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : addModel === true ? (
              <CustomerForm
                userId={userId}
                addUserDetails={(params) => submitUserDetails(params)}
              />
            ) : changePasswordModel === true ? (
              <ChangePasswordForm
                userId={userId}
                changeUserPassword={(params) => changePasswordUser(params)}

              />
            ) : (
              ""
            )}
          </ModalBox>
        </div >
      </AdminLayout >
    </>
  );
};
export default Account;
