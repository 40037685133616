import React, { useState, useEffect } from 'react';
import "./index.css";
import { useFormik } from 'formik';
import * as Yup from 'yup';
export function CalendarForm(props) {

    console.log(props);
    const [fields, setFields] = useState(props.modalData ? props.modalData : {});

    const validateContactFrom = Yup.object().shape({
        user_comment: Yup.string().required('Field name is required'),
        status: Yup.string().required('Field name is required'),
    });
    let initialValues = {};
    if (fields) {
        initialValues = {
            user_comment: fields.user_comment,
            status: fields.status,
        }
    } else {
        initialValues = {
            user_comment: "",
            status: "",
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            if (fields?.id) {
                const postData = {
                    status: values.status,
                    user_comment: values.user_comment,
                    id: fields.id,
                    name: props.modalData.name,
                    description: props.modalData.description,
                    due_date: props.modalData.due_date,
                    assigned_to: props.modalData.assigned_to,
                }
                props.editUserDetails(postData);
            } else {
                const params = {
                    status: values.status,
                    user_comment: values.user_comment,
                    name: props.modalData.name,
                    description: props.modalData.description,
                    due_date: props.modalData.due_date,
                    assigned_to: props.modalData.assigned_to,
                }
                props.addUserDetails(params)
            }
        },
    });

    return (
        <>
            <div className="invitation-wrap mt-4 mb-4">
                <div className="container">
                    <div className="row login-wrap-new">
                        <div className="col-md-12 col-xs-12">
                            <div className="form-content">
                                <div className="row">
                                    {
                                        fields.type == "task" ?
                                            <div className="col-md-6 col-xs-6 col-sm-12">
                                                <fieldset className='h-100'>
                                                    <legend>Task Info</legend>
                                                    <p className='m-0'>
                                                        <span style={{ fontSize: "18px", fontWeight: 500 }}>Title: </span>
                                                        <span> {props.modalData.name}</span>
                                                    </p>
                                                    <p className='m-0'>
                                                        <span style={{ fontSize: "18px", fontWeight: 500 }}> Description: </span>
                                                        <span> {props.modalData.description}</span>
                                                    </p>
                                                    <p className='m-0'>
                                                        <span style={{ fontSize: "18px", fontWeight: 500 }}> Due Date: </span>
                                                        <span> {props.modalData.due_date}</span>
                                                    </p>
                                                    <p className='m-0'>
                                                        <span style={{ fontSize: "18px", fontWeight: 500 }}> Status: </span>
                                                        <span className='text-success text-capitalize'>{props.modalData.status}</span>
                                                    </p>
                                                </fieldset>
                                            </div> : ''
                                    }
                                    {
                                        fields.type == "event" ?
                                            <div className="col-md-12 col-xs-12 col-sm-12">
                                                <fieldset className='h-100'>
                                                    <legend>Meeting Detail</legend>
                                                    <p className='m-0'>
                                                        <span style={{ fontSize: "18px", fontWeight: 500 }}> Title: </span>
                                                        <span> {props.modalData.name}</span>
                                                    </p>
                                                    <p className='m-0'>
                                                        <span style={{ fontSize: "18px", fontWeight: 500 }}> Description: </span>
                                                        <span> {props.modalData.description}</span>
                                                    </p>
                                                    <p className='m-0'>
                                                        <span style={{ fontSize: "18px", fontWeight: 500 }}> Reminder: </span>
                                                        <span> {props.modalData.reminder}</span>
                                                    </p>
                                                    <p className='m-0'>
                                                        <span style={{ fontSize: "18px", fontWeight: 500 }}> Visibility: </span>
                                                        <span> {props.modalData.visibility}</span>
                                                    </p>
                                                    <p className='m-0'>
                                                        <span style={{ fontSize: "18px", fontWeight: 500 }}> Categories: </span>
                                                        <span> {props.modalData.event_categories}</span>
                                                    </p>
                                                </fieldset>
                                            </div> : ''
                                    }
                                    <div className="col-md-6 col-xs-6 col-sm-12" style={{ display: fields.type == "task" ? "block" : "none" }}>
                                        <fieldset className='h-100'>
                                            <legend>Update Task</legend>
                                        <form className='row' onSubmit={formik.handleSubmit}>                                    
                                        <div className="col-md-12">
                                            <label>Status</label>
                                            <select className="form-control"
                                                name="status"
                                                id="status"
                                                {...formik.getFieldProps('status')}
                                            >
                                                <option>--Select--</option>
                                                <option value="inprogress">Inprogress</option>
                                                <option value="complete">Complete</option>
                                            </select>
                                            {formik.touched.status && formik.errors.status && (
                                                <div className="errorMsg text-danger">{formik.errors.status}</div>
                                            )}

                                        </div>

                                        <div className="col-md-12">
                                            <label>Description</label>
                                            <textarea
                                                className="form-control"
                                                name="user_comment"
                                                rows='1'
                                                id="user_comment"
                                                {...formik.getFieldProps('user_comment')}
                                            ></textarea>
                                            {formik.touched.user_comment && formik.errors.user_comment && (
                                                <div className="errorMsg text-danger">{formik.errors.user_comment}</div>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            <button type="submit" className="btn btn-primary" style={{ marginTop: "20px" }}>
                                                Update Task
                                            </button>
                                        </div>
                                     
                                        </form>
                                        </fieldset>
                                    </div>
                                

                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}