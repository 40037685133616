import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./index.css";

const Sidebar = (props) => {
  const [urlName, setUrlName] = useState("");
  let location = useLocation();
  const currentUser = useSelector((state) => state.userClient);

  useEffect(() => {
    let pathBaseName = location;
    setUrlName(pathBaseName.pathname);
  }, [location]);

  const accordion = function (multiple = false) {
    const accordion = document.querySelector(".accordion-menu");
    const dropdownHeader = accordion.querySelectorAll(".dropdownHeader");
    dropdownHeader.forEach((header) => {
      header.addEventListener("click", (e) => {
        const subMenuBlock = header.nextElementSibling;
        const subMenus = subMenuBlock.querySelector('ul');
        subMenuBlock.style.height = subMenuBlock.clientHeight ? 0 : subMenus.clientHeight + 'px';
        header.parentElement.classList.toggle("active");
        if (!multiple) {
          accordion.querySelectorAll(".subMenuBlocks").forEach((submenu) => {
            if (submenu !== subMenuBlock) {
              submenu.style.height = 0;
              submenu.parentElement.classList.remove("active");
            }
          });
        }
      });
    });
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore) accordion();
    return () => { ignore = true; }
  }, []);

  return (
    <>
      <ul className={"accordion-menu navbar-nav bg-gradient-primary sidebar sidebar-dark accordion " + props.toggleClass} id="accordionSidebar">
        <Link className="sidebar-brand d-flex align-items-center justify-content-start"
          to={currentUser.user.role === 'customer' ? "/customer/dashboard" : "/dashboard"}
        >
          <div className="sidebar-brand-text">
            <img
              src="/assets/images/logo.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </Link>

        <hr className="sidebar-divider mb-4" />

        {/* {["admin", "booking", "documentation", "accounts", "rates", "sales", "customer"].includes(currentUser.user.role) ? */}
        <li className={`nav-item ${(urlName === "/dashboard" || urlName.includes("/customer/dashboard")) ? "active" : ""}`}>
          <Link className="nav-link" to={currentUser.user.role === 'customer' ? "/customer/dashboard" : "/dashboard"}>
            <i className={currentUser.user.role === 'customer' ? "fa fa-home" : "fa fa-calendar"} aria-hidden="true"></i>
            <span> Dashboard</span>
          </Link>
        </li>
        {/* } */}

        {["sales"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName.includes("/customerHistory") ? "active" : ""}`}>
            <Link className="nav-link" to="/customerHistory">
              <i className="fa fa-history" aria-hidden="true"></i>
              <span> Customer History</span>
            </Link>
          </li> : ''
        }

        {["admin", "accounts", "documentation", "booking", "sales", "rates", "manager"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName.includes("/jobs") ? "active" : ""}`}>
            <Link className="nav-link" to="/jobs">
              <i className="fa fa-tasks" aria-hidden="true"></i>
              <span> Jobs</span>
            </Link>
          </li> : ''
        }

        {["admin", "accounts", "documentation", "booking", "rates", "sales"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName.includes("/users") ? "active" : ""}`}>
            <Link className="nav-link" to="/users">
              <i className={["admin"].includes(currentUser.user.role) ? "fa fa-male" : "fa fa-male"} aria-hidden="true"></i>
              <span> {["admin"].includes(currentUser.user.role) ? "Users" : "Profile"} </span>
            </Link>
          </li> : ''
        }

        {["admin", "booking", "documentation", "manager"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName.includes("/customer") && !urlName.includes("/customer-") ? "active" : ""}`}>
            <Link className="nav-link" to="/customer">
              <i className="fa fa-user-circle" aria-hidden="true"></i>
              <span> Customers</span>
            </Link>
          </li> : ''
        }

        {["admin"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName.includes("/service") ? "active" : ""}`}>
            <Link className="nav-link" to="/service">
              <i className="fa fa-wrench" aria-hidden="true"></i>
              <span> Services</span>
            </Link>
          </li> : ''
        }

        {["admin"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName.includes("/carrier") ? "active" : ""}`}>
            <Link className="nav-link" to="/carrier">
              <i className="fa fa-truck" aria-hidden="true"></i>
              <span> Carriers</span>
            </Link>
          </li> : ''
        }

        {["admin"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName.includes("/trucker-name") ? "active" : ""}`}>
            <Link className="nav-link" to="/trucker-name">
              <i className="fa fa-truck" aria-hidden="true"></i>
              <span> Truckers</span>
            </Link>
          </li> : ''
        }

        {["admin", "booking", "manager"].includes(currentUser.user.role) ?
          <li className={`${urlName.includes("/export-document-coordinator") || urlName.includes("/import-document-coordinator") ? "active" : ""}`}>
            <div className="dropdownHeader">
              <i className="fa fa-group"></i>
              <span>Doc. Coordinator</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
            <div className="submenuItems" style={{
              height: urlName.includes("/export-document-coordinator") || urlName.includes("/import-document-coordinator") ? '100%' : 0
            }}>
              <ul>
                {["admin", "accounts", "documentation", "booking", "sales", "rates", "manager"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName.includes("/export-document-coordinator") ? "active" : ""}`}>
                    <Link className="nav-link" to="/export-document-coordinator">
                      <i className="fa fa-file" aria-hidden="true"></i>
                      <span> Export Doc. Coordinator</span>
                    </Link>
                  </li> : ''
                }

                {["admin", "accounts", "documentation", "booking", "sales", "rates", "manager"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName.includes("/import-document-coordinator") ? "active" : ""}`}>
                    <Link className="nav-link" to="/import-document-coordinator">
                      <i className="fa fa-address-card" aria-hidden="true"></i>
                      <span> Import Doc. Coordinator</span>
                    </Link>
                  </li> : ''
                }
              </ul>
            </div>
          </li>
          : ""}

        {["admin"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName === "/container-listing" ? "active" : ""}`}>
            <Link className="nav-link" to="/container-listing">
              <i className="fa fa-bus" aria-hidden="true"></i>
              <span> Containers</span>
            </Link>
          </li> : ''
        }

        {["admin", "booking", "manager"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName.includes("/bookings/available") ? "active" : ""}`}>
            <Link className="nav-link" to="/bookings/available">
              <i className="fa fa-tasks" aria-hidden="true"></i>
              <span>Available Bookings</span>
            </Link>
          </li> : ''
        }

        {["admin", "accounts", "manager"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName === "/rates" ? "active" : ""}`}>
            <Link className="nav-link" to="/rates">
              <i className="fa fa-percent" aria-hidden="true"></i>
              <span> Exchange Rates</span>
            </Link>
          </li> : ''
        }

        {["admin"].includes(currentUser.user.role) ?
          <li style={{ display: "none" }} className={`nav-item ${urlName === "/cargo" ? "active" : ""}`}>
            <Link className="nav-link" to="/cargo">
              <i className="fa fa-ship" aria-hidden="true"></i>
              <span> Cargo</span>
            </Link>
          </li> : ''
        }

        {["customer"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName.includes("/customer/quotes") ? "active" : ""}`}>
            <Link className="nav-link" to="/customer/quotes">
              <i className="fa fa-file-excel-o" aria-hidden="true"></i>
              <span>Quote Request </span>
            </Link>
          </li> : ""
        }

        {["sales", "booking", "admin", "rates", "manager"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName.includes("/quotes") ? "active" : ""}`}>
            <Link className="nav-link" to="/quotes">
              <i className="fa fa-file-excel-o" aria-hidden="true"></i>
              <span>{currentUser.user.role === 'booking' ? 'Customer Requests' : 'Quotes'}</span>
            </Link>
          </li> : ""
        }

        {["rates", "admin", "manager"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName == "/shipping-line-rates" ? "active" : ""}`}>
            <Link className="nav-link" to="/shipping-line-rates">
              <i className="fa fa-dollar" aria-hidden="true"></i>
              <span>Shipping Line Rates</span>
            </Link>
          </li> : ""
        }

        {["rates", "admin", "manager"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName == "/customer-rate-history" ? "active" : ""}`}>
            <Link className="nav-link" to="/customer-rate-history">
              <i className="fa fa-dollar" aria-hidden="true"></i>
              <span>Customer Rate History</span>
            </Link>
          </li> : ""
        }

        {/* {["admin", "booking", "documentation", "accounts", "rates", "sales", "customer"].includes(currentUser.user.role) ? */}
        <li className={`nav-item ${urlName == "/notifications" ? "active" : ""}`}>
          <Link className="nav-link" to="/notifications">
            <i className="fa fa-bell" aria-hidden="true"></i>
            <span>Notifications</span>
          </Link>
        </li>
        {/* } */}

        {["customer"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName.includes("/customer/customer-list") ? "active" : ""}`}>
            <Link className="nav-link" to="/customer/customer-list">
              <i className="fa fa-user" aria-hidden="true"></i>
              <span> Customer Profile</span>
            </Link>
          </li> : ""
        }

        {["admin"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName == "/meetings" ? "active" : ""}`}>
            <Link className="nav-link" to="/meetings">
              <i className="fa fa-handshake-o" aria-hidden="true"></i>
              <span>Meetings</span>
            </Link>
          </li> : ""
        }

        {["admin"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName == "/tasks" ? "active" : ""}`}>
            <Link className="nav-link" to="/tasks">
              <i className="fa fa-tasks" aria-hidden="true"></i>
              <span>Tasks</span>
            </Link>
          </li> : ""
        }

        {["admin", "booking", "manager"].includes(currentUser.user.role) ?
          <li className={`${urlName.includes("/loading_port") || urlName.includes("/destination_port") ? "active" : ""}`}>
            <div className="dropdownHeader">
              <i className="fa fa-ship"></i>
              <span> Ports</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
            <div className="submenuItems" style={{
              height: urlName.includes("/loading_port") || urlName.includes("/loading_port") ? '100%' : 0
            }}>
              <ul>
                {["admin", "booking", "manager"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName.includes("/loading_port") ? "active" : ""}`}>
                    <Link className="nav-link" to="/loading_port">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      <span>Loading Port</span>
                    </Link>
                  </li> : ""
                }
                {["admin", "booking", "manager"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName.includes("/destination_port") ? "active" : ""}`}>
                    <Link className="nav-link" to="/destination_port">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      <span>Destination Port</span>
                    </Link>
                  </li> : ""
                }
              </ul>
            </div>
          </li>
          : ""}

        {["manager", "admin", "sales", "booking", "rates", "accounts", "documentation"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName == "/chat" ? "active" : ""}`}>
            <Link className="nav-link" to="/chat">
              <i className="fa fa-address-book" aria-hidden="true"></i>
              <span>Chat</span>
            </Link>
          </li> : ""
        }

        {["accounts", "admin"].includes(currentUser.user.role) ?
          <li className={`${urlName == "/customer-reporting" || urlName == "/customer-reporting-released" || urlName == "/customer-reporting-released-dates" || urlName == "/shipping-line-reporting" || urlName == "/trucker-reporting" || urlName == "/payments-reporting" ? "active" : ""}`}>
            <div className="dropdownHeader">
              <i className="fa-solid fa-list-check"></i>
              <span>AR & AP List </span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
            <div className="submenuItems" style={{
              height: urlName == "/customer-reporting" || urlName == "/customer-reporting-released"  || urlName == "/customer-reporting-released-dates" || urlName == "/shipping-line-reporting" || urlName == "/trucker-reporting" || urlName == "/payments-reporting" ? '100%' : 0
            }}>
              <ul>
                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/customer-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/customer-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Customer Reporting</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/customer-reporting-released" ? "active" : ""}`}>
                    <Link className="nav-link" to="/customer-reporting-released">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Customer Reporting Released</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/customer-reporting-released-dates" ? "active" : ""}`}>
                    <Link className="nav-link" to="/customer-reporting-released-dates">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Customer Reporting Released (Date Wise)</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/shipping-line-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/shipping-line-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Shipping Line Reporting</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/trucker-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/trucker-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Trucker Reporting</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/payments-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/payments-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Received Payment Reporting</span>
                    </Link>
                  </li> : ""
                }
              </ul>
            </div>
          </li>
          : ""}

        {["accounts", "admin","documentation"].includes(currentUser.user.role) ?
          <li className={`${urlName == "/account-reporting" || urlName == "/status-reporting" || urlName == "/job-closed-reporting" || urlName == "/trucker-balance-reporting" || urlName == "/steamship-balance-reporting" || urlName == "/customer-balance-reporting" || urlName == "/excel-report" || urlName == "/main-report" || urlName == "/received-amout-report" || urlName == "/all-sale-reporting" || urlName == "/all-reporting" ? "active" : ""}`}>
            <div className="dropdownHeader">
              <i className="fa-solid fa-file-invoice"></i>
              <span>Accounts </span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
            <div className="submenuItems" style={{
              height: urlName == "/account-reporting" || urlName == "/status-reporting" || urlName == "/job-closed-reporting" || urlName == "/trucker-balance-reporting" || urlName == "/steamship-balance-reporting" || urlName == "/customer-balance-reporting" || urlName == "/excel-report" || urlName == "/main-report" || urlName == "/received-amout-report" || urlName == "/all-sale-reporting" || urlName == "/all-reporting" ? '100%' : 0
            }}>
              <ul>
                {["accounts", "admin","documentation"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/account-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/account-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Accounts Reporting</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/status-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/status-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Status Reporting</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/job-closed-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/job-closed-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Closed Jobs Reporting</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/trucker-balance-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/trucker-balance-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Trucker Balance Reporting</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/steamship-balance-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/steamship-balance-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Steamship Line Balance</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/customer-balance-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/customer-balance-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span> Customer Balance</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/excel-report" ? "active" : ""}`}>
                    <Link className="nav-link" to="/excel-report">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span> Download Excel Report</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/main-report" ? "active" : ""}`}>
                    <Link className="nav-link" to="/main-report">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span> Reporting</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/received-amout-report" ? "active" : ""}`}>
                    <Link className="nav-link" to="/received-amout-report">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Received Amount Report</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/all-sale-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/all-sale-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>All Sale Reporting</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/all-reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/all-reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span> Print Balance Sheet</span>
                    </Link>
                  </li> : ""
                }
              </ul>
            </div>
          </li>
          : ""}

        {["accounts", "documentation", "admin"].includes(currentUser.user.role) ?
          <li className={`${urlName == "/reporting" || urlName == "/thirdparty-charges-report" || urlName == "/container-report" ? "active" : ""}`}>
            <div className="dropdownHeader">
              <i className="fa-solid fa-file"></i>
              <span>Reporting </span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
            <div className="submenuItems" style={{
              height: urlName == "/reporting" || urlName == "/thirdparty-charges-report" || urlName == "/container-report" ? '100%' : 0
            }}>
              <ul>
                {["accounts", "documentation", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/reporting" ? "active" : ""}`}>
                    <Link className="nav-link" to="/reporting">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>New Reporting</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/thirdparty-charges-report" ? "active" : ""}`}>
                    <Link className="nav-link" to="/thirdparty-charges-report">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span> Third Party Charges</span>
                    </Link>
                  </li> : ""
                }

                {["accounts", "admin"].includes(currentUser.user.role) ?
                  <li className={`nav-item ${urlName == "/container-report" ? "active" : ""}`}>
                    <Link className="nav-link" to="/container-report">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>
                      <span>Container Reporting </span>
                    </Link>
                  </li> : ""
                }
              </ul>
            </div>
          </li>
          : ""}


        {["admin", "superadmin", "manager"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName == "/employee-report" ? "active" : ""}`}>
            <Link className="nav-link" to="/employee-report">
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
              <span>Employee Report </span>
            </Link>
          </li> : ""
        }

        {["manager", "admin", "sales", "booking", "rates", "accounts", "documentation"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName == "/customer-volume-report" ? "active" : ""}`}>
            <Link className="nav-link" to="/customer-volume-report">
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
              <span>Customer Booking Volume </span>
            </Link>
          </li> : ""
        }

        {["manager", "admin", "sales", "booking", "rates", "accounts", "documentation"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName == "/history-for-sales-report" ? "active" : ""}`}>
            <Link className="nav-link" to="/history-for-sales-report">
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
              <span>History For Sales Report </span>
            </Link>
          </li> : ""
        }

        {["manager", "admin", "sales", "booking", "rates", "accounts", "documentation"].includes(currentUser.user.role) ?
          <li className={`nav-item ${urlName == "/Quotation-per-month-report" ? "active" : ""}`}>
            <Link className="nav-link" to="/Quotation-per-month-report">
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
              <span>Quotation Per Month </span>
            </Link>
          </li> : ""
        }

        <li style={{ display: "none" }} className={`nav-item ${urlName === "/members" ? "active" : ""}`}>
          <Link className="nav-link" to="">
            <i className="fa fa-users" aria-hidden="true"></i>
            <span> Members</span>
          </Link>
        </li>

        <li style={{ display: "none" }} className={`nav-item ${urlName === "/reportDesigner" ? "active" : ""}`}>
          <Link style={{ display: "none" }} className="nav-link" to="">
            <i className="fa fa-sticky-note" aria-hidden="true"></i>
            <span> Report Designer</span>
          </Link>
        </li>

        <li style={{ display: "none" }} className={`nav-item ${urlName === "/ceCentral" ? "active" : ""}`}>
          <Link className="nav-link" to="">
            <i className="fa fa-certificate" aria-hidden="true"></i>
            <span> CE Central</span>
          </Link>
        </li>
      </ul>

      <span className={"bg-color " + props.toggleClass} onClick={props.sidebarClick}>
        <i className="fa-solid fa-xmark"></i>
      </span>
    </>
  );
};
export default Sidebar;
