import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
    getMainReportingList
} from "../../../Services/Store/Common/mainReporting/mainReporting.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { mainReportingList } from "../../../Services/Store/Common/mainReporting/mainReporting.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
//import * as XLSX from "xlsx";
import { requestTokenHeader, displayErrorMessage, } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import  Loader from "../../../../src/Components/Ui/loader";
const Contact = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [userData, setUserData] = useState([]);
    const [userId, setUserId] = useState("");
    const [errorResponce, setErrorResponce] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [addModel, setAddModel] = useState(false);
    const [perPage, setPerPage] = useState(25);
    const [modalData, setModalData] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [userRecordsData, setUserRecordsData] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ratesListResult = useSelector(mainReportingList);
    // const mainClientresponse = useSelector(mainClient);
    const [searchType, setSearchType] = useState("");
    const [display, setDisplay] = useState(false);
    useEffect(() => {
        const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

        if (!loginCheck || errorResponce === 4) {
            navigate("/");
        }

    }, []);

    useEffect(() => {
        if (ratesListResult.result !== undefined) {
            const result = ratesListResult.result;
            if (result.length > 0) {
                setUserData(ratesListResult.result);
                setUserRecordsData(ratesListResult.records);
                setErrorResponce(ratesListResult.error_code);
            } else {
                setUserData("");
                setUserRecordsData("");
            }
        }
    }, [ratesListResult]);



    // useEffect(() => {
    //   const getData = setTimeout(() => {
    //     dispatch(getTruckerReportingList({ searchType: searchType, search: searchKeyword, offset, start: formik.values.start_date ,end: formik.values.end_date  }));      
    //   }, 500);
    //   return () => clearTimeout(getData);
    // }, [searchKeyword]);


    /** For pagination code start here **/
    var offset = currentPage * perPage;
    const handlePageClick = (selectedPage) => {
        dispatch(
            getMainReportingList({ searchType: searchType, search: searchKeyword, offset: selectedPage, start: formik.values.start_date, end: formik.values.end_date, pay_type: formik.values.pay_type })
        );
    };
    /** For pagination code end here **/

    const handleChange = (event) => {
        if (searchType) {
            setSearchKeyword(event.target.value.trim());
        }
    };

    const handleDownload = (type) => {
       
        
      };
      
    
      
    const callPageRecords = (userData) => {
        //console.log(userData)
        if (userData) {




            let text = userData.map((data, index) => {

                if (formik.values.pay_type == 1 && data.total_usd != 0) {
                    return (<tr key={index}>

                        <td>{data.job_number}</td>
                        <td>{data.job_date  !="0000-00-00" && data.job_date !=null ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.customer_name}</td>

                        <td>{data.when_paid  !="0000-00-00" && data.when_paid !=null ? moment(data.when_paid).format("YYYY-MM-DD") : "" }</td>
                        <td>{data.total_payable_usd}</td>
                        <td>{data.total_payable_cad}</td>
                        <td>{data.total_usd}</td>
                        <td>{data.total_cad}</td>
                        <td>{data.gain_usd}</td>
                        <td>{data.gain_cad}</td>
                    </tr>)
                }

                if (formik.values.pay_type == 2) {
                    return (<tr key={index}>

                        <td>{data.job_number}</td>
                        <td>{data.job_date   !="0000-00-00" && data.job_date !=null ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.customer_name}</td>
                        <td>{data.total_recievable_usd}</td>
                        <td>{data.total_recievable_cad}</td>
                    </tr>)
                }

                if (formik.values.pay_type == 3) {
                    return (<tr key={index}>

                        <td>{data.job_number}</td>
                        <td>{data.job_date  !="0000-00-00" && data.job_date !=null  ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.customer_name}</td>
                        <td>{data.shipping_line_id}</td>
                        <td>{data.ssl_amount + data.ssl1_amount}</td>
                        <td>{data.ssl_cad + data.ssl1_cad}</td>
                        <td>{data.ssl_paid_date !="0000-00-00" && data.ssl_paid_date !=null ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.ssl_paid_remarks}</td>
                    </tr>)
                }

                if (formik.values.pay_type == 3) {
                    return (<tr key={index}>

                        <td>{data.job_number}</td>
                        <td>{data.job_date   !="0000-00-00" && data.job_date !=null ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.customer_name}</td>
                        <td>{data.shipping_line_id}</td>
                        <td>{data.ssl_amount + data.ssl1_amount}</td>
                        <td>{data.ssl_cad + data.ssl1_cad}</td>
                        <td>{data.ssl_paid_date !="0000-00-00" && data.ssl_paid_date !=null ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.ssl_paid_remarks}</td>
                    </tr>)
                }
                if (formik.values.pay_type == 4) {
                    return (<tr key={index}>

                        <td>{data.job_number}</td>
                        <td>{data.job_date  !="0000-00-00" && data.job_date !=null ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.customer_name}</td>
                        <td>{data.shipping_line_id}</td>
                        <td>{data.ssl_amount + data.ssl1_amount}</td>
                        <td>{data.ssl_cad + data.ssl1_cad}</td>
                        <td>{data.ssl_paid_date !="0000-00-00" && data.ssl_paid_date !=null ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.ssl_paid_remarks}</td>
                    </tr>)
                }


                if (formik.values.pay_type == 5) {
                    return (<tr key={index}>

                        <td>{data.job_number}</td>
                        <td>{data.job_date  !="0000-00-00" && data.job_date !=null ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.customer_name}</td>
                        <td>{data.trucker_name}</td>
                        <td>{data.trucker_amount}</td>
                        <td>{data.trucker_paid_amount}</td>
                        <td>{data.trucker_cad}</td>
                        <td>{data.trucker_paid_cad}</td>
                    </tr>)
                }

                if (formik.values.pay_type == 6) {
                    return (<tr key={index}>

                        <td>{data.job_number}</td>
                        <td>{data.job_date  !="0000-00-00" && data.job_date !=null ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.customer_name}</td>
                        <td>{data.trucker_name}</td>
                        <td>{data.trucker_amount}</td>
                        <td>{data.trucker_cad}</td>
                    </tr>)
                }

            })
            console.log(text);
            return text


        } else {
            return (
                <tr className="text-center">
                    <td colSpan={10}>No Record Found</td>
                </tr>
            );
        }
    };


    const callPageRecords2 = (userData) => {
        if (userData) {
            if (formik.values.pay_type == 1) {
                let total_payable_usd = 0;
                let total_payable_cad = 0;
                let total_recived_usd = 0;
                let total_recived_cad = 0;
                let total_gain_usd = 0;
                let total_gain_cad = 0
                userData.forEach(element => {
                    total_payable_usd += element.total_payable_usd;
                    total_payable_cad += element.total_payable_cad;
                    total_recived_usd += element.total_recievable_usd;
                    total_recived_cad += element.total_recievable_cad;
                    total_gain_usd += element.gain_usd;
                    total_gain_cad += element.gain_cad;
                });

                return (
                    <>
                        <span>Total Payable Amount USD: <b> ${parseFloat(total_payable_usd).toFixed(2)}</b></span><br />
                        <span>Total Payable Amount CAD: <b>{parseFloat(total_payable_cad).toFixed(2)}</b></span><br />
                        <br />
                        <span>Total Recieved Amount USD: <b>$ {parseFloat(total_recived_usd).toFixed(2)}</b></span><br />
                        <span>Total Recieved Amount CAD: <b>{parseFloat(total_recived_cad).toFixed(2)}</b></span><br />

                        <br />
                        <span>Total Net Gain USD: <b> $ {parseFloat(total_gain_usd).toFixed(2)}</b></span><br />
                        <span>Total Net Gain CAD: <b>{parseFloat(total_gain_cad).toFixed(2)}</b></span><br />
                    </>
                )

            }

            if (formik.values.pay_type == 2) {
                let total_recievable_usd = 0;
                let total_recievable_cad = 0;
                userData.forEach(element => {
      
                    total_recievable_usd += element.total_recievable_usd;
                    total_recievable_cad += element.total_recievable_cad;
                });

                return (
                    <>
                        <span>Total Recievable Amount USD: <b> ${parseFloat(total_recievable_usd).toFixed(2)}</b></span><br />
                        <span>Total Recievable Ammount CAD: <b>{parseFloat(total_recievable_cad).toFixed(2)}</b></span><br />
                       
                    </>
                )

            }


            if (formik.values.pay_type == 3) {
                let total_shiping_line_usd = 0;
                let total_shiping_line_cad = 0;
                userData.forEach(element => {
                    total_shiping_line_usd += element.ssl_amount +element.ssl1_amount ;
                    total_shiping_line_cad += element.ssl_cad +element.ssl1_cad ;
                    
                });

                return (
                    <>
                        <span>Total Paid Ammount USD: <b> ${parseFloat(total_shiping_line_usd).toFixed(2)}</b></span><br />
                        <span>Total Paid Amount CAD: <b>{parseFloat(total_shiping_line_cad).toFixed(2)}</b></span><br />
                       
                    </>
                )

            }

            if (formik.values.pay_type == 4) {
                let total_shiping_line_usd = 0;
                let total_shiping_line_cad = 0;
                userData.forEach(element => {
                    total_shiping_line_usd += element.ssl_amount +element.ssl1_amount ;
                    total_shiping_line_cad += element.ssl_cad +element.ssl1_cad ;
                    
                });

                return (
                    <>
                        <span>Total Paid Ammount USD: <b> ${parseFloat(total_shiping_line_usd).toFixed(2)}</b></span><br />
                        <span>Total Paid Amount CAD: <b>{parseFloat(total_shiping_line_cad).toFixed(2)}</b></span><br />
                       
                    </>
                )

            }
            if (formik.values.pay_type == 5) {
                let total_trucker_usd = 0;
                let total_trucker_paid_usd = 0;

                let total_trucker_cad = 0;
                let total_trucker_paid_cad = 0;
                userData.forEach(element => {
                    total_trucker_usd += element.trucker_amount ;
                    total_trucker_paid_usd += element.trucker_paid_amount ;
                    total_trucker_cad  += element.trucker_cad ;
                    total_trucker_paid_cad  += element.trucker_paid_cad ;
                    
                });

                return (
                    <>
                        <span>Total Trucker Amount USD: <b> ${parseFloat(total_trucker_usd).toFixed(2)}</b></span><br />
                        <span>Total Trucker Paid USD: <b> ${parseFloat(total_trucker_paid_usd).toFixed(2)}</b></span><br />
                        <span>Balance USD: <b> ${parseFloat(total_trucker_usd = total_trucker_paid_usd).toFixed(2)}</b></span><br />
                       
                        <span>Total Trucker Amount CAD: <b> ${parseFloat(total_trucker_cad).toFixed(2)}</b></span><br />
                        <span>Total Trucker Paid CAD: <b>{parseFloat(total_trucker_paid_cad).toFixed(2)}</b></span><br />
                        <span>Balance CAD: <b>{parseFloat(total_trucker_cad = total_trucker_paid_cad).toFixed(2)}</b></span><br />
                       
                       
                    </>
                )

            }

            if (formik.values.pay_type == 6) {
                let total_trucker_usd = 0;
                let total_trucker_paid_usd = 0;
                userData.forEach(element => {
                    total_trucker_usd += element.trucker_amount ;
                    total_trucker_paid_usd += element.trucker_cad ;
                    
                });

                return (
                    <>
                        <span>Total Trucker Payable USD: <b> ${parseFloat(total_trucker_usd).toFixed(2)}</b></span><br />
                        <span>Total Trucker Payable CAD: <b>{parseFloat(total_trucker_paid_usd).toFixed(2)}</b></span><br />
                       
                    </>
                )

            }




        } else {
            return (
                ""
            );
        }
    };


    const deleteUser = async (userId2) => {
        //dispatch(deleteRatesById(userId2));
        setShowModel(false);
        setDeleteModel(false);

    };





    const handleSearhChange = (event) => {
        setSearchType(event.target.value);
    };

    const validateContactFrom = Yup.object().shape({
        end_date: Yup.string().required('Field is required'),
         start_date: Yup.string().required('Field is required'),
         pay_type: Yup.number().required('Field is required'),
    });
    let initialValues = {
        end_date: '',
        start_date: ""
    }
    
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {

            dispatch(getMainReportingList({ searchType: searchType, search: searchKeyword, offset, start: values.start_date, end: values.end_date, pay_type: values.pay_type }));

        },
    });

    return (
        <>
            <AdminLayout pageHeading="Main Reporting" pageIcon="fa fa-bar-chart">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <h3>Main Reporting</h3>
                        </div>
                    </div>
                    <div className="container-fluid demo">
                        <div
                            className="panel-group dashboard-table-format"
                            id="accordion"
                            role="tablist"
                            aria-multiselectable="true"
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="headingOne"
                                >
                                    <div className="panel-body">
                                        <div className="card  mb-4">
                                            <div className="card-body">
                                            <Loader display={display}/>
                                                    <div
                                                        id="dataTable_wrapper"
                                                        className="dataTables_wrapper dt-bootstrap4"
                                                    >

                                                        <div className="deposited p-0">
                                                            <form onSubmit={formik.handleSubmit}>


                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-6 col-md-3">
                                                                    <div className="form-group">
                                                                        <label>Start Date</label>
                                                                        <input className="form-control"
                                                                            type="date"
                                                                            name="start_date"
                                                                            {...formik.getFieldProps('start_date')}
                                                                        />
                                                                        {formik.touched.start_date && formik.errors.start_date && (
                                                                            <div className="errorMsg text-danger">{formik.errors.start_date}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className=" col-sm-4 col-md-3">
                                                                    <div className="form-group">
                                                                        <label>End Date</label>
                                                                        <input className="form-control"
                                                                            type="date"
                                                                            name="end_date"
                                                                            {...formik.getFieldProps('end_date')}
                                                                        />
                                                                        {formik.touched.end_date && formik.errors.end_date && (
                                                                            <div className="errorMsg text-danger">{formik.errors.end_date}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>


                                                                    <div className="col-sm-6 col-md-3">
                                                                    <div className="form-group">
                                                                        <label>Select reporting option</label>
                                                                        <select name="pay_type" className="form-control user-success"

                                                                            {...formik.getFieldProps('pay_type')}
                                                                        >

                                                                            <option value="">--Select Type--</option>
                                                                            <option value="1">Recieved</option>
                                                                            <option value="2">Recievable</option>
                                                                            <option value="3">Paid to shipping line</option>
                                                                            <option value="4">Payable to shipping line</option>
                                                                            <option value="5">Paid to trucker</option>
                                                                            <option value="6">Payable to trucker</option>

                                                                        </select>
                                                                        {formik.touched.pay_type && formik.errors.pay_type && (
                                                                            <div className="errorMsg text-danger">{formik.errors.pay_type}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className="col-sm-12 col-md-3">
                                                                        <input type="submit" className="btn btn-primary mr-2" name="search" value="Go" style={{ marginTop: "15px" }} />
                                                                        <button className="action-tab btn btn-primary" onClick={item => handleDownload("excel")} style={{ marginTop: "15px", display: "none" }} data-toggle="tooltip" data-placement="top" title="Export as Excel file"><i class="fa fa-file-excel-o"></i></button>
                                                                        <button className="action-tab btn btn-primary" onClick={item => handleDownload("pdf")} style={{marginLeft: "10px", marginTop: "15px",display: "none" }} data-toggle="tooltip" data-placement="top" title="Export as PDF file"><i class="fa fa-file-pdf-o"></i></button>
                                                                    </div>

                                                                </div>
                                                            </form>

                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                    <table className="table table-striped table-bordered" id="pdf">
                                                        <thead>
                                                            <tr className="bg-primary text-white">

                                                                <th>Job No.</th>
                                                                <th>Job Date</th>
                                                                <th>Customer Name</th>

                                                                {(formik.values.pay_type == 1) && <>
                                                                    <th>Date Receipt</th>
                                                                    <th>Payable USD</th>
                                                                    <th>Payable CAD</th>
                                                                    <th>Received USD</th>
                                                                    <th>Received CAD</th>
                                                                    <th>Net Gain USD</th>
                                                                    <th>Net Gain CAD</th>
                                                                </>}

                                                                {(formik.values.pay_type == 2) && <>
                                                                    <th>Recievable USD</th>
                                                                    <th>Recievable CAD</th>
                                                                </>}

                                                                {(formik.values.pay_type == 3) && <>
                                                                    <th>Shipping Line</th>
                                                                    <th>Ammount Paid USD</th>
                                                                    <th>Ammount Paid CAD</th>
                                                                    <th>When Paid</th>
                                                                    <th>Mode of Payment</th>
                                                                </>}

                                                                {(formik.values.pay_type == 4) && <>
                                                                    <th>Shipping Line</th>
                                                                    <th>Ammount Payable USD</th>
                                                                    <th>Ammount Payable CAD</th>
                                                                </>}

                                                                {(formik.values.pay_type == 5) && <>
                                                                    <th>Trucker Name</th>
                                                                    <th>Amount USD</th>
                                                                    <th>Amount Paid USD</th>
                                                                    <th>Amount CAD</th>
                                                                    <th>Amount Paid CAD</th>
                                                                </>}
                                                                {(formik.values.pay_type == 6) && <>
                                                                    <th>Trucker Name</th>
                                                                    <th>Payable Amount USD</th>
                                                                    <th>Payable Amount CAD</th>
                                                                </>}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ratesListResult.result !==
                                                                undefined &&
                                                                ratesListResult.result !== "" ? (
                                                                callPageRecords(
                                                                    ratesListResult.result
                                                                )
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={10}>No Record Found</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    {ratesListResult.result !==
                                                                undefined &&
                                                                ratesListResult.result !== "" ? (
                                                                callPageRecords2(
                                                                    ratesListResult.result
                                                                )
                                                            ) : (
                                                               ""
                                                            )}

                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-5">
                                                        {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                                       */}
                                                    </div>
                                                    <div className="col-sm-12 col-md-7">
                                                        <div
                                                            className="dataTables_paginate paging_simple_numbers"
                                                            id="dataTable_paginate"
                                                        >
                                                            {userData != undefined &&
                                                                userData.length > 0 &&
                                                                userRecordsData !== undefined ? (
                                                                <PaginationBlock
                                                                    perPage={perPage}
                                                                    userRecordsData={userRecordsData}
                                                                    callHandlePageClick={(data) =>
                                                                        handlePageClick(data)
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </AdminLayout>
        </>
    );
};
export default Contact;
