const TYPES=[];
TYPES['AxiosError']='Api Server Not Connected / Network Error ,Please login again';
TYPES['notValidUser']='Please check your credentials';
TYPES['clientInfoSucess']='Client information updated sucessfully';
TYPES['clientInfoError']='There is an issue to update client information';
TYPES['adminLoginSucess']='Admin login sucessfully';
TYPES['clientCreated']='Client created sucessfully';

TYPES['userAdded']='User added sucessfully';
TYPES['userAddedError']='There is an issue for adding user';
TYPES['userDelete']='User deleted sucessfully';
TYPES['userUpdated']='User updated sucessfully';
TYPES['userUpdatedError']='There is an issue to update user';
TYPES['userDeleteError']='There is an issue to delete user';
TYPES['paymentAdded']='Payment details added sucessfully';
TYPES['paymentAddedError']='There is an issue to add payment details';
TYPES['usernameAlreadyExist']='Username already exist';

TYPES['eventAdded']='Event added sucessfully';
TYPES['eventAddedError']='There is an issue for adding event';
TYPES['eventUpdated']='Event updated sucessfully';
TYPES['eventUpdatedError']='There is an issue for updating event';

TYPES['exhibitorAdded']='Exhibitor added sucessfully';
TYPES['exhibitorAddedError']='There is an issue for adding Exhibitor';

TYPES['exhibitorBoothMembersAddedError']='Exhibitor Booth Members added sucessfully';
TYPES['exhibitorBoothMembersAddedErrorError']='There is an issue for adding Exhibitor Booth Members';


TYPES['dataSaved']='The data has been saved';
TYPES['dataSavedError']='There is an issue while updating data';

TYPES['dataExistError']='This Code is already exists on same event';
TYPES['userPasswordChangeSuccess']='Password changed successfully';
TYPES['userPasswordChangeError']='There is an issue in changing password';


TYPES['serviceDeleteError']='There is an issue to delete service';
TYPES['serviceDelete']='Service deleted sucessfully';
TYPES['serviceUpdated']='Service updated sucessfully';
TYPES['serviceUpdatedError']='There is an issue to update service';
TYPES['serviceAdded']='Service added sucessfully';
TYPES['serviceAddedError']='There is an issue in addeding service';


TYPES['carrierDeleteError']='There is an issue to delete carrier';
TYPES['carrierDelete']='carrier deleted sucessfully';
TYPES['carrierUpdated']='carrier updated sucessfully';
TYPES['carrierUpdatedError']='There is an issue to update carrier';
TYPES['carrierAdded']='carrier added sucessfully';
TYPES['carrierAddedError']='There is an issue in addeding carrier';


TYPES['ratesDeleteError']='There is an issue to delete rates';
TYPES['ratesDelete']='Rates deleted sucessfully';
TYPES['ratesUpdated']='Rates updated sucessfully';
TYPES['ratesUpdatedError']='There is an issue to update rates';
TYPES['ratesAdded']='Rates added sucessfully';
TYPES['ratesAddedError']='There is an issue in addeding rates';

TYPES['truckerNameDeleteError']='There is an issue to delete Trucker Name';
TYPES['truckerNameDelete']='Trucker Name deleted sucessfully';
TYPES['truckerNameUpdated']='Trucker Name updated sucessfully';
TYPES['truckerNameUpdatedError']='There is an issue to update Trucker Name';
TYPES['truckerNameAdded']='Trucker Name added sucessfully';
TYPES['truckerNameAddedError']='There is an issue in addeding Trucker Name';


TYPES['importDocumentCoordinatorDeleteError']='There is an issue to delete import document coordinator';
TYPES['importDocumentCoordinatorDelete']='Import document coordinator name deleted sucessfully';
TYPES['importDocumentCoordinatorUpdated']='Import document coordinator name updated sucessfully';
TYPES['importDocumentCoordinatorUpdatedError']='There is an issue to update import document coordinator name';
TYPES['importDocumentCoordinatorAdded']='Import document coordinator name added sucessfully';
TYPES['importDocumentCoordinatorAddedError']='There is an issue in addeding import document coordinator name';


TYPES['exportDocumentCoordinatorDeleteError']='There is an issue to delete export document coordinator';
TYPES['exportDocumentCoordinatorDelete']='Export document coordinator name deleted sucessfully';
TYPES['exportDocumentCoordinatorUpdated']='Export document coordinator name updated sucessfully';
TYPES['exportDocumentCoordinatorUpdatedError']='There is an issue to update export document coordinator name';
TYPES['exportDocumentCoordinatorAdded']='Export document coordinator name added sucessfully';
TYPES['exportDocumentCoordinatorAddedError']='There is an issue in addeding export document coordinator name';

TYPES['containerDeleteError']='There is an issue to delete container';
TYPES['containerDelete']='Container deleted sucessfully';
TYPES['containerUpdated']='Container updated sucessfully';
TYPES['containerUpdatedError']='There is an issue to update container';
TYPES['containerAdded']='Container added sucessfully';
TYPES['containerAddedError']='There is an issue in addeding container';


TYPES['jobsDeleteError']='There is an issue to delete jobs';
TYPES['jobsDelete']='Jobs deleted sucessfully';
TYPES['jobsUpdated']='Jobs updated sucessfully';
TYPES['jobsUpdatedError']='There is an issue to update jobs';
TYPES['jobsAdded']='Jobs added sucessfully';
TYPES['jobsDeleteError']='There is an issue to delete jobs';

TYPES['cargoDelete']='Cargo deleted sucessfully';
TYPES['cargoUpdated']='Cargo updated sucessfully';
TYPES['cargoAdded']='Cargo added sucessfully';

TYPES['notificationDelete']='Notification deleted sucessfully';
TYPES['notificationUpdated']='Notification updated sucessfully';
TYPES['notificationAdded']='Notification added sucessfully';

TYPES['shippingLineRateDelete']='Shipping Line deleted sucessfully';
TYPES['shippingLineRateUpdated']='Shipping Line updated sucessfully';
TYPES['shippingLineRateAdded']='Shipping Line added sucessfully';

TYPES['customerRateHistoryDelete']='Customer Rate deleted sucessfully';
TYPES['customerRateHistoryUpdated']='Customer Rate updated sucessfully';
TYPES['customerRateHistoryAdded']='Customer Rate added sucessfully';

TYPES['documentationDeleteError']='There is an issue to delete documentation';
TYPES['documentationDelete']='Documentation deleted sucessfully';
TYPES['documentationUpdated']='Documentation updated sucessfully';
TYPES['documentationUpdatedError']='There is an issue to update documentation';
TYPES['documentationAdded']='Documentation added sucessfully';
TYPES['documentationAddedError']='There is an issue in addeding documentation';

TYPES['accountFileAdded']='File uploaded sucessfully';
TYPES['accountFileError']='There is an issue in addeding file';

TYPES['fileDeletedError']='There is an issue in deleting the files';
TYPES['fileDeletedSuccess']='File deleted Success';

TYPES['currencyNotFound']='Please select a currency';
TYPES['ratesNotFound']='Please input rates for today';

TYPES['accountsAdded']='Accounts added sucessfully';
TYPES['accountsAddedError']='There is an issue in addeding accounts';
TYPES['accountsUpdated']='accounts added sucessfully';
TYPES['accountsUpdatedError']='There is an issue in addeding accounts';

TYPES['fileDownloadedSuccess']='File Downloaded Success';
TYPES['fileDownloadedError']='File Downloaded Error';

TYPES['jobCommentUpdatedError']='There is an issue in updating the Comment';
TYPES['jobCommentUpdateSuccess']='Comment update Success';
TYPES['jobCommentDeletedError']='There is an issue in deleting the Comment';
TYPES['jobCommentDeletedSuccess']='Comment deleted Success';

TYPES['jobVinDeletedError']='There is an issue in deleting the Vin';
TYPES['jobVinDeletedSuccess']='Vin deleted Success';

TYPES['quotesDeleteError']='There is an issue to delete quotes';
TYPES['quotesDelete']='Quotes deleted sucessfully';
TYPES['quotesUpdated']='Quotes updated sucessfully';
TYPES['quotesUpdatedError']='There is an issue to update quotes';
TYPES['quotesAdded']='Quotes added sucessfully';
TYPES['quotesAddedError']='There is an issue in addeding quotes';



TYPES['taskDeleteError']='There is an issue to delete task';
TYPES['taskDelete']='task deleted sucessfully';
TYPES['taskUpdated']='task updated sucessfully';
TYPES['taskUpdatedError']='There is an issue to update task';
TYPES['taskAdded']='Task added sucessfully';
TYPES['taskAddedError']='There is an issue in adding task';


TYPES['originDeleteError']='There is an issue to delete origin';
TYPES['originDelete']='Origin deleted sucessfully';
TYPES['originUpdated']='Origin updated sucessfully';
TYPES['originUpdatedError']='There is an issue to update origin';
TYPES['originAdded']='Origin added sucessfully';
TYPES['originAddedError']='There is an issue in addeding origin';


TYPES['destinationDeleteError']='There is an issue to delete destination';
TYPES['destinationDelete']='Destination deleted sucessfully';
TYPES['destinationUpdated']='Destination updated sucessfully';
TYPES['destinationUpdatedError']='There is an issue to update destination';
TYPES['destinationAdded']='Destination added sucessfully';
TYPES['destinationAddedError']='There is an issue in addeding destination';


TYPES['meetingDeleteError']='There is an issue to delete meeting';
TYPES['meetingDelete']='Meeting deleted sucessfully';
TYPES['meetingUpdated']='Meeting updated sucessfully';
TYPES['meetingUpdatedError']='There is an issue to update meeting';
TYPES['meetingAdded']='meeting added sucessfully';
TYPES['meetingAddedError']='There is an issue in addeding meeting';

TYPES['containerReportingError']='Please select the filters first';


TYPES['recordDeletedError']='There is an issue in deleting the record';
TYPES['recordDeletedSuccess']='Record deleted Success';

export default TYPES;