import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
export function DestinationForm(props) {

    const [fields, setFields] = useState(props.userDetails ? props.userDetails : {});

    const validateContactFrom = Yup.object().shape({
        port_code: Yup.string().required('Port Code is required'),
        name: Yup.string().required('Name is required'),
        country: Yup.string().required('Country is required')
    });

    let initialValues = {
        name: fields.name ?? '',
        port_code: fields.port_code ?? '',
        country: fields.country ?? '',
        certificate_of_origin: fields.certificate_of_origin ?? '0'
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            let postData = {
                name: values.name,
                port_code: values.port_code,
                country: values.country,
                certificate_of_origin: values.certificate_of_origin
            }
            if (fields?.id) {
                postData['id'] = fields.id;
                props.editUserDetails(postData);
            } else {
                props.addUserDetails(postData);
            }
        },
    });

    return (
        <>
            <div className="modal-body invitation-wrap">
                <div className="login-wrap-new">
                    <div className="form-content">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        {...formik.getFieldProps('name')}
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <div className="errorMsg text-danger">{formik.errors.name}</div>
                                    )}
                                </div>
                                <div className="col-md-6 col-12">
                                    <label>Required Certificate</label>
                                    <select className="form-control"
                                        name="certificate_of_origin"
                                        id="certificate_of_origin"
                                        {...formik.getFieldProps('certificate_of_origin')}
                                    >
                                        <option>--Certificate of Origin--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    {formik.touched.certificate_of_origin && formik.errors.certificate_of_origin && (
                                        <div className="errorMsg text-danger">{formik.errors.certificate_of_origin}</div>
                                    )}
                                </div>
                                <div className="col-md-6 col-12">
                                    <label>Port Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="port_code"
                                        name="port_code"
                                        {...formik.getFieldProps('port_code')}
                                    />
                                    {formik.touched.port_code && formik.errors.port_code && (
                                        <div className="errorMsg text-danger">{formik.errors.port_code}</div>
                                    )}
                                </div>
                                <div className="col-md-6 col-12">
                                    <label>Country</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="country"
                                        name="country"
                                        {...formik.getFieldProps('country')}
                                    />
                                    {formik.touched.country && formik.errors.country && (
                                        <div className="errorMsg text-danger">{formik.errors.country}</div>
                                    )}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <button type="submit" className="btn btn-primary">
                                        {fields.id ? 'Save Destination Port' : 'Save Destination Port'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}