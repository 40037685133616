import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ModalBox } from "../../../../Components/Ui/ModalBox";
import {
  getcustomerList,
  editCustomerDetails,
  changeUserPassword,
} from "../../../../Services/Store/Common/customer/customer.action";
import { CustomerForm } from "./CustomerForm";
import { ChangePasswordForm } from "./ChangePasswordForm";
import AdminLayout from "../../../../Layout";
import { customerList } from "../../../../Services/Store/Common/customer/customer.selector";

const Account = () => {
  const currentUser = useSelector((state) => state.user);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [modalData, setModalData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerListResult = useSelector(customerList);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("customerToken"));
    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('customerToken');
      navigate("/");
    }
  }, [errorResponce]);

  useEffect(() => {
    if (customerListResult.result !== undefined) {
      const result = customerListResult.result;
      if (result.length > 0) {
        setErrorResponce(customerListResult.error_code);
      }
    }
  }, [customerListResult]);

  useEffect(() => {
    dispatch(getcustomerList({ id: currentUser.user.id, role: "customer" }));
  }, []);

  const _handleEditClick = (userDetails) => {
    setModalData(userDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleChangePasswordClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setChangePasswordModel(true);
  }

  const callPageRecords = (userData) => {
    if (userData) {
      return userData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.firstname}</td>
          <td> {data.lastname}</td>
          <td> {data.email}</td>
          <td> {data.mobile_number}</td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit User"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <button
              type="button"
              className="bd-none btn btn-success  btn-xsm"
              onClick={() => _handleChangePasswordClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Change Password"
            >
              <i className="fa fa-fw fa-key"></i>
            </button>
            <Link state={{ customerId: data.id }} className="bd-none btn btn-info btn-xsm" to="/customer/contact" data-toggle="tooltip" data-placement="top" title="Contacts Listing">
              <i className="fa fa-fw fa-address-book"></i>
            </Link>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={8}>No Record Found</td>
        </tr>
      );
    }
  };

  const changePasswordUser = async (params) => {
    dispatch(changeUserPassword(params));
    setShowModel(false);
    setChangePasswordModel(false);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
    }
  };

  const submitEditUserDetails = async (params) => {
    params.role = "customer"
    dispatch(editCustomerDetails(params));
    setShowModel(false);
  };

  return (
    <>
      <AdminLayout pageHeading="Customers" pageIcon="fa fa-male">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Customer Profile</h1>
          <div className="card shadow mb-4">
            <div className="card-body">              
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Mobile Number</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerListResult.result !==
                          undefined &&
                          customerListResult.result !== "" ? (
                          callPageRecords(
                            customerListResult.result
                          )
                        ) : (
                          <tr>
                            <td colSpan={8}>No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                      </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalBox
            show={showModel}
            title={changePasswordModel ? 'Change Password Form' : 'Customer Form'}
            size={changePasswordModel ? 'md' : 'xl'}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setChangePasswordModel(false);
            }}
          >
            {editModel === true ? (
              <CustomerForm
                editUserDetails={(params) => submitEditUserDetails(params)}
                userDetails={modalData}
              />
            ) : changePasswordModel === true ? (
              <ChangePasswordForm
                userId={userId}
                changeUserPassword={(params) => changePasswordUser(params)}
              />
            ) : (
              ""
            )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default Account;
