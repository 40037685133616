import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
    getEmployeeReportingList
} from "../../../Services/Store/Common/employeeReport/employeeReport.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { employeeReportingList } from "../../../Services/Store/Common/employeeReport/employeeReport.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
const Contact = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [userData, setUserData] = useState([]);
    const [userId, setUserId] = useState("");
    const [errorResponce, setErrorResponce] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [addModel, setAddModel] = useState(false);
    const [perPage, setPerPage] = useState(25);
    const [modalData, setModalData] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [userRecordsData, setUserRecordsData] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ratesListResult = useSelector(employeeReportingList);
    // const mainClientresponse = useSelector(mainClient);
    const [searchType, setSearchType] = useState("");
    const [employeeData, setEmployeeData] = useState("");

    useEffect(() => {
        const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

        if (!loginCheck || errorResponce === 4) {
            navigate("/");
        }

    }, []);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/tslUserListById", {
                params: { offset: 0, limit: 9999999999999 },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setEmployeeData(response?.data?.data);
            } else {
                setEmployeeData("");
            }
        }

        fetchMyAPI2();

    }, []);


    useEffect(() => {
        if (ratesListResult.result !== undefined) {
            const result = ratesListResult.result;
            if (result.length > 0) {
                setUserData(ratesListResult.result);
                setUserRecordsData(ratesListResult.records);
                setErrorResponce(ratesListResult.error_code);
            } else {
                setUserData("");
                setUserRecordsData("");
            }
        }
    }, [ratesListResult]);



    // useEffect(() => {
    //   const getData = setTimeout(() => {
    //     dispatch(getTruckerReportingList({ searchType: searchType, search: searchKeyword, offset, start: formik.values.start_date ,end: formik.values.end_date  }));      
    //   }, 500);
    //   return () => clearTimeout(getData);
    // }, [searchKeyword]);


    /** For pagination code start here **/
    var offset = currentPage * perPage;
    const handlePageClick = (selectedPage) => {
        dispatch(
            getEmployeeReportingList({ searchType: searchType, search: searchKeyword, offset: selectedPage, start: formik.values.start_date, end: formik.values.end_date, employee_name: formik.values.employee_name })
        );
    };
    /** For pagination code end here **/

    const handleChange = (event) => {
        if (searchType) {
            setSearchKeyword(event.target.value.trim());
        }
    };



    const callPageRecords = (userData) => {
        //console.log(userData)
        if (userData) {




            let text = userData.map((data, index) => {
                console.log(data.minutes , typeof data.minutes);
                var totalMinutes  = parseInt(data.minutes); 
                var hours = Math.floor(totalMinutes / 60);          
                var minutes = totalMinutes % 60;


                return (<tr key={index}>
                    <td className="">{data.name}</td>
                    <td className="">{data.create_date ? moment(data.create_date).format("YYYY-MM-DD") : ""}</td>
                    <td className= { hours < 4 ? "bg-danger" : ""}>{hours} hours {minutes} minutes</td>
            
            
                    </tr>)
                
                

               

            })
            return text


        } else {
            return (
                <tr className="text-center">
                    <td colSpan={6}>No Record Found</td>
                </tr>
            );
        }
    };



    

   


    const deleteUser = async (userId2) => {
        //dispatch(deleteRatesById(userId2));
        setShowModel(false);
        setDeleteModel(false);

    };





    const handleSearhChange = (event) => {
        setSearchType(event.target.value);
    };

    const validateContactFrom = Yup.object().shape({
        end_date: Yup.string().required('Field is required'),
        start_date: Yup.string().required('Field is required'),
       // employee_name: Yup.number().required('Field is required'),


    });
    let initialValues = {
        end_date: '',
        start_date: "",
        employee_name: "",
       
    }
   
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {

            dispatch(getEmployeeReportingList({ searchType: searchType, search: searchKeyword, offset, start: values.start_date, end: values.end_date, employee_name: formik.values.employee_name }));

        },
    });

    return (
        <>
            <AdminLayout pageHeading="Employee Report" pageIcon="fa fa-bar-chart">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <h3>Employee Report</h3>
                        </div>
                    </div>
                    <div className="container-fluid demo">
                        <div
                            className="panel-group dashboard-table-format"
                            id="accordion"
                            role="tablist"
                            aria-multiselectable="true"
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="headingOne"
                                >
                                    <div className="panel-body">
                                        <div className="card  mb-4">
                                            <div className="card-body">
                                                
                                                    <div
                                                        id="dataTable_wrapper"
                                                        className="dataTables_wrapper dt-bootstrap4"
                                                    >

                                                        <div className="deposited p-0">
                                                            <form onSubmit={formik.handleSubmit}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label>Select Employee</label>
                                                                        <select name="employee_name" className="form-control user-success"
                                                                            {...formik.getFieldProps('employee_name')}
                                                                        >
                                                                            <option>Select</option>
                                                                            {
                                                                                employeeData && employeeData.map((employee, index) => {
                                                                                    return (<option key={index} value={employee.id}>{employee.firstname + (employee.lastname ? ' ' + employee.lastname : '')}</option>);
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {formik.touched.employee_name && formik.errors.employee_name && (
                                                                            <div className="errorMsg text-danger">{formik.errors.employee_name}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>
                                                                 
                                                                    <div className=" col-sm-3">
                                                                    <div className="form-group">
                                                                        <label>Start Date</label>
                                                                        <input className="form-control"
                                                                            type="date"
                                                                            name="start_date"
                                                                            {...formik.getFieldProps('start_date')}
                                                                        />
                                                                        {formik.touched.start_date && formik.errors.start_date && (
                                                                            <div className="errorMsg text-danger">{formik.errors.start_date}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className=" col-sm-3">
                                                                        <div className="form-group">
                                                                            <label>End Date</label>
                                                                            <input className="form-control"
                                                                                type="date"
                                                                                name="end_date"
                                                                                {...formik.getFieldProps('end_date')}
                                                                            />
                                                                            {formik.touched.end_date && formik.errors.end_date && (
                                                                                <div className="errorMsg text-danger">{formik.errors.end_date}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className=" col-sm-3">
                                                                        <input type="submit" className="btn btn-primary" name="search" value="Go" style={{ marginTop: "15px" }} />
                                                                    </div>
                                                                    <div className="col-md-12 mt-2" style={{display:"none"}}>
                                                                        <button className="action-tab btn btn-primary mr-1 mb-1">Select All</button>
                                                                    </div>
                                                                </div>

                                                                
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                    <table className="table table-striped table-bordered" id="pdf">
                                                        <thead>
                                                            <tr className="bg-primary text-white">
                                                               
                                                                <th>Employee Name</th>
                                                                <th>Date</th>
                                                                <th>Working Hours</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ratesListResult.result !==
                                                                undefined &&
                                                                ratesListResult.result !== "" ? (
                                                                callPageRecords(
                                                                    ratesListResult.result
                                                                )
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={10}>No Record Found</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>

                                                 
                                                 
                                                            
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-5">
                                                        {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                                       */}
                                                    </div>
                                                    <div className="col-sm-12 col-md-7">
                                                        <div
                                                            className="dataTables_paginate paging_simple_numbers"
                                                            id="dataTable_paginate"
                                                        >
                                                            {userData != undefined &&
                                                                userData.length > 0 &&
                                                                userRecordsData !== undefined ? (
                                                                <PaginationBlock
                                                                    perPage={perPage}
                                                                    userRecordsData={userRecordsData}
                                                                    callHandlePageClick={(data) =>
                                                                        handlePageClick(data)
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </AdminLayout>
        </>
    );
};
export default Contact;
