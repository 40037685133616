import React, { useState, useEffect, useRef } from 'react';
import { requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import { CustomerBookings } from "../CustomerHistory/CustomerBookings";
import moment from "moment";
import InvoicePdfUsd from "./InvoicePdfUsd";
import InvoicePdfCad from "./InvoicePdfCad";
import InvoicePdfMix from "./InvoicePdfMix";

import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { Tooltip } from 'react-tooltip';

export function ViewForm(props) {
    const [fields, setFields] = useState([]);
    const [service, setService] = useState("");
    const [salesPerson, setSalesPerson] = useState("");
    const [releaseRcvd, setReleaseRcvd] = useState("");
    const [receivableData, setReceivableData] = useState([]);
    const [truckerName, setTruckerName] = useState("");
    const [totalReceivedCad, setTotalReceivedCad] = useState(0);
    const [totalReceivedUsd, setTotalReceivedUsd] = useState(0);
    const [displayItemCad, setDisplayItemCad] = useState(true);
    const [displayItemUsd, setDisplayItemUsd] = useState(true);
    const [displayItemMix, setDisplayItemMix] = useState(true);
    const [shipper, setShipper] = useState("");
    const [consignee, setConsignee] = useState("");
    const [extraCharges, setExtraCharges] = useState("");
    const [customerData, setCustomerData] = useState("");
    const [thirdPartyTotal, setThirdPartyTotal] = useState({usdTotal: 0, cadTotal: 0});

    const generatePDF = (id) => {
        document.getElementById(id).style.display = 'block';
        const input = document.getElementById(id);
        html2canvas(input, {
            logging: true,
            letterRendering: 1,
            scale: 2,
            windowWidth: 1440,
            useCORS: true,
            onclone: function (clonedDoc) {
                clonedDoc.getElementById(id).style.display = 'block';
            }
        }).then((canvas) => {
            var imgData = canvas.toDataURL("image/png");
            var imgWidth = 210;
            var pageHeight = 295;
            var imgHeight = (canvas.height * imgWidth) / canvas.width;
            var heightLeft = imgHeight;
            var doc = new jsPDF("p", "mm", "a4");
            var position = 0;
            doc.addImage(imgData, "jpeg", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, "jpeg", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            const pages = doc.internal.getNumberOfPages();

            for (let j = 1; j < pages + 1; j++) {
                let horizontalPos = imgWidth / 2; //Can be fixed number
                let verticalPos = pageHeight - 1; //Can be fixed number
                doc.setPage(j);
                doc.setFontSize(10);

                doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
                    align: "right"
                });
            }

            doc.save("invoice.pdf");
            document.getElementById(id).style.display = 'none';
        });
    }

    useEffect(() => {

        async function fetchMyAPI() {
            const response = await api.get("api/v1/viewJob", {
                params: { id: props.jobsDetails.id },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {

                setFields(response.data.data);

                (response.data.data[15]).map((item, i) => {
                    setThirdPartyTotal(data => {
                        return (
                            {
                                usdTotal: data.usdTotal + item.usd_amount,
                                cadTotal: data.cadTotal + item.cad_amount,
                            }
                        )
                    });
                });

                response.data.data[4].filter((item, index, arr) => {
                    if (item.id === props.jobsDetails.service_id) {
                        setService(item.name);
                    }
                });

                response.data.data[5].filter((item, index, arr) => {
                    if (item.id === props.jobsDetails.sale_person_id) {
                        setSalesPerson(item.firstname + (item.lastname ? " " + item.lastname : ""));
                    }
                });
                response.data.data[6].filter((item, index, arr) => {
                    if (item.id === props.jobsDetails.released_received_id) {
                        setReleaseRcvd(item.name);
                    }
                });

                setReceivableData(response.data.data[13]);

                (response.data.data[13]).map((item, i) => {
                    setTotalReceivedCad(data => data + item.cad_amount);
                    setTotalReceivedUsd(data => data + item.usd_amount);
                });

                props.jobsData[2].filter((item, index, arr) => {
                    if (item.id === props?.jobsDetails?.truker_id) {
                        setTruckerName(item.name);
                    }
                });
            } else {
                setFields("");
            }
        }
        fetchMyAPI();
    }, []);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/getCustomerById", {
                params: { id: props.jobsDetails.customer_id, deleteCond: true },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setCustomerData(response?.data?.data?.[0]);
            } else {
                setCustomerData("");
            }
        }
        fetchMyAPI2();
    }, []);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/getShipperById", {
                params: { id: props.jobsDetails.shipper },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setShipper(response?.data?.data?.[0]);
            } else {
                setShipper("");
            }
        }
        fetchMyAPI2();
    }, [props?.jobsDetails?.shipper]);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/getConsigneeById", {
                params: { id: props?.jobsDetails?.consignee },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setConsignee(response?.data?.data?.[0]);
            } else {
                setConsignee("");
            }
        }
        fetchMyAPI2();
    }, [props?.jobsDetails?.consignee]);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/tslFetchExtraCharges", {
                params: { id: props.jobsDetails.id },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setExtraCharges(response?.data?.data);
            } else {
                setExtraCharges("");
            }
        }
        fetchMyAPI2();
    }, []);
    return (
        <>
            <div className="modal-body">
                <div className="login-wrap-new">
                    <div className="form-content">
                        {/* booking page data */}
                        <div className="row border-bottom">
                            <div className="col-sm-6 mb-2">
                                <div className='head-block d-flex detail-view-table-head align-items-center'>
                                    <div className='img-block mr-4'>
                                        <img src="/assets/images/view-job.png" height="80" />
                                    </div>
                                    <div>
                                        <span className="view-title">Job No:</span> <code>{props.jobsDetails.job_number}</code><br />
                                        <span className="view-title">Job Status:</span> <code>{props.jobsDetails.job_status_name}</code><br />
                                        <span className="view-title">Job Date:</span> <code>{props.jobsDetails.job_date}</code><br />
                                        <span className="view-title">Job Completed:</span> <code>{fields?.[3]?.[0]?.job_completed}</code><br />
                                        <span className="view-title">Customer Name:</span> <code>{customerData?.firstname} {customerData?.lastname}</code>

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end align-items-center">
                                {/* <button className="btn btn-primary btn-sm text-white mr-2">Send Invoice</button> */}
                                <button className="btn btn-primary btn-sm text-white mr-2" onClick={item => generatePDF("invoice_usd")}>Invoice USD</button>
                                <button className="btn btn-primary btn-sm text-white mr-2" onClick={item => generatePDF("invoice_cad")}>Invoice CAD</button>
                                <button className="btn btn-primary btn-sm text-white" onClick={item => generatePDF("invoice_mix")}>Invoice MIX</button>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <nav className='mt-4 tabbable'>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button className="nav-link active" id="nav-booking-tab" data-bs-toggle="tab" data-bs-target="#nav-booking" type="button" role="tab" aria-controls="nav-booking" aria-selected="true">Booking</button>
                                        <button className="nav-link" id="nav-past-bookings-tab" data-bs-toggle="tab" data-bs-target="#nav-past-bookings" type="button" role="tab" aria-controls="nav-past-bookings" aria-selected="false">Past Bookings</button>
                                        <button className="nav-link" id="nav-doc-tab" data-bs-toggle="tab" data-bs-target="#nav-doc" type="button" role="tab" aria-controls="nav-doc" aria-selected="false">Documentation</button>
                                        <button className="nav-link" id="nav-account-tab" data-bs-toggle="tab" data-bs-target="#nav-account" type="button" role="tab" aria-controls="nav-account" aria-selected="false">Accounts</button>
                                        <button className="nav-link" id="nav-ssl-tab" data-bs-toggle="tab" data-bs-target="#nav-ssl" type="button" role="tab" aria-controls="nav-ssl" aria-selected="false">SSL, Trucker & Extra Payable</button>
                                        <button className="nav-link" id="nav-deposit-tab" data-bs-toggle="tab" data-bs-target="#nav-deposit" type="button" role="tab" aria-controls="nav-deposit" aria-selected="false">Deposit</button>
                                        <button className="nav-link" id="nav-status-tab" data-bs-toggle="tab" data-bs-target="#nav-status" type="button" role="tab" aria-controls="nav-status" aria-selected="false">Status</button>
                                        <button className="nav-link" id="nav-status-tab" data-bs-toggle="tab" data-bs-target="#nav-comment" type="button" role="tab" aria-controls="nav-comment" aria-selected="false">Comments</button>
                                        {/* <button className="nav-link" id="nav-party-tab" data-bs-toggle="tab" data-bs-target="#nav-party" type="button" role="tab" aria-controls="nav-party" aria-selected="false">Third Party</button>
                                        <button className="nav-link" id="nav-extra-charges-tab" data-bs-toggle="tab" data-bs-target="#nav-extra-charges" type="button" role="tab" aria-controls="nav-extra-charges" aria-selected="false">Extra Charges</button>*/}
                                        <button className="nav-link" id="nav-payment-tab" data-bs-toggle="tab" data-bs-target="#nav-payment" type="button" role="tab" aria-controls="nav-payment" aria-selected="false">Payments</button>
                                        <button className="nav-link" id="nav-amount-tab" data-bs-toggle="tab" data-bs-target="#nav-amount" type="button" role="tab" aria-controls="nav-amount" aria-selected="false">Amount</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-booking" role="tabpanel" aria-labelledby="nav-booking-tab">
                                        <div className="row login-wrap-new mt-4">
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Boooking No:</span>
                                                    <code>{props.jobsDetails.job_number}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Container No.:</span>
                                                    <code>{props.jobsDetails.container_number}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Carrier:</span>
                                                    <code>{props.jobsDetails.carriers_name}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span>Sale Person:</span>
                                                    <code>{salesPerson}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Origin:</span>
                                                    <code>{props.jobsDetails.origin}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Destination:</span>
                                                    <code>{props.jobsDetails.destination}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Service:</span>
                                                    <code>{service}</code>
                                                </div>
                                            </div>


                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Doc Cut Off Date:</span>
                                                    <code>{(props.jobsDetails.doc_cutoff_date && props.jobsDetails.doc_cutoff_date != "0000-00-00") ? moment(props.jobsDetails.doc_cutoff_date).format("YYYY-MM-DD") : ""}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">VGM Cut Off Date:</span>
                                                    <code>{(props.jobsDetails.vgm_cutoff_date && props.jobsDetails.vgm_cutoff_date != "0000-00-00") ? moment(props.jobsDetails.vgm_cutoff_date).format("YYYY-MM-DD") : ""}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Cargo Cut Off Date:</span>
                                                    <code>{(props.jobsDetails.cargo_curtoff_date && props.jobsDetails.cargo_curtoff_date != "0000-00-00") ? moment(props.jobsDetails.cargo_curtoff_date).format("YYYY-MM-DD") : ""}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Doc Cordinator:</span>
                                                    <code>{props.jobsDetails.edc_name}</code>
                                                </div>
                                            </div>
                                        </div>
                                        {/* booking page data end*/}
                                    </div>

                                    <div className="tab-pane fade" id="nav-past-bookings" role="tabpanel" aria-labelledby="nav-past-bookings-tab">
                                        <div className='mt-4'>
                                            <CustomerBookings
                                                ignoreBooking={props.jobsDetails.id}
                                                showCustomer='no'
                                                customer={{ id: props.jobsDetails.customer_id }}
                                                jobsData={props.jobsData}
                                            />
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="nav-doc" role="tabpanel" aria-labelledby="nav-doc-tab">
                                        <div className="row login-wrap-new mt-4">
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">B/L No:</span>
                                                    <code>{props.jobsDetails.bl_number}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Release Rcvd:</span>
                                                    <code>{releaseRcvd}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Shipper:</span>
                                                    <code>{shipper.contact_person}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Consignee:</span>
                                                    <code>{consignee.contact_person}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Commodity:</span>
                                                    <code>{props.jobsDetails.commodity}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">ETA:</span>
                                                    <code>{props.jobsDetails.eta != "0000-00-00" ? moment(props.jobsDetails.eta).format("YYYY-MM-DD") : ""}</code>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row login-wrap-new" style={{ display: (fields?.[2] && fields?.[2]?.[0]?.id != null) ? "" : "none" }} >
                                            {fields?.[2] && fields?.[2].map((item, idx) => {
                                                return (
                                                    <div key={idx} id={`id-${item.id}`} className="col-lg-3 col-sm-6 uploded-files-col">
                                                        <div className='uploded-files'>
                                                            <p className='d-flex justify-content-between align-items-center mb-0'>
                                                                <b>{item.type}</b>
                                                                {item.approved === '1' && <span className='badge badge-success p-1 d-flex align-items-center'><small>Approved</small></span>}
                                                            </p>
                                                            <p className='d-flex justify-content-between mb-0 mt-2'>
                                                                <code title={item.file_name} className='w-100'>
                                                                    <a href={window.location.hostname == "localhost" ? `http://localhost:3001/Images/uploads/${item.file_name}` : `http://golden-test.com:3001/Images/uploads/${item.file_name}`} target="_blank">{item.file_name}</a>
                                                                </code>
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div className='row login-wrap-new mt-4'>
                                            {fields?.[16] && (fields?.[16]).length > 0 && <b className="">Vin Numbers:</b>}
                                            {fields?.[16] && (fields?.[16]).map((item, idx) => {
                                                return (

                                                    <div className="col-md-3" key={idx} id={`id-${item.id}`} style={{ display: fields?.[16] ? "" : "none" }}>
                                                        <div className='comment-box'>
                                                            <p className='p-0 m-0'>
                                                                <span className='d-block date'>{moment(item.created_at).format("YYYY-MM-DD")}</span>
                                                                {item.vin_number}
                                                            </p>
                                                        </div>
                                                    </div>

                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="nav-account" role="tabpanel" aria-labelledby="nav-account-tab">
                                        <div className="row login-wrap-new mt-4">
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head d-flex flex-column'>
                                                    <span className="">Customer Name:</span>
                                                    <code>{customerData?.firstname + (customerData?.lastname ? ' ' + customerData?.lastname : '')}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Customer Quote:</span>
                                                    <br></br>
                                                    <code>{fields?.[3]?.[0]?.cq_amount ?? (props?.accountsData?.[0]?.cad_to_usd_rate && fields?.[3]?.[0]?.cq_cad > 0 ? parseFloat(fields?.[3]?.[0]?.cq_cad * props?.accountsData?.[0].cad_to_usd_rate).toFixed(2) : '') }<span style={{ color: "#336600" }}> USD</span></code>
                                                    <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.cq_cad}<span style={{ color: "#336600" }}> CAD</span> </code>
                                                    {fields?.[3]?.[0]?.bl_charges + fields?.[3]?.[0]?.freight + fields?.[3]?.[0]?.telux > 0
                                                        ? <>
                                                            <a className="btn btn-warning btn-xsm" data-tooltip-id={"rate-tooltip-view"} data-tooltip-html={
                                                                "<span>Bl Charges: $" + fields?.[3]?.[0]?.bl_charges + "</span><br/>"
                                                                + "<span>Freight: $" + fields?.[3]?.[0]?.freight + "</span><br/>"
                                                                + "<span>Telux: $" + fields?.[3]?.[0]?.telux + "</span>"
                                                            }><i className="fa fa-info"></i></a>
                                                            <Tooltip id={"rate-tooltip-view"} />
                                                        </>
                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Extra Scale Move:</span>
                                                    <br />
                                                    <code>{fields?.[3]?.[0]?.esm_amount}<span style={{ color: "#336600" }}> USD</span> </code>
                                                    <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.esm_cad}<span style={{ color: "#336600" }}> CAD</span> </code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Custom Hold Charges::</span>
                                                    <br />
                                                    <code>{fields?.[3]?.[0]?.chc_amount} <span style={{ color: "#336600" }}>USD</span> </code>&nbsp;
                                                    <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.chc_cad} <span style={{ color: "#336600" }}>CAD</span> </code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Extra Charges:</span>
                                                    <br />
                                                    <code>{fields?.[3]?.[0]?.ec_usd} <span style={{ color: "#336600" }}>USD</span> </code>&nbsp;
                                                    <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.ec_cad} <span style={{ color: "#336600" }}>CAD</span> </code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Manifest Correction Charges:</span>
                                                    <br />
                                                    <code>{fields?.[3]?.[0]?.manifest_amount} <span style={{ color: "#336600" }}>USD</span> </code>&nbsp;
                                                    <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.manifest_cad} <span style={{ color: "#336600" }}>CAD</span> </code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Customer Trucking Charges:</span>
                                                    <br />
                                                    <code>{fields?.[3]?.[0]?.ctc_amount}  <span style={{ color: "#336600" }}>USD</span> </code>&nbsp;
                                                    <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.ctc_cad}  <span style={{ color: "#336600" }}>CAD</span> </code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Customer Third Party Charges:</span>
                                                    <br />
                                                    <code>{thirdPartyTotal['usdTotal']} <span style={{ color: "#336600" }}>USD</span> </code>&nbsp;
                                                    <code style={{ marginLeft: "5px" }}>{thirdPartyTotal['cadTotal']} <span style={{ color: "#336600" }}>CAD</span> </code>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row login-wrap-new mt-4">
                                            <div className='col-12'>
                                                {(fields?.[15] && (fields?.[15]).length > 0) && <span className='view-name rounded-0 pb-1'>Third Party Charges:</span> }
                                                {(fields?.[15] && (fields?.[15]).length > 0)
                                                    && (<table className="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr No.</th>
                                                                <th>Third Party Name</th>
                                                                <th>Email</th>
                                                                <th>Conversion CAD</th>
                                                                <th>Conversion USD</th>
                                                                <th>Total CAD Amount</th>
                                                                <th>Total USD Amount</th>
                                                                <th>Remarks</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(fields?.[15]).map((item, i) => {
                                                                return [
                                                                    <tr key={i}>
                                                                        <td>{i + 1}</td>
                                                                        <td>{item.third_party_name}</td>
                                                                        <td>{item.third_party_email}</td>
                                                                        <td>{item.conversion_cad}</td>
                                                                        <td>{item.conversion_usd}</td>
                                                                        <td>{item.cad_amount}</td>
                                                                        <td>{item.usd_amount}</td>
                                                                        <td>{item.third_party_remarks}</td>
                                                                    </tr>
                                                                ]
                                                            })}
                                                        </tbody>
                                                    </table>)}

                                                { (fields?.[12] && (fields?.[12]).length > 0) && <span className='view-name rounded-0 pb-1'>Extra Charges: </span> }
                                                {(fields?.[12] && (fields?.[12]).length > 0)
                                                    && (<table className="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr No.</th>
                                                                <th>Conversion CAD</th>
                                                                <th>Conversion USD</th>
                                                                <th>Name</th>
                                                                <th>CAD Amount</th>
                                                                <th>USD Amount</th>
                                                                <th>Extra Charges Remarks</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(fields?.[12]).map((item, i) => {
                                                                return [
                                                                    <tr key={i}>
                                                                        <td>{i + 1}</td>
                                                                        <td>{item.conversion_cad}</td>
                                                                        <td>{item.conversion_usd}</td>
                                                                        <td>{item.service_name}</td>
                                                                        <td>{item.cad_amount}</td>
                                                                        <td>{item.usd_amount}</td>
                                                                        <td>{item.extra_charges_remarks}</td>
                                                                    </tr>
                                                                ]
                                                            })}
                                                        </tbody>
                                                    </table>)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="nav-ssl" role="tabpanel" aria-labelledby="nav-ssl-tab">
                                        <div className="row login-wrap-new mt-4">
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">SSL Charges:</span>
                                                    <br />
                                                    <code>{fields?.[3]?.[0]?.ssl_amount}  <span style={{ color: "#336600" }}>USD</span> </code>
                                                    <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.ssl_cad}  <span style={{ color: "#336600" }}>CAD</span> </code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Steamship Invoice No:</span>
                                                    <br />
                                                    <code>{fields?.[3]?.[0]?.steamship_invoice_no == "null" || fields?.[3]?.[0]?.steamship_invoice_no == "undefined" ? "" : fields?.[3]?.[0]?.steamship_invoice_no}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">SSL Paid Date:</span><br />
                                                    <code>{(fields?.[3]?.[0]?.ssl_paid_date && fields?.[3]?.[0]?.ssl_paid_date != "0000-00-00") ? moment(fields?.[3]?.[0]?.ssl_paid_date).format("YYYY-MM-DD") : ""}
                                                    </code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">SSL Paid Date New:</span><br />
                                                    <code>{(fields?.[3]?.[0]?.ssl_date_new && fields?.[3]?.[0]?.ssl_date_new != "0000-00-00") ? moment(fields?.[3]?.[0]?.ssl_date_new).format("YYYY-MM-DD") : ""}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Trucker Name:</span>
                                                    <br />
                                                    <code>{truckerName}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Trucker Charges:</span><br />
                                                    <code>{fields?.[3]?.[0]?.trucker_amount}  <span style={{ color: "#336600" }}>USD</span> </code>
                                                    <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.trucker_cad}  <span style={{ color: "#336600" }}>CAD</span> </code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Trucker Invoice No.:</span><br />
                                                    <code>{fields?.[3]?.[0]?.trucker_invoice_no == "null" || fields?.[3]?.[0]?.trucker_invoice_no == "undefined" ? "" : fields?.[3]?.[0]?.trucker_invoice_no}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Trucker Invoice Status:</span><br />
                                                    <code >{fields?.[3]?.[0]?.trucker_invoice_status}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Trucker Paid date:</span> <br />
                                                    <code> {(fields?.[3]?.[0]?.trucker_paid_date && fields?.[3]?.[0]?.trucker_paid_date != "0000-00-00") ? moment(fields?.[3]?.[0]?.trucker_paid_date).format("YYYY-MM-DD") : ""} </code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Trucker Paid Date New:</span> <br />
                                                    <code>{(fields?.[3]?.[0]?.trucker_date_new && fields?.[3]?.[0]?.trucker_date_new != "0000-00-00") ? moment(fields?.[3]?.[0]?.trucker_date_new).format("YYYY-MM-DD") : ""}
                                                    </code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Extra payable Charges:</span><br />
                                                    <code>{fields?.[3]?.[0]?.extra_payable_usd} USD</code>
                                                    <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.extra_payable_cad} CAD</code>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="nav-deposit" role="tabpanel" aria-labelledby="nav-deposit-tab">
                                        <div className="row login-wrap-new mt-4">
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Deposit Date:</span><br />
                                                    <code>{(fields?.[3]?.[0]?.deposit_date != "0000-00-00" && fields?.[3]?.[0]?.deposit_date != undefined) ? moment(fields?.[3]?.[0]?.deposit_date).format("YYYY-MM-DD") : ""}</code>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Deposit Notes:</span><br />
                                                    <code>{fields?.[3]?.[0]?.deposit_note == "null" || fields?.[3]?.[0]?.deposit_note == "undefined" ? "" : fields?.[3]?.[0]?.deposit_note}</code>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="nav-status" role="tabpanel" aria-labelledby="nav-status-tab">
                                        <div className="row login-wrap-new mt-4">
                                            <div className="col-sm-3 mb-2">
                                                <div className='detail-view-table-head'>
                                                    <span className="">Invoice doccumentation</span><br />
                                                    <code>{fields?.[3]?.[0]?.invoice_documentation}</code>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="nav-comment" role="tabpanel" aria-labelledby="nav-comment-tab">
                                        <div className='mt-4'>
                                            {(props?.jobsDetails?.customer_comment != "" && props.jobsDetails.customer_comment != null) &&

                                                <>
                                                    <span className="">Customer Comment on Job:</span>
                                                    <div className="row"  >
                                                        <div className="col-md-12">
                                                            <div className='comment-box'>
                                                                <p className='p-0 m-0'>
                                                                    {props.jobsDetails.customer_comment}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}

                                            {(props?.jobsDetails?.documentation_customer_comment != "" && props.jobsDetails.documentation_customer_comment != null) &&

                                                <>
                                                    <span className="">Customer Comment on Documentation:</span>
                                                    <div className="row"  >
                                                        <div className="col-md-12">
                                                            <div className='comment-box'>
                                                                <p className='p-0 m-0'>
                                                                    {props.jobsDetails.documentation_customer_comment}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}

                                            {fields?.[14] && (fields?.[14]).length > 0 && <span className="">Employees comments:</span>}
                                            {fields?.[14] && (fields?.[14]).map((item, idx) => {
                                                return (
                                                    <div key={idx} id={`id-${item.id}`} className="row" style={{ display: fields?.[15] ? "" : "none" }} >
                                                        <div className="col-md-12">
                                                            <div className='comment-box'>
                                                                <p className='p-0 m-0'>
                                                                    <span className='d-flex justify-content-between'>
                                                                        <span className='d-block date'>Employee Name: {item.employee_name}</span>
                                                                        <span className='d-block date'>{moment(item.created_at).format("YYYY-MM-DD")}</span>
                                                                    </span>
                                                                    {item.job_comment}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    {/* <div className="tab-pane fade" id="nav-party" role="tabpanel" aria-labelledby="nav-party-tab">

                                    </div> */}

                                    {/* <div className="tab-pane fade" id="nav-extra-charges" role="tabpanel" aria-labelledby="nav-extra-charges-tab">
                                     
                                    </div> */}

                                    <div className="tab-pane fade" id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab">
                                        {(receivableData && receivableData.length > 0) && (<table className="table table-striped table-bordered mt-4 payable-charges-table">
                                            <thead>
                                                <tr>
                                                    <th>Sr No.</th>
                                                    <th>When Paid</th>
                                                    <th>Mode of payment</th>
                                                    <th>Conversion CAD</th>
                                                    <th>Conversion USD</th>
                                                    <th>Total CAD Recieved</th>
                                                    <th>Total USD Recieved</th>
                                                    <th>Payment Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {receivableData.map((item, i) => {
                                                    return [
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{moment(item.when_paid).format("YYYY-MM-DD")}</td>
                                                            <td>{item.mode_of_payment}</td>
                                                            <td>{item.conversion_cad}</td>
                                                            <td>{item.conversion_usd}</td>
                                                            <td>{item.cad_amount}</td>
                                                            <td>{item.usd_amount}</td>
                                                            <td>{item.payment_remarks}</td>
                                                        </tr>
                                                    ]
                                                })}
                                            </tbody>
                                        </table>)}
                                    </div>

                                    <div className="tab-pane fade" id="nav-amount" role="tabpanel" aria-labelledby="nav-amount-tab">
                                        <div className="row view-job mt-4">
                                            <div className="col-sm-4">
                                                <div className='detail-view-table-head text-center mt-2'>
                                                    <span className="view-data view-name">Total Payable:</span>
                                                    <br />
                                                    <code>{(fields?.[3]?.[0]?.total_payable_usd === undefined || fields?.[3]?.[0]?.total_payable_usd == null) ? 0 : (fields?.[3]?.[0]?.total_payable_usd).toFixed(2)} <span style={{ color: "#336600" }}>USD</span></code>
                                                    <code style={{ marginLeft: "5px" }}>{(fields?.[3]?.[0]?.total_payable_cad === undefined || fields?.[3]?.[0]?.total_payable_cad == null) ? 0 : (fields?.[3]?.[0]?.total_payable_cad).toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 ">
                                                <div className='detail-view-table-head text-center mt-2'>
                                                    <span className="view-data view-name">Total Receivable:</span>
                                                    <br />
                                                    <code>{(fields?.[3]?.[0]?.total_recievable_usd === undefined || fields?.[3]?.[0]?.total_recievable_usd == null) ? 0 : (fields?.[3]?.[0]?.total_recievable_usd).toFixed(2)} <span style={{ color: "#336600" }}>USD</span></code>
                                                    <code style={{ marginLeft: "5px" }}>{(fields?.[3]?.[0]?.total_recievable_cad === undefined || fields?.[3]?.[0]?.total_recievable_cad == null) ? 0 : (fields?.[3]?.[0]?.total_recievable_cad).toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 ">
                                                <div className='detail-view-table-head text-center mt-2'>
                                                    <span className="view-data view-name">Total Expected Gain:</span>
                                                    <br />
                                                    <code>{(fields?.[3]?.[0]?.gain_usd === undefined || fields?.[3]?.[0]?.gain_usd == null) ? 0 : (fields?.[3]?.[0]?.gain_usd).toFixed(2)} <span style={{ color: "#336600" }}>USD</span></code>
                                                    <code style={{ marginLeft: "5px" }}>{(fields?.[3]?.[0]?.gain_cad === undefined || fields?.[3]?.[0]?.gain_cad == null) ? 0 : (fields?.[3]?.[0]?.gain_cad).toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 ">
                                                <div className='detail-view-table-head text-center mt-2'>
                                                    <span className="view-data view-name">Total Paid:</span>
                                                    <br />
                                                    <code>{totalReceivedUsd} <span style={{ color: "#336600" }}>USD</span></code>&nbsp;
                                                    <code style={{ marginLeft: "5px" }}>{totalReceivedCad.toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 ">
                                                <div className='detail-view-table-head text-center mt-2'>
                                                    <span className="view-data view-name">Total Received:</span>
                                                    <br />
                                                    <code>{totalReceivedUsd} <span style={{ color: "#336600" }}>USD</span></code>&nbsp;
                                                    <code style={{ marginLeft: "5px" }}>{totalReceivedCad.toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 ">
                                                <div className='detail-view-table-head text-center mt-2'>
                                                    <span className="view-data view-name">Net Gain:</span>
                                                    <br />
                                                    <code>{(fields?.[3]?.[0]?.gain_usd === undefined || fields?.[3]?.[0]?.gain_usd == null) ? 0 : (fields?.[3]?.[0]?.gain_usd).toFixed(2)} <span style={{ color: "#336600" }}>USD</span></code>
                                                    <code style={{ marginLeft: "5px" }}>{(fields?.[3]?.[0]?.gain_cad === undefined || fields?.[3]?.[0]?.gain_cad == null) ? 0 : (fields?.[3]?.[0]?.gain_cad).toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className='detail-view-table-head text-center mt-2'>
                                                    <span className="view-data view-name">Balance After Recieved Amount</span>
                                                    <br />
                                                    <code>{fields?.[3]?.[0]?.total_recievable_usd === undefined ? (totalReceivedUsd - 0) : (totalReceivedUsd - fields?.[3]?.[0]?.total_recievable_usd).toFixed(2)} <span style={{ color: "#336600" }}>USD</span></code>
                                                    <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.total_recievable_cad === undefined ? (totalReceivedCad - 0) : (totalReceivedCad - fields?.[3]?.[0]?.total_recievable_cad).toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="invoice_usd" style={{ display: "none" }}>
                <InvoicePdfUsd displayItem={displayItemUsd} customerData={customerData} fields={fields} receivableData={receivableData} jobsDetails={props.jobsDetails}
                    shipper={shipper.contact_person}
                    consignee={consignee.contact_person}
                    extraCharges={extraCharges}
                    service={service}
                />
            </div>

            <div id="invoice_cad" style={{ display: "none" }}>
                <InvoicePdfCad displayItem={displayItemCad} customerData={customerData} fields={fields} receivableData={receivableData} jobsDetails={props.jobsDetails}
                    shipper={shipper.contact_person}
                    consignee={consignee.contact_person}
                    extraCharges={extraCharges}
                    service={service}
                />
            </div>

            <div id="invoice_mix" style={{ display: "none" }}>
                <InvoicePdfMix displayItem={displayItemMix} customerData={customerData} fields={fields} receivableData={receivableData} jobsDetails={props.jobsDetails}
                    shipper={shipper.contact_person}
                    consignee={consignee.contact_person}
                    extraCharges={extraCharges}
                    service={service}
                />
            </div>

        </>
    );
}