import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
    getReportingList
} from "../../../Services/Store/Common/reporting/reporting.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { reportingList } from "../../../Services/Store/Common/reporting/reporting.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";

const Contact = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [userData, setUserData] = useState([]);
    const [userId, setUserId] = useState("");
    const [errorResponce, setErrorResponce] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [addModel, setAddModel] = useState(false);
    const [perPage, setPerPage] = useState(25);
    const [modalData, setModalData] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [userRecordsData, setUserRecordsData] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ratesListResult = useSelector(reportingList);
    const [customerData, setCustomerData] = useState("");
    const [exportCoordinatorData, setExportCoordinatorData] = useState("");
    const [importCoordinatorData, setImportCoordinatorData] = useState("");
    const [jobStatusData, setJobStatusData] = useState("");
    // const mainClientresponse = useSelector(mainClient);
    const [searchType, setSearchType] = useState("");

    useEffect(() => {
        const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

        if (!loginCheck || errorResponce === 4) {
            navigate("/");
        }

    }, []);

    useEffect(() => {
        if (ratesListResult.result !== undefined) {
            const result = ratesListResult.result;
            if (result.length > 0) {
                setUserData(ratesListResult.result);
                setUserRecordsData(ratesListResult.records);
                setErrorResponce(ratesListResult.error_code);
            } else {
                setUserData("");
                setUserRecordsData("");
            }
        }
    }, [ratesListResult]);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/tslCustomerListById", {
                params: { offset: 0, limit: 9999999999999 , deleteCond: true },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setCustomerData(response?.data?.data);
            } else {
                setCustomerData("");
            }
        }

        fetchMyAPI2();

    }, []);

    useEffect(() => {
        async function fetchMyAPI3() {
            const response = await api.get("api/v1/tslExportDocumentCoordinatorList", {
                params: { offset: 0, limit: 9999999999999 },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setExportCoordinatorData(response?.data?.data);
            } else {
                setExportCoordinatorData("");
            }
        }

        fetchMyAPI3();

    }, []);

    useEffect(() => {
        async function fetchMyAPI4() {
            const response = await api.get("api/v1/tslImportDocumentCoordinatorList", {
                params: { offset: 0, limit: 9999999999999 },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setImportCoordinatorData(response?.data?.data);
            } else {
                setImportCoordinatorData("");
            }
        }

        fetchMyAPI4();

    }, []);

    useEffect(() => {
        async function fetchMyAPI5() {
            const response = await api.get("api/v1/tslJobsStatusList", {
                params: { offset: 0, limit: 9999999999999 },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setJobStatusData(response?.data?.data);
            } else {
                setJobStatusData("");
            }
        }

        fetchMyAPI5();

    }, []);


    /** For pagination code start here **/
    var offset = currentPage * perPage;
    const handlePageClick = (selectedPage) => {
        dispatch(
            getReportingList({
                searchType: searchType, search: searchKeyword, offset: selectedPage,

                export: formik.values.export,
                import: formik.values.import,
                customer: formik.values.customer,
                status: formik.values.status,
                steamship: formik.values.steamship,
                job_for: formik.values.job_for,
                eta_start_date: formik.values.eta_start_date,
                eta_end_date: formik.values.eta_end_date,
                shipper: formik.values.shipper,
                destination: formik.values.destination,
                doc_cut_date: formik.values.doc_cut_date,
                release_start_date: formik.values.release_start_date,
                release_end_date: formik.values.release_end_date,


            })
        );
    };
    /** For pagination code end here **/

    const handleChange = (event) => {
        if (searchType) {
            setSearchKeyword(event.target.value.trim());
        }
    };



    const callPageRecords = (userData) => {
        //console.log(userData)
        if (userData) {
            let text = userData.map((data, index) => {                
                    return (<tr key={index}>
                        <td>{data.job_number}</td>
                        <td>{data.job_date ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                        <td>{data.doc_name}</td>
                        <td>{data.customer_name}</td>                       
                        <td>{data.booking_number}</td>
                        <td>{data.bl_number}</td>
                        <td>{data.carrier_name}</td>                 
                        <td>{data.container_number}</td>
                        <td>{data.shipper}</td>
                        <td>{data.destination}</td>
                        <td>{data.released_received_id == 0 ? "No" : "Yes"}</td>
                        <td>{data.release_date !="0000-00-00" &&  data.release_date != null ? moment(data.release_date).format("YYYY-MM-DD")  : ""}</td>
                        <td>{data.eta !="0000-00-00" &&  data.eta != null  ? moment(data.eta).format("YYYY-MM-DD")  : "" }</td>
                        <td>{data.doc_cutoff_date !="0000-00-00" ? moment(data.doc_cutoff_date).format("YYYY-MM-DD")   : ""   }</td>
                        <td><span style={{color: data.ssl_paid_date == "0000-00-00" || data.ssl_paid_date == null ? "red" : "green"}}> {data.ssl_paid_date == "0000-00-00" || data.ssl_paid_date ==null ? "Not Paid" : "Paid" } </span> </td>
                        <td>{data.total_recievable_usd === null ? 0 : data.total_recievable_usd } USD / {data.total_recievable_cad === null ? 0 :data.total_recievable_cad} CAD</td>
                        <td>{data.current_status}</td>
                    </tr>)         

               
            })
        
            return text


        } else {
            return (
                <tr className="text-center">
                    <td colSpan={17}>No Record Found</td>
                </tr>
            );
        }
    };


    const deleteUser = async (userId2) => {
        //dispatch(deleteRatesById(userId2));
        setShowModel(false);
        setDeleteModel(false);

    };





    const handleSearhChange = (event) => {
        setSearchType(event.target.value);
    };

    const validateContactFrom = Yup.object().shape({
    });
    let initialValues = {
        export: '',
        import: "",
        customer: "",
        status: "",
        steamship: "",
        job_for: "",
        eta_start_date: "",
        eta_end_date: "",
        shipper: "",
        destination: "",
        doc_cut_date: "",
        release_start_date: "",
        release_end_date: ""
    }
   
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            dispatch(getReportingList({
                searchType: searchType, search: searchKeyword, offset,
                export: values.export,
                import: values.import,
                customer: values.customer,
                status: values.status,
                steamship: values.steamship,
                job_for: values.job_for,
                eta_start_date: values.eta_start_date,
                eta_end_date: values.eta_end_date,
                shipper: values.shipper,
                destination: values.destination,
                doc_cut_date: values.doc_cut_date,
                release_start_date: values.release_start_date,
                release_end_date: values.release_end_date,
            }));

        },
    });

    return (
        <>
            <AdminLayout pageHeading="Search Jobs Reporting" pageIcon="fa fa-bar-chart">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <h3> Search Jobs</h3>
                        </div>
                    </div>
                    <div className="container-fluid demo">
                        <div
                            className="panel-group dashboard-table-format"
                            id="accordion"
                            role="tablist"
                            aria-multiselectable="true"
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="headingOne"
                                >
                                    <div className="panel-body">
                                        <div className="card  mb-4">
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                                                        <div className="deposited p-0">
                                                            <form onSubmit={formik.handleSubmit}>
                                                                <div className="row align-items-center" >
                                                                    <div className="col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Export coordinator</label>
                                                                        <select className="form-control" name="export"
                                                                            {...formik.getFieldProps('export')}                                                                        >
                                                                            <option value="">--Select Coordinator--</option>
                                                                            {
                                                                                exportCoordinatorData && exportCoordinatorData.map((user, index) => {
                                                                                    return (<option key={index} value={user.id}>{user.firstname + (user.lastname ? ' ' + user.lastname : '')}</option>);
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {formik.touched.export && formik.errors.export && (
                                                                            <div className="errorMsg text-danger">{formik.errors.export}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className=" col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Import doc. coordinator</label>
                                                                        <select className="form-control" name="import"
                                                                            {...formik.getFieldProps('import')}
                                                                        >
                                                                            <option value="">--Import doc. Coordinator--</option>
                                                                            {
                                                                                importCoordinatorData && importCoordinatorData.map((customer, index) => {
                                                                                    return (<option key={index} value={customer.id}>{customer.name}</option>);
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {formik.touched.import && formik.errors.import && (
                                                                            <div className="errorMsg text-danger">{formik.errors.import}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className=" col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Customer</label>
                                                                        <select className="form-control" name="customer"
                                                                            {...formik.getFieldProps('customer')}
                                                                        >
                                                                            <option value="">--Select Customer--</option>
                                                                            {
                                                                                customerData && customerData.map((customer, index) => {
                                                                                    return (<option key={index} value={customer.id}>{customer.firstname}</option>);
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {formik.touched.customer && formik.errors.customer && (
                                                                            <div className="errorMsg text-danger">{formik.errors.customer}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>
                                                                    
                                                                    <div className=" col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Job status</label>
                                                                        
                                                                        <select className="form-control" name="status"
                                                                            {...formik.getFieldProps('status')}
                                                                        >
                                                                            <option value="">--Select Status--</option>
                                                                            {
                                                                                jobStatusData && jobStatusData.map((customer, index) => {
                                                                                    return (<option key={index} value={customer.id}>{customer.name}</option>);
                                                                                })
                                                                            }

                                                                        </select>
                                                                        {formik.touched.status && formik.errors.status && (
                                                                            <div className="errorMsg text-danger">{formik.errors.status}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>
                                                                    
                                                                    <div className=" col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Steamship</label>
                                                                        <select className="form-control" name="steamship"
                                                                            {...formik.getFieldProps('steamship')}
                                                                        >
                                                                            <option value="">--Select Steamship--</option>
                                                                            <option value="Paid">Paid </option>
                                                                            <option value="Not Paid">Not Paid</option>
                                                                        </select>
                                                                        {formik.touched.steamship && formik.errors.steamship && (
                                                                            <div className="errorMsg text-danger">{formik.errors.steamship}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>
                                                                    
                                                                    <div className=" col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Job for</label>
                                                                        <select className="form-control" name="job_for"

                                                                            {...formik.getFieldProps('job_for')}>
                                                                            <option value="">--Select Job For--</option>
                                                                            <option value="GFFCA">GFFCA</option>
                                                                            <option value="NFFI">NFFI</option>
                                                                        </select>
                                                                        {formik.touched.job_for && formik.errors.job_for && (
                                                                            <div className="errorMsg text-danger">{formik.errors.job_for}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>
                                                                    
                                                                    <div className=" col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Eta start date</label>
                                                                        <input className="form-control"
                                                                            type="date"
                                                                            name="eta_start_date"
                                                                            min="2018-01-01"
                                                                            max="2025-12-31"
                                                                            {...formik.getFieldProps('eta_start_date')}
                                                                        />
                                                                        {formik.touched.eta_start_date && formik.errors.eta_start_date && (
                                                                            <div className="errorMsg text-danger">{formik.errors.eta_start_date}</div>
                                                                        )}

                                                                    </div>
                                                                    </div>
                                                                    
                                                                    <div className=" col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Eta end date</label>
                                                                        <input className="form-control"
                                                                            type="date"
                                                                            name="eta_end_date"
                                                                            min="2018-01-01"
                                                                            max="2025-12-31"
                                                                            {...formik.getFieldProps('eta_end_date')}
                                                                        />
                                                                        {formik.touched.eta_end_date && formik.errors.eta_end_date && (
                                                                            <div className="errorMsg text-danger">{formik.errors.eta_end_date}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>
                                                                    
                                                                    <div className=" col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Shipper</label>
                                                                        <input className="form-control"
                                                                            type="text"
                                                                            name="shipper"
                                                                            {...formik.getFieldProps('shipper')}
                                                                        />
                                                                        {formik.touched.shipper && formik.errors.shipper && (
                                                                            <div className="errorMsg text-danger">{formik.errors.shipper}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>
                                                                    
                                                                    <div className=" col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Destination</label>
                                                                        <input className="form-control"
                                                                            type="text"
                                                                            name="destination"
                                                                            {...formik.getFieldProps('destination')}
                                                                        />
                                                                        {formik.touched.destination && formik.errors.destination && (
                                                                            <div className="errorMsg text-danger">{formik.errors.destination}</div>
                                                                        )}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className=" col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Doc cutoff date</label>
                                                                        <input className="form-control"
                                                                            type="text"
                                                                            name="doc_cut_date"
                                                                            {...formik.getFieldProps('doc_cut_date')}
                                                                        />
                                                                        {formik.touched.doc_cut_date && formik.errors.doc_cut_date && (
                                                                            <div className="errorMsg text-danger">{formik.errors.doc_cut_date}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className=" col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Release req. start date</label>
                                                                        <input className="form-control"
                                                                            type="date"
                                                                            name="release_start_date"
                                                                            min="2018-01-01"
                                                                            max="2025-12-31"
                                                                            {...formik.getFieldProps('release_start_date')}
                                                                        />
                                                                        {formik.touched.release_start_date && formik.errors.release_start_date && (
                                                                            <div className="errorMsg text-danger">{formik.errors.release_start_date}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className="col-md-3 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label>Release req. end date</label>
                                                                        <input className="form-control"
                                                                            type="date"
                                                                            name="release_end_date"
                                                                            min="2018-01-01"
                                                                            max="2025-12-31"
                                                                            {...formik.getFieldProps('release_end_date')}
                                                                        />
                                                                        {formik.touched.release_end_date && formik.errors.release_end_date && (
                                                                            <div className="errorMsg text-danger">{formik.errors.release_end_date}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className=" col-md-3 col-sm-4">
                                                                        <input className="btn btn-primary mr-2" type="submit" name="search" value="Go" style={{ marginTop: "15px"  }} />
                                                                        <button className="action-tab btn btn-primary" style={{ marginTop: "15px",display:"none" }}>PDF Download</button>
                                                                    </div>
                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                    <table className="table table-striped table-bordered" id="pdf">
                                                        <thead>
                                                            <tr className="bg-primary text-white">
                                                                <th>Job No.</th>
                                                                <th>Job Date</th>
                                                                <th>Exp Doc.</th>
                                                                <th>Customer Name</th>
                                                                <th>Booking No.</th>
                                                                <th>BL No.</th>
                                                                <th>Carrier</th>
                                                                <th>Container No</th>
                                                                <th>Shipper</th>
                                                                <th>Destination</th>
                                                                <th>Release Rcvd.</th>
                                                                <th>Release Date.</th>
                                                                <th>ETA</th>
                                                                <th>Doc Cutoff</th>
                                                                <th>Steamship Inv.</th>
                                                                <th>Total Receivable</th>
                                                                <th>Job Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ratesListResult.result !==
                                                                undefined &&
                                                                ratesListResult.result !== "" ? (
                                                                callPageRecords(
                                                                    ratesListResult.result
                                                                )
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={17}>No Record Found</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-5">
                                                        {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                                       */}
                                                    </div>
                                                    <div className="col-sm-12 col-md-7">
                                                        <div
                                                            className="dataTables_paginate paging_simple_numbers"
                                                            id="dataTable_paginate"
                                                        >
                                                            {userData != undefined &&
                                                                userData.length > 0 &&
                                                                userRecordsData !== undefined ? (
                                                                <PaginationBlock
                                                                    perPage={perPage}
                                                                    userRecordsData={userRecordsData}
                                                                    callHandlePageClick={(data) =>
                                                                        handlePageClick(data)
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </AdminLayout>
        </>
    );
};
export default Contact;
