import { USER_ACTION_TYPE } from "../../../Constant/user.constants";

const INITIAL_STATE = {
  user: null,
  loginError: "",
};
export const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPE.SET_CURRENT_USER_PENDING:
      return {
        blocking: true,
        user: null,
      };

    case USER_ACTION_TYPE.SET_CURRENT_USER_SUCESS:
      return {
        blocking: false,
        user: payload.user,
      };

    case USER_ACTION_TYPE.SET_CURRENT_USER_ERROR:
      return {
        blocking: true,
        loginError: payload.error,
        user: null,
      };
      
    default:
      if(!state.user && localStorage.getItem('user')) {
        return {
            ...state,
            user: JSON.parse(localStorage.getItem('user'))
        };
    } else {
        return state;
    }
  }
};
