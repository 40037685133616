import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import {
  getdestinationList,
  deleteDestinationById,
  addDestinationDetails,
  editDestinationDetails,
} from "../../../Services/Store/Common/destination/destination.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { DestinationForm } from "./DestinationForm";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { destinationList } from "../../../Services/Store/Common/destination/destination.selector";

const Destination = () => {
  const id = useParams()?.id;
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const perPage = 25;
  const [modalData, setModalData] = useState({});
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const destinationListResult = useSelector(destinationList);
  const [searchType, setSearchType] = useState(null);
  const [searchIds, setSearchIds] = useState(null);

  useEffect(() => {
    if (id) {
      if (id === 'search') {
        let search = localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : '';
        if (search?.resource === 'destination_port' && search.ids) {
          setSearchIds(search.ids);
        }
      } else {
        setSearchType('id');
        setSearchKeyword(id);
      }
    } else {
      localStorage.removeItem('search');
      setSearchIds('');
    }
  }, [id]);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));
    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('clientToken');
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponce]);

  useEffect(() => {
    if (destinationListResult.result !== undefined) {
      const result = destinationListResult.result;
      if (result.length > 0) {
        setUserRecordsData(destinationListResult.records);
        setErrorResponce(destinationListResult.error_code);
      } else {
        setUserRecordsData("");
      }
    }
  }, [destinationListResult]);

  useEffect(() => {
    if(searchKeyword !== null && searchType !== null) {
      if(searchType === 'certificate_of_origin')
        dispatch(getdestinationList({ searchIds, searchType: searchType, search: searchKeyword ? (searchKeyword.toLowerCase() === 'yes' ? '1' : '0') : '' }));
      else
        dispatch(getdestinationList({ searchIds, searchType: searchType, search: searchKeyword }));
    }
  }, [searchKeyword, searchType]);

  useEffect(() => {
    if(searchIds !== null) {
      setSearchKeyword(null);
      setSearchType(null);
      dispatch(getdestinationList({ searchIds }));
    }
  }, [searchIds]);

  const handlePageClick = (selectedPage) => {
    if (searchType === 'certificate_of_origin')
      dispatch(getdestinationList({ searchIds, searchType: searchType, search: searchKeyword ? (searchKeyword.toLowerCase() === 'yes' ? '1' : '0') : '', offset: selectedPage }));
    else
      dispatch(getdestinationList({ searchIds, searchType: searchType, search: searchKeyword, offset: selectedPage }));
  };

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (userDetails) => {
    setModalData(userDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleAddClick = () => {
    setShowModel(true);
    setAddModel(true);
  };

  const callPageRecords = (userData) => {
    if (userData) {
      return userData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.port_code}</td>
          <td>{data.name}</td>
          <td>{data.country}</td>
          <td>{data.certificate_of_origin === '1' ? 'Yes' : 'No'}</td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit contact"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Delete contact"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={5}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteDestination = async (id) => {
    dispatch(deleteDestinationById(id));
    setShowModel(false);
    setDeleteModel(false);
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submitUserDetails = async (params) => {
    dispatch(addDestinationDetails(params));
    setShowModel(false);
    setAddModel(false);
  };

  const submitEditUserDetails = async (params) => {
    dispatch(editDestinationDetails(params, searchIds));
    setShowModel(false);
    setEditModel(false);
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <>
      <AdminLayout pageHeading="Destination Port" pageIcon="fa fa-map-marker">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Destination Port List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                  <button
                    type="button"
                    className="mb-2 mb-md-0 btn btn-primary"
                    onClick={() => _handleAddClick()}
                  >
                    Add&nbsp;
                    <i className="fa fa-plus fa-sm"></i>
                  </button>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="fillter-wrap d-flex justify-content-end">
                    <select name="selectType" id="" className="form-control me-1" value={searchType ?? ''} onChange={(event) => handleSearhChange(event)}>
                      <option value="">Select Type</option>
                      <option value="name">Destination Port</option>
                      <option value="certificate_of_origin">Certificate of Origin</option>
                    </select>
                    <input type="search" className="form-control" value={searchKeyword ?? ''} placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Port Code</th>
                          <th>Destination Port</th>
                          <th>Country</th>
                          <th>Certificate Of Origin</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {destinationListResult.result !==
                          undefined &&
                          destinationListResult.result !== "" ? (
                          callPageRecords(
                            destinationListResult.result
                          )
                        ) : (
                          <tr>
                            <td colSpan={5}>No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {
                    <PaginationBlock
                      perPage={perPage}
                      userRecordsData={userRecordsData}
                      callHandlePageClick={(data) =>
                        handlePageClick(data)
                      }
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <ModalBox
            title={deleteModel ? 'Delete Destination Port' : 'Destination Port Form'}
            show={showModel}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddModel(false);
            }}
          >
            {editModel === true ? (
              <DestinationForm
                editUserDetails={(params) => submitEditUserDetails(params)}
                userDetails={modalData}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={userId}
                deleteUserId={(userId) => deleteDestination(userId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : addModel === true ? (
              <DestinationForm
                userId={userId}
                addUserDetails={(params) => submitUserDetails(params)}
              />
            ) : (
              ""
            )}
          </ModalBox>
        </div>
      </AdminLayout >
    </>
  );
};
export default Destination;
