import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ModalBox } from "../../../../Components/Ui/ModalBox";
import {
  deleteQuotesById,
  getquotesList,
  addQuotesDetails,
  editQuotesDetails,
} from "../../../../Services/Store/Common/quotes/quotes.action";
import PaginationBlock from "../../../../Components/Ui/PaginationBlock";
import { DeleteForm } from "../../../../Components/Common/DeleteForm";
import { QuoteForm } from "./QuoteForm";
import AdminLayout from "../../../../Layout";
import { quotesList } from "../../../../Services/Store/Common/quotes/quotes.selector";
import api from "../../../../Services/Axios/index";
import { requestTokenHeader } from "../../../../Services/Helpers/helper";
import { JobForm } from "../jobs/JobForm";
import { addJobsDetails } from "../../../../Services/Store/Common/jobs/jobs.action";
import moment from "moment";
import { Tooltip } from 'react-tooltip';

const QuoteRequest = () => {
  const id = useParams()?.id;
  const currentUser = useSelector((state) => state.user);
  const largeNubmer = 1000;
  const [quoteId, setQuoteId] = useState("");
  const [jobsData, setJobsData] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [deleteModel, setDeleteModel] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const perPage = 25;
  const [modalData, setModalData] = useState({});
  const [quoteList, setQuoteList] = useState(0);
  const [quoteCount, setQuoteCount] = useState(0);
  const [addJobModel, setAddJobModel] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quotesResult = useSelector(quotesList);
  const [searchType, setSearchType] = useState("firstname");
  const [lastInsertedId, setLastInsertedId] = useState(0);

  useEffect(() => {
    if (id > 0) {
      setSearchType('id');
      setSearchKeyword(id);
    }
  }, []);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("customerToken"));
    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('customerToken');
      navigate("/");
    }
  }, [errorResponce]);

  useEffect(() => {
    setQuoteList(quotesResult.result)
    setQuoteCount(quotesResult.records);
    setErrorResponce(quotesResult.error_code);
  }, [quotesResult]);

  useEffect(() => {
    async function fetchMyAPI() {
      const response = await api.get("api/v1/tsljobData", {
        params: "",
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setJobsData(response.data.data);
      } else {
        setJobsData("");
      }
    }

    fetchMyAPI();
  }, []);

  useEffect(() => {
    dispatch(getquotesList({ id: currentUser.user.id, searchType: searchType, search: searchKeyword }));
  }, [searchKeyword]);

  const handlePageClick = (selectedPage) => {
    dispatch(getquotesList({ id: currentUser.user.id, searchType: searchType, search: searchKeyword, offset: selectedPage }));
  };

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (userDetails) => {
    setModalData(userDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (quote) => {
    setQuoteId(quote.id);
    setModalData(quote);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleAddClick = () => {
    setShowModel(true);
    setAddModel(true);
  };

  const _handleAddJobClick = (quoteDetails) => {
    fetchLastInsertedId();
    setShowModel(true);
    setAddModel(false);
    setAddJobModel(true);
    setModalData(quoteDetails);
  };

  const deleteQuote = async (id) => {
    dispatch(deleteQuotesById(id, modalData, currentUser.user));
    setShowModel(false);
    setDeleteModel(false);
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  async function fetchLastInsertedId() {
    setLastInsertedId(0);
    const response = await api.get("api/v1/tslFetchLastInsertedId", {
      params: '',
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setLastInsertedId(response.data.data?.[0].lastId);
    } else {
      setLastInsertedId([]);
    }
  }

  const callPageRecords = (quoteData) => {
    if (quoteData && quoteData.length) {
      return quoteData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.quote_number}</td>
          <td>{data.salesperson}</td>
          <td>{moment(data.created_at).format('MM/DD/YYYY')}</td>
          <td> {data.origin}</td>
          <td> {data.loading_port_name}</td>
          <td> {data.destination}</td>
          <td> {data.destination_port_name}</td>
          <td>{data.dangerous}</td>
          <td>{data.cargo_weight}</td>
          <td>{data.unit}</td>
          <td>
            {
              data.bl_charges + data.freight + data.telux > 0
                ?
                <>
                  {'$' + parseFloat(data.bl_charges + data.freight + data.telux).toFixed(2)}
                  <a className="btn btn-warning btn-xsm ms-1" data-tooltip-id={"rate-tooltip-" + index} data-tooltip-html={
                    "<span>Bl Charges: $" + data.bl_charges + "</span><br/>"
                    + "<span>Freight: $" + data.freight + "</span><br/>"
                    + "<span>Telux: $" + data.telux + "</span>"
                  }><i className="fa fa-info"></i></a>
                  <Tooltip id={"rate-tooltip-" + index} />
                </>
                : <span className="badge badge-info">Waiting</span>}</td>
          <td>{data.shipping_line}</td>
          <td>
            {
              data.bl_charges + data.freight + data.telux > 0
                ? (data.booking_request != "Yes"
                  ? <button
                    type="button"
                    className="mb-2 mb-md-0 btn btn-primary btn-xsm"
                    onClick={() => _handleAddJobClick(data)}
                  >
                    Request Booking
                  </button>
                  : (data.booking_number === null || data.booking_number === ''
                    ? <span className="badge badge-info">Booking Request Sent</span>
                    : <span className="badge badge-success">Booking Assigned</span>))
                : ''
            }
          </td>
          <td align="center">
            {
              data.bl_charges + data.freight + data.telux > 0 ?
                '' :
                <>
                  <button
                    type="button"
                    className="bd-none btn btn-warning btn-xsm"
                    onClick={() => _handleEditClick(data)}
                    data-toggle="tooltip" data-placement="top" title="Edit"
                  >
                    <i className="fa fa-fw fa-edit"></i>
                  </button>
                  <button
                    type="button"
                    className="bd-none btn btn-danger btn-xsm"
                    onClick={() => _handleDeleteClick(data)}
                    data-toggle="tooltip" data-placement="top" title="Delete"
                  >
                    <i className="fa fa-fw fa-trash"></i>
                  </button>
                </>
            }
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={14}>No Record Found</td>
        </tr>
      );
    }
  };

  const submitQuoteDetails = async (params) => {
    dispatch(addQuotesDetails(params, currentUser.user));
    setShowModel(false);
    setAddModel(false);
  };

  const submitEditQuoteDetails = async (params) => {
    dispatch(editQuotesDetails(params, modalData, currentUser.user));
    setShowModel(false);
    setEditModel(false);
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  const submitJobDetails = async (params, quoteId) => {
    params.customer_quote_id = quoteId;
    dispatch(addJobsDetails(params));
    dispatch(editQuotesDetails(
      { booking_request: 'Yes', id: quoteId, customer_id: params.customer_id },
      { quote_number: modalData.quote_number, job_number: params.job_number },
      currentUser.user));
    setShowModel(false);
    setAddJobModel(false);
  };

  return (
    <>
      <AdminLayout pageHeading="Quotes" pageIcon="fa fa-file-excel-o">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Quote List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">

              <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                  <button
                    type="button"
                    className="mb-2 mb-md-0 btn btn-primary"
                    onClick={() => _handleAddClick()}
                  >
                    Add Quote&nbsp;
                    <i className="fa fa-plus fa-sm"></i>
                  </button>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="fillter-wrap d-flex justify-content-end">
                    <select name="selectType" id="" className="form-control me-1" onChange={(event) => handleSearhChange(event)}>
                      <option value="">Select Type </option>
                      <option value="quote_number">Quote Number</option>
                      <option value="o.name">Loading Port</option>
                      <option value="origin">Origin</option>
                      <option value="destination">Destination</option>
                      <option value="dangerous">Dangerous</option>
                      <option value="cargo_weight">Cargo Weight</option>
                    </select>
                    <input type="search" className="form-control" placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">

              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Quote Number</th>
                          <th>Sales Person</th>
                          <th>Request Date</th>
                          <th>Origin</th>
                          <th>Loading Port</th>
                          <th>Destination</th>
                          <th>Destination Port</th>
                          <th>Dangerous</th>
                          <th>Cargo Weight</th>
                          <th>Unit</th>
                          <th>Cost</th>
                          <th>Shipping Line</th>
                          <th>Request Booking</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          callPageRecords(quoteList)
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {
                    <PaginationBlock
                      perPage={perPage}
                      userRecordsData={quoteCount}
                      callHandlePageClick={(data) =>
                        handlePageClick(data)
                      }
                    />
                  }
                </div>
              </div>

            </div>
          </div>
          <ModalBox
            title={addJobModel ? 'Booking Request Form' : (deleteModel ? 'Delete Quote' : 'Quote Form')}
            show={showModel}
            size={(deleteModel ? 'md' : 'xl')}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setAddModel(false);
              setDeleteModel(false);
              setAddJobModel(false);
            }}
          >
            {
              editModel === true ? (
                <QuoteForm
                  customerId={currentUser.user.id}
                  editQuotesDetails={(params) => submitEditQuoteDetails(params)}
                  quoteDetails={modalData}
                />
              ) : addModel === true ? (
                <QuoteForm
                  customerId={currentUser.user.id}
                  addQuotesDetails={(params) => submitQuoteDetails(params)}
                />
              ) : addJobModel === true ? (
                <JobForm
                  jobNumber={lastInsertedId + largeNubmer + 1}
                  customerID={currentUser.user.id}
                  salesPersonId={currentUser.user.created_by}
                  addUserDetails={(params) => submitJobDetails(params, modalData.id)}
                  jobsData={jobsData}
                  quoteData={modalData}
                />
              ) : deleteModel ? (
                <DeleteForm
                  userId={quoteId}
                  deleteUserId={(quoteId) => deleteQuote(quoteId)}
                  closeDeletePopUp={() => closeDeletePopUp()}
                />
              ) : ("")
            }
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default QuoteRequest;
