import { USER_ACTION_TYPE } from "../../../Constant/user.constants";

const INITIAL_STATE = {
  showStatusReportingList: [],
  blocking:false,
};
export const statusReportingListReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPE.SET_STATUS_LIST_PENDING:
      return {
        blocking: true,
        showStatusReportingList: [],
      };

    case USER_ACTION_TYPE.SET_STATUS_LIST_SUCESS:
      return {
        blocking: false,
        showStatusReportingList: payload,
      };

    case USER_ACTION_TYPE.SET_STATUS_LIST_ERROR:
      return {
        blocking: true,
        showStatusReportingList: [],
      };

    case USER_ACTION_TYPE.RESET_STATUS_LIST:
      return {
        blocking: true,
        showStatusReportingList: [],
      };

    default:
      return state;
  }
};
