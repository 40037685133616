import React from "react";

export function DeleteForm(props) {
  const deleteUser = async (userId) => {
    props.deleteUserId(userId);
  };
  const closeDeleteUser = async (userId) => {
    props.closeDeletePopUp(userId);
  };
  return (
    <>
      <div className="modal-body">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5 className="mb-4">Are you sure you want to delete ?</h5>
            </div>
            <div className="col-12 d-flex">
              <button
                type="button"
                onClick={() => deleteUser(props.userId)}
                className="btn btn-primary me-2"
              >
                OK
              </button>
              <button
                type="button"
                onClick={() => closeDeleteUser(props.userId)}
                className="btn btn-primary"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
