import React, { useState, useEffect, useRef } from 'react';
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader, getUserIds } from "../../../Services/Helpers/helper";
import AddRemoveMultipleInputField from "../../../Components/Ui/AddRemoveMultipleInputFields";
import moment from "moment";
import { Accordion } from "react-bootstrap";
import api from "../../../Services/Axios/index";
import Select from 'react-select'
import { ModalBox } from "../../../Components/Ui/ModalBox";
import { addNotificationDetails } from "../../../Services/Store/Common/notification/notification.action";
import { useDispatch } from "react-redux";
import { da } from '@faker-js/faker';
export function EditAccountsForm(props) {
    console.log(props);

    const dispatch = useDispatch();
    const cq_amount_ref = useRef(null);
    const esm_amount_ref = useRef(null);
    const chc_amount_ref = useRef(null);
    const ctc_amount_ref = useRef(null);
    const manifest_amount_ref = useRef(null);
    const ssl_amount_ref = useRef(null);
    const ssl_with_others_ref = useRef(null);
    const ssl_amount1_ref = useRef(null);

    const trucker_amount_ref = useRef(null);

    const trucker_paid_amount_ref = useRef(null);
    const service_amount_ref = useRef(null);

    const payable_amount_ref = useRef(null);


    const recieved_amount_ref = useRef(null);

    const [jobText, setJobText] = useState("");
    let total_balance_main = 0;
    let total_rec_usd = 0;

    const [totalPayableUsd, setTotalPayableUsd] = useState(0);
    const [totalPayableCad, setTotalPayableCad] = useState(0);
    const [totalRecievableUsd, setTotalRecievableUsd] = useState(0);
    const [totalRecievableCad, setTotalRecievableCad] = useState(0);
    const [gainUsd, setGainUsd] = useState(0);
    const [gainCad, setGainCad] = useState(0);


    const [jobComments, setJobComments] = useState(props?.jobComments ? props.jobComments : []);

    const regAmount = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
    //props?.accountsDataById?.[0][0] ? props.accountsDataById[0][0] : {}
    const [fieldsQuotes, setFieldsQuotes] = useState({});
    const [formFields, setFormFields] = useState(props?.accountsDataById?.[0][0] ? props.accountsDataById[0][0] : {});



    const [fields, setFields] = useState(props?.accountsDataById?.[0][0] ? props.accountsDataById[0][0] : {});
    const currencyName = useRef();
    const [currency, setCurrency] = useState(props?.accountsDataById?.[0][0] ? props.accountsDataById[0][0].cq_currency : "");

    const [conversionUsd, setConversionUsd] = useState(props?.accountsData?.[0] ? props?.accountsData?.[0]?.usd_to_cad_rate : "");
    const [conversionCad, setConversionCad] = useState(props?.accountsData?.[0] ? props?.accountsData?.[0]?.cad_to_usd_rate : "");

    const [ecUsd, setEcUsd] = useState(0);
    const [ecCad, setEcCad] = useState(0);
    const [quoteExtra, setQuoteExtra] = useState({});
    const [addExtraClicked, setAddExtraClicked] = useState(false);
    const [extraData, setExtraData] = useState(props?.accountsDataById?.[1] ? props.accountsDataById[1] : []);



    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState(false);
    const [applyCheck] = useState(false);

    const [fieldPayable, setFieldPayable] = useState({});
    const [payableExtra, setPayableExtra] = useState({});
    const [extraPayableUsd, setExtraPayableUsd] = useState(0);
    const [extraPayableCad, setExtraPayableCad] = useState(0);
    const [addPayableClicked, setAddPayableClicked] = useState(false);
    const [payableData, setPayableData] = useState(props?.accountsDataById?.[2] ? props.accountsDataById[2] : []);

    const [fieldsThirdPartyCharges, setFieldsThirdPartyCharges] = useState({});
    const [thirdPartyChargesExtra, setThirdPartyChargesExtra] = useState({});
    const [extraThirdPartyChargesUsd, setExtraThirdPartyChargesUsd] = useState(0);
    const [extraThirdPartyChargesCad, setExtraThirdPartyChargesCad] = useState(0);
    const [addThirdPartyChargesClicked, setAddThirdPartyChargesClicked] = useState(false);
    const [thirdPartyChargesData, setThirdPartyChargesData] = useState(props?.accountsDataById?.[6] ? props.accountsDataById[6] : []);



    const [customerReceivableUsd, setCustomerReceivableUsd] = useState(0);
    const [customerReceivableCad, setCustomerReceivableCad] = useState(0);
    const [customerReceivedUsd, setCustomerReceivedUsd] = useState(0);
    const [customerReceivedCad, setCustomerReceivedCad] = useState(0);
    const [customerBalanceUsd, setCustomerBalanceUsd] = useState(0);
    const [customerBalanceCad, setCustomerBalanceCad] = useState(0);
    const [thirdPartyBalanceUsd, setThirdPartBalanceUsd] = useState(0);
    const [thirdPartBalanceCad, setThirdPartBalanceCad] = useState(0);


    const [receivableExtra, setReceivableExtra] = useState({});
    const [addReceivableClicked, setAddReceivableClicked] = useState(false);
    const [receivableData, setReceivableData] = useState(props?.accountsDataById?.[3] ? props.accountsDataById[3] : []);

    const [selectedOption, setSelectedOption] = useState(props?.jobsDetails?.job_status_id);
    const options = [
    ];
    const [showModel, setShowModel] = useState(false);
    const [commentModel, setCommentModel] = useState(false);
    const [finalRates, setFinalRates] = useState(props?.accountsDataById?.[0][0] ? parseInt(props.accountsDataById[0][0].rates_final) : 0);
    props?.jobsData?.[6].map((data, index) => (
        options.push({ value: data.id, label: data.name })
    ));
    const handleTypeSelect = e => {
        let data = formFields;
        data["job_status_id"] = e.value;
        setFormFields({ ...data });

        setSelectedOption(e.value);
    };

    useEffect(() => {
        if (conversionCad) {
            let data = formFields;
            data['cq_amount'] = parseFloat(data['cq_cad'] * conversionCad).toFixed(2);
            setFormFields({ ...data });
        }
    }, [formFields.cq_cad]);

    useEffect(() => {
        let chargesTotal = formFields.bl_charges + formFields.freight + formFields.telux;
        if (chargesTotal > 0) {
            let data = formFields;
            data['cq_cad'] = chargesTotal;
            setFormFields({ ...data });
        }
    }, [formFields.bl_charges, formFields.freight, formFields.telux]);

    const updateCqCad = (e) => {
        let data = formFields;
        data[e.target.name] = e.target.value ? parseFloat(e.target.value) : '';
        setFormFields({ ...data });
    }

    useEffect(() => {

        //    if(receivableData && receivableData.length > 0) 

        let third_party_usd = 0;
        receivableData.forEach(element => {
            total_rec_usd += element.usd_amount;
        });
        thirdPartyChargesData.forEach(element => {
            third_party_usd += element.usd_amount;
        });

        if (formFields['manifest_paidby'] == 'THIRD PARTY') {
            total_balance_main = formFields['total_recievable_usd'] + formFields['manifest_amount'] - total_rec_usd - formFields['fac_amount'] - third_party_usd;
        } else {
            total_balance_main = formFields['total_recievable_usd'] - total_rec_usd - formFields['fac_amount'];
        }
        if (formFields['ssl_paid_date'] != '0000-00-00') {
            if (props.jobsDetails['released_received_id'] != 0 && props.jobsDetails['release_date'] != '0000-00-00') {
                if (total_balance_main <= 0) {
                    if (formFields['job_completed'] == 'Yes') {
                        setJobText("Job Closed");
                    }
                    else {
                        setJobText("Job Closure Required");
                    }
                }
                else {
                    setJobText("Payment not received ");
                }
            }
            else {
                if (total_balance_main <= 0) {
                    setJobText("Release required");
                }
                else {
                    setJobText("Received not released");
                }
            }
        }
        else {
            if (props.jobsDetails['released_received_id'] != 0 && props.jobsDetails['release_date'] != '0000-00-00') {
                if (total_balance_main <= 0) {
                    setJobText("Received not paid");
                }
                else {
                    setJobText("Not Received Not Paid");
                }
            }

        }
    }, [
        props.jobsDetails, props.accountsDataById, formFields
    ]);

    useEffect(() => {

        let totalExtraQuoteUsd = 0;
        let totalExtraQuoteCad = 0;
        let cq_amount = document.getElementById("cq_amount").value;
        let esm_amount = document.getElementById("esm_amount").value;
        let chc_amount = document.getElementById("chc_amount").value;
        let ctc_amount = document.getElementById("ctc_amount").value;


        let cq_cad = document.getElementById("cq_cad").value;
        let esm_cad = document.getElementById("esm_cad").value;
        let chc_cad = document.getElementById("chc_cad").value;
        let ctc_cad = document.getElementById("ctc_cad").value;


        let ssl_cad = document.getElementById("ssl_cad").value;
        let ssl_with_others_cad = document.getElementById("ssl_with_others_cad").value;
        let ssl1_cad = document.getElementById("ssl1_cad").value;
        let trucker_cad = document.getElementById("trucker_cad").value;
        let trucker_paid_cad = document.getElementById("trucker_paid_cad").value;
        let manifest_cad = document.getElementById("manifest_cad").value;

        let ssl_amount = document.getElementById("ssl_amount").value;
        let ssl_with_others = document.getElementById("ssl_with_others").value;
        let ssl1_amount = document.getElementById("ssl1_amount").value;
        let trucker_amount = document.getElementById("trucker_amount").value;
        let trucker_paid_amount = document.getElementById("trucker_paid_amount").value;
        let manifest_amount = document.getElementById("manifest_amount").value;





        let totalChargesPayableExtraUsd = 0;
        let totalChargesPayableExtraCad = 0;

        let totalChargesReceivableExtraUsd = 0;
        let totalChargesReceivableExtraCad = 0;

        let totalChargesThirdPartyExtraUsd = 0;
        let totalChargesThirdpartyExtraCad = 0;

        let cq_amount_total = 0;
        let cq_amount_total_cad = 0;

        let payable_cad = 0;
        let payable_usd = 0;


        let totalThirdPartyUsd = 0;
        let totalThirdPartyCad = 0;


        cq_amount_total = parseFloat(cq_amount) + parseFloat(esm_amount) + parseFloat(chc_amount) + parseFloat(ctc_amount);

        cq_amount_total_cad = parseFloat(cq_cad) + parseFloat(esm_cad) + parseFloat(chc_cad) + parseFloat(ctc_cad);


        payable_cad = parseFloat(ssl_cad) + parseFloat(ssl_with_others_cad) + parseFloat(ssl1_cad) + parseFloat(trucker_cad) + parseFloat(trucker_paid_cad) + parseFloat(manifest_cad);
        payable_usd = parseFloat(ssl_amount) + parseFloat(ssl_with_others) + parseFloat(ssl1_amount) + parseFloat(trucker_amount) + parseFloat(trucker_paid_amount) + parseFloat(manifest_amount);


        extraData.map((item, index, arr) => {


            totalExtraQuoteUsd += parseFloat(item.usd_amount);
            totalExtraQuoteCad += parseFloat(item.cad_amount);

        });
        setEcUsd(quoteArray => totalExtraQuoteUsd);
        setEcCad(quoteArray => totalExtraQuoteCad);

        payableData.map((item, index, arr) => {
            totalChargesPayableExtraUsd += parseFloat(item.usd_amount);
            totalChargesPayableExtraCad += parseFloat(item.cad_amount);
        });
        setExtraPayableUsd(quoteArray => totalChargesPayableExtraUsd);
        setExtraPayableCad(quoteArray => totalChargesPayableExtraCad);

        setTotalPayableUsd(totalChargesPayableExtraUsd + payable_usd);
        setTotalPayableCad(totalChargesPayableExtraCad + payable_cad);

        thirdPartyChargesData.map((item, index, arr) => {
            totalChargesThirdPartyExtraUsd += parseFloat(item.usd_amount);
            totalChargesThirdpartyExtraCad += parseFloat(item.cad_amount);
        });

        setExtraThirdPartyChargesUsd(quoteArray => totalChargesThirdPartyExtraUsd);
        setExtraThirdPartyChargesCad(quoteArray => totalChargesThirdpartyExtraCad);




        receivableData.map((item, index, arr) => {
            if (item.third_party != "Yes") {
                totalChargesReceivableExtraUsd += parseFloat(item.usd_amount);
                totalChargesReceivableExtraCad += parseFloat(item.cad_amount);
            } else {
                totalThirdPartyUsd += parseFloat(item.usd_amount);
                totalThirdPartyCad += parseFloat(item.cad_amount);
            }

        });

        setThirdPartBalanceUsd(quoteArray => (totalChargesThirdPartyExtraUsd - totalThirdPartyUsd));
        setThirdPartBalanceCad(quoteArray => (totalChargesThirdpartyExtraCad - totalThirdPartyCad));


        setCustomerReceivableUsd(quoteArray => totalExtraQuoteUsd + cq_amount_total);
        setCustomerReceivableCad(quoteArray => totalExtraQuoteCad + cq_amount_total_cad);

        setCustomerReceivedUsd(quoteArray => totalChargesReceivableExtraUsd);
        setCustomerReceivedCad(quoteArray => totalChargesReceivableExtraCad);
        setTotalRecievableUsd(quoteArray => totalChargesReceivableExtraUsd);
        setTotalRecievableCad(quoteArray => totalChargesReceivableExtraCad);


        setCustomerBalanceUsd(quoteArray => (totalExtraQuoteUsd + cq_amount_total) - totalChargesReceivableExtraUsd);
        setCustomerBalanceCad(quoteArray => (totalExtraQuoteCad + cq_amount_total_cad) - totalChargesReceivableExtraCad);



        setGainUsd(totalChargesReceivableExtraUsd - (totalChargesPayableExtraUsd + payable_usd));
        setGainCad(totalChargesReceivableExtraCad - (totalChargesPayableExtraCad + payable_cad));

    }, [extraData, payableData, receivableData, thirdPartyChargesData]);











    /*  Payable section   */

    const handelPayableClick = () => {
        setAddPayableClicked(true);
    }

    const handelCancelPayable = () => {
        if (document.getElementById('payable_action')?.innerHTML.length > 0) {
            document.getElementById("payable_action").style.pointerEvents = "auto";
        }
        if (payableExtra.hasOwnProperty("id")) {
            setPayableData(quoteArray => [...quoteArray, payableExtra]);
        }
        setPayableExtra({});
        setAddPayableClicked(false);
    }

    const validatePayable = (payableExtra, applyCheck = false) => {
        let errors = {};
        let formIsValid = true;

        if (!payableExtra["payable_name"] || payableExtra["payable_name"].trim() === "") {
            formIsValid = false;
            errors["payable_name"] = "*Field is required";
        }

        if (!payableExtra["cad_amount"] || (payableExtra["cad_amount"]).toString().trim() === "") {
            formIsValid = false;
            errors["cad_amount"] = "*Field is required";
        }

        if (!regAmount.test(payableExtra["cad_amount"])) {
            formIsValid = false;
            errors["cad_amount"] = "*Please enter correct value";

        }

        if (!payableExtra["usd_amount"] || (payableExtra["usd_amount"]).toString().trim() === "") {
            formIsValid = false;
            errors["usd_amount"] = "*Field is required";
        }

        if (!regAmount.test(payableExtra["usd_amount"])) {
            formIsValid = false;
            errors["usd_amount"] = "*Please enter correct value";

        }


        return {
            errors: errors,
            formIsValid: formIsValid,
        };
    };

    const _validateFormPayable = () => {
        let formFields = payableExtra;
        let response = validatePayable(formFields, applyCheck);
        setErrors(response.errors);
        return response.formIsValid;
    };
    const handleChangePayable = (event) => {
        let data = payableExtra;
        data[event.target.name] = event.target.value;
        setPayableExtra({ ...data });
    };

    const updatePayableDetails = async (event) => {
        if (document.getElementById('payable_action')?.innerHTML.length > 0) {
            document.getElementById("payable_action").style.pointerEvents = "auto";
        }
        const d = new Date();
        let ms = d.valueOf();
        event.preventDefault();
        if (_validateFormPayable()) {
            let currencytype = "USD";
            if (payableExtra && (currencytype === "USD" || currencytype === "CAD")) {
                payableExtra.unique_id = ms;
                payableExtra.conversion_cad = conversionCad;
                payableExtra.conversion_usd = conversionUsd;
                setPayableData(quoteArray => [...quoteArray, payableExtra]);
                setAddPayableClicked(false);
                setPayableExtra({});
            } else {
                displayErrorMessage('currencyNotFound');
            }
        }

    }



    /*  Payable section end  */

    /*  Customer Quote  */

    /*  Customer Quote  */
    const handelExtraClick = () => {
        setAddExtraClicked(true);
    }

    const handelCancelExtra = () => {
        if (document.getElementById('extra_charges_action')?.innerHTML.length > 0) {
            document.getElementById("extra_charges_action").style.pointerEvents = "auto";
        }
        if (quoteExtra.hasOwnProperty("id")) {
            setExtraData(quoteArray => [...quoteArray, quoteExtra]);
        }
        setQuoteExtra({})
        setAddExtraClicked(false);


    }

    const validateQuoteExtra = (quoteExtra, applyCheck = false) => {
        let errors = {};
        let formIsValid = true;

        if (!quoteExtra["service_name"] || (quoteExtra["service_name"]).toString().trim() === "") {
            formIsValid = false;
            errors["service_name"] = "*Field is required";
        }
        if (!quoteExtra["usd_amount"] || (quoteExtra["usd_amount"]).toString().trim() === "") {
            formIsValid = false;
            errors["usd_amount"] = "*Field is required";
        }


        if (!regAmount.test(quoteExtra["usd_amount"])) {
            formIsValid = false;
            errors["usd_amount"] = "*Please enter correct value";

        }
        if (!quoteExtra["cad_amount"] || (quoteExtra["cad_amount"]).toString().trim() === "") {
            formIsValid = false;
            errors["cad_amount"] = "*Field is required";
        }

        if (!regAmount.test(quoteExtra["cad_amount"])) {
            formIsValid = false;
            errors["cad_amount"] = "*Please enter correct value";

        }


        return {
            errors: errors,
            formIsValid: formIsValid,
        };
    };

    const _validateQuoteExtraForm = () => {
        let formquoteExtra = quoteExtra;
        let response = validateQuoteExtra(formquoteExtra, applyCheck);
        setErrors(response.errors);
        return response.formIsValid;
    };
    const handleChangeQuoteExtra = (event) => {
        let data = quoteExtra;
        data[event.target.name] = event.target.value;
        setQuoteExtra({ ...data });
    };
    const updateQuoteExtraDetails = (event) => {
        if (document.getElementById('extra_charges_action')?.innerHTML.length > 0) {
            document.getElementById("extra_charges_action").style.pointerEvents = "auto";
        }
        const d = new Date();
        let ms = d.valueOf();

        let currencytype = "USD";
        if (_validateQuoteExtraForm()) {
            let totalAmount = 0;
            if (quoteExtra && (currencytype === "USD" || currencytype === "CAD")) {

                quoteExtra.conversion_cad = conversionCad;
                quoteExtra.conversion_usd = conversionUsd;
                quoteExtra.unique_id = ms;

                setExtraData(quoteArray => [...quoteArray, quoteExtra]);
                setAddExtraClicked(false);
                setQuoteExtra({});
            } else {
                displayErrorMessage('currencyNotFound');
            }
        }
    }





    /* END  Customer Quote */



    /*   Receivable section  */
    const handelReceivableClick = () => {
        setAddReceivableClicked(true);
    }
    const handelCancelReceivable = () => {

        if (document.getElementById('receivable_action')?.innerHTML.length > 0) {
            document.getElementById("receivable_action").style.pointerEvents = "auto";
        }
        if (receivableExtra.hasOwnProperty("id")) {
            setReceivableData(quoteArray => [...quoteArray, receivableExtra]);
        }
        setReceivableExtra({})

        setAddReceivableClicked(false);
    }

    const validateReceivable = (quoteExtra, applyCheck = false) => {
        let errors = {};
        let formIsValid = true;

        if (!receivableExtra["when_paid"] || receivableExtra["when_paid"].trim() === "") {
            formIsValid = false;
            errors["when_paid"] = "*Field is required";
        }
        if (!receivableExtra["mode_of_payment"] || receivableExtra["mode_of_payment"].trim() === "") {
            formIsValid = false;
            errors["mode_of_payment"] = "*Field is required";
        }

        if (!receivableExtra["cad_amount"] || (receivableExtra["cad_amount"]).toString().trim() === "") {
            formIsValid = false;
            errors["cad_amount"] = "*Field is required";
        }
        if (!regAmount.test(receivableExtra["cad_amount"])) {
            formIsValid = false;
            errors["cad_amount"] = "*Please enter correct value";

        }
        if (!receivableExtra["usd_amount"] || (receivableExtra["usd_amount"]).toString().trim() === "") {
            formIsValid = false;
            errors["usd_amount"] = "*Field is required";
        }
        if (!regAmount.test(receivableExtra["usd_amount"])) {
            formIsValid = false;
            errors["usd_amount"] = "*Please enter correct value";

        }

        if (!receivableExtra["third_party"] || receivableExtra["third_party"].trim() === "") {
            formIsValid = false;
            errors["third_party"] = "*Field is required";
        }

        return {
            errors: errors,
            formIsValid: formIsValid,
        };
    };

    const _validateReceivableForm = () => {
        let formquoteExtra = receivableExtra;
        let response = validateReceivable(formquoteExtra, applyCheck);
        setErrors(response.errors);
        return response.formIsValid;
    };
    const handleChangeReceivable = (event) => {
        let data = receivableExtra;
        data[event.target.name] = event.target.value;
        setReceivableExtra({ ...data });
    };
    const updateReceivableDetails = (event) => {
        if (document.getElementById('receivable_action')?.innerHTML.length > 0) {
            document.getElementById("receivable_action").style.pointerEvents = "auto";
        }
        const d = new Date();
        let ms = d.valueOf();
        let currencytype = "USD";
        if (_validateReceivableForm()) {
            if (receivableExtra && (currencytype === "USD" || currencytype === "CAD")) {
                receivableExtra.conversion_cad = conversionCad;
                receivableExtra.conversion_usd = conversionUsd;
                receivableExtra.unique_id = ms;
                setReceivableData(quoteArray => [...quoteArray, receivableExtra]);
                setAddReceivableClicked(false);
                setReceivableExtra({});
            } else {
                displayErrorMessage('currencyNotFound');
            }
        }
    }



    /*  Third party section   */

    const handelThirdPartyChargesClick = () => {
        setAddThirdPartyChargesClicked(true);
    }

    const handelCancelThirdPartyCharges = () => {
        if (document.getElementById('third_party_action')?.innerHTML.length > 0) {
            document.getElementById("third_party_action").style.pointerEvents = "auto";
        }
        if (thirdPartyChargesExtra.hasOwnProperty("id")) {
            setThirdPartyChargesData(quoteArray => [...quoteArray, thirdPartyChargesExtra]);
        }
        setThirdPartyChargesExtra({})
        setAddThirdPartyChargesClicked(false);
    }
    function ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return (true);
        }
        return (false);
    }
    const validateThirdPartyCharges = (ThirdPartyChargesExtra, applyCheck = false) => {
        let errors = {};
        let formIsValid = true;

        if (!ThirdPartyChargesExtra["third_party_name"] || ThirdPartyChargesExtra["third_party_name"].trim() === "") {
            formIsValid = false;
            errors["third_party_name"] = "*Field is required";
        }
        if (!ThirdPartyChargesExtra["third_party_email"] || ThirdPartyChargesExtra["third_party_email"].trim() === "") {
            formIsValid = false;
            errors["third_party_email"] = "*Field is required";
        }
        if (!ValidateEmail(ThirdPartyChargesExtra["third_party_email"])) {
            formIsValid = false;
            errors["third_party_email"] = "*Enter valid email";
        }

        if (!ThirdPartyChargesExtra["cad_amount"] || (ThirdPartyChargesExtra["cad_amount"]).toString().trim() === "") {
            formIsValid = false;
            errors["cad_amount"] = "*Field is required";
        }

        if (!regAmount.test(ThirdPartyChargesExtra["cad_amount"])) {
            formIsValid = false;
            errors["cad_amount"] = "*Please enter correct value";

        }
        if (!ThirdPartyChargesExtra["usd_amount"] || (ThirdPartyChargesExtra["usd_amount"]).toString().trim() === "") {
            formIsValid = false;
            errors["usd_amount"] = "*Field is required";
        }

        if (!regAmount.test(ThirdPartyChargesExtra["usd_amount"])) {
            formIsValid = false;
            errors["usd_amount"] = "*Please enter correct value";

        }


        return {
            errors: errors,
            formIsValid: formIsValid,
        };
    };

    const _validateFormThirdPartyCharges = () => {
        let formFields = thirdPartyChargesExtra;
        let response = validateThirdPartyCharges(formFields, applyCheck);
        setErrors(response.errors);
        return response.formIsValid;
    };
    const handleChangeThirdPartyCharges = (event) => {
        let data = thirdPartyChargesExtra;
        data[event.target.name] = event.target.value;
        setThirdPartyChargesExtra({ ...data });
    };

    const updateThirdPartyChargesDetails = async (event) => {
        if (document.getElementById('third_party_action')?.innerHTML.length > 0) {
            document.getElementById("third_party_action").style.pointerEvents = "auto";
        }
        const d = new Date();
        let ms = d.valueOf();
        event.preventDefault();
        if (_validateFormThirdPartyCharges()) {
            let currencytype = "USD";
            if (thirdPartyChargesExtra && (currencytype === "USD" || currencytype === "CAD")) {

                thirdPartyChargesExtra.conversion_cad = conversionCad;
                thirdPartyChargesExtra.conversion_usd = conversionUsd;
                thirdPartyChargesExtra.unique_id = ms;
                setThirdPartyChargesData(quoteArray => [...quoteArray, thirdPartyChargesExtra]);
                setAddThirdPartyChargesClicked(false);
                setThirdPartyChargesExtra({});
            } else {
                displayErrorMessage('currencyNotFound');
            }
        }

    }


    async function fetchMyAPIThirdParty(id) {
        const response = await api.post("api/v1/tslDeleteThirdPartyCharges", {
            id: id
        },
            { headers: requestTokenHeader() }
        );
        if (response.data.errorCode === 0) {
            displaySuccessMessage("recordDeletedSuccess");
            setAddThirdPartyChargesClicked(false);
            setThirdPartyChargesData(quoteArray => quoteArray.filter(function (item) {
                return item.id !== id
            })
            );
        } else {
            displayErrorMessage("recordDeletedError");
        }
    }
    const _handleDeleteClickThirdParty = (data) => {
        if (data.hasOwnProperty("unique_id") && !data.hasOwnProperty("id")) {
            setThirdPartyChargesData(quoteArray => quoteArray.filter(function (item) {
                return item.unique_id !== data.unique_id
            })
            )

        } else {
            fetchMyAPIThirdParty(data.id);
        }

    };

    const _handleEditClickThirdParty = (item) => {
        if (document.getElementById('third_party_action')?.innerHTML.length > 0) {
            document.getElementById('third_party_action').style.pointerEvents = 'none';
        }
        setAddThirdPartyChargesClicked(true);
        setThirdPartyChargesExtra(quote => item);
        //setFieldsThirdPartyCharges(quote => item);

        if (item.hasOwnProperty("unique_id") && !item.hasOwnProperty("id")) {
            setThirdPartyChargesData(quoteArray => quoteArray.filter(function (data) {
                return item.unique_id !== data.unique_id
            })
            )
        } else {
            setThirdPartyChargesData(quoteArray => quoteArray.filter(function (itemData) {
                return item.id !== itemData.id
            })
            );
        }


    };

    /*  Third party section end  */



    async function fetchMyAPIQuote(id) {
        const response = await api.post("api/v1/tslDeleteQuote", {
            id: id
        },
            { headers: requestTokenHeader() }
        );
        if (response.data.errorCode === 0) {
            displaySuccessMessage("recordDeletedSuccess");
            setAddExtraClicked(false);
            setExtraData(quoteArray => quoteArray.filter(function (item) {
                return item.id !== id
            })
            );
        } else {
            displayErrorMessage("recordDeletedError");
        }
    }
    const _handleDeleteClickQuote = (data) => {
        if (data.hasOwnProperty("unique_id") && !data.hasOwnProperty("id")) {
            setExtraData(quoteArray => quoteArray.filter(function (item) {
                return item.unique_id !== data.unique_id
            })
            )
        } else {
            fetchMyAPIQuote(data.id);
        }
    };

    const _handleEditClickQuote = (item) => {
        if (document.getElementById('extra_charges_action')?.innerHTML.length > 0) {
            document.getElementById('extra_charges_action').style.pointerEvents = 'none';
        }

        setAddExtraClicked(true);
        setQuoteExtra(quote => item);

        if (item.hasOwnProperty("unique_id") && !item.hasOwnProperty("id")) {
            setExtraData(quoteArray => quoteArray.filter(function (data) {
                return item.unique_id !== data.unique_id
            })
            )
        } else {
            setExtraData(quoteArray => quoteArray.filter(function (itemData) {
                return item.id !== itemData.id
            })
            );
        }




    };



    async function fetchMyAPIPayableAPI(id) {
        const response = await api.post("api/v1/tslDeletePayable", {
            id: id
        },
            { headers: requestTokenHeader() }
        );
        if (response.data.errorCode === 0) {
            displaySuccessMessage("recordDeletedSuccess");
            setAddPayableClicked(false);
            setPayableData(quoteArray => quoteArray.filter(function (item) {
                return item.id !== id
            })
            );
        } else {
            displayErrorMessage("recordDeletedError");
        }
    }
    const _handleDeleteClickPayable = (data) => {
        if (!data.hasOwnProperty("unique_id") && !data.hasOwnProperty("id")) {
            setPayableData(quoteArray => quoteArray.filter(function (item) {
                return item.unique_id !== data.unique_id
            })
            )

        } else {
            fetchMyAPIPayableAPI(data.id);
        }


    };

    const _handleEditClickPayable = (item) => {
        if (document.getElementById('payable_action')?.innerHTML.length > 0) {
            document.getElementById('payable_action').style.pointerEvents = 'none';
        }
        setAddPayableClicked(true);
        setPayableExtra(quote => item);

        if (item.hasOwnProperty("unique_id") && !item.hasOwnProperty("id")) {
            setPayableData(quoteArray => quoteArray.filter(function (data) {
                return item.unique_id !== data.unique_id
            })
            )
        } else {
            setPayableData(quoteArray => quoteArray.filter(function (itemData) {
                return item.id !== itemData.id
            })
            );
        }


    };

    async function fetchMyAPIReceivable(id) {
        const response = await api.post("api/v1/tslDeleteReceivable", {
            id: id
        },
            { headers: requestTokenHeader() }
        );
        if (response.data.errorCode === 0) {
            displaySuccessMessage("recordDeletedSuccess");
            setAddReceivableClicked(false);
            setReceivableData(quoteArray => quoteArray.filter(function (item) {
                return item.id !== id
            })
            );
        } else {
            displayErrorMessage("recordDeletedError");
        }
    }
    const _handleDeleteClickReceivable = (data) => {

        if (data.hasOwnProperty("unique_id") && !data.hasOwnProperty("id")) {
            setReceivableData(quoteArray => quoteArray.filter(function (item) {
                return item.unique_id !== data.unique_id
            })
            )

        } else {
            fetchMyAPIReceivable(data.id);
        }
    };


    const _handleEditClickReceivable = (item) => {
        if (document.getElementById('receivable_action')?.innerHTML.length > 0) {
            document.getElementById('receivable_action').style.pointerEvents = 'none';
        }

        setAddReceivableClicked(true);
        setReceivableExtra(quote => item);

        if (item.hasOwnProperty("unique_id") && !item.hasOwnProperty("id")) {
            setReceivableData(quoteArray => quoteArray.filter(function (data) {
                return item.unique_id !== data.unique_id
            })
            )
        } else {
            setReceivableData(quoteArray => quoteArray.filter(function (itemData) {
                return item.id !== itemData.id
            })
            );
        }


    };

    const _handelCurrencyConversion = (id) => {
        let value = id.current.value;
        let conversion = currency == "USD" ? conversionUsd : conversionCad;
        id.current.value = (parseFloat(value) * parseFloat(conversion)).toFixed(2);
    }

    async function fetchMyAPI(id) {
        const response = await api.post("api/v1/tslDeleteJobComments", {
            id: id
        },
            { headers: requestTokenHeader() }
        );
        if (response.data.errorCode === 0) {
            displaySuccessMessage("jobCommentDeletedSuccess");
            document.getElementById(`id-${id}`).remove();
        } else {
            displayErrorMessage("jobCommentDeletedError");
        }
    }
    const _handleDeleteClickFiles = (id) => {
        fetchMyAPI(id);
    };
    const submitJobComment = async (params) => {
        setJobComments(params);
    };

    const handleChange = (event) => {
        let data = formFields;
        data[event.target.name] = event.target.value;
        setFormFields({ ...data });
    };

    const validateAddAccounts = (fields, applyCheck = false) => {
        let errors = {};
        let formIsValid = true;

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;



        return {
            errors: errors,
            formIsValid: formIsValid,
        };
    };

    const _validateForm = () => {
        let formFields123 = formFields;
        let response = validateAddAccounts(formFields123, applyCheck);
        setErrors(response.errors);
        return response.formIsValid;
    };

    const updateUserDetails = async (event) => {
        event.preventDefault();
        if (_validateForm()) {
            if (formFields) {


                if (props.accountsData == "") {
                    displayErrorMessage('ratesNotFound');
                    return;
                }

                const params = {
                    id: formFields.id,
                    job_number: props.jobsDetails.id,
                    conversion_cad: conversionCad,
                    conversion_usd: conversionUsd,
                    cq_amount: formFields.cq_amount,
                    cq_cad: formFields.cq_cad,
                    bl_charges: formFields.bl_charges,
                    freight: formFields.freight,
                    telux: formFields.telux,
                    esm_amount: formFields.esm_amount,
                    esm_cad: formFields.esm_cad,
                    chc_amount: formFields.chc_amount,
                    chc_cad: formFields.chc_cad,
                    ctc_cad: formFields.ctc_cad,
                    ctc_amount: formFields.ctc_amount,
                    manifest_amount: formFields.manifest_amount,
                    manifest_cad: formFields.manifest_cad,
                    manifest_paidby: formFields.manifest_paidby,

                    deposit_date: formFields.deposit_date,
                    deposit_note: formFields.deposit_note,
                    job_completed: formFields.job_completed,
                    invoice_documentation: formFields.invoice_documentation,
                    job_status_id: formFields.job_status_id,
                    need_release: formFields.need_release,
                    job_comment: JSON.stringify((jobComments && Object.keys(jobComments).length !== 0) ? Array.isArray(jobComments) ? jobComments : [].push(jobComments) : []),


                    quoteExtra: JSON.stringify((extraData && Object.keys(extraData).length !== 0) ? Array.isArray(extraData) ? extraData : [].push(extraData) : []),
                    ec_usd: ecUsd,
                    ec_cad: ecCad,
                    receivableExtra: JSON.stringify((receivableData && Object.keys(receivableData).length !== 0) ? Array.isArray(receivableData) ? receivableData : [].push(receivableData) : []),
                    payableExtra: JSON.stringify((payableData && Object.keys(payableData).length !== 0) ? Array.isArray(payableData) ? payableData : [].push(payableData) : []),
                    extra_payable_usd: extraPayableUsd,
                    extra_payable_cad: extraPayableCad,
                    ssl_amount: formFields.ssl_amount,
                    ssl_cad: formFields.ssl_cad,
                    ssl_with_others: formFields.ssl_with_others,
                    ssl_with_others_cad: formFields.ssl_with_others_cad,
                    ssl1_amount: formFields.ssl1_amount,
                    ssl1_cad: formFields.ssl1_cad,
                    steamship_invoice_no: formFields.steamship_invoice_no,
                    sailing_date: formFields.sailing_date,
                    ssl_paid_date: formFields.ssl_paid_date,
                    ssl_date_new: formFields.ssl_date_new,
                    ssl_paid_remarks: formFields.ssl_paid_remarks,
                    trucker_amount: formFields.trucker_amount,
                    trucker_cad: formFields.trucker_cad,
                    trucker_paid_amount: formFields.trucker_paid_amount,
                    trucker_paid_cad: formFields.trucker_paid_cad,
                    trucker_invoice_no: formFields.trucker_invoice_no,
                    trucker_invoice_status: formFields.trucker_invoice_status,
                    trucker_paid_date: formFields.trucker_paid_date,
                    trucker_date_new: formFields.trucker_date_new,
                    trucker_paid_remarks: formFields.trucker_paid_remarks,
                    thirdPartyExtra: JSON.stringify((thirdPartyChargesData && Object.keys(thirdPartyChargesData).length !== 0) ? Array.isArray(thirdPartyChargesData) ? thirdPartyChargesData : [].push(thirdPartyChargesData) : []),
                    third_party_charges_usd: extraThirdPartyChargesUsd,
                    third_party_charges_cad: extraThirdPartyChargesCad,
                    invoice_documentation: formFields.invoice_documentation,
                    need_release: formFields.need_release,

                    total_payable_usd: totalPayableUsd,
                    total_payable_cad: totalPayableCad,
                    total_recievable_usd: totalRecievableUsd,
                    total_recievable_cad: totalRecievableCad,
                    gain_usd: gainUsd,
                    gain_cad: gainCad,
                    rates_final: finalRates ? '1' : '0'
                }
                if (props.role === 'rates' && !(props?.accountsDataById?.[0][0]?.rates_final === '1') && finalRates) {
                    getUserIds({ 'roles.name': 'accounts' }, function (userIds) {
                        userIds.length && userIds.map((user_rec, index) => {
                            dispatch(addNotificationDetails({
                                user_id: user_rec.id,
                                resource: 'jobs',
                                resource_id: props?.jobsDetails?.id,
                                content: 'Cost finalized by Rates Team for  Job number  #' + props?.jobsDetails?.job_number
                            }));
                        });
                    });
                }
                props.editUserDetails(params)

            }
        }
    }

    function conversion(value, currency) {
        let newValue = "";
        if (value != "" && value > 0 && conversionUsd != "" && conversionCad != "") {

            if (currency == "USD") {
                newValue = (value * conversionUsd).toFixed(2);


            } else {
                newValue = (value * conversionCad).toFixed(2);
            }


        } else {
            value = "";
            newValue = "";

        }

        return [value, newValue];

    }

    /* END   Receivable section */
    const _handleEditClickFiles = (item) => {
        setCommentModel(item);
        setShowModel(true);
    };
    const handleCommentChange = (e) => {
        let data = commentModel;
        data["job_comment"] = e.target.value;
        setCommentModel({ ...data });

    }

    async function fetchMyAPI2(data) {
        const response = await api.post("api/v1/tslEditJobComment", {
            id: data.id,
            user_id: data.user_id,
            job_comment: data.job_comment
        },
            { headers: requestTokenHeader() }
        );
        if (response.data.errorCode === 0) {
            displaySuccessMessage("jobCommentUpdateSuccess");
            setShowModel(false);
            setCommentModel(false);
            document.getElementById(`id-${data.id}`).children[0].innerHTML = data.job_comment;


        } else {
            displayErrorMessage("jobCommentUpdatedError");
            setShowModel(false);
            setCommentModel(false);
        }
    }
    const editComment = () => {
        fetchMyAPI2(commentModel)

    }
    return (
        <>
            <div className="modal-body">
                <ul className="required-details">
                    <li className='w-100'>Line Payment:
                        <code>
                            {(formFields?.ssl_cad || formFields?.ssl_amount || formFields?.ssl1_cad || formFields?.ssl1_amount
                                || formFields?.ssl_with_others_cad || formFields?.ssl_with_others || (formFields?.sailing_date && formFields?.sailing_date !== '0000-00-00')
                                || (formFields?.ssl_paid_date && formFields?.ssl_paid_date !== '0000-00-00') || (formFields?.ssl_date_new && formFields?.ssl_date_new !== '0000-00-00')

                                || formFields?.ssl_paid_remarks || formFields?.trucker_cad || formFields?.trucker_amount || formFields?.trucker_paid_cad
                                || formFields?.trucker_paid_amount

                                || (formFields?.trucker_paid_date && formFields?.trucker_paid_date !== '0000-00-00') || (formFields?.trucker_date_new && formFields?.trucker_date_new !== '0000-00-00') || formFields?.trucker_paid_remarks || formFields?.manifest_cad
                                || formFields?.manifest_amount
                            )
                                ? "Paid" : "Not paid"}
                        </code>
                    </li>
                    <li>Job No: <code >{props.jobsDetails.job_number}</code> </li>
                    <li>Job For: <code >{props.jobsDetails.job_for}</code> </li>
                    <li>Booking No: <code >{props.jobsDetails.booking_number}</code> </li>
                    <li>Container No: <code >{props.jobsDetails.container_number}</code></li>
                    <li>Customer: <code >{props.jobsDetails.firstname + (props.jobsDetails.lastname ? ' ' + props.jobsDetails.lastname : '')}</code> </li>
                    <li>Carrier: <code> {props.jobsDetails.carriers_name}</code></li>
                    <li>Added: <code > {moment(props.jobsDetails.created_at).format("YYYY-MM-DD")}</code></li>
                    <li>Updated: <code > {moment(props.jobsDetails.updated_at).format("YYYY-MM-DD")}</code></li>
                    <li>Trucker: <code> {props.jobsDetails.trucker_name}</code></li>
                    <li>Doc CutOff Date: <code > {props.jobsDetails.doc_cutoff_date && props.jobsDetails.doc_cutoff_date !== '0000-00-00' ? props.jobsDetails.doc_cutoff_date : ''}</code></li>
                    <li>Vgm CutOff Date: <code > {props.jobsDetails.vgm_cutoff_date && props.jobsDetails.vgm_cutoff_date !== '0000-00-00' ? props.jobsDetails.vgm_cutoff_date : ''}</code></li>
                    <li>Cargo CutOff Date: <code > {props.jobsDetails.cargo_curtoff_date && props.jobsDetails.cargo_curtoff_date !== '0000-00-00' ? props.jobsDetails.cargo_curtoff_date : ''}</code></li>
                </ul>
                <form onSubmit={(event) => updateUserDetails(event)} className='m-0 p-0'>
                    <div className="row login-wrap-new mb-4" >
                        <div className="col-md-12 col-xs-12">
                            <div className="form-content">
                                {props.accountsData == "" && <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-12 ">

                                        <div className="alert alert-danger">
                                            <strong>Rates not found!</strong> Please add rates for today.
                                        </div>
                                    </div>

                                </div>}

                                <div className='row'>
                                    <div className="col-12 ">

                                        <Accordion defaultActiveKey="0">


                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    Customer Quote
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row mt-4">

                                                        <div className="col-sm-4">
                                                            <fieldset>
                                                                <legend>Conversion</legend>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div className='text-center'>
                                                                        <label>({currency == "USD" ? "usd_to_cad_rate" : "cad_to_usd_rate"})</label>
                                                                        <br />  {currency == "USD" ? conversionUsd : conversionCad}
                                                                    </div>
                                                                    <div className='text-center'>
                                                                        <label>({currency != "USD" ? "usd_to_cad_rate" : "cad_to_usd_rate"})</label>
                                                                        <br />  {currency != "USD" ? conversionUsd : conversionCad}
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-sm-4" >
                                                            <fieldset>
                                                                <legend>Customer Quote Amount</legend>
                                                                <div className='d-flex'>
                                                                    <div className="left-inner-addon mr-2">
                                                                        <i className="">CAD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="CAD Amount"
                                                                            name="cq_cad"
                                                                            id="cq_cad"
                                                                            value={formFields.cq_cad}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "CAD");
                                                                                let data = formFields;
                                                                                data["cq_cad"] = response[0];
                                                                                data["cq_amount"] = response[1];
                                                                                setFormFields({ ...data });

                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="left-inner-addon">
                                                                        <i className="">USD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="USD Amount"
                                                                            name="cq_amount"
                                                                            id="cq_amount"
                                                                            value={formFields?.cq_amount}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "USD");
                                                                                let data = formFields;
                                                                                data["cq_amount"] = response[0];
                                                                                data["cq_cad"] = response[1];
                                                                                setFormFields({ ...data });

                                                                            }}

                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className='d-flex'>
                                                                    <div className="left-inner-addon">
                                                                        <i>BL</i>
                                                                        <input type='text'
                                                                            className='form-control'
                                                                            placeholder='bl charges'
                                                                            name='bl_charges'
                                                                            id='bl_charges'
                                                                            value={formFields.bl_charges}
                                                                            onChange={(e) => updateCqCad(e)}
                                                                        />
                                                                    </div>
                                                                    <div className="left-inner-addon">
                                                                        <i>Freig.</i>
                                                                        <input type='text'
                                                                            className='form-control'
                                                                            placeholder='freight'
                                                                            name='freight'
                                                                            id='freight'
                                                                            value={formFields.freight}
                                                                            onChange={(e) => updateCqCad(e)}
                                                                        />
                                                                    </div>
                                                                    <div className="left-inner-addon">
                                                                        <i>Telux</i>
                                                                        <input type='text'
                                                                            className='form-control'
                                                                            placeholder='telux'
                                                                            name='telux'
                                                                            id='telux'
                                                                            value={formFields.telux}
                                                                            onChange={(e) => updateCqCad(e)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-sm-4" >
                                                            <fieldset>
                                                                <legend>Scale/Extra Move</legend>
                                                                <div className='d-flex'>
                                                                    <div className="left-inner-addon mr-2">
                                                                        <i className="">CAD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="CAD Amount"
                                                                            name="esm_cad"
                                                                            id="esm_cad"
                                                                            value={formFields.esm_cad}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "CAD");
                                                                                let data = formFields;
                                                                                data["esm_cad"] = response[0];
                                                                                data["esm_amount"] = response[1];
                                                                                setFormFields({ ...data });

                                                                            }}

                                                                        />


                                                                    </div>

                                                                    <div className="left-inner-addon">
                                                                        <i className="">USD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="USD Amount"
                                                                            name="esm_amount"
                                                                            id="esm_amount"
                                                                            value={formFields?.esm_amount}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "USD");
                                                                                let data = formFields;
                                                                                data["esm_amount"] = response[0];
                                                                                data["esm_cad"] = response[1];
                                                                                setFormFields({ ...data });

                                                                            }}

                                                                        />

                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-sm-4" >
                                                            <fieldset>
                                                                <legend>Custom Hold Charges</legend>
                                                                <div className='d-flex'>
                                                                    <div className="left-inner-addon mr-2">
                                                                        <i className="">CAD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="CAD Amount"
                                                                            name="chc_cad"
                                                                            id="chc_cad"
                                                                            value={formFields.chc_cad}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "CAD");
                                                                                let data = formFields;
                                                                                data["chc_cad"] = response[0];
                                                                                data["chc_amount"] = response[1];
                                                                                setFormFields({ ...data });

                                                                            }}

                                                                        />


                                                                    </div>

                                                                    <div className="left-inner-addon">
                                                                        <i className="">USD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="USD Amount"
                                                                            name="chc_amount"
                                                                            id="chc_amount"
                                                                            value={formFields?.chc_amount}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "USD");
                                                                                let data = formFields;
                                                                                data["chc_amount"] = response[0];
                                                                                data["chc_cad"] = response[1];
                                                                                setFormFields({ ...data });

                                                                            }}

                                                                        />

                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-sm-4" >
                                                            <fieldset>
                                                                <legend>Customer Trucking Charges</legend>
                                                                <div className='d-flex'>
                                                                    <div className="left-inner-addon mr-2">
                                                                        <i className="">CAD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="CAD Amount"
                                                                            name="ctc_cad"
                                                                            id="ctc_cad"
                                                                            value={formFields.ctc_cad}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "CAD");
                                                                                let data = formFields;
                                                                                data["ctc_cad"] = response[0];
                                                                                data["ctc_amount"] = response[1];
                                                                                setFormFields({ ...data });

                                                                            }}

                                                                        />


                                                                    </div>

                                                                    <div className="left-inner-addon">
                                                                        <i className="">USD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="USD Amount"
                                                                            name="ctc_amount"
                                                                            id="ctc_amount"
                                                                            value={formFields?.ctc_amount}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "USD");
                                                                                let data = formFields;
                                                                                data["ctc_amount"] = response[0];
                                                                                data["ctc_cad"] = response[1];
                                                                                setFormFields({ ...data });

                                                                            }}

                                                                        />

                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className=" col-sm-4">
                                                            <fieldset>
                                                                <legend>Extra Charges</legend>
                                                                <div className='d-flex mt-2 mb-2 align-items-center justify-content-between'>
                                                                    <input
                                                                        type="text"
                                                                        className="ec_total_usd_box ec_box"
                                                                        id="ec_usd"
                                                                        name="ec_usd"
                                                                        value={ecUsd.toFixed(2)}
                                                                    />
                                                                    <span >USD</span> <br />
                                                                    <input
                                                                        type="text"
                                                                        className="ec_total_cad_box ec_box"
                                                                        id="ec_cad"
                                                                        name="ec_cad"
                                                                        value={ecCad.toFixed(2)}
                                                                    />
                                                                    <span >CAD</span>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        {
                                                            props.role === 'rates' && !(props?.accountsDataById?.[0][0]?.rates_final === '1') &&
                                                            <div className="col-sm-4">
                                                                <fieldset>
                                                                    <legend>Submit To Accounts</legend>
                                                                    <div className='input-block d-flex align-items-center mb-4 mt-4'>
                                                                        <input
                                                                            className=''
                                                                            type="checkbox"
                                                                            onChange={(e) => {
                                                                                setFinalRates(!finalRates);
                                                                            }}
                                                                            checked={finalRates}
                                                                        />
                                                                        <span className='ms-2'>Final Rates</span>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                        }

                                                        <div className=" col-sm-4">
                                                            <fieldset>
                                                                <input type='button' className="btn btn-primary w-100" value={"Extra charges"} onClick={handelExtraClick} />
                                                            </fieldset>
                                                        </div>


                                                    </div>


                                                    {addExtraClicked && (<div className='receivable' style={{ marginTop: "0px" }}>
                                                        <div className="row">

                                                            <div className=" col-sm-4">
                                                                <label>Service Name</label>
                                                                <input
                                                                    className="form-control service_name clear-fields"
                                                                    type="text"
                                                                    name="service_name"
                                                                    value={quoteExtra.service_name}
                                                                    onChange={(event) => handleChangeQuoteExtra(event)}
                                                                />
                                                                {errors.service_name ? (
                                                                    <div className="errorMsg text-danger">
                                                                        {errors.service_name}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="col-sm-4" >
                                                                <label>Service Amount</label>
                                                                <div className='d-flex'>
                                                                    <div className="left-inner-addon mr-2">
                                                                        <i className="">CAD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="CAD Amount"
                                                                            name="cad_amount"
                                                                            id="cad_amount"
                                                                            value={quoteExtra.cad_amount}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "CAD");
                                                                                let data = quoteExtra;
                                                                                data["cad_amount"] = response[0];
                                                                                data["usd_amount"] = response[1];
                                                                                setQuoteExtra({ ...data });

                                                                            }}

                                                                        />


                                                                    </div>

                                                                    <div className="left-inner-addon">
                                                                        <i className="">USD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="USD Amount"
                                                                            name="usd_amount"
                                                                            id="usd_amount"
                                                                            value={quoteExtra?.usd_amount}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "USD");
                                                                                let data = quoteExtra;
                                                                                data["usd_amount"] = response[0];
                                                                                data["cad_amount"] = response[1];
                                                                                setQuoteExtra({ ...data });

                                                                            }}

                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className=" col-sm-4">
                                                                <label>Extra Charges Remarks</label><br />
                                                                <textarea
                                                                    className="form-control extra_charges_remarks clear-fields"
                                                                    type="textarea"
                                                                    id="extra_charges_remarks"
                                                                    name="extra_charges_remarks"
                                                                    rows={1}
                                                                    value={quoteExtra.extra_charges_remarks}
                                                                    onChange={(event) => handleChangeQuoteExtra(event)}
                                                                />
                                                                {errors.extra_charges_remarks ? (
                                                                    <div className="errorMsg text-danger">
                                                                        {errors.extra_charges_remarks}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>

                                                        </div>

                                                        <div className="row" style={{ marginTop: "20px" }}>

                                                            <div className="col-sm-4">
                                                                <input type='button' name="submit" className="btn btn-primary extra-submit" value="Add charges" onClick={(e) => updateQuoteExtraDetails(e)} />
                                                                <input type='button' name="cancel" className="btn btn-danger ml-2 payment-cancel" value="Cancel" onClick={handelCancelExtra} />
                                                            </div>
                                                        </div>

                                                    </div>)}

                                                    <div className='table-responsive'>

                                                        {(extraData && extraData.length > 0) && (<table id="extra_charges_action" className="table table-striped payable-charges-table" style={{ marginTop: "20px" }}>
                                                            <thead>
                                                                <tr>

                                                                    <th>Sr No.</th>
                                                                    <th>Conversion CAD</th>
                                                                    <th>Conversion USD</th>
                                                                    <th>Name</th>
                                                                    <th>CAD Amount </th>
                                                                    <th>USD Amount </th>
                                                                    <th>Extra Charges Remarks</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {extraData.map((item, i) => {

                                                                    return [
                                                                        <tr key={i} id={`id-${item?.id ? item.id : i}`}>
                                                                            <td>{i + 1}</td>
                                                                            <td>{item.conversion_cad}</td>
                                                                            <td>{item.conversion_usd}</td>
                                                                            <td>{item.service_name}</td>
                                                                            <td>{parseFloat(item.cad_amount).toFixed(2)}</td>
                                                                            <td>{parseFloat(item.usd_amount).toFixed(2)}</td>
                                                                            <td>{item.extra_charges_remarks}</td>
                                                                            <td>
                                                                                <button
                                                                                    type="button"
                                                                                    className="bd-none btn btn-warning btn-xsm"
                                                                                    onClick={() => {
                                                                                        _handleEditClickQuote(item);

                                                                                    }}
                                                                                    data-toggle="tooltip" data-placement="top" title="Edit"
                                                                                >
                                                                                    <i className="fa fa-fw fa-edit"></i>
                                                                                </button>

                                                                                <button
                                                                                    type="button"
                                                                                    className="bd-none btn btn-danger btn-xsm"
                                                                                    onClick={() => {
                                                                                        _handleDeleteClickQuote(item);

                                                                                    }}
                                                                                    data-toggle="tooltip" data-placement="top" title="Delete"
                                                                                >
                                                                                    <i className="fa fa-fw fa-trash"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    ]

                                                                })}



                                                            </tbody>
                                                        </table>)}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    Third Party Charges
                                                </Accordion.Header>
                                                <Accordion.Body>

                                                    <div className="row mt-4 align-items-center">
                                                        <div className=" col-sm-4">
                                                            <fieldset>
                                                                <legend>Third Party Charges</legend>
                                                                <div className='d-flex mt-2 mb-2 align-items-center justify-content-between'>
                                                                    <input
                                                                        type="text"
                                                                        className="ec_total_usd_box ec_box"
                                                                        id="third_party_charges_usd"
                                                                        name="third_party_charges_usd"
                                                                        value={extraThirdPartyChargesUsd.toFixed(2)}
                                                                    />
                                                                    <span >USD</span> <br />
                                                                    <input
                                                                        type="text"
                                                                        className="ec_total_cad_box ec_box"
                                                                        id="third_party_charges_cad"
                                                                        name="third_party_charges_cad"
                                                                        value={extraThirdPartyChargesCad.toFixed(2)}
                                                                    />
                                                                    <span >CAD</span>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className=" col-sm-4">
                                                            <fieldset>
                                                                <input type='button' className="btn btn-primary w-100" value={"Add Third Party charges"} onClick={handelThirdPartyChargesClick} />
                                                            </fieldset>
                                                        </div>
                                                    </div>

                                                    {addThirdPartyChargesClicked && (<div className='receivable' style={{ marginTop: "30px" }}>
                                                        <div className="row">

                                                            <div className=" col-sm-4">
                                                                <label>Third Party Name</label>
                                                                <input
                                                                    className="form-control third_party_name clear-fields"
                                                                    type="text"
                                                                    name="third_party_name"
                                                                    value={thirdPartyChargesExtra.third_party_name}
                                                                    onChange={(event) => handleChangeThirdPartyCharges(event)}
                                                                />
                                                                {errors.third_party_name ? (
                                                                    <div className="errorMsg text-danger">
                                                                        {errors.third_party_name}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className=" col-sm-4">
                                                                <label>Third Party Email</label>
                                                                <input
                                                                    className="form-control third_party_email clear-fields"
                                                                    type="text"
                                                                    name="third_party_email"
                                                                    value={thirdPartyChargesExtra.third_party_email}
                                                                    onChange={(event) => handleChangeThirdPartyCharges(event)}
                                                                />
                                                                {errors.third_party_email ? (
                                                                    <div className="errorMsg text-danger">
                                                                        {errors.third_party_email}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="col-sm-4" >
                                                                <label>Third Party Amount</label>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="left-inner-addon mr-2">
                                                                        <i className="">CAD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="CAD Amount"
                                                                            name="cad_amount"
                                                                            id="cad_amount"
                                                                            value={thirdPartyChargesExtra.cad_amount}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "CAD");
                                                                                let data = thirdPartyChargesExtra;
                                                                                data["cad_amount"] = response[0];
                                                                                data["usd_amount"] = response[1];
                                                                                setThirdPartyChargesExtra({ ...data });

                                                                            }}

                                                                        />


                                                                    </div>

                                                                    <div className="left-inner-addon">
                                                                        <i className="">USD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="USD Amount"
                                                                            name="usd_amount"
                                                                            id="usd_amount"
                                                                            value={thirdPartyChargesExtra?.usd_amount}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "USD");
                                                                                let data = thirdPartyChargesExtra;
                                                                                data["usd_amount"] = response[0];
                                                                                data["cad_amount"] = response[1];
                                                                                setThirdPartyChargesExtra({ ...data });

                                                                            }}

                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className=" col-sm-12">
                                                                <label>Third Party charges Remarks</label><br />
                                                                <textarea
                                                                    className="form-control third_party_remarks clear-fields"
                                                                    type="textarea"
                                                                    id="third_party_remarks"
                                                                    name="third_party_remarks"
                                                                    rows={2}
                                                                    value={thirdPartyChargesExtra.third_party_remarks}
                                                                    onChange={(event) => handleChangeThirdPartyCharges(event)}
                                                                />
                                                                {errors.third_party_remarks ? (
                                                                    <div className="errorMsg text-danger">
                                                                        {errors.third_party_remarks}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>

                                                        </div>

                                                        <div className="row" style={{ marginTop: "20px" }}>

                                                            <div className="col-sm-4">
                                                                <input type='button' name="submit" className="btn btn-primary extra-submit" value="Add charges" onClick={(e) => updateThirdPartyChargesDetails(e)} />

                                                                <input type='button' name="cancel" className="btn btn-danger payment-cancel ml-2" value="Cancel" onClick={handelCancelThirdPartyCharges} />
                                                            </div>
                                                        </div>

                                                    </div>)}

                                                    <div className='table-responsive'>

                                                        {(thirdPartyChargesData && thirdPartyChargesData.length > 0) && (
                                                            <table id="third_party_action" className="table table-striped payable-charges-table" style={{ marginTop: "20px" }}>
                                                                <thead>
                                                                    <tr>

                                                                        <th>Sr No.</th>
                                                                        <th>Conversion CAD</th>
                                                                        <th>Conversion USD</th>
                                                                        <th>Third Party Name</th>
                                                                        <th>Third Party Email</th>
                                                                        <th>CAD Amount </th>
                                                                        <th>USD Amount </th>
                                                                        <th>Third Party Charges Remarks</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {thirdPartyChargesData.map((item, i) => {

                                                                        return [
                                                                            <tr key={i} id={`id-${item?.id ? item.id : i}`}>
                                                                                <td>{i + 1}</td>
                                                                                <td>{item.conversion_cad}</td>
                                                                                <td>{item.conversion_usd}</td>
                                                                                <td>{item.third_party_name}</td>
                                                                                <td>{item.third_party_email}</td>
                                                                                <td>{parseFloat(item.cad_amount).toFixed(2)}</td>
                                                                                <td>{parseFloat(item.usd_amount).toFixed(2)}</td>
                                                                                <td>{item.third_party_remarks}</td>
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="bd-none btn btn-primary btn-xsm"
                                                                                        onClick={() => {
                                                                                            _handleEditClickThirdParty(item);

                                                                                        }}
                                                                                        data-toggle="tooltip" data-placement="top" title="Edit"
                                                                                    >
                                                                                        <i className="fa fa-fw fa-edit"></i>
                                                                                    </button>

                                                                                    <button
                                                                                        type="button"
                                                                                        className="bd-none btn btn-danger btn-xsm"
                                                                                        onClick={() => {
                                                                                            _handleDeleteClickThirdParty(item);

                                                                                        }}
                                                                                        data-toggle="tooltip" data-placement="top" title="Delete"
                                                                                    >
                                                                                        <i className="fa fa-fw fa-trash"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ]

                                                                    })}



                                                                </tbody>
                                                            </table>)}
                                                    </div>

                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    Payable
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="our_expenses mt-4">
                                                        <fieldset>
                                                            <legend>SSL</legend>
                                                            <div className="row ">
                                                                <div className="col-sm-3" >
                                                                    <label>SSL Invoice</label>
                                                                    <div className='d-flex'>
                                                                        <div className="left-inner-addon mr-2">
                                                                            <i className="">CAD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="CAD Amount"
                                                                                name="ssl_cad"
                                                                                id="ssl_cad"
                                                                                value={formFields.ssl_cad}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "CAD");
                                                                                    let data = formFields;
                                                                                    data["ssl_cad"] = response[0];
                                                                                    data["ssl_amount"] = response[1];
                                                                                    setFormFields({ ...data });
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="left-inner-addon">
                                                                            <i className="">USD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="USD Amount"
                                                                                name="ssl_amount"
                                                                                id="ssl_amount"
                                                                                value={formFields?.ssl_amount}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "USD");
                                                                                    let data = formFields;
                                                                                    data["ssl_amount"] = response[0];
                                                                                    data["ssl_cad"] = response[1];
                                                                                    setFormFields({ ...data });

                                                                                }}

                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3" >
                                                                    <label>SSL inluding other charges</label>
                                                                    <div className='d-flex'>
                                                                        <div className="left-inner-addon mr-2">
                                                                            <i className="">CAD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="CAD Amount"
                                                                                name="ssl_with_others_cad"
                                                                                id="ssl_with_others_cad"
                                                                                value={formFields.ssl_with_others_cad}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "CAD");
                                                                                    let data = formFields;
                                                                                    data["ssl_with_others_cad"] = response[0];
                                                                                    data["ssl_with_others"] = response[1];
                                                                                    setFormFields({ ...data });

                                                                                }}

                                                                            />


                                                                        </div>

                                                                        <div className="left-inner-addon">
                                                                            <i className="">USD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="USD Amount"
                                                                                name="ssl_with_others"
                                                                                id="ssl_with_others"
                                                                                value={formFields?.ssl_with_others}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "USD");
                                                                                    let data = formFields;
                                                                                    data["ssl_with_others"] = response[0];
                                                                                    data["ssl_with_others_cad"] = response[1];
                                                                                    setFormFields({ ...data });

                                                                                }}

                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3" >
                                                                    <label>SSL Invoice Extra</label>
                                                                    <div className='d-flex'>
                                                                        <div className="left-inner-addon mr-2">
                                                                            <i className="">CAD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="CAD Amount"
                                                                                name="ssl1_cad"
                                                                                id="ssl1_cad"
                                                                                value={formFields.ssl1_cad}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "CAD");
                                                                                    let data = formFields;
                                                                                    data["ssl1_cad"] = response[0];
                                                                                    data["ssl1_amount"] = response[1];
                                                                                    setFormFields({ ...data });

                                                                                }}

                                                                            />


                                                                        </div>

                                                                        <div className="left-inner-addon">
                                                                            <i className="">USD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="USD Amount"
                                                                                name="ssl1_amount"
                                                                                id="ssl1_amount"
                                                                                value={formFields?.ssl1_amount}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "USD");
                                                                                    let data = formFields;
                                                                                    data["ssl1_amount"] = response[0];
                                                                                    data["ssl1_cad"] = response[1];
                                                                                    setFormFields({ ...data });

                                                                                }}

                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className=" col-sm-3">
                                                                    <label>Steamship Invoice No.</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="steamship_invoice_no"
                                                                        name="steamship_invoice_no"
                                                                        value={formFields?.steamship_invoice_no == "null" || formFields?.steamship_invoice_no == "undefined" ? "" : formFields?.steamship_invoice_no}
                                                                        onChange={(event) => handleChange(event)}
                                                                    />

                                                                </div>

                                                                <div className=" col-sm-3">
                                                                    <label>Sailing Date</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="date"
                                                                        id="sailing_date"
                                                                        name="sailing_date"
                                                                        value={formFields?.sailing_date}
                                                                        onChange={(event) => handleChange(event)}
                                                                    />

                                                                </div>

                                                                <div className=" col-sm-3">
                                                                    <label>SSL Paid Date</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="date"
                                                                        id="ssl_paid_date"
                                                                        name="ssl_paid_date"
                                                                        value={formFields?.ssl_paid_date}
                                                                        onChange={(event) => handleChange(event)}
                                                                    />

                                                                </div>

                                                                <div className=" col-sm-3">
                                                                    <label>SSL Paid Date New</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="date"
                                                                        id="ssl_date_new"
                                                                        name="ssl_date_new"
                                                                        value={formFields?.ssl_date_new}
                                                                        onChange={(event) => handleChange(event)}
                                                                    />

                                                                </div>

                                                                <div className=" col-sm-3">
                                                                    <label>SSL Paid Remarks</label>
                                                                    <textarea
                                                                        name="ssl_paid_remarks"
                                                                        id="ssl_paid_remarks"
                                                                        className="form-control"
                                                                        rows={1}
                                                                        value={formFields?.ssl_paid_remarks == "null" || formFields?.ssl_paid_remarks == "undefined" ? "" : formFields?.ssl_paid_remarks}
                                                                        onChange={(event) => handleChange(event)}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </fieldset>


                                                        <fieldset>
                                                            <legend>Trucker </legend>
                                                            <div className="row" >
                                                                <div className="col-sm-3" >
                                                                    <label>Trucker Charges</label>
                                                                    <div className='d-flex'>
                                                                        <div className="left-inner-addon mr-2">
                                                                            <i className="">CAD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="CAD Amount"
                                                                                name="trucker_cad"
                                                                                id="trucker_cad"
                                                                                value={formFields.trucker_cad}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "CAD");
                                                                                    let data = formFields;
                                                                                    data["trucker_cad"] = response[0];
                                                                                    data["trucker_amount"] = response[1];
                                                                                    setFormFields({ ...data });

                                                                                }}

                                                                            />


                                                                        </div>

                                                                        <div className="left-inner-addon">
                                                                            <i className="">USD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="USD Amount"
                                                                                name="trucker_amount"
                                                                                id="trucker_amount"
                                                                                value={formFields?.trucker_amount}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "USD");
                                                                                    let data = formFields;
                                                                                    data["trucker_amount"] = response[0];
                                                                                    data["trucker_cad"] = response[1];
                                                                                    setFormFields({ ...data });

                                                                                }}

                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3" >
                                                                    <label>Trucker Paid Charges</label>
                                                                    <div className='d-flex'>
                                                                        <div className="left-inner-addon mr-2">
                                                                            <i className="">CAD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="CAD Amount"
                                                                                name="trucker_paid_cad"
                                                                                id="trucker_paid_cad"
                                                                                value={formFields.trucker_paid_cad}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "CAD");
                                                                                    let data = formFields;
                                                                                    data["trucker_paid_cad"] = response[0];
                                                                                    data["trucker_paid_amount"] = response[1];
                                                                                    setFormFields({ ...data });

                                                                                }}

                                                                            />


                                                                        </div>

                                                                        <div className="left-inner-addon">
                                                                            <i className="">USD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="USD Amount"
                                                                                name="trucker_paid_amount"
                                                                                id="trucker_paid_amount"
                                                                                value={formFields?.trucker_paid_amount}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "USD");
                                                                                    let data = formFields;
                                                                                    data["trucker_paid_amount"] = response[0];
                                                                                    data["trucker_paid_cad"] = response[1];
                                                                                    setFormFields({ ...data });

                                                                                }}

                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className=" col-sm-3">
                                                                    <label>Trucker Invoice No.</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="trucker_invoice_no"
                                                                        name="trucker_invoice_no"
                                                                        value={formFields?.trucker_invoice_no == "null" || formFields?.trucker_invoice_no == "undefined" ? "" : formFields?.trucker_invoice_no}
                                                                        onChange={(event) => handleChange(event)}
                                                                    />

                                                                </div>
                                                                <div className=" col-sm-3">
                                                                    <label>Trucker Paid Status</label>
                                                                    <select
                                                                        id="trucker_invoice_status"
                                                                        name="trucker_invoice_status"
                                                                        className="form-control"
                                                                        value={formFields?.trucker_invoice_status}
                                                                        onChange={(event) => handleChange(event)}
                                                                    >
                                                                        <option>--Select Paid Status--</option>
                                                                        <option value="Yes">Yes</option>
                                                                        <option value="No">No</option>
                                                                    </select>

                                                                </div>

                                                                <div className=" col-sm-3">
                                                                    <label>Trucker Invoice date</label>
                                                                    <input
                                                                        id="trucker_paid_date"
                                                                        className="form-control"
                                                                        type="date"
                                                                        name="trucker_paid_date"
                                                                        value={formFields?.trucker_paid_date}
                                                                        onChange={(event) => handleChange(event)}
                                                                    />


                                                                </div>
                                                                <div className=" col-sm-3">
                                                                    <label>Trucker Paid Invoice Date</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="date"
                                                                        id="trucker_date_new"
                                                                        name="trucker_date_new"
                                                                        value={formFields?.trucker_date_new}
                                                                        onChange={(event) => handleChange(event)}
                                                                    />

                                                                </div>
                                                                <div className=" col-sm-6">
                                                                    <label>Trucker Paid Remarks</label>
                                                                    <textarea
                                                                        name="trucker_paid_remarks"
                                                                        id="trucker_paid_remarks"
                                                                        rows={1}
                                                                        className="form-control"
                                                                        value={formFields?.trucker_paid_remarks == "null" || formFields?.trucker_paid_remarks == "undefined" ? "" : formFields?.trucker_paid_remarks}
                                                                        onChange={(event) => handleChange(event)}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </fieldset>


                                                        <div className='row align-items-center'>
                                                            <div className="col-sm-4" >
                                                                <fieldset>
                                                                    <legend>Manifest Correction/Other Charges</legend>
                                                                    <div className='d-flex'>
                                                                        <div className="left-inner-addon mr-2">
                                                                            <i className="">CAD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="CAD Amount"
                                                                                name="manifest_cad"
                                                                                id="manifest_cad"
                                                                                value={formFields.manifest_cad}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "CAD");
                                                                                    let data = formFields;
                                                                                    data["manifest_cad"] = response[0];
                                                                                    data["manifest_amount"] = response[1];
                                                                                    setFormFields({ ...data });

                                                                                }}

                                                                            />


                                                                        </div>

                                                                        <div className="left-inner-addon">
                                                                            <i className="">USD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="USD Amount"
                                                                                name="manifest_amount"
                                                                                id="manifest_amount"
                                                                                value={formFields?.manifest_amount}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "USD");
                                                                                    let data = formFields;
                                                                                    data["manifest_amount"] = response[0];
                                                                                    data["manifest_cad"] = response[1];
                                                                                    setFormFields({ ...data });

                                                                                }}

                                                                            />

                                                                        </div>

                                                                    </div>
                                                                </fieldset>
                                                            </div>


                                                            <div className="col-sm-4">
                                                                <fieldset>
                                                                    <legend >Charges Payable Extra </legend>
                                                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                                                        <input
                                                                            name="extra_payable_usd"
                                                                            id="extra_payable_usd"
                                                                            type="text"
                                                                            className="pay_total_usd_box ec_box"
                                                                            value={extraPayableUsd.toFixed(2)} />
                                                                        <span >USD</span>
                                                                        <br />
                                                                        <input
                                                                            name="extra_payable_cad"
                                                                            id="extra_payable_cad"
                                                                            type="text"
                                                                            className="pay_total-cad_box ec_box"
                                                                            value={extraPayableCad.toFixed(2)} />
                                                                        <span >CAD</span>
                                                                    </div>
                                                                </fieldset>
                                                            </div>

                                                            <div className=" col-sm-4">
                                                                <fieldset>
                                                                    <input type="button" name="payable_extra_charges" className="btn btn-primary w-100" value="Payable Extra Charges" onClick={handelPayableClick} />
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {addPayableClicked && (<div className='receivable' style={{ marginTop: "0px" }}>
                                                        <div className="row">

                                                            <div className=" col-sm-4">
                                                                <label>Payable name</label>
                                                                <input
                                                                    className="form-control payable_name clear-fields"
                                                                    type="text"
                                                                    name="payable_name"
                                                                    value={payableExtra.payable_name}
                                                                    onChange={(event) => handleChangePayable(event)}
                                                                />
                                                                {errors.payable_name ? (
                                                                    <div className="errorMsg text-danger">
                                                                        {errors.payable_name}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="col-sm-4" >
                                                                <label>Payable Amount</label>
                                                                <div className='d-flex'>
                                                                    <div className="left-inner-addon mr-2">
                                                                        <i className="">CAD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="CAD Amount"
                                                                            name="cad_amount"
                                                                            id="cad_amount"
                                                                            value={payableExtra.cad_amount}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "CAD");
                                                                                let data = payableExtra;
                                                                                data["cad_amount"] = response[0];
                                                                                data["usd_amount"] = response[1];
                                                                                setPayableExtra({ ...data });

                                                                            }}

                                                                        />


                                                                    </div>

                                                                    <div className="left-inner-addon">
                                                                        <i className="">USD</i>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="USD Amount"
                                                                            name="usd_amount"
                                                                            id="usd_amount"
                                                                            value={payableExtra?.usd_amount}
                                                                            onChange={(event) => {
                                                                                const response = conversion(event.target.value, "USD");
                                                                                let data = payableExtra;
                                                                                data["usd_amount"] = response[0];
                                                                                data["cad_amount"] = response[1];
                                                                                setPayableExtra({ ...data });

                                                                            }}

                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className=" col-sm-4">
                                                                <label>Payable Remarks</label><br />
                                                                <textarea
                                                                    className="form-control payable_remarks clear-fields"
                                                                    type="textarea"
                                                                    id="payable_remarks"
                                                                    name="payable_remarks"
                                                                    rows={1}
                                                                    value={payableExtra.payable_remarks}
                                                                    onChange={(event) => handleChangePayable(event)}
                                                                />

                                                                {errors.payable_remarks ? (
                                                                    <div className="errorMsg text-danger">
                                                                        {errors.payable_remarks}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: "20px" }}>

                                                            <div className="col-sm-4">
                                                                <input type='button' name="submit" className="btn btn-primary payment-submit" value="Add Payments" onClick={(e) => updatePayableDetails(e)} />

                                                                <input type='button' name="cancel" className="btn btn-danger ml-2 payment-submit" value="Cancel Payment" onClick={handelCancelPayable} />
                                                            </div>
                                                        </div>

                                                    </div>)}
                                                    <div className='table-responsive'>

                                                        {(payableData && payableData.length) > 0 && (<table id="payable_action" className="table table-striped payable-charges-table" style={{ marginTop: "20px" }}>
                                                            <thead>
                                                                <tr>

                                                                    <th>Sr No.</th>
                                                                    <th>Name</th>
                                                                    <th>Conversion CAD</th>
                                                                    <th>Conversion USD</th>
                                                                    <th>Payable Amount CAD</th>
                                                                    <th>Payable Amount USD</th>
                                                                    <th>Remarks</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {payableData.map((item, i) => {

                                                                    return [
                                                                        <tr key={i}>

                                                                            <td>{i + 1}</td>
                                                                            <td>{item.payable_name}</td>
                                                                            <td>{item.conversion_cad}</td>
                                                                            <td>{item.conversion_usd}</td>
                                                                            <td>{parseFloat(item.cad_amount).toFixed(2)}</td>
                                                                            <td>{parseFloat(item.usd_amount).toFixed(2)}</td>
                                                                            <td>{item.payable_remarks}</td>

                                                                            <td>
                                                                                <button
                                                                                    type="button"
                                                                                    className="bd-none btn btn-primary btn-xsm"
                                                                                    onClick={() => {

                                                                                        _handleEditClickPayable(item)
                                                                                    }}
                                                                                    data-toggle="tooltip" data-placement="top" title="Edit"
                                                                                >
                                                                                    <i className="fa fa-fw fa-edit"></i>
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="bd-none btn btn-danger btn-xsm"
                                                                                    onClick={() => {

                                                                                        _handleDeleteClickPayable(item.id)
                                                                                    }}
                                                                                    data-toggle="tooltip" data-placement="top" title="Delete"
                                                                                >
                                                                                    <i className="fa fa-fw fa-trash"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    ]

                                                                })}
                                                            </tbody>
                                                        </table>)}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    Receivable
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="deposited">
                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <div className='detail-view-table-head text-center'>
                                                                    <span className="view-data view-name">Total Receivable:</span>
                                                                    <br />
                                                                    <table className="table table-bordered">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th colSpan="2">
                                                                                    <label>Customer Receivable</label>
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <code> {customerReceivableUsd.toFixed(2)} <span >USD</span>
                                                                                    </code>
                                                                                </td>
                                                                                <td>
                                                                                    <code>{customerReceivableCad.toFixed(2)}  <span> CAD </span>
                                                                                    </code>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <div className='detail-view-table-head text-center'>
                                                                    <span className="view-data view-name">Total Received:</span>
                                                                    <br />
                                                                    <table className="table table-bordered">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th colSpan="2">
                                                                                    <label>Customer Received</label>
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <code>{customerReceivedUsd.toFixed(2)} <span >USD</span>
                                                                                    </code>
                                                                                </td>
                                                                                <td>
                                                                                    <code>{customerReceivedCad.toFixed(2)} <span> CAD </span>
                                                                                    </code>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <div className='detail-view-table-head text-center'>
                                                                    <span className="view-data view-name">Balance Amount</span>
                                                                    <br />
                                                                    <table className="table table-bordered">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th colSpan="2">
                                                                                    <label>Customer Balance</label>
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <code>{customerBalanceUsd.toFixed(2)}<span> USD</span>
                                                                                    </code><br />
                                                                                </td>
                                                                                <td>
                                                                                    <code>{customerBalanceCad.toFixed(2)}<span> CAD </span>
                                                                                    </code>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <div className='detail-view-table-head text-center'>
                                                                    <span className="view-data view-name">Third Party</span>
                                                                    <br />
                                                                    <table className="table table-bordered">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th colSpan="2">
                                                                                    <label>Customer Balance</label>
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <code>{thirdPartyBalanceUsd.toFixed(2)}<span> USD</span>
                                                                                    </code><br />
                                                                                </td>
                                                                                <td>
                                                                                    <code>{thirdPartBalanceCad.toFixed(2)}<span> CAD </span>
                                                                                    </code>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className=" col-sm-12 text-center">
                                                                <input type="button" className="btn btn-primary" value="Payment Details" onClick={handelReceivableClick} />
                                                            </div>
                                                        </div>




                                                        {addReceivableClicked && (<div className='receivable' style={{ marginTop: "30px", marginBottom: '10px' }}>
                                                            <div className="row">

                                                                <div className="col-sm-3">
                                                                    <label>When Recieved</label>
                                                                    <input
                                                                        className="form-control when_paid clear-fields"
                                                                        type="date"
                                                                        name="when_paid"
                                                                        value={receivableExtra.when_paid}
                                                                        onChange={(event) => handleChangeReceivable(event)}
                                                                    />
                                                                    {errors.when_paid ? (
                                                                        <div className="errorMsg text-danger">
                                                                            {errors.when_paid}
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <label>Mode Of Payment</label>
                                                                    <input
                                                                        className="form-control mode_of_payment clear-fields"
                                                                        type="text"
                                                                        name="mode_of_payment"
                                                                        value={receivableExtra.mode_of_payment}
                                                                        onChange={(event) => handleChangeReceivable(event)} />
                                                                    {errors.mode_of_payment ? (
                                                                        <div className="errorMsg text-danger">
                                                                            {errors.mode_of_payment}
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                                <div className="col-sm-3" >
                                                                    <label>Amount Recieved</label>
                                                                    <div className='d-flex'>
                                                                        <div className="left-inner-addon mr-2">
                                                                            <i className="">CAD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="CAD Amount"
                                                                                name="cad_amount"
                                                                                id="cad_amount"
                                                                                value={receivableExtra.cad_amount}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "CAD");
                                                                                    let data = receivableExtra;
                                                                                    data["cad_amount"] = response[0];
                                                                                    data["usd_amount"] = response[1];
                                                                                    setReceivableExtra({ ...data });

                                                                                }}

                                                                            />


                                                                        </div>

                                                                        <div className="left-inner-addon">
                                                                            <i className="">USD</i>
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                placeholder="USD Amount"
                                                                                name="usd_amount"
                                                                                id="usd_amount"
                                                                                value={receivableExtra?.usd_amount}
                                                                                onChange={(event) => {
                                                                                    const response = conversion(event.target.value, "USD");
                                                                                    let data = receivableExtra;
                                                                                    data["usd_amount"] = response[0];
                                                                                    data["cad_amount"] = response[1];
                                                                                    setReceivableExtra({ ...data });

                                                                                }}

                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <label>Third Party Payment</label>
                                                                    <select
                                                                        id="third_party"
                                                                        name="third_party"
                                                                        className="form-control"
                                                                        value={receivableExtra?.third_party}
                                                                        onChange={(event) => handleChangeReceivable(event)}
                                                                    >
                                                                        <option>Select</option>
                                                                        <option value="No">No</option>
                                                                        <option value="Yes">Yes</option>
                                                                    </select>

                                                                    {errors.third_party ? (
                                                                        <div className="errorMsg text-danger">
                                                                            {errors.third_party}
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}

                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: "20px" }}>
                                                                <div className=" col-sm-12">
                                                                    <label>Payment Remarks</label><br />
                                                                    <textarea
                                                                        className="form-control usd_received clear-fields"
                                                                        type="textarea"
                                                                        id="payment_remarks"
                                                                        name="payment_remarks"
                                                                        rows={2}
                                                                        value={receivableExtra.payment_remarks}
                                                                        onChange={(event) => handleChangeReceivable(event)}
                                                                    />
                                                                    {errors.payment_remarks ? (
                                                                        <div className="errorMsg text-danger">
                                                                            {errors.payment_remarks}
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: "20px" }}>

                                                                <div className="col-sm-4">
                                                                    <input type='button' name="submit" className="btn btn-primary payment-submit" value="Add Payments" onClick={(e) => updateReceivableDetails(e)} />
                                                                    <input type='button' name="cancel" className="btn btn-danger ml-2 payment-submit" value="Cancel Payment" onClick={handelCancelReceivable} />
                                                                </div>
                                                            </div>

                                                        </div>)}

                                                        <div className='table-responsive'>

                                                            {(receivableData && receivableData.length > 0) && (<table id="receivable_action" className="table table-striped payable-charges-table" style={{ marginTop: "20px" }}>
                                                                <thead>
                                                                    <tr>

                                                                        <th>Sr No.</th>
                                                                        <th>When Paid</th>
                                                                        <th>Mode of payment</th>
                                                                        <th>Conversion CAD</th>
                                                                        <th>Conversion USD</th>
                                                                        <th>Amount Recieved CAD</th>
                                                                        <th>Amount Recieved USD</th>
                                                                        <th>Third Party</th>
                                                                        <th>Payment Remarks</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {receivableData.map((item, i) => {

                                                                        return [
                                                                            <tr key={i}>

                                                                                <td>{i + 1}</td>
                                                                                <td>{moment(item.when_paid).format("YYYY-MM-DD")}</td>
                                                                                <td>{item.mode_of_payment}</td>
                                                                                <td>{item.conversion_cad}</td>
                                                                                <td>{item.conversion_usd}</td>
                                                                                <td>{item.cad_amount}</td>
                                                                                <td>{item.usd_amount}</td>
                                                                                <td>{item.third_party}</td>
                                                                                <td>{item.payment_remarks}</td>
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="bd-none btn btn-primary btn-xsm"
                                                                                        onClick={() => {
                                                                                            _handleEditClickReceivable(item)
                                                                                        }}
                                                                                        data-toggle="tooltip" data-placement="top" title="Edit"
                                                                                    >
                                                                                        <i className="fa fa-fw fa-edit"></i>
                                                                                    </button>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="bd-none btn btn-danger btn-xsm"
                                                                                        onClick={() => {
                                                                                            _handleDeleteClickReceivable(item.id)
                                                                                        }}
                                                                                        data-toggle="tooltip" data-placement="top" title="Delete"
                                                                                    >
                                                                                        <i className="fa fa-fw fa-trash"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ]

                                                                    })}



                                                                </tbody>
                                                            </table>)}
                                                        </div>

                                                    </div>
                                                </Accordion.Body>


                                            </Accordion.Item>

                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>
                                                    Bank Deposit Details
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="deposited">
                                                        <div className="row" >
                                                            <div className="col-sm-3">
                                                                <label>Deposit Date</label>
                                                                <input
                                                                    className="form-control"
                                                                    type="date"
                                                                    id="deposit_date"
                                                                    name="deposit_date"
                                                                    value={formFields?.deposit_date}
                                                                    onChange={(event) => handleChange(event)}
                                                                />

                                                            </div>
                                                            <div className=" col-sm-9">
                                                                <label>Deposit Notes:</label>
                                                                <textarea
                                                                    id="deposit_note"
                                                                    name="deposit_note"
                                                                    rows={1}
                                                                    className="form-control"
                                                                    value={formFields?.deposit_note == "null" || formFields?.deposit_note == "undefined" ? "" : formFields?.deposit_note}
                                                                    onChange={(event) => handleChange(event)}
                                                                ></textarea>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </Accordion.Body>


                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>
                                                    Job Status Details
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="job-status">
                                                        <div className="row">
                                                            <div className=" col-sm-3">
                                                                <label>Job Completed</label>
                                                                <select
                                                                    id="job_completed"
                                                                    name="job_completed"
                                                                    className="form-control job-complete-removeme"
                                                                    value={formFields?.job_completed}
                                                                    onChange={(event) => handleChange(event)}
                                                                >
                                                                    <option>--Select Status--</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>

                                                            </div>
                                                            <div className=" col-sm-3">
                                                                <label>Invoice Documentations</label>
                                                                <select
                                                                    name="invoice_documentation"
                                                                    id="invoice_documentation"
                                                                    className="form-control"
                                                                    value={formFields?.invoice_documentation}
                                                                    onChange={(event) => handleChange(event)}
                                                                >
                                                                    <option value="Ocean Freight">Ocean Freight</option>
                                                                    <option value="Trucking">Trucking</option>
                                                                    <option value="Ocean Freight+Trucking">Ocean Freight+Trucking</option>
                                                                    <option value="Documentation">Documentation</option>
                                                                    <option value="LCL">LCL</option>
                                                                </select>

                                                            </div>
                                                            <div className=" col-sm-3">
                                                                <label>Job Status</label> <code>{jobText}</code>
                                                                <Select
                                                                    name='job_status_id'
                                                                    options={options}
                                                                    onChange={handleTypeSelect}
                                                                    value={options.filter(function (option) {

                                                                        return option.value === selectedOption;
                                                                    })}
                                                                    label="Single select"
                                                                />
                                                            </div>

                                                            <div className=" col-sm-3">
                                                                <label>Need Release</label>
                                                                <select
                                                                    name="need_release"
                                                                    id="need_release"
                                                                    className="form-control"
                                                                    value={formFields?.need_release}
                                                                    onChange={(event) => handleChange(event)}
                                                                >
                                                                    <option>--Select --</option>
                                                                    <option value="SWB">SWB</option>
                                                                    <option value="Telex">Telex</option>
                                                                    <option value="OBL">OBL</option>
                                                                </select>


                                                            </div>

                                                        </div>
                                                        {props?.jobComments && (props?.jobComments).map((item, idx) => {
                                                            return (
                                                                <div key={idx} id={`id-${item.id}`} className="row align-items-center" style={{ display: props?.jobComments ? "" : "none" }} >
                                                                    <div className="col-md-10">
                                                                        <div className='comment-box'>
                                                                            {/* <div className='img-blocks d-none'>
                                                                        <img src="https://dashcode-react.codeshaper.net/assets/user-1.ad58ce72.jpg" />
                                                                    </div> */}
                                                                            <p className='p-0 m-0'>
                                                                                <span className='d-block date'> {moment(item.created_at).format("YYYY-MM-DD")}</span>
                                                                                {item.job_comment}
                                                                            </p>

                                                                        </div>

                                                                        <div className='del-button'>
                                                                            <a
                                                                                className="remove-file del-comment d-none"
                                                                                onClick={() => _handleEditClickFiles(item)}
                                                                            >
                                                                                <i className="fa fa-pencil"></i>
                                                                            </a>
                                                                            <a
                                                                                className="remove-file del-comment"
                                                                                onClick={() => _handleDeleteClickFiles(item.id)}
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                        <div className="row" >

                                                            <div className=" col-sm-12">

                                                                <AddRemoveMultipleInputField
                                                                    addJobComment={(params) => submitJobComment(params)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>


                                            </Accordion.Item>

                                        </Accordion>
                                        <div className='row'>
                                            <div className=" col-sm-4 mt-3">
                                                <input type="submit" name="submit" className="btn btn-primary" value='Save Account' />

                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </form>

            </div>
            <ModalBox
                show={showModel}
                size={'sm'}
                title="Edit Comment"
                onHide={() => {
                    setShowModel(false);
                    setCommentModel(false);
                }}
            >
                {
                    commentModel ?
                        (<div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <label>Job comment</label>
                                        <input type="text" className="form-control" placeholder="Job comment" value={commentModel.job_comment} onChange={(e) => handleCommentChange(e)} />
                                    </div>

                                    <div className="col-md-12 mt-3 text-center">
                                        <button className="btn btn-primary text-white" onClick={() => editComment()}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>) :
                        (
                            ""
                        )}
            </ModalBox>
        </>
    );
}