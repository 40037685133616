import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";

export const deleteCargoById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.delete("api/v1/tslCargos/" + id, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("cargoDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_CARGO_LIST_SUCCESS,
        payload: {
          records: getState().cargos.records.map((data) => data).filter((data) => data.id !== id),
          totalCount: getState().cargos.totalCount - 1,
          error_code: 0,
        },
      });
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_CARGO_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if(err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);  
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_CARGO_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const getCargoList = (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_CARGO_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslCargos", { params: postData, headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      return dispatch({
        type: USER_ACTION_TYPE.SET_CARGO_LIST_SUCCESS,
        payload: {
          records: response.data.data,
          totalCount: response.data.total_records,
          error_code: response.data.errorCode,
        },
      });
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_CARGO_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if(err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);  
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_CARGO_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const addCargoDetails = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslCargos", postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      const currentRecords = getState().cargos.records;
      currentRecords.push(response.data.result);
      displaySuccessMessage("cargoAdded");
      return dispatch({
        type: USER_ACTION_TYPE.SET_CARGO_LIST_SUCCESS,
        payload: {
          records: currentRecords,
          totalCount: getState().cargos.totalCount + 1,
          error_code: 0,
        },
      });
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_CARGO_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if(err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);  
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_CARGO_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const editCargoDetails = (postData, cargoId) => {
  return async (dispatch, getState) => {
    try {
      const response = await api.put("api/v1/tslCargos/" + cargoId, postData, { headers: requestTokenHeader() });
      if (response.data.errorCode === 0) {
        displaySuccessMessage("cargoUpdated");
        const currentRecords = getState().cargos.records;
        let index = currentRecords.findIndex(cargo => cargo.id === cargoId);
        currentRecords[index] = response.data.result;
        return dispatch({
          type: USER_ACTION_TYPE.SET_CARGO_LIST_SUCCESS,
          payload: {
            records: currentRecords,
            totalCount: getState().cargos.totalCount,
            error_code: 0,
          },
        });
      } else {
        displayErrorMessage(response.data.errorMessage);
        return dispatch({
          type: USER_ACTION_TYPE.SET_CARGO_LIST_ERROR,
          payload: {
            error_code: response.data.errorCode,
          },
        });
      }
    } catch (err) {
      if(err?.response?.data?.errorCode === 4) {
        displayErrorMessage(err?.response?.data?.errorMessage);  
      } else {
        displayErrorMessage(err.message);
      }
      return dispatch({
        type: USER_ACTION_TYPE.SET_CARGO_LIST_ERROR,
        payload: {
          error_code: err?.response?.data?.errorCode
        }
      });
    }
  }
};


