import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import { displaySuccessMessage } from "../../../Services/Helpers/helper";
import {
  deleteBookingById,
  getBookingList,
  addBookingDetails,
  editBookingDetails,
  uploadExcelBookings,
  uploadBookingConfirmation
} from "../../../Services/Store/Common/bookings/booking.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { BookingForm } from "./BookingForm";
import { ExcelForm } from "./ExcelForm";
import { BookingConfirmationForm } from "./BookingConfirmationForm";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { bookingSelector } from "../../../Services/Store/Common/bookings/booking.selector";
import { requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import moment from "moment";
import { TrackingForm } from "../Jobs/TrackingForm";

const Bookings = () => {
  const id = useParams()?.id;
  const currentUser = useSelector((state) => state.userClient);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [bookingList, setBookingList] = useState([]);
  const [bookingId, setBookingId] = useState(0);
  const [errorResponce, setErrorResponce] = useState(0);
  const [showModel, setShowModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [excelModel, setExcelModel] = useState(false);
  const [bookingConfirmationModel, setBookingConfirmationModel] = useState(false);
  const [modalData, setModalData] = useState({});
  const [bookingCount, setBookingCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookingResponse = useSelector(bookingSelector);
  const [searchType, setSearchType] = useState(null);
  const [bookingData, setBookingData] = useState({});
  const perPage = 25;
  const [addTrackingModel, setAddTrackingModel] = useState(false);
  const [mscToken, setMscToken] = useState("");
  const [searchIds, setSearchIds] = useState(null);

  useEffect(() => {
    if (id) {
      if (id === 'search') {
        let search = localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : '';
        if (search?.resource === 'bookings/available' && search.ids) {
          setSearchIds(search.ids);
        }
      } else {
        setSearchType('id');
        setSearchKeyword(id);
      }
    } else {
      localStorage.removeItem('search');
      setSearchIds('');
    }
  }, [id]);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));

    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('clientToken');
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponce]);

  useEffect(() => {
    setBookingCount(bookingResponse.totalCount);
    setBookingList(bookingResponse.records);
    setErrorResponce(bookingResponse.error_code);
    fetchMyAPI();
  }, [bookingResponse]);

  const fetchMyAPI = async () => {
    const response = await api.get("api/v1/tsljobData", {
      params: "",
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setBookingData(response.data.data);
    } else {
      setBookingData("");
    }
  }

  useEffect(() => {
    if(searchKeyword !== null && searchType !== null)
      dispatch(getBookingList({ searchIds, searchType: searchType, search: searchKeyword }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, searchType]);

  useEffect(() => {
    if(searchIds !== null) {
      setSearchKeyword(null);
      setSearchType(null);
      dispatch(getBookingList({ searchIds }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchIds]);

  const handlePageClick = (selectedPage) => {
    dispatch(getBookingList({ searchIds, searchType: searchType, search: searchKeyword, offset: selectedPage }));
  };

  const handleChange = (event) => {
    setSearchKeyword(event.target.value.trim());
  };

  const _handleEditClick = (bookingDetails) => {
    setModalData(bookingDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (bookingId) => {
    setBookingId(bookingId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleAddClick = () => {
    setShowModel(true);
    setAddModel(true);
  };

  const _handleExcelUpload = () => {
    setShowModel(true);
    setExcelModel(true);
  };

  const _handleBookingConfirmationUpload = () => {
    setShowModel(true);
    setBookingConfirmationModel(true);
  };

  async function fetchMSCToken(data) {
    const response = await api.get("api/v1/tslGetMSCToken", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setMscToken(response.data.data);
    } else {
      setMscToken("");
    }
  }

  const _handleTrackingClick = (jobsData) => {
    fetchMSCToken({ id: jobsData.shipping_line_id });
    setShowModel(true);
    setAddTrackingModel(true);
    setModalData(jobsData);
    setAddModel(false);
    setEditModel(false);
  };

  const callPageRecords = (bookingList) => {
    if (bookingList.length) {
      return bookingList.map((data, index) => (
        <tr key={data.id}>
          <td> {data.booking_number}</td>
          <td> {data.shipping_line_id ? data.carriers_name : ""}</td>
          <td className={!data.cargo_curtoff_date ? 'bg-warning' : ''}> {data.cargo_curtoff_date && data.cargo_curtoff_date !== '0000-00-00' ? moment(data.cargo_curtoff_date).format('MMM Do, Y') : ""}</td>
          <td> {data.container_number}</td>
          <td className={!data.origin ? 'bg-warning' : ''}> {data.origin}</td>
          <td className={!data.destination ? 'bg-warning' : ''}> {data.destination}</td>
          <td className={!data.vessal_name ? 'bg-warning' : ''}> {data.vessal_name}</td>
          <td> {data.job_status_id ? data.job_status_name : ""}</td>
          <td align="center">
            {["admin", "booking", "manager"].includes(currentUser.user.role) ?
              <button
                type="button"
                className="bd-none btn btn-warning btn-xsm"
                onClick={() => _handleEditClick(data)}
                data-toggle="tooltip" data-placement="top" title="Edit"
              >
                <i className="fa fa-fw fa-edit"></i>
              </button> : ""}
            {data.shipping_line_id == 1 || data.shipping_line_id == 6 || data.shipping_line_id == 5 ?
              <button
                type="button"
                className="bd-none btn btn-dark btn-xsm"
                onClick={() => _handleTrackingClick(data)}
                data-toggle="tooltip" data-placement="top" title="Tracking"
              >
                <i className="fa fa-ship"></i>
              </button>
              : ""}

            {["admin", "booking", "manager"].includes(currentUser.user.role) ?
              <button
                type="button"
                className="bd-none btn btn-danger btn-xsm"
                onClick={() => _handleDeleteClick(data.id)}
                data-toggle="tooltip" data-placement="top" title="Delete"
              >
                <i className="fa fa-fw fa-trash"></i>
              </button> : ""}
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={10}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteBooking = async (bookingId) => {
    dispatch(deleteBookingById(bookingId));
    setShowModel(false);
    setDeleteModel(false);
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submitBookingDetails = async (params) => {
    dispatch(addBookingDetails(params, searchIds));
    setShowModel(false);
    setAddModel(false);
  };

  const submitEditBookingDetails = async (params, id) => {
    dispatch(editBookingDetails(params, id, searchIds));
    setShowModel(false);
    setAddModel(false);
  };

  const submitExcel = async (params) => {
    dispatch(uploadExcelBookings(params, searchIds));
    displaySuccessMessage("File Uploaded, Updatation is in progress.");
    setShowModel(false);
    setExcelModel(false);
  };

  const submitBookingConfirmation = async (params) => {
    dispatch(uploadBookingConfirmation(params, searchIds));
    displaySuccessMessage("File Uploaded, Updatation is in progress.");
    setShowModel(false);
    setBookingConfirmationModel(false);
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <>
      <AdminLayout pageHeading="Bookings" pageIcon="fa fa-tasks">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Booking List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                  {["admin", "booking", "manager"].includes(currentUser.user.role) ?
                    <>
                      <button
                        type="button"
                        className="mb-2 mb-md-0 mr-2 btn btn-primary"
                        onClick={() => _handleAddClick()}
                      >
                        Add <i className="fa fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        className="mb-2 mb-md-0 mr-2 btn btn-primary"
                        onClick={() => _handleExcelUpload()}
                      >
                        Excel <i className="fa fa-upload"></i>
                      </button>
                      <button
                        type="button"
                        className="mb-2 mb-md-0 btn btn-primary"
                        onClick={() => _handleBookingConfirmationUpload()}
                      >
                        Booking Confirmation <i className="fa fa-upload"></i>
                      </button>
                    </> : ""
                  }
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="fillter-wrap d-flex justify-content-end">
                    <select name="selectType" id="" className="form-control me-1" value={searchType ?? ''} onChange={(event) => handleSearhChange(event)}>
                      <option value="">Select Type</option>
                      <option value="booking_number">Booking No.</option>
                      <option value="cr.name">Carrier</option>
                      <option value="container_number">Container No</option>
                      <option value="vessal_name">Vessal Name</option>
                      <option value="cargo_curtoff_date">Cargo Cut off date</option>
                      <option value="origin">Origin</option>
                      <option value="destination">Destination</option>
                    </select>
                    <input type="search" className="form-control" placeholder="Search" value={searchKeyword ?? ''} onChange={handleChange} aria-controls="dataTable" />
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Booking No.</th>
                          <th>Carrier</th>
                          <th>Cargo Cutoff Date</th>
                          <th>Container No</th>
                          <th>Origin</th>
                          <th>Destination</th>
                          <th>Vessal Name</th>
                          <th>Job Status</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {callPageRecords(bookingList)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {
                    <PaginationBlock
                      perPage={perPage}
                      userRecordsData={bookingCount}
                      callHandlePageClick={(data) =>
                        handlePageClick(data)
                      }
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <ModalBox
            show={showModel}
            title={deleteModel ? "Delete Booking" : (editModel ? "Booking Form" : (excelModel ? 'Upload Excel' : (bookingConfirmationModel ? 'Booking Confirmation PDF' : (addTrackingModel ? 'Tracking' : "Booking Form"))))}
            size={deleteModel || excelModel || bookingConfirmationModel ? 'md' : 'xl'}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddModel(false);
              setExcelModel(false);
              setBookingConfirmationModel(false);
              setAddTrackingModel(false);
            }}
          >
            {editModel ? (
              <BookingForm
                editBookingDetails={(params, id) => submitEditBookingDetails(params, id)}
                bookingData={bookingData}
                bookingDetails={modalData}
              />
            ) : addModel ? (
              <BookingForm
                addBookingDetails={(params) => submitBookingDetails(params)}
                bookingData={bookingData}
              />
            ) : deleteModel ? (
              <DeleteForm
                userId={bookingId}
                deleteUserId={(bookingId) => deleteBooking(bookingId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : excelModel ? (
              <ExcelForm
                submitExcel={(params) => submitExcel(params)}
              />
            ) : bookingConfirmationModel ? (
              <BookingConfirmationForm
                shippingLines={bookingData[0]}
                submitForm={(params) => submitBookingConfirmation(params)}
              />
            ) :
              addTrackingModel === true ?
                (
                  <TrackingForm
                    jobsDetails={modalData}
                    documentationDataById={[]}
                    mscToken={mscToken}
                  />
                )

                : ("")}
          </ModalBox>
        </div>
      </AdminLayout >
    </>
  );
};
export default Bookings;
