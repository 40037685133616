import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./sb-admin-2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "sendbird-uikit/dist/index.css";
import PageNotFound from "./pages/PageNotFound";
import AdminLogin from "./pages/Admin";
import CustomerLogin from "./pages/Customer/Login";
import User from "./pages/Common/User";
import Customer from "./pages/Common/Customer";
import Contact from "./pages/Common/Contact";
import CustomerContact from "./pages/Common/CustomerRole/Contact";
import Service from "./pages/Common/Service";
import Carrier from "./pages/Common/Carrier";
import Rates from "./pages/Common/Rates";
import CustomerHistory from "./pages/Common/CustomerHistory/Customers";
import Jobs from "./pages/Common/Jobs";
import Cargo from "./pages/Common/Cargo";
import ExportDocumentCoordinator from "./pages/Common/ExportDocumentCoordinator";
import ImportDocumentCoordinator from "./pages/Common/ImportDocumentCoordinator";
import TruckerName from "./pages/Common/TruckerName";
import ContainerListing from "./pages/Common/Container";
import { store } from "./Services/Store/store";
import { Provider } from "react-redux";
import Unauthorized from "./pages/Unauthorized";
import Permissions from "./Services/Auth/Permissions";
import CustomerPermissions from "./Services/Auth/CustomerPermissions";
import Dashboard from "./pages/Dashboards/Dashboard";
import CustomerDashboard from "./pages/Common/CustomerRole/jobs";
import Profile from "./pages/Profile";
import QuoteRequest from "./pages/Common/CustomerRole/Quote/QuoteRequest";
import Quotes from "./pages/Common/Quotes/Index";
import CustomerList from "./pages/Common/CustomerRole/Customer";
import ShippingLineRates from "./pages/Common/ShippingLineRates";
import CustomerRateHistory from "./pages/Common/CustomerRateHistory";
import AvailableBookings from "./pages/Common/AvailableBookings";
import Notification from "./pages/Common/Notification";
import Calendar from "./pages/Common/Calendar";
import Tasks from "./pages/Common/Tasks";
import Meetings from "./pages/Common/Meetings";
import Destination from "./pages/Common/Destination";
import Origin from "./pages/Common/Origin";
import CustomerReporting from "./pages/Common/CustomerReporting";
import CustomerReportingReleased from "./pages/Common/CustomerReporting/CustomerReportingReleased";
import CustomerReportingReleasedDates from "./pages/Common/CustomerReporting/CustomerReportingReleasedDates";
import ShippingLineReporting from "./pages/Common/CustomerReporting/ShippingLineReporting";
import TruckerReporting from "./pages/Common/CustomerReporting/TruckerReporting";
import PaymentsReporting from "./pages/Common/CustomerReporting/PaymentsReporting";
import AccountReporting from "./pages/Common/CustomerReporting/AccountReporting";
import ClosedJobs from "./pages/Common/CustomerReporting/ClosedJobs";
import TruckerBalanceReporting from "./pages/Common/CustomerReporting/TruckerBalanceReporting";
import SteamshipLineBalance from "./pages/Common/CustomerReporting/SteamshipLineBalance";
import CustomerBalanceReporting from "./pages/Common/CustomerReporting/CustomerBalanceReporting";
import MainReport from "./pages/Common/CustomerReporting/MainReport";
import ReceivedAmountReport from "./pages/Common/CustomerReporting/ReceivedAmountReport";
import AllSaleReporting from "./pages/Common/CustomerReporting/AllSaleReporting";
import PrintBalanceSheet from "./pages/Common/CustomerReporting/PrintBalanceSheet";
import StatusReporting from "./pages/Common/CustomerReporting/StatusReporting";
import ExcelReport from "./pages/Common/CustomerReporting/ExcelReport";
import ContainerReport from "./pages/Common/CustomerReporting/ContainerReport";
import ThirdpartyCharges from "./pages/Common/CustomerReporting/ThirdpartyCharges";
import Reporting from "./pages/Common/CustomerReporting/reporting";
import Chat from  "./pages/Common/Chat";
import EmployeeReport from "./pages/Common/CustomerReporting/EmployeeReport";
import CustomerVolumeReport from "./pages/Common/CustomerReporting/CustomerVolumeReport";
import HistoryForSales from "./pages/Common/CustomerReporting/HistoryForSales";
import QuotationPerMonth from "./pages/Common/CustomerReporting/QuotationPerMonth";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AdminLogin />} />
            <Route exact={true} path="/customer/login" element={<CustomerLogin />} />
            <Route exact={true} path="/login" element={<AdminLogin />} />

            <Route element={<Permissions allowedRoles={["superadmin", "admin", "manager", "booking", "documentation", "accounts", "rates", "sales"]} />}>
              <Route exact={true} path="/dashboard" element={<Dashboard />} />
            </Route>

            <Route element={<Permissions allowedRoles={["admin", "booking", "documentation", "accounts", "rates", "sales", "manager"]} />}>
              <Route exact={true} path="/profile" element={<Profile />} />
            </Route>

            <Route element={<CustomerPermissions allowedRoles={['customer']} />}>
              <Route exact={true} path="/customer/dashboard/:id?" element={<CustomerDashboard />} />
            </Route>

            <Route element={<Permissions allowedRoles={["admin", "accounts", "documentation", "booking", "rates", "sales"]} />}>
              <Route exact={true} path="/users/:id?" element={<User />} />
            </Route>
            <Route element={<Permissions allowedRoles={["admin", "booking", "documentation", "manager"]} />}>
              <Route exact={true} path="/customer/:id?" element={<Customer />} />
            </Route>
            <Route exact={true} path="/contact" element={<Contact />} />
            <Route exact={true} path="/customer/contact" element={<CustomerContact />} />

            <Route element={<Permissions allowedRoles={["admin"]} />}>
              <Route exact={true} path="/service/:id?" element={<Service />} />
            </Route>
            <Route element={<Permissions allowedRoles={["admin"]} />}>
              <Route exact={true} path="/carrier/:id?" element={<Carrier />} />
            </Route>
            <Route element={<Permissions allowedRoles={["admin", "accounts", "manager"]} />}>
              <Route exact={true} path="/rates" element={<Rates />} />
            </Route>

            <Route element={<Permissions allowedRoles={["admin"]} />}>
              <Route exact={true} path="/container-listing" element={<ContainerListing />} />
            </Route>
            <Route element={<Permissions allowedRoles={["sales"]} />}>
              <Route exact={true} path="/customerHistory/:id?" element={<CustomerHistory />} />
            </Route>
            <Route element={<Permissions allowedRoles={["admin", "manager", "accounts", "documentation", "sales", "rates", "booking"]} />}>
              <Route exact={true} path="/jobs/:id?" element={<Jobs />} />
            </Route>
            <Route element={<Permissions allowedRoles={["admin", "booking", "manager"]} />}>
              <Route exact={true} path="/bookings/available/:id?" element={<AvailableBookings />} />
            </Route>
            <Route element={<Permissions allowedRoles={["admin"]} />}>
              <Route exact={true} path="/cargo" element={<Cargo />} />
            </Route>
            <Route element={<Permissions allowedRoles={["admin"]} />}>
              <Route exact={true} path="/trucker-name/:id?" element={<TruckerName />} />
            </Route>
            <Route element={<Permissions allowedRoles={["admin", "accounts", "documentation", "booking", "sales", "rates", "manager"]} />}>
              <Route exact={true} path="/export-document-coordinator/:id?" element={<ExportDocumentCoordinator />} />
            </Route>
            <Route element={<Permissions allowedRoles={["admin", "accounts", "documentation", "booking", "sales", "rates", "manager"]} />}>
              <Route exact={true} path="/import-document-coordinator/:id?" element={<ImportDocumentCoordinator />} />
            </Route>
            <Route element={<Permissions allowedRoles={["rates", "admin", "manager"]} />}>
              <Route exact={true} path="/shipping-line-rates" element={<ShippingLineRates />} />
            </Route>
            <Route element={<Permissions allowedRoles={["rates", "admin", "manager"]} />}>
              <Route exact={true} path="/customer-rate-history" element={<CustomerRateHistory />} />
            </Route>
            <Route exact={true} path="/notifications" element={<Notification />} />

            <Route path="*" element={<PageNotFound />} />
            <Route exact={true} path="/unauthorized" element={<Unauthorized />} />

            <Route element={<Permissions allowedRoles={["admin", "sales", "booking", "rates", "manager"]} />}>
              <Route exact={true} path="/quotes/:id?" element={<Quotes />} />
            </Route>

            <Route element={<CustomerPermissions allowedRoles={["customer"]} />}>
              <Route exact={true} path="/customer/quotes/:id?" element={<QuoteRequest />} />
            </Route>

            <Route element={<CustomerPermissions allowedRoles={["customer"]} />}>
              <Route exact={true} path="/customer/customer-list/:id?" element={<CustomerList />} />
            </Route>

            <Route exact={true} path="/calendar" element={<Calendar />} />

            <Route element={<Tasks allowedRoles={["admin"]} />}>
              <Route exact={true} path="/tasks" element={<Tasks />} />
            </Route>
            <Route element={<Meetings allowedRoles={["admin"]} />}>
              <Route exact={true} path="/meetings" element={<Meetings />} />
            </Route>

            <Route element={<Origin allowedRoles={["admin", "booking", "manager"]} />}>
              <Route exact={true} path="/loading_port/:id?" element={<Origin />} />
            </Route>

            <Route element={<Destination allowedRoles={["admin", "booking", "manager"]} />}>
              <Route exact={true} path="/destination_port/:id?" element={<Destination />} />
            </Route>

            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/customer-reporting" element={<CustomerReporting />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/customer-reporting-released" element={<CustomerReportingReleased />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/customer-reporting-released-dates" element={<CustomerReportingReleasedDates />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/shipping-line-reporting" element={<ShippingLineReporting />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/trucker-reporting" element={<TruckerReporting />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/payments-reporting" element={<PaymentsReporting />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/account-reporting" element={<AccountReporting />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/job-closed-reporting" element={<ClosedJobs />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/trucker-balance-reporting" element={<TruckerBalanceReporting />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/steamship-balance-reporting" element={<SteamshipLineBalance />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/customer-balance-reporting" element={<CustomerBalanceReporting />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/main-report" element={<MainReport />} />
            </Route>

            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/received-amout-report" element={<ReceivedAmountReport />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/all-sale-reporting" element={<AllSaleReporting />} />
            </Route>

            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/all-reporting" element={<PrintBalanceSheet />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/status-reporting" element={<StatusReporting />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/excel-report" element={<ExcelReport />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/container-report" element={<ContainerReport />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/thirdparty-charges-report" element={<ThirdpartyCharges />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager", "admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/reporting" element={<Reporting />} />
            </Route>
            <Route element={<Permissions allowedRoles={["admin", "superadmin", "manager"]} />}>
              <Route exact={true} path="/employee-report" element={<EmployeeReport />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager","admin", "sales", "booking", "rates",  "accounts", "documentation",]} />}>
              <Route exact={true} path="/chat" element={<Chat />} />
            </Route>

            <Route element={<Permissions allowedRoles={["manager","admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/customer-volume-report" element={<CustomerVolumeReport />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager","admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/history-for-sales-report" element={<HistoryForSales />} />
            </Route>
            <Route element={<Permissions allowedRoles={["manager","admin", "sales", "booking", "rates",  "accounts", "documentation"]} />}>
              <Route exact={true} path="/Quotation-per-month-report" element={<QuotationPerMonth />} />
            </Route>
          </Routes>
        </BrowserRouter>

     
      </Provider>
    </>
  );
};
export default App;
