import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import moment from "moment";
import {
  deleteNotificationById,
  getNotificationList,
  markNotificationRead,
} from "../../../Services/Store/Common/notification/notification.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { notificationSelector } from "../../../Services/Store/Common/notification/notification.selector";

const Notification = () => {
  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const perPage = 25;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notificationData = useSelector(notificationSelector);
  const currentUser = useSelector((state) => state.userClient);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken") || sessionStorage.getItem("customerToken"));

    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('clientToken');
      navigate("/");
    }
  }, [errorResponce]);

  useEffect(() => {
    if (currentUser.user.role === 'customer') {
      dispatch(getNotificationList({ customer_id: currentUser.user.id }));
    } else {
      dispatch(getNotificationList({ user_id: currentUser.user.id }));
    }
  }, []);

  useEffect(() => {
    setNotificationList(notificationData.records);
    setNotificationCount(notificationData.totalCount);
    setErrorResponce(notificationData.error_code);
  }, [notificationData]);

  const handlePageClick = (selectedPage) => {
    dispatch(
      getNotificationList({ offset: selectedPage })
    );
  };

  const _handleDeleteClick = (notificationId) => {
    setDeleteIds([notificationId]);
    setShowModel(true);
    setDeleteModel(true);
  };

  const handleDeleteSelection = (notificationId) => {
    if (deleteIds.includes(notificationId)) {
      setDeleteIds(deleteIds.filter((id) => id !== notificationId));
    } else {
      setDeleteIds([...deleteIds, notificationId]);
    }
  };

  const callPageRecords = (notificationList) => {
    if (notificationList.length) {
      return notificationList.map((data, index) => (
        <tr key={data.id}>
          <td>
            <input
              type="checkbox"
              value={data.id}
              name={data.id}
              checked={deleteIds.includes(data.id)}
              onChange={() => handleDeleteSelection(data.id)}
            />
          </td>
          <td>{index + 1}</td>
          <td className="text-capitalize">{data.resource}</td>
          <td><Link className="text-decoration-none" to={'/' + data.resource + '/' + data.resource_id}>{data.content}</Link></td>
          <td>{moment(data.created_at).format('MMM Do, Y H:mm:s')}</td>
          <td>
            {
              data.is_read === '0' ?
                <button
                  type="button"
                  className="btn btn-primary btn-xsm"
                  title={data.is_read === '1' ? 'Mark Unread' : 'Mark Read'}
                  onClick={() => updateReadStatus({ is_read: data.is_read === '1' ? '0' : '1' }, data.id)}
                >
                  {data.is_read === '1' ? 'Mark Unread' : 'Mark Read'}
                </button> : ''
            }
          </td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id, data.is_read)}
              data-toggle="tooltip" data-placement="top" title="Delete"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr key="0" className="text-center">
          <td colSpan={7}>No Record Found</td>
        </tr>
      );
    }
  };

  const handleMultipleDelete = () => {
    setShowModel(true);
    setDeleteModel(true);
  }

  const handleSelectAll = (e) => {
    if (e.target.checked)
      setDeleteIds(notificationList.map(n => {
        return n.id;
      }));
    else
      setDeleteIds([]);
  }

  const deleteNotification = async (deleteIds) => {
    dispatch(deleteNotificationById(deleteIds, currentUser.user));
    setDeleteModel(false);
    setShowModel(false);
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const updateReadStatus = async (params, notificationId) => {
    dispatch(markNotificationRead(params, notificationId));
  };

  return (
    <>
      <AdminLayout pageHeading="Notification" pageIcon="fa fa-bell">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Notification List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                  {
                    deleteIds.length ?
                      <button
                        type="button"
                        className="mb-2 mb-md-0 btn btn-primary"
                        onClick={() => handleMultipleDelete()}
                      >
                        Delete All
                      </button> : ''
                  }
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>
                            <input type="checkbox"
                              checked={deleteIds.length > 0 && (deleteIds.length === notificationList.length)}
                              onChange={(e) => handleSelectAll(e)}
                            />
                          </th>
                          <th>S.No</th>
                          <th>Type</th>
                          <th>Content</th>
                          <th>Created</th>
                          <th>Status</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          callPageRecords(
                            notificationList
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="dataTable_paginate"
                  >
                    {
                      notificationCount > 0 ? (
                        <PaginationBlock
                          perPage={perPage}
                          userRecordsData={notificationCount}
                          callHandlePageClick={(data) =>
                            handlePageClick(data)
                          }
                        />
                      ) : (
                        ""
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalBox
            size='md'
            show={showModel}
            title="Delete Notification"
            onHide={() => {
              setShowModel(false);
              setDeleteModel(false);
            }}
          >
            {deleteModel === true ? (
              <DeleteForm
                userId={deleteIds}
                deleteUserId={(deleteIds) => deleteNotification(deleteIds)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) :
              (
                ""
              )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default Notification;
