import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader, getUserIds } from "../../../Helpers/helper";
import api from "../../../Axios/index";
import { addNotificationDetails } from "../notification/notification.action";

export const deleteQuotesById = (id, quoteData = {}, customer = '', searchIds = '') => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteQuotes", { id },
      { headers: requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      let notification = {
        resource: 'quotes',
        resource_id: quoteData.id,
        content: 'Quote #' + quoteData.quote_number + ' Deleted by Customer'
      };
      if (customer.role === 'customer') {
        if (customer.created_by > 0) {
          dispatch(addNotificationDetails({ ...notification, user_id: customer.created_by }));
        } else {
          getUserIds({ 'roles.name': 'sales' }, function (salesUserIds) {
            salesUserIds.length && salesUserIds.map((user_rec, index) => {
              dispatch(addNotificationDetails({ ...notification, user_id: user_rec.id }));
            });
          });
        }
        getUserIds({ 'roles.name': 'rates' }, function (ratesUserIds) {
          ratesUserIds.length && ratesUserIds.map((user_rec, index) => {
            dispatch(addNotificationDetails({ ...notification, user_id: user_rec.id }));
          });
        });
        return dispatch(getquotesList({ id: quoteData.customer_id }));
      } else {
        return dispatch(getquotesList({ searchIds }));
      }
    } else {
      displayErrorMessage("quotesDeleteError");
      const responce = {
        result: [],
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_QUOTES_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_QUOTES_LIST_ERROR });
  }
};

export const getquotesList = (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_QUOTES_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslQuotes", {
      params: postData,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_QUOTES_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: [],
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_QUOTES_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    return dispatch({ type: USER_ACTION_TYPE.SET_QUOTES_LIST_ERROR });
  }
};

export const addQuotesDetails = (postData, customer = {}, searchIds = '') => async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslAddQuotes", postData,
      { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("quotesAdded");
      if (customer.role === 'customer') {
        let notification = {
          resource: 'quotes',
          resource_id: response.data.result.id,
          content: 'New Quote #' + response.data.result.quote_number + ' Requested by Customer'
        };
        if (customer.created_by > 0) {
          dispatch(addNotificationDetails({ ...notification, user_id: customer.created_by }));
        } else {
          getUserIds({ 'roles.name': 'sales' }, function (salesUserIds) {
            salesUserIds.length && salesUserIds.map((user_rec, index) => {
              dispatch(addNotificationDetails({ ...notification, user_id: user_rec.id }));
            });
          });
        }
        getUserIds({ 'roles.name': 'rates' }, function (ratesUserIds) {
          ratesUserIds.length && ratesUserIds.map((user_rec, index) => {
            dispatch(addNotificationDetails({ ...notification, user_id: user_rec.id }));
          });
        });
        return dispatch(getquotesList({ id: postData.customer_id }));
      } else {
        dispatch(addNotificationDetails({
          customer_id: response.data.result.customer_id,
          resource: 'customer/quotes',
          resource_id: response.data.result.id,
          content: 'New Quote #' + response.data.result.quote_number + ' Created'
        }));
        getUserIds({ 'roles.name': 'rates' }, function (ratesUserIds) {
          ratesUserIds.length && ratesUserIds.map((user_rec, index) => {
            dispatch(addNotificationDetails({
              user_id: user_rec.id,
              resource: 'quotes',
              resource_id: response.data.result.id,
              content: 'New Quote #' + response.data.result.quote_number + ' Created'
            }));
          });
        });
        return dispatch(getquotesList({ searchIds }));
      }
    } else {
      displayErrorMessage("quotesAddedError");
      const responce = {
        result: [],
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_QUOTES_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_QUOTES_LIST_ERROR });
  }
};

export const editQuotesDetails = (postData, quoteData = {}, user = '', searchIds = '') => {
  return async (dispatch) => {
    try {
      const response = await api.post("api/v1/tslEditQuotes", postData,
        {
          headers: requestTokenHeader()
        });
      if (response.data.errorCode === 0) {
        if (user.role === 'sales') {
          let notification = {
            resource: 'quotes',
            resource_id: quoteData.id,
            content: ''
          };
          if (postData.bl_charges !== undefined && (postData.bl_charges !== quoteData.bl_charges || postData.freight !== quoteData.freight || postData.telux !== quoteData.telux)) {
            dispatch(addNotificationDetails({
              customer_id: quoteData.customer_id,
              resource: 'customer/quotes',
              resource_id: quoteData.id,
              content: 'Cost updated for Quote #' + quoteData.quote_number
            }));
            notification['content'] = 'Cost updated for Quote #' + quoteData.quote_number + ' by ' + user.firstName + (user.lastName ? ' ' + user.lastName : '');
            displaySuccessMessage("Quote Cost Updated");
          } else if (postData.priority !== quoteData.currentPriority) {
            notification['content'] = 'Priority Status Updated for Quote #' + quoteData.quote_number + ' by ' + user.firstName + (user.lastName ? ' ' + user.lastName : '');
            displaySuccessMessage("quotesUpdated");
          }
          if (notification['content'].length)
            getUserIds({ 'roles.name': 'rates' }, function (ratesUserIds) {
              ratesUserIds.length && ratesUserIds.map((user_rec, index) => {
                dispatch(addNotificationDetails({ ...notification, 'user_id': user_rec.id }));
              });
            });
          dispatch(getquotesList({ searchIds }));
        } else if ((user.role === 'rates') && postData.bl_charges !== undefined && (postData.bl_charges !== quoteData.bl_charges || postData.freight !== quoteData.freight || postData.telux !== quoteData.telux)) {
          dispatch(addNotificationDetails({
            customer_id: quoteData.customer_id,
            resource: 'customer/quotes',
            resource_id: quoteData.id,
            content: 'Cost updated for Quote #' + quoteData.quote_number
          }));
          getUserIds({ 'roles.name': 'sales' }, function (salesUserIds) {
            salesUserIds.length && salesUserIds.map((user_rec, index) => {
              dispatch(addNotificationDetails({
                resource: 'quotes',
                resource_id: quoteData.id,
                content: 'Cost updated for Quote #' + quoteData.quote_number + ' by ' + user.firstName + (user.lastName ? ' ' + user.lastName : ''),
                user_id: user_rec.id
              }));
            });
          });
          displaySuccessMessage("Quote Cost Updated");
          dispatch(getquotesList({ searchIds }));
        } else if (user.role === 'customer') {
          if (postData.booking_request === 'Yes') {
            getUserIds({ 'roles.name': 'booking' }, function (bookingUserIds) {
              bookingUserIds.length && bookingUserIds.map((user_rec, index) => {
                dispatch(addNotificationDetails({
                  user_id: user_rec.id,
                  resource: 'quotes',
                  resource_id: postData.id,
                  content: 'New Booking Request Quote #' + quoteData.quote_number
                }));
              });
            });
          } else {
            displaySuccessMessage("quotesUpdated");
          }
          dispatch(getquotesList({ id: postData.customer_id }));
        } else {
          displaySuccessMessage("quotesUpdated");
          dispatch(getquotesList({ searchIds }));
        }
      } else {
        const responce = {
          result: [],
          records: 0,
          error_code: response.data.errorCode,
        };
        displayErrorMessage("quotesUpdatedError");
        dispatch({
          type: USER_ACTION_TYPE.SET_QUOTES_LIST_SUCESS,
          payload: responce,
        });
      }
    } catch (err) {
      displayErrorMessage(err.name);
      dispatch({ type: USER_ACTION_TYPE.SET_QUOTES_LIST_ERROR });
    }
  }
};