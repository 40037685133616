import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
export function ContactForm(props) {
    const [fields, setFields] = useState(props.userDetails ? props.userDetails : {});
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState(false);
    const [applyCheck] = useState(false);

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const validateContactFrom = Yup.object().shape({
        contact_person: Yup.string().required('Contact Person is required').matches(/^[A-Za-z ]*$/, 'Please enter valid Contact Person')
            .max(150),
        email: Yup.string().email('Invalid email').nullable(true),
        mobile: Yup.string().matches(phoneRegExp, 'Mobile number is not valid').nullable(true),
        telephone: Yup.string().matches(phoneRegExp, 'Telephone number is not valid').nullable(true),
        fax: Yup.string().matches(phoneRegExp, 'fax number is not valid').nullable(true),
        address: Yup.string().required('Address is required'),
        city: Yup.string().nullable(true),
        postal_code: Yup.string().nullable(true),
        contact_type: Yup.string().required('Field is required'),
    });
    let initialValues = {};
    if (fields) {
        initialValues = {
            contact_person: fields.contact_person,
            email: fields.email,
            mobile: fields.mobile,
            telephone: fields.telephone,
            fax: fields.fax,
            address: fields.address,
            city: fields.city,
            postal_code: fields.postal_code,
            contact_type: fields.contact_type
        }

    } else {
        initialValues = {
            contact_person: '',
            email: '',
            mobile: '',
            telephone: '',
            fax: '',
            address: '',
            city: '',
            postal_code: '',
            contact_type: ""
        }
    }


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            if (fields && !(Object.keys(fields).length === 0 && fields.constructor === Object)) {

                const postData = {
                    contact_person: values.contact_person,
                    mobile: values.mobile,
                    telephone: values.telephone,
                    email: values.email,
                    fax: values.fax,
                    customer_id: props.customerId,
                    id: fields.id,
                    address: values.address,
                    city: values.city,
                    postal_code: values.postal_code,
                    contact_type: values.contact_type
                }
                props.editUserDetails(postData);

            } else {

                const params = {
                    password: values.password,
                    contact_person: values.contact_person,
                    mobile: values.mobile,
                    fax: values.fax,
                    telephone: values.telephone,
                    email: values.email,
                    userId: props.userId,
                    customer_id: props.customerId,
                    address: values.address,
                    city: values.city,
                    postal_code: values.postal_code,
                    contact_type: values.contact_type
                }
                props.addUserDetails(params)
            }


        },
    });



    return (
        <>
            <div className="modal-body invitation-wrap">

                <div className="login-wrap-new">

                    <div className="form-content">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">

                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Contact Person"
                                        id="contact_person"
                                        name="contact_person"
                                        {...formik.getFieldProps('contact_person')}
                                    />
                                    {formik.touched.contact_person && formik.errors.contact_person && (
                                        <div className="errorMsg text-danger">{formik.errors.contact_person}</div>
                                    )}

                                </div>
                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Mobile"
                                        name="mobile"
                                        {...formik.getFieldProps('mobile')}
                                    />
                                    {formik.touched.mobile && formik.errors.mobile && (
                                        <div className="errorMsg text-danger">{formik.errors.mobile}</div>
                                    )}

                                </div>

                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Telephone"
                                        name="telephone"
                                        {...formik.getFieldProps('telephone')}
                                    />
                                    {formik.touched.telephone && formik.errors.telephone && (
                                        <div className="errorMsg text-danger">{formik.errors.telephone}</div>
                                    )}

                                </div>

                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        {...formik.getFieldProps('email')}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className="errorMsg text-danger">{formik.errors.email}</div>
                                    )}

                                </div>

                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="FAX"
                                        name="fax"
                                        {...formik.getFieldProps('fax')}
                                    />
                                    {formik.touched.fax && formik.errors.fax && (
                                        <div className="errorMsg text-danger">{formik.errors.fax}</div>
                                    )}

                                </div>


                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="City"
                                        name="city"
                                        {...formik.getFieldProps('city')}
                                    />
                                    {formik.touched.city && formik.errors.city && (
                                        <div className="errorMsg text-danger">{formik.errors.city}</div>
                                    )}

                                </div>

                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Postal Code"
                                        name="postal_code"
                                        {...formik.getFieldProps('postal_code')}
                                    />
                                    {formik.touched.postal_code && formik.errors.postal_code && (
                                        <div className="errorMsg text-danger">{formik.errors.postal_code}</div>
                                    )}

                                </div>

                                <div className="col-md-6 col-xs-6">
                                    <select className="form-control"
                                        name="contact_type"
                                        id="contact_type"
                                        {...formik.getFieldProps('contact_type')}>
                                        <option>--Select--</option>
                                        <option value="shipper">Shipper</option>
                                        <option value="consignee">Consignee</option>
                                        <option value="customer">Customer</option>
                                    </select>

                                    {formik.touched.contact_type && formik.errors.contact_type && (
                                        <div className="errorMsg text-danger">{formik.errors.contact_type}</div>
                                    )}

                                </div>
                                <div className="col-md-12">

                                    <textarea
                                        placeholder="Address"
                                        className="form-control"
                                        name="address"
                                        id="address"
                                        {...formik.getFieldProps('address')}
                                    ></textarea>



                                    {formik.touched.address && formik.errors.address && (
                                        <div className="errorMsg text-danger">{formik.errors.address}</div>
                                    )}

                                </div>

                            </div>
                            <button type="submit" className="btn btn-primary">
                                {fields.id ? 'Save Contact' : 'Save Contact'}
                            </button>
                        </form>
                    </div>

                </div>
            </div>

        </>
    );
}