import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ModalBox } from "../../../../Components/Ui/ModalBox";
import {
  getjobsList,
  addDocumentationDetails,
  editDocumentationDetails,
} from "../../../../Services/Store/Common/jobs/jobs.action";
import PaginationBlock from "../../../../Components/Ui/PaginationBlock";
import { ViewForm } from "./ViewForm";
import { DocumentationForm } from "./DocumentationForm";
import { TrackingForm } from "./TrackingForm";
import AdminLayout from "../../../../Layout";
import { jobsList } from "../../../../Services/Store/Common/jobs/jobs.selector";
import moment from "moment";
import { displaySuccessMessage, requestTokenHeader, getUserIds } from "../../../../Services/Helpers/helper";
import api from "../../../../Services/Axios/index";

const Jobs = () => {
  const id = useParams()?.id;
  const currentUser = useSelector((state) => state.user);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [addDocumentationModel, setAddDocumentationModel] = useState(false);
  const [editDocumentationModel, setEditDocumentationModel] = useState(false);
  const [viewModel, setViewModel] = useState(false);
  const perPage = 25;
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState({});
  const jobsListResult = useSelector(jobsList);
  const [searchType, setSearchType] = useState("");
  const [documentationData, setDocumentationData] = useState('');
  const [documentationDataById, setDocumentationDataById] = useState({});
  const [jobComments, setJobComments] = useState([]);
  const [jobVin, setJobVin] = useState([]);
  const [shipperConsignee, setShipperConsignee] = useState([]);
  const [mscToken, setMscToken] = useState("");
  const [addTrackingModel, setAddTrackingModel] = useState(false);

  useEffect(() => {
    if (id > 0) {
      setSearchType('id');
      setSearchKeyword(id);
    }
  }, []);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("customerToken"));
    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('customerToken');
      navigate("/");
    }
  }, [errorResponce]);

  useEffect(() => {
    if (jobsListResult.result !== undefined) {
      const result = jobsListResult.result;
      if (result.length > 0) {
        setUserRecordsData(jobsListResult.records);
        setErrorResponce(jobsListResult.error_code);
      } else {
        setUserRecordsData("");
      }
    }
  }, [jobsListResult]);

  useEffect(() => {
    async function fetchMyAPI() {
      const response = await api.get("api/v1/tsljobData", {
        params: "",
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setJobsData(response.data.data);
      } else {
        setJobsData("");
      }
    }
    fetchMyAPI();
  }, []);

  useEffect(() => {
    async function fetchMyAPIDocumentation() {
      const response = await api.get("api/v1/tsldocumentationData", {
        params: "",
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setDocumentationData(response.data.data);
      } else {
        setDocumentationData("");
      }
    }
    fetchMyAPIDocumentation();
  }, []);

  useEffect(() => {
    async function fetchMyShipperConsignee(customerID) {
      const response = await api.get("api/v1/tslFetchMyShipperConsignee", {
        params: { id: customerID },
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setShipperConsignee(response.data.data);
      } else {
        setShipperConsignee("");
      }
    }
    fetchMyShipperConsignee(currentUser.user.id);
  }, []);

  async function fetchDocumentationData(data) {
    setDocumentationDataById({});
    const response = await api.get("api/v1/tsldocumentationExist", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setDocumentationDataById(response.data.data);
      setEditDocumentationModel(true);
      setAddDocumentationModel(false);
    } else {
      setDocumentationDataById({});
      setEditDocumentationModel(false);
      setAddDocumentationModel(true);
    }
  }

  async function fetchMSCToken(data) {
    setDocumentationDataById({});
    const response = await api.get("api/v1/tslGetMSCToken", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setMscToken(response.data.data);
    } else {
      setMscToken("");
    }
  }

  async function fetchDocumentationData2(data) {
    setDocumentationDataById({});
    const response = await api.get("api/v1/tsldocumentationExist", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setDocumentationDataById(response.data.data);
    } else {
      setDocumentationDataById({});
    }
  }

  async function fetchJobComments(data) {
    setJobComments([]);
    const response = await api.get("api/v1/tsljobComments", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setJobComments(response.data.data);
    } else {
      setJobComments([]);
    }
  }

  async function fetchVin(data) {
    setJobComments([]);
    const response = await api.get("api/v1/tsljobVin", {
      params: data,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      setJobVin(response.data.data);

    } else {
      setJobVin([]);
    }
  }

  useEffect(() => {
    dispatch(getjobsList({ customerId: currentUser.user.id, searchType: searchType, search: searchKeyword }));
  }, [searchKeyword]);

  const handlePageClick = (selectedPage) => {
    dispatch(getjobsList({ customerId: currentUser.user.id, searchType: searchType, search: searchKeyword, offset: selectedPage }));
  };

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleViewClick = (jobsData) => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    setModalData(jobsData);
    if (userId) {
      setUserId(userId);
      setShowModel(true);
      setViewModel(true);
      setEditDocumentationModel(false);
      setAddDocumentationModel(false);
    }
  };

  const _handleDocumentationClick = (jobsData) => {
    fetchDocumentationData({ id: jobsData.id });
    fetchJobComments({ id: jobsData.id })
    fetchVin({ id: jobsData.id })
    setModalData(jobsData);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
      setShowModel(true);
      setAddDocumentationModel(true);
    }
  };

  const _handleTrackingClick = (jobsData) => {
    fetchMSCToken({ id: jobsData.shipping_line_id });
    fetchDocumentationData2({ id: jobsData.id });
    setShowModel(true);
    setAddTrackingModel(true);
    setAddDocumentationModel(false);
    setEditDocumentationModel(false);
    setAddDocumentationModel(false);
    setModalData(jobsData);
    setViewModel(false);
  };

  const callPageRecords = (userData) => {
    if (userData) {
      return userData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.job_number}</td>
          <td> {(data.job_date && data.job_date !== "0000-00-00") ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
          <td> {data.booking_number}</td>
          <td> {data.shipping_line_id ? data.carriers_name : ""}</td>
          <td> {data.container_number}</td>
          <td> {(data.doc_cutoff_date && data.doc_cutoff_date !== "0000-00-00") ? moment(data.doc_cutoff_date).format("YYYY-MM-DD") : ""}</td>
          <td> {data.export_document_coordinator_id ? data.edc_name : ""}</td>
          <td> {data.job_status_id ? data.job_status_name : ""}</td>
          <td> {data.job_completed ? data.job_completed : "NO"}</td>
          <td align="center">
            {
              data.customer_doc_permission === '1' && data.job_completed !== 'Yes' ?
                <button
                  type="button"
                  className="bd-none btn btn-info btn-xsm"
                  onClick={() => _handleDocumentationClick(data)}
                  data-toggle="tooltip" data-placement="top" title="Documentation"
                >
                  <i className="fa fa-fw fa-file"></i>
                </button> : ''
            }
            <button
              type="button"
              className="bd-none btn btn-secondary btn-xsm"
              onClick={() => _handleViewClick(data)}
              data-toggle="tooltip" data-placement="top" title="View"
            >
              <i className="fa fa-fw fa-eye"></i>
            </button>
            {data.shipping_line_id == 1 || data.shipping_line_id == 6 || data.shipping_line_id == 5?
            <button
              type="button"
              className="bd-none btn btn-dark btn-xsm"
              onClick={() => _handleTrackingClick(data)}
              data-toggle="tooltip" data-placement="top" title="View"
            >
              <i className="fa fa-ship"></i>
            </button>
             : ""}
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={13}>No Record Found</td>
        </tr>
      );
    }
  };

  const submitDocumentationDetails = async (params) => {
    dispatch(addDocumentationDetails(params, modalData, currentUser.user));
    if (params.getAll('pdfs') && params.getAll('pdfs').length)
      displaySuccessMessage('File processing may take some time');
    setShowModel(false);
    setAddDocumentationModel(false);
  };

  const submitEditDocumentationDetails = async (params) => {
    dispatch(editDocumentationDetails(params, modalData, currentUser.user));
    if (params.getAll('pdfs') && params.getAll('pdfs').length)
      displaySuccessMessage('File processing may take some time');
    setShowModel(false);
    setEditDocumentationModel(false);
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <>
      <AdminLayout pageHeading="Dashboard" pageIcon="fa fa-tasks">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Booking History</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-12">
                  <div className="fillter-wrap d-flex justify-content-end">
                    <select name="selectType" id="" className="form-control me-1" onChange={(event) => handleSearhChange(event)}>
                      <option value="">Select Type</option>
                      <option value="job_number">Job No.</option>
                      <option value="job_date">Job Date</option>
                      <option value="booking_number">Booking No.</option>
                      <option value="shipping_line_id">Carrier</option>
                      <option value="container_number">Container No</option>
                      <option value="doc_cutoff_date">Doc. Cut off date</option>
                      <option value="export_document_coordinator_id">Export Coordinator</option>
                      <option value="job_status_id">Job Status</option>
                    </select>
                    <input type="search" className="form-control" placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Job No.</th>
                          <th>Job Date</th>
                          <th>Booking No.</th>
                          <th>Carrier	</th>
                          <th>Container No</th>
                          <th>Doc. Cut off date</th>
                          <th>Export Coordinator</th>
                          <th>Job Status</th>
                          <th>Completed</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobsListResult.result !==
                          undefined &&
                          jobsListResult.result !== "" ? (
                          callPageRecords(
                            jobsListResult.result
                          )
                        ) : (
                          <tr>
                            <td colSpan={13}>No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {
                    <PaginationBlock
                      perPage={perPage}
                      userRecordsData={userRecordsData}
                      callHandlePageClick={(data) =>
                        handlePageClick(data)
                      }
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <ModalBox
            title={addDocumentationModel || editDocumentationModel ? 'Documentation Form'
              : (viewModel ? 'View Job' : 'Job Tracking')}
            show={showModel}
            size="xl"
            onHide={() => {
              setShowModel(false);
              setAddTrackingModel(false);
            }}
          >
            {addDocumentationModel === true ? (
              <DocumentationForm
                addUserDetails={(params) => submitDocumentationDetails(params)}
                jobsDetails={modalData}
                documentationData={documentationData}
                jobComments={jobComments}
                jobsData={jobsData}
                shipperConsignee={shipperConsignee}
              />
            ) :
              editDocumentationModel === true ? (
                <DocumentationForm
                  userId={userId}
                  jobsDetails={modalData}
                  jobsData={jobsData}
                  documentationData={documentationData}
                  documentationDataById={documentationDataById}
                  jobComments={jobComments}
                  jobVin={jobVin}
                  shipperConsignee={shipperConsignee}
                  editUserDetails={(params) => submitEditDocumentationDetails(params)}
                />
              ) :
                viewModel === true ? (
                  <ViewForm
                    userId={userId}
                    jobsDetails={modalData}
                    jobsData={jobsData}
                    jobComments={jobComments}
                  />
                ) :
                  addTrackingModel === true ?
                    (
                      <TrackingForm
                        userId={userId}
                        jobsDetails={modalData}
                        jobsData={jobsData}
                        documentationDataById={documentationDataById}
                        mscToken={mscToken}
                      />
                    )
                    :
                    (
                      ""
                    )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default Jobs;
