import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import {
  deleteTaskById,
  gettaskList,
  addTaskDetails,
  editTaskDetails,
} from "../../../Services/Store/Common/tasks/task.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { TaskForm } from "./TaskForm";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { taskList } from "../../../Services/Store/Common/tasks/task.selector";
import moment from "moment";
const Tasks = () => {
  const currentUser = useSelector((state) => state.userClient);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userRecordsData, setUserRecordsData] = useState(0);
  const [getClientList, setGetClientList] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const taskListResult = useSelector(taskList);
  // const mainClientresponse = useSelector(mainClient);
  const [searchType, setSearchType] = useState("name");

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }

  }, []);

  useEffect(() => {
    if (taskListResult.result !== undefined) {
      const result = taskListResult.result;
      if (result.length > 0) {
        setUserData(taskListResult.result);
        setUserRecordsData(taskListResult.records);
        setErrorResponce(taskListResult.error_code);
      } else {
        setUserData("");
        setUserRecordsData("");
      }
    }
  }, [taskListResult]);



  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(gettaskList({ searchType: searchType, search: searchKeyword, offset }));
    }, 500);
    return () => clearTimeout(getData);
  }, [searchKeyword]);


  /** For pagination code start here **/
  var offset = currentPage * perPage;
  const handlePageClick = (selectedPage) => {
    dispatch(
      gettaskList({ searchType: searchType, search: searchKeyword, offset: selectedPage })
    );
  };
  /** For pagination code end here **/

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (userDetails) => {
    setModalData(userDetails);
    setShowModel(true);
    setEditModel(true);
    setAddModel(false);
    setDeleteModel(false);
  };

  const _handleDeleteClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setDeleteModel(true);
    setAddModel(false);
    setEditModel(false);
  };



  const _handleAddClick = () => {
    setShowModel(true);
    setAddModel(true);
    setEditModel(false);
    setDeleteModel(false);
  };

  const callPageRecords = (userData) => {
    if (userData) {
      return userData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.name}</td>
          <td>{data.description}</td>
          <td>{data.due_date}</td>
          <td>{data.user_name}</td>
          <td>{data.status}</td>
          <td>{data.user_comment == 'undefined' ? "" : data.user_comment}</td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit contact"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Delete contact"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>

          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={8}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteUser = async (userId2) => {
    dispatch(deleteTaskById(userId2));
    setShowModel(false);
    setDeleteModel(false);
  };




  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };



  const submitUserDetails = async (params) => {
    dispatch(addTaskDetails(params));
    setShowModel(false);
    setAddModel(false);

  };



  const submitEditUserDetails = async (params) => {
    dispatch(editTaskDetails(params));
    setShowModel(false);
    setAddModel(false);
  };



  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };
  return (
    <>
      <AdminLayout pageHeading="Tasks" pageIcon="fa fa-tasks">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Task List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-6">
                    <button
                      type="button"
                      className="mb-2 mb-md-0 btn btn-primary"
                      onClick={() => _handleAddClick()}
                    >
                      Add&nbsp;
                      <i className="fa fa-plus fa-sm"></i>
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="fillter-wrap d-flex justify-content-end">
                      <select name="selectType" id="" className="form-control me-1" onChange={(event) => handleSearhChange(event)}>
                        <option value="">Select Type</option>
                        <option value="name">Name</option>
                        <option value="description">Description</option>
                        <option value="TK.status">Status</option>
                      </select>
                      <input type="search" className="form-control" placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                    </div>
                  </div>
                </div>
                </div>
                <div className="card-body">
                <div className="row">
                  <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Name</th>
                          <th>Description</th>
                          <th>Due Date</th>
                          <th>Assigned To </th>
                          <th>Status</th>
                          <th>Employee Comment</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskListResult.result !==
                          undefined &&
                          taskListResult.result !== "" ? (
                          callPageRecords(
                            taskListResult.result
                          )
                        ) : (
                          <tr>
                            <td colSpan={8}>No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    {
                      <PaginationBlock
                        perPage={perPage}
                        userRecordsData={userRecordsData}
                        callHandlePageClick={(data) =>
                          handlePageClick(data)
                        }
                      />
                    }
                  </div>
                </div>
            </div>
          </div>
          <ModalBox
            show={showModel}
            title={deleteModel ? 'Delete Task' : 'Task Form'}
            size={deleteModel ? 'md' : (addModel || editModel ? 'lg' : 'xl')}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddModel(false);
            }}
          >
            {editModel === true ? (
              <TaskForm
                editUserDetails={(params) => submitEditUserDetails(params)}
                userDetails={modalData}
                currentUser={currentUser}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                deleteUserId={(userId) => deleteUser(userId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : addModel === true ? (
              <TaskForm
                addUserDetails={(params) => submitUserDetails(params)}
                currentUser={currentUser}
              />
            ) : (
              ""
            )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default Tasks;
