import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
const libraries = ['places'];
export function JobForm(props) {
    const inputRefOrigin = useRef();
    const inputRefDestination = useRef();
    const inputRefTruckingLocation = useRef();
    const [quote, setQuote] = useState(props?.quoteData ? props.quoteData : {});
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDm9SqM0fp4sg7KSLBachj-WS7zpQ80Wtk',
        libraries
    });
    const optionsCarrier = [];
    const optionsJobStatus = [];
    props?.jobsData?.[0].map((data, index) => (
        optionsCarrier.push({ value: data.id, label: data.name })
    ));

    props?.jobsData?.[6].map((data, index) => (
        optionsJobStatus.push({ value: data.id, label: data.name })
    ));

    const validateContactFrom = Yup.object().shape({
        job_for: Yup.string(),
        job_number: Yup.string(),
        job_date: Yup.string(),
        shipping_line_id: Yup.number().nullable(true),
        service_id: Yup.number().nullable(true),
        seal_number: Yup.string().nullable(true),
        sale_person_id: Yup.number().nullable(true),
        booking_number: Yup.string(),
        container_number: Yup.string(),
        customer_id: Yup.number(),
        origin: Yup.string().required("Field is required"),
        destination: Yup.string().required("Field is required"),
        loading_port: Yup.number().required("Field is required"),
        destination_port: Yup.number().required("Field is required"),
        truker_id: Yup.number(),
        doc_cutoff_date: Yup.string().nullable(true),
        vgm_cutoff_date: Yup.string().nullable(true),
        cargo_curtoff_date: Yup.string().nullable(true),
        booking_rollover_date: Yup.string().nullable(true),
        export_document_coordinator_id: Yup.number().nullable(true),
        import_document_coordinator_id: Yup.number().nullable(true),
        job_status_id: Yup.number().nullable(true),
        trucking_location: Yup.string().nullable(true),
        job_comment: Yup.string().nullable(true),
        loading_date: Yup.string().required("Field is required"),
        customer_comment: Yup.string().nullable(true),
        need_trucking: Yup.string().required("Field is required"),
    });

    let initialValues = {
        job_for: '',
        job_number: props?.jobNumber,
        job_date: '',
        shipping_line_id: quote?.shipping_line_id ? quote.shipping_line_id : '',
        service_id: '',
        seal_number: '',
        sale_person_id: props.salesPersonId,
        booking_number: '',
        container_number: '',
        customer_id: props.customerID,
        origin: quote?.origin ? quote.origin : '',
        destination: quote?.destination ? quote.destination : '',
        loading_port: quote?.loading_port ? quote.loading_port : '',
        destination_port: quote?.destination_port ? quote.destination_port : '',
        destination_port: quote?.destination_port ? quote.destination_port : '',
        truker_id: '',
        doc_cutoff_date: '',
        vgm_cutoff_date: '',
        cargo_curtoff_date: '',
        booking_rollover_date: '',
        export_document_coordinator_id: '',
        import_document_coordinator_id: '',
        job_status_id: '',
        trucking_location: '',
        job_comment: '',
        loading_date: '',
        customer_comment: quote?.comments ? quote.comments : '',
        need_trucking: ""
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            const params = {
                job_for: values.job_for,
                job_number: (props?.jobNumber).toString(),
                job_date: values.job_date,
                shipping_line_id: quote?.shipping_line_id,
                service_id: values.service_id,
                seal_number: values.seal_number,
                sale_person_id: values.sale_person_id,
                booking_number: values.booking_number,
                container_number: values.container_number,
                customer_id: props.customerID,
                origin: values.origin,
                destination: values.destination,
                loading_port: values.loading_port,
                destination_port: values.destination_port,
                truker_id: values.truker_id,
                doc_cutoff_date: values.doc_cutoff_date,
                vgm_cutoff_date: values.vgm_cutoff_date,
                cargo_curtoff_date: values.cargo_curtoff_date,
                booking_rollover_date: values.booking_rollover_date,
                export_document_coordinator_id: values.export_document_coordinator_id,
                import_document_coordinator_id: values.import_document_coordinator_id,
                job_status_id: values.job_status_id,
                trucking_location: values.trucking_location,
                job_comment: [],
                loading_date: values.loading_date,
                customer_comment: values.customer_comment,
                need_trucking: values.need_trucking
            }
            props.addUserDetails(params)
        },
    });

    const optionsOrigin = [];
    const optionsDestination = [];
    props?.jobsData?.[8].map((data, index) => (
        optionsOrigin.push({ value: data.id, label: data.name + ', ' + data.country })
    ));
    props?.jobsData?.[9].map((data, index) => (
        optionsDestination.push({ value: data.id, label: data.name + ', ' + data.country })
    ));

    const handleOriginSelect = e => {
        formik.setFieldValue("loading_port", e.value);
    };

    const handleDestinationSelect = e => {
        formik.setFieldValue("destination_port", e.value);
    };
    const handlePlaceChangedTruckingLocation = () => {
        const [place] = inputRefTruckingLocation.current.getPlaces();
        if (place) {
            formik.setFieldValue("trucking_location", place.formatted_address);
        }
    }
    const handlePlaceChangedOrigin = () => {
        const [place] = inputRefOrigin.current.getPlaces();
        if (place) {
            formik.setFieldValue("origin", place.formatted_address);
        }
    }

    const handlePlaceChangedDestination = () => {
        const [place] = inputRefDestination.current.getPlaces();
        if (place) {
            formik.setFieldValue("destination", place.formatted_address);
        }
    }
    return (
        <>
            <div className="modal-body invitation-wrap">
                <div className="login-wrap-new">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-content">
                            <div className="row">
                                <div className="col-sm-3">
                                    <label>Origin*</label>
                                    {isLoaded
                                        && <StandaloneSearchBox
                                            onLoad={ref => inputRefOrigin.current = ref}
                                            onPlacesChanged={handlePlaceChangedOrigin}
                                        >
                                            <input className="form-control"
                                                name="origin"
                                                id="origin"
                                                type='text'
                                                placeholder='Origin'
                                                {...formik.getFieldProps('origin')}
                                            />
                                        </StandaloneSearchBox>}
                                    {formik.touched.origin && formik.errors.origin && (
                                        <div className="errorMsg text-danger">{formik.errors.origin}</div>
                                    )}
                                </div>
                                <div className="col-sm-3">
                                    <label>Loading Port*</label>
                                    <Select
                                        name="loading_port"
                                        id="loading_port"
                                        options={optionsOrigin}
                                        onChange={handleOriginSelect}
                                        value={optionsOrigin.filter(function (option) {
                                            return option.value == formik.values.loading_port;
                                        })}
                                        label="Single select"
                                    />
                                    {formik.touched.loading_port && formik.errors.loading_port && (
                                        <div className="errorMsg text-danger">{formik.errors.loading_port}</div>
                                    )}
                                </div>
                                <div className="col-sm-3">
                                    <label>Destination*</label>
                                    {isLoaded
                                        && <StandaloneSearchBox
                                            onLoad={ref => inputRefDestination.current = ref}
                                            onPlacesChanged={handlePlaceChangedDestination}
                                        >
                                            <input className="form-control"
                                                name="destination"
                                                id="destination"
                                                placeholder='Destination'
                                                type='text'
                                                {...formik.getFieldProps('destination')}
                                            />
                                        </StandaloneSearchBox>}
                                    {formik.touched.destination && formik.errors.destination && (
                                        <div className="errorMsg text-danger">{formik.errors.destination}</div>
                                    )}
                                </div>
                                <div className="col-sm-3">
                                    <label>Destination Port*</label>
                                    <Select
                                        name="destination_port"
                                        id="destination_port"
                                        options={optionsDestination}
                                        onChange={handleDestinationSelect}
                                        value={optionsDestination.filter(function (option) {
                                            return option.value == formik.values.destination_port;
                                        })}
                                        label="Single select"
                                    />
                                    {formik.touched.destination_port && formik.errors.destination_port && (
                                        <div className="errorMsg text-danger">{formik.errors.destination_port}</div>
                                    )}
                                </div>
                                <div className="col-sm-3">
                                    <label>Loading Date*</label>
                                    <input className="form-control"
                                        type="date"
                                        name="loading_date"
                                        id="loading_date"
                                        {...formik.getFieldProps('loading_date')}
                                    />
                                    {formik.touched.loading_date && formik.errors.loading_date && (
                                        <div className="errorMsg text-danger">{formik.errors.loading_date}</div>
                                    )}
                                </div>
                                <div className="col-sm-3">
                                    <label>Need trucking*</label>
                                    <select className="form-control"
                                        name="need_trucking"
                                        id="need_trucking"
                                        {...formik.getFieldProps('need_trucking')}
                                    >
                                        <option>--Select--</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    {formik.touched.need_trucking && formik.errors.need_trucking && (
                                        <div className="errorMsg text-danger">{formik.errors.need_trucking}</div>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <label>Trucking Location</label>
                                    {isLoaded
                                        &&
                                        <StandaloneSearchBox
                                            onLoad={ref => inputRefTruckingLocation.current = ref}
                                            onPlacesChanged={handlePlaceChangedTruckingLocation}
                                        >
                                            <input
                                                name="trucking_location"
                                                type="text"
                                                className="form-control"
                                                id="trucking_location"
                                                placeholder='Trucking Location'
                                                {...formik.getFieldProps('trucking_location')}
                                            />
                                        </StandaloneSearchBox>
                                    }
                                    {formik.touched.trucking_location && formik.errors.trucking_location && (
                                        <div className="errorMsg text-danger">{formik.errors.trucking_location}</div>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <label>Comment</label>
                                    <textarea
                                        className="form-control"
                                        type="text"
                                        name="customer_comment"
                                        id="comment"
                                        {...formik.getFieldProps('customer_comment')}
                                    />
                                    {formik.touched.customer_comment && formik.errors.customer_comment && (
                                        <div className="errorMsg text-danger">{formik.errors.customer_comment}</div>
                                    )}
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-12">
                                    <input type="submit" name="submit" className="btn btn-primary" value={props.customerID ? 'Add Booking Request' : (props?.userDetails ? 'Save Job' : 'Save Job')} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}