import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios";

export const deleteCustomerRateHistoryById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.delete("api/v1/tslCustomerRateHistory/" + id, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("customerRateHistoryDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_SUCCESS,
        payload: {
          records: getState().customerRateHistory.records.map((data) => data).filter((data) => data.id !== id),
          totalCount: getState().customerRateHistory.totalCount - 1,
          error_code: 0,
        },
      });
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const getCustomerRateHistoryList = (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslCustomerRateHistory", { params: postData, headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      return dispatch({
        type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_SUCCESS,
        payload: {
          records: response.data.data,
          totalCount: response.data.total_records,
          error_code: response.data.errorCode,
        },
      });
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const addCustomerRateHistoryDetails = (postData) => async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslCustomerRateHistory", postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("customerRateHistoryAdded");
      dispatch(getCustomerRateHistoryList({}));
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const editCustomerRateHistoryDetails = (postData, customerRateHistoryId) => async (dispatch) => {
  try {
    const response = await api.put("api/v1/tslCustomerRateHistory/" + customerRateHistoryId, postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("customerRateHistoryUpdated");
      dispatch(getCustomerRateHistoryList({}));
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const uploadExcelCustomerRates = (postData) => async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslUploadCustomerRates", postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("Customer Rates Excel File Processed Successfully");
      return dispatch(getCustomerRateHistoryList({}));
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_CUSTOMER_RATE_HISTORY_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};


