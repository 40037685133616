import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export function ExcelForm(props) {

    const validateContactFrom = Yup.object().shape({
        excel_file: Yup.string().required('File Required')
    });
    let initialValues = {};

    initialValues = {
        excel_file: "",
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append("excel_file", values.excel_file);
            props.submitExcel(formData);
        },
    });

    return (
        <>
            <div className="modal-body">
                <form onSubmit={formik.handleSubmit} className='invitation-wrap' encType="multipart/form-data">
                    <div className="row">
                        <div className="col-12">
                            <label>Upload File</label>
                            <input
                                type='file'
                                className="form-control"
                                name="excel_file"
                                id="excel_file"
                                accept='.xlsx'
                                onChange={(event) => {
                                    if (event.currentTarget.files) {
                                        formik.setFieldValue(
                                            "excel_file",
                                            event.currentTarget.files[0]
                                        );
                                    }
                                }
                                }

                            />
                            {formik.touched.excel_file && formik.errors.excel_file && (
                                <div className="errorMsg text-danger">{formik.errors.excel_file}</div>
                            )}
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className="col-sm-4">
                            <input type="submit" name="submit" className="btn btn-primary" value="Upload" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}