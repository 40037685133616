import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import moment from "moment";
import {
  deleteCustomerRateHistoryById,
  getCustomerRateHistoryList,
  addCustomerRateHistoryDetails,
  editCustomerRateHistoryDetails,
  uploadExcelCustomerRates,
} from "../../../Services/Store/Common/customerRateHistory/customerRateHistory.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { Form } from "./Form";
import { ExcelForm } from "./ExcelForm";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { customerRateHistorySelector } from "../../../Services/Store/Common/customerRateHistory/customerRateHistory.selector";
import { displaySuccessMessage } from "../../../Services/Helpers/helper";

const CustomerRateHistory = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [customerRateHistoryList, setCustomerRateHistoryList] = useState([]);
  const [customerRateHistoryCount, setCustomerRateHistoryCount] = useState(0);
  const [customerRateHistoryId, setCustomerRateHistoryId] = useState([]);
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);

  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [excelModel, setExcelModel] = useState(false);

  const perPage = 25;
  const [modalData, setModalData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerRateHistoryData = useSelector(customerRateHistorySelector);
  const [searchType, setSearchType] = useState("");
  const currentDate = moment(new Date()).format('Y-M-DD');

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));

    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('clientToken');
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponce]);

  useEffect(() => {
    dispatch(getCustomerRateHistoryList({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorResponce === 0) {
      setShowModel(false);
      setAddModel(false);
      setEditModel(false);
      setDeleteModel(false);
    }
    setCustomerRateHistoryList(customerRateHistoryData.records);
    setCustomerRateHistoryCount(customerRateHistoryData.totalCount);
    setErrorResponce(customerRateHistoryData.error_code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerRateHistoryData]);

  useEffect(() => {
    if (searchType?.length) {
      dispatch(getCustomerRateHistoryList({ searchType: searchType, search: searchKeyword }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, searchType]);

  const handlePageClick = (selectedPage) => {
    dispatch(getCustomerRateHistoryList({ searchType: searchType, search: searchKeyword, offset: selectedPage }));
  };

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (customerRateHistoryDetails) => {
    setModalData(customerRateHistoryDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (customerRateHistoryId) => {
    setCustomerRateHistoryId(customerRateHistoryId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleAddClick = () => {
    setShowModel(true);
    setAddModel(true);
  };

  const _handleExcelUpload = () => {
    setShowModel(true);
    setExcelModel(true);
  };

  const submitExcel = async (params) => {
    dispatch(uploadExcelCustomerRates(params));
    displaySuccessMessage("File Uploaded, Updatation is in progress.");
    setShowModel(false);
    setExcelModel(false);
  };

  const callPageRecords = (customerRateHistoryList) => {

    if (customerRateHistoryList.length) {
      return customerRateHistoryList.map((data, index) => (
        <tr key={data.id}>
          <td>{index + 1}</td>
          <td>{data.customer}</td>
          <td>{data.shipping_line}</td>
          <td>{data.origin}</td>
          <td>{data.loading_port}</td>
          <td>{data.destination}</td>
          <td>{data.destination_port}</td>
          <td>{data.cost}</td>
          <td>{data.quote}</td>
          <td>{data.commodity}</td>
          <td>{data.salesperson}</td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Delete"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr key="0" className="text-center">
          <td colSpan={12}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteCustomerRateHistory = async (customerRateHistoryId) => {
    dispatch(deleteCustomerRateHistoryById(customerRateHistoryId));
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submitCustomerRateHistoryDetails = async (params, customerRateHistoryId) => {
    if (customerRateHistoryId) {
      dispatch(editCustomerRateHistoryDetails(params, customerRateHistoryId));
    } else {
      dispatch(addCustomerRateHistoryDetails(params));
    }
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <>
      <AdminLayout pageHeading="Customer Rate History" pageIcon="fa fa-dollar">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Customer Rate History List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-6">
                    <div className="d-flex">
                      <button type="button" className="mb-2 mb-md-0 mr-2 btn btn-primary" onClick={() => _handleAddClick()}>
                        Add <i className="fa fa-plus fa-sm"></i>
                      </button>
                      <button
                        type="button"
                        className="mb-2 mb-md-0 btn btn-primary"
                        onClick={() => _handleExcelUpload()}
                      >
                        Excel <i className="fa fa-upload"></i>
                      </button>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6">
                    <div className="fillter-wrap d-flex justify-content-end">
                      <select className="form-control me-1" onChange={(event) => handleSearhChange(event)}>
                        <option value="">Select Type</option>
                        <option value="customer">Customer Name</option>
                        <option value="origin">Origin</option>
                        <option value="destination">Destination</option>
                        <option value="shipping_line">Shipping Line</option>
                      </select>
                      <input type="search" className="form-control" placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                    </div>
                  </div>
                </div>
                </div>
                <div className="card-body">
                <div className="row">
                  <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>S.No</th>
                          <th>Customer</th>
                          <th>Shipping Line</th>
                          <th>Origin</th>
                          <th>Loading Port</th>
                          <th>Destination</th>
                          <th>Destination Port</th>
                          <th>Cost</th>
                          <th>Quote</th>
                          <th>Commodity</th>
                          <th>SalesPerson</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          callPageRecords(
                            customerRateHistoryList
                          )
                        }
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate"
                    >
                      {
                        customerRateHistoryCount > 0 ? (
                          <PaginationBlock
                            perPage={perPage}
                            userRecordsData={customerRateHistoryCount}
                            callHandlePageClick={(data) =>
                              handlePageClick(data)
                            }
                          />
                        ) : (
                          ""
                        )}
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <ModalBox
            size={deleteModel || excelModel ? 'md' : 'xl'}
            show={showModel}
            title={excelModel ? "Bulk Upload Customer Rate" : (deleteModel ? "Delete Customer Rate History" : (editModel ? "Edit Customer Rate History" : "Add Customer Rate History"))}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddModel(false);
              setExcelModel(false);
            }}
          >
            {editModel === true ? (
              <Form
                submitDetails={(params, customerRateHistoryId) => submitCustomerRateHistoryDetails(params, customerRateHistoryId)}
                customerRateHistoryDetails={modalData}

              />
            ) : addModel === true ? (
              <Form
                submitDetails={(params) => submitCustomerRateHistoryDetails(params, 0)}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={customerRateHistoryId}
                deleteUserId={(customerRateHistoryId) => deleteCustomerRateHistory(customerRateHistoryId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : excelModel ? (
              <ExcelForm
                submitExcel={(params) => submitExcel(params)}
              />
            ) :
              (
                ""
              )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default CustomerRateHistory;
