import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
    getSteamshipLineBalanceReportingList
} from "../../../Services/Store/Common/steamshipLineBalance/steamshipLineBalance.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { steamshipLineBalanceReportingList } from "../../../Services/Store/Common/steamshipLineBalance/steamshipLineBalance.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
const Contact = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [userData, setUserData] = useState([]);
    const [userId, setUserId] = useState("");
    const [errorResponce, setErrorResponce] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [addModel, setAddModel] = useState(false);
    const [perPage, setPerPage] = useState(25);
    const [modalData, setModalData] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [userRecordsData, setUserRecordsData] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ratesListResult = useSelector(steamshipLineBalanceReportingList);
    // const mainClientresponse = useSelector(mainClient);
    const [searchType, setSearchType] = useState("");
    const [shippingLineData, setShippingLineData] = useState("");
    useEffect(() => {
        const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

        if (!loginCheck || errorResponce === 4) {
            navigate("/");
        }

    }, []);
    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/tslCarriersList", {
                params: "",
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setShippingLineData(response?.data?.data);
            } else {
                setShippingLineData("");
            }
        }

        fetchMyAPI2();

    }, []);
    useEffect(() => {
        if (ratesListResult.result !== undefined) {
            const result = ratesListResult.result;
            if (result.length > 0) {
                setUserData(ratesListResult.result);
                setUserRecordsData(ratesListResult.records);
                setErrorResponce(ratesListResult.error_code);
            } else {
                setUserData("");
                setUserRecordsData("");
            }
        }
    }, [ratesListResult]);



    // useEffect(() => {
    //   const getData = setTimeout(() => {
    //     dispatch(getTruckerReportingList({ searchType: searchType, search: searchKeyword, offset, start: formik.values.start_date ,end: formik.values.end_date  }));      
    //   }, 500);
    //   return () => clearTimeout(getData);
    // }, [searchKeyword]);


    /** For pagination code start here **/
    var offset = currentPage * perPage;
    const handlePageClick = (selectedPage) => {
        dispatch(
            getSteamshipLineBalanceReportingList({ searchType: searchType, search: searchKeyword, offset: selectedPage, start: formik.values.start_date, end: formik.values.end_date, carrier_name: formik.values.carrier_name, pay_type: formik.values.pay_type })
        );
    };
    /** For pagination code end here **/

    const handleChange = (event) => {
        if (searchType) {
            setSearchKeyword(event.target.value.trim());
        }
    };



    const callPageRecords = (userData) => {
        if (userData) {
            let text = userData.map((data, index) => (
                <tr key={index}>
                    <td>{data.job_number}</td>
                    <td>{data.job_date ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                    <td>{data.carrier_name}</td>
                    <td>{data.booking_number}</td>
                    <td>{data.container_number}</td>
                    <td>{data.bl_number}</td>
                    <td>{data.ssl_paid_date !="0000-00-00" && data.ssl_paid_date != null ? moment(data.ssl_paid_date).format("YYYY-MM-DD") : "" }</td>
                    <td>{data.ssl_paid_remarks}</td>
                    <td>{data.ssl_amount + data.ssl1_amount}</td>
                    <td>{data.ssl_cad + data.ssl1_cad}</td>
                </tr>
            ));



            return text;




        } else {
            return (
                <tr className="text-center">
                    <td colSpan={12}>No Record Found</td>
                </tr>
            );
        }
    };


    const deleteUser = async (userId2) => {
        //dispatch(deleteRatesById(userId2));
        setShowModel(false);
        setDeleteModel(false);

    };





    const handleSearhChange = (event) => {
        setSearchType(event.target.value);
    };

    const validateContactFrom = Yup.object().shape({
        end_date: Yup.string(),
        start_date: Yup.string(),
        carrier_name: Yup.number(),
        pay_type: Yup.number(),
    });
    let initialValues = {
        end_date: '',
        start_date: "",
        carrier_name: "",
        pay_type: ""
    }
    
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            dispatch(getSteamshipLineBalanceReportingList({ searchType: searchType, search: searchKeyword, offset, start: values.start_date, end: values.end_date, carrier_name: values.carrier_name, pay_type: values.pay_type }));

        },
    });

    return (
        <>
            <AdminLayout pageHeading="Steamship Line Balance Reporting" pageIcon="fa fa-bar-chart">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <h3>Steamship Line Balance</h3>
                        </div>
                    </div>
                    <div className="container-fluid demo">
                        <div
                            className="panel-group dashboard-table-format"
                            id="accordion"
                            role="tablist"
                            aria-multiselectable="true"
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="headingOne"
                                >
                                    <div className="panel-body">
                                        <div className="card  mb-4">
                                            <div className="card-body">                                                
                                                    <div
                                                        id="dataTable_wrapper"
                                                        className="dataTables_wrapper dt-bootstrap4"
                                                    >
                                                        <div className="deposited p-o">
                                                            <form onSubmit={formik.handleSubmit}>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Select Line</label>
                                                                        <select name="carrier_name" className="form-control user-success"
                                                                            {...formik.getFieldProps('carrier_name')}
                                                                        >
                                                                            <option>Select</option>
                                                                            {
                                                                                shippingLineData && shippingLineData.map((customer, index) => {
                                                                                    return (<option key={index} value={customer.id}>{customer.name}</option>);
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {formik.touched.carrier_name && formik.errors.carrier_name && (
                                                                            <div className="errorMsg text-danger">{formik.errors.carrier_name}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Select Type</label>
                                                                        <select name="pay_type" className="form-control user-success"
                                                                            {...formik.getFieldProps('pay_type')}
                                                                        >                                                                            <option>--Select Type--</option>
                                                                            <option value="1">Paid</option>
                                                                            <option value="2">Not Paid</option>
                                                                            <option value="3">All</option>

                                                                        </select>
                                                                        {formik.touched.pay_type && formik.errors.pay_type && (
                                                                            <div className="errorMsg text-danger">{formik.errors.pay_type}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className="col-md-3 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Start Date</label>
                                                                        <input className="form-control"
                                                                            type="date"
                                                                            name="start_date"
                                                                            {...formik.getFieldProps('start_date')}
                                                                        />
                                                                        {formik.touched.start_date && formik.errors.start_date && (
                                                                            <div className="errorMsg text-danger">{formik.errors.start_date}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className="col-md-3 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>End Date</label>
                                                                        <input className="form-control"
                                                                            type="date"
                                                                            name="end_date"
                                                                            {...formik.getFieldProps('end_date')}
                                                                        />
                                                                        {formik.touched.end_date && formik.errors.end_date && (
                                                                            <div className="errorMsg text-danger">{formik.errors.end_date}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className="col-sm-12" style={{ marginTop: "15px"}}>
                                                                        <input className="btn btn-primary mr-2" type="submit" name="search" value="Go" />
                                                                        <span className="mt-2"  style={{ display: "none" }}>
                                                                            <button className="action-tab btn btn-primary mr-1">Select All</button>
                                                                         
                                                                         </span>
                                                                    </div>
                                                                </div>
 
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                    <table className="table table-striped table-bordered" id="pdf">
                                                        <thead>
                                                            <tr className="bg-primary text-white">

                                                                <th>Job No.</th>

                                                                <th>Job Date</th>

                                                                <th>Carrier</th>

                                                                <th>Booking No.</th>

                                                                <th>Container No.</th>
                                                                <th>BL No.</th>

                                                                <th>Paid Date</th>

                                                                <th>Payment Remarks</th>

                                                                <th>Total SSL Ammount Paid USD</th>

                                                                <th>Total SSL Ammount Paid CAD</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ratesListResult.result !==
                                                                undefined &&
                                                                ratesListResult.result !== "" ? (
                                                                callPageRecords(
                                                                    ratesListResult.result
                                                                )
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={12}>No Record Found</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-5">
                                                        {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                                       */}
                                                    </div>
                                                    <div className="col-sm-12 col-md-7">
                                                        <div
                                                            className="dataTables_paginate paging_simple_numbers"
                                                            id="dataTable_paginate"
                                                        >
                                                            {userData != undefined &&
                                                                userData.length > 0 &&
                                                                userRecordsData !== undefined ? (
                                                                <PaginationBlock
                                                                    perPage={perPage}
                                                                    userRecordsData={userRecordsData}
                                                                    callHandlePageClick={(data) =>
                                                                        handlePageClick(data)
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </>
    );
};
export default Contact;
