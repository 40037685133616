import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";

export const deleteBookingById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.delete("api/v1/tslBookings/" + id, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("bookingDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_BOOKING_LIST_SUCCESS,
        payload: {
          records: getState().bookings.records.map((data) => data).filter((data) => data.id !== id),
          totalCount: getState().bookings.totalCount - 1,
          error_code: 0,
        },
      });
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const getBookingList = (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_BOOKING_LIST_PENDING });
  try {
    const response = await api.post("api/v1/tslGetBookings", postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      return dispatch({
        type: USER_ACTION_TYPE.SET_BOOKING_LIST_SUCCESS,
        payload: {
          records: response.data.data,
          totalCount: response.data.total_records,
          error_code: response.data.errorCode,
        },
      });
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const addBookingDetails = (postData, searchIds) => async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslBookings", postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("bookingAdded");
      return dispatch(getBookingList({ searchIds }));
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const editBookingDetails = (postData, bookingId, searchIds) => {
  return async (dispatch) => {
    try {
      const response = await api.put("api/v1/tslBookings/" + bookingId, postData, { headers: requestTokenHeader() });
      if (response.data.errorCode === 0) {
        displaySuccessMessage("bookingUpdated");
        return dispatch(getBookingList({ searchIds }));
      } else {
        displayErrorMessage(response.data.errorMessage);
        return dispatch({
          type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
          payload: {
            error_code: response.data.errorCode,
          },
        });
      }
    } catch (err) {
      if (err?.response?.data?.errorCode === 4) {
        displayErrorMessage(err?.response?.data?.errorMessage);
      } else {
        displayErrorMessage(err.message);
      }
      return dispatch({
        type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
        payload: {
          error_code: err?.response?.data?.errorCode
        }
      });
    }
  }
};

export const uploadExcelBookings = (postData, searchIds) => async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslUploadBookings", postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("Booking Excel File Processed Successfully");
      return dispatch(getBookingList({ searchIds }));
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const uploadBookingConfirmation = (postData, searchIds) => async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslUploadBookingConfirmation", postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("Booking Confirmation Processed Successfully.");
      return dispatch(getBookingList({ searchIds }));
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};


