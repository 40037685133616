import { USER_ACTION_TYPE } from "../../../Constant/user.constants";

const INITIAL_STATE = {
  showQuotesList: [],
  blocking:false,
};
export const quotesListReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPE.SET_QUOTES_LIST_PENDING:
      return {
        blocking: true,
        showQuotesList: [],
      };

    case USER_ACTION_TYPE.SET_QUOTES_LIST_SUCESS:
      return {
        blocking: false,
        showQuotesList: payload,
      };

    case USER_ACTION_TYPE.SET_QUOTES_LIST_ERROR:
      return {
        blocking: true,
        showQuotesList: [],
      };

    case USER_ACTION_TYPE.RESET_QUOTES_LIST:
      return {
        blocking: true,
        showQuotesList: [],
      };

    default:
      return state;
  }
};
