import { USER_ACTION_TYPE } from '../../Constant/user.constants';

const INITIAL_STATE = {
    user: null,
    loginError: "",
};
export const ClientReducer=(state=INITIAL_STATE, action)=>{
    const {type,payload}=action;
    switch(type) {
        case USER_ACTION_TYPE.SET_CURRENT_CLIENT_PENDING:
            return {
                user: null
            };
        case USER_ACTION_TYPE.SET_CURRENT_CLIENT_SUCESS:
            return {
                user: payload.user,
            };
        case USER_ACTION_TYPE.SET_CURRENT_CLIENT_ERROR:
            return {
                user: null,
                loginError: payload.error
            };
        default: {
            if(!state.userClient && localStorage.getItem('user')) {
                return {
                    ...state,
                    user: JSON.parse(localStorage.getItem('user'))
                };
            } else {
                return state;
            }
        }
    }
}