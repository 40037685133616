import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import {
    getExcelReportingList
} from "../../../Services/Store/Common/excelReport/excelReport.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { excelReportingList } from "../../../Services/Store/Common/excelReport/excelReport.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import * as XLSX from "xlsx";
import Loader from "../../../../src/Components/Ui/loader";
const Contact = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [userData, setUserData] = useState([]);
    const [userId, setUserId] = useState("");
    const [errorResponce, setErrorResponce] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [addModel, setAddModel] = useState(false);
    const [perPage, setPerPage] = useState(25);
    const [modalData, setModalData] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [userRecordsData, setUserRecordsData] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ratesListResult = useSelector(excelReportingList);
    // const mainClientresponse = useSelector(mainClient);
    const [searchType, setSearchType] = useState("");
    const [customerData, setCustomerData] = useState("");
    const [display, setDisplay] = useState(false);
    useEffect(() => {
        const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

        if (!loginCheck || errorResponce === 4) {
            navigate("/");
        }

    }, []);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/tslCustomerListById", {
                params: { offset: 0, limit: 9999999999999, deleteCond: true },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setCustomerData(response?.data?.data);
            } else {
                setCustomerData("");
            }
        }

        fetchMyAPI2();

    }, []);

    useEffect(() => {
        if (ratesListResult.result !== undefined) {
            const result = ratesListResult.result;
            if (result.length > 0) {
                setUserData(ratesListResult.result);
                setUserRecordsData(ratesListResult.records);
                setErrorResponce(ratesListResult.error_code);
            } else {
                setUserData("");
                setUserRecordsData("");
            }
        }
    }, [ratesListResult]);



    const validateContactFrom = Yup.object().shape({
        end_date: Yup.string().required('Field is required'),
        start_date: Yup.string().required('Field is required'),
        customer_id: Yup.number().required('Field is required'),
    });
    let initialValues = {
        end_date: '',
        start_date: "",
        customer_id: ""
    }


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {

            // dispatch(getExcelReportingList({ start: values.start_date, end: values.end_date, customer_id: values.customer_id }));

        },
    });

    const handleDownload = (type) => {
        fetchMyAPI2(type);
    };


    async function fetchMyAPI2(type) {

        if (formik.values.start_date == "" || formik.values.end_date == "") {
            displayErrorMessage("containerReportingError"); return;

        }

        setDisplay(true);

        const response = await api.post("api/v1/tslExcelReportingList",
            { start: formik.values.start_date, end: formik.values.end_date, customer_id: formik.values.customer_id, offset: 0, limit: 9999999999999 },
            { headers: requestTokenHeader() }
        );

        if (response.data.errorCode === 0) {

            if (type == "excel") {
                const rows = (response.data.data).map((product) => ({
                    job_number: product.job_number,
                    job_date: product.job_date,
                    customer_name: product.customer_name,
                    cq_amount: product.cq_amount,
                    cq_cad: product.cq_cad,
                    carrier: product.carrier_name,
                    ssl_amount: product.ssl_amount,
                    ssl_cad: product.ssl_cad,
                    ssl_paid_date: product.ssl_paid_date != "0000-00-00" ? moment(product.ssl_paid_date).format("YYYY-MM-DD") : "",
                    steamship_status: product.ssl_paid_date == '0000-00-00' ? 'Not Paid' : 'Paid',
                    trucker_name: product.trucker_name,
                    trucker_amount: product.trucker_amount,
                    trucker_cad: product.trucker_cad,
                    trucker_date_new: product.trucker_date_new != "0000-00-00" ? moment(product.trucker_date_new).format("YYYY-MM-DD") : "",
                    total_recievable_usd: product.total_recievable_usd,
                    total_recievable_cad: product.total_recievable_cad,
                    total_usd: product.total_usd,
                    total_cad: product.total_cad,
                    balance_usd: product.total_recievable_usd - product.total_usd,
                    balance_cad: product.total_recievable_cad - product.total_cad,
                    when_paid: product.when_paid != "0000-00-00" && !(product.when_paid == null) ? moment(product.when_paid).format("YYYY-MM-DD") : ""

                }));
                const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.json_to_sheet(rows);
                XLSX.utils.book_append_sheet(workbook, worksheet, "ExcelReport");
                XLSX.utils.sheet_add_aoa(worksheet, [
                    ["Job No", "Job Date", "Customer Name",
                        "Customer Amt. USD", "Customer Amt. CAD", "Carrier", "Steamship Amt. USD", "Steamship Amt. CAD",
                        "Steamship Paid Date", "Steamship Invoice Status", "Trucker Name", "Trucker Amt. USD", "Trucker Amt. CAD",
                        "Trucker Paid Date", "Total Receivable USD", "Total Receivable CAD", "Total Received USD",
                        "Total Received CAD", "Balance USD", "Balance CAD",
                        "When Paid"],
                ]);
                XLSX.writeFile(workbook, "excel_report.xlsx", { compression: true });
            }

            if (type == "pdf") {
                const doc = new jsPDF();
                const tableColumn = ["Job No", "Job Date", "Customer Name", "Customer Amt. USD", "Customer Amt. CAD", "Carrier", "Steamship Amt. USD", "Steamship Amt. CAD", "Steamship Paid Date", "Steamship Invoice Status", "Trucker Name", "Trucker Amt. USD", "Trucker Amt. CAD", "Trucker Paid Date", "Total Receivable USD", "Total Receivable CAD", "Total Received USD", "Total Received CAD", "Balance USD", "Balance CAD", "When Paid"];
                const tableRows = [];

                (response.data.data).forEach(product => {
                    const ticketData = [
                        product.job_number,
                        product.job_date,
                        product.cq_amount,
                        product.cq_cad,
                        product.shipping_line_id,
                        product.ssl_amount,
                        product.ssl_cad,
                        product.ssl_paid_date,
                        product.ssl_paid_date == '0000-00-00' ? 'Not Paid' : 'Paid',
                        product.trucker_name,
                        product.trucker_amount,
                        product.trucker_cad,
                        product.trucker_date_new,
                        product.total_recievable_usd,
                        product.total_recievable_cad,
                        product.total_usd,
                        product.total_cad,
                        product.total_recievable_usd - product.total_usd,
                        product.total_recievable_cad - product.total_cad,
                        product.when_paid
                    ];
                    tableRows.push(ticketData);
                });

                doc.autoTable(tableColumn, tableRows, { startY: 20 });
                // const date = Date().split(" ");
                // const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
                doc.save(`excel_report.pdf`);

            }
            setDisplay(false);

        } else {
            setDisplay(false);
        }
    }

    return (
        <>
            <AdminLayout pageHeading="Excel Export" pageIcon="fa fa-bar-chart">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <h3>Excel Export</h3>
                        </div>
                    </div>
                    <div className="container-fluid demo">
                        <div
                            className="panel-group dashboard-table-format"
                            id="accordion"
                            role="tablist"
                            aria-multiselectable="true"
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="headingOne"
                                >
                                    <div className="panel-body">
                                        <div className="card  mb-4">
                                            <div className="card-body">
                                                <Loader display={display} />
                                                <div className="table-responsive">
                                                    <div
                                                        id="dataTable_wrapper"
                                                        className="dataTables_wrapper dt-bootstrap4"
                                                    >

                                                        <div className="deposited p-0">
                                                            <form onSubmit={formik.handleSubmit}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-4 col-md-3">
                                                                        <div className="form-group">
                                                                            <label>Start Date</label>
                                                                            <input className="form-control"
                                                                                type="date"
                                                                                name="start_date"
                                                                                {...formik.getFieldProps('start_date')}
                                                                            />
                                                                            {formik.touched.start_date && formik.errors.start_date && (
                                                                                <div className="errorMsg text-danger">{formik.errors.start_date}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-4 col-md-3">
                                                                        <div className="form-group">
                                                                            <label>End Date</label>
                                                                            <input className="form-control"
                                                                                type="date"
                                                                                name="end_date"
                                                                                {...formik.getFieldProps('end_date')}
                                                                            />
                                                                            {formik.touched.end_date && formik.errors.end_date && (
                                                                                <div className="errorMsg text-danger">{formik.errors.end_date}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-sm-4 col-md-3">
                                                                        <div className="form-group">
                                                                            <label>Select Customer</label>
                                                                            <select name="customer_id" className="form-control user-success"

                                                                                {...formik.getFieldProps('customer_id')}
                                                                            >

                                                                                <option>Select</option>
                                                                                {
                                                                                    customerData && customerData.map((customer, index) => {
                                                                                        return (<option key={index} value={customer.id}>{customer.firstname}</option>);
                                                                                    })
                                                                                }

                                                                            </select>
                                                                            {formik.touched.customer_id && formik.errors.customer_id && (
                                                                                <div className="errorMsg text-danger">{formik.errors.customer_id}</div>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-3">
                                                                        {/* <input className="action-tab btn btn-primary ml-1" type="submit" name="search" value="Go" style={{ marginTop: "15px" }} /> */}
                                                                        <button type="submit" className="action-tab btn btn-primary" onClick={item => handleDownload("excel")} style={{ marginTop: "15px" }} data-toggle="tooltip" data-placement="top" title="Export as Excel file"><i class="fa fa-file-excel-o"></i></button>
                                                                        {/* <button type="submit" className="action-tab btn btn-primary" onClick={item => handleDownload("pdf")} style={{ marginLeft: "10px", marginTop: "15px" }} data-toggle="tooltip" data-placement="top" title="Export as PDF file"><i class="fa fa-file-pdf-o"></i></button> */}

                                                                    </div>
                                                                </div>
                                                            </form>



                                                        </div>
                                                    </div>




                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </AdminLayout>
        </>
    );
};
export default Contact;
