import axios from "axios";
// import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import {  displayErrorMessage, displaySuccessMessage,requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";


export const deleteImportDocumentCoordinatorById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteImportDocumentCoordinator",{id},
                                   { headers:requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      const showList = {};
      const resultOutput = getState()
        .IMPORTDOCUMENTCOORDINATOR.showList.result.map((data) => data)
        .filter((data) => data.id !== id);
      showList.result = resultOutput;
      showList.records = getState().IMPORTDOCUMENTCOORDINATOR.showList.records - 1;
      showList.errorCode = 0;
      displaySuccessMessage("importDocumentCoordinatorDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_SUCESS, 
        payload: showList,
      });
    } else {
      displayErrorMessage("importDocumentCoordinatorDeleteError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_ERROR });
  }
};

export const getImportDocumentCoordinatorList= (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslImportDocumentCoordinatorList", {
                                    params: postData,
                                    headers:requestTokenHeader()
                                  });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    return dispatch({ type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_ERROR });
  }
};



export const addImportDocumentCoordinatorDetails = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddImportDocumentCoordinator",postData,
      { headers:requestTokenHeader() });
    if (response.data.errorCode === 0) {
      const resultOutput = getState().importdocumentcoordinatorList.showList.result;
      resultOutput.push(response.data.result);
      const showList = {};
      showList.result = resultOutput;
      showList.records = getState().importdocumentcoordinatorList.showList.records + 1;
      showList.errorCode = 0;
      displaySuccessMessage("importDocumentCoordinatorAdded");
      return dispatch({
        type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_SUCESS,
        payload: showList,
      });
    } else {
      displayErrorMessage("importDocumentCoordinatorAddedError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_ERROR });
  }
};


export const editImportDocumentCoordinatorDetails = (postData, searchIds) => 
 {
 return async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslEditImportDocumentCoordinator",postData,
    {
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("importDocumentCoordinatorUpdated");
      dispatch(getImportDocumentCoordinatorList({ searchIds }));

    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      displayErrorMessage("importDocumentCoordinatorUpdatedError");
      
       dispatch({
        type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
     displayErrorMessage(err.name);
     dispatch({ type: USER_ACTION_TYPE.SET_IMPORTDOCUMENTCOORDINATOR_LIST_ERROR });
  }
 }
};

