import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import {
  getUserListById,
  deleteUserById,
  addUserDetails,
  editUserDetails,
  changeUserPassword,
} from "../../../Services/Store/Common/account/account.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { UserForm } from "./UserForm";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { userListById } from "../../../Services/Store/Common/account/account.selector";
import CONSTANT from '../../../Services/Constant/user.constants';

const Account = () => {
  const id = useParams()?.id;
  const currentUser = useSelector((state) => state.userClient);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [modalData, setModalData] = useState({});
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userListByIdResult = useSelector(userListById);
  const [searchType, setSearchType] = useState(null);
  const [searchIds, setSearchIds] = useState(null);
  const perPage = 25;

  useEffect(() => {
    if (id) {
      if (id === 'search') {
        let search = localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : '';
        if (search?.resource === 'users' && search.ids) {
          setSearchIds(search.ids);
        }
      } else {
        setSearchType('id');
        setSearchKeyword(id);
      }
    } else {
      localStorage.removeItem('search');
      setSearchIds('');
    }
  }, [id]);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));

    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('clientToken');
      navigate("/");
    }
  }, [errorResponce]);

  useEffect(() => {
    if (userListByIdResult.result !== undefined) {
      const result = userListByIdResult.result;
      if (result.length > 0) {
        setUserRecordsData(userListByIdResult.records);
        setErrorResponce(userListByIdResult.error_code);
      } else {
        setUserRecordsData("");
      }
    }
  }, [userListByIdResult]);

  useEffect(() => {
    if(searchKeyword !== null && searchType !== null)
      dispatch(getUserListById({ searchIds, searchType: searchType, search: searchKeyword }));
  }, [searchKeyword, searchType]);

  useEffect(() => {
    if(searchIds !== null) {
      setSearchKeyword(null);
      setSearchType(null);
      dispatch(getUserListById({ searchIds }));
    }
  }, [searchIds]);

  const handlePageClick = (selectedPage) => {
    dispatch(getUserListById({ searchIds, searchType: searchType, search: searchKeyword, offset: selectedPage }));
  };

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (userDetails) => {
    setModalData(userDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleChangePasswordClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setChangePasswordModel(true);

  }

  const _handleAddClick = () => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
      setShowModel(true);
      setAddModel(true);
    }
  };

  const callPageRecords = (userData) => {
    if (userData) {
      return userData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.username}</td>
          <td>{data.firstname}</td>
          <td> {data.lastname}</td>
          <td> {data.email}</td>
          <td> {CONSTANT.roles[data.roleId]}</td>
          <td> {data.status}</td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit User"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            {["admin"].includes(currentUser.user.role) ?
              <button
                type="button"
                className="bd-none btn btn-danger btn-xsm"
                onClick={() => _handleDeleteClick(data.id)}
                data-toggle="tooltip" data-placement="top" title="Delete User"
              >
                <i className="fa fa-fw fa-trash"></i>
              </button> : ""}
            <button
              type="button"
              className="bd-none btn btn-success btn-xsm"
              onClick={() => _handleChangePasswordClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Change Password"
            >
              <i className="fa fa-fw fa-key"></i>
            </button>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={7}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteUser = async (userId) => {
    dispatch(deleteUserById(userId));
    setShowModel(false);
    setDeleteModel(false);
  };

  const changePasswordUser = async (params) => {
    dispatch(changeUserPassword(params));
    setShowModel(false);
    setChangePasswordModel(false);
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submitUserDetails = async (params) => {
    dispatch(addUserDetails(params));
    setShowModel(false);
    setAddModel(false);
  };

  const submitEditUserDetails = async (params) => {
    dispatch(editUserDetails(params, searchIds));
    setShowModel(false);
    setEditModel(false);
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };
  return (
    <>
      <AdminLayout pageHeading={["admin"].includes(currentUser.user.role) ? "Users" : "Profile"} pageIcon={["admin"].includes(currentUser.user.role) ? "fa fa-male" : "fa fa-male"}>
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">{["admin"].includes(currentUser.user.role) ? "User List" : "Update Profile"}</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                  {["admin"].includes(currentUser.user.role) ?
                    <button
                      type="button"
                      className="mb-2 mb-md-0 btn btn-primary"
                      onClick={() => _handleAddClick()}
                    >
                      Add user&nbsp;
                      <i className="fa fa-plus fa-sm"></i>
                    </button>
                    : ""}
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="fillter-wrap d-flex justify-content-end">
                    <select name="selectType" className="form-control me-1" value={searchType ?? ''} onChange={(event) => handleSearhChange(event)}>
                      <option value="">Select Type </option>
                      <option value="firstname">First Name </option>
                      <option value="lastname">Last Name</option>
                      <option value="username">Username</option>
                      <option value="mobile_number">Mobile Number</option>
                      <option value="role">Role</option>
                      <option value="status">Status</option>
                    </select>
                    <input type="search" className="form-control" value={searchKeyword ?? ''} placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Username</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Role</th>
                          <th>Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userListByIdResult !==
                          undefined &&
                          userListByIdResult.result !== "" ? (
                          callPageRecords(
                            userListByIdResult.result
                          )
                        ) : (
                          <tr>
                            <td colSpan={8}>No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {
                    <PaginationBlock
                      perPage={perPage}
                      userRecordsData={userRecordsData}
                      callHandlePageClick={(data) =>
                        handlePageClick(data)
                      }
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <ModalBox
            show={showModel}
            title={deleteModel ? 'Delete User' : (changePasswordModel ? 'Change Password Form' : 'User Form')}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setChangePasswordModel(false);
              setDeleteModel(false);
              setAddModel(false);
            }}
          >
            {editModel === true ? (
              <UserForm
                editUserDetails={(params) => submitEditUserDetails(params)}
                userRole = {currentUser.user.role}
                userDetails={modalData}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={userId}
                deleteUserId={(userId) => deleteUser(userId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : addModel === true ? (
              <UserForm
                userId={userId}
                addUserDetails={(params) => submitUserDetails(params)}
              />
            ) : changePasswordModel === true ? (
              <ChangePasswordForm
                userId={userId}
                changeUserPassword={(params) => changePasswordUser(params)}

              />
            ) : (
              ""
            )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default Account;
