import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
export function RatesForm(props) {

    const [fields, setFields] = useState(props.userDetails ? props.userDetails : {});
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState(false);
    const [applyCheck] = useState(false);

    const validateContactFrom = Yup.object().shape({
        cad_to_usd_rate: Yup.number().required('Field is required'),
        usd_to_cad_rate: Yup.number().required('Field is required'),
        rate_date:Yup.string().required('Field is required')
    });
    let initialValues = {};
    if (fields) {
        initialValues = {
            cad_to_usd_rate: fields.cad_to_usd_rate,
            usd_to_cad_rate:fields.usd_to_cad_rate,
            rate_date: fields.rate_date,
        }

    } else {
        initialValues = {
            cad_to_usd_rate: '',
            usd_to_cad_rate:'',
            rate_date: ""
        }
    }


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            if (fields?.id) {
                const postData = {
                    cad_to_usd_rate: values.cad_to_usd_rate,
                    usd_to_cad_rate:values.usd_to_cad_rate, 
                    rate_date: values.rate_date,                   
                    id: fields.id,
                }
                props.editUserDetails(postData);

            } else {

                const params = {
                    cad_to_usd_rate: values.cad_to_usd_rate,
                    usd_to_cad_rate:values.usd_to_cad_rate, 
                    rate_date: values.rate_date,
                }
                props.addUserDetails(params)
            }


        },
    });



    return (
        <>
            <div className="modal-body invitation-wrap">
                <div className="login-wrap-new">
                    <div className="form-content">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">

                                <div className="col-md-6 col-xs-6">
                                <label>CAD to USD Rate</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="CAD to USD Rate"
                                        id="cad_to_usd_rate"
                                        name="cad_to_usd_rate"
                                        {...formik.getFieldProps('cad_to_usd_rate')}
                                    />
                                    {formik.touched.cad_to_usd_rate && formik.errors.cad_to_usd_rate && (
                                        <div className="errorMsg text-danger">{formik.errors.cad_to_usd_rate}</div>
                                    )}

                                </div>
                                <div className="col-md-6 col-xs-6">
                                <label>USD to CAD Rate</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="USD to CAD Rate"
                                        id="usd_to_cad_rate"
                                        name="usd_to_cad_rate"
                                        {...formik.getFieldProps('usd_to_cad_rate')}
                                    />
                                    {formik.touched.usd_to_cad_rate && formik.errors.usd_to_cad_rate && (
                                        <div className="errorMsg text-danger">{formik.errors.usd_to_cad_rate}</div>
                                    )}

                                </div>
                                
                                <div className="col-md-12 col-xs-12">
                                <label>Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="rate_date"
                                        name="rate_date"
                                        {...formik.getFieldProps('rate_date')}
                                    />
                                    {formik.touched.rate_date && formik.errors.rate_date && (
                                        <div className="errorMsg text-danger">{formik.errors.rate_date}</div>
                                    )}

                                </div>
                                
                            </div>
                            <button type="submit" className="btn btn-primary">
                                {fields.id ? 'Save Rate' : 'Save Rate'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}