import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader, commodityList, getUserIds } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import AddRemoveMultipleInputField from "../../../Components/Ui/AddRemoveMultipleInputFields";
import AddRemoveMultipleVIN from "../../../Components/Ui/AddRemoveMultipleVIN";
import Select from 'react-select'
import moment from "moment";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import { useEffect } from 'react';
import { addNotificationDetails } from "../../../Services/Store/Common/notification/notification.action";
import { useDispatch, useSelector } from "react-redux";

export function DocumentationForm(props) {
    const MAX_COUNT = 5;
    const fileInput = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileLimit, setFileLimit] = useState(false);
    const [jobComments, setJobComments] = useState([]);
    const [selectedOption, setSelectedOption] = useState(props?.jobsDetails?.job_status_id ? props.jobsDetails.job_status_id : "");
    const optionsJobStatus = [];
    const [shipperList, setShipperList] = useState([]);
    const [consigneeList, setConsigneeList] = useState([]);
    const [vin, setVin] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [commentModel, setCommentModel] = useState(false);
    const [files, setFiles] = useState(props?.documentationDataById?.[1]);
    const dispatch = useDispatch();

    props.jobsData?.[6].map((data, index) => {
        return optionsJobStatus.push({ value: data.id, label: data.name });
    });

    useEffect(() => {
        setShipperList([]);
        props.shipperConsignee.length && props.shipperConsignee[0].map((data, index) => {
            setShipperList((prev) => [...prev, { value: data.id, label: data.contact_person }]);
        });
        setConsigneeList([]);
        props.shipperConsignee.length && props.shipperConsignee[1].map((data, index) => {
            setConsigneeList((prev) => [...prev, { value: data.id, label: data.contact_person }]);
        });
    }, [props.shipperConsignee]);

    const handleTypeSelect = e => {
        setSelectedOption(e.value);
    };

    const validateContactFrom = Yup.object().shape({
        job_number: Yup.number().integer().required('Job nubmer is required'),
        bl_number: Yup.string().required('B/L is required'),
        // shipper: Yup.string().required('Shipper is required'),
        consignee: Yup.string().nullable(true),
        commodity: Yup.string().nullable(true),
        service_id: Yup.number().nullable(true),
        released_received_id: Yup.number().nullable(true),
        release_date: Yup.string().nullable(true),
        eta: Yup.string().nullable(),
        steamship_invoice_number: Yup.string().nullable(),
        job_comment: Yup.string().nullable(true),
        job_status_id: Yup.string().nullable(true),
        customer_comment: Yup.string().nullable(true),
        vin: Yup.string().nullable(true),
        documentation_done: Yup.string().nullable(true),
    });

    let initialValues = {};
    if (props?.documentationDataById?.[0][0]) {
        initialValues = {
            id: parseInt(props.documentationDataById[0][0].id),
            job_number: props.documentationDataById[0][0].job_number,
            bl_number: props.documentationDataById[0][0].bl_number,
            shipper: props.documentationDataById[0][0].shipper,
            consignee: props.documentationDataById[0][0].consignee,
            commodity: props.documentationDataById[0][0].commodity,
            released_received_id: props.documentationDataById[0][0].released_received_id,
            release_date: props.documentationDataById[0][0].release_date,
            eta: props.documentationDataById[0][0].eta,
            etd: props.documentationDataById[0][0].etd,
            steamship_invoice_number: props.documentationDataById[0][0].steamship_invoice_number,
            file_head: "",
            job_comment: '',
            job_status_id: props?.jobsDetails?.job_status_id ? props.jobsDetails.job_status_id : "",
            customer_comment: props.documentationDataById[0][0].customer_comment,
            vin: "",
            documentation_done: props.documentationDataById[0][0].documentation_done,
            notification_to_rates: props.documentationDataById[0][0].notification_to_rates,
        }
    } else {
        initialValues = {
            job_number: props.jobsDetails.id,
            bl_number: '',
            shipper: '',
            consignee: '',
            commodity: props?.jobsDetails.commodity,
            released_received_id: '',
            release_date: '',
            eta: '',
            steamship_invoice_number: '',
            file_head: "",
            job_comment: '',
            job_status_id: props?.jobsDetails?.job_status_id ? props.jobsDetails.job_status_id : "",
            customer_comment: "",
            vin: "",
            documentation_done: "",
            notification_to_rates: 0
        }
    }

    async function deleteFiles(id) {
        const response = await api.post("api/v1/tslDeleteFiles", { id: id }, { headers: requestTokenHeader() });
        if (response.data.errorCode === 0) {
            displaySuccessMessage("fileDeletedSuccess");
            document.getElementById(`id-${id}`).remove();
        } else {
            displayErrorMessage("fileDeletedError");
        }
    }

    const _handleDeleteClickFiles = (id) => {
        deleteFiles(id);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            if (props?.documentationDataById?.[0][0]) {
                const formData = new FormData();
                formData.append("id", parseInt(props?.documentationDataById?.[0][0].id));
                formData.append("job_number", parseInt(values.job_number));
                formData.append("bl_number", values.bl_number);
                formData.append("shipper", values.shipper);
                formData.append("consignee", values.consignee);
                formData.append("commodity", values.commodity);
                formData.append("released_received_id", values.released_received_id);
                formData.append("release_date", values.release_date);
                formData.append("eta", values.eta);
                formData.append("etd", values.etd);
                formData.append("steamship_invoice_number", values.steamship_invoice_number);
                if (values?.file_head) {
                    formData.append("file_head", values.file_head);
                }
                if (values?.pdfs) {
                    for (let i = 0; i < values.pdfs.length; i++) {
                        formData.append("pdfs", values.pdfs[i])
                    }
                }
                let jobCommentsData = JSON.stringify((jobComments && Object.keys(jobComments).length !== 0) ? Array.isArray(jobComments) ? jobComments : [].push(jobComments) : []);
                formData.append("job_status_id", selectedOption);
                formData.append("job_comment", jobCommentsData);
                formData.append("customer_comment", values.customer_comment ?? '');
                let vinData = JSON.stringify((vin && Object.keys(vin).length !== 0) ? Array.isArray(vin) ? vin : [].push(vin) : []);

                formData.append("vin", vinData);
                formData.append("shipping_line_id", props?.jobsDetails?.shipping_line_id);
                formData.append("documentation_done", (values.documentation_done == "--Select--" || values.documentation_done == "") ? "No" : values.documentation_done);

                if (values.documentation_done == "--Select--" || values.documentation_done == "No") {
                    formData.append("notification_to_rates", 0);
                }

                if (values.documentation_done == "Yes" && !values.notification_to_rates) {
                    getUserIds({ 'roles.name': 'rates' }, function (ratesUserIds) {
                        ratesUserIds.length && ratesUserIds.map((user_rec, index) => {
                            dispatch(addNotificationDetails({
                                user_id: user_rec.id,
                                resource: 'jobs',
                                resource_id: values.job_number,
                                content: 'Invoice Updated by Doc Team for Job number  #' + props?.jobsDetails?.job_number + ', Update final rates.'
                            }));
                        });
                    });
                    formData.append("notification_to_rates", 1);
                }
                props.editUserDetails(formData);
            } else {
                const formData = new FormData();
                formData.append("job_number", parseInt(values.job_number));
                formData.append("bl_number", values.bl_number);
                formData.append("shipper", values.shipper);
                formData.append("consignee", values.consignee);
                formData.append("commodity", values.commodity);
                formData.append("released_received_id", values.released_received_id);
                formData.append("release_date", values.release_date);
                formData.append("eta", values.eta);
                formData.append("etd", values.etd);
                formData.append("steamship_invoice_number", values.steamship_invoice_number);
                if (values?.file_head) {
                    formData.append("file_head", values.file_head);
                }

                if (values?.pdfs) {
                    for (let i = 0; i < values.pdfs.length; i++) {
                        formData.append("pdfs", values.pdfs[i])
                    }
                }
                let jobCommentsData = JSON.stringify((jobComments && Object.keys(jobComments).length !== 0) ? Array.isArray(jobComments) ? jobComments : [].push(jobComments) : []);

                formData.append("job_comment", jobCommentsData);
                formData.append("job_status_id", selectedOption);
                formData.append("customer_comment", values.customer_comment);
                let vinData = JSON.stringify((vin && Object.keys(vin).length !== 0) ? Array.isArray(vin) ? vin : [].push(vin) : []);

                formData.append("vin", vinData);
                formData.append("shipping_line_id", props?.jobsDetails?.shipping_line_id);
                formData.append("documentation_done", (values.documentation_done == "--Select--" || values.documentation_done == "") ? "No" : values.documentation_done);
                if (values.documentation_done == "--Select--" || values.documentation_done == "No") {
                    formData.append("notification_to_rates", 0);
                }


                if (values.documentation_done == "Yes" && !values.notification_to_rates) {
                    getUserIds({ 'roles.name': 'rates' }, function (ratesUserIds) {
                        ratesUserIds.length && ratesUserIds.map((user_rec, index) => {
                            dispatch(addNotificationDetails({
                                user_id: user_rec.id,
                                resource: 'jobs',
                                resource_id: values.job_number,
                                content: 'Invoice Updated by Doc Team for Job number  #' + props?.jobsDetails?.job_number + ', Update final rates.'
                            }));
                        });
                    });
                    formData.append("notification_to_rates", 1);

                }
                props.addUserDetails(formData)
            }
        },
    });

    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        });
        if (!limitExceeded) setUploadedFiles(uploaded);
    }

    const handleFileEvent = (e) => {
        let chosenFiles = "";
        if (e._reactName == "onChange") {
            chosenFiles = Array.prototype.slice.call(e.target.files);
        } else {
            chosenFiles = Array.prototype.slice.call(e.dataTransfer.files);
        }
        handleUploadFiles(chosenFiles);
    }

    const handelDeleteFiles = (e) => {
        formik.setFieldValue("pdfs", null);
        formik.setFieldValue("file_head", null);
        setUploadedFiles([]);
    }

    const submitJobComment = async (params) => {
        setJobComments(params);
    };

    const submitVin = async (params) => {
        setVin(params);
    };

    async function deleteVin(id) {
        const response = await api.post("api/v1/tslDeleteVin", { id: id }, { headers: requestTokenHeader() });
        if (response.data.errorCode === 0) {
            displaySuccessMessage("jobVinDeletedSuccess");
            document.getElementById(`id-${id}`).remove();
        } else {
            displayErrorMessage("jobVinDeletedError");
        }
    }

    const _handleDeleteVin = (id) => {
        deleteVin(id);
    };

    async function deleteComments(id) {
        const response = await api.post("api/v1/tslDeleteJobComments", { id: id }, { headers: requestTokenHeader() });
        if (response.data.errorCode === 0) {
            displaySuccessMessage("jobCommentDeletedSuccess");
            document.getElementById(`id-${id}`).remove();
        } else {
            displayErrorMessage("jobCommentDeletedError");
        }
    }

    const _handleDeleteComments = (id) => {
        deleteComments(id);
    };

    const handleOndragOver = event => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        let myFiles = [];
        if(formik.values.pdfs !== undefined) {
            myFiles = formik.values.pdfs;
        }
        myFiles = myFiles.concat(Array.from(files));
        formik.setFieldValue("pdfs", myFiles);
        handleFileEvent(event);
    }

    const handleOndrop = event => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let files = event.dataTransfer.files;
        let myFiles = [];
        if(formik.values.pdfs !== undefined) {
            myFiles = formik.values.pdfs;
        }
        myFiles = myFiles.concat(Array.from(files));
        formik.setFieldValue("pdfs", myFiles);
        handleFileEvent(event);
    }

    const _handleEditClickFiles = (item) => {
        setCommentModel(item);
        setShowModel(true);
    };

    const handleCommentChange = (e) => {
        let data = commentModel;
        data["job_comment"] = e.target.value;
        setCommentModel({ ...data });
    }

    async function fetchMyAPI2(data) {
        const response = await api.post("api/v1/tslEditJobComment", { id: data.id, user_id: data.user_id, job_comment: data.job_comment }, { headers: requestTokenHeader() });
        if (response.data.errorCode === 0) {
            displaySuccessMessage("jobCommentUpdateSuccess");
            setShowModel(false);
            setCommentModel(false);
            document.getElementById(`id-${data.id}`).children[0].innerHTML = data.job_comment;
        } else {
            displayErrorMessage("jobCommentUpdatedError");
            setShowModel(false);
            setCommentModel(false);
        }
    }

    const editComment = () => {
        fetchMyAPI2(commentModel)
    }

    async function handleApproveFile(file_id, index) {
        const response = await api.post("api/v1/tslApproveFile", { id: file_id }, { headers: requestTokenHeader() });
        if (response.data.errorCode === 0) {
            var newFiles = [...files];
            var file = files[index];
            newFiles[index] = { ...file, approved: '1' };
            setFiles(newFiles);
            displaySuccessMessage("File Approved.");
            dispatch(addNotificationDetails({
                resource: 'customer/dashboard',
                resource_id: props.jobsDetails.id,
                content: file.type + ' file approved for Job #' + props.jobsDetails.job_number,
                customer_id: props.jobsDetails.customer_id
            }));
        } else {
            displayErrorMessage("API Error, Please try again");
        }
    }



    return (
        <>
            <div className="modal-body">
                <ul className="required-details">
                    <li>Job No: <code >{props.jobsDetails.job_number}</code> </li>
                    <li>Job For: <code >{props.jobsDetails.job_for}</code> </li>
                    <li>Booking No: <code >{props.jobsDetails.booking_number}</code> </li>
                    <li>Container No: <code >{props.jobsDetails.container_number}</code></li>
                    <li>Customer: <code >{props.jobsDetails.firstname + (props.jobsDetails.lastname ? ' ' + props.jobsDetails.lastname : '')}</code> </li>
                    <li>Carrier: <code> {props.jobsDetails.carriers_name}</code></li>
                    <li>Added: <code > {moment(props.jobsDetails.created_at).format("YYYY-MM-DD")}</code></li>
                    <li>Updated: <code > {moment(props.jobsDetails.updated_at).format("YYYY-MM-DD")}</code></li>
                    <li>Trucker: <code> {props.jobsDetails.trucker_name}</code></li>
                    <li>Doc CutOff Date: <code > {props.jobsDetails.doc_cutoff_date && props.jobsDetails.doc_cutoff_date !== '0000-00-00' ? props.jobsDetails.doc_cutoff_date : ''}</code></li>
                    <li>Vgm CutOff Date: <code > {props.jobsDetails.vgm_cutoff_date && props.jobsDetails.vgm_cutoff_date !== '0000-00-00' ? props.jobsDetails.vgm_cutoff_date : ''}</code></li>
                    <li>Cargo CutOff Date: <code > {props.jobsDetails.cargo_curtoff_date && props.jobsDetails.cargo_curtoff_date !== '0000-00-00' ? props.jobsDetails.cargo_curtoff_date : ''}</code></li>
                </ul>
                <div className="row login-wrap-new">
                    <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                        <div className="form-content">
                            <div className="row">
                                <div className="col-sm-3 mb-2">
                                    <label>B/L No.</label>
                                    <input type="hidden" name="job_number" id="job_number"
                                        {...formik.getFieldProps('job_number')}
                                    />
                                    <input className="form-control"
                                        name="bl_number"
                                        id="bl_number"
                                        {...formik.getFieldProps('bl_number')}
                                    />
                                    {formik.touched.bl_number && formik.errors.bl_number && (
                                        <div className="errorMsg text-danger">{formik.errors.bl_number}</div>
                                    )}
                                </div>
                                <div className="col-sm-3 mb-2">
                                    <label>Shipper</label>
                                    <Select
                                        name="shipper"
                                        options={shipperList}
                                        onChange={(selectedOption) => formik.setFieldValue('shipper', selectedOption.value)}
                                        value={shipperList.find(function (option) { return option.value == formik.values.shipper })}
                                        label="Single select"
                                    />
                                    {formik.touched.shipper && formik.errors.shipper && (
                                        <div className="errorMsg text-danger">{formik.errors.shipper}</div>
                                    )}
                                </div>
                                <div className="col-sm-3 mb-2">
                                    <label>Consignee</label>
                                    <Select
                                        name="consignee"
                                        options={consigneeList}
                                        onChange={(selectedOption) => formik.setFieldValue('consignee', selectedOption.value)}
                                        value={consigneeList.find(function (option) { return option.value == formik.values.consignee })}
                                        label="Single select"
                                    />
                                    {formik.touched.consignee && formik.errors.consignee && (
                                        <div className="errorMsg text-danger">{formik.errors.consignee}</div>
                                    )}
                                </div>
                                <div className="col-sm-3 mb-2">
                                    <label>Commodity</label>
                                    <select className="form-control"
                                        name="commodity"
                                        {...formik.getFieldProps('commodity')}
                                    >
                                        <option>Select</option>
                                        {
                                            commodityList.map((item, index) => {
                                                return <option key={index} value={item}>{item}</option>
                                            })
                                        }
                                    </select>
                                    {formik.touched.commodity && formik.errors.commodity && (
                                        <div className="errorMsg text-danger">{formik.errors.commodity}</div>
                                    )}
                                </div>
                                <div className="col-sm-3 mb-2">
                                    <label>Release Rcvd.</label>
                                    <select className="form-control"
                                        name="released_received_id"
                                        id="released_received_id"
                                        {...formik.getFieldProps('released_received_id')}
                                    >
                                        <option>--Select Bl Rcvd--</option>
                                        {props.documentationData ?
                                            props.documentationData.map((data, index) => (
                                                <option value={data.id}>{data.name}</option>
                                            )) : ''}
                                    </select>
                                    {formik.touched.released_received_id && formik.errors.released_received_id && (
                                        <div className="errorMsg text-danger">{formik.errors.released_received_id}</div>
                                    )}
                                </div>
                                <div className="col-sm-3 mb-2">
                                    <label>Release Date</label>
                                    <input className="form-control"
                                        type="date"
                                        name="release_date"
                                        id="release_date"
                                        {...formik.getFieldProps('release_date')}
                                    />
                                    {formik.touched.release_date && formik.errors.release_date && (
                                        <div className="errorMsg text-danger">{formik.errors.release_date}</div>
                                    )}
                                </div>
                                <div className="col-sm-3 mb-2">
                                    <label>ETA</label>
                                    <input className="form-control"
                                        type="date"
                                        name="eta"
                                        id="eta"
                                        {...formik.getFieldProps('eta')}
                                    />
                                    {formik.touched.eta && formik.errors.eta && (
                                        <div className="errorMsg text-danger">{formik.errors.eta}</div>
                                    )}
                                </div>
                                <div className="col-sm-3 mb-2">
                                    <label>ETD</label>
                                    <input className="form-control"
                                        type="date"
                                        name="etd"
                                        id="etd"
                                        {...formik.getFieldProps('etd')}
                                    />
                                    {formik.touched.etd && formik.errors.etd && (
                                        <div className="errorMsg text-danger">{formik.errors.etd}</div>
                                    )}
                                </div>
                                <div className="col-sm-3 mb-2">
                                    <label>Steamship Invoice No.</label>
                                    <input className="form-control"
                                        type="text"
                                        name="steamship_invoice_number"
                                        id="steamship_invoice_number"
                                        {...formik.getFieldProps('steamship_invoice_number')}
                                    />
                                    {formik.touched.steamship_invoice_number && formik.errors.steamship_invoice_number && (
                                        <div className="errorMsg text-danger">{formik.errors.steamship_invoice_number}</div>
                                    )}
                                </div>
                                <div className=" col-sm-3 mb-3">
                                    <label>Job Status</label>
                                    <Select
                                        name='job_status_id'
                                        options={optionsJobStatus}
                                        onChange={handleTypeSelect}
                                        value={optionsJobStatus.filter(function (option) {
                                            return option.value === (selectedOption == "" ? props.jobsDetails.job_status_id : selectedOption);
                                        })}
                                        label="Single select"
                                    />
                                </div>

                                <div className=" col-sm-3 mb-3">
                                    <label>Documentation Complete</label>
                                    <select className="form-control"
                                        name="documentation_done"
                                        id="documentation_done"
                                        {...formik.getFieldProps('documentation_done')}
                                    >
                                        <option>--Select--</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    {formik.touched.documentation_done && formik.errors.documentation_done && (
                                        <div className="errorMsg text-danger">{formik.errors.documentation_done}</div>
                                    )}
                                </div>

                            </div>

                            <div className="row" style={{ display: props?.documentationDataById?.[0][0] ? "" : "none" }} >
                                {files && files.map((item, idx) => {
                                    return (
                                        <div key={idx} id={`id-${item.id}`} className="col-lg-3 col-sm-6">
                                            <div className='uploded-files'>
                                                <p className='d-flex justify-content-between align-items-center mb-0'>
                                                    <span>{item.type}</span>
                                                    {
                                                        item.uploaded_by !== 'customer' ?
                                                            item.approved === '1' ?
                                                                <span className='badge badge-success p-1'>Approved</span>
                                                                : <span className='d-flex align-content-center small'>
                                                                    <input type='checkbox' onClick={() => handleApproveFile(item.id, idx)} />
                                                                    <span className='ms-1 text-info'>Approve</span>
                                                                </span>
                                                            : ''
                                                    }
                                                </p>
                                                <p className='d-flex justify-content-between mb-0 mt-2'>
                                                    <code title={item.file_name}>
                                                        <a href={window.location.hostname == "localhost" ? `http://localhost:3001/Images/uploads/${item.file_name}` : `http://golden-test.com:3001/Images/uploads/${item.file_name}`} target="_blank">{item.file_name}</a>
                                                    </code>
                                                    <a className="remove-file me-1" onClick={() => _handleDeleteClickFiles(item.id)}><i className="fa fa-trash"></i></a>
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="row">
                                <div className=" col-sm-12">
                                    <div className="mt-4">
                                        <div
                                            className="drop_zone"
                                            onDragOver={handleOndragOver}
                                            onDrop={handleOndrop}
                                            onClick={() => fileInput.current.click()}
                                        >
                                            <p>Drag and drop files here....</p>
                                            <input
                                                multiple
                                                type="file"
                                                name="pdfs"
                                                id="pdfs123"
                                                // accept="application/pdf"
                                                ref={fileInput} hidden
                                                onChange={(event) => {
                                                    const files = event.target.files;
                                                    let myFiles = [];
                                                    if(formik.values.pdfs !== undefined) {
                                                        myFiles = formik.values.pdfs;
                                                    }
                                                    myFiles = myFiles.concat(Array.from(files));
                                                    formik.setFieldValue("pdfs", myFiles);
                                                    handleFileEvent(event);
                                                }}
                                                disabled={fileLimit}
                                            />
                                        </div>

                                    </div>
                                    {formik.touched.released_received_id && formik.errors.released_received_id && (
                                        <div className="errorMsg text-danger">{formik.errors.released_received_id}</div>
                                    )}
                                </div>
                            </div>

                            {uploadedFiles.length > 0 && (<div className="close fileinput-remove" onClick={handelDeleteFiles}>×</div>)}
                            {uploadedFiles.length > 0 && (<div className="row" style={{ marginTop: "50px", border: "1px solid", paddingTop: "10px", marginBottom: "20px" }}>
                                {uploadedFiles.map((file, index) => (
                                    <div className=" col-sm-2">
                                        <div className="form-group">
                                            <span className="btn-default btn-file file-upload-back">
                                                <div className="file-input" style={{ border: "1px solid", borderRadius: "5px", padding: "2px" }}>
                                                    <div className="file-preview">
                                                        <div className="file-drop-disabled">
                                                            <div className="file-preview-thumbnails">
                                                                <div className="file-preview-frame" id="preview-1692267561537-2" data-fileindex="2" title="Policy Document.pdf" style={{ height: "160px" }}>
                                                                    <select
                                                                        className="form-control file-caption-name"
                                                                        name={`file_head[${index}]`} required
                                                                        {...formik.getFieldProps(`file_head[${index}]`)}
                                                                    >
                                                                        <option value="">--Select Heading--</option>
                                                                        <option value="1">Booking Confirmation</option>
                                                                        <option value="2">Loading Sheet</option>
                                                                        <option value="3">BL Draft</option>
                                                                        <option value="4">B13</option>
                                                                        <option value="5">Invoice</option>
                                                                        <option value="6">Release</option>
                                                                        <option value="7">Email Record</option>
                                                                        {/* <option value="8">IMO</option> */}
                                                                        {/* <option value="9">Shipping Instructions</option> */}
                                                                        {/* <option value="10">Dummy</option> */}
                                                                        <option value="11">ECTN</option>
                                                                        {/* <option value="12">Certificate of origin</option> */}
                                                                        {/* <option value="13">BL Draft-6</option>
                                                                        <option value="14">B13-6</option>
                                                                        <option value="15">Loading sheet-4</option>
                                                                        <option value="16">Invoices-8</option>
                                                                        <option value="17">Release-2</option> */}
                                                                        {formik.values.commodity === "Vehicles" && <option value="18">VIN</option>}
                                                                    </select>
                                                                    <object className="file-object" data={URL.createObjectURL(file)} type="application/pdf" width="160px" height="160px"></object>
                                                                </div>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                            <div className="file-preview-status text-center text-success"></div>
                                                            <div className="kv-fileinput-error file-error-message" ></div>
                                                        </div>
                                                    </div>

                                                    <div className="input-group ">
                                                        <div tabIndex="500" className="form-control file-caption  kv-fileinput-caption">
                                                            <div className="file-caption-name" title={file.name} wq>
                                                                <span className="glyphicon glyphicon-file kv-caption-icon"></span>{file.name.length > 22 ? file.name.substring(0, 22 - 3) + "..." : file.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>)}

                            <div className='row'>
                                <div className="col-md-12">
                                    <label>Customer Comment</label>
                                    <textarea
                                        className="form-control"
                                        name="customer_comment"
                                        id="customer_comment"
                                        {...formik.getFieldProps('customer_comment')}
                                        readOnly={true}
                                    ></textarea>
                                    {formik.touched.customer_comment && formik.errors.customer_comment && (
                                        <div className="errorMsg text-danger">{formik.errors.customer_comment}</div>
                                    )}
                                </div>

                            </div>

                            <div className="row" >
                                <div className=" col-sm-12">
                                    <AddRemoveMultipleInputField
                                        addJobComment={(params) => submitJobComment(params)}
                                    />
                                </div>
                            </div>

                            {props?.jobComments && props?.jobComments.map((item, idx) => {
                                return (
                                    <div key={idx} id={`id-${item.id}`} className="row align-items-center" style={{ display: props?.jobComments ? "" : "none" }} >
                                        <div className="col-md-10">
                                            <div className='comment-box'>
                                                {/* <div className='img-blocks d-none'>
                                                    <img src="https://dashcode-react.codeshaper.net/assets/user-1.ad58ce72.jpg" />
                                                </div> */}

                                                <p className='p-0 m-0'>
                                                    <span className='d-block date'>{moment(item.created_at).format("YYYY-MM-DD")}</span>
                                                    {item.job_comment}
                                                </p>
                                                <div className='del-button'>
                                                    <a
                                                        className="remove-file comment d-none"
                                                        onClick={() => _handleEditClickFiles(item)}
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </a>
                                                    <a
                                                        className="remove-file del-comment"
                                                        onClick={() => _handleDeleteComments(item.id)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            {formik.values.commodity === "Vehicles" && <div className="row" >
                                <div className=" col-sm-12">
                                    <AddRemoveMultipleVIN
                                        addJobComment={(params) => submitVin(params)}
                                    />
                                </div>
                            </div>}

                            {formik.values.commodity === "Vehicles" && props?.jobVin && props?.jobVin.map((item, idx) => {
                                   let apiResponse = JSON.parse(item.response);

                                   const entries = (apiResponse ?.[1] && apiResponse ?.[1] != "") ? JSON.stringify(apiResponse[1]) : "" ;

                                   return (
                                    <div key={idx} id={`id-${item.id}`} className="row align-items-center" style={{ display: props?.jobVin ? "" : "none" }}>
                                        <div className="col-md-10">
                                            <div className='comment-box'>
                                                {/* <div className='img-blocks d-none'>
                                                <img src="https://dashcode-react.codeshaper.net/assets/user-1.ad58ce72.jpg" />
                                            </div> */}
                                                <p className='p-0 m-0'>
                                                    <span className='d-block date'>{moment(item.created_at).format("YYYY-MM-DD")}</span>
                                                    <span className='d-block date'>{entries}</span>
                                                   


                                                    {item.vin_number}</p>


                                                <div className='del-button'>
                                                    <a
                                                        className="remove-file del-comment"
                                                        onClick={() => _handleDeleteVin(item.id)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            <div className='row mt-4'>
                                <div className="col-sm-4">
                                    <input type="submit" name="submit" className="btn btn-primary" value={props?.documentationDataById?.[0][0] ? "Save Documentation" : "Save Documentation"} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ModalBox
                show={showModel}
                size={'sm'}
                title="Edit Comment"
                onHide={() => {
                    setShowModel(false);
                    setCommentModel(false);
                }}
            >
                {
                    commentModel ?
                        (<div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <label>Job comment</label>
                                        <input type="text" className="form-control" placeholder="Job comment" value={commentModel.job_comment} onChange={(e) => handleCommentChange(e)} />
                                    </div>

                                    <div className="col-md-12 mt-3 text-center">
                                        <button className="btn btn-primary text-white" onClick={() => editComment()}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>) :
                        (
                            ""
                        )}
            </ModalBox>
        </>
    );
}