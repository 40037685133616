import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
  editTaskDetails,
  addTaskDetails
} from "../../../Services/Store/Common/tasks/task.action";


import { CalendarForm } from "./CalendarForm";
import AdminLayout from "../../../Layout";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import { TaskForm } from "../Tasks/TaskForm";
import { Bar, Pie, Line } from 'react-chartjs-2';
import { userData } from './userData.js';
import { lineUserData } from './lineUserData.js';
import Chart from 'chart.js/auto';
const Calendar = () => {
  const currentUser = useSelector((state) => state.userClient);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [taskList, setTaskList] = useState([]);
  const [flag, setFlag] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accountsData, setAccountsData] = useState('');
  const [displayPopUp, setDisplayPopUp] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [quoteData, setQuoteData] = useState([]);
  const [chartBarData, setChartBarData] = useState({});
  const [chartPieData, setChartPieData] = useState({});

  useEffect(() => {
    async function fetchMyAPIAccounts() {
      const response = await api.get("api/v1/tslaccountsData", {
        params: "",
        headers: requestTokenHeader()
      });
      if (response?.data?.errorCode === 0) {

        if (["admin", "accounts", "rates"].includes(currentUser.user.role)) {
          setDisplayPopUp(false);
        }

      } else {
        if (["admin", "accounts", "rates"].includes(currentUser.user.role)) {
          let returningUser = localStorage.getItem("seenPopUp");
          setDisplayPopUp(!returningUser);
        }
      }
    }

    async function fetchDashboardData() {
      const response = await api.get("api/v1/tslDashboard", {
        params: "",
        headers: requestTokenHeader()
      });
      if (response?.data?.errorCode === 0) {
        setDashboardData(response.data.data);
      } else {
        setDashboardData([]);
      }
    }

    async function fetchBookingByStatus() {
      const response = await api.get("api/v1/tslBookingByStatus", {
        params: "",
        headers: requestTokenHeader()
      });
      if (response?.data?.errorCode === 0) {
        setChartPieData(item => {
          return {
            labels: (response?.data?.data).map((data) => {
              let obj = data[0];
              return Object.keys(obj)[0];
            }),
            datasets: [
              {
                label: 'My First Dataset',
                data: (response?.data?.data).map((data) => {
                  let obj = data[0];
                  return obj[Object.keys(obj)[0]]
                }),
                backgroundColor: [
                  'rgb(255, 0, 71)',
                  'rgb(255, 86, 71)',
                  'rgb(255, 160, 71)',
                  'rgb(255, 160, 161)',
                  'rgb(182, 160, 161)',
                  'rgb(112, 160, 161)',
                  'rgb(45, 160, 161)',
                  'rgb(45, 160, 2)',
                  'rgb(45, 160, 67)',
                  'rgb(45, 160, 119)',
                  'rgb(45, 160, 206)',
                  'rgb(45, 163, 206)',
                  'rgb(141, 163, 206)',
                  'rgb(193, 163, 206)',
                  'rgb(255, 163, 206)',
                  'rgb(255, 60, 206)',
                  'rgb(255, 140, 206)',
                  'rgb(255, 98, 206)',
                  'rgb(255, 98, 90)',
                ],
                hoverOffset: 0,
                borderWidth: 0,
              },
            ],
          }
        });
      }
    }
    fetchBookingByStatus();
    fetchMyAPIAccounts();
    fetchDashboardData();
  }, []);

  useEffect(() => {
    async function fetchMyAPI2() {
      const response = await api.get("api/v1/tslCustomerListById", {
        params: { offset: 0, limit: 10 },
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setCustomerData(response?.data?.data);
      } else {
        setCustomerData([]);
      }
    }

    fetchMyAPI2();

  }, []);

  useEffect(() => {
    async function fetchMyAPI3() {
      const response = await api.get("api/v1/tslBookingPerMonthReportingList", {
        params: { offset: 0, limit: 10 },
        headers: requestTokenHeader()
      });
      if (response?.data?.errorCode === 0) {

        setChartBarData(item => {

          return {
            labels: (response?.data?.data).map((data) => data.month + " " + data.year),
            datasets: [
              {
                label: "Bookings ",
                data: (response?.data?.data).map((data) => data.count),
                backgroundColor: 'rgb(255, 180, 0)',
                hoverBackgroundColor: '#FFE559',
                borderColor: 'rgb(0, 255, 0)',
                borderWidth: 0,
                order: 1,
                borderRadius: 5,
              },
              // {
              //   axis: "xAxis",
              //   type: 'line',
              //   label: 'Dataset 1',
              //   borderColor: '#9055FD',
              //   pointBackgroundColor: '#fff',
              //   borderWidth: 3,
              //   pointRadius: 5,
              //   pointHoverRadius: 8,
              //   order: 0,
              //   lineTension: 0.5,
              //   bezierCurve: true,
              //   fill: false,
              //   data: lineUserData.map((data) => data.userGain),
              // },
            ]
          }


        });
      } else {
        setChartBarData({});
      }
    }

    fetchMyAPI3();

  }, []);

  useEffect(() => {
    async function fetchMyAPI2() {
      const response = await api.get("api/v1/tslQuotes", {
        params: { offset: 0, limit: 10 },
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setQuoteData(response?.data?.data);
      } else {
        setQuoteData([]);
      }
    }

    fetchMyAPI2();

  }, []);
  const closePopUp = () => {
    localStorage.setItem("seenPopUp", true);
    setDisplayPopUp(false);
  };

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }
  }, []);

  const userInfo = { id: currentUser.user.id };
  useEffect(() => {
    async function fetchMyAPI2() {
      const response = await api.get("api/v1/tslTaskListById", {
        params: userInfo,
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setTaskList(response?.data?.data);
      } else {
        setTaskList("");
      }
    }

    fetchMyAPI2();

  }, [flag]);

  const handleEventClick = (clickInfo) => {
    if (clickInfo.event.extendedProps.type == "task") {
      setModalData({
        name: clickInfo.event.title,
        description: clickInfo.event.extendedProps.description,
        due_date: clickInfo.event.extendedProps.due_date,
        status: clickInfo.event.extendedProps.status,
        id: clickInfo.event.id,
        assigned_to: clickInfo.event.extendedProps.assigned_to,
        type: clickInfo.event.extendedProps.type,
        user_comment: clickInfo.event.extendedProps.user_comment,
      })
    } else {

      setModalData({
        name: clickInfo.event.title,
        description: clickInfo.event.extendedProps.description,
        assigned_to: clickInfo.event.extendedProps.assigned_to,
        event_categories: clickInfo.event.extendedProps.event_categories,
        type: clickInfo.event.extendedProps.type,
        visibility: clickInfo.event.extendedProps.visibility,
        id: clickInfo.event.id,
        reminder: clickInfo.event.extendedProps.reminder,
      })

    }

    setShowModel(true);
    setAddModel(false);
  }

  const submitEditUserDetails = async (params) => {
    dispatch(editTaskDetails(params));
    setShowModel(false);
    setModalData({});
    setAddModel(false);
  };

  const _handleAddClick = () => {
    setShowModel(false);
    setAddModel(true);
  };

  const submitUserDetails = async (params) => {
    dispatch(addTaskDetails(params));
    setShowModel(false);
    setAddModel(false);
    setModalData({});
    setFlag(state => state + 1)

  };
  return (
    <>
      <AdminLayout pageHeading="Calendar" pageIcon="fa fa-calendar">
        <div className="container-fluid">
          {/* <h1 className="h3 mb-4 text-gray-800">Your Calendar</h1> */}

          <div className="row">

            {["admin", "accounts"].includes(currentUser.user.role) ?
              <div className="col-sm-6 col-lg-3 mb-4">
                <div className="card card-border-shadow-warning h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2 pb-1">
                      <div className="avatar me-2">
                        <span className="avatar-initial rounded bg-label-warning">
                          <i className="mdi mdi-scale-balance mdi-20px"></i></span>
                      </div>
                      <h4 className="ms-1 mb-0 display-7 fw-bold">{dashboardData?.truckerBalance ? parseFloat(dashboardData?.truckerBalance).toFixed(2) : ''} USD</h4>
                    </div>
                    <p className="mb-0 text-heading">Trucker Balance</p>

                  </div>
                </div>
              </div>
              : ''
            }

            {["admin", "accounts"].includes(currentUser.user.role) ?
              <div className="col-sm-6 col-lg-3 mb-4">
                <div className="card card-border-shadow-warning h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2 pb-1">
                      <div className="avatar me-2">
                        <span className="avatar-initial rounded bg-label-warning">
                          <i className="mdi mdi-scale-balance mdi-20px"></i></span>
                      </div>
                      <h4 className="ms-1 mb-0 display-7 fw-bold">{dashboardData?.customerBalance ? parseFloat(dashboardData?.customerBalance).toFixed(2) : ''} USD</h4>
                    </div>
                    <p className="mb-0 text-heading">Customer Balance</p>

                  </div>
                </div>
              </div>
              : ''
            }

            {["admin", "accounts"].includes(currentUser.user.role) ?
              <div className="col-sm-6 col-lg-3 mb-4">
                <div className="card card-border-shadow-danger h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2 pb-1">
                      <div className="avatar me-2">
                        <span className="avatar-initial rounded bg-label-danger">
                          <i className="mdi mdi-account-cash mdi-20px"></i>
                        </span>
                      </div>
                      <h4 className="ms-1 mb-0 display-7 fw-bold">{dashboardData?.accountReceivable ? parseFloat(dashboardData?.accountReceivable).toFixed(2) : ''} USD</h4>
                    </div>
                    <p className="mb-0 text-heading">Account Receivable</p>

                  </div>
                </div>
              </div>
              : ''
            }

            {["admin", "accounts"].includes(currentUser.user.role) ?
              <div className="col-sm-6 col-lg-3 mb-4">
                <div className="card card-border-shadow-info h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2 pb-1">
                      <div className="avatar me-2">
                        <span className="avatar-initial rounded bg-label-info"><i className="mdi mdi-scale-unbalanced mdi-20px"></i></span>
                      </div>
                      <h4 className="ms-1 mb-0 display-7 fw-bold">{dashboardData?.shippingLineBalance ? parseFloat(dashboardData?.shippingLineBalance).toFixed(2) : ''} USD</h4>
                    </div>
                    <p className="mb-0 text-heading">Shipping Line Balance</p>
                  </div>
                </div>
              </div>


              : ''
            }

          </div>
          <div className="row">

            {["admin", "documentation", "booking", "sales", "rates", "manager", "accounts"].includes(currentUser.user.role) ?
              <div className="col-sm-6 col-lg-3 mb-4">
                <div className="card card-border-shadow-primary h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2 pb-1">
                      <div className="avatar me-2">
                        <span className="avatar-initial rounded bg-label-primary"><i className="mdi mdi-bell-ring mdi-20px"></i></span>
                      </div>
                      <h4 className="ms-1 mb-0 display-7 fw-bold">{dashboardData?.releaseRequested}</h4>
                    </div>
                    <p className="mb-0 text-heading">Release Requested</p>

                  </div>
                </div>
              </div>
              : ''
            }



            {["admin", "documentation", "booking", "sales", "rates", "manager", "accounts"].includes(currentUser.user.role) ?
              <div className="col-sm-6 col-lg-3 mb-4">
                <div className="card card-border-shadow-danger h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2 pb-1">
                      <div className="avatar me-2">
                        <span className="avatar-initial rounded bg-label-danger">
                          <i className="mdi mdi-account-check mdi-20px"></i>
                        </span>
                      </div>
                      <h4 className="ms-1 mb-0 display-7 fw-bold">{dashboardData?.jobClosure}</h4>
                    </div>
                    <p className="mb-0 text-heading">Job Closure</p>

                  </div>
                </div>
              </div>
              : ''
            }

            {["admin", "documentation", "booking", "sales", "rates", "manager", "accounts"].includes(currentUser.user.role) ?
              <div className="col-sm-6 col-lg-3 mb-4">
                <div className="card card-border-shadow-danger h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2 pb-1">
                      <div className="avatar me-2">
                        <span className="avatar-initial rounded bg-label-danger">
                          <i className="mdi mdi-note-multiple-outline mdi-20px"></i>
                        </span>
                      </div>
                      <h4 className="ms-1 mb-0 display-7 fw-bold">{dashboardData?.sIPending}</h4>
                    </div>
                    <p className="mb-0 text-heading">SI PENDING</p>

                  </div>
                </div>
              </div>
              : ''
            }

            {["admin", "accounts"].includes(currentUser.user.role) ?
              <div className="col-sm-6 col-lg-3 mb-4">
                <div className="card card-border-shadow-primary h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2 pb-1">
                      <div className="avatar me-2">
                        <span className="avatar-initial rounded bg-label-primary"><i className="mdi mdi-bus-school mdi-20px"></i></span>
                      </div>
                      <h4 className="ms-1 mb-0 display-7 fw-bold">{dashboardData?.bookingInAMonth?.current_month_count}</h4>
                    </div>
                    <p className="mb-0 text-heading">Bookings</p>
                    <p className="mb-0">
                      <span className="me-1">{
                        dashboardData?.bookingInAMonth?.previous_month_count ? parseFloat((dashboardData?.bookingInAMonth?.current_month_count - dashboardData?.bookingInAMonth?.previous_month_count) * 100 / dashboardData?.bookingInAMonth?.previous_month_count).toFixed(2) : 100
                      }%</span>
                      <small className="text-muted">than last month</small>
                    </p>
                  </div>
                </div>
              </div> : ''
            }

          </div>

          <div className="row">
            <div className="col-lg-6 col-xxl-6 mb-4">
              <div className="card shadow">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0">New Customer List</h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table card-table">
                      <thead>
                        <tr>
                          <th>Customer Name</th>
                          <th>Mob. Number</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0">
                        {customerData && customerData.map((data, index) => {
                          return (
                            <tr>
                              <td className="ps-0">
                                <div className="d-flex justify-content-start align-items-center">
                                  <div className="me-2">
                                    <i className="text mdi mdi-account text-secondary"></i>
                                  </div>
                                  <h6 className="mb-0 fw-normal truncate">{data.firstname + (data.lastname ? ' ' + data.lastname : '')}</h6>
                                </div>
                              </td>
                              <td className="pe-0 text-nowrap">
                                <h6 className="mb-0 truncate">{data.mobile_number}</h6>
                              </td>
                              <td className="pe-0">
                                <span className="truncate">{data.email}</span>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xxl-6 mb-4">
              <div className="card shadow mb-4 h-100">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <div className="card-title mb-0">
                    <h6 className="m-0">Bookings in a year</h6>
                    <small className="text-body">booking count month wise</small>
                  </div>

                </div>
                <div className="card-body">
                  <div className="chart text-center h-100">
                    {!(Object.keys(chartBarData).length === 0 && chartBarData.constructor === Object) && <Bar
                      data={chartBarData}
                      options={{
                        plugins: {
                          title: {
                            display: true,
                            text: "Bookings"
                          },
                          legend: {
                            display: false
                          }
                        }
                      }}
                    />}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xxl-4 mb-4">
              <div className="card h-100">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <div className="card-title mb-0">
                    <h5 className="m-0 me-2">Quote Request</h5>
                  </div>

                </div>
                <div className="card-body">
                  <div className="table table-responsive">
                    <table className="p-0 m-0 table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Quote No.</th>
                          <th>Commodity</th>
                          <th>Weight</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quoteData && quoteData.map((data, index) => {

                          return (
                            <tr>
                              <td><i className="mdi mdi-timer-outline mdi-24px text-secondary"></i></td>
                              <td>{data.quote_number}</td>
                              <td>{data.item_type}</td>
                              <td>{data.cargo_weight} {data.unit}</td>
                            </tr>
                          )


                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xxl-4 mb-4">
              <div className="card h-100">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <div className="card-title mb-0">
                    <h5 className="m-0 me-2">Bookings as per job status</h5>
                    <small className="text-body">Bookings count as per job status</small>
                  </div>

                </div>
                <div className="card-body">
                  <div className="chart text-center h-100">
                    {!(Object.keys(chartPieData).length === 0 && chartPieData.constructor === Object) && <Pie
                      data={chartPieData}
                      options={{
                        borderRadius: 0,
                        aspectRatio: 1,
                      }}
                    />}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xxl-4 mb-4">
              <div className="card h-100">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <div className="card-title mb-0">
                    <h5 className="m-0 me-2">Title will be here</h5>
                    <small className="text-body">subtitle will be here</small>
                  </div>
                  <div className="dropdown">
                    <button className="btn p-0" type="button" id="ordersCountries" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="mdi mdi-dots-vertical mdi-24px"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end" aria-labelledby="ordersCountries">
                      <a className="dropdown-item " href="#!;">Title</a>
                      <a className="dropdown-item " href="#!;">Title</a>
                      <a className="dropdown-item " href="#!;">Title</a>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="nav-align-top">
                    <ul className="nav nav-tabs nav-fill tabs-line border-bottom-0" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button type="button" className="nav-link active " role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-new" aria-controls="navs-justified-new" aria-selected="true">Title</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button type="button" className="nav-link " role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-link-preparing" aria-controls="navs-justified-link-preparing" aria-selected="false" tabIndex="-1">Title</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button type="button" className="nav-link " role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-link-shipping" aria-controls="navs-justified-link-shipping" aria-selected="false" tabIndex="-1">Title</button>
                      </li>
                    </ul>
                    <div className="tab-content border-0 p-3 px-4 mx-1">
                      <div className="tab-pane fade show active" id="navs-justified-new" role="tabpanel">
                        <ul className="timeline mb-0">
                          <li className="timeline-item ps-4 border-left-dashed">
                            <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-success text-uppercase fw-medium">Title</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">101 Boulder, California(CA), 95959</p>
                            </div>
                          </li>
                          <li className="timeline-item ps-4 border-transparent">
                            <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                              <i className="mdi mdi-map-marker-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-primary text-uppercase fw-medium">Title</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">939 Orange, California(CA), 92118</p>
                            </div>
                          </li>
                        </ul>
                        <div className="border-1 border-light border-top border-dashed mb-2"></div>
                        <ul className="timeline mb-0">
                          <li className="timeline-item ps-4 border-left-dashed">
                            <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-success text-uppercase fw-medium">Title</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">162 Windsor, California(CA), 95492</p>
                            </div>
                          </li>
                          <li className="timeline-item ps-4 border-transparent">
                            <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                              <i className="mdi mdi-map-marker-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-primary text-uppercase fw-medium">Title</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">487 Sunset, California(CA), 94043</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane fade" id="navs-justified-link-preparing" role="tabpanel">
                        <ul className="timeline mb-0">
                          <li className="timeline-item ps-4 border-left-dashed">
                            <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-success text-uppercase fw-medium">sender</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">939 Orange, California(CA), 92118</p>
                            </div>
                          </li>
                          <li className="timeline-item ps-4 border-transparent border-left-dashed">
                            <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                              <i className="mdi mdi-map-marker-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-primary text-uppercase fw-medium">Title</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">101 Boulder, California(CA), 95959 </p>
                            </div>
                          </li>
                        </ul>
                        <div className="border-1 border-light border-top border-dashed mb-2 "></div>
                        <ul className="timeline mb-0">
                          <li className="timeline-item ps-4 border-left-dashed">
                            <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-success text-uppercase fw-medium">Title</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">162 Windsor, California(CA), 95492</p>
                            </div>
                          </li>
                          <li className="timeline-item ps-4 border-transparent">
                            <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                              <i className="mdi mdi-map-marker-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-primary text-uppercase fw-medium">Title</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">487 Sunset, California(CA), 94043</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane fade" id="navs-justified-link-shipping" role="tabpanel">
                        <ul className="timeline mb-0">
                          <li className="timeline-item ps-4 border-left-dashed">
                            <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-success text-uppercase fw-medium">Title</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">101 Boulder, California(CA), 95959</p>
                            </div>
                          </li>
                          <li className="timeline-item ps-4 border-transparent">
                            <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                              <i className="mdi mdi-map-marker-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-primary text-uppercase fw-medium">Title</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">939 Orange, California(CA), 92118</p>
                            </div>
                          </li>
                        </ul>
                        <div className="border-1 border-light border-top border-dashed mb-2 "></div>
                        <ul className="timeline mb-0">
                          <li className="timeline-item ps-4 border-left-dashed">
                            <span className="timeline-indicator-advanced text-success border-0 shadow-none">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-success text-uppercase fw-medium">Title</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">162 Windsor, California(CA), 95492 </p>
                            </div>
                          </li>
                          <li className="timeline-item ps-4 border-transparent">
                            <span className="timeline-indicator-advanced text-primary border-0 shadow-none">
                              <i className="mdi mdi-map-marker-outline"></i>
                            </span>
                            <div className="timeline-event ps-1">
                              <div className="timeline-header">
                                <small className="text-primary text-uppercase fw-medium">Title</small>
                              </div>
                              <h6 className="mb-2">Subtitle</h6>
                              <p className="mb-0">487 Sunset, California(CA), 94043</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>


          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="table-responsive">
                <div className="row mb-2">
                  <div className="col-sm-12 col-md-6">
                    <button
                      type="button"
                      className="mb-2 mb-md-0 btn btn-primary"
                      onClick={() => _handleAddClick()}
                    >
                      Add Task&nbsp;
                      <i className="fa fa-plus fa-sm"></i>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 m-auto">
                    <FullCalendar
                      plugins={[dayGridPlugin]}
                      initialView="dayGridMonth"
                      events={taskList}
                      eventClick={handleEventClick}
                      aspectRatio='2'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalBox
            show={showModel || addModel || displayPopUp}
            title={displayPopUp ? "Notice" : "Task Form"}
            size={displayPopUp === true ? 'md' : (addModel ? 'lg' : 'xl')}
            onHide={() => {
              setShowModel(false);
              setAddModel(false);
              closePopUp();
            }}
          >
            {showModel === true ? (
              <CalendarForm
                modalData={modalData}
                editUserDetails={(params) => submitEditUserDetails(params)}
              />
            ) : addModel === true ? (
              <TaskForm
                userId={userId}
                addUserDetails={(params) => submitUserDetails(params)}
                currentUser={currentUser}
              />
            ) : displayPopUp === true ? (

              <div className="modal-body">
                <div className="container login-wrap-new">
                  <div className="row">
                    <div className="col-md-12">
                      <p><strong>Exchange Rate missing for today.</strong></p>
                      {
                        ["admin", "accounts", "manager"].includes(currentUser.user.role) &&
                        <Link to='/rates' className="btn btn-primary">Add Rate</Link>
                      }
                    </div>
                  </div>
                </div>
              </div>
            ) :


              (
                ""
              )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default Calendar;
