import { USER_ACTION_TYPE } from "../../../Constant/user.constants";

const INITIAL_STATE = {
  showThirdpartyChargesReportingList: [],
  blocking:false,
};
export const thirdpartyChargesReportingListReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPE.SET_THIRDPARTYCHARGES_REPORTING_LIST_PENDING:
      return {
        blocking: true,
        showThirdpartyChargesReportingList: [],
      };

    case USER_ACTION_TYPE.SET_THIRDPARTYCHARGES_REPORTING_LIST_SUCESS:
      return {
        blocking: false,
        showThirdpartyChargesReportingList: payload,
      };

    case USER_ACTION_TYPE.SET_THIRDPARTYCHARGES_REPORTING_LIST_ERROR:
      return {
        blocking: true,
        showThirdpartyChargesReportingList: [],
      };

    case USER_ACTION_TYPE.RESET_THIRDPARTYCHARGES_REPORTING_LIST:
      return {
        blocking: true,
        showThirdpartyChargesReportingList: [],
      };

    default:
      return state;
  }
};
