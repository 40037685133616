import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";

export const deleteNotificationById = (ids, user) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteNotifications/", { ids: JSON.stringify(ids) }, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("notificationDelete");
      if (user.role === 'customer') {
        dispatch(getNotificationList({ customer_id: user.id }));
      } else {
        dispatch(getNotificationList({ user_id: user.id }));
      }
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_NOTIFICATION_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_NOTIFICATION_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const getNotificationList = (filters) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_NOTIFICATION_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslNotifications", { params: filters, headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      return dispatch({
        type: USER_ACTION_TYPE.SET_NOTIFICATION_LIST_SUCCESS,
        payload: {
          records: response.data.data,
          totalCount: response.data.total_records,
          unreadCount: response.data.unread_count,
          error_code: response.data.errorCode,
        },
      });
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_NOTIFICATION_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_NOTIFICATION_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const addNotificationDetails = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslNotifications", postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      return true;
    } else {
      displayErrorMessage(response.data.errorMessage);
      return dispatch({
        type: USER_ACTION_TYPE.SET_NOTIFICATION_LIST_ERROR,
        payload: {
          error_code: response.data.errorCode,
        },
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_NOTIFICATION_LIST_ERROR,
      payload: {
        error_code: err?.response?.data?.errorCode
      }
    });
  }
};

export const markNotificationRead = (postData, notificationId) => {
  return async (dispatch, getState) => {
    try {
      const response = await api.put("api/v1/tslNotifications/" + notificationId, postData, { headers: requestTokenHeader() });
      if (response.data.errorCode === 0) {
        const currentRecords = getState().notifications.records;
        let index = currentRecords.findIndex(notification => notification.id === notificationId);
        currentRecords[index].is_read = postData.is_read;
        return dispatch({
          type: USER_ACTION_TYPE.SET_NOTIFICATION_LIST_SUCCESS,
          payload: {
            records: currentRecords,
            totalCount: getState().notifications.totalCount,
            unreadCount: getState().notifications.unreadCount + (postData.is_read === '0' ? 1 : -1),
            error_code: 0,
          },
        });
      } else {
        displayErrorMessage(response.data.errorMessage);
        return dispatch({
          type: USER_ACTION_TYPE.SET_NOTIFICATION_LIST_ERROR,
          payload: {
            error_code: response.data.errorCode,
          },
        });
      }
    } catch (err) {
      if (err?.response?.data?.errorCode === 4) {
        displayErrorMessage(err?.response?.data?.errorMessage);
      } else {
        displayErrorMessage(err.message);
      }
      return dispatch({
        type: USER_ACTION_TYPE.SET_NOTIFICATION_LIST_ERROR,
        payload: {
          error_code: err?.response?.data?.errorCode
        }
      });
    }
  }
};


