import React, { useState, useEffect, useRef } from 'react';
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { requestTokenHeader } from "../../../../Services/Helpers/helper";
import api from "../../../../Services/Axios/index";
import { useDispatch, useSelector } from "react-redux";
const libraries = ['places'];


export function CustomerForm(props) {
    const currentUser = useSelector((state) => state.userClient);
    const inputRefAddress = useRef();
    const inputRefCompanyAddress = useRef();
    const [fields, setFields] = useState(props.userDetails ? props.userDetails : {});
    const [errors, setErrors] = useState({});
    const [applyCheck] = useState(false);
    const [userData, setUserData] = useState("");
    const handleChange = (event) => {
        let data = fields;
        data[event.target.name] = event.target.value;
        setFields({ ...data });
    };

    const validateRegistration = (fields, applyCheck = false) => {
        let errors = {};
        let formIsValid = true;

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!fields["firstname"] || fields["firstname"].trim() === "") {
            formIsValid = false;
            errors["firstname"] = "*Please enter your First name.";
        }
        if (!fields["lastname"] || fields["lastname"].trim() === "") {
            formIsValid = false;
            errors["lastname"] = "*Please enter your Last name.";
        }
        if (!fields["email"] || fields["email"].trim() === "") {
            formIsValid = false;
            errors["email"] = "*Please enter your email address.";
        } else if (!fields["email"].match(validRegex)) {
            formIsValid = false;
            errors["email"] = "*Please enter a valid email address.";
        }
        if (!fields["mobile_number"] || fields["mobile_number"].trim() === "") {
            formIsValid = false;
            errors["mobile_number"] = "*Please enter your mobile number.";
        }
        if ((!fields["password"] || fields["password"].trim() === "") && props.userId !== undefined) {
            formIsValid = false;
            errors["password"] = "*Please enter your Password.";
        } else if (props.userId !== undefined && fields["password"].length < 4) {
            formIsValid = false;
            errors["password"] = "*Please enter minimum 5 characters";
        }
        if (!fields["address"] || fields["address"].trim() === "") {
            formIsValid = false;
            errors["address"] = "*Please enter address";
        }
        

        if (!fields["loading_place"] || fields["loading_place"].trim() === "") {
            formIsValid = false;
            errors["loading_place"] = "*Please enter loading place.";
        } 
        return {
            errors: errors,
            formIsValid: formIsValid,
        };
    };

    const _validateForm = () => {
        let formFields = fields;
        let response = validateRegistration(formFields, applyCheck);
        setErrors(response.errors);
        return response.formIsValid;
    };
    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/tslGetAllSalesUser", {
                params: { offset: 0, limit: 9999999999999, search: "sales", searchType: "role" },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setUserData(response?.data?.data);
            } else {
                setUserData("");
            }
        }
    
        fetchMyAPI2();
    
    }, []);

    const updateUserDetails = async (event) => {
        event.preventDefault();
        if (_validateForm()) {
            if (fields) {
                const postData = {
                    firstname: fields.firstname,
                    lastname: fields.lastname,
                    email: fields.email,
                    mobile_number: fields.mobile_number,
                    status: fields.status,
                    id: fields.id,
                    bussiness_id: fields.bussiness_id,
                    cbsa_authorization_number: fields.cbsa_authorization_number,
                    address: fields.address,
                    zip: fields.zip,
                    gender: fields.gender,
                    dob: fields.dob,
                    company_name: fields.company_name,
                    company_address: fields.company_address,
                    driving_licence: fields.driving_licence,
                    cbsa_approved: fields.cbsa_approved,
                    cbsa_approved_date: fields.cbsa_approved_date,
                    loading_place_status: fields.loading_place_status,
                    loading_place: fields.loading_place,
                    username: fields.username
                }
                if (props.userDetails) {
                    props.editUserDetails(postData);
                } else if (props.userId) {
                    const params = {
                        password: fields.password,
                        firstname: fields.firstname,
                        lastname: fields.lastname,
                        email: fields.email,
                        mobile_number: fields.mobile_number,
                        status: fields.status ? fields.status : 'active',
                        userId: props.userId,
                        bussiness_id: fields.bussiness_id,
                        cbsa_authorization_number: fields.cbsa_authorization_number,
                        address: fields.address,
                        zip: fields.zip,
                        gender: fields.gender,
                        dob: fields.dob,
                        company_name: fields.company_name,
                        company_address: fields.company_address,
                        driving_licence: fields.driving_licence,
                        cbsa_approved: fields.cbsa_approved,
                        cbsa_approved_date: fields.cbsa_approved_date,
                        loading_place_status: fields.loading_place_status,
                        loading_place: fields.loading_place,
                        username: fields.username
                    }
                    props.addUserDetails(params)
                }

            }
        }
    }
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDm9SqM0fp4sg7KSLBachj-WS7zpQ80Wtk',
        libraries
    });

    const handlePlaceChangedAddress = () => {
        const [place] = inputRefAddress.current.getPlaces();
        if (place) {
            let data = fields;
            data["address"] = place.formatted_address;
            setFields({ ...data });
        }
    }
    const handlePlaceChangedCompanyAddress = () => {
        const [place] = inputRefCompanyAddress.current.getPlaces();
        if (place) {
            let data = fields;
            data["company_address"] = place.formatted_address;
            setFields({ ...data });
        }
    }
    return (

        <>
            <div className="invitation-wrap mb-4">
                <div className="container">
                    <div className="row login-wrap-new">
                        <div className="col-md-12 col-xs-12">
                            <div className="form-content">
                                <form onSubmit={(event) => updateUserDetails(event)}>
                                    <div className="row">
                                       
                                        <div className="row">
                                        <div className="col-sm-3">
                                                <label>User Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="User Name"
                                                    name="username"
                                                    value={(fields.username && fields.username != "null") ? fields.username : ''}
                                                    onChange={(event) => handleChange(event)}
                                                    readOnly ={true}
                                                />
                                                {errors.username ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.username}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            
                                            <div className="col-sm-3">
                                                <label>First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="First Name"
                                                    name="firstname"
                                                    value={fields?.firstname}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.firstname ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.firstname}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="col-sm-3">
                                                <label>Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last Name"
                                                    name="lastname"
                                                    value={fields?.lastname}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.lastname ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.lastname}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            {/* <div className="col-sm-3">
                                                <label>Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                    name="email"
                                                    value={fields?.email}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.email ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.email}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div> */}

                                            <div className="col-sm-3">
                                                <label>Mobile Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Mobile number"
                                                    name="mobile_number"
                                                    value={fields?.mobile_number}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.mobile_number ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.mobile_number}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-sm-3">
                                                <label>Driving Licence Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Driving Licence"
                                                    name="driving_licence"
                                                    value={(fields.driving_licence && fields.driving_licence == "null") ? '' : fields.driving_licence}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.driving_licence ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.driving_licence}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-sm-3">
                                                <label>CBSA Authorization Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="CBSA Authorization Number"
                                                    name="cbsa_authorization_number"
                                                    value={(fields.cbsa_authorization_number && fields.cbsa_authorization_number != "null") ? fields?.cbsa_authorization_number : ""}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.cbsa_authorization_number ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.cbsa_authorization_number}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-sm-3">
                                                <label>Address</label>
                                                {isLoaded
                                                    &&
                                                    <StandaloneSearchBox
                                                        onLoad={ref => inputRefAddress.current = ref}
                                                        onPlacesChanged={handlePlaceChangedAddress}
                                                    >
                                                        <input
                                                            name="address"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Address"
                                                            value={fields.address ? fields.address : ''}
                                                            onChange={(event) => handleChange(event)}
                                                        />
                                                    </StandaloneSearchBox>
                                                }
                                                {errors.address ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.address}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-sm-3">
                                                <label>Postal Code</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="zip"
                                                    name="zip"
                                                    value={(fields.zip && fields.zip != "null") ? fields?.zip : ""}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.zip ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.zip}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-sm-3">
                                                <label>Gender</label>
                                                <select className="form-control" name="gender" onChange={(event) => handleChange(event)} value={fields?.gender}>
                                                    <option value="Male">Male</option>
                                                    <option value="inactive">Female</option>
                                                    <option value="inactive">Other</option>
                                                </select>
                                                {errors.gender ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.gender}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-sm-3">
                                                <label>Date of Birth</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder="Date of Birth"
                                                    name="dob"
                                                    value={fields.dob ? fields.dob : ''}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.dob ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.dob}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            {/* <div className="col-sm-3">
                                                <label>Account Status</label>
                                                <select className="form-control" name="status" onChange={(event) => handleChange(event)} value={fields?.status}>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                            </div> */}

                                            <div className="col-sm-3">
                                                <label>Company Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Company Name"
                                                    name="company_name"
                                                    value={(fields.company_name && fields.company_name == "null") ? '' : fields.company_name}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.company_name ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.company_name}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-sm-3">
                                                <label>Company Address</label>
                                                {isLoaded
                                                    &&
                                                    <StandaloneSearchBox
                                                        onLoad={ref => inputRefCompanyAddress.current = ref}
                                                        onPlacesChanged={handlePlaceChangedCompanyAddress}
                                                    >
                                                        <input
                                                            name="company_address"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Company Address"
                                                            value={fields.company_address ? fields.company_address : ''}
                                                            onChange={(event) => handleChange(event)}
                                                        />
                                                    </StandaloneSearchBox>}
                                                {errors.company_address ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.company_address}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-sm-3" style={{display:"none"}}>
                                                <label>Bussiness ID</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Bussiness ID"
                                                    name="bussiness_id"
                                                    value={(fields.bussiness_id && fields.bussiness_id != "null") ? fields.bussiness_id : ''}

                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.bussiness_id ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.bussiness_id}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            {/* <div className="col-sm-3">
                                                <label>CBSA Approved</label>
                                                <select style={{ pointerEvents: "none" }} className="form-control" name="cbsa_approved" onChange={(event) => handleChange(event)} value={fields?.cbsa_approved}>
                                                    <option>--Select--</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>

                                                {errors.cbsa_approved ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.cbsa_approved}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div> */}

                                            {/* <div className="col-sm-3">
                                                <label>CBSA Approved Date</label>
                                                <input
                                                    readOnly={true}
                                                    type="date"
                                                    className="form-control"
                                                    placeholder="CBSA Approved Date"
                                                    name="cbsa_approved_date"
                                                    value={fields.cbsa_approved_date ? fields.cbsa_approved_date : ''}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.cbsa_approved_date ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.cbsa_approved_date}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                            </div> */}

                                            <div className="col-sm-3" style={{display: "none"}}>
                                                <label>Loading Place Status</label>
                                                <select className="form-control" name="loading_place_status" onChange={(event) => handleChange(event)} value={fields?.loading_place_status}>
                                                    <option>--Select--</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>

                                                {errors.loading_place_status ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.loading_place_status}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-sm-3" style={{display: "none"}}>
                                                <label>Loading Place</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Loading Place"
                                                    name="loading_place"
                                                    value={(fields.loading_place && fields.loading_place != "null") ? fields.loading_place : ''}
                                                    onChange={(event) => handleChange(event)}
                                                />
                                                {errors.loading_place ? (
                                                    <div className="errorMsg text-danger">
                                                        {errors.loading_place}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-sm-3">
                                        <label>Sales Person</label>
                                        <select className="form-control"

                                            name="user_id"
                                            value={fields?.user_id}

                                            onChange={(event) => handleChange(event)}

                                          style={{pointerEvents: "none" }}

                                        >
                                            <option value="">--Select--</option>
                                            {
                                                userData && userData.map((customer, index) => {
                                                    return (<option key={index} value={customer.id}>{customer.firstname + (customer.lastname ? ' ' + customer.lastname : '')}</option>);
                                                })
                                            }
                                        </select>
                                        {errors.user_id ? (
                                            <div className="errorMsg text-danger">
                                                {errors.user_id}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div> 
                                       
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3">
                                                <button type="submit" className="btn btn-primary">
                                                    {fields.id ? 'Save Customer' : 'Save Customer'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}