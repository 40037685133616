import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './index.css';
import { useDispatch, useSelector } from "react-redux";
import { checkLoginUser } from "../../../Services/Store/Customer/Login/user.action";
import { logoutUser } from "../../../Services/Store/ClientLogin/client.action";

const Login = () => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [applyCheck] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(logoutUser());
  }, []);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("customerToken")) && loginData.user?.id) {
      navigate('/customer/dashboard');
    }
  }, [loginData]);

  const _handleChange = (event) => {
    let data = fields;
    data[event.target.name] = event.target.value;
    setFields({ ...data });
  };

  const _validateForm = () => {
    let formFields = fields;
    let response = _validateLogin(formFields, applyCheck);
    setErrors(response.errors);
    return response.formIsValid;
  };

  const _handleSubmit = (event) => {
    event.preventDefault();

    if (_validateForm()) {
      const postData = {
        username: fields.username,
        password: fields.password,
      }
      dispatch(checkLoginUser(postData));
    }
  };

  const _validateLogin = (fields, applyCheck = false) => {
    let errors = {};
    let formIsValid = true;


    if (!fields["username"] || fields["username"].trim() === '') {
      formIsValid = false;
      errors["username"] = "*Please enter your username.";
    }

    if (!fields["password"] || fields["password"].trim() === '') {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }
    else if (fields["password"].length < 4) {
      formIsValid = false;
      errors["password"] = "*please enter minimum 5 character";
    }


    return {
      errors: errors,
      formIsValid: formIsValid
    };
  }

  return (
    <>
      <div className="admin-login">
        <div className="h-100">
          <div className="row g-0 h-100 overflow-hidden">
            <div className="col-lg-6 col-md-12 col-sm-12 h-100 hide-mob">
              <div className="img-block h-100">
                <img src="../assets/images/login-bg.png" className="w-100 h-100"/>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="login-card h-100">
                <div className="row m-auto">
                <div className="col-md-8 m-auto">
                    <form onSubmit={(event) => _handleSubmit(event)}>
                    <div className="text-center mb-5">
                      <img src="/assets/images/big-logo.png" className="w-100"/>
                    </div>
                        <div className="input-box">
                        <label>Username</label>
                          <input
                            type="text"
                            className="form-control mb-4"
                            name="username"
                            placeholder="Username"
                            onChange={(event) => _handleChange(event)}
                          />
                          {errors.username ? (
                            <div className="errorMsg text-danger">
                              {errors.username}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="input-box">
                        <label>Password</label>
                          <input
                            type="password"
                            className="form-control mb-4"
                            name="password"
                            placeholder="Password"
                            onChange={(event) => _handleChange(event)}
                          />
                          {errors.password ? (
                            <div className="errorMsg text-danger">
                              {errors.password}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <div className="form-check mb-4">
                          <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                          <label className="form-check-label" for="exampleCheck1">Keep me logged in</label>
                        </div> */}
                        <div className="btn-block">
                          <button type="submit" className="btn btn-primary mb-4">
                            Login
                          </button>
                          <Link to='/login' className="btn btn-primary">Login As Employee</Link>
                        </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;