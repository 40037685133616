import React, { useState, useEffect } from 'react';
import moment from "moment";
import { requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import { Tooltip } from 'react-tooltip';

export function RateHistory(props) {

  const [history, setHistory] = useState([]);

  useEffect(() => {
    async function fetchHistory() {
      const response = await api.get("api/v1/tslRateHistory", {
        params: { quote_id: props.quoteId },
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setHistory(response.data.data);
      } else {
        setHistory([]);
      }
    }
    fetchHistory();
  }, []);

  const callPageRecords = (history) => {
    if (history.length) {
      return history.map(data => (
        <tr key={data.id}>
          <td>{data.firstname + (data.lastname ? ' ' + data.lastname : '')}</td>
          <td>{data.bl_charges + data.freight + data.telux > 0 ?
            <>
              {'$' + parseFloat(data.bl_charges + data.freight + data.telux).toFixed(2)}
              <a className="btn btn-warning btn-xsm ms-1" data-tooltip-id={"rate-tooltip-" + data.id} data-tooltip-html={
                "<span>Bl Charges: $" + data.bl_charges + "</span><br/>"
                + "<span>Freight: $" + data.freight + "</span><br/>"
                + "<span>Telux: $" + data.telux + "</span>"
              }><i className="fa fa-info"></i></a>
              <Tooltip id={"rate-tooltip-" + data.id} />
            </>
            : ''}
          </td>
          <td>{data.name}</td>
          <td>{data.created_at ? moment(data.created_at).format("YYYY-MM-DD") : ""}</td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={4}>No Record Found</td>
        </tr>
      );
    }
  };

  return (
    <>
      <div className="modal-body">
        <div className="invitation-wrap">
          <div className="container">
            <div className="row login-wrap-new">
              <div className="col-md-12 col-xs-12">
                <div className="form-content">
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr className="bg-primary text-white">
                              <th>Given By</th>
                              <th>Cost</th>
                              <th>Shipping Line</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {callPageRecords(history)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}