import axios from "axios";
import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import {  displayErrorMessage, displaySuccessMessage,requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";


export const deleteRatesById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteRates",{id},
                                   { headers:requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      const showRatesList = {};
      const resultOutput = getState()
        .ratesList.showRatesList.result.map((data) => data)
        .filter((data) => data.id !== id);
      showRatesList.result = resultOutput;
      showRatesList.records = getState().ratesList.showRatesList.records - 1;
      showRatesList.errorCode = 0;
      displaySuccessMessage("ratesDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_RATES_LIST_SUCESS,
        payload: showRatesList,
      });
    } else {
      displayErrorMessage("ratesDeleteError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_RATES_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_RATES_LIST_ERROR });
  }
};

export const getratesList= (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslRatesList", {
                                    params: postData,
                                    headers:requestTokenHeader()
                                  });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_RATES_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_RATES_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    return dispatch({ type: USER_ACTION_TYPE.SET_RATES_LIST_ERROR });
  }
};



export const addRatesDetails = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddRates",postData,
      { headers:requestTokenHeader() });
    if (response.data.errorCode === 0) {
      const resultOutput = getState().ratesList.showRatesList.result;
      resultOutput.push(response.data.result);
      const showRatesList = {};
      showRatesList.result = resultOutput;
      showRatesList.records = getState().ratesList.showRatesList.records + 1;
      showRatesList.errorCode = 0;
      displaySuccessMessage("ratesAdded");
      return dispatch({
        type: USER_ACTION_TYPE.SET_RATES_LIST_SUCESS,
        payload: showRatesList,
      });
    } else {
      displayErrorMessage("ratesAddedError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_RATES_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_RATES_LIST_ERROR });
  }
};


export const editRatesDetails = (postData) => 
 {
 return async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslEditRates",postData,
    {
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      var resultOutput={};
      const userId = localStorage.getItem('userId');
      resultOutput.userId=userId;
      resultOutput.search='';
      resultOutput.offset=0;
      resultOutput.customerId=response.data.result.customer_id;
      displaySuccessMessage("ratesUpdated");
      dispatch(getratesList(resultOutput));

    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      displayErrorMessage("ratesUpdatedError");
      
       dispatch({
        type: USER_ACTION_TYPE.SET_RATES_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
     displayErrorMessage(err.name);
     dispatch({ type: USER_ACTION_TYPE.SET_RATES_LIST_ERROR });
  }
 }
};


