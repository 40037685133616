import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import {
  getcarrierList,
  deleteCarrierById,
  addCarrierDetails,
  editCarrierDetails,
} from "../../../Services/Store/Common/carrier/carrier.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { CarrierForm } from "./CarrierForm";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { carrierList } from "../../../Services/Store/Common/carrier/carrier.selector";

const Contact = () => {
  const id = useParams()?.id;
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const perPage = 25;
  const [modalData, setModalData] = useState({});
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carrierListResult = useSelector(carrierList);
  const [searchType, setSearchType] = useState(null);
  const [searchIds, setSearchIds] = useState(null);

  useEffect(() => {
    if (id) {
      if (id === 'search') {
        let search = localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : '';
        if (search?.resource === 'carrier' && search.ids) {
          setSearchIds(search.ids);
        }
      } else {
        setSearchType('id');
        setSearchKeyword(id);
      }
    } else {
      localStorage.removeItem('search');
      setSearchIds('');
    }
  }, [id]);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));
    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }
  }, [errorResponce]);

  useEffect(() => {
    if (carrierListResult.result !== undefined) {
      const result = carrierListResult.result;
      if (result.length > 0) {
        setUserData(carrierListResult.result);
        setUserRecordsData(carrierListResult.records);
        setErrorResponce(carrierListResult.error_code);
      } else {
        setUserData("");
        setUserRecordsData("");
      }
    }
  }, [carrierListResult]);

  useEffect(() => {
    if (searchKeyword !== null && searchType !== null)
      dispatch(getcarrierList({ searchIds, searchType: searchType, search: searchKeyword }));
  }, [searchKeyword, searchType]);

  useEffect(() => {
    if(searchIds !== null) {
      setSearchKeyword(null);
      setSearchType(null);
      dispatch(getcarrierList({ searchIds }));
    }
  }, [searchIds]);

  const handlePageClick = (selectedPage) => {
    dispatch(getcarrierList({ searchIds, searchType: searchType, search: searchKeyword, offset: selectedPage }));
  };

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (userDetails) => {
    setModalData(userDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleAddClick = () => {
    setShowModel(true);
    setAddModel(true);
  };

  const callPageRecords = (userData) => {
    if (userData) {
      return userData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.name}</td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit contact"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Delete contact"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={8}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteUser = async (userId2) => {
    dispatch(deleteCarrierById(userId2));
    setShowModel(false);
    setDeleteModel(false);
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submitUserDetails = async (params) => {
    dispatch(addCarrierDetails(params));
    setShowModel(false);
    setAddModel(false);
  };

  const submitEditUserDetails = async (params) => {
    dispatch(editCarrierDetails(params, searchIds));
    setShowModel(false);
    setAddModel(false);
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <>
      <AdminLayout pageHeading="Carrier" pageIcon="fa fa-truck">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Carrier List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-6">
                    <button
                      type="button"
                      className="mb-2 mb-md-0 btn btn-primary"
                      onClick={() => _handleAddClick()}
                    >
                      Add&nbsp;
                      <i className="fa fa-plus fa-sm"></i>
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="fillter-wrap d-flex justify-content-end">
                      <select name="selectType" id="" className="form-control me-1" value={searchType ?? ''} onChange={(event) => handleSearhChange(event)}>
                        <option value="">Select Type</option>
                        <option value="name">Carrier Name</option>
                      </select>
                      <input type="search" className="form-control" value={searchKeyword ?? ''} placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                    </div>
                  </div>
                </div>
                </div>
                <div className="card-body">
                <div className="row">
                  <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Carrier Name</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {carrierListResult.result !==
                          undefined &&
                          carrierListResult.result !== "" ? (
                          callPageRecords(
                            carrierListResult.result
                          )
                        ) : (
                          <tr>
                            <td colSpan={8}>No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    {userData != undefined &&
                      userData.length > 0 &&
                      userRecordsData !== undefined ? (
                      <PaginationBlock
                        perPage={perPage}
                        userRecordsData={userRecordsData}
                        callHandlePageClick={(data) =>
                          handlePageClick(data)
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
            </div>
          </div>
          <ModalBox
            show={showModel}
            title={deleteModel ? 'Delete Carrier' : 'Carrier Form'}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddModel(false);
            }}
          >
            {editModel === true ? (
              <CarrierForm
                editUserDetails={(params) => submitEditUserDetails(params)}
                userDetails={modalData}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={userId}
                deleteUserId={(userId) => deleteUser(userId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : addModel === true ? (
              <CarrierForm
                userId={userId}
                addUserDetails={(params) => submitUserDetails(params)}
              />
            ) : (
              ""
            )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default Contact;