import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from "moment";
import { useSelector } from 'react-redux';
import { requestTokenHeader, equipmentList, commodityList } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import Select from "react-select";
import Checkbox from '../../../../src/Components/Ui/Checkbox';
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
const libraries = ['places'];
export function Form(props) {
    const inputRefOrigin = useRef();
    const inputRefDestination = useRef();
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDm9SqM0fp4sg7KSLBachj-WS7zpQ80Wtk',
        libraries
    });
    const currentUser = useSelector((state) => state.userClient);
    const fields = props.shippingLineRateDetails ? props.shippingLineRateDetails : {};
    const [originDestination, setOriginDestination] = useState("");
    const [shippingLines, setShippingLines] = useState([]);

    const validateForm = Yup.object().shape({
        sl_quote: Yup.string().required('Quote Number is required'),
        origin: Yup.string().required('Origin is required'),
        loading_port: Yup.number().required('Loading Port is required'),
        destination: Yup.string().required('Destination is required'),
        destination_port: Yup.number().required('Destination port is required'),
        shipping_line_id: Yup.number().required('Shipping Line is required'),
        cost: Yup.number('Please enter decimal value').required('Cost is required'),
        valid_from: Yup.date().required('Valid From is required'),
        valid_to: Yup.date().required('Valid To is required').min(
            Yup.ref('valid_from'), "Valid To must be greater than Valid From"
        ),
        item_type: Yup.string().required('Commodity is required'),
    });

    let initialValues = {
        sl_quote: fields.sl_quote ?? '',
        origin: fields.origin ?? '',
        loading_port: fields.loading_port ?? '',
        destination: fields.destination ?? '',
        destination_port: fields.destination_port ?? '',
        shipping_line_id: fields?.shipping_line_id ?? '',
        cost: fields?.cost ?? '',
        valid_from: fields?.valid_from ? moment(fields.valid_from).format('yyyy-MM-DD') : '',
        valid_to: fields?.valid_to ? moment(fields.valid_to).format('yyyy-MM-DD') : '',
        equipment_types: fields?.equipment_types ? JSON.parse(fields.equipment_types) : [],
        item_type: fields?.item_type ?? '',
        remarks: fields?.remarks ?? ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateForm,
        onSubmit: (values) => {
            values.valid_from = values.valid_from ? moment(values.valid_from).format("YYYY-MM-DD") : null;
            values.valid_to = values.valid_to ? moment(values.valid_to).format("YYYY-MM-DD") : null;
            values.equipment_types = values.equipment_types.length ? JSON.stringify(values.equipment_types) : '';
            if (fields?.id) {
                props.submitDetails(values, fields.id);
            } else {
                values.created_by = currentUser.user.id;
                props.submitDetails(values);
            }
        },
    });

    useEffect(() => {
        if (fields?.item_type && commodityList.indexOf(fields.item_type) == -1) {
            commodityList.push(fields.item_type);
        }
        async function fetchOriginDestination() {
            const response = await api.get("api/v1/tslgetOriginDestination", {
                params: "",
                headers: requestTokenHeader()
            });
            if (response?.data?.errorCode === 0) {
                setOriginDestination(response.data.data);
            } else {
                setOriginDestination("");
            }
        }
        fetchOriginDestination();
    }, []);

    const optionsOrigin = [];
    const optionsDestination = [];
    originDestination?.[0]?.map((data, index) => (
        optionsOrigin.push({ value: data.id, label: data.name + (data.country ? ', ' + data.country : '') })
    ));
    originDestination?.[1]?.map((data, index) => (
        optionsDestination.push({ value: data.id, label: data.name + (data.country ? ', ' + data.country : '') })
    ));

    useEffect(() => {
        async function fetchShippingLines() {
            const response = await api.get("api/v1/tslCarriersList", {
                params: "",
                headers: requestTokenHeader()
            });
            if (response?.data?.errorCode === 0) {
                setShippingLines(response.data.data);
            } else {
                setShippingLines("");
            }
        }
        fetchShippingLines();
    }, []);

    const optionShippingLines = [];

    shippingLines?.map((data, index) => (
        optionShippingLines.push({ value: data.id, label: data.name })
    ));

    const handlePlaceChangedOrigin = () => {
        const [place] = inputRefOrigin.current.getPlaces();
        if (place) {

            formik.values.origin = place.formatted_address;

        }
    }

    const handlePlaceChangedDestination = () => {
        const [place] = inputRefDestination.current.getPlaces();
        if (place) {

            formik.values.destination = place.formatted_address;

        }
    }

    return (
        <>
            <div className="modal-body login-wrap-new">
                <form onSubmit={formik.handleSubmit} className='invitation-wrap form-content'>
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <label>Shipping Line Quote*</label>
                            <input className="form-control"
                                name="sl_quote"
                                id="sl_quote"
                                type='text'
                                {...formik.getFieldProps('sl_quote')}
                            />
                            {formik.touched.sl_quote && formik.errors.sl_quote && (
                                <div className="errorMsg text-danger">{formik.errors.sl_quote}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Origin*</label>

                            {isLoaded
                                && <StandaloneSearchBox
                                    onLoad={ref => inputRefOrigin.current = ref}
                                    onPlacesChanged={handlePlaceChangedOrigin}
                                >
                                    <input className="form-control"
                                        name="origin"
                                        id="origin"
                                        type='text'
                                        {...formik.getFieldProps('origin')}
                                    />
                                </StandaloneSearchBox>}


                            {formik.touched.origin && formik.errors.origin && (
                                <div className="errorMsg text-danger">{formik.errors.origin}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Loading Port*</label>

                            <Select
                                name="loading_port"
                                id="loading_port"
                                options={optionsOrigin}
                                onChange={(selectedOption) =>
                                    formik.setFieldValue('loading_port', selectedOption.value)
                                }
                                value={optionsOrigin.filter(function (option) {
                                    return option.value == formik.values.loading_port;
                                })}
                                label="Single select"
                            />


                            {formik.touched.loading_port && formik.errors.loading_port && (
                                <div className="errorMsg text-danger">{formik.errors.loading_port}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Destination *</label>

                            {isLoaded
                                && <StandaloneSearchBox
                                    onLoad={ref => inputRefDestination.current = ref}
                                    onPlacesChanged={handlePlaceChangedDestination}
                                >
                                    <input className="form-control"
                                        name="destination"
                                        id="destination"
                                        type='text'
                                        {...formik.getFieldProps('destination')}
                                    />

                                </StandaloneSearchBox>}

                            {formik.touched.destination && formik.errors.destination && (
                                <div className="errorMsg text-danger">{formik.errors.destination}</div>
                            )}
                        </div>

                        <div className="col-md-3 col-6">
                            <label>Destination Port*</label>
                            <Select
                                name="destination_port"
                                id="destination_port"
                                options={optionsDestination}
                                onChange={(selectedOption) =>
                                    formik.setFieldValue('destination_port', selectedOption.value)
                                }
                                value={optionsDestination.filter(function (option) {
                                    return option.value == formik.values.destination_port;
                                })}
                                label="Single select"
                            />
                            {formik.touched.destination_port && formik.errors.destination_port && (
                                <div className="errorMsg text-danger">{formik.errors.destination_port}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Shipping Line*</label>
                            <Select
                                name="shipping_line_id"
                                id="shipping_line_id"
                                options={optionShippingLines}
                                onChange={(selectedOption) =>
                                    formik.setFieldValue('shipping_line_id', selectedOption.value)
                                }
                                value={optionShippingLines.filter(function (option) {
                                    return option.value == formik.values.shipping_line_id;
                                })}
                                label="Single select"
                            />
                            {formik.touched.shipping_line_id && formik.errors.shipping_line_id && (
                                <div className="errorMsg text-danger">{formik.errors.shipping_line_id}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Cost *</label>
                            <input className="form-control"
                                name="cost"
                                id="cost"
                                type='text'
                                {...formik.getFieldProps('cost')}
                            />
                            {formik.touched.cost && formik.errors.cost && (
                                <div className="errorMsg text-danger">{formik.errors.cost}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Valid From*</label>
                            <input className="form-control"
                                name="valid_from"
                                id="valid_from"
                                type='date'
                                {...formik.getFieldProps('valid_from')}
                            />
                            {formik.touched.valid_from && formik.errors.valid_from && (
                                <div className="errorMsg text-danger">{formik.errors.valid_from}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Valid To*</label>
                            <input className="form-control"
                                name="valid_to"
                                id="valid_to"
                                type='date'
                                {...formik.getFieldProps('valid_to')}
                            />
                            {formik.touched.valid_to && formik.errors.valid_to && (
                                <div className="errorMsg text-danger">{formik.errors.valid_to}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Commodity *</label>
                            <select className="form-control"
                                name="item_type"
                                {...formik.getFieldProps('item_type')}
                            >
                                <option>Select</option>
                                {
                                    commodityList.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                    })
                                }
                            </select>
                            {formik.touched.item_type && formik.errors.item_type && (
                                <div className="errorMsg text-danger">{formik.errors.item_type}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Remarks</label>
                            <textarea className="form-control"
                                name="remarks"
                                id="remarks"
                                {...formik.getFieldProps('remarks')}
                            ></textarea>
                        </div>
                        <div className="col-12">
                            <Checkbox
                                name="equipment_types"
                                title="Equipment Types"
                                options={equipmentList}
                                value={formik.values.equipment_types}
                                onChangeFunc={(selectedOptions) =>
                                    formik.setFieldValue('equipment_types', selectedOptions)
                                }
                            />
                            {formik.touched.equipment_types && formik.errors.equipment_types && (
                                <div className="errorMsg text-danger">{formik.errors.equipment_types}</div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-12'>
                            <input type="submit" name="submit" className="btn btn-primary w-auto text-white" value={fields?.id ? "Save Shipping Rate" : "Save Shipping Rate"} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}