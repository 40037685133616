import React, { useState, useEffect } from 'react';
import { requestTokenHeader } from "../../../../Services/Helpers/helper";
import api from "../../../../Services/Axios/index";
import moment from "moment";
import InvoicePdfUsd from "../../Jobs/InvoicePdfUsd";
import InvoicePdfCad from "../../Jobs/InvoicePdfCad";
import InvoicePdfMix from "../../Jobs/InvoicePdfMix";

import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
export function ViewForm(props) {

    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState(false);
    const [applyCheck] = useState(false);
    const [service, setService] = useState("");
    const [salesPerson, setSalesPerson] = useState("");
    const [releaseRcvd, setReleaseRcvd] = useState("");

    const [receivableData, setReceivableData] = useState([]);
    const [currency, seCcurrency] = useState("");
    const [currencyName, setCurrencyName] = useState("");
    const [truckerName, setTruckerName] = useState("");
    const [totalReceivedCad, setTotalReceivedCad] = useState(0);
    const [totalReceivedUsd, setTotalReceivedUsd] = useState(0);

    const [customerData, setCustomerData] = useState("");
    const [displayItemCad, setDisplayItemCad] = useState(true);
    const [displayItemUsd, setDisplayItemUsd] = useState(true);
    const [displayItemMix, setDisplayItemMix] = useState(true);

    const [shipper, setShipper] = useState("");
    const [consignee, setConsignee] = useState("");
    const [extraCharges, setExtraCharges] = useState("");
    const generatePDF = (id) => {
        document.getElementById(id).style.display = 'block';
        const input = document.getElementById(id);
        html2canvas(input, {
            logging: true,
            letterRendering: 1,
            scale: 2,
            windowWidth: 1440,
            useCORS: true,
            onclone: function (clonedDoc) {
                clonedDoc.getElementById(id).style.display = 'block';
            }
        }).then((canvas) => {
            var imgData = canvas.toDataURL("image/png");
            var imgWidth = 210;
            var pageHeight = 295;
            var imgHeight = (canvas.height * imgWidth) / canvas.width;
            var heightLeft = imgHeight;
            var doc = new jsPDF("p", "mm");
            var position = 0;
            doc.addImage(imgData, "jpeg", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, "jpeg", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            const pages = doc.internal.getNumberOfPages();

            for (let j = 1; j < pages + 1; j++) {
                let horizontalPos = imgWidth / 2; //Can be fixed number
                let verticalPos = pageHeight - 1; //Can be fixed number
                doc.setPage(j);
                doc.setFontSize(10);

                doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
                    align: "center"
                    //Optional text styling});
                });
            }

            doc.save("invoice.pdf");
            document.getElementById(id).style.display = 'none';

        });
    }

    useEffect(() => {
        async function fetchMyAPI() {
            const response = await api.get("api/v1/viewJob", {
                params: { id: props.jobsDetails.id },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setFields(response.data.data);
                response.data.data[4].filter((item, index, arr) => {
                    if (item.id === props.jobsDetails.service_id) {
                        setService(item.name);

                    }
                });

                response.data.data[5].filter((item, index, arr) => {
                    if (item.id === props.jobsDetails.sale_person_id) {
                        setSalesPerson(item.firstname + (item.lastname ? ' ' + item.lastname : ''));

                    }
                });

                response.data.data[6].filter((item, index, arr) => {
                    if (item.id === props.jobsDetails.released_received_id) {
                        setReleaseRcvd(item.name);

                    }
                });

                setReceivableData(response.data.data[13]);

                (response.data.data[13]).map((item, i) => {

                    setTotalReceivedCad(data => data + item.cad_amount);
                    setTotalReceivedUsd(data => data + item.usd_amount);

                });


                props.jobsData[2].filter((item, index, arr) => {
                    if (item.id === props?.jobsDetails?.truker_id) {
                        setTruckerName(item.name);

                    }
                })


            } else {
                setFields("");
            }
        }

        fetchMyAPI();
    }, []);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/getCustomerById", {
                params: { id: props.jobsDetails.customer_id, deleteCond: true },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setCustomerData(response?.data?.data?.[0]);
            } else {
                setCustomerData("");
            }
        }

        fetchMyAPI2();

    }, []);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/getShipperById", {
                params: { id: props.jobsDetails.shipper },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setShipper(response?.data?.data?.[0]);
            } else {
                setShipper("");
            }
        }

        fetchMyAPI2();

    }, []);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/getConsigneeById", {
                params: { id: props.jobsDetails.consignee },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setConsignee(response?.data?.data?.[0]);
            } else {
                setConsignee("");
            }
        }

        fetchMyAPI2();

    }, []);

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/tslFetchExtraCharges", {
                params: { id: props.jobsDetails.id },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setExtraCharges(response?.data?.data);
            } else {
                setExtraCharges("");
            }
        }

        fetchMyAPI2();

    }, []);
    return (
        <>
            <div className="invitation-wrap modal-body">
                <div className="container">
                    <div className="row login-wrap-new">
                        <div className="col-md-12 col-xs-12">
                            <div className="form-content">

                                {/* booking page data */}
                                <div className="row">

                                    <div className="col-sm-6 mb-2">
                                        <div className='head-block d-flex detail-view-table-head align-items-center'>
                                            <div className='img-block mr-4'>
                                                <img src="/assets/images/view-job.png" height="80" />
                                            </div>
                                            <div>
                                                <span className="view-title">Job No:</span> <code>{props.jobsDetails.job_number}</code><br />
                                                <span className="view-title">Job Status:</span> <code>{props.jobsDetails.job_status_name}</code><br />
                                                <span className="view-title">Job Date:</span> <code>{props.jobsDetails.job_date}</code><br />
                                                <span className="view-title">Job Completed:</span> <code>{fields?.[3]?.[0]?.job_completed}</code>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 d-flex justify-content-end align-items-center">
                                        {/* <button className="btn btn-primary btn-sm text-white mr-2">Send Invoice</button> */}
                                        <button className="btn btn-primary btn-sm text-white mr-2" onClick={item => generatePDF("invoice_usd")}>Invoice USD</button>
                                        <button className="btn btn-primary btn-sm text-white mr-2" onClick={item => generatePDF("invoice_cad")}>Invoice CAD</button>
                                        <button className="btn btn-primary btn-sm text-white" onClick={item => generatePDF("invoice_mix")}>Invoice MIX</button>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='mt-4 tabbable'>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button className="nav-link active" id="nav-booking-tab" data-bs-toggle="tab" data-bs-target="#nav-booking" type="button" role="tab" aria-controls="nav-booking" aria-selected="true">Booking</button>
                                            <button className="nav-link" id="nav-doc-tab" data-bs-toggle="tab" data-bs-target="#nav-doc" type="button" role="tab" aria-controls="nav-doc" aria-selected="false">Documentation</button>
                                            <button className="nav-link" id="nav-doc-upload-tab" data-bs-toggle="tab" data-bs-target="#nav-doc-upload" type="button" role="tab" aria-controls="nav-doc-upload" aria-selected="false">Uploaded Document</button>
                                        </div>
                                    </div>

                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane show active" id="nav-booking" role="tabpanel" aria-labelledby="nav-booking-tab">
                                            <div className="row login-wrap-new mt-3">
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Booking No:</span>
                                                        <code>{props.jobsDetails.job_number}</code>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Container No.:</span>
                                                        <code>{props.jobsDetails.container_number}</code>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Carrier:</span>
                                                        <code>{props.jobsDetails.carriers_name}</code>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Sale Person:</span>
                                                        <code>{salesPerson}</code>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Origin:</span>
                                                        <code>{props.jobsDetails.origin}</code>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Destination:</span>
                                                        <code>{props.jobsDetails.destination}</code>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Service:</span>
                                                        <code>{service}</code>
                                                    </div>
                                                </div>



                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Doc Cut Off Date:</span>
                                                        <code>{(props.jobsDetails.doc_cutoff_date && props.jobsDetails.doc_cutoff_date != "0000-00-00") ? moment(props.jobsDetails.doc_cutoff_date).format("YYYY-MM-DD") : ""}</code>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">VGM Cut Off Date:</span>
                                                        <code>{(props.jobsDetails.vgm_cutoff_date && props.jobsDetails.vgm_cutoff_date != "0000-00-00") ? moment(props.jobsDetails.vgm_cutoff_date).format("YYYY-MM-DD") : ""}</code>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Cargo Cut Off Date:</span>
                                                        <code>{(props.jobsDetails.cargo_curtoff_date && props.jobsDetails.cargo_curtoff_date != "0000-00-00") ? moment(props.jobsDetails.cargo_curtoff_date).format("YYYY-MM-DD") : ""}</code>
                                                    </div>
                                                </div>

                                                {(props?.jobsDetails?.customer_comment != "" && props.jobsDetails.customer_comment != null) &&

                                                    <>
                                                        <span className="">Customer Comment:</span>
                                                        <div className="row"  >
                                                            <div className="col-md-12">
                                                                <div className='comment-box'>
                                                                    <p className='p-0 m-0'>
                                                                        {props.jobsDetails.customer_comment}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>}

                                            </div>
                                        </div>

                                        <div className="tab-pane" id="nav-doc" role="tabpanel" aria-labelledby="nav-doc-tab">
                                            <div className="row login-wrap-new mt-3">
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">B/L No:</span>
                                                        <code>{props.jobsDetails.bl_number}</code>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Release Rcvd:</span>
                                                        <code>{releaseRcvd}</code>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Shipper:</span>
                                                        <code>{shipper.contact_person}</code>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Consignee:</span>
                                                        <code>{consignee.contact_person}</code>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">Commodity:</span>
                                                        <code>{props.jobsDetails.commodity}</code>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3">
                                                    <div className='detail-view-table-head mb-2'>
                                                        <span className="">ETA:</span>
                                                        <code>{props.jobsDetails.eta != "0000-00-00" ? moment(props.jobsDetails.eta).format("YYYY-MM-DD") : ""}</code>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row login-wrap-new mt-3'>
                                            {fields?.[16] && (fields?.[16]).length > 0 && <b className="">Vin Numbers:</b> }
                                                {fields?.[16] && (fields?.[16]).map((item, idx) => {
                                                    return (
                                                        <div className="col-md-3" key={idx} id={`id-${item.id}`} style={{ display: fields?.[16] ? "" : "none" }} >
                                                            <div className='comment-box'>
                                                                <p className='p-0 m-0'>
                                                                    <span className='d-block date'>{moment(item.created_at).format("YYYY-MM-DD")}</span>
                                                                    {item.vin_number}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <div className="tab-pane" id="nav-doc-upload" role="tabpanel" aria-labelledby="nav-doc-upload-tab">
                                            <div className="row login-wrap-new" style={{ display: (fields?.[2] && fields?.[2]?.[0]?.id != null) ? "" : "none" }} >
                                                {fields?.[2] && fields?.[2].map((item, idx) => {
                                                    return (
                                                        ["BL Draft", "B13 Document", "accounts", "Release", "Booking Confirmation"].includes(item.type) && (item.uploaded_by === 'customer' || item.approved === '1') ?
                                                            <div key={idx} id={`id-${item.id}`} className="col-lg-3 col-sm-6 uploded-files-col">
                                                                <div className='uploded-files'>
                                                                    <p className='d-flex justify-content-between align-items-center mb-0'>
                                                                        <b>{item.type}</b>
                                                                    </p>
                                                                    <p className='d-flex justify-content-between mb-0 mt-2'>
                                                                        <code title={item.file_name} className='w-100'>
                                                                            <a href={window.location.hostname == "localhost" ? `http://localhost:3001/Images/uploads/${item.file_name}` : `http://golden-test.com:3001/Images/uploads/${item.file_name}`} target="_blank">{item.file_name}</a>
                                                                        </code>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            : ''
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                {/* Accounts page data */}
                                <fieldset style={{ display: "none" }}>
                                    <legend>Accounts</legend>
                                    <div className="row login-wrap-new" style={{ display: "none" }}>
                                        <div className="col-sm-3">
                                            <div className='detail-view-table-head'>
                                                <span className="">Customer Name:</span>
                                                <br />
                                                <code>{customerData?.firstname + (customerData?.lastname ? ' ' + customerData?.lastname : '')}</code>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className=' detail-view-table-head'>
                                                <span className="">Customer Quote:</span>
                                                <br />
                                                <code>{fields?.[3]?.[0]?.cq_amount}<span style={{ color: "#336600" }}> USD</span></code>
                                                <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.cq_cad}<span style={{ color: "#336600" }}> CAD</span> </code>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className='detail-view-table-head'>
                                                <span className="">Extra Scale Move:</span>
                                                <br />
                                                <code>{fields?.[3]?.[0]?.esm_amount}<span style={{ color: "#336600" }}> USD</span> </code>
                                                <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.esm_cad}<span style={{ color: "#336600" }}> CAD</span> </code>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className='detail-view-table-head'>
                                                <span className="">Custom Hold Charges::</span>
                                                <br />
                                                <code>{fields?.[3]?.[0]?.chc_amount} <span style={{ color: "#336600" }}>USD</span> </code>&nbsp;
                                                <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.chc_cad} <span style={{ color: "#336600" }}>CAD</span> </code>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className=' detail-view-table-head'>
                                                <span className="">Extra Charges:</span>
                                                <br />
                                                <code>{fields?.[3]?.[0]?.ec_usd} <span style={{ color: "#336600" }}>USD</span> </code>&nbsp;
                                                <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.ec_cad} <span style={{ color: "#336600" }}>CAD</span> </code>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className='detail-view-table-head'>
                                                <span className="">Manifest Correction Charges:</span>
                                                <br />
                                                <code>{fields?.[3]?.[0]?.manifest_amount} <span style={{ color: "#336600" }}>USD</span> </code>&nbsp;
                                                <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.manifest_cad} <span style={{ color: "#336600" }}>CAD</span> </code>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className='detail-view-table-head'>
                                                <span className="">Customer Trucking Charges:</span>
                                                <br />
                                                <code>{fields?.[3]?.[0]?.trucker_paid_amount}  <span style={{ color: "#336600" }}>USD</span> </code>&nbsp;
                                                <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.trucker_paid_cad}  <span style={{ color: "#336600" }}>CAD</span> </code>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                {/* Accounts page data end*/}


                                {/* SSL, Trucker & Extra Payable page data */}
                                <fieldset style={{ display: "none" }}>
                                    <legend> SSL, Trucker & Extra Payable </legend>
                                    <div className="row login-wrap-new" style={{ display: "none" }}>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">SSL Charges:</span>
                                            <br />
                                            <code>{fields?.[3]?.[0]?.ssl_amount}  <span style={{ color: "#336600" }}>USD</span> </code>
                                            <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.ssl_cad}  <span style={{ color: "#336600" }}>CAD</span> </code>
                                        </div>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">Steamship Invoice No:</span>
                                            <br />
                                            <code>{fields?.[3]?.[0]?.steamship_invoice_no == "null" || fields?.[3]?.[0]?.steamship_invoice_no == "undefined" ? "" : fields?.[3]?.[0]?.steamship_invoice_no}</code>
                                        </div>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">SSL Paid Date:</span><br />
                                            <code>{(fields?.[3]?.[0]?.ssl_paid_date && fields?.[3]?.[0]?.ssl_paid_date != "0000-00-00") ? moment(fields?.[3]?.[0]?.ssl_paid_date).format("YYYY-MM-DD") : ""}
                                            </code>

                                        </div>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">SSL Paid Date New:</span><br />
                                            <span className="view-data view-name">{(fields?.[3]?.[0]?.ssl_date_new && fields?.[3]?.[0]?.ssl_date_new != "0000-00-00") ? moment(fields?.[3]?.[0]?.ssl_date_new).format("YYYY-MM-DD") : ""}</span>
                                        </div>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">Trucker Name:</span>
                                            <code>{truckerName}</code>
                                        </div>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">Trucker Charges:</span><br />
                                            <code>{fields?.[3]?.[0]?.trucker_amount}  <span style={{ color: "#336600" }}>USD</span> </code>
                                            <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.trucker_cad}  <span style={{ color: "#336600" }}>CAD</span> </code>

                                        </div>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">Trucker Invoice No.:</span><br />
                                            <code>{fields?.[3]?.[0]?.trucker_invoice_no == "null" || fields?.[3]?.[0]?.trucker_invoice_no == "undefined" ? "" : fields?.[3]?.[0]?.trucker_invoice_no}</code>
                                        </div>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">Trucker Invoice Status:</span><br />
                                            <span className="view-data view-name">{fields?.[3]?.[0]?.trucker_invoice_status}</span>
                                        </div>

                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">Trucker Paid date:</span> <br />
                                            <code> {(fields?.[3]?.[0]?.trucker_paid_date && fields?.[3]?.[0]?.trucker_paid_date != "0000-00-00") ? moment(fields?.[3]?.[0]?.trucker_paid_date).format("YYYY-MM-DD") : ""} </code>
                                        </div>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">Trucker Paid Date New:</span> <br />
                                            <code>{(fields?.[3]?.[0]?.trucker_date_new && fields?.[3]?.[0]?.trucker_date_new != "0000-00-00") ? moment(fields?.[3]?.[0]?.trucker_date_new).format("YYYY-MM-DD") : ""}
                                            </code>
                                        </div>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">Extra payable Charges:</span>
                                            <code>{fields?.[3]?.[0]?.extra_payable_usd} USD</code>
                                            <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.extra_payable_cad} CAD</code>
                                        </div>


                                    </div>
                                </fieldset>
                                {/* SSL, Trucker & Extra Payable page data end*/}

                                {/* Deposit page data */}
                                <fieldset style={{ display: "none" }}>
                                    <legend> Deposit </legend>
                                    <div className="row login-wrap-new" style={{ display: "none" }}>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">Deposit Date:</span><br />
                                            <code>{(fields?.[3]?.[0]?.deposit_date != "0000-00-00" && fields?.[3]?.[0]?.deposit_date != undefined) ? moment(fields?.[3]?.[0]?.deposit_date).format("YYYY-MM-DD") : ""}</code>
                                        </div>
                                        <div className="col-sm-3 detail-view-table-head">
                                            <span className="">Deposit Notes:</span><br />
                                            <code>{fields?.[3]?.[0]?.deposit_note == "null"  || fields?.[3]?.[0]?.deposit_note == "undefined" ? "" : fields?.[3]?.[0]?.deposit_note}</code>
                                        </div>


                                    </div>
                                </fieldset>
                                {/* Deposit page data end*/}

                                {/* Status page data */}
                                <fieldset style={{ display: "none" }}>
                                    <legend> Status </legend>
                                    <div className="row " style={{ display: "none" }}>
                                        <div className="col-sm-3">
                                            <div className='detail-view-table-head mb-2'>
                                                <span className="">Invoice doccumentation</span><br />
                                                <code>{fields?.[3]?.[0]?.invoice_documentation}</code>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>


                                <fieldset style={{ display: "none" }}>
                                    <legend> Job Comments: </legend>
                                    {fields?.[14] && (fields?.[14]).map((item, idx) => {
                                        return (
                                            <div key={idx} id={`id-${item.id}`} className="row" style={{ display: fields?.[15] ? "" : "none", border: "1px solid", padding: "10px", margin: "2px" }} >
                                                <div className="col-md-10">
                                                    <div className='comment-box'>
                                                        <p>{item.job_comment}</p>
                                                    </div>
                                                    <span className='d-block date'>{moment(item.created_at).format("YYYY-MM-DD")}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </fieldset>


                                {/* Status page data end*/}



                                {/* Third Party page data */}
                                <fieldset style={{ display: "none" }}>
                                    <legend>Third Party</legend>
                                    {(fields?.[15] && (fields?.[15]).length > 0) && (<table className="table table-striped payable-charges-table" style={{ marginTop: "20px", display: "none" }}>
                                        <thead>
                                            <tr>

                                                <th>Sr No.</th>
                                                <th>Third Party Name</th>
                                                <th>Email</th>
                                                <th>Conversion CAD</th>
                                                <th>Conversion USD</th>
                                                <th>Total CAD Amount</th>
                                                <th>Total USD Amount</th>
                                                <th>Remarks</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {(fields?.[16]).map((item, i) => {

                                                return [
                                                    <tr key={i}>

                                                        <td>{i + 1}</td>
                                                        <td>{item.third_party_name}</td>
                                                        <td>{item.third_party_email}</td>
                                                        <td>{item.conversion_cad}</td>
                                                        <td>{item.conversion_usd}</td>
                                                        <td>{item.cad_amount}</td>
                                                        <td>{item.usd_amount}</td>
                                                        <td>{item.third_party_remarks}</td>

                                                    </tr>
                                                ]

                                            })}



                                        </tbody>
                                    </table>)}
                                </fieldset>
                                {/* Third Party page data end*/}



                                {/* Payments page data */}
                                <fieldset style={{ display: "none" }}>
                                    <legend>Payments</legend>
                                    {(receivableData && receivableData.length > 0) && (<table className="table table-striped table-bordered mt-4 payable-charges-table" style={{ display: "none" }}>
                                        <thead>
                                            <tr>

                                                <th>Sr No.</th>
                                                <th>When Paid</th>
                                                <th>Mode of payment</th>
                                                <th>Conversion CAD</th>
                                                <th>Conversion USD</th>
                                                <th>Total CAD Recieved</th>
                                                <th>Total USD Recieved</th>
                                                <th>Payment Remarks</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {receivableData.map((item, i) => {

                                                return [
                                                    <tr key={i}>

                                                        <td>{i + 1}</td>
                                                        <td>{moment(item.when_paid).format("YYYY-MM-DD")}</td>
                                                        <td>{item.mode_of_payment}</td>
                                                        <td>{item.conversion_cad}</td>
                                                        <td>{item.conversion_usd}</td>
                                                        <td>{item.cad_amount}</td>
                                                        <td>{item.usd_amount}</td>
                                                        <td>{item.payment_remarks}</td>

                                                    </tr>
                                                ]

                                            })}



                                        </tbody>
                                    </table>)}
                                </fieldset>



                                {/* Payments page data end*/}



                                {/* Amount page data */}
                                <fieldset style={{ display: "none" }}>
                                    <legend>Amount</legend>
                                    <div className="row login-wrap-new" style={{ display: "none" }}>
                                        <div className="col-sm-4 detail-view-table-head text-center"><span className="view-data view-name">Total Payable:
                                        </span> <br /><br />
                                            <code>{(fields?.[3]?.[0]?.total_payable_usd === undefined || fields?.[3]?.[0]?.total_payable_usd == null) ? 0 : (fields?.[3]?.[0]?.total_payable_usd).toFixed(2)} <span style={{ color: "#336600" }}>USD</span></code>
                                            <code style={{ marginLeft: "5px" }}>{(fields?.[3]?.[0]?.total_payable_cad === undefined || fields?.[3]?.[0]?.total_payable_cad == null) ? 0 : (fields?.[3]?.[0]?.total_payable_cad).toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                        </div>
                                        <div className="col-sm-4 detail-view-table-head text-center"><span className="view-data view-name">Total Receivable:</span> <br /><br />
                                            <code>{(fields?.[3]?.[0]?.total_recievable_usd === undefined || fields?.[3]?.[0]?.total_recievable_usd == null) ? 0 : (fields?.[3]?.[0]?.total_recievable_usd).toFixed(2)} <span style={{ color: "#336600" }}>USD</span></code>
                                            <code style={{ marginLeft: "5px" }}>{(fields?.[3]?.[0]?.total_recievable_cad === undefined || fields?.[3]?.[0]?.total_recievable_cad == null) ? 0 : (fields?.[3]?.[0]?.total_recievable_cad).toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                        </div>
                                        <div className="col-sm-4 detail-view-table-head text-center"><span className="view-data view-name">Total Expected Gain:</span> <br /><br />
                                            <code>{(fields?.[3]?.[0]?.gain_usd === undefined || fields?.[3]?.[0]?.gain_usd == null) ? 0 : (fields?.[3]?.[0]?.gain_usd).toFixed(2)} <span style={{ color: "#336600" }}>USD</span></code>
                                            <code style={{ marginLeft: "5px" }}>{(fields?.[3]?.[0]?.gain_cad === undefined || fields?.[3]?.[0]?.gain_cad == null) ? 0 : (fields?.[3]?.[0]?.gain_cad).toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset style={{ display: "none" }}>
                                    <div className="row login-wrap-new" style={{ marginTop: "20px", display: "none" }}>
                                        <div className="col-sm-4">
                                            <div className='detail-view-table-head text-center'>
                                                <span className="view-data view-name">Total Paid:</span>
                                                <br /><br />
                                                <code>{totalReceivedUsd} <span style={{ color: "#336600" }}>USD</span></code>&nbsp;
                                                <code style={{ marginLeft: "5px" }}>{totalReceivedCad.toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className='detail-view-table-head text-center'>
                                                <span className="view-data view-name">Total Received:</span> <br /><br />
                                                <code>{totalReceivedUsd} <span style={{ color: "#336600" }}>USD</span></code>&nbsp;
                                                <code style={{ marginLeft: "5px" }}>{totalReceivedCad.toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className='detail-view-table-head text-center'>
                                                <span className="view-data view-name">Net Gain:</span> <br /><br />
                                                <code>{(fields?.[3]?.[0]?.gain_usd === undefined || fields?.[3]?.[0]?.gain_usd == null) ? 0 : (fields?.[3]?.[0]?.gain_usd).toFixed(2)} <span style={{ color: "#336600" }}>USD</span></code>
                                                <code style={{ marginLeft: "5px" }}>{(fields?.[3]?.[0]?.gain_cad === undefined || fields?.[3]?.[0]?.gain_cad == null) ? 0 : (fields?.[3]?.[0]?.gain_cad).toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>


                                <div className="row" style={{ marginTop: "20px", display: "none" }}>
                                    <div className="col-sm-12">
                                        <div className='detail-view-table-head text-center'>
                                            <span className="view-data view-name">Balance After Recieved Amount</span> <br /><br />
                                            <code>{fields?.[3]?.[0]?.total_recievable_usd === undefined ? (totalReceivedUsd - 0) : (totalReceivedUsd - fields?.[3]?.[0]?.total_recievable_usd).toFixed(2)} <span style={{ color: "#336600" }}>USD</span></code>
                                            <code style={{ marginLeft: "5px" }}>{fields?.[3]?.[0]?.total_recievable_cad === undefined ? (totalReceivedCad - 0) : (totalReceivedCad - fields?.[3]?.[0]?.total_recievable_cad).toFixed(2)} <span style={{ color: "#336600" }}> CAD </span></code>
                                        </div>
                                    </div>
                                </div>
                                {/* Amount page data end*/}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="invoice_usd" style={{ display: "none" }}>
                <InvoicePdfUsd displayItem={displayItemUsd} customerData={customerData} fields={fields} receivableData={receivableData} jobsDetails={props.jobsDetails}
                    shipper={shipper.contact_person}
                    consignee={consignee.contact_person}
                    extraCharges={extraCharges}
                    service={service}
                />
            </div>

            <div id="invoice_cad" style={{ display: "none" }}>
                <InvoicePdfCad displayItem={displayItemCad} customerData={customerData} fields={fields} receivableData={receivableData} jobsDetails={props.jobsDetails}
                    shipper={shipper.contact_person}
                    consignee={consignee.contact_person}
                    extraCharges={extraCharges}
                    service={service}
                />
            </div>

            <div id="invoice_mix" style={{ display: "none" }}>
                <InvoicePdfMix displayItem={displayItemMix} customerData={customerData} fields={fields} receivableData={receivableData} jobsDetails={props.jobsDetails}
                    shipper={shipper.contact_person}
                    consignee={consignee.contact_person}
                    extraCharges={extraCharges}
                    service={service}
                />
            </div>
        </>
    );
}