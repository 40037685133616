export const USER_ACTION_TYPE = {
  SET_CURRENT_USER_SUCESS: "SET_CURRENT_USER_SUCESS",
  SET_CURRENT_USER_PENDING: "SET_CURRENT_USER_PENDING",
  SET_CURRENT_USER_ERROR: "SET_CURRENT_USER_ERROR",
  SET_CURRENT_CLIENT_SUCESS: "SET_CURRENT_CLIENT_SUCESS",
  SET_CURRENT_CLIENT_PENDING: "SET_CURRENT_CLIENT_PENDING",
  SET_CURRENT_CLIENT_ERROR: "SET_CURRENT_CLIENT_ERROR",
  SET_USER_SIGNUP_SUCESS: "SET_USER_SIGNUP_SUCESS",
  SET_USER_SIGNUP_PENDING: "SET_USER_SIGNUP_PENDING",
  SET_USER_SIGNUP_ERROR: "SET_USER_SIGNUP_ERROR",
  RESET_USER_SIGNUP: "RESET_USER_SIGNUP",

  SET_USER_LIST_PENDING: "SET_USER_LIST_PENDING",
  SET_USER_LIST_SUCESS: "SET_USER_LIST_SUCESS",
  SET_USER_LIST_ERROR: "SET_USER_LIST_ERROR",
  RESET_USER_LIST: "RESET_USER_LIST",

  SET_PAYMENT_DETAILS_PENDING: "SET_PAYMENT_DETAILS_PENDING",
  SET_PAYMENT_DETAILS_SUCESS: "SET_PAYMENT_DETAILS_SUCESS",
  SET_PAYMENT_DETAILS_ERROR: "SET_PAYMENT_DETAILS_ERROR",
  SET_CLIENT_PENDING: "SET_CLIENT_PENDING",
  SET_CLIENT_SUCESS: "SET_CLIENT_SUCESS",
  SET_CLIENT_ERROR: "SET_CLIENT_ERROR",
  GET_ACCOUNT_CLIENT_PENDING: "GET_ACCOUNT_CLIENT_PENDING",
  GET_ACCOUNT_CLIENT_SUCESS: "GET_ACCOUNT_CLIENT_SUCESS",
  GET_ACCOUNT_CLIENT_ERROR: "GET_ACCOUNT_CLIENT_ERROR",
  RESET_CLIENT_LIST: "RESET_CLIENT_LIST",

  SET_EVENT_LIST_PENDING: "SET_EVENT_LIST_PENDING",
  SET_EVENT_LIST_SUCESS: "SET_EVENT_LIST_SUCESS",
  SET_EVENT_LIST_ERROR: "SET_EVENT_LIST_ERROR",
  RESET_EVENT_LIST: "RESET_EVENT_LIST",


  SET_TRUCKERNAME_LIST_PENDING: "SET_TRUCKERNAME_LIST_PENDING",
  SET_TRUCKERNAME_LIST_SUCESS: "SET_TRUCKERNAME_LIST_SUCESS",
  SET_TRUCKERNAME_LIST_ERROR: "SET_TRUCKERNAME_LIST_ERROR",
  RESET_TRUCKERNAME_LIST: "RESET_TRUCKERNAME_LIST",

  SET_SERVICE_LIST_PENDING: "SET_SERVICE_LIST_PENDING",
  SET_SERVICE_LIST_SUCESS: "SET_SERVICE_LIST_SUCESS",
  SET_SERVICE_LIST_ERROR: "SET_SERVICE_LIST_ERROR",
  RESET_SERVICE_LIST: "RESET_SERVICE_LIST",


  SET_CARRIER_LIST_PENDING: "SET_CARRIER_LIST_PENDING",
  SET_CARRIER_LIST_SUCESS: "SET_CARRIER_LIST_SUCESS",
  SET_CARRIER_LIST_ERROR: "SET_CARRIER_LIST_ERROR",
  RESET_CARRIER_LIST: "RESET_CARRIER_LIST",


  SET_RATES_LIST_PENDING: "SET_RATES_LIST_PENDING",
  SET_RATES_LIST_SUCESS: "SET_RATES_LIST_SUCESS",
  SET_RATES_LIST_ERROR: "SET_RATES_LIST_ERROR",
  RESET_RATES_LIST: "RESET_CARRIER_LIST",

  SET_CONTAINER_LIST_PENDING: "SET_CONTAINER_LIST_PENDING",
  SET_CONTAINER_LIST_SUCESS: "SET_CONTAINER_LIST_SUCESS",
  SET_CONTAINER_LIST_ERROR: "SET_CONTAINER_LIST_ERROR",
  RESET_CONTAINER_LIST: "RESET_CONTAINER_LIST",



  SET_EXPORTDOCUMENTCOORDINATOR_LIST_PENDING: "SET_EXPORTDOCUMENTCOORDINATOR_LIST_PENDING",
  SET_EXPORTDOCUMENTCOORDINATOR_LIST_SUCESS: "SET_EXPORTDOCUMENTCOORDINATOR_LIST_SUCESS",
  SET_EXPORTDOCUMENTCOORDINATOR_LIST_ERROR: "SET_EXPORTDOCUMENTCOORDINATOR_LIST_ERROR",
  RESET_EXPORTDOCUMENTCOORDINATOR_LIST: "RESET_EXPORTDOCUMENTCOORDINATOR_LIST",


  SET_IMPORTDOCUMENTCOORDINATOR_LIST_PENDING: "SET_IMPORTDOCUMENTCOORDINATOR_LIST_PENDING",
  SET_IMPORTDOCUMENTCOORDINATOR_LIST_SUCESS: "SET_IMPORTDOCUMENTCOORDINATOR_LIST_SUCESS",
  SET_IMPORTDOCUMENTCOORDINATOR_LIST_ERROR: "SET_IMPORTDOCUMENTCOORDINATOR_LIST_ERROR",
  RESET_IMPORTDOCUMENTCOORDINATOR_LIST: "RESET_IMPORTDOCUMENTCOORDINATOR_LIST",


  ACCOUNT_USER_PENDING: "ACCOUNT_USER_PENDING",
  ACCOUNT_USER_SUCESS: "ACCOUNT_USER_SUCESS",
  ACCOUNT_USER_ERROR: "ACCOUNT_USER_ERROR",
  SET_ACCOUNT_LIST_SUCESS: "SET_ACCOUNT_LIST_SUCESS",
  SET_ACCOUNT_LIST_ERROR: "SET_ACCOUNT_LIST_ERROR",
  SET_ACCOUNT_LIST_PENDING: "SET_ACCOUNT_LIST_PENDING",
  GET_EVENT_DETAILS_PENDING: "GET_EVENT_DETAILS_PENDING",
  GET_EVENT_DETAILS_SUCESS: "GET_EVENT_DETAILS_SUCESS",
  GET_EVENT_DETAILS_ERROR: "GET_EVENT_DETAILS_ERROR",
  GET_PAGE_DESIGN_PENDING: "GET_PAGE_DESIGN_PENDING",
  GET_PAGE_DESIGN_SUCESS: "GET_PAGE_DESIGN_SUCESS",
  GET_PAGE_DESIGN_ERROR: "GET_PAGE_DESIGN_ERROR",
  GET_REG_TYPES_PAGE_DESIGN_PENDING: "GET_REG_TYPES_PAGE_DESIGN_PENDING",
  GET_REG_TYPES_PAGE_DESIGN_SUCESS: "GET_REG_TYPES_PAGE_DESIGN_SUCESS",
  GET_REG_TYPES_PAGE_DESIGN_ERROR: "GET_REG_TYPES_PAGE_DESIGN_ERROR",
  GET_REG_TYPES_BY_ID_PENDING: "GET_REG_TYPES_BY_ID_PENDING",
  GET_REG_TYPES_BY_ID_SUCESS: "GET_REG_TYPES_BY_ID_SUCESS",
  GET_REG_TYPES_BY_ID_ERROR: "GET_REG_TYPES_BY_ID_ERROR",
  GET_REG_CATEGORIES_PAGE_DESIGN_PENDING:
    "GET_REG_CATEGORIES_PAGE_DESIGN_PENDING",
  GET_REG_CATEGORIES_PAGE_DESIGN_SUCESS:
    "GET_REG_CATEGORIES_PAGE_DESIGN_SUCESS",
  GET_REG_CATEGORIES_PAGE_DESIGN_ERROR: "GET_REG_CATEGORIES_PAGE_DESIGN_ERROR",
  GET_REG_SCODE_PAGE_DESIGN_PENDING: "GET_REG_SCODE_PAGE_DESIGN_PENDING",
  GET_REG_SCODE_PAGE_DESIGN_SUCESS: "GET_REG_SCODE_PAGE_DESIGN_SUCESS",
  GET_REG_SCODE_PAGE_DESIGN_ERROR: "GET_REG_SCODE_PAGE_DESIGN_ERROR",
  GET_REG_CATEGORIES_BY_ID_PENDING: "GET_REG_CATEGORIES_BY_ID_PENDING",
  GET_REG_CATEGORIES_BY_ID_SUCESS: "GET_REG_CATEGORIES_BY_ID_SUCESS",
  GET_REG_CATEGORIES_BY_ID_ERROR: "GET_REG_CATEGORIES_BY_ID_ERROR",
  GET_FIELDS_QA_DISC_SESSIONS_PENDING: "GET_FIELDS_QA_DISC_SESSIONS_PENDING",
  GET_FIELDS_QA_DISC_SESSIONS_SUCESS: "GET_FIELDS_QA_DISC_SESSIONS_SUCESS",
  GET_FIELDS_QA_DISC_SESSIONS_ERROR: "GET_FIELDS_QA_DISC_SESSIONS_ERROR",
  GET_GUESTS_REGISTRANTS_FIELDS_PENDING:
    "GET_GUESTS_REGISTRANTS_FIELDS_PENDING",
  GET_GUESTS_REGISTRANTS_FIELDS_SUCESS: "GET_GUESTS_REGISTRANTS_FIELDS_SUCESS",
  GET_GUESTS_REGISTRANTS_FIELDS_ERROR: "GET_GUESTS_REGISTRANTS_FIELDS_ERROR",
  GET_CUSTOM_QUESTIONS_PENDING: "GET_CUSTOM_QUESTIONS_PENDING",
  GET_CUSTOM_QUESTIONS_SUCESS: "GET_CUSTOM_QUESTIONS_SUCESS",
  GET_CUSTOM_QUESTIONS_ERROR: "GET_CUSTOM_QUESTIONS_ERROR",
  GET_REGISTRANT_FIELD_PENDING: "GET_REGISTRANT_FIELD_PENDING",
  GET_REGISTRANT_FIELD_SUCESS: "GET_REGISTRANT_FIELD_SUCESS",
  GET_REGISTRANT_FIELD_ERROR: "GET_REGISTRANT_FIELD_ERROR",
  GET_CUSTOM_QUESTIONS_BY_ID_PENDING: "GET_CUSTOM_QUESTIONS_BY_ID_PENDING",
  GET_CUSTOM_QUESTIONS_BY_ID_SUCESS: "GET_CUSTOM_QUESTIONS_BY_ID_SUCESS",
  GET_CUSTOM_QUESTIONS_BY_ID_ERROR: "GET_CUSTOM_QUESTIONS_BY_ID_ERROR",
  GET_DISCOUNT_CODES_PENDING: "GET_DISCOUNT_CODES_PENDING",
  GET_DISCOUNT_CODES_SUCESS: "GET_DISCOUNT_CODES_SUCESS",
  GET_DISCOUNT_CODES_ERROR: "GET_DISCOUNT_CODES_ERROR",
  GET_DISCOUNT_CODES_BY_ID_PENDING: "GET_DISCOUNT_CODES_BY_ID_PENDING",
  GET_DISCOUNT_CODES_BY_ID_SUCESS: "GET_DISCOUNT_CODES_BY_ID_SUCESS",
  GET_DISCOUNT_CODES_BY_ID_ERROR: "GET_DISCOUNT_CODES_BY_ID_ERROR",
  GET_INDIVIDUAL_SESSION_PENDING: "GET_INDIVIDUAL_SESSION_PENDING",
  GET_INDIVIDUAL_SESSION_SUCESS: "GET_INDIVIDUAL_SESSION_SUCESS",
  GET_INDIVIDUAL_SESSION_ERROR: "GET_INDIVIDUAL_SESSION_ERROR",
  GET_INDIVIDUAL_SESSION_BY_ID_PENDING: "GET_INDIVIDUAL_SESSION_BY_ID_PENDING",
  GET_INDIVIDUAL_SESSION_BY_ID_SUCESS: "GET_INDIVIDUAL_SESSION_BY_ID_SUCESS",
  GET_INDIVIDUAL_SESSION_BY_ID_ERROR: "GET_INDIVIDUAL_SESSION_BY_ID_ERROR",
  GET_EXTRA_CONFIG_SESSION_PENDING: "GET_EXTRA_CONFIG_SESSION_PENDING",
  GET_EXTRA_CONFIG_SESSION_SUCESS: "GET_EXTRA_CONFIG_SESSION_SUCESS",
  GET_EXTRA_CONFIG_SESSION_ERROR: "GET_EXTRA_CONFIG_SESSION_ERROR",
  GET_EXTRA_CONFIG_SESSION_BY_ID_PENDING:
    "GET_EXTRA_CONFIG_SESSION_BY_ID_PENDING",
  GET_EXTRA_CONFIG_SESSION_BY_ID_SUCESS:
    "GET_EXTRA_CONFIG_SESSION_BY_ID_SUCESS",
  GET_EXTRA_CONFIG_SESSION_BY_ID_ERROR: "GET_EXTRA_CONFIG_SESSION_BY_ID_ERROR",
  GET_MAIN_CONTACT_FIELD_PENDING: "GET_MAIN_CONTACT_FIELD_PENDING",
  GET_MAIN_CONTACT_FIELD_SUCESS: "GET_MAIN_CONTACT_FIELD_SUCESS",
  GET_MAIN_CONTACT_FIELD_ERROR: "GET_MAIN_CONTACT_FIELD_ERROR",
  GET_REGISTRANT_FIELDS_PENDING: "GET_REGISTRANT_FIELDS_PENDING",
  GET_REGISTRANT_FIELDS_SUCESS: "GET_REGISTRANT_FIELDS_SUCESS",
  GET_REGISTRANT_FIELDS_ERROR: "GET_REGISTRANT_FIELDS_ERROR",
  GET_PAGE_DESIGN_GRP_REG_PENDING: "GET_PAGE_DESIGN_GRP_REG_PENDING",
  GET_PAGE_DESIGN_GRP_REG_SUCESS: "GET_PAGE_DESIGN_GRP_REG_SUCESS",
  GET_PAGE_DESIGN_GRP_REG_ERROR: "GET_PAGE_DESIGN_GRP_REG_ERROR",
  GET_PAGE_DESIGN_EXHIBITOR_PENDING: "GET_PAGE_DESIGN_EXHIBITOR_PENDING",
  GET_PAGE_DESIGN_EXHIBITOR_SUCESS: "GET_PAGE_DESIGN_EXHIBITOR_SUCESS",
  GET_PAGE_DESIGN_EXHIBITOR_ERROR: "GET_PAGE_DESIGN_EXHIBITOR_ERROR",
  GET_EXHIBITOR_LIST_PENDING: "GET_EXHIBITOR_LIST_PENDING",
  GET_EXHIBITOR_LIST_SUCESS: "GET_EXHIBITOR_LIST_SUCESS",
  GET_EXHIBITOR_LIST_ERROR: "GET_EXHIBITOR_LIST_ERROR",
  GET_EXHIBITOR_LIST_BY_ID_PENDING: "GET_EXHIBITOR_LIST_BY_ID_PENDING",
  GET_EXHIBITOR_LIST_BY_ID_SUCESS: "GET_EXHIBITOR_LIST_BY_ID_SUCESS",
  GET_EXHIBITOR_LIST_BY_ID_ERROR: "GET_EXHIBITOR_LIST_BY_ID_ERROR",
  GET_EXHIBITOR_BOOTH_MEMBERS_PENDING: "GET_EXHIBITOR_BOOTH_MEMBERS_PENDING",
  GET_EXHIBITOR_BOOTH_MEMBERS_SUCESS: "GET_EXHIBITOR_BOOTH_MEMBERS_SUCESS",
  GET_EXHIBITOR_BOOTH_MEMBERS_ERROR: "GET_EXHIBITOR_BOOTH_MEMBERS_ERROR",
  GET_EXHIBITOR_BOOTH_MEMBERS_BY_ID_PENDING:
    "GET_EXHIBITOR_BOOTH_MEMBERS_BY_ID_PENDING",
  GET_EXHIBITOR_BOOTH_MEMBERS_BY_ID_SUCESS:
    "GET_EXHIBITOR_BOOTH_MEMBERS_BY_ID_SUCESS",
  GET_EXHIBITOR_BOOTH_MEMBERS_BY_ID_ERROR:
    "GET_EXHIBITOR_BOOTH_MEMBERS_BY_ID_ERROR",
  GET_EMAIL_SETUP_PENDING: "GET_EMAIL_SETUP_PENDING",
  GET_EMAIL_SETUP_SUCESS: "GET_EMAIL_SETUP_SUCESS",
  GET_EMAIL_SETUP_ERROR: "GET_EMAIL_SETUP_ERROR",
  GET_REGISTRANTS_LIST_PENDING: "GET_REGISTRANTS_LIST_PENDING",
  GET_REGISTRANTS_LIST_SUCESS: "GET_REGISTRANTS_LIST_SUCESS",
  GET_REGISTRANTS_LIST_ERROR: "GET_REGISTRANTS_LIST_ERROR",
  GET_QUESTIONS_REGISTRANTS_LIST_PENDING:
    "GET_QUESTIONS_REGISTRANTS_LIST_PENDING",
  GET_QUESTIONS_REGISTRANTS_LIST_SUCESS:
    "GET_QUESTIONS_REGISTRANTS_LIST_SUCESS",
  GET_QUESTIONS_REGISTRANTS_LIST_ERROR: "GET_QUESTIONS_REGISTRANTS_LIST_ERROR",
  GET_GUEST_ADDITIONAL_REGISTRANT_PENDING:
    "GET_GUEST_ADDITIONAL_REGISTRANT_PENDING",
  GET_GUEST_ADDITIONAL_REGISTRANT_SUCESS:
    "GET_GUEST_ADDITIONAL_REGISTRANT_SUCESS",
  GET_GUEST_ADDITIONAL_REGISTRANT_ERROR:
    "GET_GUEST_ADDITIONAL_REGISTRANT_ERROR",
  GET_GUEST_ADDITIONAL_INFORMATION_BY_ID_PENDING:
    "GET_GUEST_ADDITIONAL_INFORMATION_BY_ID_PENDING",
  GET_GUEST_ADDITIONAL_INFORMATION_BY_ID_SUCESS:
    "GET_GUEST_ADDITIONAL_INFORMATION_BY_ID_SUCESS",
  GET_GUEST_ADDITIONAL_INFORMATION_BY_ID_ERROR:
    "GET_GUEST_ADDITIONAL_INFORMATION_BY_ID_ERROR",
  GET_ANSWERS_REGISTRANT_PENDING: "GET_ANSWERS_REGISTRANT_PENDING",
  GET_ANSWERS_REGISTRANT_SUCESS: "GET_ANSWERS_REGISTRANT_SUCESS",
  GET_ANSWERS_REGISTRANT_ERROR: "GET_ANSWERS_REGISTRANT_ERROR",


  SET_JOBS_LIST_PENDING: "SET_JOBS_LIST_PENDING",
  SET_JOBS_LIST_SUCESS: "SET_JOBS_LIST_SUCESS",
  SET_JOBS_LIST_ERROR: "SET_JOBS_LIST_ERROR",
  RESET_JOBS_LIST: "RESET_JOBS_LIST",
  SET_JOBS_DATA_PENDING: "SET_JOBS_DATA_PENDING",
  SET_JOBS_DATA_SUCESS: "SET_JOBS_DATA_SUCESS",
  SET_JOBS_DATA_ERROR: "SET_JOBS_DATA_ERROR",
  RESET_JOBS_DATA: "RESET_JOBS_DATA",

  SET_CARGO_LIST_PENDING: "SET_CARGO_LIST_PENDING",
  SET_CARGO_LIST_SUCCESS: "SET_CARGO_LIST_SUCCESS",
  SET_CARGO_LIST_ERROR: "SET_CARGO_LIST_ERROR",
  SET_CARGO_DATA_PENDING: "SET_CARGO_DATA_PENDING",
  SET_CARGO_DATA_SUCESS: "SET_CARGO_DATA_SUCCESS",
  SET_CARGO_DATA_ERROR: "SET_CARGO_DATA_ERROR",

  SET_BOOKING_LIST_PENDING: "SET_BOOKING_LIST_PENDING",
  SET_BOOKING_LIST_SUCCESS: "SET_BOOKING_LIST_SUCCESS",
  SET_BOOKING_LIST_ERROR: "SET_BOOKING_LIST_ERROR",
  SET_BOOKING_DATA_PENDING: "SET_BOOKING_DATA_PENDING",
  SET_BOOKING_DATA_SUCESS: "SET_BOOKING_DATA_SUCCESS",
  SET_BOOKING_DATA_ERROR: "SET_BOOKING_DATA_ERROR",

  SET_SHIPPING_LINE_RATE_LIST_PENDING: "SET_SHIPPING_LINE_RATE_LIST_PENDING",
  SET_SHIPPING_LINE_RATE_LIST_SUCCESS: "SET_SHIPPING_LINE_RATE_LIST_SUCCESS",
  SET_SHIPPING_LINE_RATE_LIST_ERROR: "SET_SHIPPING_LINE_RATE_LIST_ERROR",
  SET_SHIPPING_LINE_RATE_DATA_PENDING: "SET_SHIPPING_LINE_RATE_DATA_PENDING",
  SET_SHIPPING_LINE_RATE_DATA_SUCESS: "SET_SHIPPING_LINE_RATE_DATA_SUCCESS",
  SET_SHIPPING_LINE_RATE_DATA_ERROR: "SET_SHIPPING_LINE_RATE_DATA_ERROR",

  SET_CUSTOMER_RATE_HISTORY_LIST_PENDING: "SET_CUSTOMER_RATE_HISTORY_LIST_PENDING",
  SET_CUSTOMER_RATE_HISTORY_LIST_SUCCESS: "SET_CUSTOMER_RATE_HISTORY_LIST_SUCCESS",
  SET_CUSTOMER_RATE_HISTORY_LIST_ERROR: "SET_CUSTOMER_RATE_HISTORY_LIST_ERROR",
  SET_CUSTOMER_RATE_HISTORY_DATA_PENDING: "SET_CUSTOMER_RATE_HISTORY_DATA_PENDING",
  SET_CUSTOMER_RATE_HISTORY_DATA_SUCESS: "SET_CUSTOMER_RATE_HISTORY_DATA_SUCCESS",
  SET_CUSTOMER_RATE_HISTORY_DATA_ERROR: "SET_CUSTOMER_RATE_HISTORY_DATA_ERROR",

  SET_QUOTES_LIST_PENDING: "SET_QUOTES_LIST_PENDING",
  SET_QUOTES_LIST_SUCESS: "SET_QUOTES_LIST_SUCESS",
  SET_QUOTES_LIST_ERROR: "SET_QUOTES_LIST_ERROR",
  RESET_QUOTES_LIST: "RESET_QUOTES_LIST",

  SET_NOTIFICATION_LIST_PENDING: "SET_NOTIFICATION_LIST_PENDING",
  SET_NOTIFICATION_LIST_SUCCESS: "SET_NOTIFICATION_LIST_SUCCESS",
  SET_NOTIFICATION_LIST_ERROR: "SET_NOTIFICATION_LIST_ERROR",


  SET_TASK_LIST_PENDING: "SET_TASK_LIST_PENDING",
  SET_TASK_LIST_SUCESS: "SET_TASK_LIST_SUCESS",
  SET_TASK_LIST_ERROR: "SET_TASK_LIST_ERROR",
  RESET_TASK_LIST: "RESET_TASK_LIST",


  SET_MEETING_LIST_PENDING: "SET_MEETING_LIST_PENDING",
  SET_MEETING_LIST_SUCESS: "SET_MEETING_LIST_SUCESS",
  SET_MEETING_LIST_ERROR: "SET_MEETING_LIST_ERROR",
  RESET_MEETING_LIST: "RESET_MEETING_LIST",


  SET_ORIGIN_LIST_PENDING: "SET_ORIGIN_LIST_PENDING",
  SET_ORIGIN_LIST_SUCESS: "SET_ORIGIN_LIST_SUCESS",
  SET_ORIGIN_LIST_ERROR: "SET_ORIGIN_LIST_ERROR",
  RESET_ORIGIN_LIST: "RESET_ORIGIN_LIST",

  SET_DESTINATION_LIST_PENDING: "SET_DESTINATION_LIST_PENDING",
  SET_DESTINATION_LIST_SUCESS: "SET_DESTINATION_LIST_SUCESS",
  SET_DESTINATION_LIST_ERROR: "SET_DESTINATION_LIST_ERROR",
  RESET_DESTINATION_LIST: "RESET_DESTINATION_LIST",


  SET_CUSTOMER_REPORTING_LIST_PENDING: "SET_CUSTOMER_REPORTING_LIST_PENDING",
  SET_CUSTOMER_REPORTING_LIST_SUCESS: "SET_CUSTOMER_REPORTING_LIST_SUCESS",
  SET_CUSTOMER_REPORTING_LIST_ERROR: "SET_CUSTOMER_REPORTING_LIST_ERROR",
  RESET_CUSTOMER_REPORTING_LIST: "RESET_CUSTOMER_REPORTING_LIST",


  SET_TRUCKER_REPORTING_LIST_PENDING: "SET_TRUCKER_REPORTING_LIST_PENDING",
  SET_TRUCKER_REPORTING_LIST_SUCESS: "SET_TRUCKER_REPORTING_LIST_SUCESS",
  SET_TRUCKER_REPORTING_LIST_ERROR: "SET_TRUCKER_REPORTING_LIST_ERROR",
  RESET_TRUCKER_REPORTING_LIST: "RESET_TRUCKER_REPORTING_LIST",

  SET_PAYMENT_REPORTING_LIST_PENDING: "SET_PAYMENT_REPORTING_LIST_PENDING",
  SET_PAYMENT_REPORTING_LIST_SUCESS: "SET_PAYMENT_REPORTING_LIST_SUCESS",
  SET_PAYMENT_REPORTING_LIST_ERROR: "SET_PAYMENT_REPORTING_LIST_ERROR",
  RESET_PAYMENT_REPORTING_LIST: "RESET_PAYMENT_REPORTING_LIST",

  SET_SHIPPINGLINE_REPORTING_LIST_PENDING: "SET_SHIPPINGLINE_REPORTING_LIST_PENDING",
  SET_SHIPPINGLINE_REPORTING_LIST_SUCESS: "SET_SHIPPINGLINE_REPORTING_LIST_SUCESS",
  SET_SHIPPINGLINE_REPORTING_LIST_ERROR: "SET_SHIPPINGLINE_REPORTING_LIST_ERROR",
  RESET_SHIPPINGLINE_REPORTING_LIST: "RESET_SHIPPINGLINE_REPORTING_LIST",

  SET_REPORTINGRELEASED_REPORTING_LIST_PENDING: "SET_REPORTINGRELEASED_REPORTING_LIST_PENDING",
  SET_REPORTINGRELEASED_REPORTING_LIST_SUCESS: "SET_REPORTINGRELEASED_REPORTING_LIST_SUCESS",
  SET_REPORTINGRELEASED_REPORTING_LIST_ERROR: "SET_REPORTINGRELEASED_REPORTING_LIST_ERROR",
  RESET_REPORTINGRELEASED_REPORTING_LIST: "RESET_REPORTINGRELEASED_REPORTING_LIST",

  SET_RELEASEDDATES_REPORTING_LIST_PENDING: "SET_RELEASEDDATES_REPORTING_LIST_PENDING",
  SET_RELEASEDDATES_REPORTING_LIST_SUCESS: "SET_RELEASEDDATES_REPORTING_LIST_SUCESS",
  SET_RELEASEDDATES_REPORTING_LIST_ERROR: "SET_RELEASEDDATES_REPORTING_LIST_ERROR",
  RESET_RELEASEDDATES_REPORTING_LIST: "RESET_RELEASEDDATES_REPORTING_LIST",


  SET_BALANCESHEET_REPORTING_LIST_PENDING: "SET_BALANCESHEET_REPORTING_LIST_PENDING",
  SET_BALANCESHEET_REPORTING_LIST_SUCESS: "SET_BALANCESHEET_REPORTING_LIST_SUCESS",
  SET_BALANCESHEET_REPORTING_LIST_ERROR: "SET_BALANCESHEET_REPORTING_LIST_ERROR",
  RESET_BALANCESHEET_REPORTING_LIST: "RESET_BALANCESHEET_REPORTING_LIST",

  SET_ALLSALEREPORTING_LIST_PENDING: "SET_ALLSALEREPORTING_LIST_PENDING",
  SET_ALLSALEREPORTING_LIST_SUCESS: "SET_ALLSALEREPORTING_LIST_SUCESS",
  SET_ALLSALEREPORTING_LIST_ERROR: "SET_ALLSALEREPORTING_LIST_ERROR",
  RESET_ALLSALEREPORTING_LIST: "RESET_ALLSALEREPORTING_LIST",

  SET_RECEIVEDAMOUNT_LIST_PENDING: "SET_RECEIVEDAMOUNT_LIST_PENDING",
  SET_RECEIVEDAMOUNT_LIST_SUCESS: "SET_RECEIVEDAMOUNT_LIST_SUCESS",
  SET_RECEIVEDAMOUNT_LIST_ERROR: "SET_RECEIVEDAMOUNT_LIST_ERROR",
  RESET_RECEIVEDAMOUNT_LIST: "RESET_RECEIVEDAMOUNT_LIST",

  SET_MAINREPORTING_LIST_PENDING: "SET_MAINREPORTING_LIST_PENDING",
  SET_MAINREPORTING_LIST_SUCESS: "SET_MAINREPORTING_LIST_SUCESS",
  SET_MAINREPORTING_LIST_ERROR: "SET_MAINREPORTING_LIST_ERROR",
  RESET_MAINREPORTING_LIST: "RESET_MAINREPORTING_LIST",

  SET_CUSTOMERBALANCEREPORTING_LIST_PENDING: "SET_CUSTOMERBALANCEREPORTING_LIST_PENDING",
  SET_CUSTOMERBALANCEREPORTING_LIST_SUCESS: "SET_CUSTOMERBALANCEREPORTING_LIST_SUCESS",
  SET_CUSTOMERBALANCEREPORTING_LIST_ERROR: "SET_CUSTOMERBALANCEREPORTING_LIST_ERROR",
  RESET_CUSTOMERBALANCEREPORTING_LIST: "RESET_CUSTOMERBALANCEREPORTING_LIST",

  SET_CLOSEDJOBSREPORTING_LIST_PENDING: "SET_CLOSEDJOBSREPORTING_LIST_PENDING",
  SET_CLOSEDJOBSREPORTING_LIST_SUCESS: "SET_CLOSEDJOBSREPORTING_LIST_SUCESS",
  SET_CLOSEDJOBSREPORTING_LIST_ERROR: "SET_CLOSEDJOBSREPORTING_LIST_ERROR",
  RESET_CLOSEDJOBSREPORTING_LIST: "RESET_CLOSEDJOBSREPORTING_LIST",

  SET_TRUCKERBALANCE_LIST_PENDING: "SET_TRUCKERBALANCE_LIST_PENDING",
  SET_TRUCKERBALANCE_LIST_SUCESS: "SET_TRUCKERBALANCE_LIST_SUCESS",
  SET_TRUCKERBALANCE_LIST_ERROR: "SET_TRUCKERBALANCE_LIST_ERROR",
  RESET_TRUCKERBALANCE_LIST: "RESET_TRUCKERBALANCE_LIST",

  SET_STATUS_LIST_PENDING: "SET_STATUS_LIST_PENDING",
  SET_STATUS_LIST_SUCESS: "SET_STATUS_LIST_SUCESS",
  SET_STATUS_LIST_ERROR: "SET_STATUS_LIST_ERROR",
  RESET_STATUS_LIST: "RESET_STATUS_LIST",

  SET_STEAMSHIPLINEBALANCE_LIST_PENDING: "SET_STEAMSHIPLINEBALANCE_LIST_PENDING",
  SET_STEAMSHIPLINEBALANCE_LIST_SUCESS: "SET_STEAMSHIPLINEBALANCE_LIST_SUCESS",
  SET_STEAMSHIPLINEBALANCE_LIST_ERROR: "SET_STEAMSHIPLINEBALANCE_LIST_ERROR",
  RESET_STEAMSHIPLINEBALANCE_LIST: "RESET_STEAMSHIPLINEBALANCE_LIST",

  SET_EXCEL_LIST_PENDING: "SET_EXCEL_LIST_PENDING",
  SET_EXCEL_LIST_SUCESS: "SET_EXCEL_LIST_SUCESS",
  SET_EXCEL_LIST_ERROR: "SET_EXCEL_LIST_ERROR",
  RESET_EXCEL_LIST: "RESET_EXCEL_LIST",

  SET_CONTAINER_REPORTING_LIST_PENDING: "SET_CONTAINER_REPORTING_LIST_PENDING",
  SET_CONTAINER_REPORTING_LIST_SUCESS: "SET_CONTAINER_REPORTING_LIST_SUCESS",
  SET_CONTAINER_REPORTING_LIST_ERROR: "SET_CONTAINER_REPORTING_LIST_ERROR",
  RESET_CONTAINER_REPORTING_LIST: "RESET_CONTAINER_REPORTING_LIST",

  SET_THIRDPARTYCHARGES_REPORTING_LIST_PENDING: "SET_THIRDPARTYCHARGES_REPORTING_LIST_PENDING",
  SET_THIRDPARTYCHARGES_REPORTING_LIST_SUCESS: "SET_THIRDPARTYCHARGES_REPORTING_LIST_SUCESS",
  SET_THIRDPARTYCHARGES_REPORTING_LIST_ERROR: "SET_THIRDPARTYCHARGES_REPORTING_LIST_ERROR",
  RESET_THIRDPARTYCHARGES_REPORTING_LIST: "RESET_THIRDPARTYCHARGES_REPORTING_LIST",


  SET_REPORTING_LIST_PENDING: "SET_REPORTING_LIST_PENDING",
  SET_REPORTING_LIST_SUCESS: "SET_REPORTING_LIST_SUCESS",
  SET_REPORTING_LIST_ERROR: "SET_REPORTING_LIST_ERROR",
  RESET_REPORTING_LIST: "RESET_REPORTING_LIST",

  SET_ACCOUNT_REPORTING_LIST_PENDING: "SET_ACCOUNT_REPORTING_LIST_PENDING",
  SET_ACCOUNT_REPORTING_LIST_SUCESS: "SET_ACCOUNT_REPORTING_LIST_SUCESS",
  SET_ACCOUNT_REPORTING_LIST_ERROR: "SET_ACCOUNT_REPORTING_LIST_ERROR",
  RESET_ACCOUNT_REPORTING_LIST: "RESET_ACCOUNT_REPORTING_LIST",

  SET_EMPLOYEE_REPORTING_LIST_PENDING: "SET_EMPLOYEE_REPORTING_LIST_PENDING",
  SET_EMPLOYEE_REPORTING_LIST_SUCESS: "SET_EMPLOYEE_REPORTING_LIST_SUCESS",
  SET_EMPLOYEE_REPORTING_LIST_ERROR: "SET_EMPLOYEE_REPORTING_LIST_ERROR",
  RESET_EMPLOYEE_REPORTING_LIST: "RESET_EMPLOYEE_REPORTING_LIST",


  SET_CUSTOMERV_VOLUME_REPORTING_LIST_PENDING: "SET_CUSTOMERV_VOLUME_REPORTING_LIST_PENDING",
  SET_CUSTOMERV_VOLUME_REPORTING_LIST_SUCESS: "SET_CUSTOMERV_VOLUME_REPORTING_LIST_SUCESS",
  SET_CUSTOMERV_VOLUME_REPORTING_LIST_ERROR: "SET_CUSTOMERV_VOLUME_REPORTING_LIST_ERROR",
  RESET_CUSTOMERV_VOLUME_REPORTING_LIST: "RESET_CUSTOMERV_VOLUME_REPORTING_LIST",

  SET_HISTORY_FOR_SALES_REPORTING_LIST_PENDING: "SET_HISTORY_FOR_SALES_REPORTING_LIST_PENDING",
  SET_HISTORY_FOR_SALES_REPORTING_LIST_SUCESS: "SET_HISTORY_FOR_SALES_REPORTING_LIST_SUCESS",
  SET_HISTORY_FOR_SALES_REPORTING_LIST_ERROR: "SET_HISTORY_FOR_SALES_REPORTING_LIST_ERROR",
  RESET_HISTORY_FOR_SALES_REPORTING_LIST: "RESET_HISTORY_FOR_SALES_REPORTING_LIST",

  SET_QUOTATION_PER_MONTH_REPORTING_LIST_PENDING: "SET_QUOTATION_PER_MONTH_REPORTING_LIST_PENDING",
  SET_QUOTATION_PER_MONTH_REPORTING_LIST_SUCESS: "SET_QUOTATION_PER_MONTH_REPORTING_LIST_SUCESS",
  SET_QUOTATION_PER_MONTH_REPORTING_LIST_ERROR: "SET_QUOTATION_PER_MONTH_REPORTING_LIST_ERROR",
  RESET_QUOTATION_PER_MONTH_REPORTING_LIST: "RESET_QUOTATION_PER_MONTH_REPORTING_LIST",
};

const CONSTANT = [];

CONSTANT['roles'] = [];
CONSTANT['roles']["superadmin"] = "SuperAdmin";
CONSTANT['roles']["admin"] = "Administrator";
CONSTANT['roles']["manager"] = "Manager";
CONSTANT['roles']["booking"] = "Booking";
CONSTANT['roles']["accounts"] = "Accounts";
CONSTANT['roles']["documentation"] = "Documentation";
CONSTANT['roles']["rates"] = "Rates";
CONSTANT['roles']["sales"] = "Sales";

CONSTANT['priority'] = [];
CONSTANT['priority']["1"] = "Low";
CONSTANT['priority']["2"] = "Normal";
CONSTANT['priority']["3"] = "Medium";
CONSTANT['priority']["4"] = "High";
CONSTANT['priority']["5"] = "Critical";

export default CONSTANT;
