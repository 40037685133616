import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import api from "../Services/Axios/index";
import { requestTokenHeader } from "../Services/Helpers/helper"

const Header = (props) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userClient);
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    if (currentUser.user.role === 'customer')
      navigate("/customer/login");
    else
      navigate("/");
  };

  const searchHandler = (e) => {
    setSearchText(e.target.value.replace(/^\s+|\s+$/g, ''));
  }

  useEffect(() => {
    async function fetchResult() {
      const response = await api.get("api/v1/tslGlobalSearch", {
        params: { searchText: searchText },
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setSearchResult(response.data.result);
      } else {
        setSearchResult([]);
      }
    }
    if (searchText.length > 3) {
      fetchResult();
    } else {
      setSearchResult([]);
    }
  }, [searchText]);

  const handleNotificationClick = async (notification) => {
    if (notification.is_read === '0') {
      const response = await api.put("api/v1/tslNotifications/" + notification.id, { is_read: '1' }, { headers: requestTokenHeader() });
      if (response.data.errorCode === 0) {
        window.location.href = '/' + notification.resource + '/' + notification.resource_id;
      }
    } else {
      window.location.href = '/' + notification.resource + '/' + notification.resource_id;
    }
  }

  const handleSearchResultClick = async (search) => {
    localStorage.setItem('search', JSON.stringify({resource: search.resource, ids: search.resource_id}));
    window.location.href = '/' + search.resource + '/search';
  }

  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top">

        <button id="sidebarToggleTop" onClick={props.sidebarClick} className="border-0 btn-link d-lg-none overflow-hidden mr-3 d-flex align-item-center justify-content-center">
          <i className="fa fa-bars"></i>
        </button>

        <div className="d-none d-md-inline-block form-inline ml-md-3 my-2 my-md-0 mw-100">
          <div className="d-flex align-items-center header-heading">
            <i className={props.pageIcon ?? "fa fa-home"} aria-hidden="true"></i>
            <h4>{props.pageHeading}</h4>
          </div>
        </div>

        {
          ["admin", "booking", "documentation", "accounts", "rates", "sales", "manager"].includes(currentUser.user.role) ?
            <form className="dropdown search-box d-none d-sm-inline-block form-inline ml-md-5 my-2 my-md-0 mw-100">
              <div className="input-group search-input-group">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" className="magnifying-glass" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M232.49,215.51,185,168a92.12,92.12,0,1,0-17,17l47.53,47.54a12,12,0,0,0,17-17ZM44,112a68,68,0,1,1,68,68A68.07,68.07,0,0,1,44,112Z"></path></svg>
                <input type="text" className="form-control bg-white " onChange={e => searchHandler(e)} id="searchResultDropdown" role="list" data-bs-toggle="dropdown" aria-expanded="false" placeholder="Search you page..." />
                <div className="dropdown-list dropdown-menu search-result rounded shadow animated--grow-in w-100" aria-labelledby="searchResultDropdown">
                  {searchResult.length ? searchResult.map((res, index) => {
                    return (
                      res?.total_record > 0 ?
                        <Link className="dropdown-item" onClick={() => handleSearchResultClick(res)} key={index}>
                          <span className="small font-weight-bold"><span>{res.content}:</span> <span>{res.total_record} Records Found</span></span>
                        </Link> : ''
                    );
                  })
                    : <div className="dropdown-item"><span className="small font-weight-bold">No Result Found</span></div>
                  }
                  {/* <div className="dropdown-item text-center">
                    <span className="small font-weight-bold">Search Results: {searchResult.length}</span>
                  </div> */}
                </div>
              </div>
            </form> : ''
        }

        <ul className="navbar-nav ml-auto">
          {
            ["admin", "booking", "documentation", "accounts", "rates", "sales", "manager"].includes(currentUser.user.role) ?
              <li className="nav-item dropdown no-arrow d-sm-none" key="1">
                <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa fa-search"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                  <form className="form-inline mr-auto w-100 search-box">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text"><i className="fa fa-search text-white"></i></span>
                      </div>
                      <input type="text" className="form-control bg-white border-0 small" onChange={e => searchHandler(e)} id="searchDropdown" role="list" data-bs-toggle="dropdown" aria-expanded="false" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                      <div className="dropdown-menu search-result rounded shadow-5" aria-labelledby="searchDropdown">
                        <ul className="list-unstyled mb-0">
                          {searchResult.length ? searchResult.map((res, index) => {
                            return (
                              res?.total_record > 0 ?
                                <li>
                                  <Link className="pt-2 px-2 d-block text-decoration-none" onClick={() => handleSearchResultClick(res)} key={index}>
                                    <span className="small font-weight-bold"><span>{res.content}: <span>{res.total_records} Records Found</span></span></span>
                                  </Link>
                                  <hr className="m-0 p-0" />
                                </li> : ''
                            );
                          })
                            : <li className="pt-2 px-2"><p>No Result Found</p></li>
                          }
                        </ul>
                        {/* <hr className="my-0" />
                        <p className="text-muted text-center mb-0 mt-2"><strong>Search Results: {searchResult.length}</strong></p> */}
                      </div>
                    </div>
                  </form>
                </div>
              </li> : ''
          }
          <li className="nav-item dropdown no-arrow mx-1" key="2">
            <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fa fa-bell fa-fw"></i>
              <span className="badge badge-counter">{props.unreadCount}</span>
            </a>
            <div className="dropdown-list dropdown-menu dropdown-menu-end shadow animated--grow-in" aria-labelledby="alertsDropdown">
              {
                props.notifications.length ?
                  props.notifications.map((notification, index) => {
                    return (
                      <a className="dropdown-item" onClick={() => handleNotificationClick(notification)} key={index}>
                        <div className="small text-gray-500 d-flex justify-content-between align-items-center">
                          <span>{moment(notification.created_at).format('MMMM Do, Y H:mm')}</span>
                          {
                            notification.is_read === '0' ?
                              <span className="badge badge-success">New</span> : ''
                          }
                        </div>
                        <span className="small font-weight-bold">{notification.content}</span>
                      </a>
                    );
                  }) : ''
              }
              <Link to="/notifications" className="dropdown-item small text-center">Show All</Link>
            </div>
          </li>
          <div className="topbar-divider d-none d-sm-block"></div>
          <li className="nav-item dropdown no-arrow" key="3">
            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span className="mr-2 d-none d-lg-inline text-gray-600 small text-capitalize">{currentUser?.user ? currentUser.user.firstName + (currentUser.user.lastName ? ' ' + currentUser.user.lastName : '') : ''}</span>
              <img className="img-profile rounded-circle" src="/assets/images/profile.jpg" />
            </a>
            <div className="dropdown-menu dropdown-menu-end shadow animated--grow-in" aria-labelledby="userDropdown">
              {
                currentUser.user.role !== 'customer' ?
                  <>
                    <Link className="dropdown-item" to="/profile">
                      <i className="fa fa-user fa-sm fa-fw mr-2"></i>
                      Profile
                    </Link>
                    <div className="dropdown-divider"></div>
                  </> : ''
              }
              <Link role="button" className="dropdown-item" onClick={handleLogout}>
                <i className="fa fa-sign-out fa-sm fa-fw mr-2"></i>
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </nav>

      <p className="bg-overlay"></p>

    </>
  );
};
export default Header;
