import axios from "axios";
import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import {  displayErrorMessage, displaySuccessMessage,requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";


export const deleteTaskById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteTasks",{id},
                                   { headers:requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      const showTaskList = {};
      const resultOutput = getState()
        .taskList.showTaskList.result.map((data) => data)
        .filter((data) => data.id !== id);
      showTaskList.result = resultOutput;
      showTaskList.records = getState().taskList.showTaskList.records - 1;
      showTaskList.errorCode = 0;
      displaySuccessMessage("taskDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_TASK_LIST_SUCESS,
        payload: showTaskList,
      });
    } else {
      displayErrorMessage("taskDeleteError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_TASK_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_TASK_LIST_ERROR });
  }
};

export const gettaskList= (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslTasksList", {
                                    params: postData,
                                    headers:requestTokenHeader()
                                  });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_TASK_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_TASK_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    return dispatch({ type: USER_ACTION_TYPE.SET_TASK_LIST_ERROR });
  }
};



export const addTaskDetails = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddTasks",postData,
      { headers:requestTokenHeader() });
    if (response.data.errorCode === 0) { 
      const resultOutput = getState().taskList.showTaskList.result;
      displaySuccessMessage("taskAdded");
      if(resultOutput !== undefined){
        resultOutput.push(response.data.result);
        const showTaskList = {};
        showTaskList.result = resultOutput;
        showTaskList.records = getState().taskList.showTaskList.records + 1;
        showTaskList.errorCode = 0;
       
        return dispatch({
          type: USER_ACTION_TYPE.SET_TASK_LIST_SUCESS,
          payload: showTaskList,
        });
      }
    } else { 
      displayErrorMessage("taskAddedError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_TASK_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {

    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_TASK_LIST_ERROR });
  }
};


export const editTaskDetails = (postData) => 
 {
 return async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslEditTasks",postData,
    {
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      var resultOutput={};
      const userId = localStorage.getItem('userId');
      resultOutput.userId=userId;
      resultOutput.search='';
      resultOutput.offset=0;
      resultOutput.customerId=response.data.result.customer_id;
      displaySuccessMessage("taskUpdated");
      dispatch(gettaskList(resultOutput));

    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      displayErrorMessage("taskUpdatedError");
      
       dispatch({
        type: USER_ACTION_TYPE.SET_TASK_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
     displayErrorMessage(err.name);
     dispatch({ type: USER_ACTION_TYPE.SET_TASK_LIST_ERROR });
  }
 }
};


