import React, { useState, useEffect } from 'react';
import { requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import moment from "moment";


// import jsPDF from 'jspdf';
// import html2canvas from "html2canvas";
// import { type } from '@testing-library/user-event/dist/type';
export function JobCommentHisory(props) {
  const [fields, setFields] = useState([]);
  const [errors, setErrors] = useState({});
  const [successStatus, setSuccessStatus] = useState(false);

  useEffect(() => {
  const data =  callPageRecords(props?.jobCommentsHistory);

  setFields(data);
  
  }, [props?.jobCommentsHistory]);



  const callPageRecords = (userData) => {
    if (userData && userData.length) {
      return userData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.name}</td>
          <td>{data.job_comment} </td>
          <td>{data.created_at ? moment(data.created_at).format("YYYY-MM-DD") : ""}</td>

        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={3}>No Record Found</td>
        </tr>
      );
    }
  };


  return (
    <>
      <div className="modal-body">

        <div className="invitation-wrap">
          <div className="container">
            <div className="row login-wrap-new">
              <div className="col-md-12 col-xs-12">
                <div className="form-content">



                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr className="bg-primary text-white">
                              <th>Name</th>
                              <th>Job Comment</th>
                              <th>Date</th>

                            </tr>
                          </thead>
                          <tbody>
                            {fields}
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  );
}