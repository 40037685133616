import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
  getAllSaleReportingList
} from "../../../Services/Store/Common/allSaleReporting/allSaleReporting.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { allSaleReportingList } from "../../../Services/Store/Common/allSaleReporting/allSaleReporting.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import { requestTokenHeader, displayErrorMessage, } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import  Loader from "../../../../src/Components/Ui/loader";
const Contact = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
   const ratesListResult = useSelector(allSaleReportingList);
  // const mainClientresponse = useSelector(mainClient);
  const [searchType, setSearchType] = useState("");
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }   
    
  }, []);

  useEffect(() => {
    if (ratesListResult.result !== undefined) {
      const result = ratesListResult.result;
      if (result.length > 0) {
        setUserData(ratesListResult.result);
        setUserRecordsData(ratesListResult.records);
        setErrorResponce(ratesListResult.error_code);
      } else {
        setUserData("");
        setUserRecordsData("");
      }
    }
  }, [ratesListResult]);



  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     dispatch(getShippinglineReportingList({ searchType: searchType, search: searchKeyword, offset, start: formik.values.start_date ,end: formik.values.end_date  }));      
  //   }, 500);
  //   return () => clearTimeout(getData);
  // }, [searchKeyword]);


  /** For pagination code start here **/
  var offset = currentPage * perPage;
  const handlePageClick = (selectedPage) => {
    dispatch(
      getAllSaleReportingList({ searchType: searchType, search: searchKeyword, offset: selectedPage, start: formik.values.start_date ,end: formik.values.end_date  })
    );
  };
  /** For pagination code end here **/

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  

  const callPageRecords = (userData) => {
    //console.log(userData)
    if (userData?.[0]) {
      let text =   userData?.[0].map((data, index) =>  (        
        <tr key={index}>
          <td>{data.job_number}</td>
          <td>{data.job_date ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
          <td>{data.name}</td>
          <td>{data.shipper_name}</td>
          <td>{data.total_recievable_usd}</td>
          <td>{data.total_recievable_cad}</td>
          <td>{data.ssl_amount}</td>
          <td>{data.ssl_cad}</td>
          <td>{data.trucker_amount}</td>
          <td>{data.trucker_cad}</td>
          <td>{data.total_payable_usd}</td>
          <td>{data.total_payable_cad}</td>
          <td>{data.gain_usd}</td>
          <td>{data.gain_cad}</td>
                
        </tr>
      ));

      let text2 =   userData?.[1].map((data, index) => (
        <tr key={data.id}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td className="font-weight-bold">Total Recievable Ammount USD: {parseFloat(data.total_rec_usd).toFixed()}</td>
          <td className="font-weight-bold">Total Recievable Ammount CAD: {parseFloat(data.total_rec_cad).toFixed()}</td>

          <td className="font-weight-bold">Total Payable Ammount USD: {parseFloat(data.total_payable_usd).toFixed()}</td>
          <td className="font-weight-bold">Total Payable Ammount CAD: {parseFloat(data.total_payable_cad).toFixed()}</td>
          <td className="font-weight-bold">Total Net Gain  USD: {parseFloat(data.total_gain_usd).toFixed()}</td>
          <td className="font-weight-bold">Total Net Gain  CAD: {parseFloat(data.total_gain_cad).toFixed()}</td>       
        </tr>
      ));

      return text.concat(text2);

      


    } else {
      return (
        <tr className="text-center">
          <td colSpan={14}>No Record Found</td>
        </tr>
      );
    }
  };


  const deleteUser = async (userId2) => {
    //dispatch(deleteRatesById(userId2));
    setShowModel(false);
    setDeleteModel(false);
  
  };





  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  const validateContactFrom = Yup.object().shape({
    end_date: Yup.string().required('Field is required'),
    start_date: Yup.string().required('Field is required'),
});
let initialValues = {
  end_date: '',
  start_date:""
}

const formik = useFormik({
  initialValues: initialValues,
  validationSchema: validateContactFrom,
  onSubmit: (values) => {   

  dispatch(getAllSaleReportingList({searchType: searchType, search: searchKeyword, offset, start:values.start_date ,end: values.end_date }));

  },
});

const handleDownload = (type) => {
  fetchMyAPI2(type);
};

async function fetchMyAPI2(type) {
  if (formik.values.start_date == "" || formik.values.end_date == "") {
    displayErrorMessage("containerReportingError"); return;
  }
  setDisplay(true);

  const response = await api.post("api/v1/tslAllSaleReportingList", 
  { start: formik.values.start_date, end: formik.values.end_date, offset: 0, limit: 9999999999999 },
    { headers: requestTokenHeader() }
  );

  if (response.data.errorCode === 0) {

    if(type == "excel"){
      const rows = (response.data.data[0]).map((data) => ({
        job_number:data.job_number,
        job_date: data.job_date ? moment(data.job_date).format("YYYY-MM-DD") : "",
        name: data.name,
        shipper_name: data.shipper_name,
        total_recievable_usd: data.total_recievable_usd,
        total_recievable_cad: data.total_recievable_cad,
        ssl_amount: data.ssl_amount,
        ssl_cad: data.ssl_cad,
        trucker_amount:data.trucker_amount,
        trucker_cad:data.trucker_cad,
        total_payable_usd: data.total_payable_usd,
        total_payable_cad: data.total_payable_cad,
        gain_usd: data.gain_usd,
        gain_cad:data.gain_cad
      }));
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(rows);  
      XLSX.utils.book_append_sheet(workbook, worksheet, "TruckerReport");
      XLSX.utils.sheet_add_aoa(worksheet, [
        ["Job No.", "Job Date", "Customer Name", "Supplier", "Receivable USD","Receivable CAD", "SSL USD", "SSL CAD", "Trucker USD", "Trucker CAD", "Payable USD", "Payable CAD", "Net Gain USD", "Net Gain CAD"],
      ]);  
      XLSX.writeFile(workbook, "all-sale-reporting.xlsx", { compression: true });
    }

    if(type == "pdf"){
      const doc = new jsPDF();
      const tableColumn = ["Job No.", "Job Date", "Customer Name", "Supplier", "Receivable USD","Receivable CAD", "SSL USD", "SSL CAD", "Trucker USD", "Trucker CAD", "Payable USD", "Payable CAD", "Net Gain USD", "Net Gain CAD"];
      const tableRows = [];

      (response.data.data[0]).forEach(data => {
        const ticketData = [
        data.job_number,
        data.job_date ? moment(data.job_date).format("YYYY-MM-DD") : "",
        data.name,
        data.shipper_name,
        data.total_recievable_usd,
        data.total_recievable_cad,
        data.ssl_amount,
        data.ssl_cad,
        data.trucker_amount,
        data.trucker_cad,
       data.total_payable_usd,
        data.total_payable_cad,
        data.gain_usd,
       data.gain_cad
        ];
        tableRows.push(ticketData);
      });

      doc.autoTable(tableColumn, tableRows, { startY: 20 });
      doc.save(`all_sale_reporting.pdf`);

    }
    setDisplay(false);

  } else {
    setDisplay(false);
  }
}

  return (
    <>
      <AdminLayout pageHeading="All Sale Reporting" pageIcon="fa fa-bar-chart">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h3>All Sale Reporting</h3>
            </div>
          </div>
          <div className="container-fluid demo">
            <div
              className="panel-group dashboard-table-format"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="panel panel-default">
                <div
                  className="panel-heading"
                  role="tab"
                  id="headingOne"
                >
                  <div className="panel-body">
                    <div className="card  mb-4">
                      <div className="card-body">
                      <Loader display={display}/>
                          <div
                            id="dataTable_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                       
                       <div className="deposited p-0">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row align-items-center" > 
                              <div className="col-sm-6 col-md-3">
                              <div className="form-group">
                                  <label>Start Date</label>
                                  <input className="form-control"  
                                  type="date" 
                                  name="start_date" 
                                   {...formik.getFieldProps('start_date')}
                                  />
                                  {formik.touched.start_date && formik.errors.start_date && (
                                                <div className="errorMsg text-danger">{formik.errors.start_date}</div>
                                            )}
                                </div>  
                                </div>                         
                                <div className="col-sm-6 col-md-3">
                              <div className="form-group">
                                  <label>End Date</label>
                                  <input className="form-control"  
                                  type="date" 
                                  name="end_date" 
                                   {...formik.getFieldProps('end_date')}
                                  />
                                  {formik.touched.end_date && formik.errors.end_date && (
                                                <div className="errorMsg text-danger">{formik.errors.end_date}</div>
                                            )}
                                </div>
                                </div>
                                <div className="col-sm-12 col-md-3">
                                  <input className="btn btn-primary mr-2" type="submit" name="search" value="Go" style={{ marginTop: "15px" }} />
                                  <button className="action-tab btn btn-primary" onClick={item => handleDownload("excel")} style={{ marginTop: "15px" }} data-toggle="tooltip" data-placement="top" title="Export as Excel file"><i class="fa fa-file-excel-o"></i></button>
                                  <button className="action-tab btn btn-primary" onClick={item => handleDownload("pdf")} style={{marginLeft: "10px", marginTop: "15px" }} data-toggle="tooltip" data-placement="top" title="Export as PDF file"><i class="fa fa-file-pdf-o"></i></button>
                                </div>
                                
                              </div>
                              </form>
                            </div>
                          </div>
                          <div className="table-responsive">
                          <table className="table table-striped table-bordered" id="pdf">
                            <thead>
                              <tr className="bg-primary text-white">
                                <th>Job No.</th>
                                <th>Job Date</th>
                                <th>Customer Name</th>
                                <th>Supplier</th>
                                <th>Receivable USD</th>
                                <th>Receivable CAD</th>
                                <th>SSL USD</th>
                                <th>SSL CAD</th>
                                <th>Trucker USD</th>
                                <th>Trucker CAD</th>
                                <th>Payable USD</th>
                                <th>Payable CAD</th>
                                <th>Net Gain USD</th>
                                <th>Net Gain CAD</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ratesListResult.result !==
                                undefined &&
                                ratesListResult.result !== "" ? (
                                callPageRecords(
                                  ratesListResult.result
                                )
                              ) : (
                                <tr>
                                  <td colSpan={14}>No Record Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                                       */}
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="dataTable_paginate"
                            >
                              {userData != undefined &&
                                userData.length > 0 &&
                                userRecordsData !== undefined ? (
                                <PaginationBlock
                                  perPage={perPage}
                                  userRecordsData={userRecordsData}
                                  callHandlePageClick={(data) =>
                                    handlePageClick(data)
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
     
     
        </div>

      </AdminLayout>
    </>
  );
};
export default Contact;
