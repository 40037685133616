import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
  getPaymentReportingList
} from "../../../Services/Store/Common/paymentReporting/paymentReporting.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { paymentReportingList } from "../../../Services/Store/Common/paymentReporting/paymentReporting.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import { requestTokenHeader, displayErrorMessage, } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import  Loader from "../../../../src/Components/Ui/loader";
const Contact = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
   const ratesListResult = useSelector(paymentReportingList).showPaymentReportingList;
   const ratesListBlocking = useSelector(paymentReportingList).blocking;
  // const mainClientresponse = useSelector(mainClient);
  const [searchType, setSearchType] = useState("");
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }   
    
  }, []);

  useEffect(() => {
    setDisplay(ratesListBlocking);
    if (ratesListResult.result !== undefined) {
      const result = ratesListResult.result;
      if (result.length > 0) {
        setUserData(ratesListResult.result);
        setUserRecordsData(ratesListResult.records);
        setErrorResponce(ratesListResult.error_code);
      } else {
        setUserData("");
        setUserRecordsData("");
      }
    }
  }, [ratesListResult]);



  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     dispatch(getTruckerReportingList({ searchType: searchType, search: searchKeyword, offset, start: formik.values.start_date ,end: formik.values.end_date  }));      
  //   }, 500);
  //   return () => clearTimeout(getData);
  // }, [searchKeyword]);


  /** For pagination code start here **/
  var offset = currentPage * perPage;
  const handlePageClick = (selectedPage) => {
    dispatch(
      getPaymentReportingList({ searchType: searchType, search: searchKeyword, offset: selectedPage, start: formik.values.start_date,end: formik.values.end_date  })
    );
  };
  /** For pagination code end here **/

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  

  const callPageRecords = (userData) => {
    //console.log(userData)
    if (userData?.[0]) {
 
      let text =   userData?.[0].map((data, index) => (
        <tr key={data.id}>
          <td>{data.name}</td>
          <td>{data.when_paid ? moment(data.when_paid).format("YYYY-MM-DD") : ""}</td>
          <td>{data.mode_of_payment}</td>
          <td>{data.usd_amount}</td>
          <td>{data.cad_amount}</td>
          <td>{data.payment_remarks}</td>         
        </tr>
      ));

      let text2 =   userData?.[1].map((data, index) => (
        <tr key={data.id}>
          <td className="font-weight-bold">Total</td>         
          <td></td>
          <td></td>
          <td className="font-weight-bold">{(data.total_usd).toFixed(2)}</td>
          <td className="font-weight-bold">{(data.total_cad).toFixed(2)}</td>       
          <td></td>         
        </tr>
      ));

      return text.concat(text2);

      


    } else {
      return (
        <tr className="text-center">
          <td colSpan={8}>No Record Found</td>
        </tr>
      );
    }
  };



  const deleteUser = async (userId2) => {
    //dispatch(deleteRatesById(userId2));
    setShowModel(false);
    setDeleteModel(false);
  
  };





  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  const validateContactFrom = Yup.object().shape({
    end_date: Yup.string().required('Field is required'),
     start_date: Yup.string().required('Field is required'),
});
let initialValues = {
  end_date: '',
  start_date:""
}


const formik = useFormik({
  initialValues: initialValues,
  validationSchema: validateContactFrom,
  onSubmit: (values) => {   

  dispatch(getPaymentReportingList({searchType: searchType, search: searchKeyword, offset, start:values.start_date ,end: values.end_date }));

  },
});

const handleDownload = (type) => {
  fetchMyAPI2(type);
};

async function fetchMyAPI2(type) {
  if (formik.values.start_date == "" || formik.values.end_date == "") {
    displayErrorMessage("containerReportingError"); return;
  }
  setDisplay(true);

  const response = await api.post("api/v1/tslCustomerPaymentReportingList", 
  { start: formik.values.start_date, end: formik.values.end_date, offset: 0, limit: 9999999999999 },
    { headers: requestTokenHeader() }
  );

  if (response.data.errorCode === 0) {

    if(type == "excel"){
      const rows = (response.data.data[0]).map((data) => ({
        name:data.name,
        when_paid: data.when_paid ? moment(data.when_paid).format("YYYY-MM-DD") : "",
        mode_of_payment: data.mode_of_payment,
        usd_amount: parseFloat(data.usd_amount).toFixed(2),
        cad_amount: parseFloat(data.cad_amount).toFixed(2),
        payment_remarks: data.payment_remarks
      }));
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(rows);  
      XLSX.utils.book_append_sheet(workbook, worksheet, "TruckerReport");
      XLSX.utils.sheet_add_aoa(worksheet, [
        ["Customer Name", "When Paid", "Mode of Payment", "Amount USD", "Amount CAD","Remarks"],
      ]);  
      XLSX.writeFile(workbook, "payments_reporting.xlsx", { compression: true });
    }

    if(type == "pdf"){
      const doc = new jsPDF();
      const tableColumn =  ["Customer Name", "When Paid", "Mode of Payment", "Amount USD", "Amount CAD","Remarks"];
      const tableRows = [];

      (response.data.data[0]).forEach(data => {
        const ticketData = [
          data.name,
          moment(data.when_paid).format("YYYY-MM-DD"),
          data.mode_of_payment,
          data.usd_amount,
          data.cad_amount,
          data.payment_remarks
        ];
        console.log(data);
        tableRows.push(ticketData);
      });

      doc.autoTable(tableColumn, tableRows, { startY: 20 });
      doc.save(`payments_reporting.pdf`);

    }
    setDisplay(false);

  } else {
    setDisplay(false);
  }
}

  return (
    <>
      <AdminLayout pageHeading="Received Payment Reporting" pageIcon="fa fa-bar-chart">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h3>Received Payment Reporting</h3>
            </div>
          </div>
          <div className="container-fluid demo">
            <div
              className="panel-group dashboard-table-format"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="panel panel-default">
                <div
                  className="panel-heading"
                  role="tab"
                  id="headingOne"
                >
                  <div className="panel-body">
                    <div className="card  mb-4">
                      <div className="card-body">
                      <Loader display={display}/>
                          <div
                            id="dataTable_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                       
                       <div className="deposited">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row align-items-center" >
                              <div className="col-sm-6 col-md-4">
                              <div className="form-group">
                                  <label>Start Date</label>
                                  <input className="form-control"  
                                  type="date" 
                                  name="start_date" 
                                   {...formik.getFieldProps('start_date')}
                                  />
                                  {formik.touched.start_date && formik.errors.start_date && (
                                                <div className="errorMsg text-danger">{formik.errors.start_date}</div>
                                            )}
                                </div>
                                </div>

                                <div className="col-sm-6 col-md-4">
                                <div className="form-group">
                                  <label>End Date</label>
                                  <input className="form-control"  
                                  type="date" 
                                  name="end_date" 
                                   {...formik.getFieldProps('end_date')}
                                  />
                                  {formik.touched.end_date && formik.errors.end_date && (
                                                <div className="errorMsg text-danger">{formik.errors.end_date}</div>
                                            )}
                                </div>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                  <input className="btn btn-primary mr-2" type="submit" name="search" value="Go" style={{ marginTop: "15px"}} />
                                  <button className="action-tab btn btn-primary" onClick={item => handleDownload("excel")} style={{ marginTop: "15px" }} data-toggle="tooltip" data-placement="top" title="Export as Excel file"><i class="fa fa-file-excel-o"></i></button>
                                  <button className="action-tab btn btn-primary" onClick={item => handleDownload("pdf")} style={{marginLeft: "10px", marginTop: "15px" }} data-toggle="tooltip" data-placement="top" title="Export as PDF file"><i class="fa fa-file-pdf-o"></i></button>
                                  </div>
                              </div>
                              </form>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-striped table-bordered" id="pdf">
                              <thead>
                                <tr className="bg-primary text-white">
                                <th>Customer Name</th>
                                              <th>When Paid</th>
                                              <th>Mode of Payment</th>
                                              <th>Amount USD</th>
                                              <th>Amount CAD</th>
                                              <th>Remarks</th>
                                
                                </tr>
                              </thead>
                              <tbody>
                                {ratesListResult.result !==
                                  undefined &&
                                  ratesListResult.result !== "" ? (
                                  callPageRecords(
                                    ratesListResult.result
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={8}>No Record Found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                                       */}
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="dataTable_paginate"
                            >
                              {userData != undefined &&
                                userData.length > 0 &&
                                userRecordsData !== undefined ? (
                                <PaginationBlock
                                  perPage={perPage}
                                  userRecordsData={userRecordsData}
                                  callHandlePageClick={(data) =>
                                    handlePageClick(data)
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
     
     
        </div>

      </AdminLayout>
    </>
  );
};
export default Contact;
