import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader, getUserIds } from "../../../Helpers/helper";
import api from "../../../Axios/index";
import { addNotificationDetails } from "../notification/notification.action";

export const deleteJobsById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteJobs", { id },
      { headers: requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      const showJobsList = {};
      const resultOutput = getState()
        .jobsList.showJobsList.result.map((data) => data)
        .filter((data) => data.id !== id);
      showJobsList.result = resultOutput;
      showJobsList.records = getState().jobsList.showJobsList.records - 1;
      showJobsList.errorCode = 0;
      displaySuccessMessage("jobsDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_JOBS_LIST_SUCESS,
        payload: showJobsList,
      });
    } else {
      displayErrorMessage("jobsDeleteError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_JOBS_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_JOBS_LIST_ERROR });
  }
};

export const getjobsList = (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_JOBS_LIST_PENDING });
  try {
    const response = await api.post("api/v1/tslJobsList", postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_JOBS_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_JOBS_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    return dispatch({ type: USER_ACTION_TYPE.SET_JOBS_LIST_ERROR });
  }
};

export const addJobsDetails = (postData, searchIds) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddJobs", postData,
      { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      if (postData.customer_quote_id === undefined) {
        let notification = {
          resource_id: response.data.result.id,
          content: 'New Job #' + response.data.result.job_number + ' Created'
        };
        if (response.data.result?.customer_id > 0) {
          dispatch(addNotificationDetails({...notification, resource: 'customer/dashboard', customer_id: response.data.result.customer_id }));
        }
        if (response.data.result?.sale_person_id > 0) {
          dispatch(addNotificationDetails({...notification, resource: 'jobs', user_id: response.data.result.sale_person_id}));
        }
        getUserIds({ 'roles.name': 'documentation' }, function (docUserIds) {
          docUserIds.length && docUserIds.map((user_rec, index) => {
            dispatch(addNotificationDetails({...notification, resource: 'jobs', user_id: user_rec.id}));
          });
        });
        displaySuccessMessage("jobsAdded");
      } else {
        displaySuccessMessage("Booking Request Created");
      }
      return dispatch(getjobsList({ searchIds }));
    } else {
      displayErrorMessage("jobsAddedError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_JOBS_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_JOBS_LIST_ERROR });
  }
};

export const editJobsDetails = (postData, jobData = {}, role = '', searchIds) => {
  return async (dispatch) => {
    try {
      const response = await api.post("api/v1/tslEditJobs", postData,
        {
          headers: requestTokenHeader()
        });
      if (response.data.errorCode === 0) {
        displaySuccessMessage("jobsUpdated");
        dispatch(getjobsList({ searchIds }));
        let notification = {
          resource: 'jobs',
          resource_id: response.data.result.id,
          customer_id: null
        };
        if (postData.sale_person_id > 0 && postData.sale_person_id != jobData.sale_person_id) {
          notification['content'] = 'Job #' + postData.job_number + ' assigned to you';
          notification['user_id'] = postData.sale_person_id;
          dispatch(addNotificationDetails(notification));
        }
        if (postData.job_status_id > 0 && postData.job_status_id !== jobData.job_status_id) {
          dispatch(addNotificationDetails({
            resource: 'customer/dashboard',
            user_id: null,
            content: 'Job #' + postData.job_number + ' Status Updated',
            customer_id: postData.customer_id,
            resource_id: response.data.result.id
          }));
        }
        if (role !== 'booking') {
          getUserIds({ 'roles.name': 'booking' }, function (bookingUserIds) {
            bookingUserIds.length && bookingUserIds.map((user_rec, index) => {
              notification['content'] = 'Job #' + postData.job_number + ' Updated';
              notification['user_id'] = user_rec.id;
              dispatch(addNotificationDetails(notification));
            });
          });
        }
      } else {
        const responce = {
          result: "",
          records: 0,
          error_code: response.data.errorCode,
        };
        displayErrorMessage("jobsUpdatedError");
        dispatch({
          type: USER_ACTION_TYPE.SET_JOBS_LIST_SUCESS,
          payload: responce,
        });
      }
    } catch (err) {
      displayErrorMessage(err.name);
      dispatch({ type: USER_ACTION_TYPE.SET_JOBS_LIST_ERROR });
    }
  }
};

export const addDocumentationDetails = (postData, jobData = '', user = '', searchIds = '') => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddDocumentation", postData,
      { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("documentationAdded");
      if (user.role === 'customer') {
        let content = '';
        if (postData.getAll('pdfs') && postData.getAll('pdfs').length) {
          content = 'Files added to Job #' + jobData.job_number + ' by Customer';
        } else {
          content = 'Documentation details updated for Job #' + jobData.job_number;
        }
        getUserIds({ 'roles.name': 'documentation' }, function (docUserIds) {
          docUserIds.length && docUserIds.map((user_rec, index) => {
            dispatch(addNotificationDetails({
              resource: 'jobs',
              resource_id: jobData.id,
              user_id: user_rec.id,
              content: content
            }));
          });
        });
        dispatch(getjobsList({ customerId: user.id }));
      } else {
        dispatch(getjobsList({ searchIds }));
      }
    } else {
      displayErrorMessage("documentationAddedError");
    }
  } catch (err) {
    displayErrorMessage(err.name);
  }
};

export const editDocumentationDetails = (postData, jobData = '', user = '', searchIds = '') => {
  return async (dispatch) => {
    try {
      const response = await api.post("api/v1/tslEditDocumentation", postData,
        {
          headers: requestTokenHeader()
        });
      if (response.data.errorCode === 0) {
        displaySuccessMessage("documentationUpdated");
        if (user.role === 'customer') {
          let content = '';
          if (postData.getAll('pdfs') && postData.getAll('pdfs').length) {
            content = 'Files added to Job #' + jobData.job_number + ' by Customer';
            getUserIds({ 'roles.name': 'documentation' }, function (docUserIds) {
              docUserIds.length && docUserIds.map((user_rec, index) => {
                dispatch(addNotificationDetails({
                  resource: 'jobs',
                  resource_id: jobData.id,
                  user_id: user_rec.id,
                  content: content
                }));
              });
            });
          }
          dispatch(getjobsList({customerId: user.id }));
        } else {
          dispatch(getjobsList({ searchIds }));
        }
      } else {
        displayErrorMessage("documentationUpdatedError");
      }
    } catch (err) {
      displayErrorMessage(err.name);
    }
  }
};

export const addAccountDetails = (postData, searchIds) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddAccounts", postData,
      { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("accountsAdded");
      dispatch(getjobsList({ searchIds }));
    } else {
      displayErrorMessage("accountsAddedError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_JOBS_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_JOBS_LIST_ERROR });
  }
};

export const editAccountDetails = (postData, searchIds) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslEditAccounts", postData,
      { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("accountsUpdated");
      dispatch(getjobsList({ searchIds }));
    } else {
      displayErrorMessage("accountsUpdatedError");
    }
  } catch (err) {
    displayErrorMessage(err.name);
  }
};

export const addQuoteRateAccount = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddQuoteRateAccount", postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
    } else {
      displayErrorMessage("accountsUpdatedError");
    }
  } catch (err) {
    displayErrorMessage(err.name);
  }
};

export const addExcelDetails = (postData, searchIds) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddExcelFile", postData,
      { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("accountFileAdded");
      if (response.data.result.length) {
        const strValue = response.data.result.join(',');
        displayErrorMessage("jobs not parsed: " + strValue);
      }
      dispatch(getjobsList({ searchIds }));
    } else {
      displayErrorMessage("accountFileError");

    }
  } catch (err) {
    displayErrorMessage(err.name);
  }
};

export const addExtraCharges = (postData, searchIds) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddExtraCharges", postData,
      { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("documentationAdded");
      dispatch(getjobsList({ searchIds }));
    } else {
      displayErrorMessage("documentationAddedError");
    }
  } catch (err) {
    displayErrorMessage(err.name);
  }
};