import { USER_ACTION_TYPE } from "../../../Constant/user.constants";

const INITIAL_STATE = {
  showReportingreleasedReportingList: [],
  blocking:false,
};
export const reportingreleasedReportingListReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPE.SET_REPORTINGRELEASED_REPORTING_LIST_PENDING:
      return {
        blocking: true,
        showReportingreleasedReportingList: [],
      };

    case USER_ACTION_TYPE.SET_REPORTINGRELEASED_REPORTING_LIST_SUCESS:
      return {
        blocking: false,
        showReportingreleasedReportingList: payload,
      };

    case USER_ACTION_TYPE.SET_REPORTINGRELEASED_REPORTING_LIST_ERROR:
      return {
        blocking: true,
        showReportingreleasedReportingList: [],
      };

    case USER_ACTION_TYPE.RESET_REPORTINGRELEASED_REPORTING_LIST:
      return {
        blocking: true,
        showReportingreleasedReportingList: [],
      };

    default:
      return state;
  }
};
