import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import "./helper.css";
import TYPES from "../Constant/types";
import api from "../Axios/index";

/* called when there is need to display success messages */
const displaySuccessMessage = (key) => {
    switch (TYPES[key] !== undefined) {
        case true:
            toastr.success(TYPES[key],
                { "showMethod": "slideDown", "hideMethod": "slideUp", timeOut: 2000, "closeButton": false }
            );
            break;

        default:
            toastr.success(key,
                { "showMethod": "slideDown", "hideMethod": "slideUp", timeOut: 2000, "closeButton": false }
            );
            break;
    }

};

/* returns header for axios request */
const requestTokenHeader = () => {
    // let adminToken = JSON.parse(sessionStorage.getItem('adminToken'));
    let token = '';
    if (JSON.parse(sessionStorage.getItem('customerToken'))) {
        token = JSON.parse(sessionStorage.getItem('customerToken'))
    } else if (JSON.parse(sessionStorage.getItem('clientToken'))) {
        token = JSON.parse(sessionStorage.getItem('clientToken'))
    }
    if (token) {
        return {
            'Authorization': `bearer ${token}`,
            'content-type': 'multipart/form-data'
        };
    }
    return {};
}

const displayErrorMessage = (key) => {
    switch (TYPES[key] !== undefined) {
        case true:
            toastr.error(TYPES[key],
                { "showMethod": "slideDown", "hideMethod": "slideUp", timeOut: 2000, "closeButton": false }
            );
            break;

        default:
            toastr.error(key,
                { "showMethod": "slideDown", "hideMethod": "slideUp", timeOut: 2000, "closeButton": false }
            );
            break;
    }
};

const getUserIds = (filters, callback) => {
    async function fetchUserIds() {
        const response = await api.get("api/v1/tslUserIds", {
            params: filters,
            headers: requestTokenHeader()
        });
        if (response.data.errorCode === 0) {
            callback(response.data.result);
        } else {
            callback([]);
        }
    }
    fetchUserIds();
}

const seo = (data = {}) => {
    data.title = data.title || 'GFFCA | Login';
    data.metaDescription = data.metaDescription || 'Golden Freight Forwarding and Marketing Inc. - International Freight Forwarders';

    document.title = data.title;
    document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
}

const equipmentList = [
    { value: "20_ST", label: "20'ST" },
    { value: "40_ST", label: "40'ST" },
    { value: "40_HC", label: "40'HC" },
    { value: "45_HC", label: "45’HC" },
    { value: "20_Flat", label: "20'Flat" },
    { value: "40_Flat_Standard", label: "40' Flat Standard" },
    { value: "40_Flat_High", label: "40'Flat High" },
    { value: "20_OT", label: "20'OT" },
    { value: "40_OT", label: "40'OT" },
    { value: "40_OT_High", label: "40'OT High" },
    { value: "20_Reefer", label: "20'Reefer" },
    { value: "40_Reefer_High", label: "40'Reefer High" },
];

const commodityList = [
    "Appliances & kitchenware",
    "Art, antiques, collector pieces",
    "Auto-parts",
    "Berries",
    "Battery",
    "Beverages",
    "Boat",
    "Bone & meal",
    "Chemicals",
    "Clothes",
    "Computer and Products",
    "Cork",
    "Crane",
    "Diary products",
    "Diplomatic cargo",
    "Drums/tanks/cylinder",
    "FAK",
    "Fertilizers",
    "Fish",
    "Foodstuff",
    "Fruit & nuts",
    "Furniture",
    "Hides, fur, leather & skin",
    "Household goods",
    "Machinery",
    "Meat",
    "Metal",
    "Mineral fuels, oils, waxes",
    "Miscellaneous manfactured materials",
    "Musical instruments",
    "Oils & fats",
    "Ores, slag & ash",
    "Paper",
    "Personal care & cleaning products",
    "Personal Effects",
    "Pharmaceuticals",
    "Plants",
    "Plastic & rubber",
    "Relief Goods",
    "Salt, sulphur, earths & stone, lime & cement",
    "Seeds, beans, cereals & flour",
    "Shellfish",
    "Sports goods",
    "Tea & coffee",
    "Textiles & apparel",
    "Tile, stone & glass",
    "Tobacco",
    "Toys & games",
    "Vegetables",
    "Vehicles",
    "Wood",
    "Other",
];

export { displaySuccessMessage, displayErrorMessage, requestTokenHeader, getUserIds, seo, equipmentList, commodityList };