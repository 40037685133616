import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
export function MeetingForm(props) {

    const [fields, setFields] = useState(props.userDetails ? props.userDetails : {});
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState(false);
    const [applyCheck] = useState(false);
    const [users, setUsers] = useState([]);
    const validateTaskFrom = Yup.object().shape({
        title: Yup.string().required('Field is required').max(150),
        description: Yup.string().required('Field is required'),
        start: Yup.string().required('Field is required'),
        end: Yup.string().required('Field is required'),
        assigned_to: Yup.number().required('Field is required'),
        reminder: Yup.string().required('Field is required'),
        event_categories:Yup.string().required('Field is required'),
        visibility:Yup.string().required('Field is required'),

    });

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/tslUserListById", {
                params: "",
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setUsers(response?.data?.data);
            } else {
                setUsers("");
            }
        }

        fetchMyAPI2();

    }, []);


    let initialValues = {};
    if (fields) {
        initialValues = {
            title: fields.title,
            description: fields.description,
            start: fields.start,
            end: fields.end,
            assigned_to: fields.assigned_to,
            reminder: fields.reminder,
            visibility:fields.visibility,
            event_categories: fields.event_categories,
            attachments: fields.attachments,
        }

    } else {
        initialValues = {
            title: '',
            description: "",
            start: "",
            end:"",
            assigned_to: "",
            reminder: "",
            visibility:"",
            event_categories:"",
            attachments:"",
        }
    }


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateTaskFrom,
        onSubmit: (values) => {
            if (fields?.id) {
                const postData = {
                    title: values.title,
                    description: values.description,
                    start: values.start,
                    end: values.end,
                    assigned_to: values.assigned_to,
                    reminder: values.reminder,
                    visibility:values.visibility,
                    event_categories: values.event_categories,
                    attachments: values.attachments,
                    id: fields.id,
                }
                props.editUserDetails(postData);

            } else {

                const params = {
                    title: values.title,
                    description: values.description,
                    start: values.start,
                    end: values.end,
                    assigned_to: values.assigned_to,
                    reminder: values.reminder,
                    visibility:values.visibility,
                    event_categories: values.event_categories,
                    attachments: values.attachments,
                }
                props.addUserDetails(params)
            }


        },
    });



    return (
        <>
            <div className="modal-body invitation-wrap">
                    <div className="login-wrap-new">
                            <div className="form-content">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Title</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="title"
                                                name="title"
                                                {...formik.getFieldProps('title')}
                                            />

                                            {formik.touched.title && formik.errors.title && (
                                                <div className="errorMsg text-danger">{formik.errors.title}</div>
                                            )}

                                        </div>
                                        <div className="col-md-4">
                                            <label>Description</label>
                                            <textarea
                                                className="form-control"
                                                name="description"
                                                id="description"
                                                {...formik.getFieldProps('description')}
                                            ></textarea>
                                            {formik.touched.description && formik.errors.description && (
                                                <div className="errorMsg text-danger">{formik.errors.description}</div>
                                            )}
                                        </div>

                                        <div className="col-md-4">
                                            <label>Start Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="start"
                                                name="start"
                                                {...formik.getFieldProps('start')}
                                            />
                                            {formik.touched.start && formik.errors.start && (
                                                <div className="errorMsg text-danger">{formik.errors.start}</div>
                                            )}

                                        </div>
                                        <div className="col-md-4">
                                            <label>End Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="end"
                                                name="end"
                                                {...formik.getFieldProps('end')}
                                            />
                                            {formik.touched.end && formik.errors.end && (
                                                <div className="errorMsg text-danger">{formik.errors.end}</div>
                                            )}

                                        </div>
                                        
                                        
                                        <div className="col-md-4">
                                            <label>Assigned To</label>
                                            <select
                                                id="assigned_to"
                                                name="assigned_to"
                                                className="form-control"
                                                {...formik.getFieldProps('assigned_to')}
                                            >

                                                <option>--Select--</option>

                                                {users ?
                                                    users.map((data, index) => (
                                                        <option value={data.id} key={index.toString()}>{data.firstname + " " + (data.lastName ? ' ' + data.lastName : '')}</option>

                                                    )) : ''}
                                            </select>


                                            {formik.touched.assigned_to && formik.errors.assigned_to && (
                                                <div className="errorMsg text-danger">{formik.errors.assigned_to}</div>
                                            )}

                                        </div>
                                        <div className="col-md-4">
                                            <label>Reminder</label>
                                            <select className="form-control"
                                                name="reminder"
                                                id="reminder"
                                                {...formik.getFieldProps('reminder')}
                                            >
                                                <option>--Select--</option>
                                                <option value="5 Minutes">5 Minutes</option>
                                                <option value="10 Minutes">10 Minutes</option>
                                                <option value="15 Minutes">15 Minutes</option>
                                                <option value="30 Minutes">30 Minutes</option>
                                                <option value="1 Hour">1 Hour</option>
                                            </select>

                                            {formik.touched.reminder && formik.errors.reminder && (
                                                <div className="errorMsg text-danger">{formik.errors.reminder}</div>
                                            )}

                                        </div>
                                        <div className="col-md-4">
                                            <label>Visibility</label>
                                            <select className="form-control"
                                                name="visibility"
                                                id="visibility"
                                                {...formik.getFieldProps('visibility')}
                                            >
                                                <option>--Select--</option>
                                                <option value="public">Public</option>
                                                <option value="private">Private</option>
                                            </select>

                                            {formik.touched.visibility && formik.errors.visibility && (
                                                <div className="errorMsg text-danger">{formik.errors.visibility}</div>
                                            )}

                                        </div>
                                        <div className="col-md-4">
                                            <label>Categories</label>
                                            <select className="form-control"
                                                name="event_categories"
                                                id="event_categories"
                                                {...formik.getFieldProps('event_categories')}
                                            >
                                                <option>--Select--</option>
                                                <option value="Meeting External">Meeting External</option>
                                                <option value="Business Trip">Business Trip</option>
                                                <option value="Call">Call</option>
                                                <option value="Conference Call">Conference Call</option>
                                                <option value="Customer Service">Customer Service</option>
                                                <option value="Meeting Internal">Meeting Internal</option>
                                            </select>

                                            {formik.touched.event_categories && formik.errors.event_categories && (
                                                <div className="errorMsg text-danger">{formik.errors.event_categories}</div>
                                            )}

                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <button type="submit" className="btn btn-primary">
                                                { fields.id ? 'Save Meeting' : 'Save Meeting'}
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                    </div>
            </div>
        </>
    );
}