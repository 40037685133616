import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
    getCustomerBalanceReportingList
} from "../../../Services/Store/Common/customerBalanceReporting/customerBalanceReporting.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { customerBalanceReportingList } from "../../../Services/Store/Common/customerBalanceReporting/customerBalanceReporting.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { getcustomerList } from "../../../Services/Store/Common/customer/customer.action";
import { customerList } from "../../../Services/Store/Common/customer/customer.selector";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
const Contact = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [userData, setUserData] = useState([]);
    const [userId, setUserId] = useState("");
    const [errorResponce, setErrorResponce] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [addModel, setAddModel] = useState(false);
    const [perPage, setPerPage] = useState(25);
    const [modalData, setModalData] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [userRecordsData, setUserRecordsData] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ratesListResult = useSelector(customerBalanceReportingList);
    // const mainClientresponse = useSelector(mainClient);
    const [searchType, setSearchType] = useState("");
    const [customerData, setCustomerData] = useState("");
    useEffect(() => {
        const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

        if (!loginCheck || errorResponce === 4) {
            navigate("/");
        }

    }, []);
    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/tslCustomerListById", {
                params: { offset: 0, limit: 9999999999999, deleteCond: true  },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setCustomerData(response?.data?.data);
            } else {
                setCustomerData("");
            }
        }

        fetchMyAPI2();

    }, []);

    useEffect(() => {
        if (ratesListResult.result !== undefined) {
            const result = ratesListResult.result;
            if (result.length > 0) {
                setUserData(ratesListResult.result);
                setUserRecordsData(ratesListResult.records);
                setErrorResponce(ratesListResult.error_code);
            } else {
                setUserData("");
                setUserRecordsData("");
            }
        }
    }, [ratesListResult]);



    // useEffect(() => {
    //   const getData = setTimeout(() => {
    //     dispatch(getTruckerReportingList({ searchType: searchType, search: searchKeyword, offset, start: formik.values.start_date ,end: formik.values.end_date  }));      
    //   }, 500);
    //   return () => clearTimeout(getData);
    // }, [searchKeyword]);


    /** For pagination code start here **/
    var offset = currentPage * perPage;
    const handlePageClick = (selectedPage) => {
        dispatch(
            getCustomerBalanceReportingList({ searchType: searchType, search: searchKeyword, offset: selectedPage, customer_id: formik.values.customer_id, job_status: formik.values.job_status })
        );
    };
    /** For pagination code end here **/

    const handleChange = (event) => {
        if (searchType) {
            setSearchKeyword(event.target.value.trim());
        }
    };



    const callPageRecords = (userData) => {
        console.log(userData)
        if (userData) {
            let text = userData.map((data, index) => (
                <tr key={index}>
                    <td>{data.job_number}</td>
                    <td>{data.job_date ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
                    <td>{data.customer_id}</td>
                    <td>{data.booking_number}</td>
                    <td>{data.total_recievable_usd}</td>
                    <td>{data.total_recievable_cad}</td>
                    <td>{data.total_usd}</td>
                    <td>{data.total_cad}</td>
                    <td>{parseFloat(data.total_recievable_usd - data.total_usd).toFixed(2)}</td>
                    <td>{parseFloat(data.total_recievable_cad - data.total_cad).toFixed(2)}</td>
                </tr>
            ));



            return text;




        } else {
            return (
                <tr className="text-center">
                    <td colSpan={10}>No Record Found</td>
                </tr>
            );
        }
    };


    const callPageRecords2 = (userData) => {
        if (userData) {
            let total_payable_usd = 0;
            let total_payable_cad = 0;
            let total_paid_usd = 0;
            let total_paid_cad = 0;
            let total_balance1 = 0;
            let total_balance = 0
            userData.forEach(element => {
                total_payable_usd += element.total_recievable_usd;
                total_payable_cad += element.total_recievable_cad;
                total_paid_usd += element.total_usd;
                total_paid_cad += element.total_cad;
            });

            total_balance1 = total_payable_usd - total_paid_usd;
            total_balance = total_payable_cad - total_paid_cad;
            return (
                <>
                    <span>Total  Payable USD: <b> ${parseFloat(total_payable_usd).toFixed(2)}</b></span><br />
                    <span>Total Payable CAD: <b>{parseFloat(total_payable_cad).toFixed(2)}</b></span><br />
                    <br />
                    <span>Total Paid USD: $<b>{parseFloat(total_paid_usd).toFixed(2)}</b></span><br />
                    <span>Total Paid CAD: $<b>{parseFloat(total_paid_cad).toFixed(2)}</b></span><br />

                    <br />
                    <span>Total Balance USD: <b> $ {parseFloat(total_balance1).toFixed(2)}</b></span><br />
                    <span>Total Balance CAD: <b> ${parseFloat(total_balance).toFixed(2)}</b></span><br />
                </>
            )






        } else {
            return (
                ""
            );
        }
    };



    const deleteUser = async (userId2) => {
        //dispatch(deleteRatesById(userId2));
        setShowModel(false);
        setDeleteModel(false);

    };





    const handleSearhChange = (event) => {
        setSearchType(event.target.value);
    };

    const validateContactFrom = Yup.object().shape({
        job_status: Yup.string().required('Field is required'),
        customer_id: Yup.number().required('Field is required'),
        // start_date: Yup.string().required('Field is required'),
    });
    let initialValues = {
        customer_id: '',
        job_status: ""
    }
   
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {

            dispatch(getCustomerBalanceReportingList({ searchType: searchType, search: searchKeyword, offset, customer_id: formik.values.customer_id, job_status: formik.values.job_status }));

        },
    });

    return (
        <>
            <AdminLayout pageHeading="Customer Balance Reporting" pageIcon="fa fa-bar-chart">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <h3>Customer Balance</h3>
                        </div>
                    </div>
                    <div className="container-fluid demo">
                        <div
                            className="panel-group dashboard-table-format"
                            id="accordion"
                            role="tablist"
                            aria-multiselectable="true"
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="headingOne"
                                >
                                    <div className="panel-body">
                                        <div className="card  mb-4">
                                            <div className="card-body">
                                                
                                                    <div
                                                        id="dataTable_wrapper"
                                                        className="dataTables_wrapper dt-bootstrap4"
                                                    >

                                                        <div className="deposited p-0">
                                                            <form onSubmit={formik.handleSubmit}>


                                                                <div className="row align-items-center">
                                                                    <div className="col-md-4 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Select Customer</label>
                                                                        <select name="customer_id" className="form-control user-success"
                                                                            {...formik.getFieldProps('customer_id')}

                                                                        >
                                                                            <option>Select</option>
                                                                            {
                                                                                customerData && customerData.map((customer, index) => {
                                                                                    return (<option key={index} value={customer.id}>{customer.firstname}</option>);
                                                                                })
                                                                            }
                                                                        </select>

                                                                        {formik.touched.customer_id && formik.errors.customer_id && (
                                                                            <div className="errorMsg text-danger">{formik.errors.customer_id}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>Job Status Filter</label>
                                                                        <select name="job_status" className="form-control user-success"
                                                                            {...formik.getFieldProps('job_status')}
                                                                        >

                                                                            <option selected="" disabled="">--Select Type--</option>



                                                                            <option>On</option>

                                                                            <option>Off</option>

                                                                        </select>
                                                                        {formik.touched.job_status && formik.errors.job_status && (
                                                                            <div className="errorMsg text-danger">{formik.errors.job_status}</div>
                                                                        )}
                                                                    </div>
                                                                    </div>

                                                                    <div className="col-md-4 col-sm-12">
                                                                        <input type="submit" className="btn btn-primary mr-2" name="search" value="Go" style={{ marginTop: "15px" }} />
                                                                   
                                                                    </div>
                                                                </div>
                                                            
                                                            </form>

                                                            
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                    <table className="table table-striped table-bordered" id="pdf">
                                                        <thead>
                                                            <tr className="bg-primary text-white">

                                                                <th>Job No.</th>
                                                                <th>Job Date</th>
                                                                <th>Customer Name</th>
                                                                <th>Booking No.</th>
                                                                <th>Total Receivable USD</th>
                                                                <th>Total Receivable CAD</th>
                                                                <th>Total Received USD</th>
                                                                <th>Total Received CAD</th>
                                                                <th>Balance USD</th>
                                                                <th>Balance CAD</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ratesListResult.result !==
                                                                undefined &&
                                                                ratesListResult.result !== "" ? (
                                                                callPageRecords(
                                                                    ratesListResult.result
                                                                )
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={10}>No Record Found</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>

                                                    {ratesListResult.result !==
                                                        undefined &&
                                                        ratesListResult.result !== "" ? (
                                                        callPageRecords2(
                                                            ratesListResult.result
                                                        )
                                                    ) : (
                                                        ""
                                                    )}

                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-5">
                                                        {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                                       */}
                                                    </div>
                                                    <div className="col-sm-12 col-md-7">
                                                        <div
                                                            className="dataTables_paginate paging_simple_numbers"
                                                            id="dataTable_paginate"
                                                        >
                                                            {userData != undefined &&
                                                                userData.length > 0 &&
                                                                userRecordsData !== undefined ? (
                                                                <PaginationBlock
                                                                    perPage={perPage}
                                                                    userRecordsData={userRecordsData}
                                                                    callHandlePageClick={(data) =>
                                                                        handlePageClick(data)
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </AdminLayout>
        </>
    );
};
export default Contact;
