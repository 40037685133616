import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader, commodityList } from "../../../../Services/Helpers/helper";
import api from "../../../../Services/Axios/index";
import moment from "moment";
import AddRemoveMultipleVIN from "../../../../Components/Ui/AddRemoveMultipleVIN";
import Select from 'react-select'

export function DocumentationForm(props) {
    const MAX_COUNT = 5;
    const fileInput = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileLimit, setFileLimit] = useState(false);
    const [vin, setVin] = useState([]);
    const [shipperList, setShipperList] = useState([]);
    const [consigneeList, setConsigneeList] = useState([]);

    useEffect(() => {
        setShipperList([]);
        props.shipperConsignee.length && props.shipperConsignee[0].map((data, index) => {
            setShipperList((prev) => [...prev, { value: data.id, label: data.contact_person }]);
        });
        setConsigneeList([]);
        props.shipperConsignee.length && props.shipperConsignee[1].map((data, index) => {
            setConsigneeList((prev) => [...prev, { value: data.id, label: data.contact_person }]);
        });
    }, [props.shipperConsignee]);

    const validateContactFrom = Yup.object().shape({
        job_number: Yup.number().integer().required('Job nubmer is required'),
        bl_number: Yup.string(),
        shipper: Yup.string().nullable(true),
        consignee: Yup.string().nullable(true),
        commodity: Yup.string().nullable(true),
        service_id: Yup.number().nullable(true),
        released_received_id: Yup.number().nullable(true),
        release_date: Yup.string().nullable(true),
        eta: Yup.string().nullable(),
        steamship_invoice_number: Yup.string().nullable(),
        job_comment: Yup.string().nullable(true),
        job_status_id: Yup.string().nullable(true),
        customer_comment: Yup.string().nullable(true),
        vin: Yup.string().nullable(true),
    });

    let initialValues = {};
    if (props?.documentationDataById?.[0][0]) {
        initialValues = {
            id: parseInt(props.documentationDataById[0][0].id),
            job_number: props.documentationDataById[0][0].job_number,
            bl_number: props.documentationDataById[0][0].bl_number,
            shipper: props.documentationDataById[0][0].shipper,
            consignee: props.documentationDataById[0][0].consignee,
            commodity: props.documentationDataById[0][0].commodity,
            released_received_id: props.documentationDataById[0][0].released_received_id,
            release_date: props.documentationDataById[0][0].release_date,
            eta: props.documentationDataById[0][0].eta,
            etd: props.documentationDataById[0][0].etd,
            steamship_invoice_number: props.documentationDataById[0][0].steamship_invoice_number,
            file_head: "",
            job_comment: "",
            job_status_id: props?.jobsDetails?.job_status_id ? props.jobsDetails.job_status_id : "",
            customer_comment: props.documentationDataById[0][0].customer_comment,
            vin: ""
        }
    } else {
        initialValues = {
            job_number: props.jobsDetails.id,
            bl_number: '',
            shipper: '',
            consignee: '',
            commodity: props?.jobsDetails.commodity,
            released_received_id: '',
            release_date: '',
            eta: '',
            etd: '',
            steamship_invoice_number: '',
            file_head: "",
            job_comment: "",
            job_status_id: props?.jobsDetails?.job_status_id ? props.jobsDetails.job_status_id : "",
            customer_comment: "",
            vin: ""
        }
    }
    async function deleteFiles(id) {
        const response = await api.post("api/v1/tslDeleteFiles", {
            id: id
        },
            { headers: requestTokenHeader() }
        );
        if (response.data.errorCode === 0) {
            displaySuccessMessage("fileDeletedSuccess");
            document.getElementById(`id-${id}`).remove();
        } else {
            displayErrorMessage("fileDeletedError");
        }
    }
    const _handleDeleteClickFiles = (id) => {
        deleteFiles(id);
    };

    async function deleteVin(id) {
        const response = await api.post("api/v1/tslDeleteVin", {
            id: id
        },
            { headers: requestTokenHeader() }
        );
        if (response.data.errorCode === 0) {
            displaySuccessMessage("jobVinDeletedSuccess");
            document.getElementById(`id-${id}`).remove();
        } else {
            displayErrorMessage("jobVinDeletedError");
        }
    }
    const _handleDeleteVin = (id) => {
        deleteVin(id);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append("job_number", parseInt(values.job_number));
            formData.append("bl_number", values.bl_number);
            formData.append("shipper", values.shipper);
            formData.append("consignee", values.consignee);
            formData.append("commodity", values.commodity);
            formData.append("released_received_id", values.released_received_id);
            formData.append("release_date", values.release_date);
            formData.append("eta", values.eta);
            formData.append("etd", values.etd);
            formData.append("steamship_invoice_number", values.steamship_invoice_number);
            if (values?.file_head) {
                formData.append("file_head", values.file_head);
            }
            if (values?.pdfs) {
                for (let i = 0; i < values.pdfs.length; i++) {
                    formData.append("pdfs", values.pdfs[i])
                }
            }
            formData.append("job_comment", JSON.stringify([]));
            formData.append("job_status_id", values.job_status_id);
            formData.append("customer_comment", values.customer_comment ?? '');
            let vinData = JSON.stringify((vin && Object.keys(vin).length !== 0) ? Array.isArray(vin) ? vin : [].push(vin) : []);
            formData.append("vin", vinData);
            if (props?.documentationDataById?.[0][0]) {
                formData.append("id", parseInt(props?.documentationDataById?.[0][0].id));
                props.editUserDetails(formData);
            } else {
                props.addUserDetails(formData)
            }
        },
    });

    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        });
        if (!limitExceeded) setUploadedFiles(uploaded);
    }

    const handleFileEvent = (e) => {
        let chosenFiles = "";
        if (e._reactName == "onChange") {
            chosenFiles = Array.prototype.slice.call(e.target.files);
        } else {
            chosenFiles = Array.prototype.slice.call(e.dataTransfer.files);
        }

        handleUploadFiles(chosenFiles);
    }
    const handelDeleteFiles = (e) => {
        formik.setFieldValue("pdfs", []);
        formik.setFieldValue("file_head", null);
        setUploadedFiles([]);

    }
    const handleOndragOver = event => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        let myFiles = [];
        if(formik.values.pdfs !== undefined) {
            myFiles = formik.values.pdfs;
        }
        myFiles = myFiles.concat(Array.from(files));
        formik.setFieldValue("pdfs", myFiles);
        handleFileEvent(event);

    }
    const handleOndrop = event => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let files = event.dataTransfer.files;
        let myFiles = [];
        if(formik.values.pdfs !== undefined) {
            myFiles = formik.values.pdfs;
        }
        myFiles = myFiles.concat(Array.from(files));
        formik.setFieldValue("pdfs", myFiles);
        handleFileEvent(event);
    }
    const submitVin = async (params) => {
        setVin(params);
    };

    return (
        <>
            <div className="modal-body">
                <div className="row login-wrap-new">
                    <form className='invitation-wrap' onSubmit={formik.handleSubmit} encType="multipart/form-data">
                        <div className="col-md-12 col-xs-12">
                            <div className="form-content">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Shipper</label>
                                        <Select
                                            name="shipper"
                                            options={shipperList}
                                            onChange={(selectedOption) => formik.setFieldValue('shipper', selectedOption.value)}
                                            value={shipperList.find(function (option) { return option.value == formik.values.shipper })}
                                            label="Single select"
                                        />
                                        {formik.touched.shipper && formik.errors.shipper && (
                                            <div className="errorMsg text-danger">{formik.errors.shipper}</div>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label>Consignee</label>
                                        <Select
                                            name="consignee"
                                            options={consigneeList}
                                            onChange={(selectedOption) => formik.setFieldValue('consignee', selectedOption.value)}
                                            value={consigneeList.find(function (option) { return option.value == formik.values.consignee })}
                                            label="Single select"
                                        />
                                        {formik.touched.consignee && formik.errors.consignee && (
                                            <div className="errorMsg text-danger">{formik.errors.consignee}</div>
                                        )}
                                    </div>
                                    <div className="col-sm-4">
                                        <label>Commodity</label>
                                        <select className="form-control"
                                            name="commodity"
                                            {...formik.getFieldProps('commodity')}

                                            style={{   pointerEvents: formik.values.commodity !="" ? "none" : ""}}
                                        >
                                            <option>Select</option>
                                            {
                                                commodityList.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                        {formik.touched.commodity && formik.errors.commodity && (
                                            <div className="errorMsg text-danger">{formik.errors.commodity}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="row" style={{ display: props?.documentationDataById?.[0][0] ? "" : "none" }} >
                                    {props?.documentationDataById?.[1] && props?.documentationDataById?.[1].map((item, idx) => {
                                        return (
                                            ["BL Draft", "B13 Document", "accounts", "Release", "Booking Confirmation"].includes(item.type) && (item.uploaded_by === 'customer' || item.approved === '1') ?
                                                <div key={idx} id={`id-${item.id}`} className="col-lg-3 col-sm-6 uploded-files-col">
                                                    <div className='uploded-files'>
                                                        <p className='d-flex justify-content-between align-items-center mb-0'>
                                                            <b>{item.type}</b>
                                                            <a className="remove-file me-1" onClick={() => _handleDeleteClickFiles(item.id)}>
                                                                <i className="fa fa-trash"></i>
                                                            </a>
                                                        </p>
                                                        <p className='d-flex justify-content-between mb-0 mt-2'>
                                                            <code className='w-100' title={item.file_name}>
                                                                <a href={window.location.hostname == "localhost" ? `http://localhost:3001/Images/uploads/${item.file_name}` : `http://golden-test.com:3001/Images/uploads/${item.file_name}`} target="_blank">{item.file_name}</a>
                                                            </code>
                                                        </p>
                                                    </div>
                                                </div> : ""
                                        );
                                    })}
                                </div>
                                <div className="row mt-4">
                                    <div className=" col-sm-12">
                                        <div
                                            className="drop_zone"
                                            onDragOver={handleOndragOver}
                                            onDrop={handleOndrop}
                                            onClick={() => fileInput.current.click()}
                                        >
                                            <p>Drag and drop files here....</p>
                                            <input
                                                multiple
                                                type="file"
                                                name="pdfs"
                                                id="pdfs123"
                                                // accept="application/pdf"
                                                ref={fileInput} hidden
                                                onChange={(event) => {
                                                    const files = event.target.files;
                                                    let myFiles = [];
                                                    if(formik.values.pdfs !== undefined) {
                                                        myFiles = formik.values.pdfs;
                                                    }
                                                    myFiles = myFiles.concat(Array.from(files));
                                                    formik.setFieldValue("pdfs", myFiles);
                                                    handleFileEvent(event);
                                                }}
                                                disabled={fileLimit}
                                            />
                                        </div>
                                        {formik.touched.released_received_id && formik.errors.released_received_id && (
                                            <div className="errorMsg text-danger">{formik.errors.released_received_id}</div>
                                        )}
                                    </div>

                                </div>
                                {uploadedFiles.length > 0 && (<div className="close fileinput-remove" onClick={handelDeleteFiles}>×</div>)}
                                {uploadedFiles.length > 0 && (<div className="row" style={{ marginTop: "50px", border: "1px solid", paddingTop: "10px", marginBottom: "20px" }}>
                                    {uploadedFiles.map((file, index) => (
                                        <div className=" col-sm-2">
                                            <div className="form-group">
                                                <span className="btn-default btn-file file-upload-back">
                                                    <div className="file-input" style={{ border: "1px solid", borderRadius: "5px", padding: "2px" }}>
                                                        <div className="file-preview">
                                                            <div className="file-drop-disabled">
                                                                <div className="file-preview-thumbnails">
                                                                    <div className="file-preview-frame" id="preview-1692267561537-2" data-fileindex="2" title="Policy Document.pdf" style={{ height: "160px" }}>
                                                                        <select
                                                                            className="form-control file-caption-name"
                                                                            name={`file_head[${index}]`} required
                                                                            {...formik.getFieldProps(`file_head[${index}]`)}
                                                                        >
                                                                            <option value="">--Select Heading--</option>
                                                                            <option value="3">BL Draft</option>
                                                                            <option value="4">B13</option>
                                                                            <option value="6">Release</option>
                                                                            {formik.values.commodity === "Vehicles" && <option value="18">VIN</option>}
                                                                        </select>
                                                                        <object className="file-object" data={URL.createObjectURL(file)} type="application/pdf" width="160px" height="160px"></object>
                                                                    </div>
                                                                </div>
                                                                <div className="clearfix"></div>
                                                                <div className="file-preview-status text-center text-success"></div>
                                                                <div className="kv-fileinput-error file-error-message" ></div>
                                                            </div>
                                                        </div>

                                                        <div className="input-group ">
                                                            <div tabIndex="500" className="form-control file-caption  kv-fileinput-caption">
                                                                <div className="file-caption-name" title={file.name} wq>
                                                                    <span className="glyphicon glyphicon-file kv-caption-icon"></span>{file.name.length > 22 ? file.name.substring(0, 22 - 3) + "..." : file.name}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>)}

                                <div className="row" >
                                    <div className="col-sm-12">
                                        <label>Comment</label>
                                        <textarea
                                            className="form-control"
                                            type="text"
                                            name="customer_comment"
                                            id="customer_comment"
                                            {...formik.getFieldProps('customer_comment')}
                                        />
                                        {formik.touched.customer_comment && formik.errors.customer_comment && (
                                            <div className="errorMsg text-danger">{formik.errors.customer_comment}</div>
                                        )}

                                    </div>
                                </div>

                                {formik.values.commodity === "Vehicles" && <div className="row" >
                                    <div className=" col-sm-12">
                                        <AddRemoveMultipleVIN
                                            addJobComment={(params) => submitVin(params)}
                                        />
                                    </div>
                                </div>}



                                {formik.values.commodity === "Vehicles" && props?.jobVin && props?.jobVin.map((item, idx) => {

                                    let apiResponse = JSON.parse(item.response);

                                    const entries = (apiResponse ?.[1] && apiResponse ?.[1] != "") ? JSON.stringify(apiResponse[1]) : "" ;

                                    return (
                                        <div key={idx} id={`id-${item.id}`} className="row align-items-center" style={{ display: props?.jobVin ? "" : "none" }}>
                                            <div className="col-md-10">
                                                <div className='comment-box'>
                                                    {/* <div className='img-blocks d-none'>
                                                    <img src="https://dashcode-react.codeshaper.net/assets/user-1.ad58ce72.jpg" />
                                                </div> */}
                                                    <p className='p-0 m-0'>
                                                        <span className='d-block date'>{moment(item.created_at).format("YYYY-MM-DD")}</span>
                                                        <span className='d-block date'>{entries}</span>
                                                       


                                                        {item.vin_number}</p>


                                                    <div className='del-button'>
                                                        <a
                                                            className="remove-file del-comment"
                                                            onClick={() => _handleDeleteVin(item.id)}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className='row'>
                                    <div className="col-sm-4">
                                        <input type="submit" name="submit" className="btn btn-primary" value={props?.documentationDataById?.[0][0] ? "Save Documents" : "Save Documents"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}