import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader,getUserIds } from "../../../Helpers/helper";
import api from "../../../Axios/index";
import { addNotificationDetails } from "../notification/notification.action";

export const deleteUserById = (userId) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteCustomer", { userId },
      { headers: requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      const showList = {};
      const resultOutput = getState()
        .userListById.showList.result.map((data) => data)
        .filter((data) => data.lLoginID !== userId);
      showList.result = resultOutput;
      showList.records = getState().userListById.showList.records - 1;
      showList.errorCode = 0;
      displaySuccessMessage("userDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: showList,
      });
    } else {
      displayErrorMessage("userDeleteError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_USER_LIST_ERROR });
  }
};

export const getcustomerList = (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslCustomerListById", {
      params: postData,
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    if(err?.response?.data?.errorCode === 4) {
      displayErrorMessage(err?.response?.data?.errorMessage);  
    } else {
      displayErrorMessage(err.message);
    }
    return dispatch({
      type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_SUCESS,
      payload: {
        result: "",
        records: 0,
        error_code: err?.response?.data?.errorCode,
      }
    });
  }
};

export const changeUserPassword = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslChangeCustomerPassword", postData,
      { headers: requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      displaySuccessMessage("userPasswordChangeSuccess");
    } else {
      displayErrorMessage("userPasswordChangeError");
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_USER_LIST_ERROR });
  }
};

export const addCustomerDetails = (postData, searchIds = '') => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddCustomers", postData,
      { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("userAdded");
      dispatch(getcustomerList({ searchIds }));
      if(postData.current_login_urer_role !== 'documentation' && postData.status == "inactive"){
        getUserIds({ 'roles.name': 'documentation' }, function (userIds) {
          userIds.length && userIds.map((user_rec, index) => {
            dispatch(addNotificationDetails({
              user_id: user_rec.id,
              resource: 'customer',
              resource_id: response.data.result.id,
              content: 'New customer added having username  #' + postData.username + ' by ' + postData.sales_person_name
            }));
          });
        }); 
      }
    } else {
      if(response.data.errorMessage == "username already exist") {
        displayErrorMessage("usernameAlreadyExist");
      }else{
        displayErrorMessage("userAddedError");
      }
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_USER_LIST_ERROR });
  }
};

export const editCustomerDetails = (postData, searchIds = '') => {
  return async (dispatch) => {
    try {
      const response = await api.post("api/v1/tslEditCustomer", postData,
        {
          headers: requestTokenHeader()
        });
      if (response.data.errorCode === 0) {
        var resultOutput = {};
        if(postData?.role == "customer"){
          resultOutput.role = 'customer';
          resultOutput.id = postData.id;
        }
        resultOutput.searchIds = searchIds;
        displaySuccessMessage("userUpdated");
        dispatch(getcustomerList(resultOutput));
      } else {
        const responce = {
          result: "",
          records: 0,
          error_code: response.data.errorCode,
        };
        displayErrorMessage("userUpdatedError");
        dispatch({
          type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
          payload: responce,
        });
      }
    } catch (err) {
      displayErrorMessage(err.name);
      dispatch({ type: USER_ACTION_TYPE.SET_USER_LIST_ERROR });
    }
  }
};