import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
  getAccountReportingList
} from "../../../Services/Store/Common/accountReporting/accountReporting.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { accountReportingList } from "../../../Services/Store/Common/accountReporting/accountReporting.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
const Contact = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ratesListResult = useSelector(accountReportingList);
  const [customerData, setCustomerData] = useState("");
  const [shipperData, setShipperData] = useState("");
  const [exportCoordinatorData, setExportCoordinatorData] = useState("");
  const [importCoordinatorData, setImportCoordinatorData] = useState("");
  const [jobStatusData, setJobStatusData] = useState("");
  // const mainClientresponse = useSelector(mainClient);
  const [searchType, setSearchType] = useState("");

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }

  }, []);

  useEffect(() => {
    if (ratesListResult.result !== undefined) {
      const result = ratesListResult.result;
      if (result.length > 0) {
        setUserData(ratesListResult.result);
        setUserRecordsData(ratesListResult.records);
        setErrorResponce(ratesListResult.error_code);
      } else {
        setUserData("");
        setUserRecordsData("");
      }
    }
  }, [ratesListResult]);

  useEffect(() => {
    async function fetchMyAPI2() {
      const response = await api.get("api/v1/tslGetAllShipper", {
        params: { offset: 0, limit: 9999999999999 },
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setShipperData(response?.data?.data);
      } else {
        setShipperData("");
      }
    }

    fetchMyAPI2();

  }, []);

  useEffect(() => {
    async function fetchMyAPI2() {
      const response = await api.get("api/v1/tslCustomerListById", {
        params: { offset: 0, limit: 9999999999999, deleteCond: true  },
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setCustomerData(response?.data?.data);
      } else {
        setCustomerData("");
      }
    }

    fetchMyAPI2();

  }, []);

  useEffect(() => {
    async function fetchMyAPI3() {
      const response = await api.get("api/v1/tslExportDocumentCoordinatorList", {
        params: { offset: 0, limit: 9999999999999 },
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setExportCoordinatorData(response?.data?.data);
      } else {
        setExportCoordinatorData("");
      }
    }

    fetchMyAPI3();

  }, []);

  useEffect(() => {
    async function fetchMyAPI4() {
      const response = await api.get("api/v1/tslImportDocumentCoordinatorList", {
        params: { offset: 0, limit: 9999999999999 },
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setImportCoordinatorData(response?.data?.data);
      } else {
        setImportCoordinatorData("");
      }
    }

    fetchMyAPI4();

  }, []);

  useEffect(() => {
    async function fetchMyAPI5() {
      const response = await api.get("api/v1/tslJobsStatusList", {
        params: { offset: 0, limit: 9999999999999 },
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setJobStatusData(response?.data?.data);
      } else {
        setJobStatusData("");
      }
    }

    fetchMyAPI5();

  }, []);

  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     dispatch(getTruckerReportingList({ searchType: searchType, search: searchKeyword, offset, start: formik.values.start_date ,end: formik.values.end_date  }));      
  //   }, 500);
  //   return () => clearTimeout(getData);
  // }, [searchKeyword]);


  /** For pagination code start here **/
  var offset = currentPage * perPage;
  const handlePageClick = (selectedPage) => {
    dispatch(
      getAccountReportingList({
        searchType: searchType, 
        search: searchKeyword, 
        offset: selectedPage,
        shipper: formik.values.shipper,
        customer: formik.values.customer,
        status: formik.values.status,
        steamship: formik.values.steamship,
        job_complete: formik.values.job_complete,
        job_for: formik.values.job_for,
        start_date: formik.values.start_date,
        end_date: formik.values.end_date,
        export: formik.values.export,
        import: formik.values.import,

      })
    );
  };
  /** For pagination code end here **/

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };



  const callPageRecords = (userData) => {
    //console.log(userData)
    if (userData) {
      let text = userData.map((data, index) => (
        <tr key={index}>
          <td>{data.job_number}</td>
          <td>{data.job_date ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
          <td>{data.job_for}</td>
          <td>{data.export_documentation_coordinator_name}</td>
          <td>{data.import_documentation_coordinator_name}</td>
          <td>{data.customers_name}</td>
          <td>{data.booking_number}</td>
          <td>{data.bl_number}</td>
          <td>{data.carrier_name}</td>
          <td>{data.destination}</td>
          <td>{data.container_number}</td>
          <td></td>
          <td>{data.release_date ? moment(data.release_date).format("YYYY-MM-DD") : ""}</td>
          <td>{data.eta ? moment(data.eta).format("YYYY-MM-DD") : ""}</td>
          <td>{data.ssl_amount == null ? 0 : data.ssl_amount} {data.ssl_paid_date == '0000-00-00' || data.ssl_paid_date == null ? "Not Paid" : "Paid"}
            <br />
            {data.ssl1_amount1 != 0.00 ? data.ssl1_amount : data.ssl_date_new == '0000-00-00' || data.ssl_date_new == null  ? "Not Paid" : "Paid"}
          </td>
          <td>
            {data.total_recievable_usd == null ? 0 : data.total_recievable_usd} USD
            <br />
            {data.total_recievable_cad == null ? 0 : data.total_recievable_cad} CAD

          </td>
          <td>{ data.total_usd_third_party + data.total_usd} USD
            <br />
            {data.total_cad_third_party + data.total_cad} CAD</td>

          <td>
            {data.total_usd + data.total_recievable_usd + data.total_usd_third_party} USD
            <br />
            {data.total_cad + data.total_recievable_cad + data.total_cad_third_party} CAD


          </td>
          <td>
            {data.gain_usd == null ? 0 : data.gain_usd} USD
            <br />
            {data.gain_cad == null ? 0 : data.gain_cad} CAD
          </td>
          <td>{data.current_status}</td>
          <td>{(data.job_completed != "" && data.job_completed !== null) ? data.job_completed : "No"}</td>

        </tr>
      ));



      return text;




    } else {
      return (
        <tr className="text-center">
          <td colSpan={21}>No Record Found</td>
        </tr>
      );
    }
  };


  const deleteUser = async (userId2) => {
    //dispatch(deleteRatesById(userId2));
    setShowModel(false);
    setDeleteModel(false);

  };





  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  const validateContactFrom = Yup.object().shape({
    // end_date: Yup.string().required('Field is required'),
    // start_date: Yup.string().required('Field is required'),
  });
  let initialValues = {
    shipper: '',
    customer: '',
    status: '',
    steamship: '',
    job_complete: '',
    job_for: '',
    start_date: '',
    end_date: '',
    export: '',
    import: '',
  }
  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateContactFrom,
    onSubmit: (values) => {

      dispatch(getAccountReportingList({
        searchType: searchType, search: searchKeyword, offset,
        shipper: values.shipper,
        customer: values.customer,
        status: values.status,
        steamship: values.steamship,
        job_complete: values.job_complete,
        job_for: values.job_for,
        start_date: values.start_date,
        end_date: values.end_date,
        export: values.export,
        import: values.import,

      }));

    },
  });

  return (
    <>
      <AdminLayout pageHeading="Account Reporting" pageIcon="fa fa-bar-chart">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h3>Search Jobs</h3>
            </div>
          </div>
          <div className="container-fluid demo">
            <div
              className="panel-group dashboard-table-format"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="panel panel-default">
                <div
                  className="panel-heading"
                  role="tab"
                  id="headingOne"
                >
                  <div className="panel-body">
                    <div className="card  mb-4">
                      <div className="card-body">

                        <div
                          id="dataTable_wrapper"
                          className="dataTables_wrapper dt-bootstrap4"
                        >

                          <div className="deposited p-0">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row align-items-center" >
                                <div className="col-md-3 col-sm-4">
                                  <div className="form-group">
                                    <label>Shipper</label>
                                    <select className="form-control" name="shipper"
                                      {...formik.getFieldProps('shipper')}
                                    >
                                      <option value="">--Select Shipper--</option>
                                      {
                                        shipperData && shipperData.map((shipper, index) => {
                                          return (<option key={index} value={shipper.id}>{shipper.contact_person}</option>);
                                        })
                                      }
                                    </select>

                                    {formik.touched.shipper && formik.errors.shipper && (
                                      <div className="errorMsg text-danger">{formik.errors.shipper}</div>
                                    )}

                                  </div>
                                </div>

                                <div className="col-md-3 col-sm-4">
                                <div className="form-group">
                                  <label>Customer</label>
                                  <select className="form-control" name="customer"
                                    {...formik.getFieldProps('customer')}
                                  >
                                    <option value="">--Select Customer--</option>
                                    {
                                      customerData && customerData.map((customer, index) => {
                                        return (<option key={index} value={customer.id}>{customer.firstname}</option>);
                                      })
                                    }
                                  </select>
                                  {formik.touched.customer && formik.errors.customer && (
                                    <div className="errorMsg text-danger">{formik.errors.customer}</div>
                                  )}
                                </div>
                                </div>

                                <div className="col-md-3 col-sm-4">
                                <div className="form-group">
                                  <label>Job status</label>
                                  <select className="form-control" name="status"
                                    {...formik.getFieldProps('status')}
                                  >
                                    <option value="">--Select Status--</option>
                                    {
                                      jobStatusData && jobStatusData.map((customer, index) => {
                                        return (<option key={index} value={customer.id}>{customer.name}</option>);
                                      })
                                    }

                                  </select>
                                  {formik.touched.status && formik.errors.status && (
                                    <div className="errorMsg text-danger">{formik.errors.status}</div>
                                  )}
                                </div>
                                </div>

                                <div className="col-md-3 col-sm-4">
                                <div className="form-group">
                                  <label>Steamship</label>
                                  <select className="form-control" name="steamship"
                                    {...formik.getFieldProps('steamship')}
                                  >
                                    <option value="">--Select Steamship--</option>
                                    <option value="Paid">Paid </option>
                                    <option value="Not Paid">Not Paid</option>
                                  </select>
                                  {formik.touched.steamship && formik.errors.steamship && (
                                    <div className="errorMsg text-danger">{formik.errors.steamship}</div>
                                  )}
                                </div>
                                </div>

                                <div className="col-md-3 col-sm-4">
                                <div className="form-group">
                                  <label>Job for</label>
                                  <select className="form-control" name="job_for"

                                    {...formik.getFieldProps('job_for')}>
                                    <option value="">--Select Job For--</option>
                                    <option value="GFFCA">GFFCA</option>
                                    <option value="NFFI">NFFI</option>
                                  </select>
                                  {formik.touched.job_for && formik.errors.job_for && (
                                    <div className="errorMsg text-danger">{formik.errors.job_for}</div>
                                  )}
                                </div>
                                </div>
                               
                                <div className="col-md-3 col-sm-4">
                                <div className="form-group">
                                  <label>Job completed</label>
                                  <select className="form-control" name="job_complete"
                                    {...formik.getFieldProps('job_complete')}
                                  >
                                    <option value="">--Select--</option>
                                    <option value="Yes">Yes </option>
                                    <option value="No">No</option>
                                  </select>
                                  {formik.touched.job_complete && formik.errors.job_complete && (
                                    <div className="errorMsg text-danger">{formik.errors.job_complete}</div>
                                  )}
                                </div>
                                </div>

                                <div className="col-md-3 col-sm-4">
                                <div className="form-group">
                                  <label>Job start date</label>
                                  <input className="form-control"
                                    type="date"
                                    name="start_date"
                                    min="2018-01-01"
                                    max="2025-12-31"
                                    {...formik.getFieldProps('start_date')}
                                  />
                                  {formik.touched.start_date && formik.errors.start_date && (
                                    <div className="errorMsg text-danger">{formik.errors.start_date}</div>
                                  )}
                                </div>
                                </div>

                                <div className="col-md-3 col-sm-4">
                                <div className="form-group">
                                  <label>job end date</label>
                                  <input className="form-control"
                                    type="date"
                                    name="end_date"
                                    min="2018-01-01"
                                    max="2025-12-31"
                                    {...formik.getFieldProps('end_date')}
                                  />
                                  {formik.touched.end_date && formik.errors.end_date && (
                                    <div className="errorMsg text-danger">{formik.errors.end_date}</div>
                                  )}
                                </div>
                                </div>

                                <div className="col-md-3 col-sm-4">
                                <div className="form-group">
                                  <label>Export coordinator</label>
                                  <select className="form-control" name="export"
                                    {...formik.getFieldProps('export')}
                                  >
                                    <option value="">--Select Coordinator--</option>

                                    {
                                      exportCoordinatorData && exportCoordinatorData.map((user, index) => {
                                        return (<option key={index} value={user.id}>{user.firstname + (user.lastname ? ' ' + user.lastname : '')}</option>);
                                      })
                                    }

                                  </select>
                                  {formik.touched.export && formik.errors.export && (
                                    <div className="errorMsg text-danger">{formik.errors.export}</div>
                                  )}
                                </div>
                                </div>

                                <div className="col-md-3 col-sm-4">
                                <div className="form-group">
                                  <label>Import Doc. coordinator</label>
                                  <select className="form-control" name="import"
                                    {...formik.getFieldProps('import')}
                                  >
                                    <option value="">--Import Document Coordinator--</option>
                                    {
                                      importCoordinatorData && importCoordinatorData.map((customer, index) => {
                                        return (<option key={index} value={customer.id}>{customer.name}</option>);
                                      })
                                    }
                                  </select>
                                  {formik.touched.import && formik.errors.import && (
                                    <div className="errorMsg text-danger">{formik.errors.import}</div>
                                  )}

                                </div>
                                </div>

                                <div className="col-sm-8">
                                  <input className="btn btn-primary mr-2" type="submit" name="search" value="Go" style={{ marginTop: "15px" }} />
                                </div>
                              </div>

                            </form>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table table-striped table-bordered" id="pdf">
                            <thead>
                              <tr className="bg-primary text-white">

                                <th>Job No.</th>

                                <th>Job Date</th>

                                <th>Job For</th>
                                <th>Exp Doc.</th>
                                <th>Imp Doc</th>
                                <th>Customer Name</th>

                                <th>Booking No.</th>
                                <th>Bl No.</th>

                                <th>Carrier</th>

                                <th>Desti.</th>
                                <th>Container No</th>
                                <th>Release Rcvd.</th>
                                <th>Release date.</th>
                                <th>E T A</th>
                                <th>Steamship Inv.</th>
                                <th>Total Receivable</th>
                                <th>Total Received</th>
                                <th>Balance</th>
                                <th>Net Gain</th>
                                <th>Job Status</th>
                                <th>Completed</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ratesListResult.result !==
                                undefined &&
                                ratesListResult.result !== "" ? (
                                callPageRecords(
                                  ratesListResult.result
                                )
                              ) : (
                                <tr>
                                  <td colSpan={21}>No Record Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                                       */}
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="dataTable_paginate"
                            >
                              {userData != undefined &&
                                userData.length > 0 &&
                                userRecordsData !== undefined ? (
                                <PaginationBlock
                                  perPage={perPage}
                                  userRecordsData={userRecordsData}
                                  callHandlePageClick={(data) =>
                                    handlePageClick(data)
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>

      </AdminLayout>
    </>
  );
};
export default Contact;
