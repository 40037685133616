import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
function AddRemoveMultipleInputFields(props) {
    const currentUser = useSelector((state) => state.userClient);
    const [inputFields, setInputFields] = useState([{
        job_comment: '',
        user_id: currentUser.user.id
    }]);

    const addInputField = () => {
        setInputFields([...inputFields, {
            job_comment: '',
            user_id:currentUser.user.id
        }])

    }
    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }
    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
        props.addJobComment(inputFields);

    }
    return (

        
        <div className="row mt-3">
            <div className="row align-items-center">
                <div className="col-sm-12">
                    {
                        inputFields.map((data, index) => {
                            const { job_comment,user_id } = data;
                            return (
                                <div className="row align-items-center " key={index}>
                                    <div className="col-sm-10">
                                        <div className="form-group">
                                            <label>Job comment</label>
                                            <input type="hidden" name="user_id" value={user_id} />
                                            <textarea type="textarea"
                                                rows={2}
                                                onChange={(evnt) => handleChange(index, evnt)}
                                                value={job_comment} 
                                                name="job_comment"
                                                className="form-control"
                                                placeholder="Job Comment"
                                            />
                                        </div>
                                    </div>

                                    {index === 0 && <div className="col-sm-1">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={addInputField}><i className="fa fa-fw fa-plus"></i></button>
                                    </div>}

                                    <div className="col-sm-1">
                                        {(inputFields.length !== 1) ? <button
                                            type="button"
                                            className="btn btn-primary" onClick={removeInputFields}><i className="fa fa-fw fa-trash"></i></button> : ''}

                                    </div>
                                </div>
                            )
                        })
                    }


                </div>
            </div>
            <div className="col-sm-4">
            </div>
        </div>

    )
}
export default AddRemoveMultipleInputFields