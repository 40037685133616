
let url = ""; 
if(window.location.hostname == "localhost"){
    url =  "http://localhost:3001/"
}else {
    url =  "http://golden-test.com:3001/"

}




export const API_URL = url;

