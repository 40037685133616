import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import moment from "moment";
import {
  deleteShippingLineRateById,
  getShippingLineRateList,
  addShippingLineRateDetails,
  editShippingLineRateDetails,
  uploadExcelShippingRates,
} from "../../../Services/Store/Common/shippingLineRates/shippingLineRates.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { Form } from "./Form";
import { ExcelForm } from "./ExcelForm";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { shippingLineRatesSelector } from "../../../Services/Store/Common/shippingLineRates/shippingLineRates.selector";
import { displaySuccessMessage } from "../../../Services/Helpers/helper";

const ShippingLineRates = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [shippingLineRateList, setShippingLineRateList] = useState([]);
  const [shippingLineRateCount, setShippingLineRateCount] = useState(0);
  const [shippingLineRateId, setShippingLineRateId] = useState([]);
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);

  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [excelModel, setExcelModel] = useState(false);

  const perPage = 25;
  const [modalData, setModalData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shippingLineRateData = useSelector(shippingLineRatesSelector);
  const [searchType, setSearchType] = useState("");
  const currentDate = moment(new Date()).format('Y-M-DD');

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));

    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('clientToken');
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponce]);

  useEffect(() => {
    dispatch(getShippingLineRateList({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorResponce === 0) {
      setShowModel(false);
      setAddModel(false);
      setEditModel(false);
      setDeleteModel(false);
    }
    setShippingLineRateList(shippingLineRateData.records);
    setShippingLineRateCount(shippingLineRateData.totalCount);
    setErrorResponce(shippingLineRateData.error_code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingLineRateData]);

  useEffect(() => {
    if (searchType?.length) {
      dispatch(getShippingLineRateList({ searchType: searchType, search: searchKeyword }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, searchType]);

  const handlePageClick = (selectedPage) => {
    dispatch(getShippingLineRateList({ searchType: searchType, search: searchKeyword, offset: selectedPage }));
  };

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (shippingLineRateDetails) => {
    setModalData(shippingLineRateDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (shippingLineRateId) => {
    setShippingLineRateId(shippingLineRateId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleAddClick = () => {
    setShowModel(true);
    setAddModel(true);
  };

  const _handleExcelUpload = () => {
    setShowModel(true);
    setExcelModel(true);
  };

  const submitExcel = async (params) => {
    dispatch(uploadExcelShippingRates(params));
    displaySuccessMessage("File Uploaded, Updatation is in progress.");
    setShowModel(false);
    setExcelModel(false);
  };

  const callPageRecords = (shippingLineRateList) => {

    if (shippingLineRateList.length) {
      return shippingLineRateList.map((data, index) => (
        <tr key={data.id} className={data.valid_to < currentDate ? 'table-danger' : 'table-info'}>
          <td>{index + 1}</td>
          <td>{data.sl_quote}</td>
          <td>{data.origin}</td>
          <td>{data.loading_port_name}</td>
          <td>{data.destination}</td>
          <td>{data.destination_port_name}</td>
          <td>{data.shipping_line}</td>
          <td>{moment(data.valid_from).format("MM/DD/Y")}</td>
          <td>{moment(data.valid_to).format("MM/DD/Y")}</td>
          <td>${parseInt(data.cost).toFixed(2)}</td>
          <td>{data.item_type}</td>
          <td>{data.remarks}</td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Delete"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr key="0" className="text-center">
          <td colSpan={13}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteShippingLineRate = async (shippingLineRateId) => {
    dispatch(deleteShippingLineRateById(shippingLineRateId));
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submitShippingLineRateDetails = async (params, shippingLineRateId) => {
    if (shippingLineRateId) {
      dispatch(editShippingLineRateDetails(params, shippingLineRateId));
    } else {
      dispatch(addShippingLineRateDetails(params));
    }
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <>
      <AdminLayout pageHeading="Shipping Line Rates" pageIcon="fa fa-dollar">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Shipping Line Rate List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-6">
                    <div className="d-flex">
                      <button type="button" className="mb-2 mb-md-0 mr-2 btn btn-primary" onClick={() => _handleAddClick()}>
                        Add <i className="fa fa-plus fa-sm"></i>
                      </button>
                      <button
                        type="button"
                        className="mb-2 mb-md-0 btn btn-primary"
                        onClick={() => _handleExcelUpload()}
                      >
                        Excel <i className="fa fa-upload"></i>
                      </button>
                      <div className="d-flex align-items-center ms-2">
                        <span style={{ width: '10px', height: '10px' }} className="bg-info"></span>
                        <span className="ms-1" style={{ fontSize: '0.75rem' }}>Valid</span>
                      </div>
                      <div className="d-flex align-items-center ms-1">
                        <span style={{ width: '10px', height: '10px' }} className="bg-danger"></span>
                        <span className="ms-1" style={{ fontSize: '0.75rem' }}>Expired</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6">
                    <div className="fillter-wrap d-flex justify-content-end">
                      <select className="form-control me-1" onChange={(event) => handleSearhChange(event)}>
                        <option value="">Select Type</option>
                        <option value="o.name">Loading Port</option>
                        <option value="origin">Origin</option>
                        <option value="destination">Destination</option>
                        <option value="d.name">Destination Port</option>
                        <option value="cr.name">Shipping Line</option>
                      </select>
                      <input type="search" className="form-control" placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                    </div>
                  </div>
                </div>
                </div>
                <div className="card-body">
                <div className="row">
                  <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>S.No</th>
                          <th>Quote</th>
                          <th>Origin</th>
                          <th>Loading Port</th>
                          <th>Destination</th>
                          <th>Destination Port</th>
                          <th>Shipping Line</th>
                          <th>Valid From</th>
                          <th>Valid To</th>
                          <th>Cost</th>
                          <th>Commodity</th>
                          <th>Remarks</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          callPageRecords(
                            shippingLineRateList
                          )
                        }
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate"
                    >
                      {
                        shippingLineRateCount > 0 ? (
                          <PaginationBlock
                            perPage={perPage}
                            userRecordsData={shippingLineRateCount}
                            callHandlePageClick={(data) =>
                              handlePageClick(data)
                            }
                          />
                        ) : (
                          ""
                        )}
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <ModalBox
            size={deleteModel || excelModel ? 'md' : 'xl'}
            show={showModel}
            title={excelModel ? "Bulk Upload Shipping Line Rate" : (deleteModel ? "Delete Shipping Line Rate" : (editModel ? "Edit Shipping Line Rate" : "Add Shipping Line Rate"))}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddModel(false);
              setExcelModel(false);
            }}
          >
            {editModel === true ? (
              <Form
                submitDetails={(params, shippingLineRateId) => submitShippingLineRateDetails(params, shippingLineRateId)}
                shippingLineRateDetails={modalData}

              />
            ) : addModel === true ? (
              <Form
                submitDetails={(params) => submitShippingLineRateDetails(params, 0)}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={shippingLineRateId}
                deleteUserId={(shippingLineRateId) => deleteShippingLineRate(shippingLineRateId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : excelModel ? (
              <ExcelForm
                submitExcel={(params) => submitExcel(params)}
              />
            ) :
              (
                ""
              )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default ShippingLineRates;
