import React, { useState, useEffect } from 'react';
import CONSTANT from '../../../Services/Constant/user.constants';

export function UserForm(props) {

    const [fields, setFields] = useState(props.userDetails ? props.userDetails : {});
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        let data = fields;
        data[event.target.name] = event.target.value;
        setFields({ ...data });
    };

    const validateRegistration = (fields) => {
        let errors = {};
        let formIsValid = true;

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!fields["username"] || fields["username"].trim() === "") {
            formIsValid = false;
            errors["username"] = "*Please enter your Username.";
        }

        if (!fields["firstname"] || fields["firstname"].trim() === "") {
            formIsValid = false;
            errors["firstname"] = "*Please enter your First name.";
        }

        if (!fields["lastname"] || fields["lastname"].trim() === "") {
            formIsValid = false;
            errors["lastname"] = "*Please enter your Last name.";
        }

        if (!fields["email"] || fields["email"].trim() === "") {
            formIsValid = false;
            errors["email"] = "*Please enter your email address.";
        } else if (!fields["email"].match(validRegex)) {
            formIsValid = false;
            errors["email"] = "*Please enter a valid email address.";
        }

        if (!fields["mobile_number"] || fields["mobile_number"].trim() === "") {
            formIsValid = false;
            errors["mobile_number"] = "*Please enter your mobile number.";
        }

        if ((!fields["password"] || fields["password"].trim() === "") && props.userId !== undefined) {
            formIsValid = false;
            errors["password"] = "*Please enter your Password.";
        } else if (props.userId !== undefined && fields["password"].length < 4) {
            formIsValid = false;
            errors["password"] = "*Please enter minimum 5 characters";
        }

        return {
            errors: errors,
            formIsValid: formIsValid,
        };
    };

    const _validateForm = () => {
        let formFields = fields;
        let response = validateRegistration(formFields);
        setErrors(response.errors);
        return response.formIsValid;
    };

    const updateUserDetails = async (event) => {
        event.preventDefault();
        if (_validateForm()) {
            if (fields) {
                const postData = {
                    username: fields.username,
                    firstname: fields.firstname,
                    lastname: fields.lastname,
                    email: fields.email,
                    mobile_number: fields.mobile_number,
                    roleId: fields.roleId,
                    status: fields.status ? fields.status : 'active'
                }
                if (props.userDetails) {
                    postData['id'] = fields.id;
                    props.editUserDetails(postData);
                } else if (props.userId) {
                    postData['userId'] = props.userId;
                    postData['password'] = fields.password;
                    props.addUserDetails(postData);
                }
            }
        }
    }

    return (
        <>
            <div className="modal-body">
                <div className="login-wrap-new">
                    <div className="form-content">
                        <form onSubmit={(event) => updateUserDetails(event)}>
                            <div className="row">
                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="UserName"
                                        name="username"
                                        value={fields?.username}
                                        onChange={(event) => handleChange(event)}
                                    />
                                    {errors.username ? (
                                        <div className="errorMsg text-danger">
                                            {errors.username}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        name="firstname"
                                        value={fields?.firstname}
                                        onChange={(event) => handleChange(event)}
                                    />
                                    {errors.firstname ? (
                                        <div className="errorMsg text-danger">
                                            {errors.firstname}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        name="lastname"
                                        value={fields?.lastname}
                                        onChange={(event) => handleChange(event)}
                                    />
                                    {errors.lastname ? (
                                        <div className="errorMsg text-danger">
                                            {errors.lastname}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={fields?.email}
                                        onChange={(event) => handleChange(event)}
                                    />
                                    {errors.email ? (
                                        <div className="errorMsg text-danger">
                                            {errors.email}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-md-6 col-xs-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Mobile number"
                                        name="mobile_number"
                                        value={fields?.mobile_number}
                                        onChange={(event) => handleChange(event)}
                                    />
                                    {errors.mobile_number ? (
                                        <div className="errorMsg text-danger">
                                            {errors.mobile_number}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {
                                    props.userId !== undefined ? (
                                        <div className="col-md-6 col-xs-6">
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                placeholder="Password"
                                                name="password"
                                                value={fields?.password}
                                                onChange={(event) => handleChange(event)}
                                            />
                                            {errors.password ? (
                                                <div className="errorMsg text-danger">
                                                    {errors.password}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                    ) : ""
                                }
                                {
                                    props.userRole === 'admin' || (props.userId !== undefined && props.userRole !== 'admin') ?
                                        <>
                                            <div className="col-md-6 col-xs-6">
                                                <select className="form-control" name="roleId" onChange={(event) => handleChange(event)} value={fields?.roleId}>
                                                    <option value=''>Select Role</option>
                                                    {Object.keys(CONSTANT.roles).map((key) => <option key={key} value={key}>{CONSTANT.roles[key]}</option>)}
                                                </select>
                                            </div>
                                            <div className="col-md-6 col-xs-6">
                                                <select className="form-control" name="status" onChange={(event) => handleChange(event)} value={fields?.status}>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                            </div>
                                        </>
                                        : ''
                                }
                            </div>
                            <div className='row'>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">
                                        {fields.id ? 'Save User' : 'Save User'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}