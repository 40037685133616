import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from "moment";
import { useSelector } from 'react-redux';

export function Form(props) {
    const currentUser = useSelector((state) => state.userClient);
    const fields = props.customerRateHistoryDetails ? props.customerRateHistoryDetails : {};

    const validateForm = Yup.object().shape({
        origin: Yup.string().required('Origin is required'),
        destination: Yup.string().required('Destination is required'),
        shipping_line: Yup.string().required('Shipping Line is required')
    });

    let initialValues = {
        date: fields?.date ? moment(fields.date).format('yyyy-MM-DD') : '',
        customer: fields.customer ?? '',
        shipping_line: fields.shipping_line ?? '',
        origin: fields.origin ?? '',
        loading_port: fields.loading_port ?? '',
        destination: fields.destination ?? '',
        destination_port: fields.destination_port ?? '',
        hazardous: fields?.hazardous ?? '',
        commodity: fields?.commodity ?? '',
        trucking_status: fields?.trucking_status ?? '',
        address: fields?.address ?? '',
        cost: fields?.cost ?? '',
        quote: fields?.quote ?? '',
        equipment_types: fields?.equipment_types ?? '',
        salesperson: fields?.salesperson ?? '',
        remarks: fields?.remarks ?? ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateForm,
        onSubmit: (values) => {
            values.date = values.date ? moment(values.date).format("YYYY-MM-DD") : null;
            if (fields?.id) {
                props.submitDetails(values, fields.id);
            } else {
                values.created_by = currentUser.user.id;
                props.submitDetails(values);
            }
        },
    });

    return (
        <>
            <div className="modal-body login-wrap-new">
                <form onSubmit={formik.handleSubmit} className='invitation-wrap form-content'>
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <label>Date</label>
                            <input className="form-control"
                                name="date"
                                id="date"
                                type='date'
                                {...formik.getFieldProps('date')}
                            />
                            {formik.touched.date && formik.errors.date && (
                                <div className="errorMsg text-danger">{formik.errors.date}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Customer Name</label>
                            <input className="form-control"
                                name="customer"
                                id="customer"
                                type='text'
                                {...formik.getFieldProps('customer')}
                            />
                            {formik.touched.customer && formik.errors.customer && (
                                <div className="errorMsg text-danger">{formik.errors.customer}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Origin*</label>
                            <input className="form-control"
                                name="origin"
                                id="origin"
                                type="text"
                                {...formik.getFieldProps('origin')}
                            />
                            {formik.touched.origin && formik.errors.origin && (
                                <div className="errorMsg text-danger">{formik.errors.origin}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Loading Port</label>
                            <input className="form-control"
                                name="loading_port"
                                id="loading_port"
                                type='text'
                                {...formik.getFieldProps('loading_port')}
                            />
                            {formik.touched.loading_port && formik.errors.loading_port && (
                                <div className="errorMsg text-danger">{formik.errors.loading_port}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Destination</label>
                            <input className="form-control"
                                name="destination"
                                id="destination"
                                type="text"
                                {...formik.getFieldProps('destination')}
                            />
                            {formik.touched.destination && formik.errors.destination && (
                                <div className="errorMsg text-danger">{formik.errors.destination}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Destination Port</label>
                            <input className="form-control"
                                name="destination_port"
                                id="destination_port"
                                type="text"
                                {...formik.getFieldProps('destination_port')}
                            />
                            {formik.touched.destination_port && formik.errors.destination_port && (
                                <div className="errorMsg text-danger">{formik.errors.destination_port}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Shipping Line</label>
                            <input className="form-control"
                                name="shipping_line"
                                id="shipping_line"
                                type="text"
                                {...formik.getFieldProps('shipping_line')}
                            />
                            {formik.touched.shipping_line && formik.errors.shipping_line && (
                                <div className="errorMsg text-danger">{formik.errors.shipping_line}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Sales Person</label>
                            <input className="form-control"
                                name="salesperson"
                                id="salesperson"
                                type="text"
                                {...formik.getFieldProps('salesperson')}
                            />
                            {formik.touched.salesperson && formik.errors.salesperson && (
                                <div className="errorMsg text-danger">{formik.errors.salesperson}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Cost</label>
                            <input className="form-control"
                                name="cost"
                                id="cost"
                                type='text'
                                {...formik.getFieldProps('cost')}
                            />
                            {formik.touched.cost && formik.errors.cost && (
                                <div className="errorMsg text-danger">{formik.errors.cost}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Quote</label>
                            <input className="form-control"
                                name="quote"
                                id="quote"
                                type='text'
                                {...formik.getFieldProps('quote')}
                            />
                            {formik.touched.quote && formik.errors.quote && (
                                <div className="errorMsg text-danger">{formik.errors.quote}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Commodity</label>
                            <input className='form-control'
                                name='commodity'
                                id='commodity'
                                type='text'
                                {...formik.getFieldProps('commodity')}
                            />
                            {formik.touched.commodity && formik.errors.commodity && (
                                <div className="errorMsg text-danger">{formik.errors.commodity}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Hazardous</label>
                            <input className='form-control'
                                name='hazardous'
                                id='hazardous'
                                type='text'
                                {...formik.getFieldProps('hazardous')}
                            />
                            {formik.touched.hazardous && formik.errors.hazardous && (
                                <div className="errorMsg text-danger">{formik.errors.hazardous}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Tracking Status</label>
                            <input className='form-control'
                                name='trucking_status'
                                id='trucking_status'
                                type='text'
                                {...formik.getFieldProps('trucking_status')}
                            />
                            {formik.touched.trucking_status && formik.errors.trucking_status && (
                                <div className="errorMsg text-danger">{formik.errors.trucking_status}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Address</label>
                            <input className='form-control'
                                name='address'
                                id='address'
                                type='text'
                                {...formik.getFieldProps('address')}
                            />
                            {formik.touched.address && formik.errors.address && (
                                <div className="errorMsg text-danger">{formik.errors.address}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Equipment Types</label>
                            <input className='form-control'
                                name='equipment_types'
                                id='equipment_types'
                                type='text'
                                {...formik.getFieldProps('equipment_types')}
                            />
                            {formik.touched.equipment_types && formik.errors.equipment_types && (
                                <div className="errorMsg text-danger">{formik.errors.equipment_types}</div>
                            )}
                        </div>
                        <div className="col-md-3 col-6">
                            <label>Remarks</label>
                            <textarea className="form-control"
                                name="remarks"
                                id="remarks"
                                {...formik.getFieldProps('remarks')}
                            ></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-12'>
                            <input type="submit" name="submit" className="btn btn-primary w-auto text-white" value={fields?.id ? "Save Customer Quote Rate" : "Save Customer Quote Rate"} />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}