import { useState } from "react"
function AddRemoveMultipleVIN(props) {
    const [inputFields, setInputFields] = useState([{
        vin_number: ''
    }]);

    const addInputField = () => {
        setInputFields([...inputFields, {
            vin_number: ''
        }])

    }
    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }
    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
        props.addJobComment(inputFields);

    }
    return (
        <div className="row mt-3">
            <div className="row align-items-center">
                <div className="col-sm-12">
                    {
                        inputFields.map((data, index) => {
                            const { vin_number } = data;
                            return (
                                <div className="row align-items-center" key={index}>
                                    <div className="col-sm-10">
                                        <div className="form-group">
                                            <label>VIN</label>
                                            <input type="text"
                                                onChange={(evnt) => handleChange(index, evnt)}
                                                value={vin_number} name="vin_number"
                                                className="form-control"
                                                placeholder="Enter VIN"
                                            />
                                        </div>
                                    </div>
                                    {
                                        index === 0 && <div className="col-sm-1">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={addInputField}><i className="fa fa-fw fa-plus"></i></button>
                                        </div>
                                    }
                                    <div className="col-sm-1">
                                        {(inputFields.length !== 1) ? <button
                                            type="button"
                                            className="btn btn-primary" onClick={removeInputFields}><i className="fa fa-fw fa-trash"></i></button> : ''}

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default AddRemoveMultipleVIN