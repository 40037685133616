import axios from "axios";
import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import {  displayErrorMessage, displaySuccessMessage,requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";


export const deleteOriginById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteOrigin",{id},
                                   { headers:requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      const showOriginList = {};
      const resultOutput = getState()
        .originList.showOriginList.result.map((data) => data)
        .filter((data) => data.id !== id);
      showOriginList.result = resultOutput;
      showOriginList.records = getState().originList.showOriginList.records - 1;
      showOriginList.errorCode = 0;
      displaySuccessMessage("originDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_ORIGIN_LIST_SUCESS,
        payload: showOriginList,
      });
    } else {
      displayErrorMessage("originDeleteError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_ORIGIN_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_ORIGIN_LIST_ERROR });
  }
};

export const getoriginList= (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslOriginList", {
                                    params: postData,
                                    headers:requestTokenHeader()
                                  });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_ORIGIN_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_ORIGIN_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    return dispatch({ type: USER_ACTION_TYPE.SET_ORIGIN_LIST_ERROR });
  }
};



export const addOriginDetails = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddOrigin",postData,
      { headers:requestTokenHeader() });
    if (response.data.errorCode === 0) {
      const resultOutput = getState().originList.showOriginList.result;
      resultOutput.push(response.data.result);
      const showOriginList = {};
      showOriginList.result = resultOutput;
      showOriginList.records = getState().originList.showOriginList.records + 1;
      showOriginList.errorCode = 0;
      displaySuccessMessage("originAdded");
      return dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: showOriginList,
      });
    } else {
      displayErrorMessage("originAddedError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_USER_LIST_ERROR });
  }
};


export const editOriginDetails = (postData, searchIds) => 
 {
 return async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslEditOrigin",postData,
    {
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("originUpdated");
      dispatch(getoriginList({ searchIds }));

    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      displayErrorMessage("originUpdatedError");
      
       dispatch({
        type: USER_ACTION_TYPE.SET_ORIGIN_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
     displayErrorMessage(err.name);
     dispatch({ type: USER_ACTION_TYPE.SET_ORIGIN_LIST_ERROR });
  }
 }
};


