import React, { useState, useEffect } from 'react';
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import api from "../../../Services/Axios/index";
import { useNavigate } from "react-router-dom";
import { displayErrorMessage, requestTokenHeader } from "../../../Services/Helpers/helper";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import { ViewForm } from "../../Common/Jobs/ViewForm";

export function CustomerBookings(props) {

    const perPage = 25;
    const [customer, setCustomer] = useState(props.customer);
    const navigate = useNavigate();
    const [bookings, setBookings] = useState([]);
    const [bookingCount, setBookingCount] = useState(0);
    const [errorResponce, setErrorResponce] = useState('');
    const [showModel, setShowModel] = useState(false);
    const [viewBookingModel, setViewBookingModel] = useState(false);
    const [bookingData, setBookingData] = useState({});

    useEffect(() => {
        const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));
        if (!loginCheck || errorResponce === 4) {
            sessionStorage.removeItem('clientToken');
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorResponce]);

    const getBookings = async (customerId = 0, offset = 0) => {
        try {
            const response = await api.post("api/v1/tslJobsList", { customerId: customerId, offset: offset, limit: perPage }, { headers: requestTokenHeader() });
            if (response.data.errorCode === 0) {
                if (props.ignoreBooking !== undefined) {
                    setBookings(response.data.data.filter(rec => {
                        return rec.id !== props.ignoreBooking;
                    }));
                    setBookingCount(response.data.total_records - 1);
                } else {
                    setBookings(response.data.data);
                    setBookingCount(response.data.total_records);
                }
                setErrorResponce(response.data.errorCode);
            } else {
                setBookings([]);
                setBookingCount(0);
                setErrorResponce(response.data.errorCode);
            }
        } catch (err) {
            if (err?.response?.data?.errorCode === 4) {
                displayErrorMessage(err?.response?.data?.errorMessage);
            } else {
                displayErrorMessage(err.message);
            }
            setBookings([]);
            setBookingCount(0);
            setErrorResponce(err?.response?.data?.errorCode);
        }
    }

    useEffect(() => {
        getBookings(customer.id, 0);
    }, [customer]);

    const handlePageClick = (selectedPage) => {
        getBookings(customer.id, selectedPage);
    };

    const showBooking = (data) => {
        setShowModel(true);
        setViewBookingModel(true);
        setBookingData(data);
    }

    const callPageRecords = (bookings) => {
        if (bookings.length) {
            return bookings.map((data, index) => ( 
                <tr key={data.id}>
                    <td>{data.job_number}</td>
                    <td>{data.job_date}</td>
                    <td>{data.booking_number == 0 ? "" : data.booking_number}</td>
                    <td>{ data.sales_firstname  == null ? "" : data.sales_firstname + ' ' + data.sales_lastname}</td>
                    <td>
                        <span className='d-block'>USD ${( data.cq_amount == "" ||  data.cq_amount == null || data.cq_amount === undefined ) ? 0: data.cq_amount }</span>
                        <span className='d-block'>CAD ${(data.cq_cad == "" ||  data.cq_cad == null ||  data.cq_cad === undefined) ? 0 : data.cq_cad}</span>
                    </td>
                    <td> {data.job_status_id ? data.job_status_name : "NEW FILE"}</td>
                    <td> {data.job_completed ? data.job_completed : "NO"}</td>
                    {
                        props.ignoreBooking === undefined && 
                            <td>
                                <button type="button" className="bd-none btn btn-secondary btn-xsm" onClick={() => showBooking(data)} title="View Booking">
                                    <i className="fa fa-fw fa-eye"></i>
                                </button>
                            </td>
                    }
                </tr>
            ));
        } else {
            return (
                <tr className="text-center">
                    <td colSpan={8}>No Record Found</td>
                </tr>
            );
        }
    };

    return (
        <>

            <div className="table-responsive">
                {
                    props.showCustomer === undefined ?
                        <>
                            <div className='d-flex mb-2 mt-2'>
                                <p className='text-capitalize mb-0'><span className='font-weight-bold'>Name:</span> {customer.firstname + (customer.lastname ? ' ' + customer.lastname : '')}</p>
                                <p className='ms-4 mb-0'><span className='font-weight-bold'>Email:</span> {customer.email}</p>
                                <p className='ms-4 mb-0'><span className='font-weight-bold'>Mobile:</span> {customer.mobile_number}</p>
                            </div>
                        </> : ''
                }

                <table className="table table-striped table-bordered">
                    <thead>
                        <tr className="bg-primary text-white">
                            <th>Job No</th>
                            <th>Job Date</th>
                            <th>Booking Number</th>
                            <th>Sales Representative</th>
                            <th>Customer Quote</th>
                            <th>Job Status</th>
                            <th>Completed</th>
                            {
                                props.ignoreBooking === undefined && 
                                   <th>View</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {callPageRecords(bookings)}
                    </tbody>
                </table>

                <div className="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                    {
                        bookingCount > 0 ? (
                            <PaginationBlock
                                perPage={perPage}
                                userRecordsData={bookingCount}
                                callHandlePageClick={(data) =>
                                    handlePageClick(data)
                                }
                            />
                        ) : ("")
                    }
                </div>
            </div>

            <ModalBox
                show={showModel}
                size="xl"
                onHide={() => {
                    setShowModel(false);
                    setViewBookingModel(false);
                }}
            >
                {viewBookingModel === true ? (
                    <ViewForm
                        jobsDetails={bookingData}
                        jobsData={props.jobsData}
                    />
                ) : ""}
            </ModalBox>
        </>
    );
}