import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CONSTANT from '../../../Services/Constant/user.constants';
import { ModalBox } from "../../../Components/Ui/ModalBox";
import {
  deleteQuotesById,
  getquotesList,
  addQuotesDetails,
  editQuotesDetails,
} from "../../../Services/Store/Common/quotes/quotes.action";
import { addQuoteRateAccount } from "../../../Services/Store/Common/jobs/jobs.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { Form } from "./Form";
import { RateHistory } from "./RateHistory";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { quotesList } from "../../../Services/Store/Common/quotes/quotes.selector";
import { QuoteView } from "./View";
import moment from "moment";
import api from "../../../Services/Axios/index";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader, getUserIds } from "../../../Services/Helpers/helper";
import { addNotificationDetails } from "../../../Services/Store/Common/notification/notification.action";
import { Tooltip } from 'react-tooltip';

const Quotes = () => {
  const id = useParams()?.id;
  const perPage = 25;
  const currentUser = useSelector((state) => state.userClient);
  const [quoteId, setQuoteId] = useState("");
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [quoteList, setQuoteList] = useState([]);
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [viewModel, setViewModel] = useState(false);
  const [rateModel, setRateModel] = useState(false);
  const [attachBookingModel, setAttachBookingModel] = useState(false);
  const [rateHistoryModel, setRateHistoryModel] = useState(false);
  const [modalData, setModalData] = useState({});
  const [currentPriority, setCurrentPriority] = useState({});
  const [quoteCount, setQuoteCount] = useState(0);
  const [quoteTotalCost, setQuoteTotalCost] = useState(0);
  const [quoteCosts, setQuoteCosts] = useState({
    bl_charges: 0,
    freight: 0,
    telux: 0
  });
  const [quoteCarrier, setQuoteCarrier] = useState(0);
  const [quoteData, setQuoteData] = useState(0);
  const [availableBookings, setAvailableBookings] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quoteListResult = useSelector(quotesList);
  const [searchType, setSearchType] = useState(null);
  const [searchIds, setSearchIds] = useState(null);

  useEffect(() => {
    if (id) {
      if (id === 'search') {
        let search = localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : '';
        if (search?.resource === 'quotes' && search.ids) {
          setSearchIds(search.ids);
        }
      } else {
        console.log('id', id);
        setSearchType('id');
        setSearchKeyword(id);
      }
    } else {
      localStorage.removeItem('search');
      setSearchIds('');
    }
  }, [id]);

  useEffect(() => {
    async function fetchBookings() {
      const response = await api.post("api/v1/tslGetBookings", { limit: 'all' }, { headers: requestTokenHeader()});
      if (response.data.errorCode === 0) {
        setAvailableBookings(response.data.data);
      } else {
        setAvailableBookings([]);
      }
    }
    if (currentUser.user.role === 'booking')
      fetchBookings();
  }, []);

  async function updateReadStatus(quoteId) {
    const response = await api.post("api/v1/tslEditQuotes", { is_read: '1', id: quoteId }, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      let newQuotes = await quoteList.map(quote => {
        if (quote.id === quoteId) {
          quote.is_read = '1';
          return quote;
        } else {
          return quote;
        }
      });
      setQuoteList(newQuotes);
    } else {
      setAvailableBookings([]);
    }
  }

  useEffect(() => {
    async function fetchCarriers() {
      const response = await api.get("api/v1/tslCarriersList", {
        params: "",
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setCarriers(response.data.data);
      } else {
        setCarriers([]);
      }
    }
    if (currentUser.user.role === 'rates' || currentUser.user.role === 'sales')
      fetchCarriers();
  }, []);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));
    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('clientToken');
      navigate("/");
    }
  }, [errorResponce]);

  useEffect(() => {
    setQuoteList(quoteListResult.result);
    setQuoteCount(quoteListResult.records);
    setErrorResponce(quoteListResult.error_code);
  }, [quoteListResult]);

  useEffect(() => {
    console.log(searchKeyword, searchType);
    if(searchKeyword !== null && searchType !== null)
      dispatch(getquotesList({ searchIds, searchType: searchType, search: searchKeyword }));
  }, [searchKeyword, searchType]);

  useEffect(() => {
    if(searchIds !== null) {
      setSearchKeyword(null);
      setSearchType(null);
      dispatch(getquotesList({ searchIds }));
    }
  }, [searchIds]);

  const handlePageClick = (selectedPage) => {
    dispatch(
      getquotesList({ searchIds, searchType: searchType, search: searchKeyword, offset: selectedPage })
    );
  };

  const handleSearchText = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (quote) => {
    if (currentUser.user.role === 'sales' && quote.is_read === '0') {
      updateReadStatus(quote.id);
    }
    setModalData(quote);
    setCurrentPriority(quote.priority);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (quoteId) => {
    setQuoteId(quoteId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleAddClick = () => {
    setModalData({});
    setShowModel(true);
    setAddModel(true);
  };

  const _handleViewClick = (quote) => {
    if (currentUser.user.role === 'sales' && quote.is_read === '0') {
      updateReadStatus(quote.id);
    }
    setModalData(quote);
    setShowModel(true);
    setViewModel(true);
  }

  const _handleRateHistory = (quoteId) => {
    setQuoteId(quoteId);
    setShowModel(true);
    setRateHistoryModel(true);
  }

  const handleRateModel = (quote) => {
    if (currentUser.user.role === 'sales' && quote.is_read === '0') {
      updateReadStatus(quote.id);
    }
    setModalData(quote);
    setQuoteId(quote.id);
    setQuoteCosts({
      bl_charges: quote.bl_charges,
      freight: quote.freight,
      telux: quote.telux
    });
    setQuoteCarrier(quote.shipping_line_id ?? '');
    setShowModel(true);
    setRateModel(true);
  }

  const handleCostChange = (e) => {
    setQuoteCosts({ ...quoteCosts, [e.target.name]: parseFloat(e.target.value) });
  }

  useEffect(() => {
    setQuoteTotalCost(parseFloat(quoteCosts.bl_charges ?? 0) + parseFloat(quoteCosts.freight ?? 0) + parseFloat(quoteCosts.telux ?? 0));
  }, [quoteCosts]);

  const handleCarrierChange = (e) => {
    setQuoteCarrier(e.target.value);
  }

  const updateQuoteCost = () => {
    if (quoteTotalCost > 0 && quoteCarrier > 0) {
      dispatch(editQuotesDetails({
        bl_charges: quoteCosts.bl_charges,
        freight: quoteCosts.freight,
        telux: quoteCosts.telux,
        shipping_line_id: quoteCarrier,
        id: quoteId
      }, modalData, currentUser.user, searchIds));
      setShowModel(false);
      setRateModel(false);
      setQuoteId(0);
    } else {
      alert('Please select all fields');
    }
  }

  const handleAttachBooking = (data) => {
    setShowModel(true);
    setAttachBookingModel(true);
    setQuoteData(data);
  }

  const attachBooking = (bookingData) => {
    // console.log(bookingData);
    setShowModel(false);
    setAttachBookingModel(false);
    let jobData = {
      job_for: '',
      job_number: quoteData.job_number,
      job_date: moment().format('yyyy-MM-DD'),
      shipping_line_id: quoteData.shipping_line_id,
      service_id: bookingData.service_id ?? '',
      seal_number: bookingData.seal_number ?? '',
      sale_person_id: quoteData.sale_person_id,
      booking_number: bookingData.booking_number,
      container_number: bookingData.container_number ?? '',
      vessal_name: bookingData.vessal_name ?? '',
      customer_id: quoteData.customer_id,
      origin: quoteData.origin,
      destination: quoteData.destination,
      loading_port: quoteData.loading_port,
      destination_port: quoteData.destination_port,
      truker_id: bookingData.truker_id ?? '',
      doc_cutoff_date: bookingData.doc_cutoff_date ?? '',
      vgm_cutoff_date: bookingData.vgm_cutoff_date ?? '',
      cargo_curtoff_date: bookingData.cargo_curtoff_date ?? '',
      booking_rollover_date: bookingData.booking_rollover_date ?? '',
      export_document_coordinator_id: bookingData.export_document_coordinator_id ?? '',
      import_document_coordinator_id: bookingData.import_document_coordinator_id ?? '',
      job_status_id: bookingData.job_status_id ?? '',
      trucking_location: quoteData.trucking_location,
      loading_date: quoteData.loading_date,
      need_trucking: quoteData.need_trucking,
      customer_comment: quoteData.customer_comment,
      upload_certificate_of_origin: '',
      job_comment: [{ job_comment: bookingData.comments }],
      id: quoteData.job_id
    };
    // console.log(jobData);
    updateJobDetails(jobData, bookingData.id, quoteData);
    setQuoteData({});
  }

  async function updateJobDetails(postData, booking_id, quoteData) {
    const response = await api.post("api/v1/tslEditJobs", postData, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      dispatch(addQuoteRateAccount({
        job_number: postData.id,
        cq_cad: quoteData.bl_charges + quoteData.freight + quoteData.telux,
        bl_charges: quoteData.bl_charges,
        freight: quoteData.freight,
        telux: quoteData.telux,
      }));
      let notification = {
        resource_id: postData.id,
        content: 'New Job #' + postData.job_number + ' Created'
      }
      if (postData?.customer_id > 0) {
        dispatch(addNotificationDetails({
          ...notification, resource: 'customer/dashboard', customer_id: postData.customer_id
        }));
      }
      if (postData?.sale_person_id > 0) {
        dispatch(addNotificationDetails({
          ...notification, resource: 'jobs', user_id: postData.sale_person_id
        }));
      }
      if (postData.container_number.length) {
        getUserIds({ 'roles.name': 'documentation' }, function (docUserIds) {
          docUserIds.length && docUserIds.map((user_rec, index) => {
            dispatch(addNotificationDetails({ ...notification, resource: 'jobs', user_id: user_rec.id }));
          });
        });
      }
      displaySuccessMessage("Booking Attached and New Job created");
      deleteBooking(booking_id);
    } else {
      displayErrorMessage("jobsAddedError");
    }
  }

  async function deleteBooking(id) {
    const response = await api.delete("api/v1/tslBookings/" + id, { headers: requestTokenHeader() });
    if (response.data.errorCode === 0) {
      console.log('Booking Deleted');
      dispatch(getquotesList({ searchIds }));
    } else {
      console.log('Error in Delete Booking');
    }
  }

  const callPageRecordsAttachBookings = (availableBookings) => {
    if (availableBookings?.length) {
      return availableBookings.map((data, index) => (
        <tr >
          <td>{data.booking_number}</td>
          <td>{data.cargo_curtoff_date}</td>
          <td>{data.origin}</td>
          <td>{data.destination}</td>
          <td>{data.vessal_name}</td>
          <td>
            <button className="btn btn-primary btn-xsm" onClick={() => attachBooking(data)}>Attach</button>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan='20'>No Record Found</td>
        </tr>
      );
    }
  };

  const callPageRecords = (quoteList) => {
    if (quoteList?.length) {
      return quoteList.map((data, index) => (
        <tr key={data.id} className={currentUser.user.role === 'sales' && data.is_read === '0' ? 'table-info' : ''}>
          <td>{data.quote_number}</td>
          <td>{moment(data.created_at).format('MM/DD/YYYY')}</td>
          <td>{data.firstname + (data.lastname ? ' ' + data.lastname : '')}</td>
          {
            ["rates", "booking"].includes(currentUser.user.role) &&
            <td>{data.salesperson}</td>
          }
          <td>{data.origin}</td>
          <td>{data.loading_port_name}</td>
          <td>{data.destination}</td>
          <td>{data.destination_port_name}</td>
          <td>{data.dangerous}</td>
          <td>{data.cargo_weight}</td>
          <td>{data.unit}</td>
          <td>{CONSTANT['priority'][data.priority]}</td>
          <td>
            {data.bl_charges + data.freight + data.telux > 0
              ? <>
                {'$' + parseFloat(data.bl_charges + data.freight + data.telux).toFixed(2)}
                <a className="btn btn-warning btn-xsm ms-1" data-tooltip-id={"rate-tooltip-" + index} data-tooltip-html={
                  "<span>Bl Charges: $" + data.bl_charges + "</span><br/>"
                  + "<span>Freight: $" + data.freight + "</span><br/>"
                  + "<span>Telux: $" + data.telux + "</span>"
                }><i className="fa fa-info"></i></a>
                <Tooltip id={"rate-tooltip-" + index} />
              </>
              : ''}
            {
              (currentUser.user.role === 'rates'
                || (currentUser.user.role === 'sales' && data.bl_charges + data.freight + data.telux > 0))
                && data.booking_request !== 'Yes' ?
                <button
                  className="btn btn-primary btn-xsm d-block m-auto"
                  onClick={() => handleRateModel(data)}
                >
                  {data.bl_charges + data.freight + data.telux > 0 ? 'Edit Rate' : 'Add Rate'}
                </button> : ""
            }
          </td>
          <td>{data.shipping_line}</td>
          {
            ['booking', "manager"].includes(currentUser.user.role) &&
            <>
              <td>{data.loading_date && data.loading_date !== '0000-00-00' ? moment(data.loading_date).format("Y-M-D") : ''}</td>
              <td>{data.trucking_location}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-primary btn-xsm"
                  onClick={() => handleAttachBooking(data)}
                  data-toggle="tooltip" data-placement="top"
                >
                  Attach
                </button>
              </td>
            </>
          }
          {
            currentUser.user.role !== 'booking' &&
            <td align="center">
              {
                ["admin", "sales", "manager"].includes(currentUser.user.role) && data.booking_request !== 'Yes' &&
                <button
                  type="button"
                  className="bd-none btn btn-warning btn-xsm"
                  onClick={() => _handleEditClick(data)}
                  data-toggle="tooltip" data-placement="top" title="Edit"
                >
                  <i className="fa fa-fw fa-edit"></i>
                </button>
              }
              {["sales", "rates", "admin", "manager"].includes(currentUser.user.role) &&
                <button
                  type="button"
                  className="bd-none btn btn-secondary btn-xsm"
                  onClick={() => _handleViewClick(data)}
                  data-toggle="tooltip" data-placement="top" title="View"
                >
                  <i className="fa fa-fw fa-eye"></i>
                </button>
              }
              {["sales", "admin", "manager"].includes(currentUser.user.role) && ''
                // <button
                //   type="button"
                //   className="bd-none btn btn-danger btn-xsm"
                //   onClick={() => _handleDeleteClick(data.id)}
                //   data-toggle="tooltip" data-placement="top" title="Delete"
                // >
                //   <i className="fa fa-fw fa-trash"></i>
                // </button>
              }
              {["rates", "admin", "manager"].includes(currentUser.user.role) &&
                <button
                  type="button"
                  className="bd-none btn btn-light btn-xsm"
                  onClick={() => _handleRateHistory(data.id)}
                  data-toggle="tooltip" data-placement="top" title="Rate History"
                >
                  <i className="fa fa-history"></i>
                </button>
              }
            </td>
          }
        </tr >
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan='20'>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteQuote = async (id) => {
    dispatch(deleteQuotesById(id, {}, '', searchIds));
    setShowModel(false);
    setDeleteModel(false);
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submitQuoteDetails = async (params) => {
    dispatch(addQuotesDetails(params, {}, searchIds));
    setShowModel(false);
    setAddModel(false);
  };

  const submitEditQuoteDetails = async (params) => {
    modalData.currentPriority = currentPriority;
    dispatch(editQuotesDetails(params, modalData, currentUser.user));
    setShowModel(false);
    setEditModel(false);
  };

  const handleSearhType = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <>
      <AdminLayout pageHeading="Quotes" pageIcon="fa fa-file-excel-o">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Quote List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                  {
                    ["sales", "admin", "manager"].includes(currentUser.user.role) ?
                      <button type="button" className="mb-2 mb-md-0 btn btn-primary" onClick={() => _handleAddClick()}>
                        Add Quote&nbsp;
                        <i className="fa fa-plus fa-sm"></i>
                      </button> : ''
                  }
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="fillter-wrap d-flex justify-content-end">
                    <select className="form-control me-2" value={searchType ?? ''} onChange={(event) => handleSearhType(event)}>
                      <option value="">Select Type</option>
                      <option value="quote_number">Quote Number</option>
                      <option value="o.name">Loading Port</option>
                      <option value="origin">Origin</option>
                      <option value="destination">Destination</option>
                      <option value="dangerous">Dangerous</option>
                      <option value="volume">Volume</option>
                      <option value="cq.created_at">Requested Date</option>
                    </select>
                    <input type="search" className="form-control" value={searchKeyword ?? ''} placeholder="Search" onChange={handleSearchText} aria-controls="dataTable" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Quote Number</th>
                          <th>Request Date</th>
                          <th>Customer Name</th>
                          {
                            ["rates", "booking"].includes(currentUser.user.role) &&
                            <th>Sales Person</th>
                          }
                          <th>Origin</th>
                          <th>Loading Port</th>
                          <th>Destination</th>
                          <th>Destination Port</th>
                          <th>Dangerous</th>
                          <th>Cargo Weight</th>
                          <th>Unit</th>
                          <th>Priority</th>
                          <th>Total Cost</th>
                          <th>Shipping Line</th>
                          {
                            ['booking', "manager"].includes(currentUser.user.role) &&
                            <>
                              <th>Loading Date</th>
                              <th>Trucking Location</th>
                              <th>Booking Number</th>
                            </>
                          }
                          {
                            currentUser.user.role !== 'booking' &&
                            <th className="text-center">Action</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {callPageRecords(quoteList)}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate"
                    >
                      {quoteCount > 0 ? (
                        <PaginationBlock
                          perPage={perPage}
                          userRecordsData={quoteCount}
                          callHandlePageClick={(data) =>
                            handlePageClick(data)
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalBox
            show={showModel}
            size={attachBookingModel ? 'xl' : (rateModel || deleteModel ? 'md' : 'xl')}
            title={viewModel ? "Quote View" : (addModel || editModel ? "Quote Form"
              : (rateHistoryModel ? "Rate History" : (rateModel ? "Rate Form"
                : (attachBookingModel ? "Attach Booking" : (deleteModel ? "Delete Quote" : '')))))}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddModel(false);
              setViewModel(false);
              setRateModel(false);
              setAttachBookingModel(false);
              setRateHistoryModel(false);
            }}
          >
            {
              editModel ? (
                <Form
                  editQuotesDetails={(params) => submitEditQuoteDetails(params)}
                  quoteDetails={modalData}
                />
              ) : deleteModel ? (
                <DeleteForm
                  userId={quoteId}
                  deleteUserId={(quoteId) => deleteQuote(quoteId)}
                  closeDeletePopUp={() => closeDeletePopUp()}
                />
              ) : addModel ? (
                <Form
                  addQuotesDetails={(params) => submitQuoteDetails(params)}
                />
              ) : viewModel ? (
                <QuoteView
                  quoteData={modalData}
                />
              ) : deleteModel ? (
                <DeleteForm
                  userId={quoteId}
                  deleteUserId={(quoteId) => deleteQuote(quoteId)}
                  closeDeletePopUp={() => closeDeletePopUp()}
                />) : rateHistoryModel ? (
                  <RateHistory
                    quoteId={quoteId}
                  />) :
                rateModel ?
                  (<div className="modal-body">
                    <div className="container login-wrap-new">
                      <div className="row">
                        <div className="col-md-6">
                          <label>Carrier*</label>
                          <select className="form-control" value={quoteCarrier} onChange={(e) => handleCarrierChange(e)}>
                            <option value=''>Select Carrier</option>
                            {
                              carriers.length && carriers.map((carrier, index) => {
                                return <option value={carrier.id} key={index}>{carrier.name}</option>
                              })
                            }
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label>Total Rate*</label>
                          <input type="number" className="form-control" value={quoteTotalCost} name="total_cost" placeholder="0.00" readOnly />
                        </div>
                        <div className="col-md-6">
                          <label>BL Charges</label>
                          <input type="number" className="form-control" value={quoteCosts.bl_charges} onChange={(e) => handleCostChange(e)} name="bl_charges" placeholder="0.00" />
                        </div>
                        <div className="col-md-6">
                          <label>Freight</label>
                          <input type="number" className="form-control" value={quoteCosts.freight} onChange={(e) => handleCostChange(e)} name="freight" placeholder="0.00" />
                        </div>
                        <div className="col-md-6">
                          <label>Telux</label>
                          <input type="number" className="form-control" value={quoteCosts.telux} onChange={(e) => handleCostChange(e)} name="telux" placeholder="0.00" />
                        </div>
                        <div className="col-md-12">
                          <button className="btn btn-primary" onClick={() => updateQuoteCost()}>
                            {modalData.bl_charges + modalData.freight + modalData.telux > 0 ? 'Edit Rate' : 'Add Rate'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>) :
                  attachBookingModel ?
                    (<div className="modal-body">
                      <div className="container login-wrap-new">
                        <div className="row">
                          <div className="col-md-12">

                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Booking Number</th>
                                    <th scope="col">Cut-off date</th>
                                    <th scope="col">Origin</th>
                                    <th scope="col">Destination</th>
                                    <th scope="col">Vessel</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  {callPageRecordsAttachBookings(availableBookings)}
                                </tbody>
                              </table>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>) : ("")}
          </ModalBox>
        </div>
      </AdminLayout >
    </>
  );
};
export default Quotes;
