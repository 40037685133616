import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
export function ExcelForm(props) {
    const [fields, setFields] = useState('');
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState(false);
    const [applyCheck] = useState(false);



    const validateContactFrom = Yup.object().shape({
        type: Yup.string().required('Filed is required'),
        excel_file: Yup.string().required('File Required')
    });
    let initialValues = {};

    initialValues = {
        type: "",
        excel_file: "",
    }




    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append("excel_file", values.excel_file);
            formData.append("type", values.type);
            props.addUserDetails(formData)


        },
    });

    return (
        <>
            <div className="modal-body">
                <div className="login-wrap-new">
                    <form onSubmit={formik.handleSubmit} className='invitation-wrap'>
                        <div className="form-content">
                            <div className="row">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Type</label>
                                        <select className="form-control"
                                            name="type"
                                            id="type"
                                            {...formik.getFieldProps('type')}>
                                            <option value="">--Please Select--</option>
                                            <option value="payable">Payable</option>
                                            <option value="trucker">Trucker</option>
                                        </select>
                                        {formik.touched.type && formik.errors.type && (
                                            <div className="errorMsg text-danger">{formik.errors.type}</div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label>Upload File</label>
                                        <input
                                            type='file'
                                            className="form-control"
                                            name="excel_file"
                                            id="excel_file"
                                            onChange={(event) => {
                                                if (event.currentTarget.files) {
                                                    formik.setFieldValue(
                                                        "excel_file",
                                                        event.currentTarget.files[0]
                                                    );
                                                }
                                            }
                                            }

                                        />
                                        {formik.touched.excel_file && formik.errors.excel_file && (
                                            <div className="errorMsg text-danger">{formik.errors.excel_file}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-4">
                                    <input type="submit" name="submit" className="btn btn-primary" value="Upload" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}