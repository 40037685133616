import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
export function ContainerForm(props) {

    const [fields, setFields] = useState(props.userDetails ? props.userDetails : {});
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState(false);
    const [applyCheck] = useState(false);

    const validateContactFrom = Yup.object().shape({
        container_number: Yup.string().required('Container number is required').max(150),
        description: Yup.string().required('Description is required').max(1500),
    });
    let initialValues = {};
    if (fields) {
        initialValues = {
            container_number: fields.container_number,
            description: fields.description, 
        }

    } else {
        initialValues = {
            container_number: '',
            description: ""
        }
    }


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            if (fields?.id) {
                const postData = {
                    container_number: values.container_number,  
                    description: values.description,                   
                    id: fields.id,
                }
                props.editUserDetails(postData);

            } else {

                const params = {
                    container_number: values.container_number,
                    description: values.description,
                }
                props.addUserDetails(params)
            }        },
    });

    return (
        <>
            <div className="modal-body invitation-wrap">
                <div className="login-wrap-new">
                    <div className="form-content">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">

                                <div className="col-md-12 col-xs-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Container Number"
                                        id="container_number"
                                        name="container_number"
                                        {...formik.getFieldProps('container_number')}
                                    />
                                    {formik.touched.container_number && formik.errors.container_number && (
                                        <div className="errorMsg text-danger">{formik.errors.container_number}</div>
                                    )}

                                </div>
                                <div className="col-md-12 col-xs-12">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Description"
                                        id="description"
                                        name="description"
                                        {...formik.getFieldProps('description')}
                                    />
                                    {formik.touched.description && formik.errors.description && (
                                        <div className="errorMsg text-danger">{formik.errors.description}</div>
                                    )}

                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                { fields.id ? 'Save Container' : 'Save Container' }
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}