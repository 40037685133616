import { USER_ACTION_TYPE } from "../../../Constant/user.constants";

const INITIAL_STATE = {
  showCustomerBalanceReportingList: [],
  blocking:false,
};
export const customerBalanceReportingListReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPE.SET_CUSTOMERBALANCEREPORTING_LIST_PENDING:
      return {
        blocking: true,
        showCustomerBalanceReportingList: [],
      };

    case USER_ACTION_TYPE.SET_CUSTOMERBALANCEREPORTING_LIST_SUCESS:
      return {
        blocking: false,
        showCustomerBalanceReportingList: payload,
      };

    case USER_ACTION_TYPE.SET_CUSTOMERBALANCEREPORTING_LIST_ERROR:
      return {
        blocking: true,
        showCustomerBalanceReportingList: [],
      };

    case USER_ACTION_TYPE.RESET_CUSTOMERBALANCEREPORTING_LIST:
      return {
        blocking: true,
        showCustomerBalanceReportingList: [],
      };

    default:
      return state;
  }
};
