import React, { useState, useEffect, useCallback,useRef } from 'react';
import Checkbox from '../../../../src/Components/Ui/Checkbox';
import { getcustomerList } from "../../../Services/Store/Common/customer/customer.action";
import { useDispatch, useSelector } from "react-redux";
import { customerList } from "../../../Services/Store/Common/customer/customer.selector";
import CONSTANT from '../../../Services/Constant/user.constants';
import { requestTokenHeader, equipmentList, commodityList } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import Select from "react-select";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
const libraries = ['places'];
export function Form(props) {
    const inputRefOrigin = useRef();
    const inputRefDestination = useRef();
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDm9SqM0fp4sg7KSLBachj-WS7zpQ80Wtk',
        libraries
    });
    const [originDestination, setOriginDestination] = useState("");
    const [fields, setFields] = useState(props.quoteDetails ? props.quoteDetails : {});
    const [errors, setErrors] = useState({});
    const [customersList, setCustomerList] = useState([]);
    const [applyCheck] = useState(false);
    const customerData = useSelector(customerList);
    const dispatch = useDispatch();
    const showCustomer = props.quoteDetails?.customer_id ? false : true;
    const [equipments, setEquipments] = useState(props.quoteDetails?.equipment_types ? JSON.parse(props.quoteDetails.equipment_types) : []);
    const handlePlaceChangedOrigin = () => {
        const [place] = inputRefOrigin.current.getPlaces();
        if (place) {
            let data = fields;
            data["origin"] = place.formatted_address;
            setFields({ ...data });
        }
    }

    const handlePlaceChangedDestination = () => {
        const [place] = inputRefDestination.current.getPlaces();
        if (place) {
            let data = fields;
            data["destination"] = place.formatted_address;
            setFields({ ...data });
        }
    }
    useEffect(() => {
        dispatch(getcustomerList({ offset: 0 }));
    }, []);

    useEffect(() => {
        setCustomerList(customerData.result);
    }, [customerData]);

    const handleChange = (event) => {
        let data = fields;
        data[event.target.name] = event.target.value;
        setFields({ ...data });
    };

    const validateRegistration = (fields, applyCheck = false) => {
        let errors = {};
        let formIsValid = true;

        if (!fields["loading_port"] || (fields["loading_port"]).toString().trim() === "") {
            formIsValid = false;
            errors["loading_port"] = "*Field is required.";
        }
        if (!fields["destination_port"] || (fields["destination_port"]).toString().trim() === "") {
            formIsValid = false;
            errors["destination_port"] = "*Field is required.";
        }

        if (!fields["origin"] || fields["origin"].trim() === "") {
            formIsValid = false;
            errors["origin"] = "*Field is required.";
        }
        if (!fields["destination"] || fields["destination"].trim() === "") {
            formIsValid = false;
            errors["destination"] = "*Field is required.";
        }

        if (!fields["item_type"] || fields["item_type"].trim() === "") {
            formIsValid = false;
            errors["item_type"] = "*Field is required.";
        }

        if (!fields["dangerous"] || fields["dangerous"].trim() === "") {
            formIsValid = false;
            errors["dangerous"] = "*Field is required.";
        }

        // if (!fields["volume"] || fields["volume"].trim() === "") {
        //     formIsValid = false;
        //     errors["volume"] = "*Field is required.";
        // }


        if (!fields["cargo_weight"] || (fields["cargo_weight"]).toString().trim() === "") {
            formIsValid = false;
            errors["cargo_weight"] = "*Field is required.";
        }
        if (!fields["unit"] || fields["unit"].trim() === "") {
            formIsValid = false;
            errors["unit"] = "*Field is required.";
        }
        



        if (!fields["priority"] || fields["priority"].trim() === "") {
            formIsValid = false;
            errors["priority"] = "*Field is required.";
        }

        if (!fields["customer_id"] || parseFloat(fields["customer_id"]) === "") {
            formIsValid = false;
            errors["customer_id"] = "*Field is required.";
        }

        if (equipments.length <= 0) {
            formIsValid = false;
            errors["equipment_types"] = "*Field is required.";
        }
        return {
            errors: errors,
            formIsValid: formIsValid,
        };
    };

    const _validateForm = () => {
        let formFields = fields;
        let response = validateRegistration(formFields, applyCheck);
        setErrors(response.errors);
        return response.formIsValid;
    };

    const onHandleChange = useCallback((value, name) => {
        setEquipments(value);
        setFields(prev => ({
            ...prev,
            [name]: value
        }));
    }, []);

    const updatequoteDetails = async (event) => {
        event.preventDefault();
        if (_validateForm()) {
            const postData = {
                customer_id: fields.customer_id,
                origin: fields.origin,
                loading_port: fields.loading_port,
                destination: fields.destination,
                destination_port: fields.destination_port,
                item_type: fields.item_type,
                dangerous: fields.dangerous,
                // volume: fields.volume,
                equipment_types: equipments?.length ? JSON.stringify(equipments) : '',
                comments: fields.comments,
                priority: fields.priority,
                cargo_weight: fields.cargo_weight,
                unit: fields.unit
            }
            if (props.quoteDetails?.id) {
                postData['id'] = fields.id;
                props.editQuotesDetails(postData);
            } else {
                props.addQuotesDetails(postData);
            }
        }
    }

    useEffect(() => {
        async function fetchOriginDestination() {
            const response = await api.get("api/v1/tslgetOriginDestination", {
                params: "",
                headers: requestTokenHeader()
            });
            if (response?.data?.errorCode === 0) {
                setOriginDestination(response.data.data);
            } else {
                setOriginDestination("");
            }
        }
        fetchOriginDestination();
    }, []);

    const optionsOrigin = [];
    const optionsDestination = [];
    originDestination?.[0]?.map((data, index) => (
        optionsOrigin.push({ value: data.id, label: data.name + (data.country ? ', ' + data.country : '') })
    ));
    originDestination?.[1]?.map((data, index) => (
        optionsDestination.push({ value: data.id, label: data.name + (data.country ? ', ' + data.country : '') })
    ));

    const handleOriginSelect = e => {
        let data = fields;
        data["loading_port"] = e.value;
        setFields({ ...data });
    };

    const handleDestinationSelect = e => {
        let data = fields;
        data["destination_port"] = e.value;
        setFields({ ...data });
    };

    return (
        <>
            <div className="modal-body">
                <form onSubmit={(event) => updatequoteDetails(event)} className='invitation-wrap form-content'>
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Origin*</label>
                            {isLoaded
                                && <StandaloneSearchBox
                                    onLoad={ref => inputRefOrigin.current = ref}
                                    onPlacesChanged={handlePlaceChangedOrigin}
                                >
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="origin"
                                        placeholder='Origin'
                                        value={fields.origin ? fields.origin : ''}
                                        onChange={(event) => handleChange(event)}
                                    />
                                </StandaloneSearchBox>}
                            {errors.origin ? (
                                <div className="errorMsg text-danger">
                                    {errors.origin}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-sm-3">
                            <label>Loading Port*</label>
                            <Select
                                name="loading_port"
                                id="loading_port"
                                options={optionsOrigin}
                                onChange={handleOriginSelect}
                                value={optionsOrigin.filter(function (option) {
                                    return option.value == fields.loading_port;
                                })}
                                label="Single select"
                            />

                            {errors.loading_port ? (
                                <div className="errorMsg text-danger">
                                    {errors.loading_port}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-sm-3">
                            <label>Destination *</label>
                            {isLoaded
                                && <StandaloneSearchBox
                                    onLoad={ref => inputRefDestination.current = ref}
                                    onPlacesChanged={handlePlaceChangedDestination}
                                >
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="destination"
                                        placeholder='Destination'
                                        value={fields.destination ? fields.destination : ''}
                                        onChange={(event) => handleChange(event)}
                                    />

                                </StandaloneSearchBox>}
                            {errors.destination ? (
                                <div className="errorMsg text-danger">
                                    {errors.destination}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-sm-3">
                            <label>Destination Port *</label>
                            <Select
                                name="destination_port"
                                id="destination_port"
                                options={optionsDestination}
                                onChange={handleDestinationSelect}
                                value={optionsDestination.filter(function (option) {
                                    return option.value == fields.destination_port;
                                })}
                                label="Single select"
                            />

                            {errors.destination_port ? (
                                <div className="errorMsg text-danger">
                                    {errors.destination_port}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-sm-3">
                            <label>Commodity *</label>
                            <select className="form-control" name="item_type" onChange={(event) => handleChange(event)} value={fields?.item_type}>
                                <option>Select</option>
                                {
                                    commodityList.map((item, index) => {
                                        return <option key={index} value={item}>{item}</option>
                                    })
                                }
                            </select>
                            {errors.type ? (
                                <div className="errorMsg text-danger">
                                    {errors.type}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-sm-3">
                            <label>Dangerous *</label>
                            <select className="form-control" name="dangerous" onChange={(event) => handleChange(event)} value={fields?.dangerous}>
                                <option>Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            {errors.dangerous ? (
                                <div className="errorMsg text-danger">
                                    {errors.dangerous}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        {/* <div className="col-sm-3">
                            <label>Potential Volume *</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="volume"
                                value={fields.volume ? fields.volume : ''}
                                onChange={(event) => handleChange(event)}
                            />

                            {errors.volume ? (
                                <div className="errorMsg text-danger">
                                    {errors.volume}
                                </div>
                            ) : (
                                ""
                            )}
                            
                        </div> */}
                        <div className="col-sm-3">
                            <label>Potential Cargo Weight *</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="cargo_weight"
                                value={fields.cargo_weight ? fields.cargo_weight : ''}
                                onChange={(event) => handleChange(event)}
                            />

                            {errors.cargo_weight ? (
                                <div className="errorMsg text-danger">
                                    {errors.cargo_weight}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-sm-3">
                            <label>Unit *</label>
                            <select className="form-control" name="unit" onChange={(event) => handleChange(event)} value={fields?.unit}>
                                <option>Select</option>
                                <option value="Kg">Kg</option>
                                <option value="Lb">Lb</option>
                                <option value="Ton">Ton</option>
                            </select>
                            {errors.unit ? (
                                <div className="errorMsg text-danger">
                                    {errors.unit}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="col-sm-3">
                            <label>Priority *</label>
                            <select className="form-control" name="priority" onChange={(event) => handleChange(event)} value={fields?.priority}>
                                <option>Select</option>
                                {
                                    CONSTANT['priority'] && CONSTANT['priority'].map((val, key) => {
                                        return <option key={key} value={key}>{val}</option>
                                    })
                                }
                            </select>
                            {errors.priority ? (
                                <div className="errorMsg text-danger">
                                    {errors.priority}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        {
                            showCustomer ?
                                <div className="col-sm-3">
                                    <label>Customer*</label>
                                    <select className="form-control" name="customer_id" onChange={(event) => handleChange(event)} value={fields?.customer_id}>
                                        <option>Select</option>
                                        {
                                            customersList && customersList.map((customer, index) => {
                                                return (<option key={index} value={customer.id}>{customer.firstname}</option>);
                                            })
                                        }
                                    </select>
                                    {errors.customer_id ? (
                                        <div className="errorMsg text-danger">
                                            {errors.customer_id}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div> : ""
                        }
                        <div className="col-sm-12">
                            <Checkbox
                                name="equipment_types"
                                title="Equipment Types *"
                                value={equipments}
                                options={equipmentList}
                                onChangeFunc={onHandleChange}
                            />

                            {errors.equipment_types ? (
                                <div className="errorMsg text-danger">
                                    {errors.equipment_types}
                                </div>
                            ) : (
                                ""
                            )}

                        </div>
                        <div className="col-sm-12">
                            <label>Comments</label>
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="comments"
                                value={fields.comments ? fields.comments : ''}
                                onChange={(event) => handleChange(event)}
                            />
                            {errors.comments ? (
                                <div className="errorMsg text-danger">
                                    {errors.comments}
                                </div>
                            ) : (
                                ""
                            )}

                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className="col-sm-3">
                            <button type="submit" className="btn btn-primary">
                                {fields.id ? 'Save Quote' : 'Save Quote'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}