import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
    quotationPerMonthVolumeReportingList
} from "../../../Services/Store/Common/quotationPerMonth/quotationPerMonth.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { quotationPerMonthReportingList } from "../../../Services/Store/Common/quotationPerMonth/quotationPerMonth.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
const Contact = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [userData, setUserData] = useState([]);
    const [userId, setUserId] = useState("");
    const [errorResponce, setErrorResponce] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [addModel, setAddModel] = useState(false);
    const [perPage, setPerPage] = useState(25);
    const [modalData, setModalData] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [userRecordsData, setUserRecordsData] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ratesListResult = useSelector(quotationPerMonthReportingList);
    // const mainClientresponse = useSelector(mainClient);
    const [searchType, setSearchType] = useState("");
    const [customerData, setCustomerData] = useState("");

    useEffect(() => {
        const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

        if (!loginCheck || errorResponce === 4) {
            navigate("/");
        }

    }, []);

    function getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1);
      
        return date.toLocaleString('en-US', {
          month: 'long',
        });
      }

    useEffect(() => {
        async function fetchMyAPI2() {
            const response = await api.get("api/v1/tslCustomerListById", {
                params: { offset: 0, limit: 9999999999999, deleteCond: true  },
                headers: requestTokenHeader()
            });
            if (response.data.errorCode === 0) {
                setCustomerData(response?.data?.data);
            } else {
                setCustomerData("");
            }
        }

        fetchMyAPI2();

    }, []);


    useEffect(() => {
        if (ratesListResult.result !== undefined) {
            const result = ratesListResult.result;
            if (result.length > 0) {
                setUserData(ratesListResult.result);
                setUserRecordsData(ratesListResult.records);
                setErrorResponce(ratesListResult.error_code);
            } else {
                setUserData("");
                setUserRecordsData("");
            }
        }
    }, [ratesListResult]);



    useEffect(() => {
      const getData = setTimeout(() => {
        dispatch(quotationPerMonthVolumeReportingList({ searchType: searchType, search: searchKeyword, offset }));      
      }, 500);
      return () => clearTimeout(getData);
    }, []);


    /** For pagination code start here **/
    var offset = currentPage * perPage;
    const handlePageClick = (selectedPage) => {
        dispatch(
            quotationPerMonthVolumeReportingList({ searchType: searchType, search: searchKeyword, offset: selectedPage})
        );
    };
    /** For pagination code end here **/

    const handleChange = (event) => {
        if (searchType) {
            setSearchKeyword(event.target.value.trim());
        }
    };



    const callPageRecords = (userData) => {
        console.log(userData)
        if (userData) {




            let text = userData.map((data, index) => {
                let classname = "";
          
                return (<tr key={index}>
                    <td className="">{data.year}</td>
                    {/* <td className="">{data.avg_bookings}</td> */}
                    <td className="">{getMonthName(data.month)}</td>
                    <td className= {classname}>{data.count}</td>
            
            
                    </tr>)
                
                

               

            })
            return text


        } else {
            return (
                <tr className="text-center">
                    <td colSpan={3}>No Record Found</td>
                </tr>
            );
        }
    };



    

   


    const deleteUser = async (userId2) => {
        //dispatch(deleteRatesById(userId2));
        setShowModel(false);
        setDeleteModel(false);

    };





    const handleSearhChange = (event) => {
        setSearchType(event.target.value);
    };

    const validateContactFrom = Yup.object().shape({
       // customer_name: Yup.number().required('Field is required'),


    });
    let initialValues = {
        customer_name: "",
       
    }
    
    
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {

            dispatch(quotationPerMonthVolumeReportingList({ searchType: searchType, search: searchKeyword, offset}));

        },
    });

    return (
        <>
            <AdminLayout pageHeading="Quotation Per Month" pageIcon="fa fa-bar-chart">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <h3 className="h3 mb-4 text-gray-800">Quotation Per Month</h3>
                        </div>
                    </div>
                </div>
                    <div className="container-fluid demo">
                        <div
                            className="panel-group dashboard-table-format"
                            id="accordion"
                            role="tablist"
                            aria-multiselectable="true"
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="headingOne"
                                >
                                    <div className="panel-body">
                                        <div className="card  mb-4">
                                            <div className="card-body">
                                                
                                                    <div
                                                        id="dataTable_wrapper"
                                                        className="dataTables_wrapper dt-bootstrap4"
                                                    >

                                                     
                                                     
                                                    </div>
                                                    <div className="table-responsive">
                                                    <table className="table table-striped table-bordered" id="pdf">
                                                        <thead>
                                                            <tr className="bg-primary text-white">
                                                               
                                                                <th>Year</th>
                                                                {/* <th>Average Bookings</th> */}
                                                                <th>Month</th>
                                                                <th>Customer Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ratesListResult.result !==
                                                                undefined &&
                                                                ratesListResult.result !== "" ? (
                                                                callPageRecords(
                                                                    ratesListResult.result
                                                                )
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={3}>No Record Found</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>

                                                 
                                                 
                                                            
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-5">
                                                        {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                                       */}
                                                    </div>
                                                    <div className="col-sm-12 col-md-7">
                                                        <div
                                                            className="dataTables_paginate paging_simple_numbers"
                                                            id="dataTable_paginate"
                                                        >
                                                            {userData != undefined &&
                                                                userData.length > 0 &&
                                                                userRecordsData !== undefined ? (
                                                                <PaginationBlock
                                                                    perPage={perPage}
                                                                    userRecordsData={userRecordsData}
                                                                    callHandlePageClick={(data) =>
                                                                        handlePageClick(data)
                                                                    }
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                

            </AdminLayout>
        </>
    );
};
export default Contact;
