import React, { useState, useEffect } from 'react';
import { requestTokenHeader } from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import moment from "moment";


// import jsPDF from 'jspdf';
// import html2canvas from "html2canvas";
// import { type } from '@testing-library/user-event/dist/type';
export function VesseloperationForm(props) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [successStatus, setSuccessStatus] = useState(false);



  useEffect(() => {
    async function fetchMyAPICMACGM() {

      if (props.jobsDetails?.origin != "" && props.jobsDetails?.destination != "" && props.jobsDetails?.carriers_name == "CMA CGM" && props.jobsDetails?.eta != "0000-00-00" && props.jobsDetails?.etd != "0000-00-00" || 1 == 1) {


        const response = await api.get("api/v1/tslCMACGMVesseloperationApiData", {
          params: {
            token: props?.mscToken?.[0]?.token,
            url: 'https://apis.cma-cgm.net/vesseloperation/route/v2/routings',
            placeOfLoading: props.jobsDetails?.origin,
            placeOfDischarge: props.jobsDetails?.destination,
            departureDate: props.jobsDetails?.eta,
            arrivalDate: props.jobsDetails?.etd
          },
          headers: requestTokenHeader()
        });
        if (response.data.errorCode === 0) {
          setFields(response.data.data);
        } else {
          setFields("");
        }

      } else {
        setFields("");
      }

    }



    async function fetchMyAPIZIM() {

      if (props.jobsDetails?.origin != "" && props.jobsDetails?.destination != "" && props.jobsDetails?.carriers_name == "CMA CGM" && props.jobsDetails?.eta != "0000-00-00" && props.jobsDetails?.etd != "0000-00-00" || 1 == 1) {


        const response = await api.get("api/v1/tslZIMVesseloperationApiData", {
          params: {
            token: props?.mscToken?.[0]?.token,
            url: 'https://apigw.zim.com/vesselSchedule-sandbox/v2/pointToPoint',
            originCode: props.jobsDetails?.origin,
            destCode: props.jobsDetails?.destination,
            fromDate: props.jobsDetails?.eta,
            toDate: props.jobsDetails?.etd
          },
          headers: requestTokenHeader()
        });
        if (response.data.errorCode === 0) {
          setFields(response.data.data);
        } else {
          setFields("");
        }

      } else {
        setFields("");
      }

    }



    if (props.jobsDetails?.shipping_line_id === 5) { //CMA cgm line
      fetchMyAPICMACGM();
    }

    if (props.jobsDetails?.shipping_line_id === 6) { //ZiM line
      fetchMyAPIZIM();
    }


  }, [props]);


  const callPageRecords = (userData) => {

    if (props.jobsDetails?.shipping_line_id === 6) { //ZIM

      if (userData && userData?.["response"]?.["routes"].length > 0) {


        return userData?.["response"]?.["routes"].map((data, index) =>
        (
          <tr>


            <td><span>{data?.arrivalPortName}</span></td>
            <td><span>{data?.arrivalDate ? moment(data.arrivalDate).format("YYYY-MM-DD") : ""}</span></td>

            <td>{data.departurePortName}</td>
            <td><span>{data?.departureDate ? moment(data.departureDate).format("YYYY-MM-DD") : ""}</span></td>
            <td>{data.routeLegCount}</td>




          </tr>


        ));
      } else {
        return (
          <tr className="text-center">
            <td colSpan={13}>No Record Found</td>
          </tr>
        );
      }
    }




  };

  return (
    <>
      <div className="invitation-wrap">
        <div className="container">
          <div className="row login-wrap-new">
            <div className="col-md-12 col-xs-12">
              <div className="form-content">

                <div className="order-track">

                  <div className="row">
                    <div className="col-xl-12 col-md-12 mb-4">
                      <div className="card shadow h-100 p-4">
                        <div className="card-body py-0 px-2">
                          <div className="table-responsive">
                            <table className="table table-striped table-bordered text-left">
                              <thead>


                                {props.jobsDetails?.shipping_line_id === 5 &&

                                  <tr>
                                    <th>Port And Terminal</th>
                                    <th>Arival At Berth</th>
                                    <th>Special Booking Cut-off</th>
                                    <th>Standard Booking Cut-off</th>
                                    <th>VGM Cut-off</th>
                                    <th>Port Cut-off</th>
                                    <th>SI-off</th>
                                  </tr>

                                }

                                {props.jobsDetails?.shipping_line_id === 6 &&

                                  <tr>
                                    <th>Departure Port Name</th>
                                    <th>Arival Port Name</th>
                                    <th>Departure Date</th>
                                    <th>Arival Date</th>
                                    <th>Doc Closing Date</th>
                                    <th>VGM Closing Date</th>
                                    <th>First Gate In Date</th>
                                    <th>Container Closing Date</th>

                                  </tr>

                                }

                              </thead>
                              <tbody>
                                {
                                  fields !==
                                  undefined &&
                                  fields !== "" && fields.length > 0 && props.jobsDetails?.shipping_line_id === 5 &&
                                  fields.map(item => {

                                    return (
                                      <>

                                        {
                                          item["routingDetails"].map(data => {
                                            return (
                                              <tr>
                                                <td>
                                                  <ul className="p-0 ml-3 text-left">
                                                    <li>
                                                      {/* <a href="#">{data.pointFrom.location.name}</a><br></br> */}
                                                      <span>
                                                        <i className="fa fa-home"></i> {" "}
                                                        {data.pointFrom.location.name}
                                                      </span>
                                                    </li>
                                                  </ul>
                                                </td>
                                                <td><span>{moment(data.pointTo.arrivalDateGmt).format("YYYY-MM-DD")}</span></td>
                                                <td>
                                                  <span>{data?.pointFrom?.cutOff?.specialBookingAcceptance?.utc ? moment(data?.pointFrom?.cutOff?.specialBookingAcceptance?.utc).format("YYYY-MM-DD") : ""}</span><br></br>
                                                  <span><i className={data?.pointFrom?.cutOff?.specialBookingAcceptance?.utc ? "fa fa-clock-o" : ""}></i> {data?.pointFrom?.cutOff?.specialBookingAcceptance?.utc ? moment(data.pointFrom.cutOff.specialBookingAcceptance.utc).format("h:mm:ss a") : ""}</span>
                                                </td>
                                                <td>
                                                  <span>{data?.pointFrom?.cutOff?.standardBookingAcceptance?.utc ? moment(data?.pointFrom?.cutOff?.standardBookingAcceptance?.utc).format("YYYY-MM-DD") : ""}</span><br></br>
                                                  <span><i className={data?.pointFrom?.cutOff?.standardBookingAcceptance?.utc ? "fa fa-clock-o" : ""}></i> {data?.pointFrom?.cutOff?.standardBookingAcceptance?.utc ? moment(data.pointFrom.cutOff.standardBookingAcceptance.utc).format("h:mm:ss a") : ""}</span>
                                                </td>
                                                <td>
                                                  <span>{data?.pointFrom?.cutOff?.vgm?.utc ? moment(data?.pointFrom?.cutOff?.vgm?.utc).format("YYYY-MM-DD") : ""}</span><br></br>
                                                  <span><i className={data?.pointFrom?.cutOff?.vgm?.utc ? "fa fa-clock-o" : ""}></i> {data?.pointFrom?.cutOff?.vgm?.utc ? moment(data.pointFrom.cutOff.vgm.utc).format("h:mm:ss a") : ""}</span>
                                                </td>
                                                <td>
                                                  <span>{data?.pointFrom?.cutOff?.portCutoff?.utc ? moment(data?.pointFrom?.cutOff?.portCutoff?.utc).format("YYYY-MM-DD") : ""}</span><br></br>
                                                  <span><i className={data?.pointFrom?.cutOff?.portCutoff?.utc ? "fa fa-clock-o" : ""}></i> {data?.pointFrom?.cutOff?.portCutoff?.utc ? moment(data.pointFrom.cutOff.portCutoff.utc).format("h:mm:ss a") : ""}</span>
                                                </td>
                                                <td>
                                                  <span>{data?.pointFrom?.cutOff?.shippingInstructionAcceptance?.utc ? moment(data?.pointFrom?.cutOff?.shippingInstructionAcceptance?.utc).format("YYYY-MM-DD") : ""}</span><br></br>
                                                  <span><i className={data?.pointFrom?.cutOff?.shippingInstructionAcceptance?.utc ? "fa fa-clock-o" : ""}></i> {data?.pointFrom?.cutOff?.shippingInstructionAcceptance?.utc ? moment(data.pointFrom.cutOff.shippingInstructionAcceptance.utc).format("h:mm:ss a") : ""}</span>
                                                </td>


                                              </tr>

                                            )
                                          })
                                        }

                                      </>


                                    )


                                  })
                                }



                                {
                                  fields !==
                                  undefined &&
                                  fields !== "" && props.jobsDetails?.shipping_line_id === 6 &&
                                  fields?.["response"]?.["routes"].map(item => {

                                    return (
                                      <>

                                        {
                                          item["routeLegs"].map(data => {
                                            return (
                                              <tr>
                                                <td>
                                                  <ul className="p-0 ml-3 text-left">
                                                    <li>
                                                      {/* <a href="#">{data.pointFrom.location.name}</a><br></br> */}
                                                      <span>
                                                        <i className="fa fa-home"></i> {" "}
                                                        {data.departurePortName}
                                                      </span>
                                                    </li>
                                                  </ul>
                                                </td>

                                                <td>
                                                  <ul className="p-0 ml-3 text-left">
                                                    <li>
                                                      {/* <a href="#">{data.pointFrom.location.name}</a><br></br> */}
                                                      <span>
                                                        <i className="fa fa-plane"></i> {" "}
                                                        {data.arrivalPortName}
                                                      </span>
                                                    </li>
                                                  </ul>
                                                </td>

                                                <td>{data.departureDate ? moment(data.departureDate).format("YYYY-MM-DD") : ""}</td>
                                                <td>{data.arrivalDate ? moment(data.arrivalDate).format("YYYY-MM-DD") : ""}</td>
                                                <td>{data.docClosingDate ? moment(data.docClosingDate).format("YYYY-MM-DD") : ""}</td>
                                                <td><span>{data?.vgmClosingDate ? moment(data.vgmClosingDate).format("YYYY-MM-DD") : ""}</span></td>

                                                <td>
                                                  <span>{data?.firstGateInDate ? moment(data?.firstGateInDate).format("YYYY-MM-DD") : ""}</span><br></br>
                                                </td>

                                                <td>
                                                  <span>{data?.containerClosingDate ? moment(data?.containerClosingDate).format("YYYY-MM-DD") : ""}</span><br></br>

                                                </td>




                                              </tr>

                                            )
                                          })
                                        }

                                      </>


                                    )


                                  })
                                }

                                {/* {fields !==
                                  undefined &&
                                  fields !== "" && props.jobsDetails?.shipping_line_id == 6 ? (
                                  callPageRecords(
                                    fields
                                  )
                                ) : (
                                  <div className='text-center mb-4'>No Record Found</div>
                                )} */}


                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}