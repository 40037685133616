import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
export function CarrierForm(props) {

    const [fields, setFields] = useState(props.userDetails ? props.userDetails : {});
    const [errors, setErrors] = useState({});
    const [successStatus, setSuccessStatus] = useState(false);
    const [applyCheck] = useState(false);

    const validateContactFrom = Yup.object().shape({
        name: Yup.string().required('Carrier name is required').max(150),
    });
    let initialValues = {};
    if (fields) {
        initialValues = {
            name: fields.name,
        }

    } else {
        initialValues = {
            name: '',
        }
    }


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            if (fields?.id) {
                const postData = {
                    name: values.name,                    
                    id: fields.id,
                }
                props.editUserDetails(postData);

            } else {

                const params = {
                    name: values.name,
                }
                props.addUserDetails(params)
            }


        },
    });



    return (
        <>
            <div className="modal-body invitation-wrap">
                <div className="login-wrap-new">
                    <div className="form-content">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">

                                <div className="col-md-12 col-xs-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Carrier name"
                                        id="name"
                                        name="name"
                                        {...formik.getFieldProps('name')}
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <div className="errorMsg text-danger">{formik.errors.name}</div>
                                    )}

                                </div>
                                
                            </div>
                            <button type="submit" className="btn btn-primary">
                                {fields.id ? 'Save Carrier' : 'Save Carrier'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}