import axios from "axios";

import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import {  displayErrorMessage, displaySuccessMessage,requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";


export const deleteServiceById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteServices",{id},
                                   { headers:requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      const showServiceList = {};
      const resultOutput = getState()
        .serviceList.showServiceList.result.map((data) => data)
        .filter((data) => data.id !== id);
      showServiceList.result = resultOutput;
      showServiceList.records = getState().serviceList.showServiceList.records - 1;
      showServiceList.errorCode = 0;
      displaySuccessMessage("serviceDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_SERVICE_LIST_SUCESS,
        payload: showServiceList,
      });
    } else {
      displayErrorMessage("serviceDeleteError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_SERVICE_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_SERVICE_LIST_ERROR });
  }
};

export const getserviceList= (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_SERVICE_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslServicesList", {
                                    params: postData,
                                    headers:requestTokenHeader()
                                  });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_SERVICE_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_SERVICE_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    return dispatch({ type: USER_ACTION_TYPE.SET_SERVICE_LIST_ERROR });
  }
};



export const addServiceDetails = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddService",postData,
      { headers:requestTokenHeader() });
    if (response.data.errorCode === 0) {
      const resultOutput = getState().serviceList.showServiceList.result;
      resultOutput.push(response.data.result);
      const showServiceList = {};
      showServiceList.result = resultOutput;
      showServiceList.records = getState().serviceList.showServiceList.records + 1;
      showServiceList.errorCode = 0;
      displaySuccessMessage("serviceAdded");
      return dispatch({
        type: USER_ACTION_TYPE.SET_SERVICE_LIST_SUCESS,
        payload: showServiceList,
      });
    } else {
      displayErrorMessage("serviceAddedError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_SERVICE_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_SERVICE_LIST_ERROR });
  }
};


export const editServiceDetails = (postData, searchIds) => 
 {
 return async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslEditService",postData,
    {
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      displaySuccessMessage("serviceUpdated");
      dispatch(getserviceList({ searchIds }));

    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      displayErrorMessage("serviceUpdatedError");
      
       dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
     displayErrorMessage(err.name);
     dispatch({ type: USER_ACTION_TYPE.SET_SERVICE_LIST_ERROR });
  }
 }
};


