import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import {
  deleteCargoById,
  getCargoList,
  addCargoDetails,
  editCargoDetails,
} from "../../../Services/Store/Common/cargo/cargo.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { CargoForm } from "./CargoForm";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { cargoSelector } from "../../../Services/Store/Common/cargo/cargo.selector";

const Cargo = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [cargoList, setCargoList] = useState([]);
  const [cargoCount, setCargoCount] = useState(0);
  const [cargoId, setCargoId] = useState(0);
  const [errorResponce, setErrorResponce] = useState(0);
  const [showModel, setShowModel] = useState(false);

  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);

  const perPage = 25;
  const [modalData, setModalData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cargoData = useSelector(cargoSelector);
  const [searchType, setSearchType] = useState("");

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));

    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('clientToken');
      navigate("/");
    }
  }, [errorResponce]);

  useEffect(() => {
    dispatch(getCargoList({}));
  }, []);

  useEffect(() => {
    if (errorResponce === 0) {
      setShowModel(false);
      setAddModel(false);
      setEditModel(false);
      setDeleteModel(false);
    }
    setCargoList(cargoData.records);
    setCargoCount(cargoData.totalCount);
    setErrorResponce(cargoData.error_code);
  }, [cargoData]);

  useEffect(() => {
    if (searchType?.length) {
      dispatch(getCargoList({ searchType: searchType, search: searchKeyword }));
    }
  }, [searchKeyword, searchType]);

  /** For pagination code start here **/
  const handlePageClick = (selectedPage) => {
    dispatch(
      getCargoList({ searchType: searchType, search: searchKeyword, offset: selectedPage })
    );
  };
  /** For pagination code end here **/

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (cargoDetails) => {
    setModalData(cargoDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (cargoId) => {
    setCargoId(cargoId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleAddClick = () => {
    setShowModel(true);
    setAddModel(true);
  };

  const callPageRecords = (cargoList) => {

    if (cargoList.length) {
      return cargoList.map((data, index) => (
        <tr key={data.id}>
          <td>{index + 1}</td>
          <td>{data.bl_type}</td>
          <td>{data.cargo}</td>
          <td>{data.quotation}</td>
          <td>{data.salesman}</td>
          <td>{data.bl_number}</td>
          <td>{data.shipper_name}</td>
          <td>{data.issue_type}</td>
          <td>{data.consignee_name}</td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-primary btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Delete"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr key="0" className="text-center">
          <td colSpan={10}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteCargo = async (cargoId) => {
    dispatch(deleteCargoById(cargoId));
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submitCargoDetails = async (params, cargoId) => {
    if (cargoId) {
      dispatch(editCargoDetails(params, cargoId));
    } else {
      dispatch(addCargoDetails(params));
    }
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <>
      <AdminLayout pageHeading="Cargo" pageIcon="fa fa-ship">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Cargo Listing</h1>
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="table-responsive">
                <div className="row mb-2">
                  <div className="col-sm-12 col-md-6">
                    <button type="button" className="mb-2 mb-md-0 btn btn-primary" onClick={() => _handleAddClick()}>
                      Add <i className="fa fa-plus fa-sm"></i>
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="fillter-wrap d-flex justify-content-end">
                      <select name="selectType" className="form-control me-1" onChange={(event) => handleSearhChange(event)}>
                        <option value="">Select Type</option>
                        <option value="bl_type">Bl Type</option>
                        <option value="cargo">Cargo</option>
                        <option value="bl_number">Bl Number</option>
                        <option value="salesman">Salesman</option>
                        <option value="shipper_name">Shipper Name</option>
                      </select>
                      <input type="search" className="form-control" placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>S.No</th>
                          <th>Bl Type</th>
                          <th>Cargo</th>
                          <th>Quotation</th>
                          <th>Salesman</th>
                          <th>Bl Number</th>
                          <th>Shipper Name</th>
                          <th>Issue Type</th>
                          <th>Consignee Name</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          callPageRecords(
                            cargoList
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {
                      <PaginationBlock
                        perPage={perPage}
                        userRecordsData={cargoCount}
                        callHandlePageClick={(data) =>
                          handlePageClick(data)
                        }
                      />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalBox
            size={deleteModel === true ? 'md' : 'xl'}
            show={showModel}
            title={deleteModel === true ? "Delete Cargo" : "Cargo Form"}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddModel(false);
            }}
          >
            {editModel === true ? (
              <CargoForm
                submitDetails={(params, cargoId) => submitCargoDetails(params, cargoId)}
                cargoDetails={modalData}

              />
            ) : addModel === true ? (
              <CargoForm
                submitDetails={(params) => submitCargoDetails(params, 0)}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={cargoId}
                deleteUserId={(cargoId) => deleteCargo(cargoId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) :
              (
                ""
              )}
          </ModalBox>
        </div>

      </AdminLayout>
    </>
  );
};
export default Cargo;
