import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";

export function BookingConfirmationForm(props) {

    const optionsCarrier = [];
    props?.shippingLines.map((data, index) => (
        optionsCarrier.push({ value: data.name, label: data.name })
    ));

    const validateContactFrom = Yup.object().shape({
        shipping_line: Yup.string().required('Please select Shipping Line'),
        pdf_file: Yup.string().required('File Required')
    });
    let initialValues = {};

    initialValues = {
        pdf_file: "",
        shipping_line: ""
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append("pdf_file", values.pdf_file);
            formData.append("shipping_line", values.shipping_line);
            props.submitForm(formData);
        },
    });

    return (
        <>
            <div className="modal-body">
                <form onSubmit={formik.handleSubmit} className='invitation-wrap' encType="multipart/form-data">
                    <div className="row">
                        {/* <div className='col-12 mb-3'>
                            <p className='m-0'>Only PDF Image allowed. To convert use below link:</p>
                            <a href='https://online2pdf.com/en/pdf-change-color-to-black-and-white' target='_blank'>PDF TO IMAGE</a>
                        </div> */}
                        <div className="col-12">
                            <label>Upload File</label>
                            <input
                                type='file'
                                className="form-control"
                                name="pdf_file"
                                id="pdf_file"
                                multiple
                                accept='.pdf'
                                onChange={(event) => {
                                    if (event.currentTarget.files) {
                                        formik.setFieldValue(
                                            "pdf_file",
                                            event.currentTarget.files[0]
                                        );
                                    }
                                }
                                }

                            />
                            {formik.touched.pdf_file && formik.errors.pdf_file && (
                                <div className="errorMsg text-danger">{formik.errors.pdf_file}</div>
                            )}
                        </div>
                        <div className="col-12">
                            <label>Shipping Line</label>
                            <Select
                                name="shipping_line"
                                options={optionsCarrier}
                                value={optionsCarrier.find(function (option) {
                                    return option.value === formik.values.shipping_line;
                                })}
                                onChange={(selectedOption) =>
                                    formik.setFieldValue(
                                        'shipping_line',
                                        selectedOption.value,
                                    )
                                }
                                label="Single select"
                            />
                            {formik.touched.shipping_line && formik.errors.shipping_line && (
                                <div className="errorMsg text-danger">{formik.errors.shipping_line}</div>
                            )}
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className="col-12">
                            <input type="submit" name="submit" className="btn btn-primary" value="Upload" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}