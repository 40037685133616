import { USER_ACTION_TYPE } from "../../../Constant/user.constants";

const INITIAL_STATE = {
  showJobsList: [],
  showJobsData: [],
  blocking:false,
};
export const jobsListReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPE.SET_JOBS_LIST_PENDING:
      return {
        blocking: true,
        showJobsList: [],
      };

    case USER_ACTION_TYPE.SET_JOBS_LIST_SUCESS:
      return {
        blocking: false,
        showJobsList: payload,
      };

    case USER_ACTION_TYPE.SET_JOBS_LIST_ERROR:
      return {
        blocking: true,
        showJobsList: [],
      };

    case USER_ACTION_TYPE.RESET_JOBS_LIST:
      return {
        blocking: true,
        showJobsList: [],
      };

      case USER_ACTION_TYPE.SET_JOBS_DATA_PENDING:
        return {
          blocking: true,
          showJobsData: [],
        };
  
      case USER_ACTION_TYPE.SET_JOBS_DATA_SUCESS:
        return {
          blocking: false,
          showJobsData: payload,
        };
  
      case USER_ACTION_TYPE.SET_JOBS_DATA_ERROR:
        return {
          blocking: true,
          showJobsData: [],
        };
  
      case USER_ACTION_TYPE.RESET_JOBS_DATA:
        return {
          blocking: true,
          showJobsData: [],
        };

    default:
      return state;
  }
};
