import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { ModalBox } from "../../../Components/Ui/ModalBox";
import {
  getExportDocumentCoordinatorList,
  // deleteExportDocumentCoordinatorById,
  // addExportDocumentCoordinatorDetails,
  // editExportDocumentCoordinatorDetails,
} from "../../../Services/Store/Common/exportDocumentCoordinator/exportdocumentcoordinator.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
// import { ExportDocumentCoordinatorForm } from "./ExportDocumentCoordinatorForm";
// import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { exportdocumentcoordinatorList } from "../../../Services/Store/Common/exportDocumentCoordinator/exportdocumentcoordinator.selector";

const Contact = () => {
  // const id = useParams()?.id;
  const [searchKeyword, setSearchKeyword] = useState("");
  // const [userData, setUserData] = useState([]);
  // const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  // const [showModel, setShowModel] = useState(false);
  // const [editModel, setEditModel] = useState(false);
  // const [deleteModel, setDeleteModel] = useState(false);
  // const [addModel, setAddModel] = useState(false);
  const perPage = 25;
  // const [modalData, setModalData] = useState({});
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carrierListResult = useSelector(exportdocumentcoordinatorList);
  const [searchType, setSearchType] = useState("");

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));

    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }
  }, [errorResponce]);

  // useEffect(() => {
  //   if (id > 0) {
  //     setSearchType('id');
  //     setSearchKeyword(id);
  //   }
  // }, []);

  useEffect(() => {
    if (carrierListResult.result !== undefined) {
      const result = carrierListResult.result;
      if (result.length > 0) {
        setUserRecordsData(carrierListResult.records);
        setErrorResponce(carrierListResult.error_code);
      } else {
        setUserRecordsData(0);
      }
    }
  }, [carrierListResult]);

  useEffect(() => {
    dispatch(getExportDocumentCoordinatorList({ searchType: searchType, search: searchKeyword }));
  }, [searchKeyword]);

  const handlePageClick = (selectedPage) => {
    dispatch(getExportDocumentCoordinatorList({ searchType: searchType, search: searchKeyword, offset: selectedPage }));
  };

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  // const _handleEditClick = (userDetails) => {
  //   setModalData(userDetails);
  //   setShowModel(true);
  //   setEditModel(true);
  // };

  // const _handleDeleteClick = (userId) => {
  //   setUserId(userId);
  //   setShowModel(true);
  //   setDeleteModel(true);
  // };

  // const _handleAddClick = () => {
  //     setShowModel(true);
  //     setAddModel(true);
  // };

  const callPageRecords = (userData) => {
    if (userData) {
      return userData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.username}</td>
          <td>{data.firstname}</td>
          <td> {data.lastname}</td>
          <td> {data.email}</td>
          <td> {data.mobile_number}</td>
          <td> {data.status}</td>
          {/* <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit contact"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Delete contact"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>
          </td> */}
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={6}>No Record Found</td>
        </tr>
      );
    }
  };

  // const deleteUser = async (userId2) => {
  //   dispatch(deleteExportDocumentCoordinatorById(userId2));
  //   setShowModel(false);
  //   setDeleteModel(false);
  //   const userId = JSON.parse(localStorage.getItem("userId"));
  //   if (userId) {
  //     setUserId(userId);
  //   }
  // };

  // const closeDeletePopUp = async () => {
  //   setShowModel(false);
  //   setDeleteModel(false);
  // };

  // const submitUserDetails = async (params) => {
  //   dispatch(addExportDocumentCoordinatorDetails(params));
  //   setShowModel(false);
  //   setAddModel(false);
  //   const userId = JSON.parse(localStorage.getItem("userId"));
  //   if (userId) {
  //     setUserId(userId);
  //   }
  // };

  // const submitEditUserDetails = async (params) => {
  //   dispatch(editExportDocumentCoordinatorDetails(params));
  //   setShowModel(false);
  //   setAddModel(false);
  // };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  return (
    <>
      <AdminLayout pageHeading="Export Doc. Coordinator" pageIcon="fa fa-file">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Export Document Coordinator List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                  {/* <button
                    type="button"
                    className="mb-2 mb-md-0 btn btn-primary"
                    onClick={() => _handleAddClick()}
                  >
                    Add&nbsp;
                    <i className="fa fa-plus fa-sm"></i>
                  </button> */}
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="fillter-wrap d-flex justify-content-end">
                    <select name="selectType" id="" className="form-control me-1" onChange={(event) => handleSearhChange(event)}>
                      <option value="">Select Type</option>
                      <option value="firstname">First Name </option>
                      <option value="lastname">Last Name</option>
                      <option value="username">Username</option>
                      <option value="mobile_number">Mobile Number</option>
                      <option value="status">Status</option>
                    </select>
                    <input type="search" className="form-control" placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Username</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {carrierListResult.result !== undefined &&
                          carrierListResult.result !== "" ? (
                          callPageRecords(
                            carrierListResult.result
                          )
                        ) : ("")}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {
                    <PaginationBlock
                      perPage={perPage}
                      userRecordsData={userRecordsData}
                      callHandlePageClick={(data) =>
                        handlePageClick(data)
                      }
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          {/* <ModalBox
            title={deleteModel ? 'Delete Export Doc. Coordinator' : 'Export Doc. Coordinator Form'}
            show={showModel}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddModel(false);
            }}
          >
            {editModel === true ? (
              <ExportDocumentCoordinatorForm
                editUserDetails={(params) => submitEditUserDetails(params)}
                userDetails={modalData}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={userId}
                deleteUserId={(userId) => deleteUser(userId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : addModel === true ? (
              <ExportDocumentCoordinatorForm
                userId={userId}
                addUserDetails={(params) => submitUserDetails(params)}
              />
            ) : (
              ""
            )}
          </ModalBox> */}
        </div>
      </AdminLayout>
    </>
  );
};
export default Contact;
