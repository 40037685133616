import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import {
  getratesList,
  deleteRatesById,
  addRatesDetails,
  editRatesDetails,
} from "../../../Services/Store/Common/rates/rates.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { RatesForm } from "./RatesForm";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { ratesList } from "../../../Services/Store/Common/rates/rates.selector";
import moment from "moment";
const Contact = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ratesListResult = useSelector(ratesList);
  const [searchType, setSearchType] = useState("USD_rate");

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }

  }, []);

  useEffect(() => {
    if (ratesListResult.result !== undefined) {
      const result = ratesListResult.result;
      if (result.length > 0) {
        setUserData(ratesListResult.result);
        setUserRecordsData(ratesListResult.records);
        setErrorResponce(ratesListResult.error_code);
      } else {
        setUserData("");
        setUserRecordsData("");
      }
    }
  }, [ratesListResult]);



  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(getratesList({ searchType: searchType, search: searchKeyword, offset }));
    }, 500);
    return () => clearTimeout(getData);
  }, [searchKeyword]);


  /** For pagination code start here **/
  var offset = currentPage * perPage;
  const handlePageClick = (selectedPage) => {
    dispatch(
      getratesList({ searchType: searchType, search: searchKeyword, offset: selectedPage })
    );
  };
  /** For pagination code end here **/

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (userDetails) => {
    setModalData(userDetails);
    setShowModel(true);
    setEditModel(true);
    setDeleteModel(false);
    setAddModel(false);
  };

  const _handleDeleteClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setDeleteModel(true);
    setEditModel(false);
    setAddModel(false);
  };



  const _handleAddClick = () => {
    setShowModel(true);
    setAddModel(true);
  };

  const callPageRecords = (userData) => {
    if (userData) {
      return userData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.cad_to_usd_rate}</td>
          <td>{data.usd_to_cad_rate}</td>
          <td> {data.rate_date ? moment(data.rate_date).format("YYYY-MM-DD") : "Nil"}</td>
          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit contact"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Delete contact"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>

          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={8}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteUser = async (userId2) => {
    dispatch(deleteRatesById(userId2));
    setShowModel(false);
    setDeleteModel(false);

  };




  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };



  const submitUserDetails = async (params) => {
    dispatch(addRatesDetails(params));
    setShowModel(false);
    setAddModel(false);

  };



  const submitEditUserDetails = async (params) => {
    dispatch(editRatesDetails(params));
    setShowModel(false);
    setAddModel(false);
  };



  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };
  return (
    <>
      <AdminLayout pageHeading="Rates" pageIcon="fa fa-percent">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Exchange Rate</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-6">
                    <button
                      type="button"
                      className="mb-2 mb-md-0 btn btn-primary"
                      onClick={() => _handleAddClick()}
                    >
                      Add&nbsp;
                      <i className="fa fa-plus fa-sm"></i>
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="fillter-wrap d-flex justify-content-end">
                      <select name="selectType" id="" className="form-control me-1" onChange={(event) => handleSearhChange(event)}>
                        <option value="">Select Type</option>
                        <option value="cad_to_usd_rate">CAD to USD Rate</option>
                        <option value="usd_to_cad_rate">USD to CAD Rate</option>
                        <option value="rate_date">Date</option>
                      </select>
                      <input type="search" className="form-control" placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                    </div>
                  </div>
                </div>
                </div>
                <div className="card-body">
                <div className="row">
                  <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th> CAD to USD Rate</th>
                          <th> USD to CAD Rate</th>
                          <th>Date</th>
                          <th className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ratesListResult.result !==
                          undefined &&
                          ratesListResult.result !== "" ? (
                          callPageRecords(
                            ratesListResult.result
                          )
                        ) : (
                          <tr>
                            <td colSpan={8}>No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    {
                      <PaginationBlock
                        perPage={perPage}
                        userRecordsData={userRecordsData}
                        callHandlePageClick={(data) =>
                          handlePageClick(data)
                        }
                      />
                    }
                  </div>
                </div>
               
            </div>
          </div>
          <ModalBox
            show={showModel}
            title={deleteModel ? 'Delete Exchange Rate' : 'Exchange Rate Form'}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddModel(false);
            }}
          >
            {editModel === true ? (
              <RatesForm
                editUserDetails={(params) => submitEditUserDetails(params)}
                userDetails={modalData}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={userId}
                deleteUserId={(userId) => deleteUser(userId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : addModel === true ? (
              <RatesForm
                addUserDetails={(params) => submitUserDetails(params)}
              />
            ) : (
              ""
            )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default Contact;
