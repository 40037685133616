import axios from "axios";
import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import {  displayErrorMessage, displaySuccessMessage,requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";


export const deleteMeetingById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteMeetings",{id},
                                   { headers:requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      const showMeetingList = {};
      const resultOutput = getState()
        .meetingList.showMeetingList.result.map((data) => data)
        .filter((data) => data.id !== id);
      showMeetingList.result = resultOutput;
      showMeetingList.records = getState().meetingList.showMeetingList.records - 1;
      showMeetingList.errorCode = 0;
      displaySuccessMessage("meetingDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_MEETING_LIST_SUCESS,
        payload: showMeetingList,
      });
    } else {
      displayErrorMessage("meetingDeleteError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_MEETING_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_MEETING_LIST_ERROR });
  }
};

export const getmeetingList= (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_MEETING_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslMeetingsList", {
                                    params: postData,
                                    headers:requestTokenHeader()
                                  });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_MEETING_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_MEETING_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    return dispatch({ type: USER_ACTION_TYPE.SET_MEETING_LIST_ERROR });
  }
};



export const addMeetingDetails = (postData) => async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslAddMeetings",postData,
      { headers:requestTokenHeader() });
    if (response.data.errorCode === 0) {
      var resultOutput={};
      const userId = localStorage.getItem('userId');
      resultOutput.userId=userId;
      resultOutput.search='';
      resultOutput.offset=0;
      resultOutput.customerId=response.data.result.customer_id;
      dispatch(getmeetingList());
      displaySuccessMessage("meetingAdded");
    } else {
      displayErrorMessage("meetingAddedError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_MEETING_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_MEETING_LIST_ERROR });
  }
};


export const editMeetingDetails = (postData) => 
 {
 return async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslEditMeetings",postData,
    {
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      var resultOutput={};
      const userId = localStorage.getItem('userId');
      resultOutput.userId=userId;
      resultOutput.search='';
      resultOutput.offset=0;
      resultOutput.customerId=response.data.result.customer_id;
      displaySuccessMessage("meetingUpdated");
      dispatch(getmeetingList(resultOutput));

    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      displayErrorMessage("meetingUpdatedError");
      
       dispatch({
        type: USER_ACTION_TYPE.SET_MEETING_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
     displayErrorMessage(err.name);
     dispatch({ type: USER_ACTION_TYPE.SET_MEETING_LIST_ERROR });
  }
 }
};


