import { combineReducers } from 'redux';
import { userListByIdReducer,paymentDetailsReducer } from './Common/account/account.reducer';
import { userReducer} from './Customer/Login/user.reducer';
import { ClientReducer } from './ClientLogin/client.reducer';

import { truckernameListReducer } from './Common/truckername/truckername.reducer';
import { serviceListReducer } from './Common/service/service.reducer';
import { carrierListReducer } from './Common/carrier/carrier.reducer';
import { ratesListReducer } from './Common/rates/rates.reducer';
import { containerListReducer } from './Common/containerlisting/containerlisting.reducer';

import { jobsListReducer } from './Common/jobs/jobs.reducer';
import { cargoReducer } from './Common/cargo/cargo.reducer';
import { bookingReducer } from './Common/bookings/booking.reducer';
import { notificationReducer } from './Common/notification/notification.reducer';
import { shippingLineRatesReducer } from './Common/shippingLineRates/shippingLineRates.reducer';
import { customerRateHistoryReducer } from './Common/customerRateHistory/customerRateHistory.reducer';

import { exportdocumentcoordinatorListReducer } from './Common/exportDocumentCoordinator/exportdocumentcoordinator.reducer';
import { importdocumentcoordinatorListReducer } from './Common/importDocumentCoordinator/importdocumentcoordinator.reducer';
import { quotesListReducer } from './Common/quotes/quotes.reducer';
import { taskListReducer } from './Common/tasks/task.reducer';
import { meetingListReducer } from './Common/meetings/meetings.reducer';
import { originListReducer } from './Common/origin/origin.reducer';
import { destinationListReducer } from './Common/destination/destination.reducer';

import { customerReportingListReducer } from './Common/customerReporting/customerReporting.reducer';
import { truckerReportingListReducer } from './Common/truckerReporting/truckerReporting.reducer';
import { paymentReportingListReducer } from './Common/paymentReporting/paymentReporting.reducer';
import { shippinglineReportingListReducer } from './Common/shippinglineReporting/shippinglineReporting.reducer';
import { reportingreleasedReportingListReducer } from './Common/reportingreleasedReporting/reportingreleasedReporting.reducer';
import { releaseddatesReportingListReducer } from './Common/releaseddatesReporting/releaseddatesReporting.reducer';
import { printBalanceSheetReportingListReducer } from './Common/printBalanceSheetReporting/printBalanceSheetReporting.reducer';
import { allSaleReportingListReducer } from './Common/allSaleReporting/allSaleReporting.reducer';
import { receivedAmountListReducer } from './Common/receivedAmountReport/receivedAmountReport.reducer';
import { mainReportingListReducer } from './Common/mainReporting/mainReporting.reducer';
import { customerBalanceReportingListReducer } from './Common/customerBalanceReporting/customerBalanceReporting.reducer';
import { closedJobsReportingListReducer } from './Common/closedJobsReporting/closedJobsReporting.reducer';
import { truckerBalanceReportingListReducer } from './Common/truckerBalanceReporting/truckerBalanceReporting.reducer';
import { statusReportingListReducer } from './Common/statusReporting/statusReporting.reducer';
import { steamshipLineBalanceReportingListReducer } from './Common/steamshipLineBalance/steamshipLineBalance.reducer';
import { containerReportingListReducer } from './Common/containerReport/containerReport.reducer';
import { thirdpartyChargesReportingListReducer } from './Common/thirdpartyCharges/thirdpartyCharges.reducer';
import { reportingListReducer } from './Common/reporting/reporting.reducer';
import { accountReportingListReducer } from './Common/accountReporting/accountReporting.reducer';
import { employeeReportingListReducer } from './Common/employeeReport/employeeReport.reducer';
import { customerVolumeReportingListReducer } from './Common/customerVolume/customerVolume.reducer';
import { historyForSalesReportingListReducer } from './Common/historyForSales/historyForSales.reducer';
import { quotationPerMonthReportingListReducer } from './Common/quotationPerMonth/quotationPerMonth.reducer';
import {excelReportingListReducer}  from './Common/excelReport/excelReport.reducer';

export const rootReducer=combineReducers({
    user: userReducer,
    userClient:ClientReducer,
    userListById:userListByIdReducer,
    paymentDetails:paymentDetailsReducer,
    truckernameList: truckernameListReducer,
    serviceList: serviceListReducer,
    carrierList: carrierListReducer, 
    ratesList: ratesListReducer, 
    exportdocumentcoordinatorList: exportdocumentcoordinatorListReducer,
    importdocumentcoordinatorList: importdocumentcoordinatorListReducer,
    containerList: containerListReducer, 
    jobsList:jobsListReducer,
    cargos:cargoReducer,
    bookings:bookingReducer,
    notifications:notificationReducer,
    shippingLineRates:shippingLineRatesReducer,
    customerRateHistory:customerRateHistoryReducer,
    quotesList: quotesListReducer,
    taskList:taskListReducer,
    meetingList: meetingListReducer, 
    originList: originListReducer,
    destinationList: destinationListReducer,
    customerReportingList: customerReportingListReducer,
    truckerReportingList: truckerReportingListReducer,
    paymentReportingList: paymentReportingListReducer,
    shippinglineReportingList: shippinglineReportingListReducer,
    reportingreleasedReportingList: reportingreleasedReportingListReducer,
    releaseddatesReportingList: releaseddatesReportingListReducer, 
    printBalanceSheetReportingList: printBalanceSheetReportingListReducer,
    allSaleReportingList: allSaleReportingListReducer,
    receivedAmountReportingList: receivedAmountListReducer,
    mainReportingList: mainReportingListReducer,
    customerBalanceReportingList:customerBalanceReportingListReducer, 
    closedJobsReportingList: closedJobsReportingListReducer, 
    truckerBalanceReportingList: truckerBalanceReportingListReducer, 
    statusReportingList: statusReportingListReducer, 
    steamshipLineBalanceReportingList: steamshipLineBalanceReportingListReducer,
    containerReportingList:containerReportingListReducer,
    thirdpartyChargesReportingList: thirdpartyChargesReportingListReducer, 
    reportingList: reportingListReducer,
    accountReportingList:accountReportingListReducer, 
    employeeReportingList: employeeReportingListReducer,
    customerVolumeReportingList: customerVolumeReportingListReducer,
    historyForSalesReportingList: historyForSalesReportingListReducer, 
    quotationPerMonthReportingList: quotationPerMonthReportingListReducer,
    excelReportingList: excelReportingListReducer
});