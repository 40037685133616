import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import {
  getserviceList,
  deleteServiceById,
  addServiceDetails,
  editServiceDetails,
} from "../../../Services/Store/Common/service/service.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { ServiceForm } from "./ServiceForm";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { serviceList } from "../../../Services/Store/Common/service/service.selector";

const Contact = () => {
  const id = useParams()?.id;
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [modalData, setModalData] = useState({});
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contactListResult = useSelector(serviceList);
  const [searchType, setSearchType] = useState(null);
  const perPage = 25;
  const [searchIds, setSearchIds] = useState(null);

  useEffect(() => {
    if (id) {
      if (id === 'search') {
        let search = localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : '';
        if (search?.resource === 'service' && search.ids) {
          setSearchIds(search.ids);
        }
      } else {
        setSearchType('id');
        setSearchKeyword(id);
      }
    } else {
      localStorage.removeItem('search');
      setSearchIds('');
    }
  }, [id]);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));

    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('clientToken');
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponce]);

  useEffect(() => {
    if (contactListResult.result !== undefined) {
      const result = contactListResult.result;
      if (result.length > 0) {
        setUserRecordsData(contactListResult.records);
        setErrorResponce(contactListResult.error_code);
      } else {
        setUserRecordsData("");
      }
    }
  }, [contactListResult]);

  useEffect(() => {
    if(searchKeyword !== null && searchType !== null)
      dispatch(getserviceList({ searchIds, searchType: searchType, search: searchKeyword }));
  }, [searchKeyword, searchType]);

  useEffect(() => {
    if(searchIds !== null) {
      setSearchKeyword(null);
      setSearchType(null);
      dispatch(getserviceList({ searchIds }));
    }
  }, [searchIds]);

  const handlePageClick = (selectedPage) => {
    dispatch(getserviceList({ searchIds, searchType: searchType, search: searchKeyword, offset: selectedPage }));
  };

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };

  const _handleEditClick = (userDetails) => {
    setModalData(userDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (userId) => {
    setUserId(userId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const _handleAddClick = () => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
      setShowModel(true);
      setAddModel(true);
    }
  };

  const callPageRecords = (userData) => {
    if (userData) {
      return userData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.name}</td>

          <td align="center">
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit contact"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Delete contact"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>

          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={8}>No Record Found</td>
        </tr>
      );
    }
  };

  const deleteUser = async (userId2) => {
    dispatch(deleteServiceById(userId2));
    setShowModel(false);
    setDeleteModel(false);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
    }
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submitUserDetails = async (params) => {
    dispatch(addServiceDetails(params));
    setShowModel(false);
    setAddModel(false);
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (userId) {
      setUserId(userId);
    }
  };

  const submitEditUserDetails = async (params) => {
    dispatch(editServiceDetails(params, searchIds));
    setShowModel(false);
    setAddModel(false);
  };

  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };
  return (
    <>
      <AdminLayout pageHeading="Service" pageIcon="fa fa-wrench">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Service List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">
              
                <div className="row align-items-center">
                  <div className="col-sm-12 col-md-6">
                    <button
                      type="button"
                      className="mb-2 mb-md-0 btn btn-primary"
                      onClick={() => _handleAddClick()}
                    >
                      Add&nbsp;
                      <i className="fa fa-plus fa-sm"></i>
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="fillter-wrap d-flex justify-content-end">
                      <select name="selectType" id="" className="form-control me-1" value={searchType ?? ''} onChange={(event) => handleSearhChange(event)}>
                        <option value="">Select Type</option>
                        <option value="name">Service Name</option>
                      </select>
                      <input type="search" className="form-control" value={searchKeyword ?? ''} placeholder="Search" onChange={handleChange} aria-controls="dataTable" />
                    </div>
                  </div>
                </div>
                </div>
                <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>Service Name</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactListResult.result !==
                          undefined &&
                          contactListResult.result !== "" ? (
                          callPageRecords(
                            contactListResult.result
                          )
                        ) : (
                          <tr>
                            <td colSpan={8}>No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    {
                      <PaginationBlock
                        perPage={perPage}
                        userRecordsData={userRecordsData}
                        callHandlePageClick={(data) =>
                          handlePageClick(data)
                        }
                      />
                    }
                  </div>
                </div>
            </div>
          </div>
          <ModalBox
            title={deleteModel ? 'Delete Service' : 'Service Form'}
            show={showModel}
            onHide={() => {
              setShowModel(false);
              setEditModel(false);
              setDeleteModel(false);
              setAddModel(false);
            }}
          >
            {editModel === true ? (
              <ServiceForm
                editUserDetails={(params) => submitEditUserDetails(params)}
                userDetails={modalData}
              />
            ) : deleteModel === true ? (
              <DeleteForm
                userId={userId}
                deleteUserId={(userId) => deleteUser(userId)}
                closeDeletePopUp={() => closeDeletePopUp()}
              />
            ) : addModel === true ? (
              <ServiceForm
                userId={userId}
                addUserDetails={(params) => submitUserDetails(params)}
              />
            ) : (
              ""
            )}
          </ModalBox>
        </div>
      </AdminLayout>
    </>
  );
};
export default Contact;
