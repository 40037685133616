import { USER_ACTION_TYPE } from "../../../Constant/user.constants";

const INITIAL_STATE = {
  records: [],
  totalCount: 0,
  error_code: 0,
  blocking: false,
};
export const bookingReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {

    case USER_ACTION_TYPE.SET_BOOKING_LIST_PENDING:
      return {
        ...state, error_code: 0, blocking: true,
      };

    case USER_ACTION_TYPE.SET_BOOKING_LIST_SUCCESS:
      return {
        blocking: false,
        records: payload.records,
        totalCount: payload.totalCount,
        error_code: payload.error_code
      };

    case USER_ACTION_TYPE.SET_BOOKING_LIST_ERROR:
      return {
        ...state, blocking: false, error_code: payload.error_code
      };

    default:
      return state;
  }
};
