import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { customerList } from "../../../Services/Store/Common/customer/customer.selector";
import AdminLayout from "../../../Layout";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import {
  getcustomerList,
  deleteUserById,
  addCustomerDetails,
  editCustomerDetails,
} from "../../../Services/Store/Common/customer/customer.action";
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import { CustomerBookings } from "./CustomerBookings";
import { CustomerForm } from "../../Common/Customer/CustomerForm";
import moment from "moment";
import api from "../../../Services/Axios/index";
import { requestTokenHeader } from "../../../Services/Helpers/helper";

const CustomerHistory = () => {
  const id = useParams()?.id;
  const perPage = 25;
  const [showModel, setShowModel] = useState(false);
  const [bookingModel, setBookingModel] = useState(false);
  const [addCustomerModel, setAddCustomerModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [modalData, setModalData] = useState({});
  const [customer, setCustomer] = useState({});
  const [customersList, setCustomerList] = useState([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [notBookedFrom, setNotBookedFrom] = useState('');
  const [errorResponce, setErrorResponce] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerData = useSelector(customerList);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const [jobsData, setJobsData] = useState({});
  const currentUser = useSelector((state) => state.userClient);
  const [searchIds, setSearchIds] = useState(null);

  useEffect(() => {
    if (id) {
      if (id === 'search') {
        let search = localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : '';
        if (search?.resource === 'customerHistory' && search.ids) {
          setSearchIds(search.ids);
        }
      } else {
        setSearchType('id');
        setSearchKeyword(id);
      }
    } else {
      localStorage.removeItem('search');
      setSearchIds('');
    }
  }, [id]);

  useEffect(() => {
    async function fetchMyAPI() {
      const response = await api.get("api/v1/tsljobData", {
        params: "",
        headers: requestTokenHeader()
      });
      if (response.data.errorCode === 0) {
        setJobsData(response.data.data);
      } else {
        setJobsData("");
      }
    }
    fetchMyAPI();
  }, []);

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("clientToken"));
    if (!loginCheck || errorResponce === 4) {
      sessionStorage.removeItem('clientToken');
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponce]);

  useEffect(() => {
    setCustomerList(customerData.result);
    setCustomerCount(customerData.records);
    setErrorResponce(customerData.error_code);
  }, [customerData]);

  useEffect(() => {
    if(searchIds !== null) {
      setSearchKeyword(null);
      setSearchType(null);
      dispatch(getcustomerList({ searchIds, notBookedFrom: notBookedFrom }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchIds]);

  const handlePageClick = (selectedPage) => {
    dispatch(getcustomerList({ searchIds, notBookedFrom: notBookedFrom, searchType: searchType, search: searchKeyword, offset: selectedPage }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const loadBookingPopup = (customer) => {
    setShowModel(true);
    setBookingModel(true);
    setCustomer(customer);
  }

  const filterNotBooked = (e) => {
    setSearchType('');
    setSearchKeyword('');
    if (e.target.value !== '') {
      let notBooked = 0;
      if (e.target.value > 0) {
        notBooked = moment().subtract(e.target.value, 'days').format("YYYY-MM-DD");
      }
      setNotBookedFrom(notBooked);
      dispatch(getcustomerList({ searchIds, notBookedFrom: notBooked }));
    } else {
      setNotBookedFrom('');
      dispatch(getcustomerList({ searchIds, notBookedFrom: '' }));
    }
  }

  useEffect(() => {
    if(searchKeyword !== null && searchType !== null)
      dispatch(getcustomerList({ searchIds, notBookedFrom: notBookedFrom, searchType: searchType, search: searchKeyword }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, searchType]);

  const handleAddCustomer = () => {
    setShowModel(true);
    setAddCustomerModel(true);
  };

  const createCustomer = async (params) => {
    dispatch(addCustomerDetails(params, searchIds));
    setShowModel(false);
    setAddCustomerModel(false);
  };

  const handleSearhType = (e) => {
    setSearchType(e.target.value);
  };

  const handleSearchText = (e) => {
    setSearchKeyword(e.target.value.trim());
  };

  const _handleEditClick = (customerDetails) => {
    setModalData(customerDetails);
    setShowModel(true);
    setEditModel(true);
  };

  const _handleDeleteClick = (customerId) => {
    setCustomerId(customerId);
    setShowModel(true);
    setDeleteModel(true);
  };

  const closeDeletePopUp = async () => {
    setShowModel(false);
    setDeleteModel(false);
  };

  const submitEditUserDetails = async (params) => {
    dispatch(editCustomerDetails(params, searchIds));
    setShowModel(false);
    setEditModel(false);
  };

  const deleteUser = async (customerId) => {
    dispatch(deleteUserById(customerId));
    setShowModel(false);
    setDeleteModel(false);
  };

  const callPageRecords = (customerData) => {
    if (customerData) {
      return customerData.map((data, index) => (
        <tr key={data.id}>
          <td>{data.firstname}</td>
          <td>{data.lastname}</td>
          <td>{data.email}</td>
          <td>{data.mobile_number}</td>
          <td>{data.company_name}</td>
          <td>{data.job_date}</td>
          <td align="center">
            {data.job_date ?
              <button
                type="button"
                className="bd-none btn btn-light btn-xsm"
                onClick={() => loadBookingPopup(data)}
                data-toggle="tooltip" data-placement="top" title="Booking History"
              >
                <i className="fa fa-fw fa-tasks"></i>
              </button> :
              ""
            }
          </td>
          <td>{data.status}</td>
          <td>
            <button
              type="button"
              className="bd-none btn btn-warning btn-xsm"
              onClick={() => _handleEditClick(data)}
              data-toggle="tooltip" data-placement="top" title="Edit User"
            >
              <i className="fa fa-fw fa-edit"></i>
            </button>
            <Link state={{ customerId: data.id }} className="bd-none btn btn-info btn-xsm" to="/contact" data-toggle="tooltip" data-placement="top" title="Contacts Listing">
              <i className="fa fa-fw fa-address-book"></i>
            </Link>
            <button
              type="button"
              className="bd-none btn btn-danger btn-xsm"
              onClick={() => _handleDeleteClick(data.id)}
              data-toggle="tooltip" data-placement="top" title="Delete User"
            >
              <i className="fa fa-fw fa-trash"></i>
            </button>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="text-center">
          <td colSpan={9}>No Record Found</td>
        </tr>
      );
    }
  };

  return (
    <>
      <AdminLayout pageHeading="Bookings" pageIcon="fa fa-tasks">
        <div className="container-fluid">
          <h1 className="h3 mb-4 text-gray-800">Customer List</h1>
          <div className="card shadow mb-4">
            <div className="card-header">

              <div className="row align-items-center">
                <div className="col-sm-12 col-md-6">
                  <button type="button" className="mb-2 mb-md-0 btn btn-primary" onClick={() => handleAddCustomer()}>
                    Add Customer <i className="fa fa-plus fa-sm"></i>
                  </button>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="fillter-wrap d-flex align-items-center">
                    <select className="form-control me-1" value={searchType ?? ''} onChange={(event) => handleSearhType(event)}>
                      <option value="">Search Type</option>
                      <option value="firstname">First Name </option>
                      <option value="lastname">Last Name</option>
                      <option value="email">Username</option>
                      <option value="status">Status</option>
                    </select>
                    <input type="text" className="form-control me-2" value={searchKeyword ?? ''} placeholder="Search" onChange={handleSearchText} />
                    <label className="mb-0">Not Booked</label>
                    <select name="selectType" className="form-control" onChange={(e) => filterNotBooked(e)}>
                      <option value="">Days</option>
                      <option value="30">Last 30 Days</option>
                      <option value="60">Last 60 Days</option>
                      <option value="0">New User</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered user-select-none">
                      <thead>
                        <tr className="bg-primary text-white">
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Mobile Number</th>
                          <th>Company Name</th>
                          <th>Last Booking Date</th>
                          <th className="text-center">Bookings</th>
                          <th className="text-center">Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {callPageRecords(customersList)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                    {
                      <PaginationBlock
                        perPage={perPage}
                        userRecordsData={customerCount}
                        callHandlePageClick={(data) =>
                          handlePageClick(data)
                        }
                      />
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
          <ModalBox
            show={showModel}
            size={deleteModel ? 'md' : 'xl'}
            title={bookingModel ? "Booking History" : (deleteModel ? 'Delete Customer' : 'Customer Form')}
            onHide={() => {
              setShowModel(false);
              setBookingModel(false);
              setAddCustomerModel(false);
              setEditModel(false);
              setDeleteModel(false);
            }}
          >
            {
              bookingModel ? (
                <div className="modal-body">
                  <div className="container login-wrap-new">
                    <div className="row">
                      <div className="col-md-12">
                        <CustomerBookings
                          customer={customer}
                          jobsData={jobsData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) :
                addCustomerModel ? (
                  <CustomerForm
                    userId={currentUser.user.id}
                    addUserDetails={(params) => createCustomer(params)}
                  />) : editModel === true ? (
                    <CustomerForm
                      editUserDetails={(params) => submitEditUserDetails(params)}
                      userDetails={modalData}
                    />
                  ) : deleteModel === true ? (
                    <DeleteForm
                      userId={customerId}
                      deleteUserId={(customerId) => deleteUser(customerId)}
                      closeDeletePopUp={() => closeDeletePopUp()}
                    />) : ""}
          </ModalBox>
        </div>
      </AdminLayout >
    </>
  );
}
export default CustomerHistory; 