import React from 'react';
import { Link } from 'react-router-dom';
import AdminLayout from "../../Layout";

const Unauthorized = () => {
  return (
    <>
      <AdminLayout pageHeading="Unauthorized" pageIcon="fa fa-lock">
        <div className="container-fluid">
          <div className="card shadow mb-4">
            <div className="card-body text-center pt-4">
              <h1>Unauthorized</h1>
              <p>Sorry, you do not have access to this page.</p>
              <p>
                <Link className="btn btn-primary" to="/dashboard">
                  <span> Go To Dashboard</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </AdminLayout >
    </>
  );
}
export default Unauthorized; 