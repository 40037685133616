import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from "moment";

export function CargoForm(props) {

    const fields = props.cargoDetails ? props.cargoDetails : {};

    const validateCargoForm = Yup.object().shape({
        vessal: Yup.string().required('Vessal is required'),
        bl_type: Yup.string().required('Bl Type is required'),
        // cargo: Yup.string().required('Cargo is required'),
        // quotation: Yup.string().required('Quotation is required'),
        // salesman: Yup.string().required('Salesman is required'),
        // bl_number: Yup.string().required('Bl Number is required'),
        // original_bls: Yup.string().required('Original Bls is required'),
        // frieght: Yup.string().required('Frieght is required'),
        // shipper_name: Yup.string().required('Shipper Name is required'),
        // shipper_address: Yup.string().required('Shipper Address is required'),
        // booked_principle: Yup.string().required('Booked Principle is required'),
        // issue_type: Yup.string().required('Issue Type is required'),
        // discharge_port: Yup.string().required('Discharge Port is required'),
        // delivery: Yup.string().required('Delivery is required'),
        // notify_name: Yup.string().required('Notify Name is required'),
        // notify_ed_address: Yup.string().required('Notify Ed Address is required'),
        // comodity: Yup.string().required('Comodity is required'),
        // gross_weight: Yup.string().required('Gross Weight is required'),
        // cbm: Yup.string().required('Cbm is required'),
        // weight: Yup.string().required('Weight is required'),
        // consignee_name: Yup.string().required('Consignee Name is required'),
        // consignee_ed_address: Yup.string().required('Consignee Ed Address is required'),
        // booking_customer: Yup.string().required('Booking Customer is required'),
        // consignee: Yup.string().required('Consignee is required'),
        // consignee_address: Yup.string().required('Consignee Address is required'),
        // port_charges_till: Yup.date().required('Port Charges Till is required'),
        // load_port: Yup.string().required('Load Port is required'),
        // receipt_port: Yup.string().required('Receipt Port is required'),
        // pro_carried: Yup.string().required('Pro Carried is required'),
    });

    let initialValues = {
        vessal: fields?.vessal ?? '',
        bl_type: fields?.bl_type ?? '',
        cargo: fields?.cargo ?? '',
        quotation: fields?.quotation ?? '',
        salesman: fields?.salesman ?? '',
        bl_number: fields?.bl_number ?? '',
        original_bls: fields?.original_bls ?? '',
        frieght: fields?.frieght ?? '',
        shipper_name: fields?.shipper_name ?? '',
        shipper_address: fields?.shipper_address ?? '',
        booked_principle: fields?.booked_principle ?? '',
        issue_type: fields?.issue_type ?? '',
        discharge_port: fields?.discharge_port ?? '',
        delivery: fields?.delivery ?? '',
        notify_name: fields?.notify_name ?? '',
        notify_ed_address: fields?.notify_ed_address ?? '',
        comodity: fields?.comodity ?? '',
        gross_weight: fields?.gross_weight ?? '',
        cbm: fields?.cbm ?? '',
        weight: fields?.weight ?? '',
        consignee_name: fields?.consignee_name ?? '',
        consignee_ed_address: fields?.consignee_ed_address ?? '',
        booking_customer: fields?.booking_customer ?? '',
        consignee: fields?.consignee ?? '',
        consignee_address: fields?.consignee_address ?? '',
        port_charges_till: fields?.port_charges_till ?? '',
        load_port: fields?.load_port ?? '',
        receipt_port: fields?.receipt_port ?? '',
        pro_carried: fields?.pro_carried ?? '',
        notify1: fields?.notify1 ?? '',
        notify1_address: fields?.notify1_address ?? '',
        general_cargo: fields?.general_cargo ?? '',
        description: fields?.description ?? '',
        marks: fields?.marks ?? '',
        maf_details: fields?.maf_details ?? '',
        charges_other: fields?.charges_other ?? '',
        part_bl: fields?.part_bl ?? '',
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateCargoForm,
        onSubmit: (values) => {
            values.port_charges_till = values.port_charges_till ? moment(values.port_charges_till).format("YYYY-MM-DD") : null;
         
            if (fields?.id) {
                props.submitDetails(values, fields.id);
            } else {
                props.submitDetails(values);
            }
        },
    });

    return (
        <>
            <div className="modal-body">
                <form onSubmit={formik.handleSubmit} className='invitation-wrap form-content'>
                    <div className="container">
                        <Tabs
                            defaultActiveKey="general"
                            className="mb-3"
                        >
                            <Tab eventKey="general" title="General">
                                <div className="row">
                                    <div className='col-md-6 mb-1 p-1'>
                                        <div className='border p-2'>
                                            <div className='row'>
                                                <div className="col-6">
                                                    <label>Vessal/VoyJob*</label>
                                                    <input className="form-control"
                                                        name="vessal"
                                                        id="vessal"
                                                        {...formik.getFieldProps('vessal')}
                                                    />
                                                    {formik.touched.vessal && formik.errors.vessal && (
                                                        <div className="errorMsg text-danger">{formik.errors.vessal}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Bl Type*</label>
                                                    <select className="form-control"
                                                        name="bl_type"
                                                        id="bl_type"
                                                        {...formik.getFieldProps('bl_type')}>
                                                        <option value="">--Please Select--</option>
                                                        <option value="bl_type1">Bl Type1</option>
                                                        <option value="bl_type2">Bl Type2</option>
                                                    </select>
                                                    {formik.touched.bl_type && formik.errors.bl_type && (
                                                        <div className="errorMsg text-danger">{formik.errors.bl_type}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Cargo*</label>
                                                    <select className="form-control"
                                                        name="cargo"
                                                        id="cargo"
                                                        {...formik.getFieldProps('cargo')}>
                                                        <option value="">--Please Select--</option>
                                                        <option value="cargo1">Cargo1</option>
                                                        <option value="cargo2">Cargo2</option>
                                                    </select>
                                                    {formik.touched.cargo && formik.errors.cargo && (
                                                        <div className="errorMsg text-danger">{formik.errors.cargo}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Quotation*</label>
                                                    <input className="form-control"
                                                        name="quotation"
                                                        id="quotation"
                                                        {...formik.getFieldProps('quotation')}
                                                    />
                                                    {formik.touched.quotation && formik.errors.quotation && (
                                                        <div className="errorMsg text-danger">{formik.errors.quotation}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Salesman*</label>
                                                    <input className="form-control"
                                                        name="salesman"
                                                        id="salesman"
                                                        {...formik.getFieldProps('salesman')}
                                                    />
                                                    {formik.touched.salesman && formik.errors.salesman && (
                                                        <div className="errorMsg text-danger">{formik.errors.salesman}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>BL Number*</label>
                                                    <input className="form-control"
                                                        name="bl_number"
                                                        id="bl_number"
                                                        {...formik.getFieldProps('bl_number')}
                                                    />
                                                    {formik.touched.bl_number && formik.errors.bl_number && (
                                                        <div className="errorMsg text-danger">{formik.errors.bl_number}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Original Bls #*</label>
                                                    <input className="form-control"
                                                        name="original_bls"
                                                        id="original_bls"
                                                        {...formik.getFieldProps('original_bls')}
                                                    />
                                                    {formik.touched.original_bls && formik.errors.original_bls && (
                                                        <div className="errorMsg text-danger">{formik.errors.original_bls}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Frieght*</label>
                                                    <select className="form-control"
                                                        name="frieght"
                                                        id="frieght"
                                                        {...formik.getFieldProps('frieght')}>
                                                        <option value="">--Please Select--</option>
                                                        <option value="frieght1">Frieght1</option>
                                                        <option value="frieght2">Frieght2</option>
                                                    </select>
                                                    {formik.touched.frieght && formik.errors.frieght && (
                                                        <div className="errorMsg text-danger">{formik.errors.frieght}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Shipper Name*</label>
                                                    <input className="form-control"
                                                        name="shipper_name"
                                                        id="shipper_name"
                                                        {...formik.getFieldProps('shipper_name')}
                                                    />
                                                    {formik.touched.shipper_name && formik.errors.shipper_name && (
                                                        <div className="errorMsg text-danger">{formik.errors.shipper_name}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Shipper Address*</label>
                                                    <input className="form-control"
                                                        name="shipper_address"
                                                        id="shipper_address"
                                                        {...formik.getFieldProps('shipper_address')}
                                                    />
                                                    {formik.touched.shipper_address && formik.errors.shipper_address && (
                                                        <div className="errorMsg text-danger">{formik.errors.shipper_address}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Booked By Principle*</label>
                                                    <select className="form-control"
                                                        name="booked_principle"
                                                        id="booked_principle"
                                                        {...formik.getFieldProps('booked_principle')}>
                                                        <option value="">--Please Select--</option>
                                                        <option value="frieght_all_1">Frieght All In One</option>
                                                        <option value="frieght_all_2">Frieght All In Two</option>
                                                    </select>
                                                    {formik.touched.booked_principle && formik.errors.booked_principle && (
                                                        <div className="errorMsg text-danger">{formik.errors.booked_principle}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Issue Type*</label>
                                                    <select className="form-control"
                                                        name="issue_type"
                                                        id="issue_type"
                                                        {...formik.getFieldProps('issue_type')}>
                                                        <option value="">--Please Select--</option>
                                                        <option value="issue_type_1">Issue Type 1</option>
                                                        <option value="issue_type_2">Issue Type 2</option>
                                                    </select>
                                                    {formik.touched.issue_type && formik.errors.issue_type && (
                                                        <div className="errorMsg text-danger">{formik.errors.issue_type}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Remarks</label>
                                                    <input className="form-control"
                                                        name="remarks"
                                                        id="remarks"
                                                        {...formik.getFieldProps('remarks')}
                                                    />
                                                    {formik.touched.remarks && formik.errors.remarks && (
                                                        <div className="errorMsg text-danger">{formik.errors.remarks}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-1 p-1'>
                                        <div className='border p-2'>
                                            <div className='row'>
                                                <div className="col-6">
                                                    <label>Consignee Name*</label>
                                                    <input className="form-control"
                                                        name="consignee_name"
                                                        id="consignee_name"
                                                        {...formik.getFieldProps('consignee_name')}
                                                    />
                                                    {formik.touched.consignee_name && formik.errors.consignee_name && (
                                                        <div className="errorMsg text-danger">{formik.errors.consignee_name}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Consignee Ed Address*</label>
                                                    <textarea className="form-control"
                                                        name="consignee_ed_address"
                                                        id="consignee_ed_address"
                                                        {...formik.getFieldProps('consignee_ed_address')}
                                                    />
                                                    {formik.touched.consignee_ed_address && formik.errors.consignee_ed_address && (
                                                        <div className="errorMsg text-danger">{formik.errors.consignee_ed_address}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Booking Customer*</label>
                                                    <input className="form-control"
                                                        name="booking_customer"
                                                        id="booking_customer"
                                                        {...formik.getFieldProps('booking_customer')}
                                                    />
                                                    {formik.touched.booking_customer && formik.errors.booking_customer && (
                                                        <div className="errorMsg text-danger">{formik.errors.booking_customer}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Consignee*</label>
                                                    <input className="form-control"
                                                        name="consignee"
                                                        id="consignee"
                                                        {...formik.getFieldProps('consignee')}
                                                    />
                                                    {formik.touched.consignee && formik.errors.consignee && (
                                                        <div className="errorMsg text-danger">{formik.errors.consignee}</div>
                                                    )}
                                                </div>
                                                <div className="col-12">
                                                    <label>Consignee Address Details*</label>
                                                    <textarea className="form-control"
                                                        name="consignee_address"
                                                        id="consignee_address"
                                                        rows="5"
                                                        {...formik.getFieldProps('consignee_address')}
                                                    />
                                                    {formik.touched.consignee_address && formik.errors.consignee_address && (
                                                        <div className="errorMsg text-danger">{formik.errors.consignee_address}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Port Charges Till*</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        id="port_charges_till"
                                                        name="port_charges_till"
                                                        {...formik.getFieldProps('port_charges_till')}
                                                    />
                                                    {formik.touched.port_charges_till && formik.errors.port_charges_till && (
                                                        <div className="errorMsg text-danger">{formik.errors.port_charges_till}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Enable Date</label>
                                                    <input
                                                        className="form-control"
                                                        type="checkbox"
                                                        id="enable_date"
                                                        name="enable_date"
                                                        {...formik.getFieldProps('enable_date')}
                                                    />
                                                    {formik.touched.enable_date && formik.errors.enable_date && (
                                                        <div className="errorMsg text-danger">{formik.errors.enable_date}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-1 p-1'>
                                        <div className='border p-2'>
                                            <div className='row'>
                                                <div className="col-6">
                                                    <label>Discharge Port*</label>
                                                    <input className="form-control"
                                                        name="discharge_port"
                                                        id="discharge_port"
                                                        {...formik.getFieldProps('discharge_port')}
                                                    />
                                                    {formik.touched.discharge_port && formik.errors.discharge_port && (
                                                        <div className="errorMsg text-danger">{formik.errors.discharge_port}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Delivery*</label>
                                                    <input className="form-control"
                                                        name="delivery"
                                                        id="delivery"
                                                        {...formik.getFieldProps('delivery')}
                                                    />
                                                    {formik.touched.delivery && formik.errors.delivery && (
                                                        <div className="errorMsg text-danger">{formik.errors.delivery}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Notify Name*</label>
                                                    <input className="form-control"
                                                        name="notify_name"
                                                        id="notify_name"
                                                        {...formik.getFieldProps('notify_name')}
                                                    />
                                                    {formik.touched.notify_name && formik.errors.notify_name && (
                                                        <div className="errorMsg text-danger">{formik.errors.notify_name}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Notify Ed Address*</label>
                                                    <textarea className="form-control"
                                                        name="notify_ed_address"
                                                        id="notify_ed_address"
                                                        {...formik.getFieldProps('notify_ed_address')}
                                                    />
                                                    {formik.touched.notify_ed_address && formik.errors.notify_ed_address && (
                                                        <div className="errorMsg text-danger">{formik.errors.notify_ed_address}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Comodity*</label>
                                                    <input className="form-control"
                                                        name="comodity"
                                                        id="comodity"
                                                        {...formik.getFieldProps('comodity')}
                                                    />
                                                    {formik.touched.comodity && formik.errors.comodity && (
                                                        <div className="errorMsg text-danger">{formik.errors.comodity}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Gross WT*</label>
                                                    <input className="form-control"
                                                        name="gross_weight"
                                                        id="gross_weight"
                                                        {...formik.getFieldProps('gross_weight')}
                                                    />
                                                    {formik.touched.gross_weight && formik.errors.gross_weight && (
                                                        <div className="errorMsg text-danger">{formik.errors.gross_weight}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>CBM*</label>
                                                    <input className="form-control"
                                                        name="cbm"
                                                        id="cbm"
                                                        {...formik.getFieldProps('cbm')}
                                                    />
                                                    {formik.touched.cbm && formik.errors.cbm && (
                                                        <div className="errorMsg text-danger">{formik.errors.cbm}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>WT (KG,MT)*</label>
                                                    <input className="form-control"
                                                        name="weight"
                                                        id="weight"
                                                        {...formik.getFieldProps('weight')}
                                                    />
                                                    {formik.touched.weight && formik.errors.weight && (
                                                        <div className="errorMsg text-danger">{formik.errors.weight}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-1 p-1'>
                                        <div className='border p-2'>
                                            <div className='row'>
                                                <div className="col-6">
                                                    <label>Load Port*</label>
                                                    <input className="form-control"
                                                        name="load_port"
                                                        id="load_port"
                                                        {...formik.getFieldProps('load_port')}
                                                    />
                                                    {formik.touched.load_port && formik.errors.load_port && (
                                                        <div className="errorMsg text-danger">{formik.errors.load_port}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Receipt Port*</label>
                                                    <input className="form-control"
                                                        name="receipt_port"
                                                        id="receipt_port"
                                                        {...formik.getFieldProps('receipt_port')}
                                                    />
                                                    {formik.touched.receipt_port && formik.errors.receipt_port && (
                                                        <div className="errorMsg text-danger">{formik.errors.receipt_port}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Pro Carried*</label>
                                                    <input className="form-control"
                                                        name="pro_carried"
                                                        id="pro_carried"
                                                        {...formik.getFieldProps('pro_carried')}
                                                    />
                                                    {formik.touched.pro_carried && formik.errors.pro_carried && (
                                                        <div className="errorMsg text-danger">{formik.errors.pro_carried}</div>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <label>Notify1</label>
                                                    <input className="form-control"
                                                        name="notify1"
                                                        id="notify1"
                                                        {...formik.getFieldProps('notify1')}
                                                    />
                                                    {formik.touched.notify1 && formik.errors.notify1 && (
                                                        <div className="errorMsg text-danger">{formik.errors.notify1}</div>
                                                    )}
                                                </div>
                                                <div className="col-12">
                                                    <label>Notify1 Address</label>
                                                    <textarea className="form-control"
                                                        name="notify1_address"
                                                        id="notify1_address"
                                                        rows="5"
                                                        {...formik.getFieldProps('notify1_address')}
                                                    />
                                                    {formik.touched.notify1_address && formik.errors.notify1_address && (
                                                        <div className="errorMsg text-danger">{formik.errors.notify1_address}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="cargo_details" title="General Cargo Details">
                                <div className="row">
                                    <div className='col-12'>
                                        <textarea className="form-control"
                                            name="general_cargo"
                                            id="general_cargo"
                                            rows="15"
                                            placeholder='General Cargo'
                                            {...formik.getFieldProps('general_cargo')}
                                        />
                                        {formik.touched.general_cargo && formik.errors.general_cargo && (
                                            <div className="errorMsg text-danger">{formik.errors.general_cargo}</div>
                                        )}
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="containers" title="Containers">
                                Tab content for Containers
                            </Tab>
                            <Tab eventKey="description_marks" title="Description And Marks">
                                <div className="row">
                                    <div className='col-md-5'>
                                        <textarea className="form-control"
                                            name="description"
                                            id="description"
                                            rows="15"
                                            placeholder='Description'
                                            {...formik.getFieldProps('description')}
                                        />
                                        {formik.touched.description && formik.errors.description && (
                                            <div className="errorMsg text-danger">{formik.errors.description}</div>
                                        )}
                                    </div>
                                    <div className='col-md-7'>
                                        <textarea className="form-control"
                                            name="marks"
                                            id="marks"
                                            rows="10"
                                            placeholder='Marks'
                                            {...formik.getFieldProps('marks')}
                                        />
                                        {formik.touched.marks && formik.errors.marks && (
                                            <div className="errorMsg text-danger">{formik.errors.marks}</div>
                                        )}
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="maf_details" title="Maf Details">
                                <div className="row">
                                    <div className='col-12'>
                                        <textarea className="form-control"
                                            name="maf_details"
                                            id="maf_details"
                                            rows="15"
                                            {...formik.getFieldProps('maf_details')}
                                        />
                                        {formik.touched.maf_details && formik.errors.maf_details && (
                                            <div className="errorMsg text-danger">{formik.errors.maf_details}</div>
                                        )}
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="charges" title="Charges">
                                Tab content for Charges
                            </Tab>
                            <Tab eventKey="charges_other" title="ChargesOther">
                                <div className="row">
                                    <div className='col-12'>
                                        <textarea className="form-control"
                                            name="charges_other"
                                            id="charges_other"
                                            placeholder='Charges'
                                            rows="15"
                                            {...formik.getFieldProps('charges_other')}
                                        />
                                        {formik.touched.charges_other && formik.errors.charges_other && (
                                            <div className="errorMsg text-danger">{formik.errors.charges_other}</div>
                                        )}
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="part_bl" title="PartBL">
                                <div className="row">
                                    <div className='col-12'>
                                        <textarea className="form-control"
                                            name="part_bl"
                                            id="part_bl"
                                            placeholder='PartBL'
                                            rows="15"
                                            {...formik.getFieldProps('part_bl')}
                                        />
                                        {formik.touched.part_bl && formik.errors.part_bl && (
                                            <div className="errorMsg text-danger">{formik.errors.part_bl}</div>
                                        )}
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="notify2" title="Notify2">
                                Tab content for Notify 2
                            </Tab>
                        </Tabs>
                        <div className="row">
                            <div className='col-12'>
                                <input type="submit" name="submit" className="btn btn-primary w-auto" value={fields?.id ? "Save Cargo" : "Save Cargo"} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}