import { USER_ACTION_TYPE } from "../../../Constant/user.constants";
import {  displayErrorMessage, displaySuccessMessage,requestTokenHeader } from "../../../Helpers/helper";
import api from "../../../Axios/index";


export const deleteUserById = (id) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslDeleteContactById",{id},
                                   { headers:requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
      const showList = {};
      const resultOutput = getState()
        .userListById.showList.result.map((data) => data)
        .filter((data) => data.lLoginID !== id);
      showList.result = resultOutput;
      showList.records = getState().userListById.showList.records - 1;
      showList.errorCode = 0;
      displaySuccessMessage("userDelete");
      return dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: showList,
      });
    } else {
      displayErrorMessage("userDeleteError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_USER_LIST_ERROR });
  }
};

export const getcontactList= (postData) => async (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_PENDING });
  try {
    const response = await api.get("api/v1/tslContactsListByCustomerId", {
                                    params: postData,
                                    headers:requestTokenHeader()
                                  });
    if (response.data.errorCode === 0) {
      const responce = {
        result: response.data.data,
        records: response.data.total_records,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_SUCESS,
        payload: responce,
      });
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    return dispatch({ type: USER_ACTION_TYPE.SET_ACCOUNT_LIST_ERROR });
  }
};

export const changeUserPassword = (postData) => async (dispatch, getState) => {
  
  try {
    const response = await api.post("api/v1/tslChangeContactPassword",postData,
                                   { headers:requestTokenHeader() }
    );
    if (response.data.errorCode === 0) {
     
      displaySuccessMessage("userPasswordChangeSuccess");
    
    } else {
      displayErrorMessage("userPasswordChangeError");
     
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_USER_LIST_ERROR });
  }
};


export const addUserDetails = (postData) => async (dispatch, getState) => {
  try {
    const response = await api.post("api/v1/tslAddContacts",postData,
      { headers:requestTokenHeader() });
    if (response.data.errorCode === 0) { 
      // const resultOutput = getState().userListById.showList.result;
      // resultOutput.push(response.data.result);
      // const showList = {};
      // showList.result = resultOutput;
      // showList.records = getState().userListById.showList.records + 1;
      // showList.errorCode = 0;
      // displaySuccessMessage("userAdded");
      // return dispatch({
      //   type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
      //   payload: showList,
      // });

      var resultOutput={};
      const userId = localStorage.getItem('userId');
      resultOutput.userId=userId;
      resultOutput.search='';
      resultOutput.offset=0;
      resultOutput.customerId=response.data.result.customer_id;
      displaySuccessMessage("userAdded");
      dispatch(getcontactList(resultOutput));


    } else {
      displayErrorMessage("userAddedError");
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      return dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_USER_LIST_ERROR });
  }
};


export const editUserDetails = (postData) => 
 {
 return async (dispatch) => {
  try {
    const response = await api.post("api/v1/tslEditContactByCustomerId",postData,
    {
      headers: requestTokenHeader()
    });
    if (response.data.errorCode === 0) {
      var resultOutput={};
      const userId = localStorage.getItem('userId');
      resultOutput.userId=userId;
      resultOutput.search='';
      resultOutput.offset=0;
      resultOutput.customerId=response.data.result.customer_id;
      displaySuccessMessage("userUpdated");
      dispatch(getcontactList(resultOutput));

    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      displayErrorMessage("userUpdatedError");
      
       dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
     displayErrorMessage(err.name);
     dispatch({ type: USER_ACTION_TYPE.SET_USER_LIST_ERROR });
  }
 }
};

export const editClientDetails = (postData) =>
{
  return async (dispatch, getState) => {
   try {
    //dispatch({ type: USER_ACTION_TYPE.SET_CLIENT_PENDING });
    const response = await api.post("api/v1/tslEditUser", postData,
      {
       headers:requestTokenHeader() 
      });
    if (response.data.errorCode === 0) {
      // dispatch({
      //   type: USER_ACTION_TYPE.SET_CLIENT_SUCESS,
      //   payload: {result:"Client created sucessfully"},
      // });
      var resultOutput={};
      const userId = localStorage.getItem('userId');
      resultOutput.userId=userId;
      resultOutput.search='';
      resultOutput.offset=0;
      displaySuccessMessage("clientInfoSucess");
      if(userId){
        dispatch(getcontactList(resultOutput));
      }
    } else {
      const responce = {
        result: "",
        records: 0,
        error_code: response.data.errorCode,
      };
      displayErrorMessage("clientInfoError");
      return dispatch({
        type: USER_ACTION_TYPE.SET_USER_LIST_SUCESS,
        payload: responce,
      });
    }
  } catch (err) {
    displayErrorMessage(err.name);
    return dispatch({ type: USER_ACTION_TYPE.SET_CLIENT_ERROR });
  }
 }
};