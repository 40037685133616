import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import Accordion from "react-bootstrap/Accordion";
import { ModalBox } from "../../../Components/Ui/ModalBox";

import "./index.css";
import {
  getThirdpartyChargesReportingList
} from "../../../Services/Store/Common/thirdpartyCharges/thirdpartyCharges.action";
import PaginationBlock from "../../../Components/Ui/PaginationBlock";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteForm } from "../../../Components/Common/DeleteForm";
import AdminLayout from "../../../Layout";
import { thirdpartyChargesReportingList } from "../../../Services/Store/Common/thirdpartyCharges/thirdpartyCharges.selector";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
const Contact = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState("");
  const [errorResponce, setErrorResponce] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userRecordsData, setUserRecordsData] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ratesListResult = useSelector(thirdpartyChargesReportingList);
  // const mainClientresponse = useSelector(mainClient);
  const [searchType, setSearchType] = useState("");

  useEffect(() => {
    const loginCheck = JSON.parse(sessionStorage.getItem("adminToken")) ? JSON.parse(sessionStorage.getItem("adminToken")) : JSON.parse(sessionStorage.getItem("clientToken")) ? JSON.parse(sessionStorage.getItem("clientToken")) : '';

    if (!loginCheck || errorResponce === 4) {
      navigate("/");
    }

  }, []);

  useEffect(() => {
    if (ratesListResult.result !== undefined) {
      const result = ratesListResult.result;
      if (result.length > 0) {
        setUserData(ratesListResult.result);
        setUserRecordsData(ratesListResult.records);
        setErrorResponce(ratesListResult.error_code);
      } else {
        setUserData("");
        setUserRecordsData("");
      }
    }
  }, [ratesListResult]);



  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     dispatch(getShippinglineReportingList({ searchType: searchType, search: searchKeyword, offset, start: formik.values.start_date ,end: formik.values.end_date  }));      
  //   }, 500);
  //   return () => clearTimeout(getData);
  // }, [searchKeyword]);


  /** For pagination code start here **/
  var offset = currentPage * perPage;
  const handlePageClick = (selectedPage) => {
    dispatch(
      getThirdpartyChargesReportingList({ searchType: searchType, search: searchKeyword, offset: selectedPage, start: formik.values.start_date, end: formik.values.end_date, paid_by: formik.values.paid_by })
    );
  };
  /** For pagination code end here **/

  const handleChange = (event) => {
    if (searchType) {
      setSearchKeyword(event.target.value.trim());
    }
  };



  const callPageRecords = (userData) => {


    if (userData && userData.length) {

      
      let manifest_amount_total = 0;
      let manifest_cad_total = 0;
      let balance_usd = 0;
      let amount_cad = 0; 
      let paid_cad = 0;
      let balance_cad  =0;
      let paid_usd = 0;

      let text = userData.map((data, index) => {    
       


        if (formik.values.paid_by == 1) {
          manifest_amount_total +=data.manifest_amount;
          manifest_cad_total += data.manifest_cad;
          return (<tr key={index}>

            <td>{data.job_number_view}</td>
            <td>{data.job_date ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
            <td>{data.carrier_name}</td>

            <td>{data.booking_number}</td>
            <td>{data.container_number}</td>
            <td>{data.manifest_paidby}</td>
            <td>{parseFloat(data.manifest_amount).toFixed(2)}</td>
            <td>{parseFloat(data.manifest_cad).toFixed(2)}</td>
          </tr>);
        }

        if (formik.values.paid_by == 2) {
          manifest_amount_total +=data.manifest_amount;
          manifest_cad_total += data.manifest_cad;
           balance_usd += data.manifest_amount - data.total_usd;
       amount_cad += data.manifest_cad; 
       paid_cad += data.total_cad;
       balance_cad  +=data.manifest_cad - data.total_cad;

       paid_usd += data.total_usd;

          return (<tr key={index}>

            <td>{data.job_number_view}</td>
            <td>{data.job_date ? moment(data.job_date).format("YYYY-MM-DD") : ""}</td>
            <td>{data.carrier_name}</td>

            <td>{data.booking_number}</td>
            <td>{data.container_number}</td>
            <td>{data.manifest_paidby} ({data.third_party_name.toUpperCase()})</td>
            <td>{parseFloat(data.manifest_amount).toFixed(2) }</td>
            <td>{parseFloat(data.total_usd).toFixed(2)}</td>
            <td>{parseFloat(data.manifest_amount - data.total_usd).toFixed(2)}</td>       
           
            <td>{parseFloat(data.manifest_cad).toFixed(2)}</td>
            <td>{parseFloat(data.total_cad).toFixed(2)}</td>
            <td>{parseFloat(data.manifest_cad - data.total_cad).toFixed(2)}</td>
            <td>{data.third_party_paid_date}</td>

          </tr>)
        }

      })
      if (formik.values.paid_by == 1) {
        text.push(<>
          <tr>
            <td colSpan={6} className="font-weight-bold">Total</td>
            <td className="font-weight-bold">{parseFloat(manifest_amount_total).toFixed(2)}</td>
            <td className="font-weight-bold">{parseFloat(manifest_cad_total).toFixed(2)}</td>
          </tr> 
          </>)
      }

      if (formik.values.paid_by == 2) {
        text.push(<>
          <tr>
            <td colSpan={6} className="font-weight-bold">Total</td>
            <td className="font-weight-bold">{parseFloat(manifest_amount_total).toFixed(2)}</td>
            <td className="font-weight-bold">{parseFloat(paid_usd).toFixed(2)}</td>
            <td className="font-weight-bold">{parseFloat(balance_usd).toFixed(2)}</td>
            <td className="font-weight-bold">{parseFloat(amount_cad).toFixed(2)}</td>
            <td className="font-weight-bold">{parseFloat(paid_cad).toFixed(2)}</td>
            <td className="font-weight-bold">{parseFloat(balance_cad).toFixed(2)}</td>
            <td></td>
          </tr> 
          </>)
      }

      return text


    } else {
      return (
        <tr className="text-center">
          <td colSpan={13}>No Record Found</td>
        </tr>
      );
    }
  };


  const deleteUser = async (userId2) => {
    //dispatch(deleteRatesById(userId2));
    setShowModel(false);
    setDeleteModel(false);

  };





  const handleSearhChange = (event) => {
    setSearchType(event.target.value);
  };

  const validateContactFrom = Yup.object().shape({
    end_date: Yup.string().required('Field is required'),
    start_date: Yup.string().required('Field is required'),
    paid_by: Yup.number().required('Field is required'),
  });
  let initialValues = {
    end_date: '',
    start_date: "",
    paid_by: ""
  }
 
  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateContactFrom,
    onSubmit: (values) => {
      dispatch(getThirdpartyChargesReportingList({ searchType: searchType, search: searchKeyword, offset, start: values.start_date, end: values.end_date, paid_by: values.paid_by }));

    },
  });

  

  return (
    <>
      <AdminLayout pageHeading="Third Party Charges Reporting" pageIcon="fa fa-bar-chart">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h3> Third Party Charges</h3>
            </div>
          </div>
          <div className="container-fluid demo">
            <div
              className="panel-group dashboard-table-format"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="panel panel-default">
                <div
                  className="panel-heading"
                  role="tab"
                  id="headingOne"
                >
                  <div className="panel-body">
                    <div className="card  mb-4">
                      <div className="card-body">
                        <div className="table-responsive">
                          <div
                            id="dataTable_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >

                            <div className="deposited p-0">
                              <form onSubmit={formik.handleSubmit}>
                                <div className="row align-items-center" >
                                  <div className="col-md-3 col-sm-4">
                                  <div className="form-group">
                                    <label>Start Date</label>
                                    <input className="form-control"
                                      type="date"
                                      name="start_date"
                                      {...formik.getFieldProps('start_date')}
                                    />
                                    {formik.touched.start_date && formik.errors.start_date && (
                                      <div className="errorMsg text-danger">{formik.errors.start_date}</div>
                                    )}
                                  </div>
                                  </div>
                                  <div className="col-md-3 col-sm-4">
                                  <div className="form-group">
                                    <label>End Date</label>
                                    <input className="form-control"
                                      type="date"
                                      name="end_date"
                                      {...formik.getFieldProps('end_date')}
                                    />
                                    {formik.touched.end_date && formik.errors.end_date && (
                                      <div className="errorMsg text-danger">{formik.errors.end_date}</div>
                                    )}
                                  </div>
                                  </div>
                                  <div className="col-md-3 col-sm-4">
                                  <div className="form-group">
                                    <label>Paid BY</label>
                                    <select name="paid_by" className="form-control user-success"

                                      {...formik.getFieldProps('paid_by')}
                                    >

                                      <option>--Select Type--</option>
                                      <option value="1">GFFCA</option>
                                      <option value="2">THIRD PARTY</option>

                                    </select>
                                    {formik.touched.paid_by && formik.errors.paid_by && (
                                      <div className="errorMsg text-danger">{formik.errors.paid_by}</div>
                                    )}
                                  </div>
                                  </div>
                                  <div className="col-md-3 col-sm-12">
                                    <input className="btn btn-primary mr-2" type="submit" name="search" value="Go" style={{ marginTop: "15px" }} />
                                    <button className="action-tab btn btn-primary" style={{ marginTop: "15px",display:"none"}}>PDF Download</button>
                                  </div>
                                </div>

                              </form>
                            </div>
                          </div>
                          <table className="table table-striped table-bordered" id="pdf">
                            <thead>
                              <tr className="bg-primary text-white">
                                <th>Job No.</th>
                                <th>Job Date</th>
                                <th>Carrier</th>
                                <th>Booking No.</th>
                                <th>Container No.</th>
                                <th>Manifest/Other Paid BY</th>
                                <th>Amount USD</th>
                                {formik.values.paid_by == 2 && <th>Paid USD</th>}
                                {formik.values.paid_by == 2 && <th>Balance USD</th>}
                                <th>Amount CAD</th>
                                {formik.values.paid_by == 2 && <th>Paid CAD</th>}
                                {formik.values.paid_by == 2 && <th>Balance CAD</th>}
                                {formik.values.paid_by == 2 && <th>Paid Date</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {ratesListResult.result !==
                                undefined &&
                                ratesListResult.result !== "" ? (
                                callPageRecords(
                                  ratesListResult.result
                                )
                              ) : (
                                <tr>
                                  <td colSpan={13}>No Record Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            {/* <div className="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                                       */}
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="dataTable_paginate"
                            >
                              {userData != undefined &&
                                userData.length > 0 &&
                                userRecordsData !== undefined ? (
                                <PaginationBlock
                                  perPage={perPage}
                                  userRecordsData={userRecordsData}
                                  callHandlePageClick={(data) =>
                                    handlePageClick(data)
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>

      </AdminLayout>
    </>
  );
};
export default Contact;
