import { USER_ACTION_TYPE } from '../../../Constant/user.constants';
import { API_URL } from '../../../../Config/config';
import { displayErrorMessage } from "../../../Helpers/helper";
import api from "../../../Axios/index";
import { getNotificationList } from "../../Common/notification/notification.action";

export const checkLoginUser = (postData) => async dispatch => {

    dispatch({ type: USER_ACTION_TYPE.SET_CURRENT_USER_PENDING });
    try {
        const response = await api.post(API_URL + 'api/v1/tslCustomerLogin', postData);
        if(response.data.errorCode === 0) {
            sessionStorage.setItem("customerToken", JSON.stringify(response.data.token));
            sessionStorage.removeItem("clientToken");
            localStorage.setItem("user", JSON.stringify(response.data.user));
            localStorage.setItem("userId", response.data.user.id);
            dispatch(getNotificationList({'customer_id': response.data.user.id}));
            return dispatch({
                type: USER_ACTION_TYPE.SET_CURRENT_USER_SUCESS,
                payload: { user : response.data.user },
            });
        } else if(response.data.errorCode === 1 || response.data.errorCode === 2) {
            displayErrorMessage(response.data.errorMessage);
            return dispatch({
                type: USER_ACTION_TYPE.SET_CURRENT_USER_ERROR,
                payload: { error: response.data.errorMessage },
            });
        }
    } catch (err) {
        displayErrorMessage(err.name);
        return dispatch({ type: USER_ACTION_TYPE.SET_CURRENT_USER_ERROR });
    }
};