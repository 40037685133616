import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import { ModalBox } from "../../../Components/Ui/ModalBox";
import AddRemoveMultipleInputField from "../../../Components/Ui/AddRemoveMultipleInputFields";
import { displayErrorMessage, displaySuccessMessage, requestTokenHeader ,getUserIds} from "../../../Services/Helpers/helper";
import api from "../../../Services/Axios/index";
import moment from "moment";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { addNotificationDetails } from "../../../Services/Store/Common/notification/notification.action";
import { useDispatch, useSelector } from "react-redux";
const libraries = ['places'];

export function JobForm(props) {
    const dispatch = useDispatch();
    const inputRefOrigin = useRef();
    const inputRefDestination = useRef();
    const inputRefTruckingLocation = useRef();
    const [fields, setFields] = useState(props.userDetails ? props.userDetails : '');
    const [jobComments, setJobComments] = useState(props?.jobComments ? props.jobComments : []);
    const [selectedCarrierOption, setSelectedCarrierOption] = useState(props?.userDetails?.shipping_line_id ? props.userDetails.shipping_line_id : "");
    const [selectedJobStatusOption, setSelectedJobStatusOption] = useState(props?.userDetails?.job_status_id ? props.userDetails.job_status_id : "");
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDm9SqM0fp4sg7KSLBachj-WS7zpQ80Wtk',
        libraries
    });
    const optionsCarrier = [];
    const optionsJobStatus = [];
    props?.jobsData?.[0].map((data, index) => (
        optionsCarrier.push({ value: data.id, label: data.name })
    ));
    props?.jobsData?.[6].map((data, index) => (
        optionsJobStatus.push({ value: data.id, label: data.name })
    ));
    const handleCarrierSelect = e => {
        setSelectedCarrierOption(e.value);
    };

    const handleJobStatusSelect = e => {
        setSelectedJobStatusOption(e.value);
    };

    const [showModel, setShowModel] = useState(false);
    const [commentModel, setCommentModel] = useState(false);

    const validateContactFrom = Yup.object().shape({
        job_for: Yup.string().required('Job For is required'),
        job_number: Yup.string().required('Job number is required'),
        job_date: Yup.string().required('Job date is required'),
        shipping_line_id: Yup.number().nullable(true),
        service_id: Yup.number().nullable(true),
        seal_number: Yup.string().nullable(true),
        sale_person_id: Yup.number().required("Sale person is required"),
        booking_number: Yup.string().required('Booking number is required'),
        container_number: Yup.string().required('Container number is required'),
        vessal_name: Yup.string().nullable(true),
        customer_id: Yup.number().required('Customer Name is required'),
        origin: Yup.string().nullable(true),
        destination: Yup.string().nullable(true),
        loading_port: Yup.number().nullable(true),
        destination_port : Yup.number().nullable(true),
        truker_id: Yup.number().required(' Trucker Name is required'),
        doc_cutoff_date: Yup.string().nullable(true),
        vgm_cutoff_date: Yup.string().nullable(true),
        cargo_curtoff_date: Yup.string().nullable(true),
        booking_rollover_date: Yup.string().nullable(true),
        export_document_coordinator_id: Yup.number().nullable(true),
        import_document_coordinator_id: Yup.number().nullable(true),
        job_status_id: Yup.number().nullable(true),
        trucking_location: Yup.string().nullable(true),
        job_comment: Yup.string().nullable(true),
        need_trucking: Yup.string().nullable(true),
        customer_comment: Yup.string().nullable(true),
        loading_date: Yup.string().nullable(true)
    });
    let initialValues = {};

    if (fields) {
        initialValues = {
            job_for: fields.job_for,
            job_number: fields.job_number,
            job_date: fields.job_date,
            shipping_line_id: fields.shipping_line_id,
            service_id: fields.service_id,
            seal_number: (fields.seal_number === "null" || fields.seal_number === "undefined") ? "" : fields.seal_number,
            sale_person_id: fields.sale_person_id,
            booking_number: fields.booking_number,
            container_number: fields.container_number,
            customer_id: fields.customer_id,
            vessal_name: fields.vessal_name,
            origin: fields.origin,
            destination: fields.destination,
            loading_port: fields.loading_port,
            destination_port: fields.destination_port,
            truker_id: fields.truker_id,
            doc_cutoff_date: fields.doc_cutoff_date,
            vgm_cutoff_date: fields.vgm_cutoff_date,
            cargo_curtoff_date: fields.cargo_curtoff_date,
            booking_rollover_date: fields.booking_rollover_date,
            export_document_coordinator_id: fields.export_document_coordinator_id,
            import_document_coordinator_id: fields.import_document_coordinator_id,
            job_status_id: fields.job_status_id,
            trucking_location: fields.trucking_location === "null" ? "" : fields.trucking_location,
            job_comment: fields.job_comment === "null" ? "" : fields.job_comment,
            need_trucking: fields.need_trucking === "null" ? "" : fields.need_trucking,
            customer_comment: fields.customer_comment === "null" ? "" : fields.customer_comment,
            loading_date: fields.loading_date === "null" ? "" : fields.loading_date,
        }
    } else {
        initialValues = {
            job_for: '',
            job_number: props?.jobNumber,
            job_date: '',
            shipping_line_id: '',
            service_id: '',
            seal_number: '',
            sale_person_id: '',
            booking_number: '',
            container_number: '',
            vessal_name: '',
            customer_id: '',
            origin: '',
            destination: '',
            truker_id: '',
            doc_cutoff_date: '',
            vgm_cutoff_date: '',
            cargo_curtoff_date: '',
            booking_rollover_date: '',
            export_document_coordinator_id: '',
            import_document_coordinator_id: '',
            job_status_id: '',
            trucking_location: '',
            job_comment: '',
            need_trucking: "",
            customer_comment: '',
            loading_date: '',
            loading_port: '',
            destination_port: '',
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validateContactFrom,
        onSubmit: (values) => {
            let upload_certificate_of_origin = '0';
            props?.jobsData?.[9].forEach((data, index) => {
                if (values.destination === data.id && data.certificate_of_origin === '1') {
                    upload_certificate_of_origin = '1';
                }
            });
            if (fields?.id) {
                let postData = {
                    job_for: values.job_for,
                    id: fields.id,
                    job_number: (values.job_number).toString(),
                    job_date: values.job_date,
                    shipping_line_id: selectedCarrierOption == "" ? 30 : selectedCarrierOption,
                    service_id: values.service_id,
                    seal_number: values.seal_number,
                    sale_person_id: values.sale_person_id,
                    booking_number: values.booking_number,
                    container_number: values.container_number,
                    vessal_name: values.vessal_name,
                    customer_id: values.customer_id,
                    origin: values.origin,
                    destination: values.destination,
                    truker_id: values.truker_id,
                    doc_cutoff_date: values.doc_cutoff_date,
                    vgm_cutoff_date: values.vgm_cutoff_date,
                    cargo_curtoff_date: values.cargo_curtoff_date,
                    booking_rollover_date: values.booking_rollover_date,
                    export_document_coordinator_id: values.export_document_coordinator_id,
                    import_document_coordinator_id: values.import_document_coordinator_id,
                    job_status_id: selectedJobStatusOption,
                    trucking_location: values.trucking_location,
                    job_comment: JSON.stringify((jobComments && Object.keys(jobComments).length !== 0) ? Array.isArray(jobComments) ? jobComments : [].push(jobComments) : []),
                    loading_date: values.loading_date,
                    need_trucking: values.need_trucking,
                    customer_comment: values.customer_comment,
                    upload_certificate_of_origin: upload_certificate_of_origin,
                    loading_port: values.loading_port,
                    destination_port: values.destination_port,
                }

                if(values.container_number !="" && values.container_number !=="undefined" && (fields.container_number == "" || fields.container_number ==="undefined" || fields.container_number ==null)){

                    getUserIds({ 'roles.name': 'documentation' }, function (documentationUserIds) {
                        documentationUserIds.length && documentationUserIds.map((user_rec, index) => {
                          dispatch(addNotificationDetails({
                            user_id: user_rec.id,
                            resource: 'jobs',
                            resource_id: fields.id,
                            content: 'Container number added to job  #' + values.job_number
                          }));
                        });
                      }); 
                    
                                    }

                props.editUserDetails(postData);

            } else {
                let params = {
                    job_for: values.job_for,
                    job_number: (values.job_number).toString(),
                    job_date: values.job_date,
                    shipping_line_id: selectedCarrierOption == "" ? 30 : selectedCarrierOption,
                    service_id: values.service_id,
                    seal_number: values.seal_number,
                    sale_person_id: values.sale_person_id,
                    booking_number: values.booking_number,
                    container_number: values.container_number,
                    vessal_name: values.vessal_name,
                    customer_id: values.customer_id,
                    origin: values.origin,
                    destination: values.destination,
                    truker_id: values.truker_id,
                    doc_cutoff_date: values.doc_cutoff_date,
                    vgm_cutoff_date: values.vgm_cutoff_date,
                    cargo_curtoff_date: values.cargo_curtoff_date,
                    booking_rollover_date: values.booking_rollover_date,
                    export_document_coordinator_id: values.export_document_coordinator_id,
                    import_document_coordinator_id: values.import_document_coordinator_id,
                    job_status_id: selectedCarrierOption,
                    trucking_location: values.trucking_location,
                    job_comment: JSON.stringify((jobComments && Object.keys(jobComments).length !== 0) ? Array.isArray(jobComments) ? jobComments : [].push(jobComments) : []),
                    customer_comment: values.customer_comment,
                    need_trucking: values.need_trucking,
                    loading_date: values.loading_date,
                    upload_certificate_of_origin: upload_certificate_of_origin,
                    loading_port: values.loading_port,
                    destination_port: values.destination_port,
                }

               
                
                props.addUserDetails(params)
            }
        },
    });

    const submitJobComment = async (params) => {
        setJobComments(params);
    };

    async function fetchMyAPI(id) {
        const response = await api.post("api/v1/tslDeleteJobComments", {
            id: id
        },
            { headers: requestTokenHeader() }
        );
        if (response.data.errorCode === 0) {
            displaySuccessMessage("jobCommentDeletedSuccess");
            document.getElementById(`id-${id}`).remove();
        } else {
            displayErrorMessage("jobCommentDeletedError");
        }
    }
    const _handleDeleteClickFiles = (id) => {
        fetchMyAPI(id);
    };

    const _handleEditClickFiles = (item) => {
        setCommentModel(item);
        setShowModel(true);
    };
    const handleCommentChange = (e) => {
        let data = commentModel;
        data["job_comment"] = e.target.value;
        setCommentModel({ ...data });
    }

    async function fetchMyAPI2(data) {
        const response = await api.post("api/v1/tslEditJobComment", {
            id: data.id,
            user_id: data.user_id,
            job_comment: data.job_comment
        },
            { headers: requestTokenHeader() }
        );
        if (response.data.errorCode === 0) {
            displaySuccessMessage("jobCommentUpdateSuccess");
            setShowModel(false);
            setCommentModel(false);
            document.getElementById(`id-${data.id}`).children[0].innerHTML = data.job_comment;
        } else {
            displayErrorMessage("jobCommentUpdatedError");
            setShowModel(false);
            setCommentModel(false);
        }
    }
    const editComment = () => {
        fetchMyAPI2(commentModel)
    }

    const optionsOrigin = [];
    const optionsDestination = [];
    props?.jobsData?.[8].map((data, index) => (
        optionsOrigin.push({ value: data.id, label: data.name + ', ' + data.country })
    ));

    props?.jobsData?.[9].map((data, index) => (
        optionsDestination.push({ value: data.id, label: data.name + ', ' + data.country })
    ));

    const handleOriginSelect = e => {
        formik.setFieldValue("loading_port", e.value);
    };

    const handleDestinationSelect = e => {
        formik.setFieldValue("destination_port", e.value);
    };

    const handlePlaceChangedTruckingLocation = () => {
        const [place] = inputRefTruckingLocation.current.getPlaces();
        if (place) {
            formik.setFieldValue("trucking_location", place.formatted_address);
        }
    }
    const handlePlaceChangedOrigin = () => {
        const [place] = inputRefOrigin.current.getPlaces();
        if (place) {
            formik.setFieldValue("origin", place.formatted_address);
        }
    }

    const handlePlaceChangedDestination = () => {
        const [place] = inputRefDestination.current.getPlaces();
        if (place) {
            formik.setFieldValue("destination", place.formatted_address);
          

        }
    }
    return (
        <>
            <div className="modal-body">
                <div className="">
                    <div className="row login-wrap-new">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-content">
                                <div className="row">
                                    <div className="col-sm-3 mb-2">
                                        <label>Job For</label>
                                        <select className="form-control"
                                            name="job_for"
                                            id="job_for"
                                            {...formik.getFieldProps('job_for')}>
                                            <option value="">--Please Select--</option>
                                            <option value="GFFCA">GFFCA</option>
                                            <option value="NFFI">NFFI</option>
                                        </select>
                                        {formik.touched.job_for && formik.errors.job_for && (
                                            <div className="errorMsg text-danger">{formik.errors.job_for}</div>
                                        )}
                                    </div>

                                    <div className="col-sm-3 mb-2">
                                        <label>Job No.</label>
                                        <input className="form-control"
                                            name="job_number"
                                            id="job_number"
                                            {...formik.getFieldProps('job_number')}
                                            readOnly
                                        />
                                        {formik.touched.job_number && formik.errors.job_number && (
                                            <div className="errorMsg text-danger">{formik.errors.job_number}</div>
                                        )}
                                    </div>

                                    <div className="col-sm-3 mb-2">
                                        <label>Job Date</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="job_date"
                                            name="job_date"
                                            {...formik.getFieldProps('job_date')}
                                        />
                                        {formik.touched.job_date && formik.errors.job_date && (
                                            <div className="errorMsg text-danger">{formik.errors.job_date}</div>
                                        )}
                                    </div>

                                    <div className="col-sm-3 mb-2">
                                        <label>Carrier</label>
                                        <Select
                                            name="shipping_line_id"
                                            options={optionsCarrier}
                                            onChange={handleCarrierSelect}
                                            value={optionsCarrier.filter(function (option) {
                                                return option.value === selectedCarrierOption;
                                            })}
                                            label="Single select"
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3 mb-2">
                                        <label>Service</label>
                                        <select
                                            id="service_id"
                                            name="service_id"
                                            className="form-control"
                                            {...formik.getFieldProps('service_id')}
                                        >
                                            <option>--Select Service--</option>
                                            {props.jobsData ?
                                                props.jobsData[1].map((data, index) => (
                                                    <option value={data.id} key={index.toString()}>{data.name}</option>

                                                )) : ''}
                                        </select>
                                        {formik.touched.service_id && formik.errors.service_id && (
                                            <div className="errorMsg text-danger">{formik.errors.service_id}</div>
                                        )}
                                    </div>
                                    <div className=" col-sm-3 mb-2">
                                        <label>Seal No.</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="seal_number"
                                            id="seal_number"
                                            {...formik.getFieldProps('seal_number')}
                                        />
                                        {formik.touched.seal_number && formik.errors.seal_number && (
                                            <div className="errorMsg text-danger">{formik.errors.seal_number}</div>
                                        )}
                                    </div>

                                    <div className=" col-sm-3 mb-2">
                                        <label>Sale Person</label>
                                        <select
                                            id="sale_person_id"
                                            name="sale_person_id"
                                            className="form-control"
                                            {...formik.getFieldProps('sale_person_id')}
                                        >
                                            <option>--Select Service--</option>
                                            {props.jobsData ?
                                                props.jobsData[7].map((data, index) => (
                                                    (data.role === 'sales') && <option value={data.id} key={index.toString()}>{data.firstname + (data.lastname ? " " + data.lastname : "")}</option>
                                                )) : ''}
                                        </select>
                                        {formik.touched.sale_person_id && formik.errors.sale_person_id && (
                                            <div className="errorMsg text-danger">{formik.errors.sale_person_id}</div>
                                        )}
                                    </div>
                                    <div className=" col-sm-3 mb-2">
                                        <label>Booking No</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="booking_number"
                                            id="booking_number"
                                            {...formik.getFieldProps('booking_number')}
                                        />
                                        {formik.touched.booking_number && formik.errors.booking_number && (
                                            <div className="errorMsg text-danger">{formik.errors.booking_number}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className=" col-sm-3 mb-2">
                                        <label>Container No.</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="container_number"
                                            id="container_number"
                                            {...formik.getFieldProps('container_number')}
                                        />
                                        {formik.touched.container_number && formik.errors.container_number && (
                                            <div className="errorMsg text-danger">{formik.errors.container_number}</div>
                                        )}
                                    </div>

                                    <div className=" col-sm-3 mb-2">
                                        <label>Customer Name</label>
                                        <select className="form-control"
                                            name="customer_id"
                                            id="customer_id"
                                            {...formik.getFieldProps('customer_id')}
                                        >
                                            <option>--Select Customer--</option>
                                            {props.jobsData ?
                                                props.jobsData[3].map((data, index) => (
                                                    <option value={data.id} key={index.toString()}>{data.firstname + (data.lastname ? ' ' + data.lastname : '')}</option>
                                                )) : ''}
                                        </select>
                                        {formik.touched.customer_id && formik.errors.customer_id && (
                                            <div className="errorMsg text-danger">{formik.errors.customer_id}</div>
                                        )}
                                    </div>

                                    <div className=" col-sm-3 mb-2">
                                        <label>Origin</label>
                                        {isLoaded
                                && <StandaloneSearchBox
                                    onLoad={ref => inputRefOrigin.current = ref}
                                    onPlacesChanged={handlePlaceChangedOrigin}
                                >
                                    <input className="form-control"
                                        name="origin"
                                        id="origin"
                                        type='text'
                                        placeholder='Origin'
                                        {...formik.getFieldProps('origin')}
                                    />
                                </StandaloneSearchBox>}
                                      
                                        {formik.touched.origin && formik.errors.origin && (
                                            <div className="errorMsg text-danger">{formik.errors.origin}</div>
                                        )}
                                    </div>

                                    <div className=" col-sm-3 mb-2">
                                        <label>Loading Port</label>
                                        <Select
                                            name="loading_port"
                                            id="loading_port"
                                            options={optionsOrigin}
                                            onChange={handleOriginSelect}
                                            value={optionsOrigin.filter(function (option) {
                                                return option.value == formik.values.loading_port;
                                            })}
                                            label="Single select"
                                        />
                                        {formik.touched.loading_port && formik.errors.loading_port && (
                                            <div className="errorMsg text-danger">{formik.errors.loading_port}</div>
                                        )}
                                    </div>

                                    
                                </div>
                                <div className='row'>

                                <div className=" col-sm-3 mb-2">
                                        <label>Destination</label>
                                        {isLoaded
                                && <StandaloneSearchBox
                                    onLoad={ref => inputRefDestination.current = ref}
                                    onPlacesChanged={handlePlaceChangedDestination}
                                >
                                    <input className="form-control"
                                        name="destination"
                                        id="destination"
                                        placeholder='Destination'
                                        type='text'
                                        {...formik.getFieldProps('destination')}
                                    />

                                </StandaloneSearchBox>}
                                      
                                        {formik.touched.destination && formik.errors.destination && (
                                            <div className="errorMsg text-danger">{formik.errors.destination}</div>
                                        )}
                                    </div>

                                    <div className=" col-sm-3 mb-2">
                                        <label>Destination Port</label>
                                        <Select
                                            name="destination_port"
                                            id="destination_port"
                                            options={optionsDestination}
                                            onChange={handleDestinationSelect}
                                            value={optionsDestination.filter(function (option) {
                                                return option.value == formik.values.destination_port;
                                            })}
                                            label="Single select"
                                        />
                                        {formik.touched.destination_port && formik.errors.destination_port && (
                                            <div className="errorMsg text-danger">{formik.errors.destination_port}</div>
                                        )}
                                    </div>

                                    <div className="col-sm-3 mb-2">
                                        <label>Vessal Name</label>
                                        <input className="form-control"
                                            name="vessal_name"
                                            id="vessal_name"
                                            {...formik.getFieldProps('vessal_name')}
                                        />
                                        {formik.touched.vessal_name && formik.errors.vessal_name && (
                                            <div className="errorMsg text-danger">{formik.errors.vessal_name}</div>
                                        )}
                                    </div>  <div className=" col-sm-3 mb-2">
                                        <label>Loading Date</label>
                                        <input className="form-control"
                                            type="date"
                                            name="loading_date"
                                            id="loading_date"
                                            {...formik.getFieldProps('loading_date')}
                                        />
                                        {formik.touched.loading_date && formik.errors.loading_date && (
                                            <div className="errorMsg text-danger">{formik.errors.loading_date}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="col-sm-3 mb-2">
                                        <label>Trucker Name</label>
                                        <select className="form-control"
                                            name="truker_id"
                                            {...formik.getFieldProps('truker_id')}
                                        >
                                            <option>--Select Trucker--</option>
                                            {props.jobsData ?
                                                props.jobsData[2].map((data, index) => (
                                                    <option value={data.id} key={index.toString()}>{data.name}</option>
                                                )) : ''}
                                        </select>
                                        {formik.touched.truker_id && formik.errors.truker_id && (
                                            <div className="errorMsg text-danger">{formik.errors.truker_id}</div>
                                        )}
                                    </div>

                                    <div className=" col-sm-3 mb-2">
                                        <label>Doc Cutoff Date</label>
                                        <input className="form-control"
                                            type="date"
                                            name="doc_cutoff_date"
                                            id="doc_cutoff_date"
                                            {...formik.getFieldProps('doc_cutoff_date')}
                                        />
                                        {formik.touched.doc_cutoff_date && formik.errors.doc_cutoff_date && (
                                            <div className="errorMsg text-danger">{formik.errors.doc_cutoff_date}</div>
                                        )}
                                    </div>

                                    <div className=" col-sm-3 mb-2">
                                        <label>VGM Cutoff Date</label>
                                        <input className="form-control"
                                            type="date"
                                            name="vgm_cutoff_date"
                                            id="vgm_cutoff_date"
                                            {...formik.getFieldProps('vgm_cutoff_date')}
                                        />
                                        {formik.touched.vgm_cutoff_date && formik.errors.vgm_cutoff_date && (
                                            <div className="errorMsg text-danger">{formik.errors.vgm_cutoff_date}</div>
                                        )}
                                    </div>

                                    <div className=" col-sm-3 mb-2">
                                        <label>Cargo Cutoff Date</label>
                                        <input className="form-control"
                                            type="date"
                                            name="cargo_curtoff_date"
                                            id="cargo_curtoff_date"
                                            {...formik.getFieldProps('cargo_curtoff_date')}
                                        />
                                        {formik.touched.cargo_curtoff_date && formik.errors.cargo_curtoff_date && (
                                            <div className="errorMsg text-danger">{formik.errors.cargo_curtoff_date}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="row" >
                                    <div className="col-sm-3 mb-2">
                                        <label> Booking Rollover Date</label>
                                        <input className="form-control"
                                            type="date"
                                            name="booking_rollover_date"
                                            id="booking_rollover_date"
                                            {...formik.getFieldProps('booking_rollover_date')}
                                        />
                                        {formik.touched.booking_rollover_date && formik.errors.booking_rollover_date && (
                                            <div className="errorMsg text-danger">{formik.errors.booking_rollover_date}</div>
                                        )}
                                    </div>

                                    <div className=" col-sm-3 mb-2">
                                        <label>Export Document Coordinator</label>
                                        <select className="form-control"
                                            name="export_document_coordinator_id"
                                            id="export_document_coordinator_id"
                                            {...formik.getFieldProps('export_document_coordinator_id')}
                                        >
                                            <option>--Export Document Coordinator--</option>
                                            {props.jobsData ?
                                                props?.jobsData?.[4].map((data, index) => (
                                                    <option value={data.id} key={index.toString()}>{data.name}</option>
                                                )) : ''}
                                        </select>
                                        {formik.touched.export_document_coordinator_id && formik.errors.export_document_coordinator_id && (
                                            <div className="errorMsg text-danger">{formik.errors.export_document_coordinator_id}</div>
                                        )}
                                    </div>

                                    <div className=" col-sm-3 mb-2">
                                        <label>Import Document Coordinator</label>
                                        <select
                                            className="form-control"
                                            name="import_document_coordinator_id"
                                            id="import_document_coordinator_id"
                                            {...formik.getFieldProps('import_document_coordinator_id')}

                                        >
                                            <option value="">--Import Document Coordinator--</option>
                                            {props.jobsData ?
                                                props?.jobsData?.[5].map((data, index) => (
                                                   <option value={data.id} key={index.toString()}>{data.name}</option>
                                                )) : ''}
                                        </select>
                                        {formik.touched.import_document_coordinator_id && formik.errors.import_document_coordinator_id && (
                                            <div className="errorMsg text-danger">{formik.errors.import_document_coordinator_id}</div>
                                        )}
                                    </div>

                                    <div className=" col-sm-3 mb-2">
                                        <label>Job Status</label>
                                        <Select
                                            name="job_status_id"
                                            options={optionsJobStatus}
                                            onChange={handleJobStatusSelect}
                                            value={optionsJobStatus.filter(function (option) {
                                                return option.value === selectedJobStatusOption;
                                            })}
                                            label="Single select"
                                        />
                                        {formik.touched.job_status_id && formik.errors.job_status_id && (
                                            <div className="errorMsg text-danger">{formik.errors.job_status_id}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="row"></div>

                                <div className="row" >
                                  
                                    <div className=" col-sm-3 mb-2">
                                        <label>Need trucking</label>
                                        <select className="form-control"
                                            name="need_trucking"
                                            id="need_trucking"
                                            {...formik.getFieldProps('need_trucking')}
                                        >
                                            <option>--Select--</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                        {formik.touched.need_trucking && formik.errors.need_trucking && (
                                            <div className="errorMsg text-danger">{formik.errors.need_trucking}</div>
                                        )}
                                    </div>
                                    <div className=" col-sm-3 mb-2">
                                        <label>Trucking Location</label>
                                        {isLoaded
                                            &&
                                            <StandaloneSearchBox
                                                onLoad={ref => inputRefTruckingLocation.current = ref}
                                                onPlacesChanged={handlePlaceChangedTruckingLocation}
                                            >
                                                <input
                                                    name="trucking_location"
                                                    type="text"
                                                    className="form-control"
                                                    id="trucking_location"
                                                    placeholder='Trucking Location'
                                                    {...formik.getFieldProps('trucking_location')}
                                                />
                                            </StandaloneSearchBox>
                                        }
                                        {formik.touched.trucking_location && formik.errors.trucking_location && (
                                            <div className="errorMsg text-danger">{formik.errors.trucking_location}</div>
                                        )}
                                    </div>
                                 
                                </div>

                                <div className='row'>
                                    <div className="col-md-12">
                                        <label>Customer Comment</label>
                                        <textarea
                                            className="form-control"
                                            name="customer_comment"
                                            id="customer_comment"
                                            {...formik.getFieldProps('customer_comment')}
                                            readOnly={true}
                                        ></textarea>
                                        {formik.touched.customer_comment && formik.errors.customer_comment && (
                                            <div className="errorMsg text-danger">{formik.errors.customer_comment}</div>
                                        )}
                                    </div>
                                </div>

                                {props?.jobComments && props?.jobComments.map((item, idx) => {
                                    return (
                                        <div key={idx} id={`id-${item.id}`} className="row align-items-box" style={{ display: props?.jobComments ? "" : "none" }} >
                                            <div className="col-md-10">
                                                <div className='comment-box'>
                                                    <p className='p-0 m-0'>
                                                        <span className='d-block date'> {moment(item.created_at).format("YYYY-MM-DD")}</span>
                                                        {item.job_comment}
                                                    </p>
                                                    <div className='del-button'>
                                                        <a
                                                            className="remove-file del-comment d-none"
                                                            onClick={() => _handleEditClickFiles(item)}
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </a>
                                                        <a
                                                            className="remove-file del-comment"
                                                            onClick={() => _handleDeleteClickFiles(item.id)}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="row" >
                                    <div className=" col-sm-12">
                                        <AddRemoveMultipleInputField
                                            addJobComment={(params) => submitJobComment(params)}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <input type="submit" name="submit" className="btn btn-primary text-white pr-3 pl-3" value={fields?.id ? "Save Job" : "Save Job"} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <ModalBox
                show={showModel}
                size={'sm'}
                title="Edit Comment"
                onHide={() => {
                    setShowModel(false);
                    setCommentModel(false);
                }}
            >
                {
                    commentModel ?
                        (<div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <label>Job comment</label>
                                        <input type="text" className="form-control" placeholder="Job comment" value={commentModel.job_comment} onChange={(e) => handleCommentChange(e)} />
                                    </div>
                                    <div className="col-md-12 mt-3 text-center">
                                        <button className="btn btn-primary text-white" onClick={() => editComment()}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>) :
                        (
                            ""
                        )}
            </ModalBox>
        </>
    );
}